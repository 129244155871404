const WORDS = [
[ '네', 'yes', '1', '' ],
[ '있다', 'to exist; to have; to stay', '2', '' ],
[ '학교', 'school', '3', '學校' ],
[ '집', 'house, home', '4', '' ],
[ '먹다', 'to eat', '5', '' ],
[ '친구', 'friend', '6', '親舊' ],
[ '아니요', 'no', '7', '' ],
[ '보다', 'to see, to look at', '8', '' ],
[ '저', 'I; that (over there)', '9', '' ],
[ '선생님', 'teacher', '10', '先生-' ],
[ '가다', 'to go', '11', '' ],
[ '이름', 'name', '12', '' ],
[ '한국', 'Korea', '13', '韓國' ],
[ '하다', 'to do', '14', '' ],
[ '그', 'that (before nouns)', '15', '' ],
[ '학생', 'student', '16', '學生' ],
[ '없다', 'to not exist; to not have', '17', '' ],
[ '어디', 'where', '18', '' ],
[ '밥', 'rice (cooked); meal', '19', '' ],
[ '공부하다', 'to study', '20', '工夫' ],
[ '커피', 'coffee', '21', '' ],
[ '사람', 'person, people', '22', '' ],
[ '말하다', 'to speak', '23', '' ],
[ '책', 'book', '24', '冊' ],
[ '아버지', 'father', '25', '' ],
[ '좋다', 'to be good, be nice; to like', '26', '' ],
[ '시간', 'time; hours', '27', '時間' ],
[ '뭐', 'what', '28', '' ],
[ '마시다', 'to drink', '29', '' ],
[ '영화', 'movie', '30', '映畵' ],
[ '어머니', 'mother', '31', '' ],
[ '오다', 'to come', '32', '' ],
[ '물', 'water', '33', '' ],
[ '미국', 'USA', '34', '美國' ],
[ '크다', 'to be big', '35', '' ],
[ '이거', 'this thing', '36', '' ],
[ '차', 'tea; car', '37', '茶, 車' ],
[ '만나다', 'to meet', '38', '' ],
[ '여자', 'woman, female', '39', '女子' ],
[ '오늘', 'today', '40', '' ],
[ '안녕하다', 'to be well, be in good health', '41', '安寧' ],
[ '이', 'tooth; this', '42', '' ],
[ '머리', 'head; hair', '43', '' ],
[ '주다', 'to give', '44', '' ],
[ '책상', 'desk', '45', '冊床' ],
[ '누구', 'who', '46', '' ],
[ '예쁘다', 'to be pretty, charming', '47', '' ],
[ '주스', 'juice', '48', '' ],
[ '영어', 'English (language)', '49', '英語' ],
[ '읽다', 'to read', '50', '' ],
[ '사랑', 'love', '51', '' ],
[ '일', 'work, matter; day', '52', '日' ],
[ '어렵다', 'to be difficult, hard', '53', '' ],
[ '빨리', 'quickly', '54', '' ],
[ '남자', 'man, male', '55', '男子' ],
[ '사다', 'to buy', '56', '' ],
[ '돈', 'money', '57', '' ],
[ '가방', 'bag', '58', '' ],
[ '감사하다', 'to be thankful, thank, appreciate', '59', '感謝' ],
[ '같이', 'together', '60', '' ],
[ '노래', 'song', '61', '' ],
[ '듣다', 'to listen, to hear', '62', '' ],
[ '전화', 'telephone', '63', '電話' ],
[ '제', 'my', '64', '' ],
[ '알다', 'to know', '65', '' ],
[ '사과', 'apple; apology', '66', '沙果' ],
[ '여기', 'here', '67', '' ],
[ '쓰다', 'to write; to use', '68', '' ],
[ '월요일', 'Monday', '69', '月曜日' ],
[ '옷', 'clothes', '70', '' ],
[ '운동하다', 'to work out, to exercise', '71', '運動' ],
[ '어제', 'yesterday', '72', '' ],
[ '생각', 'thought, idea', '73', '' ],
[ '맛있다', 'to be delicious, tasty', '74', '' ],
[ '우유', 'milk', '75', '牛乳' ],
[ '잘', 'well', '76', '' ],
[ '사랑하다', 'to love', '77', '' ],
[ '대학교', 'college', '78', '大學校' ],
[ '볼펜', 'ballpoint pen', '79', '' ],
[ '재미있다', 'to be fun, interesting', '80', '' ],
[ '이쪽', 'this side, our side', '81', '' ],
[ '방', 'a room', '82', '房' ],
[ '자다', 'to sleep', '83', '' ],
[ '동생', 'younger sibling', '84', '' ],
[ '화요일', 'Tuesday', '85', '火曜日' ],
[ '춥다', 'to be cold (regarding the weather)', '86', '' ],
[ '일본', 'Japan', '87', '日本' ],
[ '왜', 'why', '88', '' ],
[ '노래하다', 'to sing', '89', '' ],
[ '음악', 'music', '90', '音樂' ],
[ '그럼', "then, if so; that's right", '91', '' ],
[ '죄송하다', 'to be sorry, feel guilty', '92', '罪悚' ],
[ '서울', 'Seoul', '93', '' ],
[ '문', 'door', '94', '門' ],
[ '모르다', 'to not know', '95', '' ],
[ '아침', 'morning, breakfast', '96', '' ],
[ '의자', 'chair', '97', '椅子' ],
[ '살다', 'to live', '98', '' ],
[ '많이', 'a lot, many, plenty', '99', '' ],
[ '질문', 'question', '100', '質問' ],
[ '비싸다', 'to be expensive', '101', '' ],
[ '말', 'words, language; horse', '102', '' ],
[ '요리', 'cooking, dish', '103', '料理' ],
[ '앉다', 'to sit, sit down', '104', '' ],
[ '언제', 'when', '105', '' ],
[ '수요일', 'Wednesday', '106', '水曜日' ],
[ '작다', 'to be small', '107', '' ],
[ '좀', 'a little; kindly; certainly', '108', '' ],
[ '지금', 'now', '109', '只今' ],
[ '놀다', 'to hang out, to play', '110', '' ],
[ '연필', 'pencil', '111', '鉛筆' ],
[ '바나나', 'banana', '112', '' ],
[ '시작하다', 'to start, begin', '113', '始作' ],
[ '눈', 'eye; snow', '114', '' ],
[ '가족', 'family', '115', '家族' ],
[ '입다', 'to wear', '116', '' ],
[
  '김치',
  'kimchi (traditional Korean dish made of seasoned vegetables and other ingredients)',
  '117',
  ''
],
[ '내일', 'tomorrow', '118', '來日' ],
[ '아프다', 'to be sick; to hurt, ache', '119', '' ],
[ '날', 'day, date; edge', '120', '' ],
[ '목요일', 'Thursday', '121', '木曜日' ],
[ '이야기하다', 'to talk', '122', '' ],
[ '저기', 'over there', '123', '' ],
[ '청소', 'cleaning', '124', '淸掃' ],
[ '바쁘다', 'to be busy', '125', '' ],
[ '년', 'year', '126', '年' ],
[ '그리고', 'and', '127', '' ],
[ '요리하다', 'to cook', '128', '料理' ],
[ '오빠', 'older brother (of a female)', '129', '' ],
[ '생일', 'birthday', '130', '生日' ],
[ '물어보다', 'to ask, question', '131', '' ],
[ '끝', 'end', '132', '' ],
[ '회사', 'company, corporation', '133', '會社' ],
[ '쉽다', 'to be easy', '134', '' ],
[ '어떻게', 'how', '135', '' ],
[ '중국', 'China', '136', '中國' ],
[ '찾다', 'to look for; to find; to withdraw (money)', '137', '' ],
[ '비', 'rain; broom', '138', '' ],
[ '숙제', 'homework', '139', '宿題' ],
[ '아니다', 'to not be', '140', '' ],
[ '공책', 'notebook', '141', '空冊' ],
[ '햄버거', 'hamburger', '142', '' ],
[ '전화하다', 'to make a phone call', '143', '電話' ],
[ '가게', 'shop, store', '144', '' ],
[ '배', 'pear; stomach; boat', '145', '' ],
[ '피곤하다', 'to be tired', '146', '疲困' ],
[ '저녁', 'evening; dinner', '147', '' ],
[ '금요일', 'Friday', '148', '金曜日' ],
[ '일어나다', 'to wake up, get up; to happen', '149', '' ],
[ '날씨', 'weather', '150', '' ],
[ '번', 'time (first, second...)', '151', '番' ],
[ '타다', 'to ride, get in; to burn, be tanned', '152', '' ],
[ '자주', 'often', '153', '' ],
[ '공원', 'park', '154', '公園' ],
[ '만들다', 'to make', '155', '' ],
[ '바지', 'pants', '156', '' ],
[
  '형',
  "a man's older brother; type, style; punishment",
  '157',
  '兄, 型, 刑'
],
[ '일하다', 'to work', '158', '' ],
[ '자동차', 'car', '159', '自動車' ],
[ '고기', 'meat', '160', '' ],
[ '그렇다', 'to be so (like that)', '161', '' ],
[ '은행', 'bank', '162', '銀行' ],
[ '더', 'more', '163', '' ],
[ '청소하다', 'to clean up, to vacuum', '164', '淸掃' ],
[ '다음', 'the next, the following', '165', '' ],
[ '독일', 'Germany', '166', '獨逸' ],
[ '배우다', 'to learn', '167', '' ],
[ '아이', 'child', '168', '' ],
[ '안녕히', 'peacefully; safely', '169', '安寧' ],
[ '끝나다', 'to end, be over', '170', '' ],
[ '개', 'dog; thing (count.)', '171', '' ],
[ '토요일', 'Saturday', '172', '土曜日' ],
[ '기다리다', 'to wait', '173', '' ],
[ '김밥', 'Korean dried seaweed rice rolls', '174', '' ],
[ '이번', 'this time', '175', '-番' ],
[ '나쁘다', 'to be bad', '176', '' ],
[ '밤', 'night; chestnut', '177', '' ],
[ '여행', 'trip, travel', '178', '旅行' ],
[ '다니다', 'to go (frequently), to attend, work for', '179', '' ],
[ '너무', 'too (much)', '180', '' ],
[ '부모님', 'parents', '181', '父母-' ],
[ '재미없다', 'to be boring, dull', '182', '' ],
[ '텔레비전', 'TV', '183', '' ],
[ '사진', 'picture, photo', '184', '寫眞' ],
[ '받다', 'to receive, accept; to take', '185', '' ],
[ '병원', 'hospital', '186', '病院' ],
[ '딸', 'daughter', '187', '' ],
[ '도착하다', 'to arrive', '188', '到着' ],
[ '나라', 'country, nation', '189', '' ],
[ '점심', 'lunch; afternoon', '190', '點心' ],
[ '싸다', 'to be inexpensive, cheap; to wrap up, pack', '191', '' ],
[ '얼마', 'how much (money)', '192', '' ],
[ '일요일', 'Sunday', '193', '日曜日' ],
[ '돕다', 'to help', '194', '' ],
[ '앞', 'the front', '195', '' ],
[ '주말', 'weekend', '196', '週末' ],
[ '다르다', 'to be different', '197', '' ],
[ '그래서', 'therefore', '198', '' ],
[ '선물', 'present, gift', '199', '膳物' ],
[ '생각하다', 'to think', '200', '' ],
[ '빵', 'bread', '201', '' ],
[ '약속', 'appointment; promise', '202', '約束' ],
[ '싫다', 'to be unlikable, to not like', '203', '' ],
[ '거기', 'there', '204', '' ],
[ '음식', 'food', '205', '飮食' ],
[ '나오다', 'to come out', '206', '' ],
[ '아들', 'son', '207', '' ],
[ '도서관', 'library', '208', '圖書館' ],
[ '길다', 'to be long', '209', '' ],
[ '다', 'all', '210', '' ],
[ '문제', 'problem, question', '211', '問題' ],
[ '웃다', 'to laugh, smile', '212', '' ],
[ '아이스크림', 'ice cream', '213', '' ],
[ '일주일', 'one week', '214', '一週日' ],
[ '좋아하다', 'to like', '215', '' ],
[ '명', 'counter for people', '216', '名' ],
[ '고양이', 'cat', '217', '' ],
[ '수영하다', 'to swim', '218', '水泳' ],
[ '이쪽으로', 'this way, over here', '219', '' ],
[ '인사', 'greetings', '220', '人事' ],
[ '늦다', 'to be late', '221', '' ],
[ '몇', 'how many', '222', '' ],
[ '시장', 'market; mayor', '223', '市場, 市長' ],
[
  '찍다',
  'to take a picture; to mark, stamp, dip into, chop',
  '224',
  ''
],
[ '하지만', 'but, though', '225', '' ],
[ '시험', 'exam, test', '226', '試驗' ],
[ '맞다', 'to be correct; to be hit, struck', '227', '' ],
[ '치마', 'skirt', '228', '' ],
[ '시', "poem; time, hour; o'clock", '229', '詩, 時' ],
[ '못하다', 'to be unable to do', '230', '' ],
[ '요즘', 'these days', '231', '' ],
[ '화장실', 'toilet, bathroom', '232', '化粧室' ],
[ '멀다', 'to be far', '233', '' ],
[ '수업', 'class, instruction', '234', '受業·授業' ],
[ '무슨', 'which/what (+ noun)', '235', '' ],
[ '운전하다', 'to drive', '236', '運轉' ],
[ '프랑스', 'France', '237', '' ],
[ '월', 'month', '238', '月' ],
[ '많다', 'to be many, to be plenty', '239', '' ],
[ '나이', 'age', '240', '' ],
[ '매일', 'every day', '241', '每日' ],
[ '부르다', 'to call, to call out', '242', '' ],
[ '마음', 'mind, heart, nature', '243', '' ],
[ '포도', 'grape', '244', '葡萄' ],
[ '반갑다', 'to be glad, happy, pleased', '245', '' ],
[ '안', 'inside, within; not; proposal', '246', '案' ],
[ '지하철', 'subway', '247', '地下鐵' ],
[ '닫다', 'to close', '248', '' ],
[ '우리', 'we (informal)', '249', '' ],
[ '시계', 'clock, watch', '250', '時計' ],
[ '짧다', 'to be short', '251', '' ],
[ '다시', 'again', '252', '' ],
[ '분', 'minute; person (hon.)', '253', '分' ],
[ '지내다', 'to spend time; to pursue; to associate with', '254', '' ],
[ '얼마나', 'how + adjective/adverb', '255', '' ],
[ '식당', 'restaurant, cafeteria, diner', '256', '食堂' ],
[ '덥다', 'to be hot (regarding the weather)', '257', '' ],
[ '혼자', 'alone', '258', '' ],
[ '오월', 'May', '259', '五月' ],
[ '쉬다', 'to rest, take a rest', '260', '' ],
[ '꽃', 'flower', '261', '' ],
[ '정말', 'really, seriously', '262', '正-' ],
[ '고맙다', 'to be thankful, grateful', '263', '' ],
[ '피자', 'pizza', '264', '' ],
[ '대답', 'answer', '265', '對答' ],
[ '되다', 'to become, get, turn into', '266', '' ],
[ '손', 'hand', '267', '' ],
[ '취미', 'hobby', '268', '趣味' ],
[ '가깝다', 'to be close', '269', '' ],
[ '그래', 'yes, okay', '270', '' ],
[ '자전거', 'bicycle', '271', '自轉車' ],
[
  '내리다',
  'to come down, put down; to get out of; to take off',
  '272',
  ''
],
[ '옆', 'beside, next to', '273', '' ],
[ '유월', 'June', '274', '六月' ],
[ '열다', 'to open', '275', '' ],
[ '보통', 'usually, normally', '276', '普通' ],
[ '저쪽', 'that side, over there', '277', '' ],
[ '중요하다', 'to be important', '278', '重要' ],
[ '술', 'alcoholic drink', '279', '' ],
[ '신문', 'newspaper', '280', '新聞' ],
[ '말다', 'to not do', '281', '' ],
[ '신발', 'shoes, footwear', '282', '' ],
[ '백화점', 'department store', '283', '百貨店' ],
[ '같다', 'to be the same (as); to look like, seem like', '284', '' ],
[ '컴퓨터', 'computer', '285', '' ],
[ '감기', 'cold (the illness)', '286', '感氣' ],
[ '적다', 'to write down, fill out; to be small in quantity', '287', '' ],
[ '곳', 'place', '288', '' ],
[ '직업', 'job, occupation', '289', '職業' ],
[ '잘하다', 'to do well, be skillful', '290', '' ],
[ '나중에', 'later', '291', '' ],
[ '의사', 'doctor, physician; intention, idea', '292', '醫師, 意思' ],
[ '괜찮다', 'to be okay', '293', '' ],
[ '과일', 'fruit', '294', '' ],
[ '극장', 'movie theater; theater', '295', '劇場' ],
[ '가르치다', 'to teach', '296', '' ],
[ '새', 'new; bird', '297', '' ],
[ '고향', 'hometown', '298', '故鄕' ],
[ '맵다', 'to be spicy, hot; to be intense', '299', '' ],
[ '일월', 'January', '300', '一月' ],
[ '그런데', 'by the way, but then', '301', '' ],
[ '결혼하다', 'to get married', '302', '結婚' ],
[ '잠', 'sleep, nap', '303', '' ],
[ '사전', 'dictionary', '304', '辭典' ],
[ '나가다', 'to go out', '305', '' ],
[ '언니', 'older sister (of a female)', '306', '' ],
[ '냉장고', 'refrigerator', '307', '冷藏庫' ],
[ '여러분', 'everyone, ladies and gentlemen', '308', '' ],
[ '따뜻하다', 'to be warm, kind', '309', '' ],
[ '공항', 'airport', '310', '空港' ],
[ '그런', 'such, that kind of', '311', '' ],
[ '씻다', 'to wash', '312', '' ],
[ '거리', 'distance; street, road', '313', '距離' ],
[ '오렌지', 'orange', '314', '' ],
[ '태어나다', 'to be born', '315', '' ],
[ '침대', 'bed', '316', '寢臺' ],
[ '때', 'time; when; occasion; dirt', '317', '' ],
[ '힘들다', 'to be difficult, tiring, painful', '318', '' ],
[ '이월', 'February', '319', '二月' ],
[ '천천히', 'slowly', '320', '' ],
[ '놓다', 'to lay, put, place; to let go', '321', '' ],
[ '영국', 'United Kingdom', '322', '英國' ],
[ '달', 'month, moon', '323', '' ],
[ '춤추다', 'to dance', '324', '' ],
[ '창문', 'window', '325', '窓門' ],
[ '누나', 'older sister (of a male)', '326', '' ],
[ '슬프다', 'to be sad', '327', '' ],
[ '열심히', 'diligently, passionately', '328', '熱心' ],
[ '그림', 'picture', '329', '' ],
[ '잡다', 'to catch, grab, take', '330', '' ],
[ '씨', 'Mr., Ms.; seed, kernel', '331', '氏' ],
[ '늦게', 'late', '332', '' ],
[ '걱정하다', 'to worry', '333', '' ],
[ '가수', 'singer', '334', '歌手' ],
[ '여름', 'summer', '335', '' ],
[ '팔다', 'to sell', '336', '' ],
[ '잔', 'cup, glass', '337', '盞' ],
[ '엄마', 'mom', '338', '' ],
[ '깨끗하다', 'to be clean', '339', '' ],
[ '모자', 'hat, cap; mother and son', '340', '帽子, 母子' ],
[ '카메라', 'camera', '341', '' ],
[ '울다', 'to cry', '342', '' ],
[ '준비', 'preparation, arrangement', '343', '準備' ],
[ '속', 'inside', '344', '' ],
[ '구두', 'dress shoes', '345', '' ],
[ '축하하다', 'to congratulate', '346', '祝賀' ],
[ '불고기', 'marinated beef', '347', '' ],
[ '어떤', 'some, certain; what kind of', '348', '' ],
[ '삼월', 'March', '349', '三月' ],
[ '걷다', 'to walk', '350', '' ],
[ '나', 'I (informal)', '351', '' ],
[ '태권도', 'Taekwondo', '352', '跆拳道' ],
[ '뜨겁다', 'to be hot (regarding objects)', '353', '' ],
[ '아빠', 'dad', '354', '' ],
[ '작년', 'last year', '355', '昨年' ],
[ '보내다', 'to send; to pass (spend) time', '356', '' ],
[ '조금', 'a little', '357', '' ],
[ '오전', 'before noon', '358', '午前' ],
[ '여동생', 'younger sister', '359', '女--' ],
[ '세수하다', "to wash one's face", '360', '洗手' ],
[ '힘', 'power, strength, force, capability', '361', '' ],
[ '일찍', 'early', '362', '' ],
[ '편지', 'letter, note', '363', '便紙·片紙' ],
[ '맛없다', 'to be tasteless, unsavory', '364', '' ],
[
  '무엇',
  'what, which, something (the unshortened form of 뭐)',
  '365',
  ''
],
[ '서점', 'bookstore', '366', '書店' ],
[ '걸리다', 'to take time; to hang; to be caught', '367', '' ],
[ '아직', 'yet, still', '368', '' ],
[ '맥주', 'beer', '369', '麥酒' ],
[ '싫어하다', 'to dislike', '370', '' ],
[ '또', 'again, repeatedly; also, too', '371', '' ],
[ '사월', 'April', '372', '四月' ],
[ '치다', 'to play a game; to hit, attack', '373', '' ],
[ '초콜릿', 'chocolate', '374', '' ],
[ '제일', 'the most', '375', '第一' ],
[ '남동생', 'younger brother', '376', '男--' ],
[ '시원하다', 'to be cool, refreshing', '377', '' ],
[ '그분', 'he/she', '378', '' ],
[ '병', 'bottle; sickness, disease', '379', '甁' ],
[ '올해', 'this year', '380', '' ],
[ '내다', 'to pay; to hand in; to produce', '381', '' ],
[ '오후', 'after noon', '382', '午後' ],
[ '겨울', 'winter', '383', '' ],
[ '생기다', 'to occur, happen; to look; to form', '384', '' ],
[ '층', 'floor (count.)', '385', '層' ],
[ '얼굴', 'face', '386', '' ],
[ '조용하다', 'to be quiet', '387', '' ],
[ '한번', 'one time', '388', '-番' ],
[ '얘기', 'story; talk, conversation', '389', '' ],
[ '모두', 'all together, everyone', '390', '' ],
[ '소개하다', 'to introduce, recommend', '391', '紹介' ],
[ '가끔', 'sometimes', '392', '' ],
[ '검은색', 'black', '393', '' ],
[ '역', 'train station; part in a play', '394', '驛, 役' ],
[ '짜다', 'to organize, construct; to be salty', '395', '' ],
[ '생선', 'fish (for eating)', '396', '生鮮' ],
[ '오래', 'a long time', '397', '' ],
[ '휴일', 'public holiday', '398', '休日' ],
[ '버리다', 'to abandon, to throw away', '399', '' ],
[ '위', 'above; stomach', '400', '胃' ],
[ '먼저', 'first, ahead', '401', '' ],
[ '건강하다', 'to be healthy', '402', '健康' ],
[ '할머니', 'grandmother', '403', '' ],
[ '칠월', 'July', '404', '七月' ],
[ '가지다', 'to posess (have)', '405', '' ],
[ '샌드위치', 'sandwich', '406', '' ],
[ '동물', 'animal', '407', '動物' ],
[ '똑같다', 'to be exactly the same', '408', '' ],
[ '키', 'height', '409', '' ],
[ '남편', 'husband', '410', '男便' ],
[ '서다', 'to stand; to stop', '411', '' ],
[ '그렇지만', 'but, however', '412', '' ],
[ '계획', 'plan, project', '413', '計劃' ],
[ '잘못하다', 'to make a mistake', '414', '' ],
[ '사무실', 'office', '415', '事務室' ],
[ '너', 'you (informal)', '416', '' ],
[ '벌써', 'already', '417', '' ],
[ '필요하다', 'to need', '418', '必要' ],
[ '흰색', 'white', '419', '' ],
[ '부엌', 'kitchen', '420', '' ],
[ '잠시', 'a short while', '421', '暫時' ],
[ '들어가다', 'to enter, go in', '422', '' ],
[ '안경', 'glasses, eyeglasses', '423', '眼鏡' ],
[ '봄', 'spring', '424', '' ],
[ '남학생', 'male student', '425', '男學生' ],
[ '놀라다', 'to be surprised, astonished, startled', '426', '' ],
[ '팔월', 'August', '427', '八月' ],
[ '이런', 'such', '428', '' ],
[ '생활', 'life, living, livelihood', '429', '生活' ],
[ '계시다', 'to exist (hon.)', '430', '' ],
[ '수박', 'watermelon', '431', '' ],
[ '교실', 'classroom', '432', '敎室' ],
[ '살', 'years of age (count.); flesh, muscle', '433', '' ],
[ '미안하다', 'to be sorry (less formal)', '434', '未安' ],
[ '파티', 'party (social gathering)', '435', '' ],
[ '색깔', 'color', '436', '色-' ],
[ '끝내다', 'to finish (something)', '437', '' ],
[ '우체국', 'post office', '438', '郵遞局' ],
[ '여러', 'many, several', '439', '' ],
[ '축구', 'football (soccer)', '440', '蹴球' ],
[
  '달다',
  'to be sweet; to put up, attach; to weigh, measure',
  '441',
  ''
],
[ '산', 'mountain', '442', '山' ],
[ '어느', 'which', '443', '' ],
[ '배우', 'actor', '444', '俳優' ],
[
  '구경하다',
  'to go sightseeing, look around; to see, watch (a show, movie, play, sports game etc.)',
  '445',
  ''
],
[ '할아버지', 'grandfather', '446', '' ],
[ '냉면', 'cold noodles', '447', '冷麵' ],
[ '밑', 'the bottom, below', '448', '' ],
[ '유명하다', 'to be famous', '449', '有名' ],
[ '처음', 'for the first time; the origin', '450', '' ],
[ '구월', 'September', '451', '九月' ],
[ '왼쪽', 'left side', '452', '' ],
[ '설명하다', 'to explain', '453', '說明' ],
[ '밖', 'the outside', '454', '' ],
[ '잡지', 'magazine, journal', '455', '雜誌' ],
[ '비슷하다', 'to be similar', '456', '' ],
[ '외국', 'foreign country', '457', '外國' ],
[ '며칠', 'several days; day of the month', '458', '' ],
[ '청바지', 'blue jeans', '459', '靑--' ],
[ '들어오다', 'to come in, enter', '460', '' ],
[ '반', 'half; group, class', '461', '半, 班' ],
[ '택시', 'taxi', '462', '' ],
[ '진짜', 'really, very; real thing', '463', '眞-' ],
[ '아름답다', 'to be beautiful', '464', '' ],
[ '식사', 'meal', '465', '食事' ],
[ '동안', 'during, interval', '466', '' ],
[ '사용하다', 'to use, make use of', '467', '使用' ],
[ '뒤', 'behind', '468', '' ],
[ '시월', 'October', '469', '十月' ],
[
  '밝다',
  'to be bright, light; to be promising; to be sharp, keen',
  '470',
  ''
],
[ '교수', 'professor; teaching, instruction', '471', '敎授' ],
[ '마지막', 'the last, final one', '472', '' ],
[ '계속하다', 'to continue', '473', '繼續' ],
[ '맛', 'taste, flavor', '474', '' ],
[ '양말', 'socks', '475', '洋襪' ],
[ '가을', 'autumn', '476', '' ],
[ '초대하다', 'to invite', '477', '招待' ],
[ '비빔밥', 'boiled rice with assorted mixtures', '478', '' ],
[ '주소', 'address, residence', '479', '住所' ],
[ '빠르다', 'to be quick, fast', '480', '' ],
[ '물건', 'thing, object, article', '481', '物件' ],
[ '길', 'street, road', '482', '' ],
[ '여학생', 'female student', '483', '女學生' ],
[
  '들다',
  'to be included, be among; to pick up, hold, carry; to mention, state',
  '484',
  ''
],
[ '이렇게', 'like this, in this way', '485', '' ],
[ '도시', 'city', '486', '都市' ],
[ '아내', 'wife', '487', '' ],
[ '연습하다', 'to practice, train', '488', '練習' ],
[ '캐나다', 'Canada', '489', '' ],
[ '오른쪽', 'right side', '490', '' ],
[ '약국', 'pharmacy', '491', '藥局' ],
[ '도와주다', 'to help out, assist', '492', '' ],
[ '강', 'river', '493', '江' ],
[ '파란색', 'blue', '494', '' ],
[ '지갑', 'wallet; purse', '495', '紙匣' ],
[ '높다', 'to be high; to be noble', '496', '' ],
[ '십일월', 'November', '497', '十一月' ],
[ '모든', 'all; every', '498', '' ],
[ '이해하다', 'to understand, grasp', '499', '理解' ],
[ '팔', 'arm', '500', '' ],
[ '항상', 'always', '501', '恒常' ],
[ '잊다', 'to forget', '502', '' ],
[ '기숙사', 'dormitory', '503', '寄宿舍' ],
[ '라면', 'ramen', '504', '' ],
[ '출발하다', 'to depart, leave', '505', '出發' ],
[ '값', 'price, cost', '506', '' ],
[ '거실', 'living room', '507', '居室' ],
[ '무겁다', 'to be heavy', '508', '' ],
[ '외국어', 'foreign language', '509', '外國語' ],
[ '앞으로', 'from now on', '510', '' ],
[ '실례하다', 'to be excused', '511', '失禮' ],
[ '함께', 'together, in company with', '512', '' ],
[ '십이월', 'December', '513', '十二月' ],
[ '켜다', 'to turn on, switch on', '514', '' ],
[ '아줌마', 'middle aged woman', '515', '' ],
[ '전', 'before, prior to', '516', '前' ],
[ '쇼핑', 'shopping', '517', '' ],
[ '복잡하다', 'to be crowded; to be complicated', '518', '複雜' ],
[ '그렇게', 'so, like that', '519', '' ],
[ '대학생', 'college student', '520', '大學生' ],
[ '지난주', 'last week', '521', '--週' ],
[ '바꾸다', 'to change, switch, alter', '522', '' ],
[ '화', 'anger; disaster, misfortune', '523', '火, 禍' ],
[ '바로', 'right there; directly', '524', '' ],
[ '죽다', 'to die', '525', '' ],
[ '학년', 'school year', '526', '學年' ],
[ '사이', 'relationship; distance apart', '527', '' ],
[ '친절하다', 'to be kind, considerate', '528', '親切' ],
[ '그러니까', 'therefore, consequently', '529', '' ],
[ '예', 'example; yes', '530', '例' ],
[ '내려오다', 'to come down, get off', '531', '' ],
[ '설탕', 'sugar', '532', '雪糖·屑糖' ],
[ '아마', 'probably, maybe', '533', '' ],
[ '졸업하다', 'to graduate', '534', '卒業' ],
[ '이곳', 'this place, here', '535', '' ],
[ '수건', 'towel', '536', '手巾' ],
[ '차갑다', 'to be cold (regarding objects)', '537', '' ],
[ '약', 'drug, medicine; approximately', '538', '藥, 約' ],
[ '아래', 'under', '539', '' ],
[ '건강', 'health', '540', '健康' ],
[ '그리다', 'to draw a picture', '541', '' ],
[ '개월', 'months (count.)', '542', '個月' ],
[ '언제나', 'always; usually', '543', '' ],
[ '빨갛다', 'to be red', '544', '' ],
[ '방학', 'vacation (from school)', '545', '放學' ],
[ '컵', 'cup', '546', '' ],
[
  '세우다',
  'to make something stand, erect; to stop, park; to set (up)',
  '547',
  ''
],
[ '부산', 'Busan', '548', '釜山' ],
[ '그때', 'then, at that time', '549', '' ],
[ '사실', 'fact, truth', '550', '事實' ],
[ '넓다', 'to be wide, spacious', '551', '' ],
[ '휴지', 'toilet paper, tissue; pause', '552', '休紙, 休止' ],
[ '떡', 'Korean rice cake', '553', '' ],
[ '출근하다', 'to go (get) to work', '554', '出勤' ],
[ '아주', 'very much', '555', '' ],
[ '번호', 'number', '556', '番號' ],
[ '어떻다', 'to be the manner in which something is done', '557', '' ],
[ '기분', 'mood, feeling', '558', '氣分' ],
[ '몸', 'body, figure', '559', '' ],
[ '내년', 'next year', '560', '來年' ],
[ '다녀오다', 'to go and get back, be back', '561', '' ],
[ '운동장', 'field, (school) stadium; playground', '562', '運動場' ],
[ '아기', 'baby', '563', '' ],
[ '위험하다', 'to be dangerous, risky', '564', '危險' ],
[ '공', 'ball', '565', '' ],
[ '계속', 'continuously', '566', '繼續' ],
[ '드리다', 'to give, offer (hon.)', '567', '' ],
[ '경찰', 'police, police officer', '568', '警察' ],
[ '그릇', 'bowl, container', '569', '' ],
[ '떠나다', 'to leave, depart', '570', '' ],
[ '식탁', 'dining table', '571', '食卓' ],
[ '목', 'neck; throat', '572', '' ],
[ '즐겁다', 'to be pleasant, joyful, cheerful, happy', '573', '' ],
[ '요일', 'day of the week', '574', '曜日' ],
[ '버스', 'bus', '575', '' ],
[ '내려가다', 'to go down', '576', '' ],
[ '방법', 'way, method', '577', '方法' ],
[ '갈비', 'barbecued spare ribs', '578', '' ],
[ '낮다', 'to be low', '579', '' ],
[ '한국말', 'Korean language', '580', '韓國-' ],
[ '꼭', 'certainly, for sure', '581', '' ],
[ '닦다', 'to wipe; to polish', '582', '' ],
[ '기차', 'train', '583', '汽車' ],
[ '첫째', 'first, the foremost', '584', '' ],
[ '화나다', 'to be angry, mad', '585', '火-' ],
[ '손님', 'guest; customer', '586', '' ],
[ '근처', 'nearby', '587', '近處' ],
[ '가볍다', 'to be light (as opposed to heavy)', '588', '' ],
[ '비행기', 'airplane', '589', '飛行機' ],
[ '그쪽', 'that, that way; you', '590', '' ],
[ '여자친구', 'girlfriend', '591', '女子親舊' ],
[ '신다', 'to put on, wear (footwear)', '592', '' ],
[ '과자', 'cookie, snack', '593', '菓子' ],
[ '불', 'fire; light', '594', '' ],
[ '오르다', 'to rise, go up', '595', '' ],
[ '계절', 'season', '596', '季節' ],
[ '나무', 'tree; wood', '597', '' ],
[ '똑똑하다', 'to be smart, bright, intelligent', '598', '' ],
[ '건물', 'building', '599', '建物' ],
[ '게임', 'game', '600', '' ],
[ '끄다', 'to put out, turn off', '601', '' ],
[ '인기', 'popularity', '602', '人氣' ],
[ '코', 'nose', '603', '' ],
[ '가져오다', 'to bring', '604', '' ],
[ '박물관', 'museum', '605', '博物館' ],
[ '지난달', 'last month', '606', '' ],
[ '배고프다', 'to be hungry', '607', '' ],
[ '교회', 'church', '608', '敎會' ],
[ '발', 'foot', '609', '' ],
[ '날다', 'to fly', '610', '' ],
[ '외국인', 'foreigner', '611', '外國人' ],
[ '아니', 'no, not', '612', '' ],
[ '잊어버리다', 'to forget (completely)', '613', '' ],
[ '야구', 'baseball', '614', '野球' ],
[ '못', 'nail; cannot', '615', '' ],
[ '행복하다', 'to be happy', '616', '幸福' ],
[ '찌개', 'stew', '617', '' ],
[ '정류장', 'stop, station (bus etc.)', '618', '停留場' ],
[ '넣다', 'to put in, insert', '619', '' ],
[ '군인', 'military personnel, soldier', '620', '軍人' ],
[ '오랜만에', 'after a long time', '621', '' ],
[ '연락하다', 'to contact, keep in touch', '622', '連絡' ],
[ '바다', 'sea, ocean', '623', '' ],
[ '지도', 'map, chart', '624', '地圖' ],
[ '파랗다', 'to be blue', '625', '' ],
[ '간호사', 'nurse', '626', '看護師' ],
[ '입', 'mouth', '627', '' ],
[ '농담', 'joke', '628', '弄談' ],
[ '샤워하다', 'to take a shower', '629', '' ],
[ '역사', 'history', '630', '歷史' ],
[ '가장', 'the most (more formal than 제일)', '631', '' ],
[ '조심하다', 'to be careful, watch out', '632', '操心' ],
[ '내', 'my (informal)', '633', '' ],
[ '양복', 'suit', '634', '洋服' ],
[ '귀엽다', 'to be cute', '635', '' ],
[ '대학', 'university', '636', '大學' ],
[ '잠깐', 'for a little while', '637', '' ],
[ '퇴근하다', 'to go home from work', '638', '退勤' ],
[ '닭', 'chicken', '639', '' ],
[ '북쪽', 'north', '640', '北-' ],
[ '깎다', 'to peel, trim, cut; to discount (cut the price)', '641', '' ],
[ '등산', 'hiking, mountain climbing', '642', '登山' ],
[ '어서', 'please, kindly; quickly', '643', '' ],
[ '강하다', 'to be strong, powerful', '644', '强' ],
[ '젓가락', 'chopsticks', '645', '' ],
[ '제주도', 'Jeju Island', '646', '濟州島' ],
[ '드시다', 'to eat, drink (hon.)', '647', '' ],
[ '해', 'year; sun; harm', '648', '害' ],
[ '갑자기', 'suddenly', '649', '' ],
[ '부탁하다', 'to request, ask a favor', '650', '付託' ],
[ '다리', 'leg; bridge', '651', '' ],
[ '대화', 'conversation', '652', '對話' ],
[ '잘생기다', 'to look good, be handsome', '653', '' ],
[ '교과서', 'textbook', '654', '敎科書' ],
[ '콜라', 'coke', '655', '' ],
[ '주문하다', 'to order (smth.)', '656', '注文' ],
[ '종이', 'paper', '657', '' ],
[ '한국어', 'Korean (language)', '658', '韓國語' ],
[ '잃다', 'to lose (something)', '659', '' ],
[ '반바지', 'shorts', '660', '半--' ],
[ '꿈', 'dream', '661', '' ],
[ '계란', 'egg', '662', '鷄卵' ],
[ '궁금하다', 'to be curious, to wonder', '663', '' ],
[ '고등학교', 'high school', '664', '高等學校' ],
[ '이제', 'now (after something)', '665', '' ],
[ '뛰다', 'to jump; to run, dash', '666', '' ],
[ '목욕', 'bath', '667', '沐浴' ],
[ '곧', 'soon', '668', '' ],
[ '보이다', 'to be visible; to show, display', '669', '' ],
[ '동쪽', 'the east', '670', '東-' ],
[ '자리', 'room, space, place (for something); mat', '671', '' ],
[ '어리다', 'to be young, little', '672', '' ],
[ '경치', 'scene, scenery', '673', '景致' ],
[ '낮', 'daytime', '674', '' ],
[ '돌아가다', 'to go back, return; to work, operate', '675', '' ],
[ '한복', 'Korean dress (traditional)', '676', '韓服' ],
[ '소파', 'sofa', '677', '' ],
[ '안다', 'to hug, hold', '678', '' ],
[ '사탕', 'candy', '679', '砂糖' ],
[ '옷장', 'wardrobe, closet', '680', '' ],
[ '하얗다', 'to be white', '681', '' ],
[ '댁', 'house (hon.)', '682', '宅' ],
[ '저희', 'we, our', '683', '' ],
[ '싸우다', 'to argue, fight', '684', '' ],
[ '사장', 'president of a company', '685', '社長' ],
[ '아무도', 'no one, nobody', '686', '' ],
[ '피우다', 'to smoke;to bloom, blossom; to make a fire', '687', '' ],
[ '교통', 'traffic', '688', '交通' ],
[ '칼', 'knife; sword', '689', '' ],
[ '시끄럽다', 'to be noisy', '690', '' ],
[ '지하', 'underground, basement', '691', '地下' ],
[ '바람', 'wind, breeze; wish, desire', '692', '' ],
[ '걸어가다', 'to walk (somewhere)', '693', '' ],
[ '세탁기', 'washing machine', '694', '洗濯機' ],
[ '슈퍼마켓', 'supermarket', '695', '' ],
[ '벌다', 'to earn (money)', '696', '' ],
[ '중학교', 'middle school (junior high)', '697', '中學校' ],
[ '별', 'star', '698', '' ],
[ '맑다', 'to be clear, clean', '699', '' ],
[ '주인', 'owner, employer', '700', '主人' ],
[ '남자친구', 'boyfriend', '701', '男子親舊' ],
[ '묻다', 'to ask; to stick to, be stained; to bury', '702', '' ],
[ '유학', 'studying abroad; Confucianism', '703', '留學, 儒學' ],
[ '자장면', 'Chinese dish of noodles in black soybean paste', '704', '' ],
[ '장소', 'place, location', '705', '場所' ],
[ '불다', 'to blow', '706', '' ],
[ '한글', 'Korean alphabet', '707', '' ],
[
  '장',
  'counter for sheets (of paper or similar material); intestine',
  '708',
  '張, 腸'
],
[ '낫다', 'to be better; to get better, to heal', '709', '' ],
[ '아주머니', 'middle aged woman; aunt', '710', '' ],
[ '남녀', 'men and women', '711', '男女' ],
[ '말씀하시다', 'to speak, talk (hon.)', '712', '' ],
[ '날짜', 'date (in a calendar)', '713', '' ],
[ '비밀', 'secret', '714', '秘密' ],
[ '믿다', 'to believe, trust', '715', '' ],
[ '아파트', 'apartment', '716', '' ],
[ '표', 'ticket; table, diagram; mark, sign', '717', '票, 表' ],
[ '더럽다', 'to be dirty', '718', '' ],
[ '돼지', 'pig', '719', '' ],
[ '중에서', 'among, between', '720', '中-' ],
[ '지나다', 'to pass, go by', '721', '' ],
[ '소금', 'salt', '722', '' ],
[ '자신', 'oneself; self-confidence', '723', '自身, 自信' ],
[ '돌아오다', 'to come back, return', '724', '' ],
[ '귀', 'ear', '725', '' ],
[ '신호', 'signal', '726', '信號' ],
[ '지키다', 'to defend, protect', '727', '' ],
[ '어른', "adult; one's senior", '728', '' ],
[
  '이상',
  'more than, above; abnormality; ideal',
  '729',
  '以上, 異常·異狀, 理想'
],
[ '흐리다', 'to be cloudy', '730', '' ],
[ '문화', 'culture', '731', '文化' ],
[ '사실은', 'in fact', '732', '事實-' ],
[ '벗다', 'to take off (clothes)', '733', '' ],
[ '열쇠', 'key', '734', '' ],
[ '권', 'counter for books', '735', '卷' ],
[ '잘못되다', 'to go wrong', '736', '' ],
[ '러시아', 'Russia', '737', '' ],
[ '기억', 'memory', '738', '記憶' ],
[ '주무시다', 'to sleep (hon.)', '739', '' ],
[ '별로', 'not particularly', '740', '別-' ],
[ '남쪽', 'south', '741', '南-' ],
[
  '마르다',
  'to be dry, become dry; to become thin, lose weight',
  '742',
  ''
],
[ '손가락', 'finger', '743', '' ],
[ '한강', 'Han River', '744', '漢江' ],
[ '다치다', 'to get hurt', '745', '' ],
[ '딸기', 'strawberry', '746', '' ],
[ '휴지통', 'trash can, wastebasket', '747', '休紙桶' ],
[ '지우다', 'to erase, cross out; to wash off', '748', '' ],
[ '참', 'very, really', '749', '' ],
[ '회의', 'meeting, conference', '750', '會議' ],
[ '걸어오다', 'to come by foot', '751', '' ],
[ '즐겁게', 'pleasantly', '752', '' ],
[ '장미', 'rose', '753', '薔薇' ],
[ '노랗다', 'to be yellow', '754', '' ],
[ '방금', 'a moment ago', '755', '方今' ],
[ '경찰서', 'police station', '756', '警察署' ],
[ '올라가다', 'to go up', '757', '' ],
[ '직장', 'workplace, job', '758', '職場' ],
[ '팀', 'team', '759', '' ],
[ '변하다', 'to change', '760', '變-' ],
[ '왜냐하면', 'because, since', '761', '' ],
[ '전화번호', 'phone number', '762', '電話番號' ],
[ '남다', 'to remain, to be left', '763', '' ],
[ '실수', 'mistake, error', '764', '失手' ],
[ '닭고기', 'chicken (meat)', '765', '' ],
[ '남대문', 'the South Gate', '766', '南大門' ],
[ '기쁘다', 'to be glad, happy, pleased', '767', '' ],
[ '담배', 'cigarette', '768', '' ],
[ '초등학교', 'elementary school', '769', '初等學校' ],
[ '만지다', 'to touch', '770', '' ],
[ '경기', 'game (football etc.); economy, business', '771', '競技, 景氣' ],
[ '공휴일', 'legal holiday, bank holiday', '772', '公休日' ],
[ '헤어지다', 'to break up, part, separate', '773', '' ],
[ '말고', 'not ... but ...', '774', '' ],
[ '달력', 'calendar', '775', '-曆' ],
[ '무섭다', 'to be scary, frightening, fearful; to be scared', '776', '' ],
[ '원', 'won (Korean currency); circle', '777', '圓' ],
[ '허리', 'waist', '778', '' ],
[ '복습하다', 'to review', '779', '復習' ],
[ '티셔츠', 'T-shirt', '780', '' ],
[ '위험', 'danger, risk', '781', '危險' ],
[ '달리다', 'to run; to hang; to depend on', '782', '' ],
[ '운동화', 'sports shoes', '783', '運動靴' ],
[ '재미있게', 'interestingly, in a fun way', '784', '' ],
[ '편하다', 'to be convenient, comfortable', '785', '便' ],
[ '눈물', 'tear', '786', '' ],
[ '단어', 'word, vocabulary', '787', '單語' ],
[ '끊다', 'to cut off, break off', '788', '' ],
[ '소풍', 'picnic', '789', '消風' ],
[ '똑바로', 'straight, directly; honestly, frankly', '790', '' ],
[ '결혼식', 'wedding ceremony', '791', '結婚式' ],
[ '고르다', 'to choose, pick; to be regular, even', '792', '' ],
[ '칫솔', 'toothbrush', '793', '齒-' ],
[ '아무나', 'anybody', '794', '' ],
[
  '부인',
  '[your] wife (hon.); woman, lady; denial, disapproval',
  '795',
  '夫人, 婦人, 否認'
],
[ '착하다', 'to be nice (good-natured)', '796', '' ],
[ '환자', 'patient (medical)', '797', '患者' ],
[ '호텔', 'hotel', '798', '' ],
[ '등', 'the back; grade, class', '799', '等' ],
[ '수고하다', 'to work hard, keep up the effort', '800', '' ],
[ '서쪽', 'the west', '801', '西-' ],
[ '하늘', 'the sky', '802', '' ],
[ '소주', 'Korean hard liquor', '803', '燒酒' ],
[ '해보다', 'to try; to experience', '804', '' ],
[ '정도', 'degree, extent', '805', '程度' ],
[ '숟가락', 'spoon', '806', '' ],
[ '녹색', 'green', '807', '綠色' ],
[ '뚱뚱하다', 'to be fat', '808', '' ],
[ '칠판', 'blackboard', '809', '漆板' ],
[ '비누', 'soap', '810', '' ],
[ '대', 'counter for vehicles and other machinery', '811', '臺' ],
[ '짓다', 'to make, build, construct', '812', '' ],
[ '부탁', 'request', '813', '付託' ],
[ '매우', 'very, so, much', '814', '' ],
[ '수영장', 'swimming pool', '815', '水泳場' ],
[ '걸다', 'to hang, suspend; to speak to, call', '816', '' ],
[ '여권', 'passport', '817', '旅券' ],
[ '저런', 'such, like that', '818', '' ],
[ '부부', 'a married couple', '819', '夫婦' ],
[ '나타나다', 'to appear, turn up; to be revealed', '820', '' ],
[ '물론', 'of course', '821', '勿論' ],
[ '스키', 'skiing, skis', '822', '' ],
[ '일본어', 'Japanese (language)', '823', '日本語' ],
[ '멋있다', 'to be attractive, stylish, cool', '824', '' ],
[ '사고', 'accident; thinking', '825', '事故, 思考' ],
[ '가슴', 'chest', '826', '' ],
[ '편의점', 'convenience store', '827', '便宜店' ],
[ '빌리다', 'to borrow', '828', '' ],
[ '계단', 'stairs', '829', '階段' ],
[ '쌀', 'uncooked rice', '830', '' ],
[ '학원', 'educational institute, academy', '831', '學院' ],
[ '잡수시다', 'to eat (hon.)', '832', '' ],
[ '실례', 'bad manners, rudeness', '833', '失禮' ],
[ '마리', 'counter for animals', '834', '' ],
[ '느끼다', 'to feel, experience', '835', '' ],
[ '가구', 'furniture; household', '836', '家具, 家口' ],
[ '세상', 'the world, society', '837', '世上' ],
[ '어둡다', 'to be dark, gloomy', '838', '' ],
[ '장갑', 'glove', '839', '掌匣' ],
[ '오래간만', 'first time in a while', '840', '' ],
[ '산책하다', 'to take a walk', '841', '散策' ],
[ '스트레스', 'stress', '842', '' ],
[ '반지', 'ring', '843', '斑指' ],
[ '잃어버리다', 'to lose something (completely)', '844', '' ],
[ '치약', 'toothpaste', '845', '齒藥' ],
[ '어린이', 'young child, kid', '846', '' ],
[ '이사하다', 'to move (to live elsewhere)', '847', '移徙' ],
[ '그러나', 'but, though, however', '848', '' ],
[ '시내', 'the city, downtown; brook, stream', '849', '市內' ],
[
  '어울리다',
  'to match, fit, suit well, look good; to associate (with), get along (with)',
  '850',
  ''
],
[ '직접', 'directly, personally', '851', '直接' ],
[ '어깨', 'shoulder', '852', '' ],
[ '불편하다', 'to be inconvenient, uncomfortable', '853', '不便' ],
[ '거울', 'mirror', '854', '' ],
[ '만화', 'comic book', '855', '漫畵' ],
[ '나다', 'to appear, come out; to break', '856', '' ],
[ '벽', 'wall; characteristic', '857', '壁' ],
[ '까만색', 'black', '858', '' ],
[ '갖다', 'to have, to hold', '859', '' ],
[ '최고', 'the best, the highest, top', '860', '最高' ],
[ '이유', 'reason, cause', '861', '理由' ],
[ '자르다', 'to cut, slice', '862', '' ],
[ '지하철역', 'subway station', '863', '地下鐵驛' ],
[ '뉴스', 'news', '864', '' ],
[ '막히다', 'to be blocked, be stopped', '865', '' ],
[ '현재', 'the present (tense)', '866', '現在' ],
[ '성', 'family name; castle; gender', '867', '姓, 城, 性' ],
[ '튼튼하다', 'to be strong, solid, healthy', '868', '' ],
[ '농구', 'basketball', '869', '籠球' ],
[ '구름', 'cloud', '870', '' ],
[ '유행', 'fashion', '871', '流行' ],
[ '돌다', 'to turn, rotate, revolve', '872', '' ],
[ '노래방', 'karaoke room', '873', '--房' ],
[ '국수', 'noodles', '874', '' ],
[ '이모', "aunt (mother's sister)", '875', '姨母' ],
[ '이기다', 'to win', '876', '' ],
[ '주차장', 'parking lot', '877', '駐車場' ],
[ '아저씨', 'mister', '878', '' ],
[ '최근', 'lately, most recently', '879', '最近' ],
[
  '떨어지다',
  'to fall, crash; to be short of, run out; to be worn out',
  '880',
  ''
],
[ '학기', 'semester', '881', '學期' ],
[ '크리스마스', 'Christmas', '882', '' ],
[ '친하다', 'to be close, friendly, intimate with', '883', '親' ],
[ '옛날', 'old times, the past', '884', '' ],
[ '여성', 'women', '885', '女性' ],
[ '갔다오다', 'to go and come back', '886', '' ],
[ '열', 'fever, heat; row, line, queue', '887', '熱, 列' ],
[ '가운데', 'center', '888', '' ],
[ '약간', 'a little, slightly', '889', '若干' ],
[ '건너다', 'to cross (over)', '890', '' ],
[ '분위기', 'atmosphere, mood', '891', '雰圍氣' ],
[ '새벽', 'dawn', '892', '' ],
[ '홍차', 'black tea', '893', '紅茶' ],
[ '남기다', 'to leave, set aside', '894', '' ],
[ '노력', 'effort', '895', '努力' ],
[ '거의', 'almost', '896', '' ],
[ '애인', 'lover, sweetheart', '897', '愛人' ],
[ '심심하다', 'to be bored', '898', '' ],
[ '기자', 'reporter', '899', '記者' ],
[ '아까', 'a while ago', '900', '' ],
[ '시장보다', 'to go grocery shopping', '901', '市場' ],
[ '카드', 'card', '902', '' ],
[ '상품', 'product, goods; prize', '903', '商品, 賞品' ],
[ '즐기다', 'to enjoy oneself', '904', '' ],
[ '야채', 'vegetable', '905', '野菜' ],
[ '노란색', 'yellow', '906', '' ],
[ '취직하다', 'to get a job', '907', '就職' ],
[ '글', 'writing, a piece of writing, text', '908', '' ],
[ '평소에', 'usually, ordinarily', '909', '平素-' ],
[ '못생기다', 'to be ugly, unattractive', '910', '' ],
[ '삼촌', 'uncle', '911', '三寸' ],
[ '냄새', 'smell, scent', '912', '' ],
[ '결정하다', 'to decide', '913', '決定' ],
[ '서로', 'each other, mutually', '914', '' ],
[ '동네', 'neighborhood, village', '915', '洞-' ],
[ '굽다', 'to bake, roast, toast; to be crooked, be bent', '916', '' ],
[ '내용', 'contents, substance', '917', '內容' ],
[ '소리', 'sound, noise', '918', '' ],
[ '급하다', 'to be in a hurry, be urgent', '919', '急' ],
[ '라디오', 'radio', '920', '' ],
[ '예약', 'reservation, booking', '921', '豫約' ],
[ '외우다', 'to memorize', '922', '' ],
[ '우산', 'umbrella', '923', '雨傘' ],
[ '강아지', 'puppy', '924', '' ],
[ '이용하다', 'to use, take advantage of', '925', '利用' ],
[ '오이', 'cucumber', '926', '' ],
[ '소설', 'novel', '927', '小說' ],
[ '날씬하다', 'to be slim, slender', '928', '' ],
[
  '선배',
  'senior (often at the same education- or workplace)',
  '929',
  '先輩'
],
[ '거짓말', 'lie', '930', '' ],
[ '참다', 'to endure, bear', '931', '' ],
[ '남성', 'men, male; masculine gender', '932', '男性' ],
[ '만약', 'if', '933', '萬若' ],
[
  '사귀다',
  'to date, go out; to make friends with, keep company with',
  '934',
  ''
],
[ '의미', 'meaning, sense', '935', '意味' ],
[ '물고기', 'fish', '936', '' ],
[ '두껍다', 'to be thick, heavy', '937', '' ],
[ '특히', 'especially, particularly', '938', '特-' ],
[ '관심', 'interest, concern', '939', '關心' ],
[ '찾아가다', 'to visit', '940', '' ],
[ '예술', 'art', '941', '藝術' ],
[ '미터', 'meter', '942', '' ],
[ '시키다', 'to order, force someone', '943', '' ],
[ '성격', 'character, personality', '944', '性格' ],
[ '횡단보도', 'crosswalk', '945', '橫斷步道' ],
[ '익숙하다', 'to be familiar, used to something', '946', '' ],
[ '결과', 'result', '947', '結果' ],
[ '땀', 'sweat, perspiration', '948', '' ],
[ '던지다', 'to throw', '949', '' ],
[ '반찬', 'side dish', '950', '飯饌' ],
[ '배달', 'delivery', '951', '配達' ],
[ '기억나다', 'to remember, recollect', '952', '' ],
[ '일부', 'part, section, portion', '953', '一部' ],
[ '이미', 'already, yet; previously', '954', '' ],
[ '까맣다', 'to be black', '955', '' ],
[ '자식', "one's children", '956', '子息' ],
[ '지난번', 'last time, the other day', '957', '--番' ],
[ '얻다', 'to get, to gain', '958', '' ],
[ '자연', 'nature', '959', '自然' ],
[ '가지', 'kind (of), sort (of); branch, twig; eggplant', '960', '' ],
[
  '전하다',
  'to convey, communicate, report; to teach, introduce',
  '961',
  '傳'
],
[ '테니스', 'tennis', '962', '' ],
[ '중국어', 'Chinese (language)', '963', '中國語' ],
[ '깊다', 'to be deep', '964', '' ],
[ '휴가', 'vacation (in general)', '965', '休暇' ],
[ '쉽게', 'easily', '966', '' ],
[ '방송하다', 'to broadcast', '967', '放送' ],
[ '하루', '(one) day, 24 hours', '968', '' ],
[ '회사원', 'company employee (in an office)', '969', '會社員' ],
[ '가져가다', 'to take (along), carry', '970', '' ],
[ '해외', 'foreign country, overseas', '971', '海外' ],
[ '피', 'blood', '972', '' ],
[ '이상하다', 'to be strange (unusual)', '973', '異常·異狀' ],
[ '그대로', 'as [it] is, intactly', '974', '' ],
[ '과거', 'the past', '975', '過去' ],
[ '깨다', 'to break; to wake (up)', '976', '' ],
[ '선수', 'player, athlete', '977', '選手' ],
[ '감자', 'potato', '978', '' ],
[ '특별하다', 'to be special, extraordinary', '979', '特別' ],
[ '피아노', 'piano', '980', '' ],
[ '성함', 'name (hon.)', '981', '姓銜' ],
[ '바뀌다', 'to be changed, transformed', '982', '' ],
[ '이후', 'after this, in the future', '983', '以後' ],
[ '아가씨', 'miss, young lady', '984', '' ],
[ '따라하다', 'to imitate, repeat (after)', '985', '' ],
[ '종업원', 'waiter, waitress', '986', '從業員' ],
[ '출장', 'business trip', '987', '出張·出場' ],
[ '부럽다', 'to be jealous, envious', '988', '' ],
[ '화장품', 'cosmetics', '989', '化粧品' ],
[ '달걀', 'egg', '990', '' ],
[ '피다', 'to bloom, blossom; to burn', '991', '' ],
[ '인형', 'doll, puppet', '992', '人形' ],
[ '광고', 'advertisement', '993', '廣告' ],
[ '추워지다', 'to become cold', '994', '' ],
[ '추석', 'Korean harvest festival', '995', '秋夕' ],
[ '빨간색', 'red', '996', '' ],
[ '젊다', 'to be young, youthful', '997', '' ],
[ '남산', 'Namsan, Mt. Nam (in Seoul)', '998', '南山' ],
[ '고민', 'worry, trouble', '999', '苦悶' ],
[ '갖다주다', 'to bring over', '1000', '' ],
[ '시청', 'city hall; watching', '1001', '市廳, 視聽' ],
[ '메뉴', 'menu', '1002', '' ],
[ '열리다', 'to be open, be opened; to bear fruit', '1003', '' ],
[ '월급', 'monthly salary', '1004', '月給' ],
[ '간식', 'snack', '1005', '間食' ],
[ '부드럽다', 'to be soft, smooth', '1006', '' ],
[ '박수', 'handclapping, applause', '1007', '拍手' ],
[ '쪽', 'side, direction; page', '1008', '' ],
[ '선택하다', 'to choose, select', '1009', '選擇' ],
[ '느낌', 'feeling, sensation', '1010', '' ],
[ '요리사', 'cook, chef', '1011', '料理師' ],
[ '눕다', 'to lie down', '1012', '' ],
[ '주사', 'injection, shot', '1013', '注射' ],
[ '글쎄', 'well, let me see', '1014', '' ],
[ '불안하다', 'to be anxious, uneasy', '1015', '不安' ],
[ '접시', 'plate, dish', '1016', '' ],
[ '가격', 'price', '1017', '價格' ],
[
  '지다',
  'to lose, be defeated; to be under an obligation; to go down, fall',
  '1018',
  ''
],
[ '동대문', 'Dongdaemun, the East Gate', '1019', '東大門' ],
[ '그냥', 'just; without any special reason', '1020', '' ],
[ '유리', 'glass', '1021', '琉璃' ],
[ '가보다', 'to have been to a place', '1022', '' ],
[ '고모', "aunt (father's sister)", '1023', '姑母' ],
[ '넥타이', 'necktie', '1024', '' ],
[ '음료수', 'beverage, drink', '1025', '飮料水' ],
[ '얇다', 'to be thin, flimsy', '1026', '' ],
[ '발음', 'pronunciation', '1027', '發音' ],
[ '조용히', 'silently, quietly', '1028', '' ],
[ '초', 'seconds; candle', '1029', '秒' ],
[ '빼다', 'to pull out, extract; to subtract; to remove', '1030', '' ],
[ '상처', 'injury, wound, bruise', '1031', '傷處' ],
[ '애들', 'kids (short from 아이들)', '1032', '' ],
[ '호수', 'lake; number (of a house or room)', '1033', '號數' ],
[ '고치다', 'to fix, repair', '1034', '' ],
[ '대사관', 'embassy', '1035', '大使館' ],
[ '훨씬', 'by far, much', '1036', '' ],
[ '간단하다', 'to be simple, easy', '1037', '簡單' ],
[ '기침', 'cough', '1038', '' ],
[ '습관', 'habit', '1039', '習慣' ],
[ '사오다', 'to buy something (and bring it)', '1040', '' ],
[ '관광', 'tourism', '1041', '觀光' ],
[ '스포츠', 'sports', '1042', '' ],
[ '방문하다', 'to visit', '1043', '訪問' ],
[ '해마다', 'every year', '1044', '' ],
[ '연세', 'age (hon.)', '1045', '年歲' ],
[ '들리다', 'to be heard, to hear; to be lifted, be raised', '1046', '' ],
[ '전부', 'all, entire, total', '1047', '全部' ],
[ '국', 'soup', '1048', '' ],
[ '푸르다', 'to be blue, green', '1049', '' ],
[ '세탁', 'washing', '1050', '洗濯' ],
[ '미래', 'future', '1051', '未來' ],
[ '바라다', 'to desire, wish, hope', '1052', '' ],
[ '봉투', 'envelope, bag', '1053', '封套' ],
[ '고개', 'head, neck; hill', '1054', '' ],
[ '저번', 'last time, the other day', '1055', '-番' ],
[ '움직이다', 'to move', '1056', '' ],
[ '식구', 'family, member of family', '1057', '食口' ],
[ '혼자서', 'by oneself, to oneself, in solitude', '1058', '' ],
[ '나누다', 'to divide, split up; to share', '1059', '' ],
[ '형제', 'brothers, siblings', '1060', '兄弟' ],
[ '하숙집', 'boarding house', '1061', '下宿-' ],
[ '새롭다', 'to be new, fresh', '1062', '' ],
[ '소식', 'news (from people you know)', '1063', '消息' ],
[ '배추', 'Chinese cabbage', '1064', '' ],
[ '지나가다', 'to go through, pass', '1065', '' ],
[ '동물원', 'zoo', '1066', '動物園' ],
[ '전혀', 'completely; (not) at all', '1067', '全-' ],
[ '풀다', 'to solve; untie', '1068', '' ],
[ '직원', 'employee, staff member', '1069', '職員' ],
[ '인터넷', 'internet', '1070', '' ],
[ '닮다', 'to resemble, be alike', '1071', '' ],
[ '행사', 'event', '1072', '行事' ],
[ '변호사', 'lawyer', '1073', '辯護士' ],
[ '불쌍하다', 'to be pitiful, pathetic, poor', '1074', '' ],
[ '귤', 'tangerine, mandarin', '1075', '橘' ],
[ '크게', 'greatly, highly', '1076', '' ],
[ '계산하다', 'to calculate; to pay', '1077', '計算' ],
[ '설거지', 'dishwashing', '1078', '' ],
[ '유학생', 'foreign student', '1079', '留學生' ],
[ '자라다', 'to grow up, be raised', '1080', '' ],
[ '연락처', 'contact information', '1081', '連絡處' ],
[ '아르바이트', 'part-time job', '1082', '' ],
[ '펴다', 'to open, unfold, spread', '1083', '' ],
[ '혹시', 'by any chance; perhaps', '1084', '或是' ],
[ '후배', "one's junior (at school or workplace)", '1085', '後輩' ],
[ '옳다', 'to be right, correct, proper', '1086', '' ],
[ '숫자', 'numbers', '1087', '數字' ],
[ '토마토', 'tomato', '1088', '' ],
[ '넘어지다', 'to fall (down)', '1089', '' ],
[ '생신', 'birthday (hon.)', '1090', '生辰' ],
[ '완전히', 'completely, entirely, fully', '1091', '完全' ],
[ '차다', 'to kick; to be cold, chilly; to be full (of)', '1092', '' ],
[ '답장', 'response, answer (written)', '1093', '答狀' ],
[ '새해', 'New Year', '1094', '' ],
[
  '알아보다',
  'to find out, check out, investigate; to recognize',
  '1095',
  ''
],
[ '미술관', 'art museum', '1096', '美術館' ],
[ '부분', 'part, portion', '1097', '部分' ],
[ '느리다', 'to be slow', '1098', '' ],
[ '고속버스', 'express bus', '1099', '高速bus' ],
[ '늘', 'always, all the time', '1100', '' ],
[ '주차하다', 'to park', '1101', '駐車' ],
[ '가위', 'scissors', '1102', '' ],
[ '채소', 'vegetable', '1103', '菜蔬' ],
[ '꺼내다', 'to pull, take out; to start talking', '1104', '' ],
[ '회색', 'gray', '1105', '灰色' ],
[ '목소리', 'voice', '1106', '' ],
[ '안전하다', 'to be safe, secure', '1107', '安全' ],
[ '프로그램', 'program', '1108', '' ],
[ '음악가', 'musician', '1109', '音樂家' ],
[ '두다', 'to put, place, lay; to leave (behind, as is)', '1110', '' ],
[ '매년', 'every year', '1111', '每年' ],
[ '깜짝', 'startling', '1112', '' ],
[ '좋아지다', 'to become better; to become fond of', '1113', '' ],
[ '손수건', 'handkerchief', '1114', '-手巾' ],
[ '필통', 'pencil case', '1115', '筆筒' ],
[ '배부르다', 'to have a full stomach', '1116', '' ],
[ '시민', 'citizen', '1117', '市民' ],
[ '울음', 'crying', '1118', '' ],
[ '전공하다', 'to major in, specialize in', '1119', '專攻' ],
[ '콩', 'bean', '1120', '' ],
[ '신호등', 'traffic light', '1121', '信號燈' ],
[ '넘다', 'to exceed; to climb (over)', '1122', '' ],
[ '소포', 'parcel, package', '1123', '小包' ],
[ '카페', 'café', '1124', '' ],
[ '좁다', 'to be narrow', '1125', '' ],
[ '평일', 'weekday', '1126', '平日' ],
[ '교육', 'education', '1127', '敎育' ],
[ '생각나다', 'to come to mind, think of, remember', '1128', '' ],
[ '세계', 'the world', '1129', '世界' ],
[ '목걸이', 'necklace', '1130', '' ],
[ '화가', 'painter, artist', '1131', '畫家' ],
[ '늘다', 'to increase, grow, rise', '1132', '' ],
[ '녹차', 'green tea', '1133', '綠茶' ],
[ '첫날', 'the first day', '1134', '' ],
[ '기회', 'chance, opportunity', '1135', '機會' ],
[ '알리다', 'to inform, let know, notify', '1136', '' ],
[ '현금', 'cash', '1137', '現金' ],
[ '가까이', 'near (to), close; nearly', '1138', '' ],
[ '한식', 'Korean-style food', '1139', '韓食' ],
[ '세다', 'to be strong; to count (up)', '1140', '' ],
[ '사업', 'enterprise, business', '1141', '事業' ],
[ '에어컨', 'air conditioner', '1142', '' ],
[ '원하다', 'to want, wish, hope', '1143', '願' ],
[ '섬', 'island', '1144', '' ],
[ '매주', 'every week', '1145', '每週' ],
[ '키우다', 'to raise, bring up, breed', '1146', '' ],
[ '건너편', 'the other side', '1147', '--便' ],
[ '입술', 'lips', '1148', '' ],
[ '만두', 'dumpling', '1149', '饅頭' ],
[
  '부끄럽다',
  'to be shameful, disgraceful, ashamed; to be shy',
  '1150',
  ''
],
[ '반대', 'reverse, opposite', '1151', '反對' ],
[ '둘이', 'two people', '1152', '' ],
[ '육교', 'pedestrian overpass', '1153', '陸橋' ],
[ '뵙다', 'to see, meet (hon.)', '1154', '' ],
[ '갈색', 'light brown', '1155', '褐色' ],
[ '바닥', 'floor, the bottom', '1156', '' ],
[ '부자', 'rich person; father and son', '1157', '富者, 父子' ],
[ '갈아타다', 'to change vehicles, transfer', '1158', '' ],
[ '대학원', 'graduate school', '1159', '大學院' ],
[ '핸드폰', 'cellphone', '1160', '' ],
[ '결심', 'determination, resolution', '1161', '決心' ],
[ '찾아오다', 'to come see someone; to retrieve something', '1162', '' ],
[ '특별히', 'especially, particularly', '1163', '特別' ],
[ '무', 'radish', '1164', '' ],
[ '악기', 'musical instrument', '1165', '樂器' ],
[ '오래되다', 'to be old (for an object)', '1166', '' ],
[ '광주', 'Gwangju', '1167', '光州' ],
[ '쓰레기', 'garbage, trash', '1168', '' ],
[ '입원하다', 'to be hospitalized', '1169', '入院' ],
[ '때문에', 'because of', '1170', '' ],
[ '공짜', 'free (of charge)', '1171', '空-' ],
[ '모으다', 'to collect, gather up', '1172', '' ],
[ '대부분', 'greater part, mostly', '1173', '大部分' ],
[ '무게', 'weight', '1174', '' ],
[ '경험하다', 'to experience', '1175', '經驗' ],
[ '지우개', 'eraser, rubber', '1176', '' ],
[ '인삼', 'ginseng', '1177', '人蔘' ],
[ '알맞다', 'to be fitting, suitable, appropriate', '1178', '' ],
[ '부장', 'head of department', '1179', '部長' ],
[ '마을', 'village, town', '1180', '' ],
[ '취소하다', 'to cancel, withdraw', '1181', '取消' ],
[ '비디오', 'video', '1182', '' ],
[ '휴게실', 'lounge, resting room', '1183', '休憩室' ],
[ '데리고가다', 'to bring someone to some place', '1184', '' ],
[ '역시', 'too, also; as expected; still', '1185', '亦是' ],
[ '줄', 'line, rope', '1186', '' ],
[ '부족하다', 'to lack, be lacking, not good enough', '1187', '不足' ],
[ '바닷가', 'beach, seaside', '1188', '' ],
[ '수영복', 'swimsuit', '1189', '水泳服' ],
[ '올라오다', 'to come up', '1190', '' ],
[ '행동', 'behavior, action', '1191', '行動' ],
[ '얼음', 'ice', '1192', '' ],
[ '흐르다', 'to flow; to overflow; to elapse', '1193', '' ],
[ '수술', 'operation, surgery', '1194', '手術' ],
[ '호주', 'Australia', '1195', '濠洲' ],
[ '멈추다', 'to stop, cease', '1196', '' ],
[ '된장', 'soy bean paste', '1197', '-醬' ],
[ '미리', 'in advance', '1198', '' ],
[ '약하다', 'to be weak', '1199', '弱' ],
[ '이틀', 'two days', '1200', '' ],
[ '목욕탕', 'bathroom, bath house', '1201', '沐浴湯' ],
[
  '이르다',
  'to arrive, reach; to be early, premature; to tell, say',
  '1202',
  ''
],
[ '국제', 'international-', '1203', '國際' ],
[ '데이트', 'date, going out', '1204', '' ],
[ '수첩', '(pocket) notebook, personal organizer', '1205', '手帖' ],
[ '늙다', 'to grow old, be old', '1206', '' ],
[ '성공', 'success', '1207', '成功' ],
[ '귀걸이', 'earring', '1208', '' ],
[ '금지하다', 'to prohibit, ban', '1209', '禁止' ],
[ '짐', 'load, burden, luggage, cargo', '1210', '' ],
[ '분홍색', 'pink', '1211', '粉紅色' ],
[ '놀이', 'play, game, pastime', '1212', '' ],
[ '정확하다', 'to be exact, correct, accurate', '1213', '正確' ],
[ '따로', 'separately; in addition', '1214', '' ],
[ '안내', 'guide, information leaflet', '1215', '案內' ],
[ '모이다', 'to come together, assemble, gather', '1216', '' ],
[ '사계절', 'four seasons', '1217', '四季節' ],
[ '길이', 'length', '1218', '' ],
[ '방향', 'direction', '1219', '方向' ],
[ '바라보다', 'to look at, watch, gaze', '1220', '' ],
[ '주로', 'mostly, mainly', '1221', '主-' ],
[ '드라마', 'TV show', '1222', '' ],
[ '한잔하다', 'to have a drink', '1223', '-盞' ],
[ '모습', 'figure, shape, features', '1224', '' ],
[ '목적', 'purpose, aim, goal', '1225', '目的' ],
[ '외롭다', 'to be lonely', '1226', '' ],
[ '두부', 'tofu', '1227', '豆腐' ],
[ '옆집', 'next door', '1228', '' ],
[ '요금', 'charge, fee', '1229', '料金' ],
[ '빨래하다', 'to do laundry', '1230', '' ],
[ '방송국', 'broadcasting station', '1231', '放送局' ],
[ '토끼', 'rabbit', '1232', '' ],
[ '위치', 'position, location, site', '1233', '位置' ],
[ '밀다', 'to push, shove', '1234', '' ],
[ '답', 'answer', '1235', '答' ],
[ '어젯밤', 'last night', '1236', '' ],
[ '편안하다', 'to be comfortable, relaxed, peaceful', '1237', '便安' ],
[ '센티미터', 'centimeter', '1238', '' ],
[ '출구', 'exit', '1239', '出口' ],
[ '조카', 'nephew, niece', '1240', '' ],
[ '환영하다', 'to welcome (somebody)', '1241', '歡迎' ],
[ '소고기', 'beef', '1242', '' ],
[ '기간', 'period, term', '1243', '期間' ],
[ '오른손', 'right hand', '1244', '' ],
[ '검사하다', 'to examine, inspect', '1245', '檢査' ],
[ '도쿄', 'Tokyo', '1246', '' ],
[ '사거리', 'intersection, crossroads', '1247', '四-' ],
[ '비다', 'to be empty, vacant', '1248', '' ],
[ '엽서', 'postcard', '1249', '葉書' ],
[ '무릎', 'knee; lap', '1250', '' ],
[ '쓰레기통', 'garbage can, waste basket', '1251', '-桶' ],
[ '노트하다', 'to take notes', '1252', '' ],
[ '정문', 'the front gate, main entrance', '1253', '正門' ],
[ '남', 'other people', '1254', '' ],
[ '지하도', 'underpass', '1255', '地下道' ],
[ '갈아입다', 'to change clothes', '1256', '' ],
[ '간장', 'soy sauce', '1257', '-醬' ],
[ '깨끗이', 'cleanly, neatly', '1258', '' ],
[ '치료', 'treatment, cure', '1259', '治療' ],
[ '볶다', 'to fry, roast', '1260', '' ],
[ '모양', 'shape', '1261', '模樣·貌樣' ],
[ '아무데나', 'any place, anywhere', '1262', '' ],
[ '신혼여행', 'honeymoon', '1263', '新婚旅行' ],
[ '싱겁다', 'to be bland, not salty enough', '1264', '' ],
[ '대구', 'Daegu', '1265', '大邱' ],
[ '모레', 'the day after tomorrow', '1266', '' ],
[ '주황색', 'orange', '1267', '朱黃色' ],
[ '젖다', 'to get wet, soaked, be moistened', '1268', '' ],
[ '중간', 'middle', '1269', '中間' ],
[ '엘리베이터', 'elevator', '1270', '' ],
[ '반대하다', 'to oppose, be against', '1271', '反對' ],
[ '잔치', 'party, feast', '1272', '' ],
[ '고장', 'defect, break down', '1273', '故障' ],
[ '푹', 'deeply, soundly, carefully', '1274', '' ],
[ '비교하다', 'to compare with', '1275', '比較' ],
[ '도움', 'help, aid', '1276', '' ],
[ '교통사고', 'traffic accident', '1277', '交通事故' ],
[ '설날', "New Year's Day", '1278', '' ],
[ '편리하다', 'to be convenient, handy', '1279', '便利' ],
[ '오랫동안', 'for a long time', '1280', '' ],
[ '규칙', 'rule, regulation', '1281', '規則' ],
[ '높이', 'height; high, highly', '1282', '' ],
[ '정리하다', 'to arrange, put in order', '1283', '整理' ],
[ '모기', 'mosquito', '1284', '' ],
[ '간단히', 'simply, briefly', '1285', '簡單' ],
[ '마치다', 'to finish, complete', '1286', '' ],
[ '언어', 'language', '1287', '言語' ],
[ '메시지', 'message', '1288', '' ],
[
  '대회',
  'mass meeting, convention; contest, tournament (where many people gather)',
  '1289',
  '大會'
],
[ '흘리다', 'to spill, shed (tears, blood etc.)', '1290', '' ],
[ '인천', 'Incheon', '1291', '仁川' ],
[ '밀가루', 'flour', '1292', '' ],
[ '심하다', 'to be heavy, severe, harsh', '1293', '甚' ],
[ '땅', 'the ground; land', '1294', '' ],
[ '탁구', 'table tennis', '1295', '卓球' ],
[ '건너가다', 'to cross, go across', '1296', '' ],
[ '공무원', 'public servant, government employee', '1297', '公務員' ],
[ '자꾸', 'constantly, repeatedly', '1298', '' ],
[ '주변', 'surroundings', '1299', '周邊' ],
[
  '익다',
  'to ripen, be ripe, be [well] cooked; to be skilled, experienced, be familiar',
  '1300',
  ''
],
[ '종류', 'kind, type, sort', '1301', '種類' ],
[ '짧게', 'briefly', '1302', '' ],
[ '외출하다', 'to go out', '1303', '外出' ],
[ '킬로그램', 'kilogram', '1304', '' ],
[ '경복궁', 'Gyeong Bok palace', '1305', '景福宮' ],
[ '부지런하다', 'to be hardworking, diligent', '1306', '' ],
[ '제목', 'title, name', '1307', '題目' ],
[ '술집', 'bar, pub', '1308', '' ],
[ '설렁탕', 'beef and noodle soup', '1309', '-湯' ],
[ '떠들다', 'to make noise, speak loudly', '1310', '' ],
[ '점점', 'gradually, little by little, increasingly', '1311', '漸漸' ],
[ '사흘', 'three days', '1312', '' ],
[ '경찰관', 'police officer', '1313', '警察官' ],
[ '다하다', 'to run out, be used up, become exhausted', '1314', '' ],
[ '정원', 'capacity, limit; garden, yard', '1315', '定員, 庭園' ],
[ '발가락', 'toe', '1316', '' ],
[ '주부', 'housewife', '1317', '主婦' ],
[ '쳐다보다', 'to look (at), stare (at)', '1318', '' ],
[ '기차역', 'train station', '1319', '汽車驛' ],
[ '커피숍', 'coffee shop', '1320', '' ],
[ '희망', 'hope', '1321', '希望' ],
[ '부치다', 'to mail, ship; to fry', '1322', '' ],
[ '자기소개', 'self-introduction', '1323', '自己紹介' ],
[ '금방', 'shortly, soon, quickly', '1324', '今方' ],
[ '잎', 'leaf', '1325', '' ],
[ '신선하다', 'to be fresh', '1326', '新鮮' ],
[ '크기', 'size, volume', '1327', '' ],
[ '포장', 'packing, wrapping', '1328', '包裝' ],
[ '올리다', 'to raise, lift up; to offer', '1329', '' ],
[ '우선', 'first of all', '1330', '于先' ],
[ '안쪽', 'the inside, the inner part', '1331', '' ],
[ '안내하다', 'to guide, lead the way', '1332', '案內' ],
[ '이마', 'forehead', '1333', '' ],
[ '태국', 'Thailand', '1334', '泰國' ],
[ '자랑하다', 'to be proud (of), boast (about)', '1335', '' ],
[ '세탁소', "laundry, dry cleaner's", '1336', '洗濯所' ],
[ '케이크', 'cake', '1337', '' ],
[ '공장', 'factory', '1338', '工場' ],
[ '식다', 'to cool off, get cold', '1339', '' ],
[ '청소년', 'teenager, youth, adolescent', '1340', '靑少年' ],
[ '아무리', 'however, no matter how', '1341', '' ],
[ '침실', 'bedroom', '1342', '寢室' ],
[ '끓이다', 'to boil, heat, cook', '1343', '' ],
[ '글자', 'letter, character', '1344', '-字' ],
[ '멋', 'stylishness, elegance', '1345', '' ],
[ '순서', 'order, sequence, procedure', '1346', '順序' ],
[ '훌륭하다', 'to be excellent, great, superb, fine', '1347', '' ],
[ '음식점', 'restaurant', '1348', '飮食店' ],
[ '멀리', 'far, far away', '1349', '' ],
[ '실패하다', 'to fail, go wrong', '1350', '失敗' ],
[ '한두', 'one or two', '1351', '' ],
[ '서류', 'documents, papers', '1352', '書類' ],
[ '붙다', 'to stick to, adhere to', '1353', '' ],
[ '관광객', 'tourist', '1354', '觀光客' ],
[ '메모', 'memo, note', '1355', '' ],
[ '전체', 'the whole, entire', '1356', '全體' ],
[ '데려오다', 'to bring, go and get, fetch someone', '1357', '' ],
[ '책장', 'bookshelf, bookcase; the page of a book', '1358', '冊欌, 冊張' ],
[ '이불', 'comforter, bedding', '1359', '' ],
[
  '지각하다',
  'to be late (for something); to perceive, sense, be aware',
  '1360',
  '遲刻, 知覺'
],
[ '나흘', 'four days', '1361', '' ],
[ '고추장', 'red-pepper paste', '1362', '-醬' ],
[ '맞추다', 'to set, adjust', '1363', '' ],
[ '출입', 'entry; coming and going', '1364', '出入' ],
[ '그래도', 'nevertheless, still, however', '1365', '' ],
[ '설악산', 'Seoraksan (mountain)', '1366', '雪嶽山' ],
[ '잘되다', 'to be going well, come out well', '1367', '' ],
[ '도로', 'road, street; back, again', '1368', '道路' ],
[ '소', 'cow', '1369', '' ],
[
  '적당하다',
  'to be appropriate, suitable, moderate, proper',
  '1370',
  '適當'
],
[ '퍼센트', 'percent', '1371', '' ],
[ '연예인', 'entertainer, celebrity', '1372', '演藝人' ],
[ '흔들다', 'to shake, wave, swing', '1373', '' ],
[ '자세히', 'closely, in detail', '1374', '仔細' ],
[ '왼손', 'left hand', '1375', '' ],
[ '국내', 'inside country, domestic', '1376', '國內' ],
[ '서두르다', 'to hurry up, hasten', '1377', '' ],
[ '일기', 'diary, journal', '1378', '日記' ],
[ '보라색', 'violet, purple', '1379', '' ],
[ '긴장하다', 'to be nervous, be tense', '1380', '緊張' ],
[ '걸음', 'step', '1381', '' ],
[ '한식집', 'Korean restaurant', '1382', '韓食-' ],
[ '졸다', 'to doze off', '1383', '' ],
[ '노인', 'old people', '1384', '老人' ],
[ '날마다', 'every day', '1385', '' ],
[ '관계', 'relationship, connection', '1386', '關係' ],
[ '돌리다', 'to turn, spin', '1387', '' ],
[ '호', 'a number (count.); issue; arc', '1388', '號' ],
[ '원피스', 'dress', '1389', '' ],
[ '신청하다', 'to apply for, request', '1390', '申請' ],
[ '반드시', 'surely, certainly', '1391', '' ],
[ '식품', 'food items, groceries', '1392', '食品' ],
[ '마중하다', 'to (come to) meet someone', '1393', '' ],
[ '문학', 'literature', '1394', '文學' ],
[ '기름', 'oil; fat', '1395', '' ],
[ '유치원', 'kindergarten', '1396', '幼稚園' ],
[ '섞다', 'to blend, mix', '1397', '' ],
[ '기온', 'temperature', '1398', '氣溫' ],
[ '꽃병', 'flower vase', '1399', '' ],
[ '충분하다', 'to be enough, be sufficient', '1400', '充分' ],
[ '또는', 'or', '1401', '' ],
[
  '이전',
  'the previous, before, formerly; relocation, move',
  '1402',
  '以前, 移轉'
],
[ '꾸다', 'to dream; to borrow', '1403', '' ],
[ '태도', 'attitude, manner', '1404', '態度' ],
[ '센터', 'center', '1405', '' ],
[ '식초', 'vinegar', '1406', '食醋' ],
[ '지르다', 'to yell, shout, scream', '1407', '' ],
[ '태풍', 'typhoon', '1408', '颱風' ],
[ '돌', 'stone, rock; first birthday', '1409', '' ],
[ '정하다', 'to decide, determine, choose', '1410', '定' ],
[ '이따가', 'a little later', '1411', '' ],
[ '주위', 'the circumference, perimeter; surroundings', '1412', '周圍' ],
[ '슬퍼하다', 'to feel sad', '1413', '' ],
[ '상자', 'box', '1414', '箱子' ],
[ '위쪽', 'the upper direction', '1415', '' ],
[ '선풍기', 'electric fan', '1416', '扇風機' ],
[ '싣다', 'to load (up)', '1417', '' ],
[ '재료', 'material, ingredient', '1418', '材料' ],
[ '어머님', 'mother (hon.)', '1419', '' ],
[
  '입학하다',
  'to enter a school, be admitted into a school',
  '1420',
  '入學'
],
[ '스파게티', 'spaghetti', '1421', '' ],
[ '전기', 'electricity; biography', '1422', '電氣, 傳記' ],
[ '답답하다', 'to feel frustrated;to be stuffy', '1423', '' ],
[ '지방', 'local area, region; fat', '1424', '地方, 脂肪' ],
[ '호랑이', 'tiger', '1425', '' ],
[ '덕분에', 'thanks to', '1426', '德分' ],
[ '빨다', 'to wash (laundry); to suck', '1427', '' ],
[ '치과', 'dentist', '1428', '齒科' ],
[ '냄비', 'pot, saucepan', '1429', '' ],
[ '상', 'award, prize; table', '1430', '賞, 床' ],
[ '끓다', 'to boil, simmer', '1431', '' ],
[ '자유', 'freedom, liberty', '1432', '自由' ],
[ '디자인', 'design', '1433', '' ],
[ '칭찬하다', 'to praise, compliment', '1434', '稱讚' ],
[ '무언가', 'something', '1435', '' ],
[ '가요', 'song', '1436', '歌謠' ],
[ '쌓다', 'to pile up; to accumulate', '1437', '' ],
[ '된장찌개', 'soy bean paste stew', '1438', '-醬--' ],
[ '서랍', 'drawer', '1439', '' ],
[ '통장', 'bankbook', '1440', '通帳' ],
[ '돌려주다', 'to give back, return something', '1441', '' ],
[ '경주', 'race, run; Gyeongju', '1442', '競走, 慶州' ],
[ '스스로', 'oneself', '1443', '' ],
[ '장보다', 'to go to market, go grocery shopping', '1444', '場' ],
[ '시골', 'country, rural area', '1445', '' ],
[ '자판기', 'vending machine', '1446', '自販機' ],
[ '붙이다', 'to stick, attach', '1447', '' ],
[ '한자', 'Chinese character', '1448', '漢字' ],
[ '스웨터', 'sweater', '1449', '' ],
[ '입구', 'entrance, gateway', '1450', '入口' ],
[ '붉다', 'to be red, crimson', '1451', '' ],
[ '신분증', 'ID card', '1452', '身分證' ],
[ '뱀', 'snake', '1453', '' ],
[
  '예매하다',
  'to book, reserve, buy in advance; to sell tickets in advance',
  '1454',
  '豫買, 豫賣'
],
[ '웃음', 'laughter, smile', '1455', '' ],
[ '식빵', 'bread', '1456', '食-' ],
[ '그치다', 'to stop (doing), cease', '1457', '' ],
[ '단풍', 'tinged autumn leaves', '1458', '丹楓' ],
[ '두세', 'two or three', '1459', '' ],
[ '입장권', 'admission ticket', '1460', '入場券' ],
[
  '찌다',
  'to gain weight; to be steaming hot, be sultry; to cook with steam',
  '1461',
  ''
],
[ '신부', 'bride; Catholic priest', '1462', '新婦, 神父' ],
[ '달러', 'dollar', '1463', '' ],
[ '소중하다', 'to be valuable, important, precious', '1464', '所重' ],
[ '드디어', 'finally, at (long) last', '1465', '' ],
[ '속도', 'speed, pace', '1466', '速度' ],
[ '큰소리', 'loud voice; bragging', '1467', '' ],
[ '연결하다', 'to connect, link, attach', '1468', '連結' ],
[ '미역국', 'seaweed soup', '1469', '' ],
[ '중심', 'center, core, balance', '1470', '中心, 重心' ],
[ '틀다', 'to twist, turn; to switch on', '1471', '' ],
[ '동시에', 'at the same time', '1472', '同時' ],
[ '어린아이', 'child', '1473', '' ],
[ '능력', 'ability, capability', '1474', '能力' ],
[ '바르다', 'to apply, put on', '1475', '' ],
[ '기타', 'guitar; etc.', '1476', '其他' ],
[ '바깥', 'outside', '1477', '' ],
[ '화려하다', 'to be splendid, flashy', '1478', '華麗' ],
[ '옷걸이', 'coat hanger', '1479', '' ],
[ '명절', '(national) holiday', '1480', '名節' ],
[ '굵다', 'to be thick', '1481', '' ],
[ '관광지', 'tourist attraction [spot]', '1482', '觀光地' ],
[ '앞쪽', 'the front', '1483', '' ],
[ '왕', 'king', '1484', '王' ],
[ '줄이다', 'to reduce, decrease', '1485', '' ],
[ '각각', 'each, every', '1486', '各各' ],
[ '상추', 'lettuce', '1487', '' ],
[ '진하다', 'to be dark;to be thick, strong, rich', '1488', '津' ],
[ '그저께', 'day before yesterday', '1489', '' ],
[ '약사', 'pharmacist', '1490', '藥師' ],
[ '찾아보다', 'to look for, search', '1491', '' ],
[
  '차례',
  'order, sequence; time, turn; memorial service for ancestors',
  '1492',
  '次例, 茶禮'
],
[ '빌딩', 'building', '1493', '' ],
[ '미용실', "beauty salon, hairdresser's", '1494', '美容室' ],
[ '틀리다', 'to be wrong, mistaken; to make a mistake', '1495', '' ],
[ '활동', 'activity', '1496', '活動' ],
[ '여기저기', 'here and there', '1497', '' ],
[ '분명하다', 'to be clear, obvious', '1498', '分明' ],
[ '깊이', 'depth; deeply', '1499', '' ],
[ '인간', 'human being, mankind', '1500', '人間' ],
[ '검다', 'to be black', '1501', '' ],
[ '친척', 'relatives', '1502', '親戚' ],
[ '떡볶이', 'rice-stick with vegetables', '1503', '' ],
[ '성적', 'grade, mark; sexual', '1504', '成績, 性的' ],
[ '기르다', 'to raise, look after; to grow', '1505', '' ],
[ '정거장', 'station, stop', '1506', '停車場' ],
[ '서비스', 'service', '1507', '' ],
[ '교환하다', 'to exchange, trade', '1508', '交換' ],
[ '열흘', 'ten days', '1509', '' ],
[ '할인', 'discount', '1510', '割引' ],
[ '모자라다', 'to be insufficient', '1511', '' ],
[ '방문', 'room door', '1512', '房門' ],
[ '아무튼', 'anyway, in any case', '1513', '' ],
[
  '검사',
  'prosecutor, district attorney; examination, inspection, check',
  '1514',
  '檢事, 檢査'
],
[ '줍다', 'to pick up, gather up', '1515', '' ],
[ '운동복', 'sportswear', '1516', '運動服' ],
[ '김', 'dried seaweed; steam', '1517', '' ],
[ '통화하다', 'to talk over the telephone', '1518', '通話' ],
[ '아래쪽', 'down, lower part', '1519', '' ],
[ '손녀', 'granddaughter', '1520', '孫女' ],
[ '썰다', 'to cut (up), chop, slice', '1521', '' ],
[ '정답', 'correct answer', '1522', '正答' ],
[ '아나운서', 'announcer, news anchor', '1523', '' ],
[ '주일', "week; Sunday, Lord's day", '1524', '週日, 主日' ],
[ '기뻐하다', 'to be pleased, happy, glad', '1525', '' ],
[ '회원', 'member, membership', '1526', '會員' ],
[ '똑같이', 'equally, evenly', '1527', '' ],
[ '당연하다', 'to be reasonable, natural', '1528', '當然' ],
[ '김치찌개', 'kimchi stew', '1529', '' ],
[ '정신', 'mind, spirit, soul', '1530', '精神' ],
[ '매다', 'to tie, bind', '1531', '' ],
[ '동전', '(copper) coin', '1532', '銅錢' ],
[ '속옷', 'underwear', '1533', '' ],
[ '학생증', 'student ID', '1534', '學生證' ],
[
  '그만두다',
  'to quit (work, school, etc.); to give up (drinking etc.)',
  '1535',
  ''
],
[ '자기', 'self, oneself', '1536', '自己' ],
[ '메일', 'mail', '1537', '' ],
[ '단순하다', 'to be simple', '1538', '單純' ],
[ '장마', 'rainy season, rainy spell', '1539', '' ],
[ '수학', 'mathematics; study', '1540', '數學, 修學' ],
[ '들르다', 'to stop by', '1541', '' ],
[ '송편', 'half-moon-shaped rice cake', '1542', '松-' ],
[ '짜증', 'irritation, annoyance', '1543', '' ],
[ '초록색', 'green', '1544', '草綠色' ],
[ '돌아가시다', 'to pass away, die (hon.)', '1545', '' ],
[ '시대', 'period, era', '1546', '時代' ],
[ '슬픔', 'sorrow, sadness', '1547', '' ],
[ '거절하다', 'to refuse, decline, reject', '1548', '拒絶' ],
[ '혀', 'tongue', '1549', '' ],
[ '두통', 'headache', '1550', '頭痛' ],
[ '얼다', 'to freeze, be frozen', '1551', '' ],
[ '조건', 'condition, term', '1552', '條件' ],
[ '새로', 'newly', '1553', '' ],
[ '우표', 'stamp', '1554', '郵票' ],
[ '누르다', 'to press down, push down, to suppress', '1555', '' ],
[ '풍경', 'landscape, scenery', '1556', '風景' ],
[ '터미널', 'terminal', '1557', '' ],
[ '도망가다', 'to run away, escape', '1558', '逃亡' ],
[ '너희', 'you (plural)', '1559', '' ],
[ '영화관', 'movie theater', '1560', '映畫館' ],
[ '재채기하다', 'to sneeze', '1561', '' ],
[ '경제', 'economy', '1562', '經濟' ],
[ '갈비탕', 'beef rib soup', '1563', '' ],
[ '영하', 'temperature below freezing', '1564', '零下' ],
[ '감다', "to wind, roll; to wash; to close one's eyes", '1565', '' ],
[ '만일', 'in case, by any chance, suppose', '1566', '萬一' ],
[ '머리카락', 'hair', '1567', '' ],
[ '변화하다', 'to change, alter', '1568', '變化' ],
[ '버릇', 'habit; manners', '1569', '' ],
[ '연말', 'end of the year', '1570', '年末' ],
[
  '밝히다',
  'to brighten (up), lighten; to clarify, disclose',
  '1571',
  ''
],
[ '점수', 'score, mark, grade', '1572', '點數' ],
[ '스케이트', 'skates', '1573', '' ],
[ '서양', 'the West', '1574', '西洋' ],
[ '뽑다', 'to pick, draw, pull out', '1575', '' ],
[ '감', 'persimmon; feeling', '1576', '減' ],
[ '코끼리', 'elephant', '1577', '' ],
[ '결석하다', 'to be absent (from school)', '1578', '缺席' ],
[ '꽃집', 'flower shop', '1579', '' ],
[ '신랑', 'groom', '1580', '新郞' ],
[ '팔리다', 'to be sold, be in demand', '1581', '' ],
[ '환경', 'environment', '1582', '環境' ],
[ '찬물', 'cold water', '1583', '' ],
[ '일식', 'Japanese-style food; solar eclipse', '1584', '日食, 日蝕' ],
[ '익숙해지다', 'to get used to', '1585', '' ],
[ '전철', 'subway (train)', '1586', '電鐵' ],
[ '이웃', 'neighbor', '1587', '' ],
[ '출퇴근하다', 'to commute', '1588', '出退勤' ],
[ '치킨', 'chicken', '1589', '' ],
[ '연휴', 'long holiday, consecutive holidays', '1590', '連休' ],
[ '줄다', 'to decrease, shrink, lose size', '1591', '' ],
[ '국가', 'state, nation', '1592', '國家' ],
[ '장난감', 'toy', '1593', '' ],
[ '시어머니', 'mother-in-law (of a woman)', '1594', '媤-' ],
[ '그만하다', 'to stop (doing something)', '1595', '' ],
[ '연극', 'play, theatrical performance', '1596', '演劇' ],
[ '모임', 'meeting, gathering', '1597', '' ],
[ '가능하다', 'to be possible', '1598', '可能' ],
[ '주머니', 'pocket, bag, pouch', '1599', '' ],
[ '전화기', 'telephone (set)', '1600', '電話機' ],
[ '화내다', "to get angry, vent one's anger", '1601', '' ],
[ '청년', 'young man, young people', '1602', '靑年' ],
[ '손바닥', 'palm of the hand', '1603', '' ],
[ '기본', 'basis, foundation, basics', '1604', '基本' ],
[ '심다', 'to plant', '1605', '' ],
[ '매달', 'every month', '1606', '每-' ],
[ '콘서트', 'concert', '1607', '' ],
[ '고생하다', 'to suffer, have a hard time, have trouble', '1608', '苦生' ],
[ '덜', 'less', '1609', '' ],
[ '결국', 'after all, finally, in the end', '1610', '結局' ],
[ '가리키다', 'to point to', '1611', '' ],
[ '솔직히', 'honestly, frankly', '1612', '率直' ],
[ '볶음밥', 'fried rice', '1613', '' ],
[ '정치', 'politics', '1614', '政治' ],
[ '따르다', 'to follow, go after; to pour, fill', '1615', '' ],
[ '온도', 'temperature', '1616', '溫度' ],
[ '햇빛', 'sunlight, sunshine', '1617', '' ],
[
  '한가하다',
  'to be free, have leisure, have time to spare',
  '1618',
  '閑暇'
],
[ '마당', 'yard, garden', '1619', '' ],
[ '당신', 'you (specific usage)', '1620', '當身' ],
[ '뜨다', 'to float; to rise; to open (eyes)', '1621', '' ],
[ '과학', 'science', '1622', '科學' ],
[ '블라우스', 'blouse', '1623', '' ],
[ '무료', 'free of charge (more formal)', '1624', '無料' ],
[ '튀기다', 'to fry; to splash', '1625', '' ],
[ '항공', 'aviation, flight', '1626', '航空' ],
[ '쇠고기', 'beef', '1627', '' ],
[ '기대하다', 'to expect, look forward', '1628', '期待' ],
[ '하늘색', 'sky blue', '1629', '' ],
[ '기사', 'news article; driver, chauffeur; engineer', '1630', '記事, 技士' ],
[ '모시다', 'to accompany, take; to treat; to serve (hon.)', '1631', '' ],
[ '한옥', 'Korean-style house', '1632', '韓屋' ],
[ '겉', 'the outside, surface', '1633', '' ],
[ '경우', 'case, circumstances, scenario', '1634', '境遇' ],
[ '씹다', 'to chew', '1635', '' ],
[
  '양식',
  'style, pattern; Western-style food; good sense; culture, farming (of fish)',
  '1636',
  '樣式, 洋食, 良識, 養殖'
],
[ '축구공', 'soccer ball', '1637', '' ],
[ '발견하다', 'to discover, find', '1638', '發見' ],
[ '카레', 'curry', '1639', '' ],
[ '인생', 'life (human)', '1640', '人生' ],
[ '지루하다', 'to be boring', '1641', '' ],
[ '체육관', 'gym', '1642', '體育館' ],
[ '낚시', 'fishing', '1643', '' ],
[ '원래', 'originally, by nature, always', '1644', '元來·原來' ],
[ '메다', "to carry on one's shoulder; to choke (up)", '1645', '' ],
[ '중앙', 'center, middle', '1646', '中央' ],
[ '나머지', 'the rest, the remainder', '1647', '' ],
[ '확인하다', 'to confirm, verify, check', '1648', '確認' ],
[ '서너', 'three or four', '1649', '' ],
[ '교사', 'instructor, teacher', '1650', '敎師' ],
[ '없어지다', 'to lose, be lost; to disappear', '1651', '' ],
[ '시설', 'establishment, facilities', '1652', '施設' ],
[ '시간표', 'schedule, timetable', '1653', '時間表' ],
[ '가늘다', 'to be thin, slender', '1654', '' ],
[ '공사', 'construction', '1655', '工事' ],
[ '다이어트', 'diet', '1656', '' ],
[ '연구', 'research', '1657', '硏究' ],
[ '대단하다', 'to be great, tremendous; to be wonderful', '1658', '' ],
[ '국적', 'nationality, citizenship', '1659', '國籍' ],
[ '및', 'and, as well as', '1660', '' ],
[ '배탈나다', 'to have a stomachache', '1661', '-縫' ],
[ '칼국수', 'chopped noodles', '1662', '' ],
[ '전통', 'tradition', '1663', '傳統' ],
[ '그립다', 'to be missed, be beloved; to miss, long for', '1664', '' ],
[ '중국집', 'Chinese restaurant', '1665', '中國-' ],
[ '차이', 'difference', '1666', '差異' ],
[ '섭섭하다', 'to be sorry, disappointed, regret', '1667', '' ],
[ '한편', 'one side, one hand; meanwhile', '1668', '-便' ],
[ '글씨', 'letter, character (handwritten)', '1669', '' ],
[ '국민', 'people, citizen', '1670', '國民' ],
[ '뛰어가다', 'to run, dash', '1671', '' ],
[ '단점', 'weakness, flaw, shortcoming', '1672', '短點' ],
[ '배드민턴', 'badminton', '1673', '' ],
[ '완벽하다', 'to be perfect, flawless', '1674', '完璧' ],
[ '무척', 'very much', '1675', '' ],
[ '진심으로', "sincerely, from one's heart", '1676', '眞心' ],
[ '귀찮다', 'to be troublesome, bothersome, annoying', '1677', '' ],
[ '내과', 'internal medicine', '1678', '內科' ],
[ '스키장', 'ski resort', '1679', 'ski場' ],
[ '미끄러지다', 'to slide, slip', '1680', '' ],
[ '삼계탕', 'chicken soup with ginseng', '1681', '蔘鷄湯' ],
[ '껌', 'chewing gum', '1682', '' ],
[ '책임', 'responsibility; blame', '1683', '責任' ],
[ '갚다', 'to pay back, repay', '1684', '' ],
[ '금년', 'this year', '1685', '今年' ],
[
  '수저',
  'spoon and chopsticks (traditional Korean utensils)',
  '1686',
  ''
],
[
  '통하다',
  'to go through, flow; to be circulated; to be understood',
  '1687',
  '通'
],
[ '집안일', 'housework, housekeeping; household affair', '1688', '' ],
[ '예습', 'preparation, rehearsal', '1689', '豫習' ],
[ '일어서다', 'to stand up', '1690', '' ],
[ '대전', 'Daejeon; great war; state ceremony', '1691', '大田, 大戰, 大典' ],
[ '운전사', 'driver, chauffeur', '1692', '運轉士' ],
[ '밉다', 'to be hateful, dislikeable; to be ugly', '1693', '' ],
[ '소년', 'young boy', '1694', '少年' ],
[ '올림픽', 'Olympics', '1695', '' ],
[ '종일', 'all day long', '1696', '終日' ],
[ '귀여워하다', 'to treat affectionately', '1697', '' ],
[ '시절', 'years (college years etc.), period of life', '1698', '時節' ],
[ '막걸리', 'raw rice wine', '1699', '' ],
[ '심각하다', 'to be serious, grave, severe', '1700', '深刻' ],
[ '되게', 'very, really, extremely', '1701', '' ],
[ '과', 'department; lesson, chapter', '1702', '科, 課' ],
[ '미치다', 'to go mad, crazy; to reach', '1703', '' ],
[ '공연', 'public performance', '1704', '公演' ],
[ '여행사', 'travel agency', '1705', '旅行社' ],
[ '접다', 'to fold', '1706', '' ],
[ '순간', 'moment, instant', '1707', '瞬間' ],
[ '하얀색', 'white', '1708', '' ],
[ '피부', 'skin', '1709', '皮膚' ],
[ '웃기다', 'to amuse, to be funny', '1710', '' ],
[ '정보', 'information', '1711', '情報' ],
[ '바이올린', 'violin', '1712', '' ],
[ '퇴원하다', 'to be discharged from the hospital', '1713', '退院' ],
[ '언젠가', 'someday', '1714', '' ],
[ '휴대전화', 'cell phone', '1715', '携帶電話' ],
[ '물다', 'to bite; to pay (for)', '1716', '' ],
[ '절대로', 'never; absolutely, completely', '1717', '絶對' ],
[ '표현', 'expression (verbal, grammatical etc.)', '1718', '表現' ],
[ '잡히다', 'to be caught, be grabbed', '1719', '' ],
[ '억', 'hundred million', '1720', '億' ],
[ '참외', 'oriental melon', '1721', '' ],
[ '굉장히', 'very, extremely, very much', '1722', '宏壯' ],
[ '게으르다', 'to be idle, lazy', '1723', '' ],
[ '매력', 'charm, attractiveness', '1724', '魅力' ],
[ '아래층', 'downstairs', '1725', '' ],
[ '다행이다', 'to be lucky, fortunate', '1726', '多幸' ],
[ '손목', 'wrist', '1727', '' ],
[ '화분', 'flower pot', '1728', '花盆' ],
[ '떨다', 'to tremble, shake, vibrate', '1729', '' ],
[ '무궁화', 'Rose of Sharon', '1730', '無窮花' ],
[ '대중', 'the (general) public', '1731', '大衆' ],
[ '따라가다', 'to follow, go after, tag along', '1732', '' ],
[ '기술', 'skill, technique; description', '1733', '技術, 記述' ],
[ '치즈', 'cheese', '1734', '' ],
[ '주제', 'subject, theme, topic', '1735', '主題' ],
[ '커지다', 'to grow big, expand; to become serious', '1736', '' ],
[ '세배', "New Year's bow", '1737', '歲拜' ],
[ '맞은편', 'opposite side', '1738', '-便' ],
[ '저금하다', 'to save money, make a deposit', '1739', '貯金' ],
[ '설마', "impossibly, can't be true", '1740', '' ],
[ '성인', 'adult, grown-up', '1741', '成人' ],
[ '시다', 'to be sour', '1742', '' ],
[ '회', 'time, round (count.); lime; raw fish', '1743', '回, 灰, 膾' ],
[ '안방', 'master bedroom', '1744', '-房' ],
[ '받아들이다', 'to accept', '1745', '' ],
[ '장점', 'advantage, strength, strong point', '1746', '長點' ],
[ '안개', 'fog, mist', '1747', '' ],
[ '공중전화', 'public phone', '1748', '公衆電話' ],
[ '나서다', 'to leave, set out; to come forward; to meddle', '1749', '' ],
[ '좌석', 'seat', '1750', '座席' ],
[ '트럭', 'truck', '1751', '' ],
[ '집중하다', 'to concentrate', '1752', '集中' ],
[ '떡국', 'rice-cake soup', '1753', '' ],
[ '용돈', 'pocket money, allowance', '1754', '用-' ],
[ '숨다', 'to hide, disappear', '1755', '' ],
[ '초대장', '(written) invitation', '1756', '招待狀' ],
[ '손자', 'grandson', '1757', '孫子' ],
[ '자연스럽다', 'to be natural', '1758', '自然-' ],
[ '문장', 'sentence', '1759', '文章' ],
[ '물음', 'question', '1760', '' ],
[ '막', 'just, just now; severely, carelessly; curtain', '1761', '幕' ],
[ '깨지다', 'to be broken, get broken', '1762', '' ],
[ '점원', 'salesperson', '1763', '店員' ],
[ '큰일', 'serious matter', '1764', '' ],
[ '상상하다', 'to imagine', '1765', '想像' ],
[ '헬스클럽', 'health club, gym', '1766', '' ],
[ '지역', 'area, region', '1767', '地域' ],
[ '닿다', 'to reach, touch', '1768', '' ],
[ '예정', 'schedule', '1769', '豫定' ],
[ '안내문', 'notice', '1770', '案內文' ],
[ '알아듣다', 'to understand, catch, see', '1771', '' ],
[
  '영상',
  'picture, image, video; temperature above freezing',
  '1772',
  '映像, 零上'
],
[ '튀김', 'fried food', '1773', '' ],
[ '군대', 'army, troops', '1774', '軍隊' ],
[ '이루다', 'to achieve, accomplish; to make, form', '1775', '' ],
[ '당장', 'on the spot, immediately', '1776', '當場' ],
[ '검정', 'black', '1777', '' ],
[ '대하다', 'to face, confront; to treat', '1778', '對' ],
[ '엉덩이', 'butt, buttocks', '1779', '' ],
[ '편', 'side', '1780', '便' ],
[ '인터뷰', 'interview', '1781', '' ],
[ '합격하다', 'to pass an exam', '1782', '合格' ],
[ '당근', 'carrot', '1783', '' ],
[ '불교', 'Buddhism', '1784', '佛敎' ],
[
  '끼다',
  'to be caught (in), be involved; to wear; to cloud over, be foggy',
  '1785',
  ''
],
[ '최대', 'the largest, the biggest', '1786', '最大' ],
[ '영수증', 'receipt', '1787', '領收證' ],
[ '깜빡하다', "to forget, slip one's mind", '1788', '' ],
[ '사촌', 'cousin', '1789', '四寸' ],
[ '가만히', 'still, motionlessly', '1790', '' ],
[ '평생', "lifetime, one's entire life", '1791', '平生' ],
[ '꿈꾸다', 'to dream', '1792', '' ],
[ '가정', 'home, family; supposition, assumption', '1793', '家庭, 假定' ],
[ '눈앞', "in front of one's eyes, just ahead", '1794', '' ],
[ '포기하다', 'to give up', '1795', '抛棄' ],
[ '짝', 'one of a set, partner', '1796', '' ],
[ '욕심', 'greed', '1797', '慾心' ],
[ '제대로', 'appropriately, properly', '1798', '' ],
[ '위하다', 'to do for the sake of, on behalf of', '1799', '爲' ],
[ '뉴욕', 'New York', '1800', '' ],
[ '번역', 'translation', '1801', '飜譯' ],
[ '끌다', 'to pull, draw', '1802', '' ],
[ '일식집', 'Japanese restaurant', '1803', '日食-' ],
[ '안주', 'appetizers served with drinks', '1804', '按酒' ],
[ '쌀쌀하다', 'to be chilly', '1805', '' ],
[ '과장님', 'head of department, chief', '1806', '課長-' ],
[ '더욱', 'increasingly, more and more', '1807', '' ],
[ '일반', 'general, common', '1808', '一般' ],
[ '내놓다', 'to put out, take out; to show, present', '1809', '' ],
[ '택배', 'parcel delivery service', '1810', '宅配' ],
[ '배꼽', 'navel', '1811', '' ],
[ '외식하다', 'to dine out', '1812', '外食' ],
[ '아무래도', 'anyway, by the look of (it); by any means', '1813', '' ],
[ '작가', 'writer, author', '1814', '作家' ],
[ '낳다', 'to give birth', '1815', '' ],
[ '겁', 'fear, fright', '1816', '怯' ],
[ '대표', 'representative', '1817', '代表' ],
[ '살펴보다', 'to look around, watch for', '1818', '' ],
[ '공간', 'space, room', '1819', '空間' ],
[ '스타일', 'style', '1820', '' ],
[ '사자', 'lion', '1821', '獅子' ],
[ '비키다', 'get out of the way, step aside', '1822', '' ],
[ '잡채', 'noodles with sauteed vegetables', '1823', '雜菜' ],
[ '벌레', 'bug, insect', '1824', '' ],
[ '보호하다', 'to protect', '1825', '保護' ],
[ '단추', 'button', '1826', '' ],
[ '향기', 'scent, fragrance', '1827', '香氣' ],
[ '잠들다', 'to fall asleep', '1828', '' ],
[ '비용', 'expense, cost', '1829', '費用' ],
[ '여행지', 'destination', '1830', '旅行地' ],
[ '나타내다', 'to express; to show, display', '1831', '' ],
[ '발전', 'development, growth', '1832', '發展' ],
[ '딱', 'exactly, precisely', '1833', '' ],
[ '재산', 'property, fortune, wealth', '1834', '財産' ],
[ '살리다', "to save one's life, spare; to make good use", '1835', '' ],
[ '표정', 'facial expression', '1836', '表情' ],
[ '그룹', 'group', '1837', '' ],
[ '독서하다', 'to read books', '1838', '讀書' ],
[ '더위', 'the heat', '1839', '' ],
[ '분식', 'flour-based food', '1840', '粉食' ],
[ '희다', 'to be white, fair', '1841', '' ],
[ '일상생활', 'everyday life', '1842', '日常生活' ],
[ '사회', 'society, community', '1843', '社會' ],
[ '미워하다', 'to hate, loathe', '1844', '' ],
[ '분명히', 'clearly, definitely', '1845', '分明' ],
[ '보기', 'example', '1846', '' ],
[ '자료', 'materials, data', '1847', '資料' ],
[ '빠지다', 'to fall into; to fall out, come off', '1848', '' ],
[ '역할', 'role, part', '1849', '役割' ],
[ '웬일', 'what matter, what cause', '1850', '' ],
[ '관련하다', 'to be related to, connected with', '1851', '關聯' ],
[ '이미지', 'image', '1852', '' ],
[ '소녀', 'young girl', '1853', '少女' ],
[ '막다', 'to block, close', '1854', '' ],
[ '전문', 'specialty', '1855', '專門' ],
[ '매표소', 'ticket booth', '1856', '賣票所' ],
[ '알려지다', 'to become known', '1857', '' ],
[ '민족', 'people, ethnic group', '1858', '民族' ],
[ '짓', 'act, behavior', '1859', '' ],
[ '다행히', 'fortunately', '1860', '多幸' ],
[ '쓰이다', 'to be used, serve as; to be written', '1861', '' ],
[ '제품', 'product, manufactured goods', '1862', '製品' ],
[ '국물', 'soup, broth', '1863', '' ],
[ '확실하다', 'to be certain, be reliable', '1864', '確實' ],
[ '여보', 'Honey, Dear', '1865', '' ],
[
  '감정',
  'feeling, emotion; grudge, animosity; appraisal, valuation',
  '1866',
  '感情, 憾情, 鑑定'
],
[ '덮다', 'to cover, shut, close', '1867', '' ],
[ '유리창', 'glass window', '1868', '琉璃窓' ],
[ '회장', 'president, chairman; hall, venue', '1869', '會長, 會場' ],
[ '일으키다', 'to raise, set somebody upright', '1870', '' ],
[ '절대', 'absolutely, definitely', '1871', '絶對' ],
[ '골프', 'golf', '1872', '' ],
[ '개인', 'individual', '1873', '個人' ],
[ '불리다', 'to be called, be invited; to enrich oneself', '1874', '' ],
[ '전쟁', 'war', '1875', '戰爭' ],
[ '버섯', 'mushroom', '1876', '' ],
[ '해결하다', 'to solve a question, problem', '1877', '解決' ],
[ '곰', 'bear', '1878', '' ],
[ '불만', 'dissatisfaction, complaint', '1879', '不滿' ],
[ '옮기다', 'to move, transfer', '1880', '' ],
[ '실내', 'indoors', '1881', '室內' ],
[ '항공권', 'airline ticket', '1882', '航空券' ],
[ '사라지다', 'to disappear', '1883', '' ],
[ '연기', 'acting, performance; smoke', '1884', '演技, 煙氣' ],
[ '제발', 'please; by all means', '1885', '' ],
[ '현대', 'the present age, modern times', '1886', '現代' ],
[ '높이다', 'to raise, make higher', '1887', '' ],
[ '실력', 'skill, ability', '1888', '實力' ],
[ '아시아', 'Asia', '1889', '' ],
[ '구하다', 'to look for, seek; to rescue, save', '1890', '求' ],
[ '나물', 'seasoned vegetables', '1891', '' ],
[ '발표', 'announcement, presentation', '1892', '發表' ],
[ '빵집', 'bakery', '1893', '' ],
[ '환전하다', 'to exchange money', '1894', '換錢' ],
[ '꽤', 'substantially, fairly', '1895', '' ],
[ '세기', 'century', '1896', '世紀' ],
[
  '맡다',
  'to take charge of, undertake; to smell, sniff, scent',
  '1897',
  ''
],
[ '포도주', 'wine', '1898', '葡萄酒' ],
[ '과제', 'task, assignment', '1899', '課題' ],
[ '늘어나다', 'to grow longer; to increase', '1900', '' ],
[ '특징', 'distinguishing mark, characteristic', '1901', '特徵' ],
[ '막내', 'youngest child (in a family)', '1902', '' ],
[ '요새', 'these days, currently; fortress', '1903', '要塞' ],
[ '놓치다', 'to miss, let slip, fail to catch', '1904', '' ],
[ '이혼', 'divorce', '1905', '離婚' ],
[ '모델', 'model', '1906', '' ],
[ '대신하다', 'to take the place of, act as a substitute', '1907', '代身' ],
[ '그대', 'you (poet.)', '1908', '' ],
[ '욕실', 'bathroom', '1909', '浴室' ],
[ '집다', 'to pick up', '1910', '' ],
[ '장학금', 'scholarship', '1911', '奬學金' ],
[ '단체', 'organization, association, group of people', '1912', '團體' ],
[ '가난하다', 'to be poor', '1913', '' ],
[ '각종', 'every kind, various kinds', '1914', '各種' ],
[ '데', 'spot, place', '1915', '' ],
[ '회식', 'dining together (among colleagues)', '1916', '會食' ],
[
  '챙기다',
  'to gather, pack, put things in order; to take care of',
  '1917',
  ''
],
[ '과목', 'subject, course', '1918', '科目' ],
[ '어쩌면', 'maybe', '1919', '' ],
[ '참가하다', 'to participate, join', '1920', '參加' ],
[ '발목', 'ankle', '1921', '' ],
[
  '조사',
  'investigation, inquiry, survey; postposition; eulogy',
  '1922',
  '調査, 助詞, 弔辭·弔詞'
],
[ '낡다', 'to be old, worn', '1923', '' ],
[ '전문가', 'expert, specialist', '1924', '專門家' ],
[ '이하', 'below, less than', '1925', '以下' ],
[ '따라오다', 'to follow, come with', '1926', '' ],
[ '지구', 'Earth, globe; district, zone', '1927', '地球' ],
[ '팬', 'fan (a person)', '1928', '' ],
[ '명령', 'order, command', '1929', '命令' ],
[ '죽이다', 'to kill, murder; to hold back, suppress', '1930', '' ],
[ '질서', 'order, system', '1931', '秩序' ],
[ '아픔', 'pain, ache; sorrow, grief', '1932', '' ],
[ '요구하다', 'to demand, ask, claim', '1933', '要求' ],
[ '짬뽕', 'Chinese spicy noodle soup', '1934', '' ],
[ '즉시', 'at once, immediately, without delay', '1935', '卽時' ],
[ '밟다', 'to step on; to trail after', '1936', '' ],
[ '노동자', 'worker, laborer', '1937', '勞動者' ],
[ '하나씩', 'one by one', '1938', '' ],
[ '식물', 'plant, vegetation', '1939', '植物' ],
[
  '꺼지다',
  'to be extinguished, go out; to get lost, disappear (col.)',
  '1940',
  ''
],
[ '자격', 'qualification', '1941', '資格' ],
[ '대단히', 'very much, greatly, highly', '1942', '' ],
[ '감동하다', 'to be moved, touched (emotionally)', '1943', '感動' ],
[ '큰길', 'main road', '1944', '' ],
[ '의견', 'opinion, view, idea', '1945', '意見' ],
[ '더하다', 'to add (up), sum up; to get worse, grow harder', '1946', '' ],
[ '반말', 'plain speech', '1947', '半-' ],
[ '테이블', 'table', '1948', '' ],
[ '연애', 'dating; love, romance', '1949', '戀愛' ],
[ '달라지다', 'to change, alter, vary', '1950', '' ],
[ '계약', 'contract', '1951', '契約' ],
[ '숨', 'breath, breathing', '1952', '' ],
[ '속하다', 'to belong to', '1953', '屬' ],
[ '저녁때', 'evening', '1954', '' ],
[ '복도', 'hallway', '1955', '複道' ],
[ '내밀다', 'to stick out, extend', '1956', '' ],
[ '초보', 'first steps, the beginning', '1957', '初步' ],
[ '고구마', 'sweet potato', '1958', '' ],
[ '효과', 'effect, efficiency', '1959', '效果' ],
[
  '안타깝다',
  'to be heart-breaking, frustrating, regrettable',
  '1960',
  ''
],
[ '봉지', 'bag, sack', '1961', '封紙' ],
[ '마찬가지', 'the same, identical, equal', '1962', '' ],
[ '진행하다', 'to progress, proceed', '1963', '進行' ],
[ '목도리', 'scarf', '1964', '' ],
[ '신체', 'body', '1965', '身體' ],
[ '한턱내다', 'to treat to a meal or a drink', '1966', '' ],
[ '기준', 'standard, criteria', '1967', '基準' ],
[ '쇼핑센터', 'shopping center', '1968', '' ],
[ '점차', 'gradually, little by little', '1969', '漸次' ],
[ '붓다', 'to pour (into); to become swollen', '1970', '' ],
[ '수입', 'income; import', '1971', '收入, 輸入' ],
[ '파', 'green onion', '1972', '' ],
[ '녹음하다', 'to record (sound etc.)', '1973', '錄音' ],
[ '괜히', 'in vain, pointlessly, for nothing', '1974', '' ],
[ '문제점', 'problem, drawback, the point in question', '1975', '問題點' ],
[ '달려가다', 'to run, dash, rush', '1976', '' ],
[ '목표', 'target, goal', '1977', '目標' ],
[ '믿음', 'belief, trust', '1978', '' ],
[ '농사', 'farming', '1979', '農事' ],
[ '담다', 'to put into', '1980', '' ],
[ '미소', 'smile', '1981', '微笑' ],
[ '얼른', 'promptly, right away, quickly', '1982', '' ],
[ '강조하다', 'to stress, emphasize', '1983', '强調' ],
[ '모래', 'sand', '1984', '' ],
[ '주요', 'major, main, primary', '1985', '主要' ],
[ '먹이다', 'to feed, let someone eat', '1986', '' ],
[ '세계적', 'global, worldwide', '1987', '世界的' ],
[ '유럽', 'Europe', '1988', '' ],
[ '각', 'each, every; angle', '1989', '各, 角' ],
[ '선선하다', 'to be cool, refreshing', '1990', '' ],
[ '금연', 'smoking prohibited, no smoking', '1991', '禁煙' ],
[ '마음대로', "as one pleases, at one's convenience", '1992', '' ],
[
  '속상하다',
  'to be upset, distressed; to be upsetting, distressing',
  '1993',
  '-傷-'
],
[ '예전', 'the old days; before', '1994', '' ],
[ '추억', 'recollection, remembrance', '1995', '追憶' ],
[ '수많다', 'to be numerous, a lot of', '1996', '數-' ],
[ '젊은이', 'the youth, young person', '1997', '' ],
[ '공기', 'air', '1998', '空氣' ],
[ '늘리다', 'to increase, add; to extend', '1999', '' ],
[ '학습', 'studying, learning', '2000', '學習' ],
[ '어쨌든', 'anyway, in any case', '2001', '' ],
[ '사물', 'objects, things, matters', '2002', '事物' ],
[ '보여주다', 'to show, display', '2003', '' ],
[ '가짜', 'imitation, fake', '2004', '假-' ],
[ '고추', 'chili pepper', '2005', '' ],
[ '발생하다', 'to occur, arise, happen', '2006', '發生' ],
[ '밤색', 'brown, chestnut', '2007', '' ],
[ '예술가', 'artist', '2008', '藝術家' ],
[ '드레스', 'dress', '2009', '' ],
[
  '취하다',
  'to get drunk, be drunk; to choose, pick, take',
  '2010',
  '醉, 取'
],
[ '빛', 'light, ray, glow', '2011', '' ],
[ '태양', 'sun', '2012', '太陽' ],
[ '차리다', 'to set, prepare; to pull oneself together', '2013', '' ],
[
  '일단',
  'once [something has happened]; first (of several actions); for now',
  '2014',
  '一旦'
],
[ '집들이', 'housewarming party', '2015', '' ],
[ '숙소', 'lodging, accomodations', '2016', '宿所' ],
[ '끊어지다', 'to break down, break off, snap, run out', '2017', '' ],
[ '경기장', 'sports stadium', '2018', '競技場' ],
[ '원숭이', 'monkey', '2019', '' ],
[ '창피하다', 'to be ashamed, embarrassed', '2020', '猖披' ],
[ '누구나', 'anyone', '2021', '' ],
[ '적당히', 'suitably, appropriately, moderately', '2022', '適當' ],
[ '적어도', 'at least', '2023', '' ],
[ '고백하다', 'to confess, admit', '2024', '告白' ],
[ '형님', "a man's older brother (hon.)", '2025', '兄-' ],
[ '일반적', 'general, usual', '2026', '一般的' ],
[ '떨리다', 'to tremble, shake, shiver', '2027', '' ],
[ '판매', 'sale', '2028', '販賣' ],
[ '베트남', 'Vietnam', '2029', '' ],
[ '문자', 'letters, alphabet; text message', '2030', '文章' ],
[ '다가오다', 'to approach, come near', '2031', '' ],
[ '전국', 'the whole country', '2032', '全國' ],
[ '숲', 'forest', '2033', '' ],
[ '양치질하다', 'to brush teeth', '2034', '養齒-' ],
[ '그리', 'so, very; there, that way', '2035', '' ],
[ '양파', 'onion', '2036', '洋-' ],
[ '위층', 'upstairs', '2037', '' ],
[ '판단하다', 'to judge, decide, make a judgement', '2038', '判斷' ],
[ '바보', 'fool, stupid person', '2039', '' ],
[ '태극기', 'national flag of Korea', '2040', '太極旗' ],
[ '쌓이다', 'to pile up, be stacked up', '2041', '' ],
[ '소문', 'rumor, gossip', '2042', '所聞' ],
[ '어려움', 'difficulty, hardship', '2043', '' ],
[ '전자의', 'electronic', '2044', '電子' ],
[ '넘어가다', 'to cross, go over; to fall into, go down', '2045', '' ],
[ '문법', 'grammar', '2046', '文法' ],
[ '몰래', 'secretly', '2047', '' ],
[ '무시하다', 'to ignore, disregard', '2048', '無視' ],
[ '프로', 'professional', '2049', '' ],
[ '법', 'law', '2050', '法' ],
[ '가죽', 'leather', '2051', '' ],
[ '인정하다', 'to admit', '2052', '認定' ],
[ '한쪽', 'one side, one way', '2053', '' ],
[ '우동', 'thick white noodles', '2054', 'udon[饂飩]' ],
[ '달려오다', 'to come running', '2055', '' ],
[ '신경', 'nerves', '2056', '神經' ],
[ '때때로', 'occasionally', '2057', '' ],
[ '계산기', 'calculator', '2058', '計算器/計算機' ],
[ '따다', 'to pick, pluck; to obtain', '2059', '' ],
[ '박사', 'doctor, expert', '2060', '博士' ],
[ '꽃다발', 'bouquet', '2061', '' ],
[ '어색하다', 'to feel awkward, be at a loss for words', '2062', '語塞' ],
[ '마음속', "one's mind, one's heart", '2063', '' ],
[ '기초', 'foundation, basis, base', '2064', '基礎' ],
[ '햇살', 'sunlight, sunshine', '2065', '' ],
[ '주장하다', 'to assert, contend, insist', '2066', '主張' ],
[ '펜', 'pen', '2067', '' ],
[ '어차피', 'anyway, in any case', '2068', '於此彼' ],
[ '무서워하다', 'to fear, be afraid', '2069', '' ],
[ '무역', 'trade, commerce', '2070', '貿易' ],
[ '물속', 'inside water', '2071', '' ],
[ '연두색', 'light green', '2072', '軟豆色' ],
[ '놓이다', 'to be put; to feel relieved', '2073', '' ],
[ '편히', 'conveniently, comfortably', '2074', '便-' ],
[ '너머', 'beyond, the other side', '2075', '' ],
[ '기록하다', 'to record, write down; to register', '2076', '記錄' ],
[ '고객', 'customer, client', '2077', '' ],
[ '농업', 'agriculture', '2078', '農業' ],
[ '윷놀이', 'yut (Korean game)', '2079', '' ],
[
  '상관없다',
  'to not matter, not care; to have nothing to do with',
  '2080',
  '相關-'
],
[ '이리', 'wolf; here, this way', '2081', '' ],
[ '점', 'dot, point; viewpoint; fortune-telling', '2082', '點, 占' ],
[ '씩씩하다', 'to be brave, manly', '2083', '' ],
[ '각자', 'each one, each', '2084', '各自' ],
[ '말레이시아', 'Malaysia', '2085', '' ],
[ '개인적', 'personal, individual', '2086', '個人的' ],
[ '나빠지다', 'to grow worse', '2087', '' ],
[
  '인상',
  'impression; features, appearance; increase',
  '2088',
  '印象, 人相, 引上'
],
[ '지붕', 'roof', '2089', '' ],
[ '개발하다', 'to develop, improve', '2090', '開發' ],
[ '추위', 'cold weather', '2091', '' ],
[ '고통', 'pain, suffering', '2092', '苦痛' ],
[ '병들다', 'to become sick', '2093', '病-' ],
[ '과학자', 'scientist', '2094', '科學者' ],
[ '꿀', 'honey', '2095', '' ],
[ '예절', 'manners, etiquette', '2096', '禮節' ],
[ '이루어지다', 'to come true; to be formed, consist (of)', '2097', '' ],
[ '최선', 'the best', '2098', '最善' ],
[ '왠지', 'somehow, for some reason', '2099', '' ],
[ '후회하다', 'to regret', '2100', '後悔' ],
[ '몸무게', 'weight', '2101', '' ],
[ '통일', 'unity, unification', '2102', '統一' ],
[ '끄덕이다', 'to nod', '2103', '' ],
[ '일정', 'schedule', '2104', '日程' ],
[ '에너지', 'energy', '2105', '' ],
[ '구체적', 'concrete, definite, specific', '2106', '具體的' ],
[ '견디다', 'to bear, endure, tolerate', '2107', '' ],
[
  '관리',
  'administration, management; government official',
  '2108',
  '管理, 官吏'
],
[ '그만큼', 'that much, to that extent', '2109', '' ],
[ '포함하다', 'to include, contain', '2110', '包含' ],
[ '새끼', 'young animal; fellow', '2111', '' ],
[ '철', 'iron, steel; season; discretion, [good] sense', '2112', '鐵' ],
[ '받아쓰다', 'to take dictation, write down', '2113', '' ],
[ '확실히', 'certainly, for sure', '2114', '確實' ],
[ '오징어', 'squid, cuttlefish', '2115', '' ],
[ '종교', 'religion', '2116', '宗敎' ],
[ '묶다', 'to tie, bind', '2117', '' ],
[ '촬영', 'shooting, filming', '2118', '撮影' ],
[ '마침내', 'finally, eventually, at last', '2119', '' ],
[
  '향하다',
  'to face, look out at; to proceed to, lean towards',
  '2120',
  '向'
],
[ '만남', 'meeting', '2121', '' ],
[ '공동', 'joint, group, collaboration', '2122', '共同' ],
[ '가득하다', 'to be filled (with)', '2123', '' ],
[ '소비자', 'consumer', '2124', '消費者' ],
[ '와이셔츠', 'dress shirt', '2125', '' ],
[ '이익', 'profit, gain, advantage', '2126', '利益' ],
[ '신나다', 'to be excited', '2127', '' ],
[ '상황', 'situation, state of things', '2128', '狀況' ],
[ '흙', 'earth, soil, dirt', '2129', '' ],
[ '참석하다', 'to attend, be present', '2130', '參席' ],
[ '밤새도록', 'all night long', '2131', '' ],
[ '탁자', 'tea table', '2132', '卓子' ],
[ '싸움하다', 'to fight, quarrel', '2133', '' ],
[ '역사적', 'historical', '2134', '歷史的' ],
[ '밥솥', 'rice cooker', '2135', '' ],
[ '충격', 'shock', '2136', '衝擊' ],
[ '쏟다', 'to spill, pour', '2137', '' ],
[
  '상대방',
  'the other party, the opposing team, adversary',
  '2138',
  '相對方'
],
[ '겨우', 'barely', '2139', '' ],
[ '복사하다', 'to reproduce, copy', '2140', '複寫' ],
[ '가스', 'gas', '2141', '' ],
[ '조상', 'ancestor, forefather', '2142', '祖上' ],
[ '꾸미다', 'to decorate, make up; to invent, prepare', '2143', '' ],
[ '미술', 'art', '2144', '美術' ],
[ '쥐', 'rat; mouse', '2145', '' ],
[ '적극적', 'positive, active', '2146', '積極的' ],
[ '날아가다', 'to fly away', '2147', '' ],
[ '수표', 'check', '2148', '手票' ],
[ '흔히', 'commonly, frequently, generally', '2149', '' ],
[ '저축하다', 'to save up, deposit', '2150', '貯蓄' ],
[ '낮잠', 'nap', '2151', '' ],
[ '교포', 'ethnic Korean living abroad', '2152', '僑胞' ],
[ '담기다', 'to be put in, be included', '2153', '' ],
[ '근무', 'service, work, duty', '2154', '勤務' ],
[ '코피', 'nosebleed', '2155', '' ],
[ '건축', 'architecture, construction', '2156', '建築' ],
[ '멀어지다', 'to grow apart, drift apart', '2157', '' ],
[ '주인공', 'protagonist, main character', '2158', '主人公' ],
[ '곁', 'side, beside', '2159', '' ],
[ '발달하다', 'to develop', '2160', '發達' ],
[ '패션', 'fashion, style', '2161', '' ],
[ '평화', 'peace', '2162', '平和' ],
[ '조심스럽다', 'to be careful, cautious', '2163', '' ],
[ '토론', 'discussion, debate', '2164', '討論' ],
[ '너무나', 'too much', '2165', '' ],
[ '존댓말', 'polite/formal language (speech)', '2166', '尊待-' ],
[
  '가리다',
  'to cover, hide, shield; to distinguish, differentiate',
  '2167',
  ''
],
[ '최초', 'the very first', '2168', '最初' ],
[ '그녀', 'she', '2169', '' ],
[ '고려하다', 'to consider, take into account', '2170', '考慮' ],
[ '어둠', 'darkness', '2171', '' ],
[ '양', 'quantity, amount; sheep, lamb', '2172', '量, 羊' ],
[ '흔들리다', 'to shake, sway, quake', '2173', '' ],
[ '야외', 'outside, open air', '2174', '野外' ],
[ '마늘', 'garlic', '2175', '' ],
[ '사회적', 'social', '2176', '社會的' ],
[ '쥐다', 'to grip, clench, grasp', '2177', '' ],
[ '관객', 'audience, spectator', '2178', '觀客' ],
[ '스카프', 'scarf', '2179', '' ],
[ '평범하다', 'to be ordinary, common', '2180', '平凡' ],
[ '마치', 'as though, as if', '2181', '' ],
[ '애완동물', 'pet', '2182', '愛玩動物' ],
[ '다가가다', 'to approach, go near', '2183', '' ],
[ '업무', 'business, duty', '2184', '業務' ],
[ '한숨', 'sigh; rest, pause', '2185', '' ],
[ '적', 'enemy, opponent, rival', '2186', '敵' ],
[
  '맡기다',
  'to leave something with someone, to entrust, deposit',
  '2187',
  ''
],
[ '외삼촌', 'maternal uncle', '2188', '外三寸' ],
[ '살짝', 'slightly, gently; in secret, by stealth', '2189', '' ],
[ '상하다', 'to go bad, spoil; to be hurt', '2190', '傷' ],
[ '사이다', 'lemon-lime soda', '2191', '' ],
[ '배경', 'background', '2192', '背景' ],
[ '벌어지다', 'to get wider; to happen, break out', '2193', '' ],
[ '전체적', 'overall, general', '2194', '全體的' ],
[ '스케줄', 'schedule', '2195', '' ],
[ '이성', 'reason, rationality; the opposite sex', '2196', '理性, 異性' ],
[ '없애다', 'to remove, get rid of; to waste', '2197', '' ],
[ '무용', 'dance', '2198', '舞踊' ],
[ '몇몇', 'some, several', '2199', '' ],
[ '양보하다', 'to yield, give, concede', '2200', '讓步' ],
[ '온', 'all, entire, whole', '2201', '' ],
[ '주택', 'house, housing', '2202', '住宅' ],
[ '넘치다', 'to overflow, exceed', '2203', '' ],
[ '하여튼', 'anyway, anyhow, in any case', '2204', '何如-' ],
[ '그늘', 'shade', '2205', '' ],
[ '현관', 'front door, entrance, foyer', '2206', '玄關' ],
[ '젓다', 'to stir, whip; to row; to gesticulate', '2207', '' ],
[ '파도', 'waves', '2208', '波濤' ],
[ '동아리', 'club, society, group', '2209', '' ],
[ '예상하다', 'to forecast, anticipate, expect', '2210', '豫想' ],
[ '액세서리', 'accessories', '2211', '' ],
[ '단지', 'simply, merely, just; apartment complex', '2212', '但只, 團地' ],
[ '떨어뜨리다', 'to drop (something)', '2213', '' ],
[ '규칙적', 'regular', '2214', '規則的' ],
[ '눈썹', 'eyebrow', '2215', '' ],
[ '죽', 'porridge, gruel; in a row', '2216', '粥' ],
[ '편찮다', 'to be unwell, sick', '2217', '便-' ],
[ '축제', 'festival', '2218', '祝祭' ],
[ '도둑', 'thief', '2219', '' ],
[ '불가능하다', 'to be impossible', '2220', '不可能' ],
[ '게다가', 'besides, on top of that', '2221', '' ],
[ '시집', "one's husband's home; collection of poems", '2222', '媤―, 詩集' ],
[ '졸리다', 'to feel sleepy', '2223', '' ],
[ '정상', 'normality; top, summit', '2224', '正常, 頂上' ],
[ '도시락', '(packed) lunch', '2225', '' ],
[ '혹은', 'or', '2226', '或-' ],
[ '소리치다', 'to shout, yell', '2227', '' ],
[ '별일', 'special event, unusual occurence', '2228', '別-' ],
[ '플라스틱', 'plastic', '2229', '' ],
[ '보고하다', 'to report, inform', '2230', '報告' ],
[ '맨날', 'always, every day (less formal)', '2231', '' ],
[ '절', 'Buddhist temple; deep bow; clause, verse', '2232', '節' ],
[ '채우다', 'to fill up; to lock, fasten', '2233', '' ],
[ '대표적', 'representative, typical', '2234', '代表的' ],
[ '뿌리', 'root', '2235', '' ],
[ '시인', 'poet', '2236', '詩人' ],
[ '올려놓다', 'to put a thing on a place', '2237', '' ],
[ '통신', 'communication, correspondence', '2238', '通信' ],
[ '어느새', 'in no time, quickly', '2239', '' ],
[ '활용하다', 'to utilize, put to practical use', '2240', '活用' ],
[ '눈빛', 'eyes, look', '2241', '' ],
[ '정식', 'formal, official', '2242', '正式' ],
[ '커다랗다', 'to be very big, huge', '2243', '' ],
[ '우연히', 'by chance, accidentally', '2244', '偶然' ],
[ '딴', 'other, different', '2245', '' ],
[ '흥미', 'interest', '2246', '興味' ],
[ '지치다', 'to be exhausted, worn out', '2247', '' ],
[ '수상', 'prime minister', '2248', '首相' ],
[ '채널', 'channel', '2249', '' ],
[ '관찰하다', 'to observe, watch', '2250', '觀察' ],
[ '켤레', 'a pair', '2251', '' ],
[ '요청', 'request, demand', '2252', '要請' ],
[
  '틀림없다',
  'to be certain, beyond a doubt; to be trustworthy',
  '2253',
  ''
],
[ '비교적', 'relatively, comparatively', '2254', '比較的' ],
[ '마침', 'coincidently, just', '2255', '' ],
[ '동양', 'the East', '2256', '東洋' ],
[ '닫히다', 'to be closed, to shut', '2257', '' ],
[ '인구', 'population', '2258', '人口' ],
[ '즐거움', 'pleasure, joy, happiness', '2259', '' ],
[ '허락하다', 'to allow, permit', '2260', '許諾' ],
[ '몸살', 'body ache', '2261', '' ],
[ '즉', 'namely, so to speak', '2262', '卽' ],
[ '살려주다', 'to save, rescue, spare', '2263', '' ],
[ '중요성', 'importance, significance', '2264', '重要性' ],
[ '날개', 'wings', '2265', '' ],
[ '도전', 'challenge', '2266', '挑戰' ],
[ '뒤집다', 'to turn over, turn inside out, reverse', '2267', '' ],
[ '임금', 'wages, pay; king, monarch', '2268', '賃金' ],
[ '오피스텔', 'studio apartment', '2269', '' ],
[ '시도하다', 'to try, attempt', '2270', '試圖' ],
[ '단맛', 'sweet taste', '2271', '' ],
[ '사정', 'reason; situation, circumstances', '2272', '事情' ],
[ '넘어서다', 'to pass over, exceed', '2273', '' ],
[ '당연히', 'naturally, of course', '2274', '當然' ],
[ '턱', 'chin, jaw; elevated place; feast', '2275', '' ],
[ '시외', 'the suburbs, outside the city limits', '2276', '市外' ],
[ '울리다', 'to ring; to make someone cry', '2277', '' ],
[ '과학적', 'scientific', '2278', '科學的' ],
[ '껍질', 'skin, bark, peel, nutshell', '2279', '' ],
[ '솔직하다', 'to be frank, honest, candid', '2280', '率直' ],
[ '사이좋게', 'on good terms, friendly, in harmony', '2281', '' ],
[ '종합', 'synthesis, putting together', '2282', '綜合' ],
[ '넓어지다', 'to widen', '2283', '' ],
[ '우승', 'victory, championship', '2284', '優勝' ],
[ '나뭇잎', 'leaf', '2285', '' ],
[ '세금', 'tax', '2286', '稅金' ],
[ '가꾸다', 'to grow, cultivate; to take care of', '2287', '' ],
[ '일등', 'the first class; the first place', '2288', '一等' ],
[ '콧물', 'snot, nasal mucus', '2289', '' ],
[ '반복하다', 'to repeat', '2290', '反復' ],
[ '미디어', 'media', '2291', '' ],
[ '예금', 'deposit, savings', '2292', '預金' ],
[ '자랑스럽다', 'to be proud', '2293', '' ],
[ '영원히', 'forever, eternally', '2294', '永遠' ],
[ '수출', 'export', '2295', '輸出' ],
[ '풀리다', 'to get solved, get loose, be relieved', '2296', '' ],
[ '오해', 'misunderstanding', '2297', '誤解' ],
[ '빗', 'comb', '2298', '' ],
[ '자유롭다', 'to be free', '2299', '自由-' ],
[ '구멍', 'hole', '2300', '' ],
[ '초보자', 'beginner, novice', '2301', '初步者' ],
[ '마주보다', 'to look at each other', '2302', '' ],
[ '회화', 'conversation; painting', '2303', '會話, 繪畵' ],
[ '정신적', 'mental', '2304', '精神的' ],
[ '얹다', 'to put on, place', '2305', '' ],
[ '사건', 'incident, affair, event', '2306', '事件' ],
[ '함부로', 'thoughtlessly, carelessly', '2307', '' ],
[ '표시하다', 'to mark, indicate; to express, show', '2308', '標示, 表示' ],
[ '뚜껑', 'lid, cover', '2309', '' ],
[ '대기업', 'large company', '2310', '大企業' ],
[ '뛰어다니다', 'to run around', '2311', '' ],
[ '고급', 'high rank', '2312', '高級' ],
[ '부동산', 'real estate, property', '2313', '不動産' ],
[ '벌이다', 'to start, set up, stage', '2314', '' ],
[ '유교', 'Confucianism', '2315', '儒敎' ],
[ '시리즈', 'series', '2316', '' ],
[ '당황하다', 'to be embarrassed, flustered, confused', '2317', '唐慌' ],
[ '풀', 'grass; glue', '2318', '' ],
[ '부족', 'tribe; shortage, lack', '2319', '部族, 不足' ],
[ '정해지다', 'to be set, arranged, determined', '2320', '定' ],
[ '경영', '(business) management, administration', '2321', '經營' ],
[ '자동판매기', 'vending machine', '2322', '自動販賣機' ],
[ '뿌리다', 'to sprinkle, spray, scatter', '2323', '' ],
[ '상태', 'condition, state', '2324', '狀態' ],
[ '새우', 'shrimp, prawn', '2325', '' ],
[ '활발하다', 'to be lively, active', '2326', '活潑' ],
[ '곳곳', 'here and there, in several places', '2327', '' ],
[ '보험', 'insurance', '2328', '保險' ],
[ '생겨나다', 'to come into existence, occur', '2329', '' ],
[ '대통령', 'president (of a country)', '2330', '大統領' ],
[ '금', 'gold; line', '2331', '金' ],
[
  '놀랍다',
  'to be surprising, startling; to be wonderful, marvelous',
  '2332',
  ''
],
[ '담요', 'blanket', '2333', '潰-' ],
[ '코트', 'coat', '2334', '' ],
[ '적용하다', 'to apply (a rule, changes, etc.)', '2335', '適用' ],
[ '무늬', 'design, pattern, figure', '2336', '' ],
[ '정장', 'formal wear (outfit, dress, suit)', '2337', '正裝' ],
[ '머무르다', 'to stay, remain', '2338', '' ],
[ '상대', 'business school; counterpart', '2339', '商大, 相對' ],
[ '보고서', 'report, paper', '2340', '報告書' ],
[ '털다', 'to dust off, brush off; to rob', '2341', '' ],
[ '장래', 'the future', '2342', '將來' ],
[ '손톱', 'fingernail', '2343', '' ],
[ '우울하다', 'to be gloomy, cheerless, depressed', '2344', '憂鬱' ],
[ '오리', 'duck', '2345', '' ],
[ '복', 'blessing, fortune', '2346', '福' ],
[ '적어지다', 'to decrease, drop', '2347', '' ],
[ '무조건', 'unconditional', '2348', '無條件' ],
[ '의심', 'doubt, suspicion', '2349', '疑心' ],
[ '괴롭다', 'to be painful, distressing', '2350', '' ],
[ '외교', 'diplomacy', '2351', '外交' ],
[ '베개', 'pillow', '2352', '' ],
[ '신기하다', 'to be marvelous, wonderful, amazing', '2353', '神奇' ],
[ '테이프', 'tape', '2354', '' ],
[ '성별', 'sex, gender', '2355', '性別' ],
[ '둘러보다', 'to look around', '2356', '' ],
[ '한국적', 'Korean', '2357', '韓國的' ],
[ '증상', 'symptom', '2358', '症狀' ],
[ '때리다', 'to beat, hit, attack', '2359', '' ],
[ '신', 'shoes, footwear; God; joy, delight', '2360', '神' ],
[ '옥수수', 'corn', '2361', '' ],
[ '위로하다', 'to console, comfort', '2362', '慰勞' ],
[ '죽음', 'death', '2363', '' ],
[ '미인', 'beautiful woman', '2364', '美人' ],
[
  '아무렇지도 않다',
  'to be indifferent, unconcerned; to be safe, all right',
  '2365',
  ''
],
[ '저리', 'that way, that direction; low interest (rate)', '2366', '低利' ],
[ '시청자', 'viewer, TV audience', '2367', '視聽者' ],
[ '태우다', 'to burn; to give a ride, take aboard', '2368', '' ],
[ '각국', 'every country', '2369', '各國' ],
[
  '송이',
  'counter for clusters of grapes, bunches of flowers and similar',
  '2370',
  ''
],
[ '감상하다', 'to enjoy, appreciate', '2371', '鑑賞' ],
[ '바위', 'rock', '2372', '' ],
[ '기념', 'commemoration', '2373', '記念·紀念' ],
[ '찾아내다', 'to discover, locate, come up with', '2374', '' ],
[ '과정', 'process; course', '2375', '過程, 課程' ],
[ '퇴근시간', 'closing time', '2376', '退勤時間' ],
[ '건지다', 'to take out of water; to take back', '2377', '' ],
[ '악수', 'handshake', '2378', '握手' ],
[ '놈', 'guy; jerk', '2379', '' ],
[ '신고하다', 'to report, declare, notify', '2380', '申告' ],
[ '챔피언', 'champion', '2381', '' ],
[ '석유', 'oil, petroleum', '2382', '石油' ],
[ '날아오다', 'to fly', '2383', '' ],
[ '최소한', 'at the (very) least', '2384', '最小限' ],
[ '수년', 'a number of years', '2385', '數年' ],
[ '몰다', 'to drive, steer', '2386', '' ],
[ '향수', 'perfume, fragrance; homesickness', '2387', '香水, 鄕愁' ],
[ '큰아들', "one's eldest son", '2388', '' ],
[ '출연하다', 'to appear (in a movie etc.)', '2389', '出演' ],
[ '내내', 'throughout, all the time, from start to finish', '2390', '' ],
[ '국기', 'national flag', '2391', '國旗' ],
[ '쏟아지다', 'to pour, get spilt', '2392', '' ],
[ '역사가', 'historian', '2393', '歷史家' ],
[ '이동', 'movement, transfer', '2394', '移動' ],
[ '둥글다', 'to be round, spherical', '2395', '' ],
[ '여관', 'inn', '2396', '旅館' ],
[ '뼈', 'bone', '2397', '' ],
[ '임신하다', 'to be pregnant', '2398', '姙娠' ],
[ '골목', 'alley', '2399', '' ],
[ '농촌', 'farm village', '2400', '農村' ],
[ '흘러나오다', 'to flow out', '2401', '' ],
[ '신용', 'trust, credibility, credit', '2402', '信用' ],
[ '인삼차', 'ginseng tea', '2403', '人蔘茶' ],
[ '낯설다', 'to be unfamiliar', '2404', '' ],
[ '남매', 'brother and sister', '2405', '男妹' ],
[ '커튼', 'curtain', '2406', '' ],
[ '우수하다', 'to be superior, excellent', '2407', '優秀' ],
[ '까닭', 'reason, cause, grounds (for)', '2408', '' ],
[ '담임', 'home room teacher', '2409', '擔任' ],
[ '들여다보다', 'to look into, peep, gaze', '2410', '' ],
[ '정치적', 'political', '2411', '政治的' ],
[ '벌', 'punishment, penalty; bee', '2412', '罰' ],
[ '썩다', 'to rot, decay, go bad', '2413', '' ],
[ '기업', 'enterprise, business', '2414', '企業' ],
[ '강물', 'river water', '2415', '江-' ],
[ '확대하다', 'to expand, magnify', '2416', '擴大' ],
[ '겨울철', 'winter', '2417', '' ],
[ '조각', 'piece, slice; sculpture', '2418', '彫刻' ],
[ '두드리다', 'to knock, tap, rap; to beat, strike', '2419', '' ],
[ '근로자', 'worker, working people', '2420', '勤勞者' ],
[ '다수', 'large number, majority', '2421', '多數' ],
[
  '아깝다',
  'to be precious, valuable; to be regrettable, pitiful',
  '2422',
  ''
],
[ '도중에', 'on the way', '2423', '途中' ],
[ '씨름', 'wrestling', '2424', '' ],
[ '전달하다', 'to deliver, convey', '2425', '傳達' ],
[ '아프리카', 'Africa', '2426', '' ],
[ '탑', 'tower, pagoda', '2427', '塔' ],
[ '들려오다', "to reach one's ears", '2428', '' ],
[ '승용차', 'passenger car', '2429', '乘用車' ],
[ '동창', 'fellow students, classmates', '2430', '同窓' ],
[ '찌르다', 'to pierce, stab', '2431', '' ],
[ '부담', 'burden', '2432', '負擔' ],
[ '인사동', 'Insadong', '2433', '' ],
[ '진출하다', 'to advance (into), enter', '2434', '進出' ],
[ '땅콩', 'peanut', '2435', '' ],
[ '대문', 'gate, front gate', '2436', '大門' ],
[
  '엉뚱하다',
  'to be extraordinary, extravagant, unreasonable',
  '2437',
  ''
],
[ '신입생', 'freshman', '2438', '新入生' ],
[ '여유', 'time/money to spare, composure', '2439', '餘裕' ],
[ '숨쉬다', 'to breathe', '2440', '' ],
[ '정', 'affection, attachment, emotion', '2441', '情' ],
[ '곧바로', 'at once, straight', '2442', '' ],
[ '귀국하다', "to return to one's home country", '2443', '歸國' ],
[ '레몬', 'lemon', '2444', '' ],
[ '제한', 'limit, restriction', '2445', '制限' ],
[ '달아나다', 'to run away, escape', '2446', '' ],
[ '강제', 'coercion, by force', '2447', '强制' ],
[ '수돗물', 'tap water', '2448', '水道-' ],
[
  '맞서다',
  'to stand opposite someone, stand against, oppose',
  '2449',
  ''
],
[ '교류', 'exchange, interchange', '2450', '交流' ],
[ '양쪽', 'both sides', '2451', '兩-' ],
[ '만족하다', 'to be satisfied, pleased', '2452', '滿足' ],
[ '준비물', 'preparation material, stuff', '2453', '準備物' ],
[ '무렵', 'time', '2454', '' ],
[ '일상', 'everyday, daily life', '2455', '日常' ],
[ '돌보다', 'to look after, take care of', '2456', '' ],
[ '운', 'luck, fortune, fate', '2457', '運' ],
[ '오늘날', 'today, present', '2458', '' ],
[
  '반하다',
  "to fall in love, be charmed, lose one's heart; to be against",
  '2459',
  '反'
],
[ '체중', 'body weight', '2460', '體重' ],
[ '몹시', 'extremely, awfully, very', '2461', '' ],
[ '명함', 'business card', '2462', '名銜·名啣' ],
[ '떼다', 'to take off, remove, detach', '2463', '' ],
[ '대중교통', 'public transportation', '2464', '大衆交通' ],
[ '달빛', 'moonlight', '2465', '' ],
[ '논의하다', 'to discuss, debate', '2466', '論議' ],
[ '배구', 'volleyball', '2467', '排球' ],
[ '모니터', 'monitor', '2468', '' ],
[ '장남', 'eldest son', '2469', '長男' ],
[ '잘살다', 'to be rich, live affluently', '2470', '' ],
[ '신문지', 'newspaper', '2471', '新聞紙' ],
[ '삶', 'life', '2472', '' ],
[ '소용없다', 'to be useless', '2473', '所用' ],
[ '실제', 'truth, reality', '2474', '實際' ],
[ '집안', 'home, family, household', '2475', '' ],
[ '사무', 'office work', '2476', '事務' ],
[ '펼쳐지다', 'to spread, stretch out, unfold', '2477', '' ],
[ '감동적', 'moving, touching, impressive', '2478', '感動的' ],
[ '나비', 'butterfly', '2479', '' ],
[ '등록하다', 'to register, enroll', '2480', '登錄' ],
[ '심리', 'mentality; court hearing, trial', '2481', '心理, 審理' ],
[ '머릿속', "inside one's head, mind", '2482', '' ],
[ '국어', 'national language; mother tongue', '2483', '國語' ],
[ '담그다', 'to soak, immerse', '2484', '' ],
[ '책임자', 'person in charge', '2485', '責任者' ],
[ '참기름', 'sesame oil', '2486', '' ],
[ '영원하다', 'to be eternal', '2487', '永遠' ],
[ '질', 'quality', '2488', '質' ],
[ '마사지', 'massage', '2489', '' ],
[ '당시', 'at that time, in those days', '2490', '當時' ],
[ '앓다', 'to be ill, suffer, be troubled', '2491', '' ],
[ '소화제', 'digestive medicine', '2492', '消化劑' ],
[ '며느리', 'daughter-in-law', '2493', '' ],
[ '비판하다', 'to criticize, pass judgement', '2494', '批判' ],
[ '규모', 'scale, scope', '2495', '規模' ],
[ '이튿날', 'the next day', '2496', '' ],
[ '저자', 'author', '2497', '著者' ],
[ '내려다보다', 'to look down, overlook', '2498', '' ],
[ '사용자', 'user, consumer', '2499', '使用者' ],
[ '기운', 'energy, vigor, strength', '2500', '' ],
[ '연하다', 'to be soft, light, tender', '2501', '軟' ],
[ '본래', 'originally, primarily, by nature', '2502', '本來' ],
[ '뺨', 'cheek', '2503', '' ],
[ '자동', 'automatic', '2504', '自動' ],
[ '넓히다', 'to widen, expand', '2505', '' ],
[ '긍정적', 'positive, affirmative', '2506', '肯定的' ],
[ '팬티', 'panties, briefs', '2507', '' ],
[ '회복하다', 'to recover, regain, get better', '2508', '恢復' ],
[ '반장', 'class president, leader', '2509', '班長' ],
[ '놀이터', 'playground', '2510', '' ],
[ '후식', 'dessert', '2511', '後食' ],
[ '흘러가다', 'to flow, run', '2512', '' ],
[ '일회용', 'disposable', '2513', '一回用' ],
[ '어쩐지', 'somehow', '2514', '' ],
[ '불행하다', 'to be unhappy, unfortunate', '2515', '不幸' ],
[ '인원', 'the number of people', '2516', '人員' ],
[ '오른발', 'right foot', '2517', '' ],
[ '도구', 'tool, instrument', '2518', '道具' ],
[ '먹히다', 'to be eaten, be swallowed', '2519', '' ],
[ '졸업생', 'graduate', '2520', '卒業生' ],
[ '알', 'egg, spawn', '2521', '' ],
[ '상대하다', 'to deal with', '2522', '相對' ],
[ '피로', 'fatigue', '2523', '疲勞' ],
[ '유난히', 'unusually, exceptionally', '2524', '' ],
[ '섭씨', 'Celsius', '2525', '攝氏' ],
[ '사랑스럽다', 'to be lovely, adorable', '2526', '' ],
[ '도대체', '(what) on earth, in the world; at all', '2527', '都大體' ],
[ '빗물', 'rainwater', '2528', '' ],
[ '용서하다', 'to forgive', '2529', '容恕' ],
[ '부근', 'neighborhood', '2530', '附近' ],
[ '벨트', 'belt', '2531', '' ],
[ '창고', 'warehouse, storage', '2532', '倉庫' ],
[ '오가다', 'to come and go', '2533', '' ],
[ '부작용', 'side effect', '2534', '副作用' ],
[ '주먹', 'fist', '2535', '' ],
[ '허용되다', 'to be permitted, be approved', '2536', '許容' ],
[ '낙엽', 'fallen leaves', '2537', '落葉' ],
[ '콩나물', 'bean sprouts', '2538', '' ],
[ '현실', 'actuality, reality', '2539', '現實' ],
[ '빌다', 'to ask, beg; to wish, pray; to apologize', '2540', '' ],
[ '전통적', 'traditional, conventional', '2541', '傳統的' ],
[ '스님', 'monk, priest', '2542', '' ],
[ '합치다', 'to combine, unite, put together', '2543', '合' ],
[ '임시', 'temporary', '2544', '臨時' ],
[ '털', 'hair, fur, feather', '2545', '' ],
[ '동료', 'colleague, co-worker', '2546', '同僚' ],
[ '내려놓다', 'to put down, take down', '2547', '' ],
[ '가습기', 'humidifier', '2548', '加濕器' ],
[ '미팅', 'blind date', '2549', '' ],
[ '담당하다', 'to undertake, be in charge', '2550', '擔當' ],
[ '특성', 'characteristic, nature, property', '2551', '特性' ],
[ '엊그제', 'a couple of days ago', '2552', '' ],
[
  '동화',
  "fairy tale, children's story; assimilation",
  '2553',
  '童話, 同和'
],
[ '아쉽다', 'to be sorry, sad', '2554', '' ],
[ '연구소', 'research institute', '2555', '硏究所' ],
[ '꿈속', 'inside a dream', '2556', '' ],
[ '절약하다', 'to economize, save', '2557', '節約' ],
[ '심장', 'the heart (in the body)', '2558', '心臟' ],
[ '받침', 'support, prop, final consonant', '2559', '' ],
[ '제공', 'offer', '2560', '提供' ],
[ '얕다', 'to be shallow', '2561', '' ],
[ '도자기', 'ceramic ware, pottery', '2562', '陶瓷器' ],
[ '왼발', 'left foot', '2563', '' ],
[ '해석하다', 'to interpret', '2564', '解釋' ],
[ '기능', 'function; skill', '2565', '機能, 技能' ],
[ '부끄러움', 'shyness, bashfulness, shame', '2566', '' ],
[ '통', 'totally, not at all; barrel, can, cask', '2567', '桶' ],
[ '자라나다', 'to grow', '2568', '' ],
[ '소설가', 'novelist', '2569', '小說家' ],
[ '터널', 'tunnel', '2570', '' ],
[ '보관하다', 'to keep, store, take charge of', '2571', '保管' ],
[ '작품', 'piece of artwork', '2572', '作品' ],
[ '그림자', 'shadow', '2573', '' ],
[ '가치', 'value, worth', '2574', '價値' ],
[ '당기다', 'to pull, draw, yank', '2575', '' ],
[ '대도시', 'big city', '2576', '大都市' ],
[ '볼일', 'business, things to do', '2577', '' ],
[ '경고하다', 'to warn', '2578', '警告' ],
[ '국립', 'national establishment', '2579', '國立' ],
[ '하양', 'white, whiteness', '2580', '' ],
[ '예외', 'exception', '2581', '例外' ],
[ '마련하다', 'to prepare, arrange', '2582', '' ],
[ '신혼부부', 'newly wed couple', '2583', '新婚夫婦' ],
[ '손뼉', 'palm', '2584', '' ],
[ '권하다', 'to advise, recommend, offer', '2585', '勸' ],
[ '암', 'cancer', '2586', '癌' ],
[ '소스', 'sauce', '2587', '' ],
[ '감히', 'boldly, daringly', '2588', '敢-' ],
[ '곱다', 'to be pretty, lovely', '2589', '' ],
[ '자가용', "one's own car", '2590', '自家用' ],
[ '부처', 'Buddha', '2591', '' ],
[ '제출하다', 'to submit, hand in, present', '2592', '提出' ],
[ '원인', 'cause, factor', '2593', '原因' ],
[ '밥그릇', 'rice bowl', '2594', '' ],
[ '현상', 'the present situation, existing circumstances', '2595', '現象' ],
[ '말리다', 'to dry; to make somebody stop doing something', '2596', '' ],
[ '경제적', 'financial, economic', '2597', '經濟的' ],
[ '밥맛', 'appetite', '2598', '' ],
[ '입장하다', 'to enter, get into, obtain admission', '2599', '入場' ],
[ '주민', 'resident, inhabitant', '2600', '住民' ],
[ '손잡이', 'handle, knob', '2601', '' ],
[ '행운', 'good luck, good fortune', '2602', '幸運' ],
[ '다녀가다', 'to drop by', '2603', '' ],
[ '음악회', 'concert', '2604', '音樂會' ],
[ '거짓', 'false, falsehood, fabrication', '2605', '' ],
[ '위반하다', 'to violate, break', '2606', '違反' ],
[ '배낭', 'backpack', '2607', '背囊' ],
[ '박스', 'box', '2608', '' ],
[ '승진', 'promotion, advancement', '2609', '昇進' ],
[ '벌리다', 'to open, widen, stretch out', '2610', '' ],
[ '외교관', 'diplomat', '2611', '外交官' ],
[ '헌', 'old, shabby, used', '2612', '' ],
[ '일정하다', 'to be fixed, constant, regular', '2613', '一定' ],
[ '강도', 'strength, intensity; robber, robbery', '2614', '强度, 强盜' ],
[ '일부러', 'intentionally, on purpose', '2615', '' ],
[ '증세', 'symptom', '2616', '症勢' ],
[ '뻗다', 'to reach, stretch out, spread', '2617', '' ],
[ '재작년', 'two years ago', '2618', '再昨年' ],
[ '양념', 'spices and condiments', '2619', '' ],
[ '당하다', 'to experience, encounter, suffer', '2620', '當' ],
[ '우주', 'universe', '2621', '宇宙' ],
[ '여름철', 'summertime', '2622', '' ],
[ '형태', 'form (more abstract)', '2623', '形態' ],
[ '돌아서다', 'to turn (around)', '2624', '' ],
[ '등록금', 'tuition', '2625', '登錄金' ],
[ '와인', 'wine', '2626', '' ],
[ '연기하다', 'to delay, postpone, put off; to act', '2627', '延期, 演技' ],
[ '무대', 'stage', '2628', '舞臺' ],
[ '꼭대기', 'the top', '2629', '' ],
[ '구조', 'structure, organization; rescue', '2630', '構造, 救助' ],
[ '짙다', 'to be dark, deep, thick', '2631', '' ],
[ '독일어', 'German', '2632', '獨逸語' ],
[ '달리', 'differently', '2633', '' ],
[ '피하다', 'to avoid', '2634', '避' ],
[ '의류', 'clothing, clothes', '2635', '衣類' ],
[ '불빛', 'light, glow', '2636', '' ],
[ '입장', 'position, situation; admission', '2637', '立場, 入場' ],
[ '떠올리다', 'to recall, recollect', '2638', '' ],
[ '주유소', 'gas station', '2639', '注油所' ],
[ '먼지', 'dust', '2640', '' ],
[ '생산하다', 'to produce, make', '2641', '生産' ],
[ '보도', 'report; sidewalk', '2642', '報道, 步道' ],
[ '한동안', 'for a while, for quite a while', '2643', '' ],
[ '소원', 'wish, desire', '2644', '所願' ],
[ '벗기다', 'to take off, undress, peel', '2645', '' ],
[ '수준', 'level, standard', '2646', '水準' ],
[ '산속', 'deep in a mountain', '2647', '山-' ],
[ '존재하다', 'to exist, be present', '2648', '存在' ],
[ '공주', 'princess; Gong Ju', '2649', '公主, 公州' ],
[ '비닐', 'plastic, vinyl', '2650', '' ],
[ '단계', 'stage, phase, step', '2651', '段階' ],
[ '느껴지다', 'to feel (as if); to be impressed, be moved', '2652', '' ],
[ '시부모', "parents of one's husband", '2653', '媤父母' ],
[ '자', 'ruler', '2654', '' ],
[ '제안하다', 'to propose, suggest', '2655', '提案' ],
[ '피해', 'damage, harm', '2656', '被害' ],
[ '길거리', 'street, road', '2657', '' ],
[ '상담', 'advice, consultation; business talk', '2658', '相談, 商談' ],
[ '섞이다', 'to be mixed, be blended', '2659', '' ],
[ '식용유', 'cooking oil', '2660', '食用油' ],
[ '녀석', 'guy, fellow', '2661', '' ],
[ '실망하다', 'to be disappointed', '2662', '失望' ],
[ '내부', 'the inside, inner part', '2663', '內部' ],
[ '밤중에', 'in the middle of the night', '2664', '' ],
[ '노동', 'labor', '2665', '勞動' ],
[
  '지나치다',
  'to pass by, go past; to go too far, be too much',
  '2666',
  ''
],
[ '신문사', 'newspaper company', '2667', '新聞社' ],
[ '닷새', 'five days', '2668', '' ],
[ '시합하다', 'to play against, have a game', '2669', '試合' ],
[ '자세', 'posture, position', '2670', '姿勢' ],
[ '술자리', 'drinking party', '2671', '' ],
[ '생명', 'life, soul', '2672', '生命' ],
[ '숙이다', 'to drop, lower, bow, bend', '2673', '' ],
[ '하숙비', 'boarding expenses', '2674', '下宿費' ],
[ '코너', 'corner', '2675', '' ],
[ '저렴하다', 'to be cheap', '2676', '低廉' ],
[ '영향', 'influence, effect', '2677', '影響' ],
[ '양배추', 'cabbage', '2678', '' ],
[ '신사', 'gentleman', '2679', '紳士' ],
[ '여쭤보다', 'to ask (hum.)', '2680', '' ],
[ '목사님', 'pastor, minister', '2681', '牧師' ],
[ '오직', 'only, solely', '2682', '' ],
[ '동의하다', 'to agree', '2683', '同意' ],
[ '진찰', 'medical examination', '2684', '診察' ],
[ '바닷물', 'seawater', '2685', '' ],
[ '후반', 'second half', '2686', '後半' ],
[ '쏘다', 'to shoot, fire', '2687', '' ],
[ '전시회', 'exhibition, display', '2688', '展示會' ],
[
  '눈치',
  "reading (sensing) the atmosphere or people's faces",
  '2689',
  ''
],
[ '굉장하다', 'to be wonderful, great, marvelous', '2690', '宏壯' ],
[ '자매', 'sisters', '2691', '姉妹' ],
[ '언제든지', 'anytime', '2692', '' ],
[ '욕', 'swear word, insult, abuse', '2693', '辱' ],
[ '조그맣다', 'to be tiny, small', '2694', '' ],
[ '범인', 'criminal, culprit', '2695', '犯人' ],
[ '버터', 'butter', '2696', '' ],
[ '지원하다', 'to support, back; to apply, volunteer', '2697', '支援, 志願' ],
[ '매번', 'every time, very often, always', '2698', '每番' ],
[ '꾸준히', 'steadily, consistently', '2699', '' ],
[ '천장', 'ceiling', '2700', '天障' ],
[ '앞서다', 'to go before, precede', '2701', '' ],
[ '연구자', 'researcher', '2702', '硏究者' ],
[ '또한', 'also, too, as well', '2703', '' ],
[ '불구하다', 'to disregard', '2704', '不拘' ],
[ '기후', 'climate', '2705', '氣候' ],
[ '무더위', 'very hot and humid weather', '2706', '' ],
[ '영혼', 'soul', '2707', '靈魂' ],
[ '삶다', 'to boil, cook', '2708', '' ],
[ '부잣집', 'rich family', '2709', '富者-' ],
[ '잠옷', 'pajamas, nightclothes', '2710', '' ],
[ '참조하다', 'to refer (to)', '2711', '參照' ],
[ '지식', 'knowledge, understanding', '2712', '知識' ],
[ '고춧가루', 'red-pepper paste', '2713', '' ],
[ '수도', 'capital city; water supply (service)', '2714', '首都, 水道' ],
[ '세워지다', 'to be built, be established', '2715', '' ],
[ '소개팅', 'blind date', '2716', '紹介' ],
[ '비타민', 'vitamin', '2717', '' ],
[ '투표하다', 'to vote', '2718', '投票' ],
[ '질투', 'jealousy', '2719', '嫉妬' ],
[ '앞길', 'the road ahead, outlook', '2720', '' ],
[ '논리', 'logic', '2721', '論理' ],
[ '밤늦다', 'to be late at night', '2722', '' ],
[ '기본적', 'basic', '2723', '基本的' ],
[ '굳이', 'firmly, positively', '2724', '' ],
[ '실시하다', 'to implement, carry out', '2725', '實施' ],
[ '불어', 'French language', '2726', '佛語' ],
[ '개구리', 'frog', '2727', '' ],
[ '용기', 'courage; container', '2728', '勇氣, 容器' ],
[ '떠오르다', "to come across one's mind; to rise up", '2729', '' ],
[ '목적지', "one's destination", '2730', '目的地' ],
[ '세로', 'length (vertical)', '2731', '' ],
[ '진지하다', 'to be serious, earnest', '2732', '眞摯' ],
[ '매점', 'snack stand, booth, cafeteria', '2733', '賣店' ],
[ '홈페이지', 'website, home page', '2734', '' ],
[ '정부', 'government', '2735', '政府' ],
[ '갈다', 'to change, replace; to sharpen, grind, hone', '2736', '' ],
[ '간판', 'store signboard', '2737', '看板' ],
[ '온통', 'all, wholly, entirely', '2738', '' ],
[ '택하다', 'to choose, select', '2739', '擇' ],
[ '과외', 'private lessons, tutoring', '2740', '課外' ],
[ '심부름', 'errand', '2741', '' ],
[ '방식', 'method, manner, way', '2742', '方式' ],
[ '엄청나다', 'to be huge, enormous, tremendous', '2743', '' ],
[ '청소기', 'vacuum cleaner', '2744', '淸掃機' ],
[ '사위', 'son-in-law', '2745', '' ],
[ '억울하다', 'to suffer unfairness, feel victimized', '2746', '抑鬱' ],
[ '활기', 'energy, activity', '2747', '活氣' ],
[ '오히려', 'rather, preferably', '2748', '' ],
[ '선거', 'election', '2749', '選擧' ],
[ '숨기다', 'to hide, conceal', '2750', '' ],
[ '숙녀', 'lady', '2751', '淑女' ],
[ '가루', 'powder, flour, dust', '2752', '' ],
[ '입력하다', 'to enter, input', '2753', '入力' ],
[ '디자이너', 'designer', '2754', '' ],
[
  '시기',
  'time, moment; opportunity, chance; jealousy',
  '2755',
  '時期, 時機, 猜忌'
],
[ '하품하다', 'to yawn', '2756', '' ],
[ '의지', 'will, volition', '2757', '意志' ],
[ '죄', 'crime, sin, blame', '2758', '罪' ],
[ '치우다', 'to tidy up, clean up, remove', '2759', '' ],
[ '살인', 'murder', '2760', '殺人' ],
[ '골목길', 'alley', '2761', '' ],
[ '감동받다', 'to be impressed', '2762', '感動' ],
[ '군데', 'place, spot', '2763', '' ],
[ '상당히', 'considerably, quite', '2764', '相當' ],
[ '부딪치다', 'to bump against, crash into, collide', '2765', '' ],
[ '현실적', 'realistic, practical', '2766', '現實的' ],
[ '적성', 'aptitude', '2767', '適性' ],
[ '멋지다', 'to be splendid, lovely, nice', '2768', '' ],
[ '본인', 'person in question, oneself', '2769', '本人' ],
[ '장사', 'business, trade', '2770', '' ],
[ '주의하다', 'to pay attention, take care, be careful', '2771', '注意' ],
[ '테스트', 'test', '2772', '' ],
[ '학자', 'scholar', '2773', '學者' ],
[ '너무하다', 'to be unreasonable, too much', '2774', '' ],
[ '장례식', 'funeral', '2775', '葬禮式' ],
[ '불법', 'illegality, unlawfulness', '2776', '不法' ],
[ '읽히다', 'to be read; to make someone read', '2777', '' ],
[ '구성', 'composition, organization, formation', '2778', '構成' ],
[ '한마디', 'a word', '2779', '' ],
[ '비하다', 'to compare to', '2780', '比' ],
[ '외할머니', 'maternal grandmother', '2781', '外-' ],
[ '쌍', 'pair', '2782', '雙' ],
[ '보람 있다', 'to be worthwhile, be fruitful', '2783', '' ],
[ '공포', 'fear, scare', '2784', '恐怖' ],
[ '당분간', 'for the time being, for a while', '2785', '當分間' ],
[ '미루다', 'to delay, postpone', '2786', '' ],
[ '결코', 'never, by no means', '2787', '決-' ],
[ '불꽃', 'spark, flame', '2788', '' ],
[ '기도하다', 'to pray; to attempt', '2789', '祈禱, 企圖' ],
[ '파일', 'file', '2790', '' ],
[ '집단', 'group', '2791', '集團' ],
[ '터뜨리다', 'to pop, burst, set off', '2792', '' ],
[ '온종일', 'all day long, the whole day', '2793', '-終日' ],
[ '부친', 'father', '2794', '父親' ],
[ '식히다', 'to cool, make/let something cool', '2795', '' ],
[ '행위', 'act, action', '2796', '行爲' ],
[ '웃어른', 'senior citizen, elder', '2797', '' ],
[ '각오하다', 'to be determined, be prepared', '2798', '覺悟' ],
[ '제법', 'pretty, fairly, quite', '2799', '' ],
[ '다방', 'tea room, coffee house', '2800', '茶房' ],
[ '들려주다', 'to let hear (i.e. tell, read to, inform etc.)', '2801', '' ],
[ '인물', 'person, individual, character', '2802', '人物' ],
[ '실제로', 'in fact, actually', '2803', '實際-' ],
[ '스치다', 'to graze, brush (touch lightly)', '2804', '' ],
[ '최신', 'the newest, the latest', '2805', '最新' ],
[ '소나기', 'shower', '2806', '' ],
[ '창조하다', 'to create', '2807', '創造' ],
[ '첫눈', 'the first sight; the first snow (of the season)', '2808', '' ],
[ '총', 'gun; total, overall', '2809', '銃, 總' ],
[ '아쉬워하다', 'to miss, feel the lack of', '2810', '' ],
[ '사업가', 'businessman', '2811', '事業家' ],
[ '유산', 'abortion; inheritance, legacy', '2812', '流産, 遺産' ],
[ '살피다', 'to look over carefully', '2813', '' ],
[ '녹화', 'video recording', '2814', '錄畵' ],
[ '셔츠', 'shirt', '2815', '' ],
[ '보장하다', 'to guarantee', '2816', '保障' ],
[ '대박', 'jackpot; something great', '2817', '' ],
[ '악몽', 'bad dream, nightmare', '2818', '惡夢' ],
[ '부러워하다', 'to envy, be envious', '2819', '' ],
[ '소극적', 'passive, negative', '2820', '消極的' ],
[ '경쟁', 'competition', '2821', '競爭' ],
[ '끊기다', 'to be cut off, stopped, broken', '2822', '' ],
[ '말투', "one's way of talking, style of speech", '2823', '-套' ],
[ '마구', 'hard, wildly, carelessly', '2824', '' ],
[ '추천하다', 'to recommend', '2825', '推薦' ],
[ '시아버지', 'father-in-law (of a woman)', '2826', '' ],
[ '봉사', 'service; blind person', '2827', '奉仕' ],
[ '따라다니다', 'to follow (somebody around), to chase', '2828', '' ],
[ '물가', 'cost of living, prices; edge of water', '2829', '物價' ],
[ '현장', 'the actual spot, scene (of action), site', '2830', '現場' ],
[ '깨우다', 'to wake someone up', '2831', '' ],
[ '서명', 'signature; book title', '2832', '署名, 書名' ],
[ '앞뒤', 'front and back, before and behind', '2833', '' ],
[
  '곤란하다',
  'to be difficult, tough; to be embarrassing, awkward',
  '2834',
  '困難'
],
[ '움직임', 'movement, motion', '2835', '' ],
[ '경향', 'tendency, trend', '2836', '傾向' ],
[ '서투르다', 'to be unfamiliar (with), unskilled, poor (at)', '2837', '' ],
[ '게시판', 'bulletin board, notice board', '2838', '揭示板' ],
[ '성장', 'growth', '2839', '成長' ],
[ '드물다', 'to be rare', '2840', '' ],
[ '분야', 'field, area (abstract)', '2841', '分野' ],
[ '키스', 'kiss', '2842', '' ],
[ '건설하다', 'to build, construct', '2843', '建設' ],
[ '바늘', 'needle', '2844', '' ],
[ '식', 'form, style; ceremony', '2845', '式' ],
[ '가버리다', 'to be gone', '2846', '' ],
[ '작업', 'work, operation', '2847', '作業' ],
[ '교장', 'principal, headmaster', '2848', '校長' ],
[ '개다', 'to fold up; to clear up', '2849', '' ],
[ '자체', 'oneself, itself', '2850', '自體' ],
[ '창밖', 'outside the window', '2851', '窓-' ],
[ '완전하다', 'to be perfect, complete', '2852', '完全' ],
[ '그다지', '(not) so much, in that way', '2853', '' ],
[ '소감', 'thoughts (on), impression', '2854', '所感' ],
[ '가져다주다', 'to bring, deliver', '2855', '' ],
[ '비서', 'secretary', '2856', '秘書' ],
[ '절반', 'half', '2857', '折半' ],
[ '비우다', 'to empty, vacate', '2858', '' ],
[ '체육', 'physical education', '2859', '體育' ],
[ '전날', 'the day before, previously', '2860', '前-' ],
[ '희생하다', 'to sacrifice', '2861', '犧牲' ],
[ '쇼', 'show', '2862', '' ],
[ '매진', 'sold out, sellout', '2863', '賣盡' ],
[ '무너지다', 'to collapse, cave in', '2864', '' ],
[ '폭력', 'violence, force', '2865', '暴力' ],
[ '문화재', 'cultural assets', '2866', '文化財' ],
[ '지니다', 'to keep, carry, have', '2867', '' ],
[ '어휘', 'vocabulary', '2868', '語彙' ],
[ '소나무', 'pine tree', '2869', '' ],
[ '유지하다', 'to keep up, maintain', '2870', '維持' ],
[ '끝내', 'to the last, to the end', '2871', '' ],
[ '감옥', 'jail', '2872', '監獄' ],
[ '미끄럽다', 'to be slippery', '2873', '' ],
[ '시선', "one's eyes", '2874', '視線' ],
[ '예고', 'advance notice, warning', '2875', '豫告' ],
[ '두렵다', 'to be fearful, afraid, frightened', '2876', '' ],
[ '호흡', 'breathing', '2877', '呼吸' ],
[ '가로', 'width', '2878', '' ],
[ '구입하다', 'to purchase', '2879', '購入' ],
[ '옛날얘기', 'folk tale, legend', '2880', '' ],
[ '여전히', 'still, as ever', '2881', '如前' ],
[ '넘어오다', 'to come over, cross over; to fall, come down', '2882', '' ],
[ '시청률', '(viewer) ratings', '2883', '視聽率' ],
[ '동기', 'motive; same joining time; sibling', '2884', '動機, 同期, 同氣' ],
[ '쫓다', 'to chase, pursue', '2885', '' ],
[ '최저', 'lowest, minimum', '2886', '最低' ],
[ '톤', 'ton (count.); tone', '2887', '' ],
[ '도망치다', 'to run away, escape', '2888', '逃亡' ],
[ '파랑', 'blue', '2889', '' ],
[ '운명', 'fate, destiny', '2890', '運命' ],
[ '딱딱하다', 'to be hard, stiff, firm', '2891', '' ],
[ '책임감', 'sense of responsibility', '2892', '責任感' ],
[ '상징', 'symbol', '2893', '象徵' ],
[ '건네다', 'to pass, hand over, deliver', '2894', '' ],
[ '외부', 'the outside, the exterior', '2895', '外部' ],
[ '줄무늬', 'stripes', '2896', '' ],
[ '진정하다', 'to be true, real, genuine; to calm down', '2897', '眞正, 鎭靜' ],
[ '잔뜩', 'to the greatest possible extent, to the fullest', '2898', '' ],
[ '소득', 'income, profit', '2899', '所得' ],
[ '망설이다', 'to hesitate, be hesitant', '2900', '' ],
[ '예식장', 'ceremony hall, wedding hall', '2901', '禮式場' ],
[ '분노', 'rage, fury', '2902', '憤怒' ],
[ '속이다', 'to deceive, cheat, trick', '2903', '' ],
[ '가사', 'lyrics; housework, chores', '2904', '歌詞, 家事' ],
[ '손발', 'hands and feet', '2905', '' ],
[ '추측하다', 'to guess, suppose', '2906', '推測' ],
[ '아드님', 'son (hon.)', '2907', '' ],
[ '면', 'side, face; cotton', '2908', '面, 綿' ],
[ '빠져나가다', 'to escape, slip away', '2909', '' ],
[ '산업', 'industry', '2910', '産業' ],
[ '용도', 'use, purpose', '2911', '用途' ],
[ '펼치다', 'to open, unroll, outstretch', '2912', '' ],
[ '화장', 'make-up; cremation', '2913', '化粧, 火葬' ],
[ '온몸', 'entire body', '2914', '' ],
[ '적응하다', 'to adapt, adjust', '2915', '適應' ],
[ '종이컵', 'paper cup', '2916', '' ],
[
  '대체',
  '(why) on earth, (what) the hell; substitution',
  '2917',
  '大體, 代替'
],
[ '깨뜨리다', 'to break, smash, violate', '2918', '' ],
[ '지혜', 'wisdom, wit', '2919', '智慧' ],
[ '승무원', 'crewman, stewardess', '2920', '乘務員' ],
[ '외치다', 'to cry out, shout, yell', '2921', '' ],
[ '미만', 'under, less than', '2922', '未滿' ],
[ '길가', 'roadside', '2923', '' ],
[ '의지하다', 'to rely, depend, lean on', '2924', '依支' ],
[ '뒷산', 'the mountain behind', '2925', '-山' ],
[ '영양', 'nutrition, nourishment', '2926', '營養' ],
[ '빌려주다', 'to lend, loan', '2927', '' ],
[ '인도', 'India; sidewalk', '2928', '人道, 引渡' ],
[ '화장지', 'facial tissue, toilet paper', '2929', '化粧紙' ],
[ '아끼다', 'to spare, save, begrudge', '2930', '' ],
[ '기관', 'engine; institute, school; organ', '2931', '機關, 器官' ],
[ '술병', 'liquor bottle', '2932', '-甁' ],
[ '다정하다', 'to be affectionate, friendly, intimate', '2933', '多情' ],
[ '개나리', 'forsythia', '2934', '' ],
[ '성명', 'full name; statement', '2935', '姓名, 聲明' ],
[ '괴롭히다', 'to harass, annoy, trouble', '2936', '' ],
[ '농민', 'farmer', '2937', '農民' ],
[ '사방', 'all directions, everywhere', '2938', '四方' ],
[ '파다', 'to dig, excavate', '2939', '' ],
[ '벌금', 'fine, penalty', '2940', '罰金' ],
[ '버튼', 'button', '2941', '' ],
[ '해치다', 'to injure, harm, damage', '2942', '害' ],
[ '송아지', 'calf', '2943', '' ],
[ '열정', 'passion', '2944', '熱情' ],
[ '이어지다', 'to be connected, be continued', '2945', '' ],
[ '반응', 'response, reaction', '2946', '反應' ],
[ '외모', 'appearance', '2947', '外貌' ],
[ '깨닫다', 'to realize, recognize', '2948', '' ],
[ '화면', 'screen, picture', '2949', '畵面' ],
[ '줄기', 'trunk, stem', '2950', '' ],
[ '해당하다', 'to be relevant, to correspond (to)', '2951', '該當' ],
[ '밤낮', 'day and night', '2952', '' ],
[ '풍습', 'customs, manners', '2953', '風習' ],
[ '썰렁하다', 'to be chilly; to be not funny, awkward', '2954', '' ],
[ '재주', 'talent, skill', '2955', '才-' ],
[ '노선', 'route, line', '2956', '路線' ],
[ '손잡다', 'to hold hands', '2957', '' ],
[ '주방', 'kitchen', '2958', '廚房' ],
[ '한꺼번에', 'all at once, all together', '2959', '' ],
[ '제거하다', 'to remove, eliminate, get rid of', '2960', '除去' ],
[ '월드컵', 'World Cup', '2961', '' ],
[ '분석', 'analysis', '2962', '分析' ],
[ '내다보다', 'to look out', '2963', '' ],
[ '이력서', 'resume, CV', '2964', '履歷書' ],
[ '장관', 'minister; magnificent sight, grandeur', '2965', '長官, 壯觀' ],
[ '묻히다', 'to be buried, concealed; to smear', '2966', '' ],
[ '철저히', 'thoroughly, completely', '2967', '徹底' ],
[ '터', 'site, ground', '2968', '' ],
[ '분리하다', 'to separate', '2969', '分離' ],
[ '따님', 'daughter (hon.)', '2970', '' ],
[ '승객', 'passenger', '2971', '乘客' ],
[ '건드리다', 'to touch, meddle with, provoke', '2972', '' ],
[ '하숙', 'boarding house', '2973', '下宿' ],
[ '의도', 'intention, aim, purpose', '2974', '意圖' ],
[ '굶다', 'to starve, go hungry, skip a meal', '2975', '' ],
[ '약점', 'weakness, weak point', '2976', '弱點' ],
[ '이웃집', 'house next door', '2977', '' ],
[ '접근하다', 'to approach, come close, access', '2978', '接近' ],
[ '햇볕', 'sunbeam, sunlight', '2979', '' ],
[ '비밀번호', 'password, PIN', '2980', '秘密番號' ],
[ '조깅하다', 'to jog', '2981', '' ],
[ '근육', 'muscle', '2982', '筋肉' ],
[ '독감', 'flu', '2983', '毒感' ],
[ '다리다', 'to iron, press', '2984', '' ],
[ '수단', 'means, way', '2985', '手段' ],
[ '잔돈', 'change (money)', '2986', '' ],
[ '상쾌하다', 'to be refreshing, fresh', '2987', '爽快' ],
[ '꽃잎', 'petal', '2988', '' ],
[ '변명', 'excuse, justification', '2989', '辨明' ],
[ '터지다', 'to burst, blow up, explode', '2990', '' ],
[ '중독', 'poisoning; addiction', '2991', '中毒' ],
[ '해산물', 'seafood, marine products', '2992', '海産物' ],
[ '묵다', 'to stay (over night); to get old', '2993', '' ],
[ '법칙', 'law, rule', '2994', '法則' ],
[ '책가방', 'student backpack', '2995', '' ],
[ '야근하다', 'to work (overtime) at night', '2996', '夜勤' ],
[ '깜빡', 'with a flash', '2997', '' ],
[ '소화', 'digestion', '2998', '消化' ],
[ '서늘하다', 'to be chilly, cool', '2999', '' ],
[ '관계자', 'the interested parties, affected persons', '3000', '關係者' ],
[ '항의', 'protest, complaint', '3001', '抗議' ],
[ '밀리다', 'to be delayed, be behind, be overdue', '3002', '' ],
[ '액자', 'picture frame', '3003', '額子' ],
[ '온라인', 'online', '3004', '' ],
[ '건조하다', 'to be dry', '3005', '乾燥' ],
[ '빨강', 'red', '3006', '' ],
[ '침', 'saliva, spit; needle, sting', '3007', '鍼' ],
[ '놓아두다', 'to leave (as is)', '3008', '' ],
[ '신제품', 'new product', '3009', '新製品' ],
[ '미혼', 'single, unmarried', '3010', '未婚' ],
[ '갖추다', 'to get ready, prepare', '3011', '' ],
[ '배려', 'consideration, regard', '3012', '配慮' ],
[ '발등', 'top of the foot', '3013', '' ],
[ '반납하다', 'to return, give back, hand in', '3014', '返納' ],
[ '나뭇가지', 'tree branch', '3015', '' ],
[ '참고', 'reference', '3016', '參考' ],
[ '촌스럽다', 'to be rustic, tacky, out-of-style', '3017', '村-' ],
[ '찬성', 'agreement, consent, approval', '3018', '贊成' ],
[ '재벌', 'conglomerate, plutocrat', '3019', '財閥' ],
[ '환하다', 'to be bright; to be familiar (with)', '3020', '' ],
[ '예문', 'example sentence', '3021', '例文' ],
[ '꼬리', 'tail', '3022', '' ],
[ '심사하다', 'to screen, evaluate, judge', '3023', '審査' ],
[ '아이디어', 'idea', '3024', '' ],
[ '자녀', 'children', '3025', '子女' ],
[ '빠져나오다', 'to escape, get out', '3026', '' ],
[ '선약', 'previous engagement', '3027', '先約' ],
[ '진단', 'diagnosis', '3028', '診斷' ],
[ '버티다', 'to endure, last, hold out', '3029', '' ],
[ '교복', 'school uniform', '3030', '校服' ],
[ '술잔', 'wine glass, shot glass', '3031', '-盞' ],
[ '진실되다', 'to be true, truthful, sincere, real', '3032', '眞實' ],
[ '외할아버지', 'maternal grandfather', '3033', '外-' ],
[ '균형', 'balance', '3034', '均衡' ],
[ '태워주다', 'to give a person a ride', '3035', '' ],
[ '차별', 'discrimination', '3036', '差別' ],
[ '해수욕장', 'swimming beach', '3037', '海水浴場' ],
[ '맞히다', 'to hit the mark, guess right', '3038', '' ],
[ '압력', 'pressure', '3039', '壓力' ],
[ '품', 'bosom, breast; work, labor', '3040', '' ],
[ '수리하다', 'to repair, fix; to accept, receive', '3041', '修理, 受理' ],
[ '다만', 'only, merely', '3042', '' ],
[ '환불', 'refund', '3043', '還拂' ],
[ '붙잡다', 'to catch, hold, grasp', '3044', '' ],
[ '관점', 'point of view', '3045', '觀點' ],
[ '소방관', 'fireman', '3046', '消防官' ],
[ '잇다', 'to connect, join', '3047', '' ],
[ '우정', 'friendship', '3048', '友情' ],
[ '볼링', 'bowling', '3049', '' ],
[ '사소하다', 'to be trivial, insignificant', '3050', '些少' ],
[ '필수', 'essential, required', '3051', '必須' ],
[ '복숭아', 'peach', '3052', '' ],
[ '전설', 'legend', '3053', '傳說' ],
[ '빼앗다', 'to take away, rob; to fascinate, captivate', '3054', '' ],
[ '귀신', 'ghost, spirit', '3055', '鬼神' ],
[ '개미', 'ant', '3056', '' ],
[ '착각하다', 'to be under an illusion, delude oneself', '3057', '錯覺' ],
[ '평가', 'appraisal, evaluation, assessment', '3058', '評價' ],
[ '고무', 'rubber', '3059', '' ],
[ '천재', 'genius', '3060', '天才' ],
[ '거치다', 'to go through, pass (through)', '3061', '' ],
[ '최종', 'last, final', '3062', '最終' ],
[ '볼', 'cheek; ball', '3063', '' ],
[ '망하다', 'to go to ruin, go bankrupt, perish', '3064', '亡-' ],
[ '부채', 'fan, folding fan; debt, liabilities', '3065', '負債' ],
[ '뒷문', 'back door', '3066', '-門' ],
[ '화제', 'topic, subject of conversation', '3067', '話題' ],
[ '녹다', 'to melt, thaw, unfreeze', '3068', '' ],
[ '해안', 'coast, seaside', '3069', '海岸' ],
[ '목숨', 'life', '3070', '' ],
[ '겸손하다', 'to be modest, humble', '3071', '謙遜' ],
[ '자막', 'subtitles; caption', '3072', '字幕' ],
[ '시스템', 'system', '3073', '' ],
[ '보상', 'compensation; reward', '3074', '補償, 報償' ],
[ '잘리다', 'to be cut, be fired', '3075', '' ],
[ '정수기', 'water purifier', '3076', '淨水器' ],
[ '구석', 'corner', '3077', '' ],
[ '순진하다', 'to be naive', '3078', '純眞' ],
[ '독립', 'independence', '3079', '獨立' ],
[ '저절로', 'by itself, automatically', '3080', '' ],
[ '위협', 'threat, intimidation', '3081', '威脅' ],
[ '나아가다', 'to advance, proceed', '3082', '' ],
[ '논리적', 'logical', '3083', '論理的' ],
[ '마디', 'knuckle; word', '3084', '' ],
[ '연장하다', 'to extend, renew', '3085', '延長' ],
[ '주식', 'stocks, shares; principal item of diet', '3086', '株式, 主食' ],
[ '밤새', 'all night', '3087', '' ],
[ '이용자', 'user', '3088', '利用者' ],
[ '비추다', 'to light up, shed light; to reflect, mirror', '3089', '' ],
[ '접속', 'access', '3090', '接續' ],
[ '꽉', 'tightly, firmly; closely', '3091', '' ],
[ '불완전하다', 'to be incomplete, imperfect', '3092', '不完全' ],
[
  '제약',
  'restriction, limitation; manufacture of medicines',
  '3093',
  '制約, 製藥'
],
[ '이메일', 'email', '3094', '' ],
[ '장면', 'scene (in a movie etc.)', '3095', '場面' ],
[ '되돌리다', 'to restore, put back, return', '3096', '' ],
[ '휴가철', 'vacation season', '3097', '休暇-' ],
[ '장난', 'game, playing (for fun)', '3098', '' ],
[ '통과하다', 'to pass, get through', '3099', '通過' ],
[ '과로', 'working too much, excessive labor', '3100', '過勞' ],
[ '손등', 'back of the hand', '3101', '' ],
[ '소극장', 'small theater', '3102', '小劇場' ],
[ '혼나다', 'to be scolded, have a hard time', '3103', '' ],
[ '부대', 'unit, troops; bag, sack', '3104', '部隊, 負袋' ],
[ '이리저리', 'here and there, this way and that', '3105', '' ],
[ '구분하다', 'to divide, classify', '3106', '區分' ],
[ '일체', 'all, the whole; entirely, absolutely', '3107', '一切, 一體' ],
[ '여우', 'fox', '3108', '' ],
[ '첫인상', "one's first impression", '3109', '-印象' ],
[ '삼다', 'to adopt, use a thing as', '3110', '' ],
[ '자주색', 'purple, violet', '3111', '紫朱色' ],
[ '막상', 'in reality', '3112', '' ],
[ '순수하다', 'to be pure, genuine', '3113', '純粹' ],
[ '예의', '(good) manners, courtesy', '3114', '禮儀' ],
[ '쌍둥이', 'twins', '3115', '雙-' ],
[ '상사', "one's boss, superior", '3116', '上司' ],
[ '흔하다', 'to be common, commonplace', '3117', '' ],
[ '의문', 'question, doubt', '3118', '疑問' ],
[ '코미디', 'comedy', '3119', '' ],
[ '화해하다', 'to be reconciled, make up with', '3120', '和解' ],
[ '작용', 'action, effect, function', '3121', '作用' ],
[ '호주머니', 'pocket', '3122', '胡-' ],
[ '언론', 'the press, the media', '3123', '言論' ],
[ '켜져있다', 'to be lightened, be turned on', '3124', '' ],
[ '상상력', 'imagination', '3125', '想像力' ],
[ '건너', 'across', '3126', '' ],
[ '방해하다', 'to disturb, interrupt', '3127', '妨害' ],
[ '세월', '(long period of) time', '3128', '歲月' ],
[ '엉터리', 'rubbish, nonsense, fake, cheat', '3129', '' ],
[ '연기자', 'actor, actress', '3130', '演技者' ],
[ '되찾다', 'to recover, regain', '3131', '' ],
[ '형식', 'form, formality', '3132', '形式' ],
[ '늦잠', 'sleeping late (in the morning), oversleeping', '3133', '' ],
[ '적절하다', 'to be appropriate, adequate, proper', '3134', '適切' ],
[ '명예', 'honor, distinction, fame', '3135', '名譽' ],
[ '핑계', 'excuse, pretext', '3136', '' ],
[ '불규칙', 'irregular', '3137', '不規則' ],
[ '두려워하다', 'to be afraid, scared of', '3138', '' ],
[ '기적', 'miracle; steam whistle', '3139', '奇蹟, 汽笛' ],
[ '오토바이', 'motorcycle', '3140', '' ],
[ '통역하다', 'to interpret (translate orally)', '3141', '通譯' ],
[ '중식', 'Chinese food; lunch', '3142', '中食' ],
[ '입맛', 'appetite, taste', '3143', '' ],
[ '곡', 'tune, music, song', '3144', '曲' ],
[ '애쓰다', 'to make efforts, go to trouble', '3145', '' ],
[ '신분', 'social position', '3146', '身分' ],
[ '한참', 'for some time', '3147', '' ],
[ '구조하다', 'to save, rescue', '3148', '救助' ],
[ '이민', 'emigration; immigration', '3149', '移民' ],
[ '복수', 'revenge; plural', '3150', '復讐, 複數' ],
[ '차라리', 'rather, preferably', '3151', '' ],
[ '의외', 'surprise, unexpected thing', '3152', '意外' ],
[ '지난날', 'days gone by, the past', '3153', '' ],
[ '연주하다', 'to play (a musical instrument), to perform', '3154', '演奏' ],
[ '오페라', 'opera', '3155', '' ],
[ '구매', 'purchase', '3156', '購買' ],
[ '잠자리', 'dragonfly', '3157', '' ],
[
  '놀리다',
  'to tease, kid, make fun of; to leave something idle, unused',
  '3158',
  ''
],
[ '왕복', 'round trip', '3159', '往復' ],
[ '발걸음', 'step, pace', '3160', '' ],
[ '응원하다', 'to cheer, support', '3161', '應援' ],
[ '물결', 'wave', '3162', '' ],
[ '수집하다', 'to collect, gather', '3163', '蒐集' ],
[ '살아가다', 'to lead a life, get along, keep living', '3164', '' ],
[ '막차', 'the last train/bus', '3165', '-車' ],
[ '출판하다', 'to publish', '3166', '出版' ],
[ '꽂다', 'to put into, stick into', '3167', '' ],
[ '조절하다', 'to control, adjust, regulate', '3168', '調節' ],
[ '면접', '(job) interview', '3169', '面接' ],
[ '맞이하다', 'to welcome, greet, receive', '3170', '' ],
[ '주장', 'insistence, assertion; captain, leader', '3171', '主張, 主將' ],
[ '재킷', 'jacket', '3172', '' ],
[ '그네', 'swings', '3173', '' ],
[ '동작', 'movement, action', '3174', '動作' ],
[ '도리어', 'on the contrary, instead', '3175', '' ],
[ '먹이', 'food, feed', '3176', '' ],
[ '혼내다', 'to scold, teach a person a lesson; to scare', '3177', '魂' ],
[ '이만', 'just this much, this far, to this extent', '3178', '' ],
[ '강의', 'lecture', '3179', '講義' ],
[ '눈사람', 'snowman', '3180', '' ],
[ '초점', 'focus', '3181', '焦點' ],
[ '지켜보다', 'to watch, observe', '3182', '' ],
[ '야간', 'night, night time', '3183', '夜間' ],
[ '까다', 'to peel; to hatch', '3184', '' ],
[ '취향', 'taste, liking, preference', '3185', '趣向' ],
[ '대낮', 'the middle of the day', '3186', '' ],
[ '식량', 'food, provision', '3187', '食糧' ],
[ '부러지다', 'to be broken, snap', '3188', '' ],
[ '널리', 'widely, broadly, all over', '3189', '' ],
[ '대사', 'lines, dialogue; ambassador', '3190', '臺詞, 大使' ],
[ '엉망', 'wreck, mess, bad shape', '3191', '' ],
[ '곧장', 'straight, directly', '3192', '' ],
[ '우회전하다', 'to turn right', '3193', '右回轉' ],
[ '빛나다', 'to shine, gleam, be bright', '3194', '' ],
[ '노트북', 'notebook, laptop', '3195', '' ],
[ '그제', 'day before yesterday', '3196', '' ],
[ '의논하다', 'to discuss (with), consult', '3197', '議論' ],
[ '벌떡', 'suddenly, abruptly', '3198', '' ],
[ '찢다', 'to tear, rip', '3199', '' ],
[ '지시하다', 'to direct, instruct', '3200', '指示' ],
[ '대다', 'to place, put; to feel, touch', '3201', '' ],
[ '동그랗다', 'to be round', '3202', '' ],
[ '공업', 'industry', '3203', '工業' ],
[ '시험지', 'test paper', '3204', '試驗紙' ],
[ '쐬다', 'to get (fresh air), be exposed (to sun)', '3205', '' ],
[ '드러나다', 'to be exposed, emerge, come out', '3206', '' ],
[ '자존심', 'self-respect, pride', '3207', '自尊心' ],
[ '이끌다', 'to lead, guide', '3208', '' ],
[ '바탕', 'foundation, basis', '3209', '' ],
[ '나뉘다', 'to be divided, be apart', '3210', '' ],
[ '저울', 'scales, balance', '3211', '' ],
[ '의식', 'consciousness; ceremony, ritual', '3212', '意識' ],
[ '세일', 'sale', '3213', '' ],
[ '못되다', 'to be bad, mean, evil; to be under, short of', '3214', '' ],
[ '실', 'thread, yarn', '3215', '' ],
[ '걸치다', 'to extend, stretch; to wear, throw on', '3216', '' ],
[
  '부정',
  'denial, negation; corruption, dishonesty; unfaithfulness',
  '3217',
  '否定, 不正, 不貞'
],
[ '지도하다', 'to guide, direct, coach', '3218', '指導' ],
[ '감싸다', 'to cover, wrap (up), shelter', '3219', '' ],
[ '설사', 'diarrhea; even if', '3220', '泄瀉, 設使' ],
[ '존중하다', 'to appreciate, respect', '3221', '尊重' ],
[ '세대', 'generation', '3222', '世代' ],
[ '겪다', 'to undergo, suffer', '3223', '' ],
[ '기울다', 'to tilt, lean, decline', '3224', '' ],
[ '호기심', 'curiosity', '3225', '好奇心' ],
[ '참여하다', 'to participate (in)', '3226', '參與' ],
[ '상식', 'common sense; general knowledge', '3227', '常識' ],
[ '싱싱하다', 'to be fresh', '3228', '' ],
[ '눈병', 'eye disease', '3229', '-病' ],
[ '놀이공원', 'amusement park', '3230', '--公園' ],
[ '사투리', 'dialect', '3231', '' ],
[ '덕수궁', 'Deoksu Palace', '3232', '德壽宮' ],
[ '문서', 'document, papers', '3233', '文書' ],
[ '잠그다', 'to lock', '3234', '' ],
[ '품질', 'quality', '3235', '品質' ],
[
  '전원',
  'countryside, rural areas; electricity supply; all members, everyone',
  '3236',
  '田園, 電源, 全員'
],
[ '클래식', 'classic', '3237', '' ],
[ '머물다', 'to stay, remain', '3238', '' ],
[ '직후', 'immediately after', '3239', '直後' ],
[ '음성', 'voice; negative', '3240', '音聲, 陰性' ],
[ '번개', 'lightning', '3241', '' ],
[ '돌멩이', 'stone, pebble', '3242', '' ],
[ '기념품', 'souvenir', '3243', '紀念品' ],
[ '훈련', 'training, practice', '3244', '訓鍊/訓練' ],
[ '거품', 'bubble, foam', '3245', '' ],
[ '런던', 'London', '3246', '' ],
[ '빚', 'debt, loan', '3247', '' ],
[ '투자하다', 'to invest', '3248', '投資' ],
[ '범죄', 'crime', '3249', '犯罪' ],
[ '잘나다', 'to be good, smart, distinguished', '3250', '' ],
[ '예방하다', 'to prevent', '3251', '豫防' ],
[ '대충', 'roughly, approximately; almost, practically', '3252', '' ],
[ '임무', 'duty, task', '3253', '任務' ],
[ '위기', 'crisis', '3254', '危機' ],
[ '동그라미', 'circle', '3255', '' ],
[ '유적지', 'historical site', '3256', '遺跡地' ],
[ '마주치다', 'to meet with, come across', '3257', '' ],
[ '철도', 'railway', '3258', '鐵道' ],
[ '증거', 'evidence', '3259', '證據' ],
[ '충돌', 'collision, crash, impact', '3260', '衝突' ],
[ '줄거리', 'outline, synopsis, plot; stalk, stem', '3261', '' ],
[ '대개', 'mostly, generally', '3262', '大槪' ],
[ '차이점', 'differences, points of distinction', '3263', '差異點' ],
[ '상점', 'store, shop', '3264', '商店' ],
[ '페이지', 'page', '3265', '' ],
[ '다소', 'more or less, to some degree', '3266', '多少' ],
[ '방안', 'plan, way', '3267', '方案' ],
[ '제시하다', 'to suggest, propose, present', '3268', '提示' ],
[ '순하다', 'to be gentle, mild, meek', '3269', '順' ],
[ '줄어들다', 'to grow smaller, decrease', '3270', '' ],
[ '인류', 'humanity, mankind', '3271', '人類' ],
[ '박히다', 'to be stuck (in), embedded', '3272', '' ],
[ '실체', 'truth, true nature', '3273', '實體' ],
[ '지겹다', 'to be tedious, boring, dull', '3274', '' ],
[ '구별하다', 'to distinguish, differentiate', '3275', '區別' ],
[ '자살', 'suicide', '3276', '自殺' ],
[ '관람', 'viewing, inspection', '3277', '觀覽' ],
[ '실컷', "to one's satisfaction, as much as one likes", '3278', '' ],
[ '다듬다', 'to trim; to refine', '3279', '' ],
[ '처리하다', 'to handle, deal with, take care of', '3280', '處理' ],
[ '긁다', 'to scratch, scrape', '3281', '' ],
[ '시달리다', 'to suffer (from)', '3282', '' ],
[ '장군', 'general, admiral', '3283', '將軍' ],
[ '캠퍼스', 'campus', '3284', '' ],
[ '논문', 'thesis, research paper', '3285', '論文' ],
[ '개성', 'personality, individuality', '3286', '個性' ],
[ '밤새우다', 'to stay up all night', '3287', '' ],
[ '불리하다', 'to be unfavorable, disadvantageous', '3288', '不利' ],
[ '불평', 'complaint', '3289', '不平' ],
[ '아예', 'from the beginning; by any means', '3290', '' ],
[ '음력', 'lunar calendar', '3291', '陰曆' ],
[ '데우다', 'to heat (up), warm (up)', '3292', '' ],
[ '법원', 'court (of law)', '3293', '法院' ],
[ '워낙', 'very, by nature', '3294', '' ],
[ '반면에', 'on the other hand', '3295', '反面' ],
[ '재판', 'trial; second edition', '3296', '裁判, 再版' ],
[ '쓸데없다', 'to be useless', '3297', '' ],
[ '증명하다', 'to prove, demonstrate, confirm', '3298', '證明' ],
[ '기대다', 'to lean (on), rest (against)', '3299', '' ],
[ '감각', 'sense, feeling', '3300', '感覺' ],
[ '조언', 'advice', '3301', '助言' ],
[ '환상', 'illusion, fantasy', '3302', '幻想' ],
[ '물러서다', 'to step back, back off', '3303', '' ],
[ '모험', 'adventure', '3304', '冒險' ],
[ '설정', 'setup, setting', '3305', '設定' ],
[ '송별회', 'farewell party', '3306', '送別會' ],
[ '천국', 'heaven, paradise', '3307', '天國' ],
[ '가로등', 'street lamp, street light', '3308', '街路燈' ],
[ '연구실', "(professor's) office; laboratory", '3309', '硏究室' ],
[ '전시하다', 'to exhibit, display', '3310', '展示' ],
[ '확보하다', 'to secure, ensure, assure', '3311', '確保' ],
[ '충고', 'advice', '3312', '忠告' ],
[ '달콤하다', 'to be sweet, sugary', '3313', '' ],
[ '작동', 'operation (of a device)', '3314', '作動' ],
[ '얌전하다', 'to be gentle, well-behaved, obedient, modest', '3315', '' ],
[ '거스름돈', 'change (coins)', '3316', '' ],
[ '핑크', 'pink', '3317', '' ],
[ '합계', 'sum, total', '3318', '合計' ],
[ '손길', 'touch of a hand', '3319', '' ],
[ '차비', 'transportation fare', '3320', '車費' ],
[ '껴안다', 'to hug, embrace', '3321', '' ],
[ '띄다', 'to leave a space; to be visible, stand out', '3322', '' ],
[ '무리', 'unreasonableness, excess; group, crowd', '3323', '無理' ],
[ '북한', 'North Korea', '3324', '北韓' ],
[ '인상적', 'impressive', '3325', '印象的' ],
[ '미처', 'as far as, to that extent', '3326', '' ],
[ '벌주다', 'to punish', '3327', '' ],
[ '책임지다', 'to take responsibility', '3328', '責任' ],
[ '깔끔하다', 'to be neat, tidy', '3329', '' ],
[ '지급하다', 'to provide, pay', '3330', '支給' ],
[ '갈등', 'conflict', '3331', '葛藤' ],
[ '효자', 'filial (devoted) son', '3332', '孝子' ],
[ '망치다', 'to spoil, ruin, screw up', '3333', '亡-' ],
[ '종', 'species; bell; slave, servant', '3334', '種, 鐘' ],
[ '들키다', 'to get caught, be discovered', '3335', '' ],
[ '장단점', 'pros and cons, strengths and weaknesses', '3336', '長短點' ],
[ '암기하다', 'to memorize', '3337', '暗記' ],
[ '우습다', 'to be funny, amusing', '3338', '' ],
[ '소비', 'consumption', '3339', '消費' ],
[ '선글라스', 'sunglasses', '3340', '' ],
[ '존경하다', 'to respect, look up to', '3341', '尊敬' ],
[ '솜씨', 'skill, ability', '3342', '' ],
[
  '사례',
  'case, instance, example; reward, compensation',
  '3343',
  '事例, 謝禮'
],
[ '필기하다', 'to take notes', '3344', '筆記' ],
[ '비만', 'fatness, corpulence', '3345', '肥滿' ],
[ '사표', '(written) resignation', '3346', '辭表' ],
[ '여기다', 'to regard, consider', '3347', '' ],
[ '지극히', 'very, extremely, perfectly', '3348', '至極' ],
[ '대리', 'deputy, proxy', '3349', '代理' ],
[ '분수', 'fountain; fraction', '3350', '噴水, 分數' ],
[ '그럭저럭', 'somehow', '3351', '' ],
[ '감독', 'supervision; supervisor, director', '3352', '監督' ],
[ '호감', 'good feeling, favorable impression', '3353', '好感' ],
[ '제과점', 'bakery', '3354', '製菓店' ],
[ '소매', 'sleeve; retail', '3355', '小賣' ],
[ '포근하다', 'to be warm, mild; to be soft, comfortable', '3356', '' ],
[ '매체', 'media', '3357', '媒體' ],
[ '전문적', 'specialized, professional', '3358', '專門的/顓門的' ],
[ '예감', 'premonition, foreboding, hunch', '3359', '豫感' ],
[ '당첨되다', 'to win the prize', '3360', '當籤' ],
[ '모범', 'example, (role) model', '3361', '模範' ],
[ '해내다', 'accomplish, achieve, do', '3362', '' ],
[ '벗어나다', 'to get out, deviate', '3363', '' ],
[ '금융', 'finance', '3364', '金融' ],
[ '영리하다', 'to be smart, clever, bright', '3365', '怜悧' ],
[ '부리다', 'to manage, handle;to raise, create, show, have', '3366', '' ],
[ '교외', 'the suburbs', '3367', '郊外' ],
[ '흥분하다', 'to be excited, be stimulated', '3368', '興奮' ],
[ '횟수', 'number (of times)', '3369', '回數' ],
[ '뚜렷하다', 'to be distinctive, clear', '3370', '' ],
[ '휴학하다', 'to take time off from school', '3371', '休學' ],
[ '기술자', 'technician, engineer', '3372', '技術者' ],
[ '작곡', 'composition (of music)', '3373', '作曲' ],
[ '안과', 'ophthalmic hospital', '3374', '眼科' ],
[ '후추', 'black pepper', '3375', '' ],
[ '대체로', 'generally, mostly', '3376', '大體-' ],
[ '뇌', 'brain', '3377', '腦' ],
[ '가전제품', 'electric home appliance', '3378', '家電製品' ],
[ '빈자리', 'empty seat, vacant spot', '3379', '' ],
[ '안내원', 'guide', '3380', '案內員' ],
[ '설레다', 'to flutter, vibrate', '3381', '' ],
[ '부주의', 'carelessness', '3382', '不注意' ],
[ '권투', 'boxing', '3383', '拳鬪' ],
[ '만원', 'full house (no space for more people)', '3384', '滿員' ],
[ '면도하다', 'to shave', '3385', '面刀' ],
[ '야단치다', 'to scold, rebuke', '3386', '惹端' ],
[ '오염되다', 'to be contaminated, polluted', '3387', '汚染' ],
[ '부츠', 'boots', '3388', '' ],
[ '공격', 'attack, assault', '3389', '攻擊' ],
[ '직장인', '(office) worker', '3390', '職場人' ],
[ '공해', 'pollution', '3391', '公害' ],
[ '공개하다', 'to make public', '3392', '公開' ],
[ '성당', 'Catholic church', '3393', '聖堂' ],
[ '네모', 'square', '3394', '' ],
[ '독', 'poison; jar', '3395', '毒' ],
[ '제자리', 'the proper place, the original place', '3396', '' ],
[ '훔치다', 'to steal, swipe, lift; to wipe, mop', '3397', '' ],
[
  '잠기다',
  'to be submerged, go down; to be locked, be fastened',
  '3398',
  ''
],
[ '상대편', 'the other party', '3399', '相對便' ],
[ '방면', 'direction; way, aspect', '3400', '方面' ],
[ '농부', 'farmer, peasant', '3401', '農夫' ],
[ '꼼꼼하다', 'to be careful, meticulous', '3402', '' ],
[ '조끼', 'vest', '3403', '' ],
[ '노랑', 'yellow', '3404', '' ],
[ '슬리퍼', 'slipper', '3405', '' ],
[ '수다', 'chatter, chit-chat', '3406', '' ],
[ '다리미', 'clothes iron', '3407', '' ],
[ '졸업식', 'graduation ceremony', '3408', '卒業式' ],
[ '지옥', 'hell', '3409', '地獄' ],
[ '가입하다', 'to join, subscribe', '3410', '加入' ],
[ '결론', 'conclusion', '3411', '結論' ],
[ '밭', 'field, farm', '3412', '' ],
[ '목록', 'list, catalogue', '3413', '目錄' ],
[ '연합', 'alliance, coalition', '3414', '聯合' ],
[ '쫓기다', 'to be chased', '3415', '' ],
[ '차이다', 'to get kicked; to be dumped', '3416', '' ],
[ '인연', 'tie, connection; destiny', '3417', '因緣' ],
[ '이기적', 'selfish', '3418', '利己的' ],
[ '용감하다', 'to be brave, daring, courageous', '3419', '勇敢' ],
[ '그저', 'without any reason', '3420', '' ],
[ '증가하다', 'to increase, grow', '3421', '增加' ],
[ '제작', 'production, making', '3422', '製作' ],
[ '급속히', 'rapidly', '3423', '急速' ],
[ '점검하다', 'to inspect, check', '3424', '點檢' ],
[ '몫', 'share, portion', '3425', '' ],
[ '빨개지다', 'to turn red', '3426', '' ],
[ '좀처럼', '[not] easily, [not] readily', '3427', '' ],
[ '사적', 'private, personal; historical site', '3428', '私的, 史蹟' ],
[ '멀미', 'nausea, motion sickness', '3429', '' ],
[ '총장', 'chancellor, secretary general', '3430', '總長' ],
[ '외투', 'overcoat', '3431', '外套' ],
[ '기계', 'machine', '3432', '機械' ],
[ '넘어뜨리다', 'to throw down, tip down, push down', '3433', '' ],
[ '처녀', 'unmarried (single) woman, maiden, virgin', '3434', '處女' ],
[ '강요', 'enforcement, coercion', '3435', '强要' ],
[ '강사', 'speaker, lecturer, instructor', '3436', '講師' ],
[ '좁히다', 'to narrow, restrict', '3437', '' ],
[ '새다', 'to leak, escape; to dawn', '3438', '' ],
[
  '전세',
  'lease of a house on a deposit basis; charter; war situation',
  '3439',
  '傳貰, 專貰, 戰勢'
],
[
  '조정',
  'adjustment, modification; mediation; rowing',
  '3440',
  '調整, 調停, 漕艇'
],
[ '바구니', 'wicker basket', '3441', '' ],
[ '최고급', 'highest grade, top class', '3442', '最高級' ],
[ '이내', 'within, less than; soon, at once', '3443', '以內' ],
[ '변경', 'change, alteration', '3444', '變更' ],
[ '비명', 'scream', '3445', '悲鳴' ],
[ '우편', 'mail, post', '3446', '郵便' ],
[ '표준', 'standard, norm, average', '3447', '標準' ],
[ '게', 'crab', '3448', '' ],
[ '물질', 'matter, substance, material', '3449', '物質' ],
[ '교대', 'rotation, shift, alternation', '3450', '交代' ],
[ '닳다', 'to wear out', '3451', '' ],
[ '샴푸', 'shampoo', '3452', '' ],
[
  '어긋나다',
  'to be contrary, at cross purposes; to go amiss',
  '3453',
  ''
],
[ '찻잔', 'teacup', '3454', '茶盞' ],
[ '이발소', 'barber shop', '3455', '理髮所' ],
[ '유혹하다', 'to tempt, lure, seduce', '3456', '誘惑' ],
[ '억양', 'intonation, accent', '3457', '抑揚' ],
[ '대상', 'object, target; grand prize', '3458', '對象, 大賞' ],
[ '답변', 'answer, reply', '3459', '答辯' ],
[ '시각', 'time; view; sight, vision', '3460', '時刻, 視角, 視覺' ],
[ '성질', 'nature, temper', '3461', '性質' ],
[ '침묵', 'silence', '3462', '沈默' ],
[ '가르다', 'to divide, cut, split', '3463', '' ],
[ '어르신', 'senior citizen, elderly', '3464', '' ],
[ '메달', 'medal', '3465', '' ],
[ '비롯하다', 'to include', '3466', '' ],
[ '윗사람', "one's senior, one's superior", '3467', '' ],
[ '쓸쓸하다', 'to be lonely, forlorn', '3468', '' ],
[ '사생활', 'privacy, private life', '3469', '私生活' ],
[ '환상적', 'fantastic', '3470', '幻想的' ],
[ '연주회', 'concert, recital', '3471', '演奏會' ],
[
  '유치하다',
  'to be childish, immature; to attract, invite',
  '3472',
  '幼稚, 誘致'
],
[ '최소', 'the smallest, minimum', '3473', '最小, 最少' ],
[ '우연', 'coincidence, chance', '3474', '偶然' ],
[ '삐다', 'to sprain, twist', '3475', '' ],
[ '문병가다', 'to visit a sick person', '3476', '問病' ],
[ '회비', 'membership fee', '3477', '會費' ],
[ '매운탕', 'spicy fish stew', '3478', '--湯' ],
[ '세트', 'a set', '3479', '' ],
[ '재수', 'luck, fortune', '3480', '財數' ],
[ '달래다', 'to soothe, calm (down)', '3481', '' ],
[ '실천', 'practice, action (as opposed to just talking)', '3482', '實踐' ],
[ '단골', 'regular customer', '3483', '' ],
[ '한가지', 'the same; one thing, a kind, a sort', '3484', '' ],
[ '이별하다', 'to part, separate', '3485', '離別' ],
[ '비난하다', 'to criticize, attack, blame', '3486', '非難' ],
[ '몽땅', 'all', '3487', '' ],
[ '면허증', 'license, permission', '3488', '免許證' ],
[ '습도', 'humidity', '3489', '濕度' ],
[ '지저분하다', 'to be messy, disordered, untidy', '3490', '' ],
[ '무덥다', 'to be stifling, humid, sultry', '3491', '' ],
[ '회의실', 'conference room', '3492', '會議室' ],
[ '분실', 'loss', '3493', '紛失' ],
[ '팔꿈치', 'elbow', '3494', '' ],
[ '좌회전하다', 'to turn left', '3495', '左回轉' ],
[ '앞서', 'before, earlier', '3496', '' ],
[ '올바르다', 'to be straight, upright, correct', '3497', '' ],
[ '성실하다', 'to be sincere, faithful', '3498', '誠實' ],
[ '대책', 'countermeasure', '3499', '對策' ],
[ '천둥', 'thunder', '3500', '' ],
[ '현대인', 'modern people', '3501', '現代人' ],
[ '고독하다', 'to be lonely, solitary', '3502', '孤獨' ],
[ '소방서', 'fire station', '3503', '消防署' ],
[ '체험', 'experience', '3504', '體驗' ],
[ '뮤지컬', 'musical', '3505', '' ],
[ '고궁', 'old palace', '3506', '古宮' ],
[ '사인', 'signature, autograph; cause of death', '3507', '死因' ],
[ '장마철', 'the rainy season', '3508', '' ],
[ '다투다', 'to fight, argue, struggle', '3509', '' ],
[ '천사', 'angel', '3510', '天使' ],
[ '넘기다', 'to turn over', '3511', '' ],
[ '거꾸로', 'upside down, inside out, backwards', '3512', '' ],
[ '불쾌하다', 'to be unpleasant, uncomfortable', '3513', '不快' ],
[ '둘러싸다', 'to surround, enclose', '3514', '' ],
[ '삭제하다', 'to delete, remove', '3515', '削除' ],
[ '언급', 'mention, reference', '3516', '言及' ],
[ '인심', "one's mind, one's heart", '3517', '人心' ],
[ '대한민국', 'Republic of Korea', '3518', '大韓民國' ],
[ '가능성', 'possibility, likelihood; potential', '3519', '可能性' ],
[ '신비하다', 'to be mysterious', '3520', '神秘' ],
[ '큰아버지', "father's elder brother", '3521', '' ],
[ '큰어머니', "father's elder brother's wife", '3522', '' ],
[ '폭', 'width, range; deeply, completely', '3523', '幅' ],
[ '남미', 'South America', '3524', '南美' ],
[ '분류하다', 'to classify, categorize', '3525', '分類' ],
[ '휴양지', 'rest area, resort, vacation spot', '3526', '休養地' ],
[ '마라톤', 'marathon', '3527', '' ],
[ '매달다', 'to hang, suspend', '3528', '' ],
[ '폭설', 'heavy snowfall', '3529', '暴雪' ],
[ '세차하다', 'to wash a car', '3530', '洗車' ],
[ '마약', 'drugs, narcotics', '3531', '痲藥' ],
[ '독하다', 'to be potent, strong; to be spiteful', '3532', '毒' ],
[ '소형', 'small item, small size', '3533', '小型·小形' ],
[ '굳다', 'to harden, solidify, get stiff', '3534', '' ],
[ '뱉다', 'to spit out', '3535', '' ],
[ '생활비', 'living expenses, the cost of living', '3536', '生活費' ],
[ '멎다', 'to stop', '3537', '' ],
[ '매', 'hawk, falcon; whip, rod', '3538', '' ],
[ '호두', 'walnut', '3539', '' ],
[ '달려들다', 'to attack, jump at', '3540', '' ],
[ '눈길', 'snowy road; eyes, line of vision', '3541', '' ],
[ '귀중하다', 'to be precious, valuable', '3542', '貴重' ],
[ '안색', "complexion; the look on one's face", '3543', '顔色' ],
[ '밑줄', 'underline', '3544', '' ],
[ '물약', 'liquid medicine', '3545', '-藥' ],
[ '상영', 'screening, showing (a movie etc.)', '3546', '上映' ],
[ '돌아다니다', 'to wander around, roam', '3547', '' ],
[ '양심', 'conscience', '3548', '良心' ],
[ '풍부하다', 'to be plentiful, ample', '3549', '豊富' ],
[ '유머', 'humor', '3550', '' ],
[
  '피서',
  'summer retreat (out of town), summer vacation, getaway',
  '3551',
  '避暑'
],
[ '시력', 'eyesight', '3552', '視力' ],
[ '학비', 'school expenses, tuition fees', '3553', '學費' ],
[ '수십', 'tens, scores, dozens', '3554', '數十' ],
[ '다양하다', 'to be various, diverse', '3555', '多樣' ],
[ '도서', 'books, publications', '3556', '圖書' ],
[ '한계', 'limit, boundary', '3557', '限界' ],
[ '바가지', 'dipper; rip-off', '3558', '' ],
[ '처방', 'prescription', '3559', '處方' ],
[ '월세', 'monthly rent', '3560', '月貰' ],
[ '바둑', 'Go (the board game)', '3561', '' ],
[ '수수료', 'commission, fee', '3562', '手數料' ],
[ '폭발하다', 'to explode, blow up, detonate, erupt', '3563', '暴發, 爆發' ],
[ '다지다', 'to chop up, mince; to harden', '3564', '' ],
[ '과연', 'indeed, sure enough', '3565', '果然' ],
[ '사모님', "one's teacher's wife, one's superior's wife", '3566', '師母-' ],
[ '골', 'goal; valley; brain', '3567', '' ],
[ '어지럽다', 'to be dizzy, feel giddy; to be in disorder', '3568', '' ],
[ '부서지다', 'to break, break down, become out of order', '3569', '' ],
[ '과장하다', 'to exaggerate', '3570', '誇張' ],
[
  '화재',
  'fire (the kind that you call firefighters for)',
  '3571',
  '火災'
],
[ '접수', 'receipt, reception', '3572', '接受' ],
[ '바치다', 'to dedicate, offer, give', '3573', '' ],
[ '유료', 'requiring payment', '3574', '有料' ],
[ '장인', "one's wife's father; master craftsman", '3575', '丈人, 匠人' ],
[ '진행자', 'presenter, host, MC', '3576', '進行者' ],
[ '부정확', 'inaccuracy, incorrectness', '3577', '不正確' ],
[ '스타킹', 'stockings', '3578', '' ],
[ '안약', 'eye drops', '3579', '眼藥' ],
[ '교재', 'teaching materials', '3580', '敎材' ],
[ '동화책', 'fairy tale book', '3581', '童話冊' ],
[ '흉내', 'imitation', '3582', '' ],
[ '순식간에', 'in the blink of an eye', '3583', '瞬息間' ],
[ '실장', 'chief, head of department', '3584', '室長' ],
[ '조미료', 'seasoning, condiment', '3585', '調味料' ],
[ '영웅', 'hero, heroine', '3586', '英雄' ],
[ '거미', 'spider', '3587', '' ],
[ '분량', 'quantity', '3588', '分量' ],
[ '날리다', 'to lose, make fly', '3589', '' ],
[ '끌리다', 'to drag, be drawn (to)', '3590', '' ],
[ '몸짓', 'gesture', '3591', '' ],
[ '온천', 'hot spring', '3592', '溫泉' ],
[ '속다', 'to be deceived, be fooled', '3593', '' ],
[
  '선',
  'line; goodness, virtue; meeting with a prospective marriage partner',
  '3594',
  '線, 善'
],
[ '거지', 'beggar', '3595', '' ],
[ '감독하다', 'to supervise, oversee', '3596', '監督' ],
[ '굽', 'heel', '3597', '' ],
[ '노약자', 'old and/or weak people', '3598', '老弱者' ],
[ '동창회', 'alumni meeting, reunion', '3599', '同窓會' ],
[ '핸드백', 'handbag', '3600', '' ],
[ '위대하다', 'to be great, grand', '3601', '偉大' ],
[ '출신', 'native, origin', '3602', '出身' ],
[ '업체', 'business, enterprise, concern', '3603', '業體' ],
[ '작성하다', 'to draw up, write out, prepare', '3604', '作成' ],
[ '초급', 'elementary (beginning) level', '3605', '初級' ],
[ '약혼', 'engagement (before marriage)', '3606', '約婚' ],
[ '나르다', 'to carry, transport', '3607', '' ],
[ '이윽고', 'eventually, at last, finally', '3608', '' ],
[ '장기간', 'long time', '3609', '長期間' ],
[ '담당자', 'person in charge', '3610', '擔當者' ],
[ '병실', 'hospital room, ward', '3611', '病室' ],
[ '눈부시다', 'to be dazzling, glaring, brilliant', '3612', '' ],
[ '격려하다', 'to encourage', '3613', '激勵' ],
[ '소독하다', 'to disinfect, sterilize', '3614', '消毒' ],
[ '야경', 'night view', '3615', '夜景' ],
[ '탤런트', 'TV actor, TV star', '3616', '' ],
[ '찌푸리다', 'to frown, scowl; to cloud over', '3617', '' ],
[ '느끼하다', 'to be greasy, oily, rich', '3618', '' ],
[ '정직하다', 'to be honest, straightforward', '3619', '正直' ],
[ '전망', 'view, prospect', '3620', '展望' ],
[ '평균', 'average, mean', '3621', '平均' ],
[ '잔디밭', 'lawn', '3622', '' ],
[ '몸매', "one's figure", '3623', '' ],
[ '재활용', 'recycling', '3624', '再活用' ],
[ '그런대로', 'such as it is', '3625', '' ],
[ '재활용품', 'recyclable materials', '3626', '再活用品' ],
[ '몰려오다', 'to come in flocks, swarm in', '3627', '' ],
[ '계산대', 'counter, checkout', '3628', '計算臺' ],
[ '귀하', 'sir, madam', '3629', '貴下' ],
[ '뽀뽀', 'kiss, peck', '3630', '' ],
[ '억지로', "against one's will, by force", '3631', '' ],
[ '생년월일', 'date of birth', '3632', '生年月日' ],
[ '녹이다', 'to melt, dissolve', '3633', '' ],
[ '세미나', 'seminar', '3634', '' ],
[ '복사기', 'copy machine', '3635', '複寫機/複寫器' ],
[ '씨앗', 'seed', '3636', '' ],
[ '진동하다', 'to vibrate', '3637', '振動' ],
[ '비비다', 'to rub; to mix', '3638', '' ],
[ '자극하다', 'to stimulate, arouse, excite, irritate', '3639', '刺戟' ],
[ '검색', 'search', '3640', '檢索' ],
[ '왼편', 'the left side', '3641', '―便' ],
[ '서운하다', 'to be sad, hurt; to be regrettable', '3642', '' ],
[ '까다롭다', 'to be particular (about), fussy, difficult', '3643', '' ],
[ '시외버스', 'intercity bus', '3644', '市外bus' ],
[ '정성', 'sincerity, devotion', '3645', '精誠' ],
[ '설치', 'installation', '3646', '設置' ],
[ '삼키다', 'to swallow', '3647', '' ],
[ '마음씨', 'nature, temper', '3648', '' ],
[ '내버리다', 'to throw away', '3649', '' ],
[ '조르다', 'to pester, nag; to tighten', '3650', '' ],
[ '지적하다', 'to point out, indicate', '3651', '指摘' ],
[ '신청서', 'application form', '3652', '申請書' ],
[ '해변', 'beach, seashore', '3653', '海邊' ],
[ '복용하다', 'to take (a dose of) medicine or drugs', '3654', '服用' ],
[ '갇히다', 'to be locked (up), shut (in), confined', '3655', '' ],
[ '재능', 'talent, gift', '3656', '才能' ],
[ '맺다', 'to bear, produce; to form', '3657', '' ],
[ '문득', 'suddenly', '3658', '' ],
[ '뺏다', 'to take (away), snatch', '3659', '' ],
[ '파리', 'fly; Paris', '3660', '' ],
[ '체조', 'gymnastics', '3661', '體操' ],
[ '최대한', 'maximum, at the most', '3662', '最大限' ],
[ '운영하다', 'to manage, run, operate', '3663', '運營' ],
[ '꿇다', 'to kneel (down)', '3664', '' ],
[ '바래다주다', 'see a person home', '3665', '' ],
[ '끈', 'string, cord', '3666', '' ],
[ '미장원', 'beauty parlor, hair salon', '3667', '美粧院' ],
[ '눈싸움', 'snowball fight; staring contest', '3668', '' ],
[ '약도', 'rough map, sketch map', '3669', '略圖' ],
[ '피해자', 'victim', '3670', '被害者' ],
[ '콤플렉스', 'inferiority complex', '3671', '' ],
[ '통통하다', 'to be plump, chubby', '3672', '' ],
[ '대기하다', 'to stand by, be waiting', '3673', '待機' ],
[ '주전자', 'kettle', '3674', '酒煎子' ],
[ '추가하다', 'to add, supplement', '3675', '追加' ],
[ '허리띠', 'belt', '3676', '' ],
[ '정오', 'noon, midday', '3677', '正午' ],
[ '어리석다', 'to be foolish, stupid, silly', '3678', '' ],
[ '고속도로', 'expressway, highway', '3679', '高速道路' ],
[ '교문', 'school gate', '3680', '校門' ],
[ '상업', 'commerce, business', '3681', '商業' ],
[ '단독', 'single, solo, independent', '3682', '單獨' ],
[ '벼', 'rice', '3683', '' ],
[ '수량', 'quantity, amount; quantity of water', '3684', '數量, 水量' ],
[ '화창하다', 'to be sunny', '3685', '和暢' ],
[ '강의실', 'lecture room', '3686', '講義室' ],
[ '경비', 'expenses; guard, security', '3687', '經費, 警備' ],
[ '유형', 'type, category', '3688', '類型' ],
[ '비상', 'emergency -', '3689', '非常' ],
[ '금액', 'amount of money', '3690', '金額' ],
[ '정면', 'front, facade', '3691', '正面' ],
[ '금하다', 'to prohibit, forbid, ban', '3692', '禁' ],
[ '사항', 'matter, item, particulars', '3693', '事項' ],
[ '안부', 'regards; news (regarding a person)', '3694', '安否' ],
[ '외아들', 'only son', '3695', '' ],
[ '원룸', 'studio (apartment)', '3696', '' ],
[ '화장대', 'dressing table', '3697', '化粧臺' ],
[ '표준어', 'standard language', '3698', '標準語' ],
[ '업다', "to carry on one's back", '3699', '' ],
[ '규정', 'rule, regulation', '3700', '規定' ],
[ '그리움', 'longing, yearning', '3701', '' ],
[ '탈출하다', 'to escape', '3702', '脫出' ],
[ '구역', 'area, zone, district', '3703', '區域' ],
[ '우아하다', 'to be elegant, graceful', '3704', '優雅' ],
[ '초기', 'the early days, the beginning', '3705', '初期' ],
[ '무사히', 'safely', '3706', '無事' ],
[ '적히다', 'to be written down', '3707', '' ],
[ '감추다', 'to hide, conceal', '3708', '' ],
[ '도저히', 'not at all, no way', '3709', '到底-' ],
[ '계곡', 'valley', '3710', '溪谷' ],
[ '캄캄하다', 'to be dark, pitch-black', '3711', '' ],
[ '프린터', 'printer', '3712', '' ],
[ '교양', 'sophistication, refinement', '3713', '敎養' ],
[ '응답', 'answer, response', '3714', '應答' ],
[ '전자레인지', 'microwave oven', '3715', '電子range' ],
[ '서럽다', 'to be sad, sorrowful', '3716', '' ],
[ '분실물', 'lost article, lost item', '3717', '紛失物' ],
[ '동영상', 'video', '3718', '動映像' ],
[ '선호하다', 'to prefer', '3719', '選好' ],
[ '정형외과', 'orthopedics', '3720', '整形外科' ],
[ '편견', 'prejudice, bias', '3721', '偏見' ],
[ '애정', 'love, affection', '3722', '愛情' ],
[ '인쇄하다', 'to print', '3723', '印刷' ],
[ '상반기', 'the first half of the year', '3724', '上半期' ],
[ '난리', 'fuss, disturbance', '3725', '亂離' ],
[ '시댁', "one's husband's home (family)", '3726', '媤宅' ],
[ '까먹다', 'to forget; to peel and eat; to use up', '3727', '' ],
[ '속담', 'saying, proverb', '3728', '俗談' ],
[ '공지하다', 'to announce, notify', '3729', '公知' ],
[ '수수께끼', 'puzzle, riddle', '3730', '' ],
[ '홍수', 'flood', '3731', '洪水' ],
[ '마무리하다', 'to finish, finalize, complete', '3732', '' ],
[ '개방하다', 'to open, unbar', '3733', '開放' ],
[ '따로따로', 'separately, individually', '3734', '' ],
[ '창가', 'area near window', '3735', '窓-' ],
[ '접촉', 'contact, touch', '3736', '接觸' ],
[ '식후', 'after a meal', '3737', '食後' ],
[ '원칙', 'principle, general rule', '3738', '原則' ],
[ '보안', 'security', '3739', '保安' ],
[ '사냥하다', 'to hunt', '3740', '' ],
[ '명단', 'list of names', '3741', '名單' ],
[ '신중하다', 'to be cautious, prudent, discreet', '3742', '愼重' ],
[ '별도', 'separate, additional', '3743', '別途' ],
[ '무기', 'arms, weapon', '3744', '武器' ],
[ '질병', 'disease, illness', '3745', '疾病' ],
[ '파괴하다', 'to destroy, ruin', '3746', '破壞' ],
[ '문방구', 'stationery; stationery store', '3747', '文房具' ],
[ '이롭다', 'to be beneficial, do good', '3748', '' ],
[ '샌들', 'sandals', '3749', '' ],
[ '녹음기', 'recorder', '3750', '錄音器' ],
[ '고집', 'stubbornness, insistence', '3751', '固執' ],
[ '민주주의', 'democracy', '3752', '民主主義' ],
[ '파트너', 'partner', '3753', '' ],
[ '교훈', 'lesson, moral', '3754', '敎訓' ],
[ '쓰다듬다', 'to stroke, pat', '3755', '' ],
[ '한눈', 'one eye; a look, a glance', '3756', '' ],
[ '노려보다', 'to glare, scowl', '3757', '' ],
[ '높임말', 'honorific language (style, words)', '3758', '' ],
[ '반영하다', 'to reflect', '3759', '反映' ],
[
  '도장',
  'stamp, seal; painting; taekwondo studio',
  '3760',
  '圖章, 塗裝, 道場'
],
[ '후보', 'candidate, nominee', '3761', '候補' ],
[ '배송', 'shipping, delivery', '3762', '配送' ],
[ '단위', 'unit, measure', '3763', '單位' ],
[ '유일하다', 'to be the only one', '3764', '唯一' ],
[ '등장하다', 'to appear, make an appearance', '3765', '登場' ],
[ '선전', 'propaganda, advertisement', '3766', '宣傳' ],
[ '연속', 'series, streak, continuation', '3767', '連續' ],
[ '연인', 'sweetheart, lover', '3768', '戀人' ],
[ '웬만하다', 'to be tolerable, fairly good', '3769', '' ],
[ '독특하다', 'to be peculiar, unique, distinctive', '3770', '獨特' ],
[ '손해', 'damage, loss', '3771', '損害' ],
[ '급히', 'hastily, in a hurry', '3772', '急' ],
[ '번갈다', '', '3773', '番-' ],
[ '이론', 'theory', '3774', '理論' ],
[ '난로', 'heater, stove', '3775', '煖爐' ],
[ '재우다', 'to put [send] someone to sleep [bed]', '3776', '' ],
[
  '사기',
  'fraud, deception, scam; morale, fighting spirit',
  '3777',
  '詐欺, 士氣'
],
[ '재다', 'to measure, gauge', '3778', '' ],
[ '모집하다', 'to recruit, invite', '3779', '募集' ],
[ '고래', 'whale', '3780', '' ],
[ '감소하다', 'to decrease, decline', '3781', '減少' ],
[ '방울', 'drop (of liquid); bell', '3782', '' ],
[ '뛰어나다', 'to be outstanding, excellent', '3783', '' ],
[ '언덕', 'hill; slope', '3784', '' ],
[ '유창하다', 'to be fluent', '3785', '流暢' ],
[ '멋대로', 'as one pleases, selfishly', '3786', '' ],
[ '절망', 'despair, hopelessness', '3787', '絶望' ],
[ '흘러내리다', 'to run down, trickle down', '3788', '' ],
[ '후춧가루', 'ground pepper', '3789', '' ],
[
  '학과',
  '(university) department, major; lesson, classwork',
  '3790',
  '學科'
],
[ '박다', 'to thrust in, hammer, drive', '3791', '' ],
[ '짖다', 'to bark, howl', '3792', '' ],
[ '활짝', 'extensively, widely, fully', '3793', '' ],
[ '조명하다', 'to illuminate, highlight', '3794', '照明' ],
[ '한라산', 'Mt. Halla', '3795', '漢拏山' ],
[ '휴식', 'rest, relaxation', '3796', '休息' ],
[ '결승', 'finals', '3797', '決勝' ],
[ '해돋이', 'sunrise', '3798', '' ],
[ '난방', 'heating', '3799', '煖房·暖房' ],
[ '공통점', 'common feature', '3800', '共通點' ],
[ '어기다', 'to break, violate', '3801', '' ],
[ '쫓아가다', 'to run after, pursue, keep up with', '3802', '' ],
[ '진학하다', 'to enter (a higher level of) school', '3803', '進學' ],
[ '정지하다', 'to stop, halt', '3804', '停止' ],
[ '육지', 'land', '3805', '陸地' ],
[ '시점', 'point in time; point of view', '3806', '時點, 視點' ],
[ '수명', 'life, life span', '3807', '壽命' ],
[ '일자리', 'job, position', '3808', '' ],
[ '인하', 'reduction, cut', '3809', '引下' ],
[ '기념일', 'anniversary', '3810', '紀念日' ],
[ '퇴직', 'retirement', '3811', '退職' ],
[ '음식물', 'food (and drink)', '3812', '飮食物' ],
[
  '사원',
  'employee, member of the staff; temple, mosque',
  '3813',
  '社員, 寺院'
],
[ '합의', 'agreement, consent; consultation', '3814', '合意, 合議' ],
[ '설득하다', 'to persuade', '3815', '說得' ],
[ '대접하다', 'to treat, treat to, entertain', '3816', '待接' ],
[
  '당당하다',
  'to be imposing, commanding, confident, dignified',
  '3817',
  '堂堂'
],
[ '음반', 'record, album', '3818', '音盤' ],
[ '추천서', 'letter of recommendation', '3819', '推薦書' ],
[ '거두다', 'to harvest, reap, gain', '3820', '' ],
[ '첨부하다', 'to attach', '3821', '添附' ],
[ '뚫다', 'to bore, dig, pierce', '3822', '' ],
[ '초밥', 'sushi', '3823', '醋-' ],
[ '주름', 'wrinkle, crease', '3824', '' ],
[ '계좌', 'bank account', '3825', '計座' ],
[ '엎드리다', 'to lie face down', '3826', '' ],
[
  '뒤지다',
  'to look through, go through, search for, rummage; to fall behind',
  '3827',
  ''
],
[ '낭비하다', 'to waste', '3828', '浪費' ],
[ '거칠다', 'to be rough, coarse', '3829', '' ],
[ '사연', 'story, situation', '3830', '事緣' ],
[
  '냉정하다',
  'to be cool-headed, objective, cold-hearted',
  '3831',
  '冷靜, 冷情'
],
[ '넉넉하다', 'to be sufficient, plenty', '3832', '' ],
[ '종로', 'Jongno', '3833', '鍾路/鐘路' ],
[ '붐비다', 'to be crowded, packed', '3834', '' ],
[ '고모부', "father's sister's husband", '3835', '姑母夫' ],
[ '줄곧', 'all the time, continually', '3836', '' ],
[ '동호회', 'club, society', '3837', '同好會' ],
[ '데다', 'to be burnt, get scorched, scald oneself', '3838', '' ],
[ '이발하다', 'to get a haircut (for men)', '3839', '理髮' ],
[ '풍선', 'balloon', '3840', '風船' ],
[ '솟다', 'to rise; to gush out, flow out', '3841', '' ],
[ '염색하다', 'to dye', '3842', '染色' ],
[ '간섭하다', 'to interfere, meddle', '3843', '干涉' ],
[ '무덤', 'grave, tomb', '3844', '' ],
[ '초조하다', 'to be anxious, restless, impatient', '3845', '焦燥' ],
[ '사막', 'desert', '3846', '沙漠·砂漠' ],
[ '험하다', 'to be rugged, rough, sinister', '3847', '險' ],
[ '반성', 'self-reflection, self-examination', '3848', '反省' ],
[ '운반하다', 'to transport, carry, convey', '3849', '運搬' ],
[ '골고루', 'evenly, equally', '3850', '' ],
[ '경력', 'career, work experience', '3851', '經歷' ],
[
  '빠뜨리다',
  'to drop, throw into; to omit, exclude; to tempt',
  '3852',
  ''
],
[ '외숙모', "maternal uncle's wife", '3853', '外叔母' ],
[ '비결', 'secret, key (of how to do something)', '3854', '秘訣' ],
[ '연봉', 'annual salary', '3855', '年俸' ],
[ '무관심하다', 'to be indifferent, unconcerned', '3856', '無關心' ],
[ '작은아버지', "father's younger brother", '3857', '' ],
[ '작은어머니', "the wife of father's younger brother", '3858', '' ],
[ '반죽', 'dough', '3859', '' ],
[ '국회', 'national assembly', '3860', '國會' ],
[ '점퍼', 'jacket, windbreaker', '3861', '' ],
[ '물러나다', 'to fall back, retreat, retire', '3862', '' ],
[ '접하다', 'to learn, hear', '3863', '接' ],
[ '안심하다', 'to be relieved, rest easy', '3864', '安心' ],
[ '채', 'counter for houses', '3865', '' ],
[ '연설', 'speech, address', '3866', '演說' ],
[ '만점', 'perfect score', '3867', '滿點' ],
[ '국제선', 'international flight', '3868', '國際線' ],
[ '작전', 'operation, military action', '3869', '作戰' ],
[ '끌어안다', 'to hug, cuddle', '3870', '' ],
[ '얼룩', 'stain, spot', '3871', '' ],
[ '실업', 'unemployment; industry, business', '3872', '失業, 實業' ],
[ '상금', 'prize money, award (cash)', '3873', '賞金' ],
[ '고요하다', 'to be still, quiet, calm, silent, peaceful', '3874', '' ],
[ '발자국', 'footstep, footprint', '3875', '' ],
[ '쓰러지다', 'to fall down, drop down, collapse', '3876', '' ],
[ '의욕', 'will, drive', '3877', '意欲' ],
[ '반기다', 'to greet, welcome', '3878', '' ],
[ '중순', 'the middle ten days of a month', '3879', '中旬' ],
[ '곧잘', 'quite well, frequently', '3880', '' ],
[ '생방송', 'live broadcast', '3881', '生放送' ],
[ '범위', 'scope, range, extent, limits', '3882', '範圍' ],
[ '별명', 'nickname', '3883', '別名' ],
[ '드러내다', 'to reveal, expose, show', '3884', '' ],
[ '권력', 'power', '3885', '權力' ],
[ '베란다', 'porch', '3886', '' ],
[ '국화', 'chrysanthemum', '3887', '菊花' ],
[ '해롭다', 'to be harmful, bad', '3888', '害-' ],
[ '축소하다', 'to reduce, cut down', '3889', '縮小' ],
[ '코스', 'course, route', '3890', '' ],
[ '곡식', 'grain, cereals', '3891', '穀食' ],
[ '오락', 'entertainment, pastime, recreation', '3892', '娛樂' ],
[ '처방전', 'prescription', '3893', '處方箋' ],
[ '시행', 'enforcement, implementation', '3894', '施行' ],
[ '옷차림', 'dress, attire, getup', '3895', '' ],
[ '기혼', 'married', '3896', '旣婚' ],
[ '깨', 'sesame', '3897', '' ],
[ '새집', "new house; bird's nest", '3898', '' ],
[ '실종', 'disappearance', '3899', '失踪' ],
[ '미지근하다', 'to be lukewarm, tepid', '3900', '' ],
[ '발행', 'publication, issue', '3901', '發行' ],
[ '수속', 'procedure', '3902', '手續' ],
[ '지폐', 'paper money', '3903', '紙幣' ],
[ '대청소', 'general cleaning', '3904', '大淸掃' ],
[ '대조하다', 'to contrast, compare', '3905', '對照' ],
[ '체력', 'physical strength', '3906', '體力' ],
[ '열매', 'fruit, nut, berry', '3907', '' ],
[ '의무', 'obligation, duty', '3908', '義務' ],
[ '입시', 'entrance exam', '3909', '入試' ],
[
  '재생하다',
  'to remake, regenerate, recycle, play [a video]',
  '3910',
  '再生'
],
[ '비기다', 'to end in a tie', '3911', '' ],
[ '새기다', 'to engrave, carve', '3912', '' ],
[ '메모지', 'memo pad, notepad', '3913', 'memo紙' ],
[ '환갑', 'sixtieth birthday', '3914', '還甲' ],
[ '바퀴', 'wheel; round; cockroach', '3915', '' ],
[ '판사', 'judge', '3916', '判事' ],
[ '긋다', 'to draw a line', '3917', '' ],
[ '배우자', 'spouse, partner', '3918', '配偶者' ],
[ '기다', 'to crawl', '3919', '' ],
[ '초청하다', 'to invite', '3920', '招請' ],
[ '확장하다', 'to expand, extend', '3921', '擴張' ],
[ '순위', 'rating, ranking, position', '3922', '順位' ],
[ '기획', 'planning', '3923', '企劃' ],
[ '부서', 'department, division', '3924', '部署' ],
[ '탄생하다', 'to be born', '3925', '誕生' ],
[ '마땅하다', 'to be suitable, appropriate, to deserve', '3926', '' ],
[ '승리', 'victory', '3927', '勝利' ],
[ '청혼하다', 'to propose marriage', '3928', '請婚' ],
[ '설문지', 'questionnaire', '3929', '設問紙' ],
[ '가뭄', 'drought', '3930', '' ],
[ '가라앉다', 'to sink, settle', '3931', '' ],
[ '계기', 'opportunity, motive; instrument', '3932', '契機, 計器' ],
[ '정책', 'policy', '3933', '政策' ],
[ '좌측', "one's left", '3934', '左側' ],
[ '노선도', 'route map', '3935', '路線圖' ],
[ '창', 'window; spear', '3936', '窓, 槍' ],
[ '합리적', 'rational, reasonable', '3937', '合理的' ],
[ '우체통', 'mailbox', '3938', '郵遞筒' ],
[ '집어넣다', 'to put into, bring in', '3939', '' ],
[ '창구', 'window, counter', '3940', '窓口' ],
[ '완성하다', 'to complete, finish', '3941', '完成' ],
[ '든든하다', 'to be strong, secure, reassured', '3942', '' ],
[ '인식', 'recognition, awareness', '3943', '認識' ],
[ '묘사', 'description', '3944', '描寫' ],
[ '덮이다', 'to be covered', '3945', '' ],
[ '환율', 'exchange rate', '3946', '換率' ],
[
  '학력',
  'level of education, academic background; academic ability',
  '3947',
  '學歷, 學力'
],
[ '세제', 'detergent; tax system', '3948', '洗劑, 稅制' ],
[ '어느덧', 'before you know it', '3949', '' ],
[ '네거리', 'four-way intersection', '3950', '' ],
[ '자격증', 'certificate, license', '3951', '資格證' ],
[ '보름달', 'full moon', '3952', '' ],
[ '비록', 'even though, even if', '3953', '' ],
[ '걸레', 'rag, mop; slut', '3954', '' ],
[ '수선하다', 'to mend, repair', '3955', '修繕' ],
[ '호박', 'zucchini, pumpkin; amber', '3956', '琥珀' ],
[ '세련되다', 'to be polished, refined', '3957', '洗練ㆍ洗鍊' ],
[ '책방', 'bookstore', '3958', '冊房' ],
[ '지불하다', 'to pay', '3959', '支拂' ],
[ '물기', 'moisture', '3960', '-氣' ],
[ '생생하다', 'to be vivid', '3961', '生生' ],
[ '굴', 'cave; oyster', '3962', '窟' ],
[ '공감', 'sympathy', '3963', '共感' ],
[ '대형', 'large size', '3964', '大型' ],
[ '은', 'silver', '3965', '銀' ],
[ '검토하다', 'to examine, review, study', '3966', '檢討' ],
[ '대로', 'main street, main road', '3967', '大路' ],
[ '꼼짝 못하다', 'to be unable to move at all', '3968', '' ],
[ '중단하다', 'to suspend, discontinue, interrupt', '3969', '中斷' ],
[ '장수', 'seller, merchant; long life, longevity', '3970', '長壽' ],
[ '사진기', 'camera', '3971', '寫眞機' ],
[ '토하다', 'to throw up, vomit', '3972', '吐' ],
[ '장식하다', 'to decorate, trim, adorn', '3973', '裝飾·粧飾' ],
[ '보물', 'treasure', '3974', '寶物' ],
[ '색다르다', 'to be different, unconventional, unusual', '3975', '色-' ],
[ '차량', 'car, vehicle', '3976', '車輛' ],
[ '과식하다', 'to overeat', '3977', '過食' ],
[ '은혜', 'favor, kindness, benefits', '3978', '恩惠' ],
[
  '빼앗기다',
  'to be taken away, be robbed of; to be fascinated',
  '3979',
  ''
],
[ '실험하다', 'to perform an experiment', '3980', '實驗' ],
[ '빛깔', 'color, tint', '3981', '' ],
[ '소유하다', 'to own, have', '3982', '所有' ],
[ '귀가하다', 'to go home, come home', '3983', '歸家' ],
[ '일출', 'sunrise', '3984', '日出' ],
[ '진리', 'truth', '3985', '眞理' ],
[ '효율적', 'efficient', '3986', '效率的' ],
[ '출국하다', 'to leave the country', '3987', '出國' ],
[ '다물다', "to close one's mouth", '3988', '' ],
[ '취업', 'employment, work', '3989', '就業' ],
[ '날카롭다', 'to be sharp, pointed', '3990', '' ],
[ '진로', 'course, direction, path', '3991', '進路' ],
[ '습기', 'moisture, humidity', '3992', '濕氣' ],
[ '뜯다', 'to tear, pick, pluck, take away', '3993', '' ],
[ '자신감', 'confidence', '3994', '自信感' ],
[ '미련', 'lingering attachment', '3995', '未練' ],
[ '내성적', 'introverted, reserved', '3996', '內省的' ],
[ '우편물', 'mail', '3997', '郵便物' ],
[ '개최하다', 'to hold', '3998', '開催' ],
[ '집세', 'rent', '3999', '―貰' ],
[ '마음가짐', 'mental attitude', '4000', '' ],
[ '헤매다', 'to wander about, walk around', '4001', '' ],
[ '끼우다', 'to put between, insert', '4002', '' ],
[ '설문', 'question (on a questionnaire)', '4003', '說問' ],
[ '입장료', 'admission fee', '4004', '入場料' ],
[ '대출', 'loan, lending', '4005', '貸出' ],
[ '간편하다', 'to be convenient, easy', '4006', '簡便' ],
[ '무지개', 'rainbow', '4007', '' ],
[ '평', 'review, comment', '4008', '評' ],
[ '연령', 'age', '4009', '年齡' ],
[ '비율', 'ratio, proportion', '4010', '比率' ],
[ '안정', 'stability; calm, rest', '4011', '安定, 安靜' ],
[ '꾸중', 'scolding', '4012', '' ],
[ '수면제', 'sleeping pills', '4013', '睡眠劑' ],
[ '탑승하다', 'to board', '4014', '搭乘' ],
[ '반품하다', 'to return', '4015', '返品' ],
[ '말다툼', 'quarrel, dispute', '4016', '' ],
[ '콧노래', 'humming', '4017', '' ],
[ '거래처', 'customer, account', '4018', '去來處' ],
[ '보수', 'pay, wages; repair', '4019', '報酬, 補修' ],
[ '해소', 'solution, relief, settlement', '4020', '解消' ],
[ '여가', 'leisure, spare time', '4021', '餘暇' ],
[ '종종', 'sometimes, now and then, often', '4022', '種種' ],
[ '독자', 'reader, subscriber', '4023', '讀者' ],
[ '악취', 'bad smell, stink', '4024', '惡臭' ],
[ '사나이', 'man', '4025', '' ],
[ '실감하다', 'to feel, realize', '4026', '實感' ],
[
  '효도하다',
  "to be a good son/daughter, devoted to one's parents",
  '4027',
  '孝道'
],
[ '염려', 'worry, fear', '4028', '念慮' ],
[ '내지', 'from .. to', '4029', '乃至' ],
[ '공연장', 'concert hall, venue', '4030', '公演場' ],
[ '입히다', 'to dress someone, cover', '4031', '' ],
[ '크림', 'cream', '4032', '' ],
[ '뜰', 'yard, garden', '4033', '' ],
[ '제자', 'student, disciple', '4034', '弟子' ],
[ '쓸다', 'to sweep', '4035', '' ],
[ '앞두다', 'to have something ahead', '4036', '' ],
[ '심지어', 'even, what is more', '4037', '甚至於' ],
[ '사로잡다', 'to capture', '4038', '' ],
[ '둘레', 'circumference', '4039', '' ],
[ '잔소리하다', 'to nag, scold, nitpick', '4040', '' ],
[ '산소', 'oxygen; grave, tomb', '4041', '酸素, 山所' ],
[ '발표회', 'recital, performance, conference', '4042', '發表會' ],
[ '쑥스럽다', 'to be shy, bashful, embarrassed', '4043', '' ],
[ '고소하다', 'to be savory, aromatic; to gloat, show joy', '4044', '' ],
[ '알아내다', 'to discover, figure out', '4045', '' ],
[ '멸치', 'anchovy', '4046', '' ],
[ '연못', 'pond', '4047', '蓮―' ],
[ '이자', 'interest', '4048', '利子' ],
[ '꽃무늬', 'floral design', '4049', '' ],
[ '베풀다', 'to give (generously)', '4050', '' ],
[ '리본', 'ribbon', '4051', '' ],
[ '안전띠', 'safety belt', '4052', '安全-' ],
[ '부정적', 'negative', '4053', '否定的' ],
[ '현지', 'the actual place, the very spot', '4054', '現地' ],
[ '공통되다', 'to be common', '4055', '共通' ],
[ '꺾다', 'to break off, snap', '4056', '' ],
[ '연도', 'year', '4057', '年度' ],
[ '우물', 'well', '4058', '' ],
[ '요가', 'yoga', '4059', '' ],
[ '오래오래', 'for a long time, forever', '4060', '' ],
[ '무관하다', 'to be unrelated, irrelevant', '4061', '無關' ],
[ '형부', "the husband of a girl's elder sister", '4062', '兄夫' ],
[ '운전자', 'driver', '4063', '運轉者' ],
[ '이것저것', 'this and that', '4064', '' ],
[ '지점', 'spot, point, place; branch office', '4065', '地點, 支店' ],
[ '보석', 'jewel; bail', '4066', '寶石, 保釋' ],
[ '기부하다', 'to donate', '4067', '寄附' ],
[ '수행하다', 'to carry out, do', '4068', '遂行' ],
[ '초청장', 'invitation', '4069', '招請狀' ],
[ '마음껏', 'as much as one likes', '4070', '' ],
[ '보리', 'barley', '4071', '' ],
[ '약혼자', 'fiancé, fiancée', '4072', '約婚者' ],
[ '귀하다', 'to be noble, rare, precious', '4073', '貴' ],
[ '용건', 'business, matter of business', '4074', '用件' ],
[ '일교차', 'daily temperature range', '4075', '日較差' ],
[ '우편함', 'mailbox', '4076', '郵便函' ],
[ '살찌다', 'to gain weight', '4077', '' ],
[ '찜질하다', 'to apply a hot pack', '4078', '' ],
[ '부위', 'part, region', '4079', '部位' ],
[ '매달리다', 'to hang, cling to', '4080', '' ],
[ '살림', 'household, housekeeping', '4081', '' ],
[ '향', 'scent', '4082', '香' ],
[ '향상되다', 'to improve', '4083', '向上' ],
[ '정기', 'fixed interval, period; spirit, energy', '4084', '定期, 精氣' ],
[ '엄숙하다', 'to be solemn, somber', '4085', '嚴肅' ],
[ '덩어리', 'lump, chunk', '4086', '' ],
[ '숙박하다', 'to stay the night', '4087', '宿泊' ],
[ '승낙하다', 'to agree, consent, approve', '4088', '承諾' ],
[ '물감', 'paint, dye, color', '4089', '' ],
[ '차원', 'level, dimension', '4090', '次元' ],
[ '소아과', 'pediatrics', '4091', '小兒科' ],
[ '학위', 'academic degree', '4092', '學位' ],
[ '원장', 'director', '4093', '園長·院長' ],
[ '기호', 'taste, preference (for); sign, symbol', '4094', '嗜好, 記號' ],
[ '홀로', 'alone, by oneself', '4095', '' ],
[ '수사', 'investigation; numeral', '4096', '搜査, 數詞' ],
[ '측', 'side', '4097', '側' ],
[ '보행자', 'pedestrian', '4098', '步行者' ],
[ '한국인', 'Korean', '4099', '韓國人' ],
[ '기말시험', 'final exam', '4100', '期末試驗' ],
[ '드라이어', 'dryer', '4101', '' ],
[ '쫓아오다', 'to follow, chase', '4102', '' ],
[ '수화기', 'telephone receiver', '4103', '受話器' ],
[ '직진하다', 'to go straight', '4104', '直進' ],
[ '치수', 'size, measurement', '4105', '-數' ],
[ '거래', 'deal, dealings, trade', '4106', '去來' ],
[ '늦추다', 'to postpone, loosen, ease', '4107', '' ],
[ '조직', 'group, organization', '4108', '組織' ],
[ '미역', 'seaweed, sea mustard', '4109', '' ],
[ '장비', 'equipment, gear', '4110', '裝備' ],
[ '옥상', 'rooftop', '4111', '屋上' ],
[ '줄넘기하다', 'to jump rope', '4112', '' ],
[ '볼거리', 'spectacle, attraction', '4113', '' ],
[ '공손하다', 'to be polite, courteous', '4114', '恭遜' ],
[ '중고차', 'used car', '4115', '中古車' ],
[ '저장하다', 'to store, preserve', '4116', '貯藏' ],
[
  '수정',
  'modification; correction; fertilization',
  '4117',
  '修正, 修訂, 受精'
],
[ '견학', 'field trip, tour', '4118', '見學' ],
[ '생물', 'living thing, creature', '4119', '生物' ],
[ '소박하다', 'to be simple (and honest)', '4120', '素朴' ],
[ '띄우다', 'to make something fly or float; to send', '4121', '' ],
[ '진급하다', 'to be promoted, move up', '4122', '進級' ],
[ '국제적', 'international', '4123', '國際的' ],
[ '강력하다', 'to be strong, powerful', '4124', '强力' ],
[ '수필', 'essay', '4125', '隨筆' ],
[ '학문', 'learning, study', '4126', '學問' ],
[ '내려앉다', 'to collapse, fall down', '4127', '' ],
[ '일시', 'date and time; for a time', '4128', '日時, 一時' ],
[ '비자', 'visa', '4129', '' ],
[ '여유롭다', 'to be leisurely', '4130', '餘裕' ],
[ '산꼭대기', 'top of a mountain', '4131', '山-' ],
[ '하필', 'why of all the [things, people etc.]', '4132', '何必' ],
[ '공중', 'public; the air, space', '4133', '公衆, 空中' ],
[ '속삭이다', 'to whisper', '4134', '' ],
[ '닥치다', 'to approach, draw near; to shut up', '4135', '' ],
[ '문구', 'stationery; phrase, sentence', '4136', '文具, 文句' ],
[ '품목', 'item, goods', '4137', '品目' ],
[ '확정하다', 'to determine, decide, settle, fix', '4138', '確定' ],
[ '고무신', 'rubber shoes', '4139', '' ],
[ '할부', 'monthly installment plan', '4140', '割賦' ],
[ '감시하다', 'to watch, observe, do surveillance', '4141', '監視' ],
[ '오븐', 'oven', '4142', '' ],
[ '이빨', 'tooth', '4143', '' ],
[ '국회의원', 'member of national assembly', '4144', '國會議員' ],
[ '운전기사', 'driver', '4145', '運轉技士' ],
[ '가렵다', 'to be itchy, feel itchy', '4146', '' ],
[ '측면', 'side, flank', '4147', '側面' ],
[ '정직', 'honesty; suspension', '4148', '正直, 停職' ],
[ '쾌적하다', 'to be pleasant, nice, comfortable', '4149', '快適' ],
[ '늑대', 'wolf', '4150', '' ],
[ '대륙', 'continent', '4151', '大陸' ],
[ '베다', "to cut, slash, saw; to rest one's head on", '4152', '' ],
[ '철학', 'philosophy', '4153', '哲學' ],
[ '전환', 'change, switch', '4154', '轉換' ],
[ '꼬마', '(little) kid', '4155', '' ],
[ '특이하다', 'to be unusual, peculiar, unique', '4156', '特異' ],
[ '회견', 'interview', '4157', '會見' ],
[ '자정', 'midnight', '4158', '子正' ],
[ '제비', 'player; lot; swallow', '4159', '' ],
[ '최악', 'the worst', '4160', '最惡' ],
[ '망가지다', 'to break down, be damaged', '4161', '' ],
[ '물리다', 'to be bitten; to be fed up', '4162', '' ],
[ '고유하다', 'to be unique, distinct, innate', '4163', '固有' ],
[ '결제', 'payment', '4164', '決濟' ],
[ '월말', 'end of the month', '4165', '月末' ],
[ '인용', 'quotation, citation', '4166', '引用' ],
[ '제시간', 'scheduled time, appropriate time', '4167', '-時間' ],
[ '여부', 'or not', '4168', '與否' ],
[ '팩스', 'fax', '4169', '' ],
[ '뻔하다', 'to be obvious, clear', '4170', '' ],
[ '선명하다', 'to be clear, distinct, sharp', '4171', '鮮明' ],
[ '근로', 'work, labor', '4172', '勤勞' ],
[ '지배하다', 'to rule, control, dominate', '4173', '支配' ],
[ '이어서', 'continuing, following', '4174', '' ],
[ '비둘기', 'pigeon', '4175', '' ],
[ '광장', 'plaza, square', '4176', '廣場' ],
[
  '형사',
  'detective, criminal (investigation, trial, etc.)',
  '4177',
  '刑事'
],
[ '상승하다', 'to rise, climb, increase', '4178', '上昇' ],
[ '체계', 'system', '4179', '體系' ],
[ '문의', 'inquiry', '4180', '問議' ],
[ '앞머리', 'bangs, front hair; forehead', '4181', '' ],
[ '유람선', 'cruise ship, excursion boat', '4182', '遊覽船' ],
[ '골프장', 'golf course', '4183', 'golf場' ],
[ '필름', 'film', '4184', '' ],
[ '차남', 'second eldest son', '4185', '次男' ],
[ '표지판', 'sign', '4186', '標識板' ],
[ '거부하다', 'to refuse, deny, reject', '4187', '拒否' ],
[ '자네', 'you', '4188', '' ],
[ '진료', 'medical examination and treatment', '4189', '診療' ],
[ '직전', 'just before', '4190', '直前' ],
[ '잦다', 'to be frequent', '4191', '' ],
[ '파악하다', 'to grasp, understand, figure out', '4192', '把握' ],
[
  '의원',
  'member of parliament or assembly or congress; clinic',
  '4193',
  '議員, 醫院'
],
[ '통합', 'combination, integration, unity', '4194', '統合' ],
[ '내적', 'inner, internal', '4195', '內的' ],
[ '문명', 'civilization', '4196', '文明' ],
[ '사고방식', 'way of thinking', '4197', '思考方式' ],
[ '널다', 'to hang', '4198', '' ],
[ '홀수', 'odd number', '4199', '―數' ],
[ '쿵', 'with a thud, bang', '4200', '' ],
[ '권유하다', 'to advise, suggest, recommend', '4201', '勸誘' ],
[ '배터리', 'battery', '4202', '' ],
[ '항구', 'port, harbor', '4203', '港口' ],
[ '어찌', 'how, what', '4204', '' ],
[ '하반기', 'the second half of the year', '4205', '下半期' ],
[ '이래', 'since', '4206', '以來' ],
[ '의학', 'medicine, medical science', '4207', '醫學' ],
[ '처지', 'position, circumstances, situation', '4208', '處地' ],
[ '탓', 'reason, blame', '4209', '' ],
[ '백성', 'the people, the public', '4210', '百姓' ],
[ '주목', 'attention, notice', '4211', '注目' ],
[ '배웅하다', 'to see someone out, see someone off', '4212', '' ],
[
  '단정하다',
  'to be neat, tidy, proper; to conclude, judge',
  '4213',
  '端正, 斷定'
],
[ '머뭇거리다', 'to hesitate, hold back', '4214', '' ],
[ '뭉치다', 'to gather (together), bundle, crumple up', '4215', '' ],
[ '마누라', 'wife', '4216', '' ],
[ '시위', 'demonstration, protest; string', '4217', '示威' ],
[ '웨이터', 'waiter', '4218', '' ],
[ '신속하다', 'to be quick, prompt, swift', '4219', '迅速' ],
[ '포장마차', '(covered) cart bar, snack stall', '4220', '布帳馬車' ],
[ '투명하다', 'to be clear, transparent', '4221', '透明' ],
[ '조그마하다', 'to be small, tiny', '4222', '' ],
[ '구석구석', 'every nook and corner', '4223', '' ],
[ '농장', 'farm, ranch', '4224', '農場' ],
[ '통제', 'control', '4225', '統制' ],
[ '긴장감', 'tension, suspense', '4226', '緊張感' ],
[ '간지럽다', 'to be ticklish, feel ticklish, itchy', '4227', '' ],
[ '겹치다', 'to overlap', '4228', '' ],
[ '승패', 'victory and/or defeat', '4229', '勝敗' ],
[ '연', 'year; kite; lotus', '4230', '年, 鳶, 蓮' ],
[ '솜', 'cotton', '4231', '' ],
[ '영화제', 'film festival', '4232', '映畫祭' ],
[ '인근', 'vicinity, nearby', '4233', '隣近' ],
[ '스승', 'teacher', '4234', '' ],
[ '싹', 'completely, entirely; bud, sprout', '4235', '' ],
[ '혈액', 'blood', '4236', '血液' ],
[ '관습', 'custom, convention', '4237', '慣習' ],
[ '논', 'rice field', '4238', '' ],
[ '여전하다', 'to be unchanged, be as usual', '4239', '如前' ],
[ '청춘', 'youth', '4240', '靑春' ],
[
  '기막히다',
  'to be struck dumb, be stunned; to be perfect, fantastic',
  '4241',
  '氣-'
],
[ '얄밉다', 'to be mean, nasty, provoking', '4242', '' ],
[ '납득하다', 'to accept', '4243', '納得' ],
[ '왕자', 'prince', '4244', '王子' ],
[ '포스터', 'poster', '4245', '' ],
[ '망원경', 'telescope', '4246', '望遠鏡' ],
[ '사슴', 'deer', '4247', '' ],
[ '동행하다', 'to accompany', '4248', '同行' ],
[ '상인', 'merchant, dealer', '4249', '商人' ],
[ '침착하다', 'to be composed, calm', '4250', '沈着' ],
[ '자취', 'trace, trail', '4251', '' ],
[ '내쉬다', 'to exhale, breathe out', '4252', '' ],
[ '분주하다', 'to be busy', '4253', '奔走' ],
[ '은은하다', 'to be vague, faint', '4254', '隱隱' ],
[ '저고리', 'Korean traditional jacket', '4255', '' ],
[ '까치', 'magpie', '4256', '' ],
[ '구청', 'district office', '4257', '區廳' ],
[ '주거', 'residence, dwelling', '4258', '住居' ],
[ '학점', 'credit, grade', '4259', '學點' ],
[ '신세', 'debt, life', '4260', '身世' ],
[ '불과하다', 'to be nothing more than', '4261', '不過' ],
[ '칠하다', 'to paint', '4262', '漆' ],
[ '통로', 'aisle, passage', '4263', '通路' ],
[ '지속되다', 'to last, continue', '4264', '持續' ],
[ '천', 'cloth, fabric', '4265', '' ],
[ '출판사', 'publishing company', '4266', '出版社' ],
[ '조개', 'clam', '4267', '' ],
[ '다양성', 'diversity', '4268', '多樣性' ],
[ '틀', 'frame, cast', '4269', '' ],
[ '온돌', 'the Korean floor heating system', '4270', '溫突' ],
[ '병아리', 'chick', '4271', '' ],
[ '손실', 'loss', '4272', '損失' ],
[ '쾌감', 'pleasant feeling, pleasure', '4273', '快感' ],
[ '초원', 'grassland, meadow', '4274', '草原' ],
[ '나들이', 'trip, going out', '4275', '' ],
[ '공식적', 'official, formal', '4276', '公式的' ],
[ '트이다', 'to get cleared, become better', '4277', '' ],
[ '튀다', 'to spatter, bounce', '4278', '' ],
[ '큰절', 'deep bow', '4279', '' ],
[ '소속되다', 'to belong to, be affiliated', '4280', '所屬' ],
[ '강렬하다', 'to be strong, intense', '4281', '强烈' ],
[ '혁명', 'revolution', '4282', '革命' ],
[ '달구다', 'to heat (up)', '4283', '' ],
[ '승자', 'winner', '4284', '勝者' ],
[ '포옹하다', 'to embrace, hug, cuddle', '4285', '抱擁' ],
[ '복권', 'lottery (ticket); reinstatement', '4286', '福券, 復權' ],
[ '찬란하다', 'to be radiant, shining, gorgeous', '4287', '燦爛·粲爛' ],
[ '떠맡다', 'to take on, assume, undertake', '4288', '' ],
[ '애교', 'charms, courtesy', '4289', '愛嬌' ],
[ '은근히', 'politely, courteously; quietly, inwardly', '4290', '慇懃' ],
[ '뇌물', 'bribe', '4291', '賂物' ],
[ '깃발', 'flag', '4292', '旗-' ],
[ '폭풍', 'storm, windstorm', '4293', '暴風' ],
[ '승인', 'approval', '4294', '承認' ],
[ '따스하다', 'to be warm', '4295', '' ],
[ '클럽', 'club', '4296', '' ],
[ '대학로', 'Daehangno, college street', '4297', '大學路' ],
[ '하룻밤', 'one night, overnight', '4298', '' ],
[ '천연', 'natural', '4299', '天然' ],
[ '원망하다', 'to resent, reproach, blame', '4300', '怨望' ],
[ '맨손', 'bare hands', '4301', '' ],
[ '지지하다', 'to support, back up', '4302', '支持' ],
[ '경영학', 'business administration', '4303', '經營學' ],
[ '삼각형', 'triangle', '4304', '三角形' ],
[ '넋', 'soul, spirit', '4305', '' ],
[ '수상하다', 'to be suspicious, strange, shady', '4306', '殊常' ],
[ '비참하다', 'to be miserable, wretched, tragic', '4307', '悲慘' ],
[ '쓸모있다', 'to be useful', '4308', '' ],
[ '형용사', 'adjective', '4309', '形容詞' ],
[ '내기', 'bet, betting', '4310', '' ],
[ '얼어붙다', 'to be frozen', '4311', '' ],
[ '참치', 'tuna', '4312', '' ],
[ '수신자', 'recipient', '4313', '受信者' ],
[ '연하', 'younger, junior', '4314', '年下' ],
[ '남김없이', 'entirely, without exception', '4315', '' ],
[ '상속하다', 'to inherit', '4316', '相續' ],
[ '다그치다', 'to push, press (with words, not physically)', '4317', '' ],
[ '의리', 'loyalty', '4318', '義理' ],
[ '목격하다', 'to witness, observe', '4319', '目擊' ],
[
  '지독하다',
  'to be vicious; to be awful, terrible, severe',
  '4320',
  '至毒'
],
[ '동거하다', 'to live together', '4321', '同居' ],
[ '딛다', 'to step (on)', '4322', '' ],
[ '탈춤', 'masked dance', '4323', '' ],
[ '어마어마하다', 'to be tremendous, immense, grand', '4324', '' ],
[ '검찰', 'the prosecution', '4325', '檢察' ],
[ '지문', 'fingerprint', '4326', '指紋' ],
[ '계급', '(social) class', '4327', '階級' ],
[ '노출하다', 'to expose', '4328', '露出' ],
[ '호응', '(positive) response', '4329', '呼應' ],
[ '교무실', "teachers' room", '4330', '敎務室' ],
[ '집착', 'obsession', '4331', '執着' ],
[ '희미하다', 'to be dim, faint, vague', '4332', '稀微' ],
[ '잔디', 'grass, lawn', '4333', '' ],
[ '부당하다', 'to be unfair, unjust', '4334', '不當' ],
[ '헷갈리다', 'to be confused, confusing; to be hard to find', '4335', '' ],
[ '번쩍', 'easily; high; all of a sudden', '4336', '' ],
[ '다름없이', 'equally, similarly, in the same way', '4337', '' ],
[ '시상식', 'awards ceremony', '4338', '施賞式' ],
[ '사납다', 'to be wild, fierce, violent', '4339', '' ],
[ '인내심', 'patience, endurance', '4340', '忍耐心' ],
[ '짝사랑', 'unrequited love, (secret) crush', '4341', '' ],
[ '성취하다', 'to achieve, accomplish', '4342', '成就' ],
[ '한발', 'one step', '4343', '' ],
[ '구두쇠', 'miser, cheapskate', '4344', '' ],
[ '취득하다', 'to acquire, obtain', '4345', '取得' ],
[ '초인종', 'doorbell', '4346', '招人鐘' ],
[
  '감당하다',
  'to be able to fulfill the duties; to handle, manage, deal (with)',
  '4347',
  '堪當, 勘當'
],
[ '질리다', 'to get tired, sick of', '4348', '' ],
[ '변함없이', 'still, as ever', '4349', '變-' ],
[ '회전하다', 'to turn, rotate, spin', '4350', '回轉/廻轉' ],
[ '영광', 'honor, glory', '4351', '榮光' ],
[
  '공유',
  'sharing (of information, files etc.); public ownership',
  '4352',
  '共有, 公有'
],
[ '금속', 'metal', '4353', '金屬' ],
[ '지원자', 'applicant, volunteer; supporter', '4354', '志願者, 支援者' ],
[
  '백수',
  'being out of work, unemployed; all kinds of animals',
  '4355',
  '白手, 百獸'
],
[ '탈', 'mask, disguise; sickness', '4356', '縫' ],
[ '명랑하다', 'to be cheerful, bright', '4357', '明朗' ],
[ '안동', 'Andong', '4358', '安東' ],
[ '재즈', 'jazz', '4359', '' ],
[
  '중요시하다',
  'to put a high value (on), regard as important',
  '4360',
  '重要視'
],
[ '이상형', 'ideal type', '4361', '理想型' ],
[ '강남', 'Gangnam', '4362', '江南' ],
[ '보호자', 'guardian, protector', '4363', '保護者' ],
[ '청색', 'blue', '4364', '靑色' ],
[ '황금', 'gold', '4365', '黃金' ],
[ '돋보이다', 'to stand out, show to advantage', '4366', '' ],
[ '형수', "one's elder brother's wife", '4367', '兄嫂' ],
[ '구독', 'subscription', '4368', '購讀' ],
[ '온수', 'warm water', '4369', '溫水' ],
[ '구박하다', 'to ill-treat, mistreat', '4370', '驅迫' ],
[ '시상', 'award; poetic concept, idea', '4371', '施賞, 詩想' ],
[ '낯', 'face', '4372', '' ],
[ '보류하다', 'to postpone, put off', '4373', '保留' ],
[ '잔인하다', 'to be cruel, brutal', '4374', '殘忍' ],
[ '개업하다', 'to open, start (a business)', '4375', '開業' ],
[ '군중', 'crowd', '4376', '群衆' ],
[ '끌어내다', 'to take out, bring out, drag out', '4377', '' ],
[ '정열', 'passion, enthusiasm', '4378', '情熱' ],
[ '실크', 'silk', '4379', '' ],
[ '진지', 'meal (hon.); position, stronghold', '4380', '陣地' ],
[ '부하', 'subordinate', '4381', '部下' ],
[ '기세', 'spirit, force', '4382', '氣勢' ],
[ '납치하다', 'to kidnap', '4383', '拉致' ],
[ '훗날', 'some day, the future', '4384', '後-' ],
[ '가해자', 'attacker, perpetrator', '4385', '加害者' ],
[ '유언', 'will, testament, dying wish', '4386', '遺言' ],
[ '적립', 'saving, accumulation', '4387', '積立' ],
[ '눌리다', 'to be squeezed, be pressed; to be oppressed', '4388', '' ],
[ '느닷없이', 'suddenly, abruptly', '4389', '' ],
[ '내복', 'long underwear', '4390', '內服' ],
[ '꼬다', 'to twist, cross, entwine', '4391', '' ],
[ '반도', 'peninsula', '4392', '半島' ],
[ '진열하다', 'to display, exhibit', '4393', '陳列' ],
[ '도표', 'diagram, chart', '4394', '圖表' ],
[ '촛불', 'candlelight', '4395', '' ],
[ '대본', 'script, screenplay', '4396', '臺本' ],
[ '새하얗다', 'to be pure white, snow-white', '4397', '' ],
[ '수익', 'profit, earnings, revenue', '4398', '收益' ],
[ '성냥', 'matches', '4399', '' ],
[ '연료', 'fuel', '4400', '燃料' ],
[ '무선', 'wireless, radio', '4401', '無線' ],
[ '실시간', 'real time, live', '4402', '實時間' ],
[ '권총', 'pistol, gun', '4403', '拳銃' ],
[ '간첩', 'spy, secret agent', '4404', '間諜' ],
[ '유효하다', 'to be valid', '4405', '有效' ],
[ '판소리', 'traditional Korean singing drama', '4406', '' ],
[ '축복', 'blessing', '4407', '祝福' ],
[ '서예', 'calligraphy', '4408', '書藝' ],
[ '붕대', 'bandage, dressing', '4409', '繃帶' ],
[ '죄책감', 'feeling of guilt, guilty conscience', '4410', '罪責感' ],
[ '감돌다', 'to hang (in the air)', '4411', '' ],
[ '차지하다', 'to take possession, occupy', '4412', '' ],
[ '퇴장', 'walkout, exit, departure', '4413', '退場' ],
[ '경솔하다', 'to be careless, rash', '4414', '輕率' ],
[ '맴돌다', 'to circle, hover', '4415', '' ],
[ '황당하다', 'to be ridiculous, absurd, nonsensical', '4416', '荒唐' ],
[ '별것', 'something peculiar', '4417', '別-' ],
[ '해명하다', 'to explain (oneself), clarify', '4418', '解明' ],
[ '고아', 'orphan', '4419', '孤兒' ],
[ '번지다', 'to spread, run', '4420', '' ],
[ '화산', 'volcano', '4421', '火山' ],
[ '조치', 'action, steps, measures', '4422', '措置' ],
[ '끔찍하다', 'to be terrible, horrible', '4423', '' ],
[ '거스르다', 'to go upstream; to give change', '4424', '' ],
[
  '횡설수설하다',
  'to talk nonsense, babble, speak incoherently',
  '4425',
  '橫說竪說'
],
[ '불량하다', 'to be poor, faulty', '4426', '不良' ],
[ '굴다', 'to behave, act', '4427', '' ],
[ '목장', 'farm, ranch', '4428', '牧場' ],
[ '여리다', 'to be tender, soft, delicate', '4429', '' ],
[ '오누이', 'brother and sister', '4430', '' ],
[ '엎다', 'to turn upside down', '4431', '' ],
[ '괴물', 'monster', '4432', '怪物' ],
[ '거듭나다', 'to reform, turn over a new leaf', '4433', '' ],
[ '경호', 'guard', '4434', '警護' ],
[ '잠바', 'jacket', '4435', '' ],
[ '어쩌다가', 'by chance; once in a while', '4436', '' ],
[ '흑인', 'black, ethnic African', '4437', '黑人' ],
[ '여왕', 'queen', '4438', '女王' ],
[ '쪽지', 'note, message', '4439', '-紙' ],
[ '미남', 'good-looking man', '4440', '美男' ],
[ '이성적', 'rational, reasonable', '4441', '理性的' ],
[ '감격하다', 'to be deeply moved', '4442', '感激' ],
[ '명성', 'reputation, fame', '4443', '名聲' ],
[ '청취자', 'listener (of a radio or similar)', '4444', '聽取者' ],
[ '배신하다', 'to betray', '4445', '背信' ],
[ '탈의실', 'dressing room', '4446', '脫衣室' ],
[ '통조림', 'canned food', '4447', '桶-' ],
[ '무모하다', 'to be rash, reckless', '4448', '無謀' ],
[ '부유하다', 'to be rich, wealthy', '4449', '富裕' ],
[ '공평하다', 'to be fair, just, even-handed', '4450', '公平' ],
[ '한심하다', 'to be pathetic, pitiful', '4451', '寒心' ],
[ '명소', 'notable sights, attractions', '4452', '名所' ],
[ '재혼하다', 'to remarry, marry again', '4453', '再婚' ],
[ '국경', 'border, boundary', '4454', '國境' ],
[ '사양', 'specification; rejection, declining', '4455', '仕樣, 辭讓' ],
[ '결말', 'ending, finish, conclusion', '4456', '結末' ],
[ '기린', 'giraffe', '4457', '麒麟' ],
[ '영토', 'territory, domain', '4458', '領土' ],
[ '대가족', 'large family, extended family', '4459', '大家族' ],
[ '충전하다', 'to electrify, charge', '4460', '充電' ],
[ '중지하다', 'to discontinue, stop, suspend', '4461', '中止' ],
[ '신형', 'new model, new style', '4462', '新型' ],
[ '복장', 'attire, clothes', '4463', '服裝' ],
[ '수염', 'beard, moustache, whiskers', '4464', '鬚髥' ],
[ '부디', 'please', '4465', '' ],
[ '상품권', 'gift certificate', '4466', '商品券' ],
[ '전주', 'last week; Jeonju', '4467', '前週, 全州' ],
[ '동사', 'verb', '4468', '動詞' ],
[ '남몰래', 'secretly', '4469', '' ],
[ '선반', 'shelf', '4470', '' ],
[ '미녀', 'beautiful woman', '4471', '美女' ],
[ '돌이키다', 'to look back on, reflect; to undo', '4472', '' ],
[ '용의자', 'suspect', '4473', '容疑者' ],
[ '데모', 'demonstration', '4474', '' ],
[ '종료', 'end, conclusion, termination', '4475', '終了' ],
[ '둔하다', 'to be dull, slow, stupid', '4476', '鈍' ],
[ '협박하다', 'to threaten, intimidate', '4477', '脅迫' ],
[ '솥', 'iron pot', '4478', '' ],
[ '용법', 'usage, use', '4479', '用法' ],
[ '뻔뻔하다', 'to be shameless, brazen', '4480', '' ],
[ '벽돌', 'brick', '4481', '壁-' ],
[ '빈틈', 'gap, crack', '4482', '' ],
[ '방패', 'shield', '4483', '防牌' ],
[ '건장하다', 'to be well built, have a strong physique', '4484', '健壯' ],
[ '무례하다', 'to be rude, disrespectful', '4485', '無禮' ],
[ '혈압', 'blood pressure', '4486', '血壓' ],
[ '고용하다', 'to hire, employ', '4487', '雇用' ],
[ '간절히', 'earnestly, eagerly, sincerely', '4488', '懇切' ],
[ '빤히', 'evidently, obviously; intently', '4489', '' ],
[ '훈훈하다', 'to be comfortably warm', '4490', '薰薰' ],
[ '멍청하다', 'to be stupid, dumb', '4491', '' ],
[ '급식', 'food service', '4492', '給食' ],
[ '이를테면', 'for instance, so to speak', '4493', '' ],
[ '물끄러미', 'with a blank look', '4494', '' ],
[ '모음', 'vowel', '4495', '母音' ],
[ '펭귄', 'penguin', '4496', '' ],
[ '마감', 'deadline', '4497', '' ],
[ '온전하다', 'to be whole, intact', '4498', '穩全' ],
[ '요원', 'agent', '4499', '要員' ],
[ '올바로', 'honestly, uprightly, properly', '4500', '' ],
[ '객실', 'room; cabin', '4501', '客室' ],
[ '바래다', 'to fade, lose color', '4502', '' ],
[ '셈', 'figures, calculation', '4503', '' ],
[ '팝송', 'pop song', '4504', '' ],
[ '극복하다', 'to overcome', '4505', '克服' ],
[ '시리다', "to be cold (about one's body parts)", '4506', '' ],
[ '마지못해', 'reluctantly, grudgingly', '4507', '' ],
[ '꼬박꼬박', 'regularly', '4508', '' ],
[ '변기', 'toilet', '4509', '便器' ],
[ '숙면', 'deep sleep, sound sleep', '4510', '熟眠' ],
[ '고아원', 'orphanage', '4511', '孤兒院' ],
[ '인턴', 'intern', '4512', '' ],
[ '명상', 'meditation', '4513', '冥想·瞑想' ],
[ '기색', 'look, expression, sign', '4514', '氣色' ],
[ '야무지다', 'to be sturdy, skillful, shrewd', '4515', '' ],
[ '발휘하다', 'to demonstrate, show', '4516', '發揮' ],
[ '밥상', 'dinner table', '4517', '-床' ],
[ '탈락하다', 'to drop out, be eliminated', '4518', '脫落' ],
[ '야단맞다', 'to be scolded thoroughly', '4519', '惹端' ],
[
  '시시하다',
  'to be dull, boring; to be trivial, insignificant',
  '4520',
  ''
],
[ '검', 'sword', '4521', '劍' ],
[ '유행가', 'popular song', '4522', '流行歌' ],
[ '대장', 'general, admiral; leader, commander', '4523', '大將' ],
[ '체포하다', 'to arrest', '4524', '逮捕' ],
[ '탄탄하다', 'to be solid, strong, robust', '4525', '' ],
[ '뒤돌아보다', 'to look back', '4526', '' ],
[ '친숙하다', 'to be familiar', '4527', '親熟' ],
[ '또래', 'peer (group), same age group', '4528', '' ],
[ '싸늘하다', 'to be cool, chilly', '4529', '' ],
[ '뾰족하다', 'to be pointed, sharp', '4530', '' ],
[ '함정', 'trap', '4531', '陷穽' ],
[ '파업', 'strike, walkout', '4532', '罷業' ],
[ '하찮다', 'to be trivial, petty, insignificant', '4533', '' ],
[ '앞지르다', 'to pass, overtake, get ahead', '4534', '' ],
[ '정열적', 'passionate', '4535', '情熱的' ],
[ '삼국', 'three countries', '4536', '三國' ],
[ '꼴불견', 'unsightliness', '4537', '-不見' ],
[
  '봐주다',
  'to let someone off, let something slide, tolerate',
  '4538',
  ''
],
[ '모친', 'mother', '4539', '母親' ],
[ '조리법', 'recipe', '4540', '調理法' ],
[ '소심하다', 'to be timid, shy', '4541', '小心' ],
[ '양초', 'candle', '4542', '洋-' ],
[ '가곡', 'song', '4543', '歌曲' ],
[ '탁하다', 'to be murky, muddy, stuffy', '4544', '濁' ],
[ '자발적', 'voluntary', '4545', '自發的' ],
[ '되뇌다', 'to repeat, say (many times over)', '4546', '' ],
[ '맹세하다', 'to swear, pledge', '4547', '盟誓' ],
[
  '상견례',
  'meeting (between the families of the bride and the groom)',
  '4548',
  '相見禮'
],
[ '사극', 'historical drama', '4549', '史劇' ],
[ '망치', 'hammer', '4550', '' ],
[ '샘', 'spring, fountain; jealousy', '4551', '' ],
[ '연수', 'training, study', '4552', '硏修' ],
[ '알몸', 'naked body', '4553', '' ],
[ '이데올로기', 'ideology', '4554', '' ],
[ '이어폰', 'earphones', '4555', '' ],
[ '끝장', 'end, conclusion, termination', '4556', '' ],
[ '세균', 'bacteria, germ', '4557', '細菌' ],
[ '혼동하다', 'to confuse, mix up', '4558', '混同' ],
[ '잽싸다', 'to be quick, swift, agile', '4559', '' ],
[ '한반도', 'the Korean peninsula', '4560', '韓半島' ],
[ '이륙하다', 'to take off (about planes)', '4561', '離陸' ],
[ '모질다', 'to be harsh, hard, severe', '4562', '' ],
[ '교도소', 'jail, prison', '4563', '矯導所' ],
[ '더듬다', 'to grope, feel around', '4564', '' ],
[ '소질', 'aptitude, talent', '4565', '素質' ],
[ '껍데기', 'shell, skin', '4566', '' ],
[ '수줍다', 'to be shy', '4567', '' ],
[ '교정하다', 'to proofread; to correct', '4568', '校正, 矯正' ],
[ '감염', 'infection', '4569', '感染' ],
[ '원수', 'enemy', '4570', '怨讐' ],
[ '기절하다', 'to faint, pass out', '4571', '氣絶' ],
[ '유리하다', 'to be favorable, advantageous', '4572', '有利' ],
[ '장바구니', 'shopping basket', '4573', '場-' ],
[ '외향적', 'extroverted, outgoing', '4574', '外向的' ],
[ '의젓하다', 'to be mature, dignified', '4575', '' ],
[ '매력적', 'attractive, charming', '4576', '魅力的' ],
[
  '미숙하다',
  'to be inexperienced, unskilled; to be unripe, immature',
  '4577',
  '未熟'
],
[ '노크하다', 'to knock', '4578', '' ],
[ '빈칸', 'blank space, blank column', '4579', '' ],
[ '조만간', 'soon, shortly, before long', '4580', '早晩間' ],
[ '단조롭다', 'to be monotonous', '4581', '單調-' ],
[ '이력', 'background, personal history, record', '4582', '履歷' ],
[ '이주하다', 'to move, migrate', '4583', '移住' ],
[
  '애매하다',
  'to be vague, obscure, uncertain; to be wrongly accused',
  '4584',
  '曖昧'
],
[ '특집', 'special edition', '4585', '特輯' ],
[ '낭만', 'romance', '4586', '浪漫' ],
[ '저항', 'resistance, defiance', '4587', '抵抗' ],
[ '대우하다', 'to treat', '4588', '待遇' ],
[ '굴뚝', 'chimney', '4589', '' ],
[ '평평하다', 'to be flat, even', '4590', '平平' ],
[
  '따갑다',
  "to be stinging, burning, irritated (for one's skin or body parts)",
  '4591',
  ''
],
[ '정화', 'purification', '4592', '淨化' ],
[ '사은품', 'free gift', '4593', '謝恩品' ],
[ '정밀', 'detailed, precise, exact', '4594', '精密' ],
[ '해고하다', 'to fire, dismiss', '4595', '解雇' ],
[ '남색', 'indigo, deep blue', '4596', '藍色' ],
[ '뜻하다', 'to mean, signify; to intend, plan, aim', '4597', '' ],
[ '듯하다', 'to look like, seem, look as if', '4598', '' ],
[ '지휘하다', 'to command, lead, direct', '4599', '指揮' ],
[ '건국', 'establishment of a country', '4600', '建國' ],
[ '백과사전', 'encyclopedia', '4601', '百科事典' ],
[ '자재', 'materials', '4602', '資材' ],
[ '전학가다', 'to change schools', '4603', '轉學' ],
[ '물품', 'item, article; goods', '4604', '物品' ],
[ '동참하다', 'to join, take part in', '4605', '同參' ],
[
  '소장',
  'manager, head; written complaint, petition',
  '4606',
  '所長, 訴狀'
],
[ '예능', 'artistic talents', '4607', '藝能' ],
[ '외양', 'appearance, look', '4608', '外樣' ],
[ '권한', 'rights, authority', '4609', '權限' ],
[ '기약', 'promise', '4610', '期約' ],
[ '응급', 'emergency', '4611', '應急' ],
[ '고소', 'accusation, (legal) charge; wry smile', '4612', '告訴, 苦笑' ],
[ '안락하다', 'to be comfortable', '4613', '安樂' ],
[ '당사자', 'the person directly involved', '4614', '當事者' ],
[ '음', 'sound, tone; yin', '4615', '音, 陰' ],
[ '주선하다', 'to arrange, set up', '4616', '周旋' ],
[
  '상의',
  'discussion, consultation; jacket, coat, shirt, blouse',
  '4617',
  '相議, 上衣'
],
[ '독신', 'bachelorhood', '4618', '獨身' ],
[ '희한하다', 'to be strange, odd, unusual, rare', '4619', '稀罕' ],
[ '미모', 'good looks, beautiful face', '4620', '美貌' ],
[ '기어이', 'no matter what, without fail', '4621', '期於-' ],
[ '피시', 'PC (personal computer)', '4622', '' ],
[ '조회', 'inquiry (into)', '4623', '照會' ],
[ '실외', 'outdoors', '4624', '室外' ],
[ '충치', 'cavity, decayed tooth', '4625', '蟲齒' ],
[ '구성원', 'member', '4626', '構成員' ],
[ '건축물', 'building, structure', '4627', '建築物' ],
[ '거문고', 'Korean harp', '4628', '' ],
[ '가이드', 'tour guide, guide book', '4629', '' ],
[ '무술', 'martial arts', '4630', '武術' ],
[ '업소', 'business establishment', '4631', '業所' ],
[ '서먹하다', 'to feel awkward, be ill at ease', '4632', '' ],
[ '합창', 'chorus, ensemble', '4633', '合唱' ],
[ '깃', 'collar; feather', '4634', '' ],
[ '벚꽃', 'cherry blossoms', '4635', '' ],
[ '연예', 'entertainment', '4636', '演藝' ],
[ '노릇', 'role, function', '4637', '' ],
[ '재난', 'disaster, catastrophe', '4638', '災難' ],
[ '보육원', 'orphanage', '4639', '保育院' ],
[
  '실물',
  'real [life] (as opposed to a picture or a model)',
  '4640',
  '實物'
],
[ '후원', 'support, backing, sponsorship', '4641', '後援' ],
[ '카운터', 'register, counter', '4642', '' ],
[ '엄청', 'very, very much', '4643', '' ],
[ '황색', 'yellow', '4644', '黃色' ],
[ '발라드', 'ballad', '4645', '' ],
[ '호의', 'goodwill, kindness', '4646', '好意' ],
[
  '지성',
  'brain power, intelligence; sincerity, devotion',
  '4647',
  '知性, 至誠'
],
[ '부사', 'adverb', '4648', '副詞' ],
[ '왜곡하다', 'to distort, twist', '4649', '歪曲' ],
[ '명사', 'noun', '4650', '名詞' ],
[ '막바지', 'last stage, end', '4651', '' ],
[ '우유부단하다', 'to be indecisive', '4652', '優柔不斷' ],
[
  '준수하다',
  'to abide by, comply with, obey [rules, law]; to be good-looking',
  '4653',
  '遵守, 俊秀'
],
[ '햄', 'ham', '4654', '' ],
[ '찜질방', 'Korean dry sauna, spa', '4655', '-房' ],
[ '자음', 'consonant', '4656', '子音' ],
[ '감미롭다', 'to be sweet, mellow', '4657', '甘味-' ],
[ '방귀', 'fart', '4658', '' ],
[ '충동', 'impulse, urge', '4659', '衝動' ],
[ '월차', 'every month, monthly', '4660', '月次' ],
[ '정체', 'identity, true character; not moving', '4661', '正體, 停滯' ],
[ '섬세하다', 'to be delicate, fine', '4662', '纖細' ],
[ '경쟁자', 'rival, competitor', '4663', '競爭者' ],
[ '팥', 'red-beans', '4664', '' ],
[ '용모', 'appearance, look, face', '4665', '容貌' ],
[ '염소', 'goat; chlorine', '4666', '鹽素' ],
[ '선입견', 'prejudice, preconcieved notion', '4667', '先入見' ],
[ '반나절', 'six hours, half the day', '4668', '半-' ],
[ '사치스럽다', 'to be luxurious, extravagant', '4669', '奢侈-' ],
[ '선박', 'big ship', '4670', '船舶' ],
[ '민망하다', 'to be embarrassed', '4671', '憫旦' ],
[ '취급하다', 'to handle, treat, deal with, deal in', '4672', '取扱' ],
[ '패배하다', 'to be defeated, lose', '4673', '敗北' ],
[ '목격자', 'eyewitness', '4674', '目擊者' ],
[ '예민하다', 'to be sharp, keen, acute, sensitive', '4675', '銳敏' ],
[ '책자', 'booklet, brochure', '4676', '冊子' ],
[ '거르다', 'to skip, miss; to filter (out)', '4677', '' ],
[ '끼니', 'meal', '4678', '' ],
[ '색연필', 'colored pencil', '4679', '色鉛筆' ],
[ '매출', 'sales', '4680', '賣出' ],
[ '팽창', 'expansion', '4681', '膨脹' ],
[ '협상', 'negotiation, talks', '4682', '協商' ],
[ '욕설', 'insult, abusive language', '4683', '辱說' ],
[ '익명', 'anonymity', '4684', '匿名' ],
[ '아득하다', 'to be far away, in the distance', '4685', '' ],
[ '접대', 'entertainment, reception, hospitality', '4686', '接待' ],
[ '정감', 'feeling, emotion, sentiment', '4687', '情感' ],
[ '보증금', '(security) deposit', '4688', '保證金' ],
[ '산뜻하다', 'to be fresh, refreshing', '4689', '' ],
[ '차분하다', 'to be calm, cool', '4690', '' ],
[
  '기일',
  "anniversary of one's death; fixed date, appointed day",
  '4691',
  '忌日, 期日'
],
[ '어미', 'ending; (animal) mother', '4692', '語尾' ],
[ '어설프다', 'to be imperfect, not adequate, sloppy', '4693', '' ],
[ '무난하다', 'to be easy, safe, acceptable', '4694', '無難' ],
[ '소송', 'lawsuit', '4695', '訴訟' ],
[ '출세하다', 'to have great success in life', '4696', '出世' ],
[ '멍들다', 'to get a bruise', '4697', '' ],
[ '음모', 'conspiracy, scheme', '4698', '陰謀' ],
[ '대결', 'contest, confrontation, fight', '4699', '對決' ],
[ '헐다', 'to tear down, demolish', '4700', '' ],
[ '급여', 'pay, salary', '4701', '給與' ],
[ '과음하다', 'to drink too much', '4702', '過飮' ],
[ '묘미', 'charm, beauty', '4703', '妙味' ],
[ '유출되다', 'to be spilled, leaked', '4704', '流出' ],
[ '교제', 'association, acquaintance, society', '4705', '交際' ],
[ '성향', 'tendency, inclination', '4706', '性向' ],
[ '소망', 'wish, hope, desire', '4707', '所望' ],
[ '식상하다', 'to be fed up, sick and tired', '4708', '食傷' ],
[ '시범', 'setting an example, demonstration', '4709', '示範' ],
[ '철새', 'migratory bird', '4710', '' ],
[ '하락', 'drop, fall, decrease', '4711', '下落' ],
[ '곤충', 'insect, bug', '4712', '昆蟲' ],
[ '통치', 'rule, reign', '4713', '統治' ],
[ '자상하다', 'to be attentive, considerate, thoughtful', '4714', '仔詳' ],
[ '깃털', 'feather', '4715', '' ],
[ '여사', 'Mrs., madame', '4716', '女史' ],
[ '흩날리다', 'to scatter, flutter, blow', '4717', '' ],
[ '등급', 'class, grade, ranking', '4718', '等級' ],
[ '언짢다', 'to be upset, displeased', '4719', '' ],
[ '지장', 'hindrance, obstacle, harm; thumbprint', '4720', '支障, 指章' ],
[ '중복', 'overlap, duplication', '4721', '重複' ],
[ '한산하다', 'to be quiet, empty, inactive', '4722', '閑散' ],
[ '처지다', 'to hang, droop, sink, be left behind', '4723', '' ],
[ '폐', 'lungs; trouble', '4724', '肺, 弊' ],
[ '외래어', 'loan words', '4725', '外來語' ],
[ '예리하다', 'to be sharp, keen', '4726', '銳利' ],
[ '궤도', 'track, orbit', '4727', '軌道' ],
[ '기이하다', 'to be strange, odd', '4728', '奇異' ],
[ '헛소문', 'groundless rumor', '4729', '-所聞' ],
[ '실명', "one's real name; blindness", '4730', '實名, 失明' ],
[ '선착순으로', 'by order of arrival', '4731', '先着順' ],
[ '개시하다', 'to begin, start, open', '4732', '開始' ],
[ '나열하다', 'to list', '4733', '羅列' ],
[ '별나다', 'to be peculiar, strange', '4734', '別-' ],
[ '비겁하다', 'to be cowardly', '4735', '卑怯' ],
[ '폭로하다', 'to disclose, expose', '4736', '暴露' ],
[ '보건소', 'health center', '4737', '保健所' ],
[ '왕비', 'queen, empress', '4738', '王妃' ],
[ '예정일', 'due date', '4739', '豫定日' ],
[ '학창', 'school', '4740', '學窓' ],
[ '강당', 'lecture hall', '4741', '講堂' ],
[ '물리학', 'physics', '4742', '物理學' ],
[ '아낌없이', 'generously, ungrudgingly, freely', '4743', '' ],
[ '썰매', 'sled, sleigh', '4744', '' ],
[ '인출하다', 'to withdraw', '4745', '引出' ],
[ '신하', 'servant, vassal, government officer', '4746', '臣下' ],
[ '행인', 'passerby, pedestrian', '4747', '行人' ],
[ '주어', 'subject', '4748', '主語' ],
[ '공학', 'engineering', '4749', '工學' ],
[ '김장', 'kimchi prepared for winter', '4750', '' ],
[ '복종하다', 'to obey', '4751', '服從' ],
[ '기껏', "to the best of one's ability", '4752', '' ],
[ '어지르다', 'to make a mess', '4753', '' ],
[ '오류', 'error, mistake', '4754', '誤謬' ],
[ '만족도', 'level of satisfaction', '4755', '滿足度' ],
[ '개편', 'reorganization, reform', '4756', '改編' ],
[ '입양하다', 'to adopt a child', '4757', '入養' ],
[ '가면', 'mask', '4758', '假面' ],
[ '은퇴하다', 'to retire', '4759', '隱退' ],
[ '액체', 'liquid', '4760', '液體' ],
[ '악마', 'devil', '4761', '惡魔' ],
[ '직관', 'intuition', '4762', '直觀' ],
[ '회계', 'accounting', '4763', '會計' ],
[ '출력하다', 'to print out', '4764', '出力' ],
[ '단서', 'clue, lead; condition', '4765', '端緖, 但書' ],
[ '추첨하다', 'to draw lots', '4766', '抽籤' ],
[ '농가', 'farmhouse, farming family', '4767', '農家' ],
[ '수직', 'vertical', '4768', '垂直' ],
[ '미용', 'beauty treatment', '4769', '美容' ],
[ '상세하다', 'to be detailed, full', '4770', '詳細' ],
[ '낭독하다', 'to read aloud, recite', '4771', '朗讀' ],
[ '맛보다', 'to taste, try', '4772', '' ],
[ '한도', 'limit (regarding money)', '4773', '限度' ],
[ '표적', 'target', '4774', '標的' ],
[ '혼란', 'confusion, turmoil, chaos', '4775', '混亂' ],
[ '최상', 'the best, the highest', '4776', '最上' ],
[ '시체', 'corpse', '4777', '屍體' ],
[ '당구', 'billiards', '4778', '撞球' ],
[ '자수하다', 'to turn oneself in', '4779', '自首' ],
[ '허전하다', 'to feel empty, lonesome', '4780', '' ],
[ '환절기', 'change of seasons', '4781', '換節期' ],
[ '샅샅이', 'everywhere, all over', '4782', '' ],
[ '뒤처지다', 'to be left behind', '4783', '' ],
[ '친근하다', 'to be familiar, informal, friendly', '4784', '親近' ],
[ '체류하다', 'to stay', '4785', '滯留' ],
[ '면회', 'visit', '4786', '面會' ],
[ '따위', 'etc., and the like', '4787', '' ],
[ '날로', 'day by day; raw, uncooked', '4788', '' ],
[ '알아차리다', 'to realize, notice', '4789', '' ],
[ '유래', 'origin', '4790', '由來' ],
[ '논란', 'controversy, dispute', '4791', '論難' ],
[ '취지', 'purpose, object', '4792', '趣旨' ],
[ '능하다', 'to be good, skilled (at)', '4793', '能' ],
[ '대여하다', 'to rent, lend, loan', '4794', '貸與' ],
[ '분쟁', 'dispute, conflict', '4795', '紛爭' ],
[ '단말기', 'terminal', '4796', '端末機' ],
[ '엿새', 'six days', '4797', '' ],
[ '예언', 'prophecy, prediction', '4798', '豫言' ],
[ '채점하다', 'to grade, mark', '4799', '採點' ],
[ '도리', 'duty, reason', '4800', '道理' ],
[ '추신', 'P.S.', '4801', '追伸' ],
[ '짤막하다', 'to be short, choppy', '4802', '' ],
[ '호칭', 'title, name', '4803', '呼稱' ],
[ '그루', 'counter for trees', '4804', '' ],
[ '이치', 'reason, logic', '4805', '理致' ],
[ '동정', 'sympathy, pity; movements; virginity', '4806', '同情, 動靜, 童貞' ],
[ '과도하다', 'to be excessive', '4807', '過度' ],
[ '생수', 'mineral water', '4808', '生水' ],
[ '수료하다', 'to complete, finish (a course)', '4809', '修了' ],
[ '암호', 'code, cipher', '4810', '暗號' ],
[ '양로원', 'nursing home', '4811', '養老院' ],
[ '물방울', 'drop of water', '4812', '' ],
[ '오죽', 'very, indeed, how', '4813', '' ],
[ '해양', 'the ocean, marine', '4814', '海洋' ],
[ '금고', 'safe, vault', '4815', '金庫' ],
[ '초라하다', 'to be shabby, poor, pitiful', '4816', '' ],
[ '매연', 'exhaust (fumes)', '4817', '煤煙' ],
[ '원작', 'original (work)', '4818', '原作' ],
[ '성능', 'efficiency, performance', '4819', '性能' ],
[ '도우미', 'helper, assistant', '4820', '' ],
[ '거만하다', 'to be arrogant, haughty', '4821', '倨慢' ],
[ '짭짤하다', 'to be a little salty', '4822', '' ],
[ '중개인', 'broker, agent, middleman', '4823', '仲介人' ],
[ '새싹', 'sprout, bud', '4824', '' ],
[ '법정', 'court of law', '4825', '法廷' ],
[ '지리', 'geography', '4826', '地理' ],
[ '기말', 'end of a term, end of a semester', '4827', '期末' ],
[
  '인상착의',
  '(the description of) look, personal appearance',
  '4828',
  '人相着衣'
],
[ '미아', 'missing child, lost child', '4829', '迷兒' ],
[ '탐색하다', 'to explore, investigate', '4830', '探索' ],
[ '완공되다', 'to be completed, be completely built', '4831', '完工' ],
[ '점치다', 'to divine, foretell', '4832', '占' ],
[ '야유회', 'picnic', '4833', '野遊會' ],
[ '떫다', 'to be bitter, sour', '4834', '' ],
[ '대인', 'adult, noble man; personal', '4835', '大人, 對人' ],
[ '엉금엉금', 'crawling, creeping', '4836', '' ],
[ '획득하다', 'to acquire, gain, win', '4837', '獲得' ],
[ '작정', 'intention, decision, plan', '4838', '作定' ],
[ '허탈하다', 'to be dejected, dispirited', '4839', '虛脫' ],
[ '평온하다', 'to be calm, peaceful, serene', '4840', '平穩' ],
[ '진수성찬', 'feast, lots of food', '4841', '珍羞盛饌' ],
[ '개조하다', 'to remodel, alter', '4842', '改造' ],
[ '다급하다', 'to be urgent', '4843', '多急' ],
[ '검거하다', 'to catch, arrest, round up', '4844', '檢擧' ],
[ '본격적', 'earnest, full-scale, regular', '4845', '本格的' ],
[ '제외하다', 'to exclude, leave out', '4846', '除外' ],
[ '팀장', 'team leader', '4847', 'team長' ],
[ '환호하다', 'to cheer', '4848', '喚呼' ],
[ '행방', "one's whereabouts", '4849', '行方' ],
[ '누이', 'sister', '4850', '' ],
[ '즐비하다', 'to stand closely together, form a line', '4851', '櫛比' ],
[ '장만하다', 'to prepare, purchase, get', '4852', '' ],
[ '씁쓸하다', 'to be bitter, disappointed', '4853', '' ],
[ '거북하다', 'to be uncomfortable', '4854', '' ],
[ '꼴찌', 'the last, the bottom', '4855', '' ],
[ '개강', 'beginning of semester', '4856', '開講' ],
[ '완료하다', 'to complete', '4857', '完了' ],
[ '더군다나', 'besides, moreover, further', '4858', '' ],
[
  '언뜻',
  'in an instant, in a flash; by chance, by accident',
  '4859',
  ''
],
[ '최강', 'the strongest', '4860', '最强' ],
[ '전략', 'strategy, tactic', '4861', '戰略' ],
[ '얽히다', 'to be tangled, involved', '4862', '' ],
[ '일화', 'anecdote', '4863', '逸話' ],
[ '애칭', 'nickname, pet name', '4864', '愛稱' ],
[ '빡빡하다', 'to be tight, rigid, inflexible', '4865', '' ],
[ '감탄하다', 'to admire, wonder', '4866', '感歎' ],
[ '모욕하다', 'to insult', '4867', '侮辱' ],
[ '엉성하다', 'to be poor, loose', '4868', '' ],
[ '확률', 'probability, chance', '4869', '確率' ],
[ '설계하다', 'to design, map out, plan', '4870', '設計' ],
[ '작별', 'farewell, parting', '4871', '作別' ],
[ '출전하다', 'to participate, compete', '4872', '出戰' ],
[ '창업하다', 'to establish, start, found', '4873', '創業' ],
[ '자영업', "one's own business, self-employment", '4874', '自營業' ],
[ '박람회', 'exhibition, exposition', '4875', '博覽會' ],
[ '건기', 'dry season', '4876', '乾期' ],
[ '경쾌하다', 'to be cheerful, rhythmical', '4877', '輕快' ],
[ '수강', 'taking a course', '4878', '受講' ],
[ '송금하다', 'to remit, send money', '4879', '送金' ],
[ '선불', 'payment in advance', '4880', '先拂' ],
[ '푸짐하다', 'to be generous, plentiful', '4881', '' ],
[ '납부하다', 'to pay', '4882', '納付' ],
[ '외동딸', 'only daughter and only child', '4883', '' ],
[ '재충전하다', 'to recharge', '4884', '再充電' ],
[ '계발하다', 'to develop', '4885', '啓發' ],
[ '터놓다', 'to open (up)', '4886', '' ],
[ '활력', 'energy, vitality', '4887', '活力' ],
[ '때우다', 'to fill, patch up; to solder', '4888', '' ],
[ '들통이 나다', 'to be detected, be revealed', '4889', '' ],
[ '매너', 'manners', '4890', '' ],
[ '회상하다', 'to recall, recollect', '4891', '回想' ],
[ '흥미진진하다', 'to be exciting, absorbing', '4892', '興味津津' ],
[ '실화', 'true story, real life story', '4893', '實話' ],
[ '위축되다', 'to be daunted, intimidated', '4894', '萎縮' ],
[ '좌절하다', 'to be frustrated, discouraged', '4895', '挫折' ],
[ '위인', 'great man; nature, disposition', '4896', '偉人, 爲人' ],
[ '빗나가다', 'to turn away, miss', '4897', '' ],
[ '흐뭇하다', 'to be pleased, satisfied, delighted', '4898', '' ],
[ '새까맣다', 'to be deep black', '4899', '' ],
[ '강연하다', 'give a lecture', '4900', '講演' ],
[ '뭉클하다', 'to be touching', '4901', '' ],
[ '벼락', 'lightning', '4902', '' ],
[ '유망하다', 'to be promising', '4903', '有望' ],
[ '불길하다', 'to be unlucky, ominous', '4904', '不吉' ],
[ '출출하다', 'to be slightly hungry', '4905', '' ],
[ '집사람', 'wife', '4906', '' ],
[ '악역', 'villain', '4907', '惡役' ],
[ '동남아', 'Southeast Asia', '4908', '東南亞' ],
[ '속성', 'attribute; quick', '4909', '屬性, 速成' ],
[ '전송하다', 'to send, transmit', '4910', '電送' ],
[ '공예', 'craft', '4911', '工藝' ],
[ '유감', 'regret', '4912', '遺憾' ],
[ '불꽃놀이', 'fireworks', '4913', '' ],
[ '압도적으로', 'overwhelmingly', '4914', '壓倒的' ],
[ '표명하다', 'to express, state', '4915', '表明' ],
[ '선비', 'classical scholar', '4916', '' ],
[ '궁궐', 'royal palace', '4917', '宮闕' ],
[ '기저귀', 'diaper', '4918', '' ],
[ '성묘하다', "to visit one's ancestral graves", '4919', '省墓' ],
[ '양해', 'understanding, consent', '4920', '諒解' ],
[ '새콤하다', 'to be sour, acidic', '4921', '' ],
[ '착용하다', 'to wear', '4922', '着用' ],
[ '출간하다', 'to publish', '4923', '出刊' ],
[ '일과', 'daily tasks', '4924', '日課' ],
[ '대견하다', 'to be proud', '4925', '' ],
[ '등교하다', 'to go to school', '4926', '登校' ],
[ '양손', 'both hands', '4927', '兩-' ],
[ '수다스럽다', 'to be talkative', '4928', '' ],
[ '육아', 'childcare, care of infants', '4929', '育兒' ],
[ '울타리', 'fence', '4930', '' ],
[ '끈끈하다', 'to be sticky', '4931', '' ],
[ '소인', 'child; postmark', '4932', '小人, 消印' ],
[ '발레', 'ballet', '4933', '' ],
[ '불쑥', 'unexpectedly, abruptly', '4934', '' ],
[ '오만하다', 'to be arrogant', '4935', '傲慢' ],
[ '차단하다', 'to block, shut off', '4936', '遮斷' ],
[ '샘물', 'spring water', '4937', '' ],
[ '뒤집히다', 'to be turned over, be reversed', '4938', '' ],
[ '묘하다', 'to be strange, weird', '4939', '妙' ],
[ '논술하다', 'to state, discuss', '4940', '論述' ],
[ '가시', 'thorn', '4941', '' ],
[ '환기', 'ventilation', '4942', '換氣' ],
[ '탐험하다', 'to explore', '4943', '探險' ],
[ '낙타', 'camel', '4944', '駱駝' ],
[ '건방지다', 'to be impertinent, arrogant', '4945', '' ],
[ '저물다', 'to get dark', '4946', '' ],
[ '보일러', 'boiler', '4947', '' ],
[ '꼭꼭', 'thoroughly, firmly', '4948', '' ],
[ '보약', 'tonic, restorative herbal medicine', '4949', '補藥' ],
[ '보배', 'treasure', '4950', '' ],
[ '공룡', 'dinosaur', '4951', '恐龍' ],
[ '넘나들다', 'to come and go often', '4952', '' ],
[ '기특하다', 'to be admirable, commendable', '4953', '奇特' ],
[ '드러눕다', 'to lie down, sprawl', '4954', '' ],
[ '질기다', 'to be tough, durable, persistent, tenacious', '4955', '' ],
[
  '감기다',
  "to be rolled, wound around; to shut one's eyes; to wash one's hair",
  '4956',
  ''
],
[ '고립', 'isolation', '4957', '孤立' ],
[ '덤비다', 'to come at, attack', '4958', '' ],
[ '소형차', 'compact car', '4959', '小型車' ],
[ '징그럽다', 'to be gross, nasty, creepy', '4960', '' ],
[ '향기롭다', 'to be fragrant, aromatic', '4961', '香氣' ],
[ '대추', 'jujube', '4962', '' ],
[ '선발하다', 'to select, pick out', '4963', '選拔' ],
[ '우르르', 'all together, all over', '4964', '' ],
[ '흥', 'fun, joy', '4965', '興' ],
[ '구슬', 'bead, marble', '4966', '' ],
[ '연약하다', 'to be weak, delicate, frail', '4967', '軟弱' ],
[ '자금', 'money, capital', '4968', '資金' ],
[ '낙서하다', 'to scribble, doodle', '4969', '落書' ],
[ '효녀', 'devoted daughter', '4970', '孝女' ],
[ '누렇다', 'to be yellow', '4971', '' ],
[ '귓속말', 'whisper', '4972', '' ],
[ '초가집', 'thatched-roof house', '4973', '草家-' ],
[ '기와집', 'tile-roofed house', '4974', '' ],
[ '재수하다', 'to cram to repeat a college entrance exam', '4975', '再修' ],
[ '성금', 'donation, contribution', '4976', '誠金' ],
[ '불우하다', 'to be underprivileged, disadvantaged', '4977', '不遇' ],
[ '굶주리다', 'to starve, go hungry', '4978', '' ],
[ '달라붙다', 'to stick, cling to', '4979', '' ],
[ '시행착오', 'trial and error', '4980', '施行錯誤' ],
[ '재물', 'wealth, riches', '4981', '財物' ],
[ '심경', 'mind, feelings', '4982', '心境' ],
[ '무식하다', 'to be ignorant', '4983', '無識' ],
[ '식성', 'taste, preference', '4984', '食性' ],
[ '푸른색', 'blue, green', '4985', '' ],
[ '무시무시하다', 'to be terrible, horrible', '4986', '' ],
[ '전화위복', 'blessing in disguise', '4987', '轉禍爲福' ],
[ '붉은색', 'red', '4988', '' ],
[ '기력', 'energy', '4989', '氣力' ],
[ '반칙', 'foul, violation', '4990', '反則' ],
[ '시무룩하다', 'to be sullen, displeased', '4991', '' ],
[ '구실', 'duty, obligation; excuse, pretext', '4992', '口實' ],
[ '낭만적', 'romantic', '4993', '浪漫的' ],
[ '부활하다', 'to revive, bring back, rise from the dead', '4994', '復活' ],
[ '약탈하다', 'to plunder, loot, pillage', '4995', '掠奪' ],
[ '캐다', 'to dig up', '4996', '' ],
[ '짜릿하다', 'to be thrilling, exhilarating', '4997', '' ],
[ '선하다', 'to be vivid, fresh; to be nice', '4998', '善' ],
[ '도끼', 'ax', '4999', '' ],
[ '구급차', 'ambulance', '5000', '救急車' ],
[ '체형', 'body type', '5001', '體型' ],
[ '부쩍', 'greatly, dramatically', '5002', '' ],
[ '개그', 'jokes, humor', '5003', '' ],
[ '강점', 'strengths; occupation', '5004', '强點, 强占' ],
[ '손대다', 'to touch', '5005', '' ],
[ '도박하다', 'to gamble', '5006', '賭博' ],
[ '인적', 'human', '5007', '人的' ],
[ '속셈', 'secret plan, hidden intention', '5008', '' ],
[ '개입하다', 'to intervene, interfere', '5009', '介入' ],
[ '동굴', 'cave', '5010', '洞窟' ],
[ '명당', 'ideal spot', '5011', '明堂' ],
[ '허무', 'futility, emptiness', '5012', '虛無' ],
[ '함성', 'shout, yell (by many people)', '5013', '喊聲' ],
[ '살금살금', 'stealthily, quietly', '5014', '' ],
[ '짬', 'free time', '5015', '' ],
[ '알약', 'pill', '5016', '-藥' ],
[ '뒷줄', 'back row', '5017', '' ],
[ '도둑질하다', 'to steal, rob', '5018', '' ],
[ '가정부', 'housekeeper', '5019', '家政婦' ],
[ '동해', 'the East Sea', '5020', '東海' ],
[ '관리비', 'management expenses, maintenance cost', '5021', '管理費' ],
[ '노임', 'wages, pay', '5022', '勞賃' ],
[ '자책하다', 'to blame oneself, reproach oneself', '5023', '自責' ],
[ '놀이기구', 'amusement park rides', '5024', '--機構' ],
[ '싱가포르', 'Singapore', '5025', '' ],
[ '녹음실', 'recording studio', '5026', '錄音室' ],
[ '물냉면', 'cold noodles served in water', '5027', '-冷麵' ],
[ '이모부', "mother's sister's husband", '5028', '姨母夫' ],
[ '제국', 'empire', '5029', '帝國' ],
[ '바람둥이', 'womanizer, playboy', '5030', '' ],
[ '환대', 'hospitality, [warm] welcome', '5031', '歡待' ],
[ '산보하다', 'to walk, stroll', '5032', '散步' ],
[ '취침', 'going to bed, retiring', '5033', '就寢' ],
[ '노래자랑', 'singing contest', '5034', '' ],
[
  '양다리',
  'double-deal, both ways (also used in the context of cheating)',
  '5035',
  '兩-'
],
[ '쪽팔리다', 'to be embarrassing', '5036', '' ],
[ '홀랑', 'completely', '5037', '' ],
[ '각설하다', 'to change the subject', '5038', '却說' ],
[ '직행', 'going straight, going direct', '5039', '直行' ],
[ '만년필', 'fountain pen', '5040', '萬年筆' ],
[ '순번', 'turn, go', '5041', '順番' ],
[ '살살', 'gently, softly', '5042', '' ],
[ '울적하다', 'to be depressed, gloomy, cheerless', '5043', '鬱寂' ],
[ '심리학', 'psychology', '5044', '心理學' ],
[ '탕수육', 'sweet and sour pork', '5045', '糖水肉' ],
[ '걸어다니다', 'to walk, walk around', '5046', '' ],
[ '가물가물하다', 'to be blurry, blurred', '5047', '' ],
[ '편도', 'one-way trip', '5048', '片道' ],
[ '멍청이', 'stupid person', '5049', '' ],
[ '본업', 'main occupation', '5050', '本業' ],
[
  '치사하다',
  'to be shameful, disgraceful, cheating, mean; to thank someone, express gratitude',
  '5051',
  '恥事, 致詞/致辭'
],
[ '제복', 'uniform', '5052', '制服' ],
[ '남해', 'the South Sea', '5053', '南海' ],
[ '정차하다', 'to stop, make a stop (about vehicles)', '5054', '停車' ],
[ '서방', 'husband, son-in-law; the West', '5055', '書房, 西方' ],
[ '예고편', 'preview, trailer', '5056', '豫告篇' ],
[ '사내아이', 'boy', '5057', '' ],
[ '절박하다', 'to be urgent, desperate', '5058', '切迫' ],
[ '레슨', 'lesson', '5059', '' ],
[ '야망', 'ambition, aspiration', '5060', '野望' ],
[ '웨딩드레스', 'wedding dress', '5061', '' ],
[ '정략', 'political scheme, move', '5062', '政略' ],
[ '실망시키다', 'to disappoint', '5063', '失望' ],
[ '기죽다', 'to be discouraged', '5064', '' ],
[ '외계인', 'alien', '5065', '外界人' ],
[ '미끄럼', 'slide, sliding', '5066', '' ],
[ '하마', 'hippo', '5067', '河馬' ],
[ '주민등록증', 'ID card', '5068', '住民登錄證' ],
[ '리모콘', 'remote control', '5069', '' ],
[ '달라고 하다', 'to request (ask) to give', '5070', '' ],
[ '사립학교', 'private school', '5071', '私立學校' ],
[ '반년', 'half a year', '5072', '半年' ],
[ '정각', 'the exact time (not sooner or later)', '5073', '正刻' ],
[ '폭풍우', 'rainstorm', '5074', '暴風雨' ],
[ '급행열차', 'express train', '5075', '急行列車' ],
[ '턱수염', 'beard', '5076', '-鬚髥' ],
[ '홍콩', 'Hong Kong', '5077', '' ],
[ '책꽂이', 'bookshelf', '5078', '冊-' ],
[ '에펠탑', 'Eiffel Tower', '5079', 'Eiffel塔' ],
[ '비꼬다', 'to be sarcastic', '5080', '' ],
[ '엄연하다', 'to be undeniable, clear', '5081', '儼然' ],
[ '관악산', 'Mt. Gwanak', '5082', '冠岳山' ],
[ '광화문', 'Gwang Hwa Gate', '5083', '光化門' ],
[ '전하', 'Your Highness', '5084', '殿下' ],
[ '게시물', 'post (on the internet etc.)', '5085', '揭示物' ],
[ '코멘트', 'comment', '5086', '' ],
[ '부침개', 'vegetable pancake', '5087', '' ],
[ '생물학', 'biology', '5088', '生物學' ],
[ '헤어스타일', 'hairstyle', '5089', '' ],
[ '기내식', 'in-flight meal', '5090', '機內食' ],
[ '노름하다', 'to gamble', '5091', '' ],
[ '사다리', 'ladder', '5092', '' ],
[ '반사하다', 'to reflect', '5093', '反射' ],
[ '수갑', 'handcuffs', '5094', '手匣' ],
[ '흉기', 'deadly weapon', '5095', '凶器' ],
[ '대기실', 'waiting room', '5096', '待機室' ],
[ '목수', 'carpenter', '5097', '木手' ],
[ '타이밍', 'timing', '5098', '' ],
[ '선택권', 'option, choice', '5099', '選擇權' ],
[ '진가', 'true value, real worth', '5100', '眞價' ],
[ '대만', 'Taiwan', '5101', '臺灣' ],
[ '영구히', 'eternally, permanently', '5102', '永久' ],
[ '가구점', 'furniture store', '5103', '家具店' ],
[ '수다떨다', 'to chitchat', '5104', '' ],
[ '이천', 'Icheon', '5105', '利川' ],
[ '결백하다', 'to be innocent', '5106', '潔白' ],
[ '마술', 'magic', '5107', '魔術' ],
[ '여배우', 'actress', '5108', '女俳優' ],
[ '가만두다', 'to tolerate, leave as is', '5109', '' ],
[ '돌잔치', "celebration of a baby's first birthday", '5110', '' ],
[ '반팔', 'short-sleeve shirts', '5111', '半-' ],
[ '엑스레이', 'X-ray', '5112', '' ],
[ '귀고리', 'earring', '5113', '' ],
[ '우체부', 'mailman', '5114', '郵遞夫' ],
[ '명물', 'specialty', '5115', '名物' ],
[ '앞줄', 'front row', '5116', '' ],
[ '사치품', 'luxury goods', '5117', '奢侈品' ],
[ '레이더', 'radar', '5118', '' ],
[ '쿨', 'cool', '5119', '' ],
[ '전해주다', 'to deliver, convey, report', '5120', '傳' ],
[ '횡재', 'windfall', '5121', '橫財' ],
[ '눅눅하다', 'to be damp, humid', '5122', '' ],
[ '관리자', 'manager, administrator', '5123', '管理者' ],
[ '흥정하다', 'to bargain, haggle over terms', '5124', '' ],
[ '신청곡', 'song request', '5125', '申請曲' ],
[ '애절하다', 'to be sad, touching, pathetic', '5126', '哀切' ],
[ '카세트', 'cassette', '5127', '' ],
[ '고정시키다', 'to fix, fasten, immobilize', '5128', '固定' ],
[ '유용하다', 'to be useful, helpful', '5129', '有用' ],
[ '별장', 'vacation home, cottage', '5130', '別莊' ],
[ '불결하다', 'to be dirty, unsanitary, filthy', '5131', '不潔' ],
[ '따끈따끈하다', 'to be hot, warm', '5132', '' ],
[ '수정과', 'dried persimmon punch', '5133', '水正果' ],
[ '맞선', 'marriage meeting, blind date', '5134', '' ],
[ '사진관', 'photo studio', '5135', '寫眞館' ],
[ '맏아들', 'the eldest son', '5136', '' ],
[ '동갑', 'the same age', '5137', '同甲' ],
[ '신간', 'new book', '5138', '新刊' ],
[ '자랑거리', 'something one is proud of', '5139', '' ],
[ '부수', 'number of copies; radical', '5140', '部數, 部首' ],
[ '봉사활동', 'community service activities', '5141', '奉仕活動' ],
[ '공상과학', 'science fiction', '5142', '空想科學' ],
[ '칠순', 'the age of seventy', '5143', '七旬' ],
[ '차표', 'ticket for train/bus', '5144', '車票' ],
[ '어귀', 'entrance', '5145', '' ],
[ '장녀', 'eldest daughter', '5146', '長女' ],
[ '주급', 'weekly pay [salary]', '5147', '週給' ],
[ '등신', 'fool, idiot', '5148', '等神' ],
[ '육감', 'sixth sense, gut feeling', '5149', '六感' ],
[ '뒷담화', "talking behind someone's back", '5150', '-談話' ],
[ '청구서', 'bill, invoice', '5151', '請求書' ],
[ '큰형', "boy's eldest brother", '5152', '-兄' ],
[ '문맥', 'context', '5153', '文脈' ],
[ '팔찌', 'bracelet', '5154', '' ],
[ '여의도', 'Yeouido', '5155', '汝矣島' ],
[ '앵무새', 'parrot', '5156', '鸚鵡-' ],
[ '생강', 'ginger', '5157', '生薑' ],
[ '째려보다', 'to look unkindly at', '5158', '' ],
[ '은행원', 'teller, bank clerk', '5159', '銀行員' ],
[ '독서실', 'reading room', '5160', '讀書室' ],
[ '식모', 'maid, domestic help', '5161', '食母' ],
[ '유로화', 'euro', '5162', 'Euro貨' ],
[ '들러리', 'best man, bridesmaid', '5163', '' ],
[ '간접화법', 'indirect speech', '5164', '間接話法' ],
[ '쌈밥', 'rice wrapped in greens', '5165', '' ],
[ '말끔', 'all, completely, thoroughly', '5166', '' ],
[ '입찰', 'bid, tender', '5167', '入札' ],
[ '손주', 'grandchildren', '5168', '孫-' ],
[ '펴놓다', 'to spread, lay out, keep open', '5169', '' ],
[ '유치원생', 'kindergartener', '5170', '幼稚園生' ],
[ '압구정', 'Apgujeong', '5171', '狎鷗亭' ],
[ '독학', 'self-study', '5172', '獨學' ],
[ '용납하다', 'to allow, permit, tolerate', '5173', '容納' ],
[ '색칠하다', 'to color, paint', '5174', '色漆' ],
[ '위엄', 'dignity', '5175', '威嚴' ],
[ '교환학생', 'exchange student', '5176', '交換學生' ],
[ '집주인', 'landlord, house owner', '5177', '-主人' ],
[ '기본형', 'basic form', '5178', '基本形' ],
[ '뺨맞다', 'to get slapped', '5179', '' ],
[ '중고품', 'used article, secondhand goods', '5180', '中古品' ],
[
  '전편',
  'the whole book (movie, series etc.); the original book (movie)',
  '5181',
  '全篇, 前篇'
],
[ '예비군', 'reservist, reserve forces', '5182', '豫備軍' ],
[ '유력하다', 'to be influential, leading, powerful', '5183', '有力' ],
[ '설화', 'tale, story', '5184', '說話' ],
[ '듬직하다', 'to be reliable, dependable', '5185', '' ],
[ '해바라기', 'sunflower', '5186', '' ],
[ '금품', 'money and valuables', '5187', '金品' ],
[ '잠실', 'Jamsil', '5188', '' ],
[ '힘세다', 'to be strong, powerful', '5189', '' ],
[ '음절', 'syllable', '5190', '音節' ],
[ '앞면', 'the front', '5191', '-面' ],
[ '북녘', 'north', '5192', '北-' ],
[ '물개', 'seal', '5193', '' ],
[ '장물', 'stolen goods', '5194', '臟物' ],
[ '앙큼하다', 'to be sly, cunning', '5195', '' ],
[ '연락선', 'ferry', '5196', '連絡船' ],
[
  '얼빠지다',
  "to be dazed, be dull-headed, lose one's senses",
  '5197',
  ''
],
[ '악성', 'bad, vicious, malignant', '5198', '惡性' ],
[
  '실연하다',
  'to be disappointed in love, heartbroken; to demonstrate',
  '5199',
  '失戀, 實演'
],
[ '영업시간', 'business hours', '5200', '營業時間' ],
[ '말대꾸하다', 'to talk back, retort', '5201', '' ],
[ '남방', 'shirt; south', '5202', '南方' ],
[ '사직서', 'letter of resignation', '5203', '辭職書' ],
[ '힘내다', 'to gather heart, cheer up', '5204', '' ],
[ '원기', 'energy, vigor', '5205', '元氣' ],
[ '전처', "one's former wife, ex-wife", '5206', '前妻' ],
[ '지은이', 'writer, author', '5207', '' ],
[ '일과표', 'daily schedule', '5208', '日課表' ],
[ '세종대왕', 'the Great King Sejong', '5209', '世宗大王' ],
[ '장식장', 'display cupboard, cabinet', '5210', '裝飾欌' ],
[ '새치기하다', 'to jump the queue, cut in line', '5211', '' ],
[ '고자질하다', 'to tattle, tell on', '5212', '告者-' ],
[ '삽', 'shovel', '5213', '' ],
[ '팔씨름하다', 'to arm wrestle', '5214', '' ],
[ '외딴', 'isolated, remote', '5215', '' ],
[ '다락', 'attic', '5216', '' ],
[ '김치전', 'kimchi pancake', '5217', '--煎' ],
[ '조선시대', 'the Joseon period', '5218', '朝鮮時代' ],
[ '불타다', 'to burn, be in flames', '5219', '' ],
[ '충전기', 'charger', '5220', '充電器' ],
[ '눈보라', 'snowstorm', '5221', '' ],
[ '사기꾼', 'con artist, swindler', '5222', '詐欺-' ],
[ '유쾌하다', 'to be cheerful, pleasant', '5223', '愉快' ],
[ '동점', 'tie, same score', '5224', '同點' ],
[ '명심하다', 'to keep in mind, remember', '5225', '銘心' ],
[ '재계', 'the financial world, business circles', '5226', '財界' ],
[ '말짱하다', 'to be impeccable, neat; to be perfectly okay', '5227', '' ],
[ '전경', 'panorama; the front view', '5228', '全景, 前景' ],
[
  '김새다',
  "to lose intereset, enthusiasm, be disappointed; to spoil one's fun",
  '5229',
  ''
],
[ '처자', "one's wife and children; a maiden", '5230', '妻子, 處子' ],
[ '다람쥐', 'squirrel', '5231', '' ],
[ '신입사원', 'newly hired employee', '5232', '新入社員' ],
[ '바람나다', 'to have a secret love affair', '5233', '' ],
[ '벽보', 'poster', '5234', '壁報' ],
[ '고사하다', 'to reject, decline; to wither (away)', '5235', '固辭, 枯死' ],
[ '합창단', 'choir, chorus', '5236', '合唱團' ],
[ '석양', 'sunset', '5237', '夕陽' ],
[ '잠수하다', 'to dive, go underwater', '5238', '潛水' ],
[ '수거하다', 'to collect, pick up', '5239', '收去' ],
[ '아웃', 'out', '5240', '' ],
[ '클래스', 'class', '5241', '' ],
[ '골탕먹다', 'to suffer a big loss, be cheated', '5242', '-湯' ],
[ '장난치다', 'to joke, kid, frisk, get funny (with)', '5243', '' ],
[ '한없다', 'to be unlimited, endless', '5244', '限' ],
[ '음주운전', 'drunk driving', '5245', '飮酒運轉' ],
[ '안정성', 'stability', '5246', '安定性' ],
[ '기자회견', 'press conference', '5247', '記者會見' ],
[ '자켓', 'jacket', '5248', '' ],
[ '문패', 'doorplate', '5249', '門牌' ],
[ '무지', 'ignorance, stupidity; very, extremely', '5250', '無知, 無智' ],
[ '군소리', 'unnecessary remark', '5251', '' ],
[ '밋밋하다', 'to be flat, plain, dull', '5252', '' ],
[ '주의사항', 'matters that require (special) attention', '5253', '注意事項' ],
[ '엿듣다', 'to eavesdrop, overhear', '5254', '' ],
[ '질색하다', 'to detest, loathe', '5255', '窒塞' ],
[ '건배', 'toast, cheers', '5256', '乾杯' ],
[ '데뷔하다', "to make one's debut", '5257', '' ],
[ '출렁거리다', 'to slop, splash, roll', '5258', '' ],
[ '겁쟁이', 'coward', '5259', '怯-' ],
[ '스킨십', 'touch (skin to skin, with affection)', '5260', '' ],
[ '크레파스', 'crayon', '5261', '' ],
[ '암소', 'cow', '5262', '' ],
[ '오싹하다', 'to shudder, feel a chill', '5263', '' ],
[ '원점', 'starting point', '5264', '原點' ],
[ '성형수술', 'plastic surgery', '5265', '成形手術' ],
[ '근시', 'nearsightedness', '5266', '近視' ],
[ '미끈미끈하다', 'to be all slippery, very smooth', '5267', '' ],
[ '주정하다', 'to act in a drunken and disorderly way', '5268', '酒酊' ],
[ '앞일', 'future', '5269', '' ],
[ '콘센트', 'socket, electrical outlet', '5270', '' ],
[ '랭킹', 'ranking', '5271', '' ],
[ '동침', 'sleeping together, sharing a bed', '5272', '同寢' ],
[ '싱크대', 'sink', '5273', 'sink臺' ],
[ '명동', 'Myeong Dong', '5274', '鳴動' ],
[ '숙어', 'idiom', '5275', '熟語' ],
[ '장화', 'boots', '5276', '長靴' ],
[ '마룻바닥', 'the floor', '5277', '' ],
[ '고동색', 'reddish brown', '5278', '古銅色' ],
[ '여신', 'goddess', '5279', '女神' ],
[ '멋쟁이', 'dandy, sharp dresser', '5280', '' ],
[ '천벌', 'divine punishment', '5281', '天罰' ],
[ '착수하다', 'to start, launch', '5282', '着手' ],
[ '극찬', 'high praise, compliment', '5283', '極讚' ],
[ '파혼하다', "to break off one's engagement", '5284', '破婚' ],
[ '온기', 'warmth', '5285', '溫氣' ],
[ '시제', 'tense (grammatical)', '5286', '時制' ],
[ '액션', 'action', '5287', '' ],
[ '욕조', 'bathtub', '5288', '浴槽' ],
[ '계산서', 'check, bill', '5289', '計算書' ],
[ '종영', 'end (of a show or movie)', '5290', '終映' ],
[ '피신하다', 'to escape, flee, take shelter', '5291', '避身' ],
[ '금실', 'marital chemistry (harmony)', '5292', '琴瑟' ],
[ '어림', 'rough estimate', '5293', '' ],
[ '죄목', 'charge, name of a crime', '5294', '罪目' ],
[ '경청하다', 'to listen attentively', '5295', '傾聽' ],
[ '계획표', 'written schedule, plans', '5296', '計劃表' ],
[ '등불', 'lamp, lamplight', '5297', '燈-' ],
[ '견적', 'estimate (for)', '5298', '見積' ],
[ '미화하다', 'to glorify, glamorize, romanticize', '5299', '美化' ],
[ '화랑', 'art gallery', '5300', '畵廊' ],
[ '겸비하다', 'to have both, combine', '5301', '兼備' ],
[ '노약자석', 'handicapped seating', '5302', '老弱者席' ],
[ '껄끄럽다', 'to be uncomfortable, uneasy, strained', '5303', '' ],
[ '경쟁사', 'competitor, rival company', '5304', '競爭社' ],
[ '웅변', 'eloquence, oratory', '5305', '雄辯' ],
[ '어림없다', 'to be far from, be beyond', '5306', '' ],
[ '납기일', 'pay day, date for payment', '5307', '納期日' ],
[ '울보', 'crybaby', '5308', '' ],
[ '스페인', 'Spain', '5309', '' ],
[ '쟁반', 'tray', '5310', '錚盤' ],
[ '옛일', 'things of the past', '5311', '' ],
[ '일기예보', 'weather forecast', '5312', '日氣豫報' ],
[ '꼬시다', 'to chase (women), pick up (girls), hit on', '5313', '' ],
[ '가위바위보', 'rock-paper-scissors', '5314', '-褓' ],
[ '딸꾹질하다', 'to have hiccups', '5315', '' ],
[ '유부남', 'married man', '5316', '有婦男' ],
[ '악명', 'evil reputation, notoriety', '5317', '惡名' ],
[ '원한', 'grudge', '5318', '怨恨' ],
[ '적중하다', 'to hit the mark (target), come true', '5319', '的中' ],
[ '재방송', 'rerun, repeat', '5320', '再放送' ],
[ '멀뚱멀뚱', 'blankly, vacantly', '5321', '' ],
[ '소매치기', 'pickpocket', '5322', '' ],
[ '배신자', 'betrayer, traitor', '5323', '背信者' ],
[ '육개장', 'spicy beef soup', '5324', '肉-醬' ],
[ '수레', 'wagon, cart', '5325', '' ],
[ '밀치다', 'to push, shove', '5326', '' ],
[ '핫도그', 'hotdog', '5327', '' ],
[ '체결하다', 'to conclude (a contract), to sign', '5328', '締結' ],
[ '분리수거하다', 'to separate (trash)', '5329', '分離收去' ],
[ '믿음직하다', 'to be reliable, dependable', '5330', '' ],
[ '빈방', 'vacant room', '5331', '-房' ],
[ '완강하다', 'to be stubborn, insistent', '5332', '頑强' ],
[ '멕시코', 'Mexico', '5333', '' ],
[ '처남', "brother of one's wife", '5334', '妻男' ],
[ '종반', 'the last phase (stage)', '5335', '終盤' ],
[ '아침저녁', 'mornings and evenings', '5336', '' ],
[ '추리소설', 'mystery novel', '5337', '推理小說' ],
[ '면세점', 'duty-free shop', '5338', '免稅店' ],
[ '아랍', 'Arab', '5339', '' ],
[ '로션', 'lotion', '5340', '' ],
[ '빚지다', 'to get into debt', '5341', '' ],
[ '면전', "to one's face", '5342', '面前' ],
[ '브라질', 'Brazil', '5343', '' ],
[ '소년원', 'youth detention center', '5344', '少年院' ],
[ '베이징', 'Beijing', '5345', '' ],
[ '조조', 'early morning', '5346', '早朝' ],
[ '십대', 'teenage, teenager', '5347', '十代' ],
[ '도로변', 'roadside', '5348', '道路邊' ],
[ '생시', 'being awake', '5349', '生時' ],
[ '비축하다', 'to reserve, stock up (on)', '5350', '備蓄' ],
[ '연보라', 'light purple', '5351', '軟-' ],
[ '새침하다', 'to be coy, prim', '5352', '' ],
[ '민속촌', 'Folk village', '5353', '民俗村' ],
[ '물비누', 'liquid soap', '5354', '' ],
[ '출소하다', 'to be released from prison', '5355', '出所' ],
[ '괴짜', 'odd (eccentric) person', '5356', '怪-' ],
[ '방대하다', 'to be huge, vast, massive', '5357', '厖大·尨大' ],
[ '사직하다', 'to resign, step down', '5358', '辭職' ],
[ '필독', 'required reading', '5359', '必讀' ],
[ '찬밥', 'cold boiled rice', '5360', '' ],
[ '걷히다', 'to clear up, lift', '5361', '' ],
[
  '선적',
  'shipment, shipping; the nationality of a ship',
  '5362',
  '船積, 船籍'
],
[ '시동', 'starting (of a machine)', '5363', '始動' ],
[ '세끼', 'three meals (a day)', '5364', '' ],
[ '삐치다', 'to sulk', '5365', '' ],
[ '우애', 'brotherly love', '5366', '友愛' ],
[ '몽골', 'Mongolia', '5367', '' ],
[ '난민', 'refugee', '5368', '難民' ],
[ '어학연수', 'language study abroad', '5369', '語學硏修' ],
[ '북경', 'Beijing', '5370', '北京' ],
[ '금붕어', 'goldfish', '5371', '金-' ],
[ '인증', 'certification, confirmation', '5372', '認證' ],
[ '간직하다', 'to cherish, treasure, keep', '5373', '' ],
[ '유령', 'ghost', '5374', '幽靈' ],
[ '빈대떡', 'green-bean pancake', '5375', '' ],
[ '친부모', "one's birth parents", '5376', '親父母' ],
[ '주먹밥', 'rice balls', '5377', '' ],
[ '열애', 'passionate love', '5378', '熱愛' ],
[ '소속사', 'agency, management company', '5379', '所屬' ],
[ '가두다', 'to shut in, lock up', '5380', '' ],
[ '목청껏', "at the top of one's voice", '5381', '' ],
[ '떠가다', 'to float, drift', '5382', '' ],
[ '서리', 'frost', '5383', '' ],
[ '유부녀', 'married woman', '5384', '有夫女' ],
[ '묘', 'grave, tomb', '5385', '墓' ],
[ '과시하다', 'to show off, parade', '5386', '誇示' ],
[ '총동원하다', 'to mobilize all the resources', '5387', '總動員' ],
[ '뜨개질', 'knitting', '5388', '' ],
[ '야식', 'late-night snack', '5389', '夜食' ],
[ '사돈', 'relatives by marriage, in-laws', '5390', '査頓' ],
[ '총점', 'total score', '5391', '總點' ],
[ '관리실', 'administrative office', '5392', '管理室' ],
[ '경호원', 'bodyguard, escort', '5393', '警護員' ],
[ '집집마다', 'every house', '5394', '' ],
[ '완결', 'completion, conclusion', '5395', '完結' ],
[ '애독자', '(devoted) reader', '5396', '愛讀者' ],
[ '헛소리', 'nonsense, silly talk', '5397', '' ],
[ '액운', 'bad luck, misfortune', '5398', '厄運' ],
[ '과수원', 'orchard, fruit garden', '5399', '果樹園' ],
[ '동구', 'entrance to a village', '5400', '洞口' ],
[ '전차', 'tram; tank', '5401', '電車, 戰車' ],
[ '검사관', 'inspector, examiner', '5402', '檢査官' ],
[ '선곡', 'selection of music', '5403', '選曲' ],
[ '학제', 'school system', '5404', '學制' ],
[ '연차', 'annual', '5405', '年次' ],
[ '개학하다', 'for school to start (after vacations)', '5406', '開學' ],
[
  '수능',
  'college entrance exams (short from 수학능력시험)',
  '5407',
  '修學能力試驗'
],
[ '문맹', 'illiteracy', '5408', '文盲' ],
[ '오보', 'false (incorrect) report', '5409', '誤報' ],
[ '상무', 'director', '5410', '常務' ],
[ '오그라들다', 'to shrink, shrivel', '5411', '' ],
[ '일꾼', 'worker', '5412', '' ],
[ '제왕', 'king, emperor', '5413', '帝王' ],
[ '명찰', 'name tag', '5414', '名札' ],
[ '변태', 'pervert', '5415', '變態' ],
[ '계략', 'scheme, trick, plot', '5416', '計略' ],
[ '봉변', 'humiliation, mishap', '5417', '逢變' ],
[ '무의식적', 'unconscious, involuntary', '5418', '無意識的' ],
[ '상큼하다', 'to be fresh, refreshing', '5419', '' ],
[ '명곡', 'classic, musical masterpiece', '5420', '名曲' ],
[ '물어내다', 'to pay for, compensate', '5421', '' ],
[ '동아줄', 'rope', '5422', '' ],
[ '수하물', 'luggage', '5423', '手荷物' ],
[ '중독성', 'addictiveness; toxicity', '5424', '中毒性' ],
[ '마귀할멈', 'witch', '5425', '魔鬼-' ],
[ '또박또박', 'clearly, neatly', '5426', '' ],
[ '댓글', 'comment', '5427', '' ],
[ '주군', "one's master", '5428', '主君' ],
[ '일거리', 'work, things to do, task', '5429', '' ],
[ '수락', 'acceptance, consent', '5430', '受諾' ],
[ '뒤져보다', 'to look for, hunt after, rummage', '5431', '' ],
[ '비틀다', 'to twist', '5432', '' ],
[ '청와대', 'the Blue House, Korean presidential mansion', '5433', '靑瓦臺' ],
[ '화염', 'flame, blaze', '5434', '火焰' ],
[ '노년기', 'old age', '5435', '老年期' ],
[ '분장하다', 'to put on make up, dress as', '5436', '扮裝' ],
[ '어구', 'phrase; fishing gear', '5437', '語句, 漁具' ],
[ '기러기', 'wild goose', '5438', '' ],
[ '램프', 'lamp', '5439', '' ],
[ '투수', 'pitcher', '5440', '投手' ],
[ '도토리', 'acorn', '5441', '' ],
[ '외가', "one's mother's parents' home", '5442', '外家' ],
[ '신작', 'new (piece of) work', '5443', '新作' ],
[ '단풍나무', 'maple', '5444', '丹楓-' ],
[ '본체만체하다', 'to ignore, pretend not to see', '5445', '' ],
[ '파마하다', 'to get a perm', '5446', '' ],
[ '늪', 'swamp, marsh, bog', '5447', '' ],
[ '장님', 'blind person', '5448', '' ],
[ '펄럭펄럭', 'with a flutter, flapping', '5449', '' ],
[ '해수', 'seawater', '5450', '海水' ],
[ '마주서다', 'to stand face to face', '5451', '' ],
[ '수다쟁이', 'talkative person', '5452', '' ],
[ '금반지', 'gold ring', '5453', '金半指' ],
[ '주중', 'weekdays', '5454', '週中' ],
[ '무디다', 'to be blunt, dull', '5455', '' ],
[ '칼날', 'blade of a knife', '5456', '' ],
[ '타자', 'typing; batter, hitter', '5457', '打字, 打者' ],
[ '지팡이', 'walking stick, cane, wand', '5458', '' ],
[ '만담', 'gag, comic talk', '5459', '漫談' ],
[ '물집', 'blister', '5460', '' ],
[ '장가가다', 'to get married (for a man)', '5461', '' ],
[ '절레절레', "shaking one's head", '5462', '' ],
[ '하이웨이', 'highway', '5463', '' ],
[ '놀이동산', 'amusement park', '5464', '' ],
[ '공공기관', 'public institution', '5465', '公共機關' ],
[ '탐내다', 'to want, desire, covet', '5466', '貪-' ],
[ '변소', 'bathroom, toilet', '5467', '便所' ],
[ '조교', 'teaching assistant', '5468', '助敎' ],
[ '룸메이트', 'roommate', '5469', '' ],
[ '학생회관', 'student center', '5470', '學生會館' ],
[ '당초에', 'initially, originally', '5471', '當初-' ],
[ '주걱', 'spatula, paddle', '5472', '' ],
[ '전봇대', 'telephone pole', '5473', '電報-' ],
[ '머리핀', 'hairpin', '5474', '' ],
[ '정찰제', 'fixed-price system', '5475', '正札制' ],
[ '친목', 'friendship', '5476', '親睦' ],
[ '전골', 'beef with vegetables cooked in a casserole', '5477', '' ],
[ '잔혹하다', 'to be cruel, heartless, ruthless', '5478', '殘酷' ],
[ '무역항', 'trade port', '5479', '貿易港' ],
[ '수족관', 'aquarium', '5480', '水族館' ],
[ '식물원', 'botanical garden', '5481', '植物園' ],
[ '새끼손가락', 'little finger', '5482', '' ],
[ '국자', 'ladle', '5483', '' ],
[ '사당', 'shrine', '5484', '祠堂' ],
[ '의뢰인', 'client', '5485', '依賴人' ],
[ '짠돌이', 'cheap (stingy, frugal) person', '5486', '' ],
[ '고사', 'examination', '5487', '考査' ],
[ '가불하다', 'to pay in advance', '5488', '假拂' ],
[ '문제집', 'workbook', '5489', '問題集' ],
[ '원상복구', 'restoration to the original state', '5490', '原狀復舊' ],
[ '하키', 'hockey', '5491', '' ],
[ '법학', 'law', '5492', '法學' ],
[ '새어머니', 'stepmother', '5493', '' ],
[ '채권자', 'creditor', '5494', '債權者' ],
[ '채무', 'debt', '5495', '債務' ],
[ '불륜', 'affair, adultery', '5496', '不倫' ],
[ '우두머리', 'leader, chief, boss', '5497', '' ],
[ '탈진하다', 'to be exhausted, drained', '5498', '脫盡' ],
[ '눈부처', "one's image reflected in a person's eye", '5499', '' ],
[ '덫', 'trap, snare', '5500', '' ],
[ '청첩장', 'wedding invitation', '5501', '請牒狀' ],
[ '독수리', 'eagle', '5502', '禿-' ],
[ '호적', 'family register', '5503', '戶籍' ],
[ '차편', 'transportation', '5504', '車便' ],
[ '꽃바구니', 'flower basket', '5505', '' ],
[ '요술', 'magic, sorcery', '5506', '妖術' ],
[ '빚내다', 'to borrow money', '5507', '' ],
[ '층지다', 'to have layers', '5508', '層-' ],
[ '첩', 'concubine; book, album', '5509', '妾, 帖' ],
[ '운수', 'fortune, luck; transportation', '5510', '運數, 運輸' ],
[ '등기우편', 'registered mail', '5511', '登記郵便' ],
[ '청명하다', 'to be fine, clear', '5512', '淸明' ],
[ '전보', 'telegram', '5513', '電報' ],
[ '간주하다', 'to regard, consider', '5514', '看做' ],
[ '숙지하다', 'to know inside out, be fully aware', '5515', '熟知' ],
[ '열연', 'enthusiastic performance', '5516', '熱演' ],
[ '유괴하다', 'to kidnap, abduct', '5517', '誘拐' ],
[ '애태우다', 'to worry, be anxious', '5518', '' ],
[ '짝퉁', 'fake, imitation', '5519', '' ],
[ '술래잡기', 'tag, hide-and-seek', '5520', '' ],
[ '상급생', 'senior (in higher class than you)', '5521', '上級生' ],
[ '수식하다', 'to modify', '5522', '修飾' ],
[ '또라이', 'weirdo', '5523', '' ],
[ '주간', 'week; daytime', '5524', '週間, 晝間' ],
[
  '괘씸하다',
  'to be disgraceful, outrageous; to be disgusted (with, that)',
  '5525',
  ''
],
[ '야만적', 'uncivilized, savage', '5526', '野蠻的' ],
[ '악담하다', 'to verbally abuse, speak ill of', '5527', '惡談' ],
[ '오진', 'wrong diagnosis', '5528', '誤診' ],
[ '택일', 'choosing one; choosing the day', '5529', '擇一, 擇日' ],
[ '전채', 'appetizer', '5530', '前菜' ],
[ '이태원', 'Itaewon', '5531', '' ],
[ '대학원생', 'graduate student', '5532', '大學院生' ],
[ '식혜', 'sweet rice drink', '5533', '食醯' ],
[ '춘천', 'Chuncheon', '5534', '春川' ],
[ '왕관', 'crown', '5535', '王冠' ],
[ '차세대', 'next generation', '5536', '次世代' ],
[ '이목', 'attention', '5537', '耳目' ],
[ '심기', 'mood, temper', '5538', '心氣' ],
[ '전매특허', 'patent', '5539', '專賣特許' ],
[ '악행', 'evil deed, wrongdoing', '5540', '惡行' ],
[ '오슬오슬', 'shivering', '5541', '' ],
[ '퐁당', 'with a splash', '5542', '' ],
[ '긴하다', 'to be important, urgent, essential', '5543', '緊' ],
[ '삽입하다', 'to insert', '5544', '揷入' ],
[ '괴담', 'ghost story', '5545', '怪談' ],
[ '인수하다', 'to take over, accept', '5546', '引受' ],
[ '구좌', '(bank) account', '5547', '口座' ],
[ '배반', 'betrayal', '5548', '背反·背叛' ],
[ '단도', 'dagger, short sword', '5549', '短刀' ],
[ '사또', 'district magistrate', '5550', '使道' ],
[ '반전', 'reversal, turnaround; antiwar', '5551', '反轉, 反戰' ],
[ '당번', 'duty', '5552', '當番' ],
[ '민생', "public welfare, people's livelihood", '5553', '民生' ],
[ '새장', 'bird cage', '5554', '-欌' ],
[ '게재하다', 'to print, publish', '5555', '揭載' ],
[ '육박하다', 'to approach, draw near, close in', '5556', '肉迫' ],
[ '상세', 'details, particulars', '5557', '詳細' ],
[ '유배', 'banishment, exile', '5558', '流配' ],
[ '선로', 'train tracks, railroad', '5559', '線路' ],
[ '금발', 'golden hair', '5560', '金髮' ],
[ '보따리', 'bundle', '5561', '褓-' ],
[ '배즙', 'pear juice', '5562', '' ],
[ '계집아이', 'girl', '5563', '' ],
[ '뜬금없이', 'all of a sudden', '5564', '' ],
[ '샴페인', 'champagne', '5565', '' ],
[ '추리하다', 'to deduce, reason', '5566', '推理' ],
[ '정성껏', "with one's utmost sincerity or whole heart", '5567', '精誠-' ],
[
  '돌잡이',
  'doljabi (when a baby picks out one object from several)',
  '5568',
  ''
],
[ '청진기', 'stethoscope', '5569', '聽診器' ],
[ '벼룩시장', 'flea market', '5570', '--市場' ],
[ '작업실', 'workroom', '5571', '作業室' ],
[ '귀금속', 'jewelry, precious metal', '5572', '貴金屬' ],
[ '굴욕', 'humiliation, indignity', '5573', '屈辱' ],
[ '건반', 'keyboard, key', '5574', '鍵盤' ],
[ '군것질', 'eating between meals', '5575', '' ],
[ '클릭하다', 'to click', '5576', '' ],
[ '사물함', 'locker', '5577', '私物函' ],
[ '가업', 'family business', '5578', '家業' ],
[ '요리책', 'cookbook', '5579', '料理冊' ],
[ '협찬하다', 'to sponsor, support', '5580', '協贊' ],
[ '도착지', 'destination', '5581', '到着地' ],
[ '무능', 'incompetence', '5582', '無能' ],
[ '부엌용품', 'kitchen appliances', '5583', '-用品' ],
[ '까불다', 'to act up, behave badly or silly', '5584', '' ],
[ '맛집', 'restaurant famous for delicious food', '5585', '' ],
[ '코뿔소', 'rhinoceros', '5586', '' ],
[ '건달', 'good-for-nothing', '5587', '乾達' ],
[ '버겁다', "to be beyond one's capability or resources", '5588', '' ],
[ '달인', 'expert, master', '5589', '達人' ],
[ '잠복', 'stakeout, hiding and waiting', '5590', '潛伏' ],
[ '모함', 'set-up, slander, false accusations', '5591', '謀陷' ],
[ '벌칙', 'penalty', '5592', '罰則' ],
[ '완수하다', 'to carry out, accomplish', '5593', '完遂' ],
[ '수포', 'bubble; blister', '5594', '水泡, 水疱' ],
[ '돋보기', 'magnifying glass, reading glasses', '5595', '' ],
[ '숨바꼭질', 'hide-and-seek', '5596', '' ],
[ '완패', 'complete defeat, crushing defeat', '5597', '完敗' ],
[ '꿀꺽', 'gulp', '5598', '' ],
[ '대보다', 'to compare', '5599', '' ],
[ '현수막', 'banner', '5600', '懸垂幕' ],
[ '별말', 'unexpected, absurd, or extraordinary remark', '5601', '別―' ],
[ '벚꽃놀이', 'picnic under the cherry blossoms', '5602', '' ],
[ '항공편', 'flight', '5603', '航空便' ],
[ '만석', 'full, fully occupied', '5604', '' ],
[ '동맹', 'alliance, partnership', '5605', '同盟' ],
[ '대기자', 'waiting person', '5606', '大記者' ],
[ '지지리', 'extremely, awfully, terribly', '5607', '' ],
[ '돗자리', 'mat', '5608', '' ],
[ '처제', "younger sister of one's wife", '5609', '妻弟' ],
[ '품절되다', 'to be out of stock', '5610', '品切' ],
[ '아군', 'our forces, ally', '5611', '我軍' ],
[ '지령', 'order, instruction, directive', '5612', '指令' ],
[ '갈매기', 'seagull', '5613', '' ],
[ '이해심', 'understanding, empathy', '5614', '理解心' ],
[ '무전기', 'walkie-talkie, radio', '5615', '無電機' ],
[ '단원', 'unit; member', '5616', '單元, 團員' ],
[ '덤벙거리다', 'to be careless', '5617', '' ],
[ '주꾸미', 'short arm octopus', '5618', '' ],
[ '둔감하다', 'to be insensitive, obtuse, impervious', '5619', '鈍感' ],
[ '포털', 'portal', '5620', '' ],
[ '뚝', 'with a snap; suddenly', '5621', '' ],
[ '돌풍', 'gust of wind', '5622', '突風' ],
[ '멜론', 'melon', '5623', '' ],
[ '정비사', 'mechanic', '5624', '整備士' ],
[ '밤사이', 'nighttime', '5625', '' ],
[ '유한하다', 'to be finite, limited', '5626', '有限' ],
[ '온돌방', 'room with the Korean floor heating system', '5627', '溫突房' ],
[
  '아이돌',
  'K-pop band member (young, good-looking, famous)',
  '5628',
  ''
],
[ '창립', 'establishment, foundation', '5629', '創立' ],
[ '어버이', 'parents', '5630', '' ],
[ '개교하다', 'to open a school', '5631', '開校' ],
[ '카네이션', 'carnation', '5632', '' ],
[ '축의금', 'monetary gift', '5633', '祝儀金' ],
[ '화환', 'wreath', '5634', '花環' ],
[ '게양하다', 'to hoist, raise', '5635', '揭揚' ],
[ '복어', 'blowfish', '5636', '―漁' ],
[ '이야깃거리', 'subject, topic', '5637', '' ],
[ '유모차', 'stroller', '5638', '乳母車' ],
[ '프러포즈', 'marriage proposal', '5639', '' ],
[ '돌고래', 'dolphin', '5640', '' ],
[
  '반찬거리',
  'groceries, materials for making side dishes',
  '5641',
  '飯饌-'
],
[ '빨대', 'straw', '5642', '' ],
[ '아우', 'younger brother, younger sister', '5643', '' ],
[ '시연', 'demonstration, rehearsal', '5644', '試演' ],
[ '항복하다', 'to surrender', '5645', '降伏' ],
[ '투혼', 'fighting spirit', '5646', '鬪魂' ],
[ '버전', 'version', '5647', '' ],
[ '귀띔', 'hint, tip', '5648', '' ],
[ '계산원', 'cashier', '5649', '計算員' ],
[ '콤비', 'combination', '5650', '' ],
[ '욕먹다', 'to be blamed, be criticized, be reproached', '5651', '辱-' ],
[ '자택', "one's own house (home)", '5652', '自宅' ],
[ '텐트', 'tent', '5653', '' ],
[ '어묵', 'fish cake', '5654', '魚-' ],
[ '본색', "one's true colors", '5655', '本色' ],
[ '신청자', 'applicant', '5656', '申請者' ],
[ '음원', 'sound source, online music', '5657', '音源' ],
[
  '방과',
  'dismissal of class (when school is over for the day)',
  '5658',
  '放課'
],
[ '면목', 'face, honor', '5659', '面目' ],
[ '빗자루', 'broom', '5660', '' ],
[ '대걸레', 'mop', '5661', '' ],
[ '딱밤', 'finger flick', '5662', '' ],
[ '반숙하다', 'to be half-cooked, half-ripe', '5663', '半熟' ],
[ '드럼', 'drums', '5664', '' ],
[ '종이접기', 'origami', '5665', '' ],
[ '침술', 'acupuncture', '5666', '鍼術' ],
[ '메기', 'catfish', '5667', '' ],
[ '노비', 'slave, servant', '5668', '奴婢' ],
[ '망고', 'mango', '5669', '' ],
[ '마술사', 'magician', '5670', '魔術師' ],
[ '무용수', 'showgirl', '5671', '舞踊手' ],
[ '자물쇠', 'lock', '5672', '' ],
[ '갯벌', 'mud flat', '5673', '' ],
[ '기웃기웃', 'peeping, snooping', '5674', '' ],
[ '애원하다', 'to beg, plead, entreat', '5675', '哀願' ],
[ '부적절하다', 'to be inappropriate', '5676', '不適切' ],
[ '급료', 'salary', '5677', '給料' ],
[ '할리우드', 'Hollywood', '5678', '' ],
[ '관사', 'article (grammatical); official residence', '5679', '冠詞, 官舍' ],
[ '계약직', 'contract worker', '5680', '契約職' ],
[ '악어', 'crocodile, alligator', '5681', '鰐魚' ],
[ '데굴데굴', 'rolling (continuously)', '5682', '' ],
[ '포즈', 'pose', '5683', '' ],
[ '사채', 'private loan', '5684', '私債' ],
[ '더듬이', 'feelers, antenna', '5685', '' ],
[ '스케치북', 'sketchbook', '5686', '' ],
[ '분가하다', "to move out of one's parents' house", '5687', '分家' ],
[ '총명하다', 'to be intelligent, bright', '5688', '聰明' ],
[ '펄쩍펄쩍', 'jumping up and down', '5689', '' ],
[ '미련하다', 'to be stupid, foolish', '5690', '' ],
[ '파스', 'pain relief patch', '5691', '' ],
[ '장부', 'ledger, books, account', '5692', '帳簿' ],
[ '먹방', 'eating show', '5693', '' ],
[ '교과목', 'subject, course', '5694', '敎科目' ],
[ '회계사', 'accountant', '5695', '會計士' ],
[ '티켓', 'ticket', '5696', '' ],
[ '내가다', 'to take out, take away, remove', '5697', '' ],
[ '재촉하다', 'to hurry, rush, push', '5698', '' ],
[ '발매', 'sale', '5699', '發賣' ],
[ '거북', 'turtle', '5700', '' ],
[ '운동회', 'sports day', '5701', '運動會' ],
[ '회전목마', 'carousel', '5702', '回轉木馬' ],
[ '낙지', 'small octopus', '5703', '' ],
[ '화단', 'flower bed; the art world', '5704', '花壇, 畵壇' ],
[ '상어', 'shark', '5705', '' ],
[ '업히다', "to be carried on someone's back", '5706', '' ],
[ '벗겨지다', 'to come off, fall off, get removed', '5707', '' ],
[ '매형', "one's elder sister's husband", '5708', '妹兄' ],
[ '동의어', 'synonym', '5709', '同義語' ],
[ '하이힐', 'high-heeled dress shoes', '5710', '' ],
[ '태연하다', 'to be calm, composed, nonchalant', '5711', '泰然' ],
[ '무능력하다', 'to be incompetent, incapable', '5712', '無能力' ],
[ '생선회', 'sashimi, sliced raw fish', '5713', '生鮮膾' ],
[ '보트', 'boat', '5714', '' ],
[ '명세서', '(detailed) statement', '5715', '明細書' ],
[ '차고', 'garage', '5716', '車庫' ],
[ '단층집', 'single-story house', '5717', '單層-' ],
[ '망상', 'delusion', '5718', '妄想' ],
[ '호출기', 'pager', '5719', '呼出機' ],
[ '올케', "the wife of a girl's brother", '5720', '' ],
[ '마녀', 'witch', '5721', '' ],
[ '봉급', 'salary, wages', '5722', '俸給' ],
[ '말랑말랑하다', 'to be soft, tender', '5723', '' ],
[ '등산화', 'hiking boots', '5724', '登山靴' ],
[ '저금통', 'coin bank', '5725', '貯金筒' ],
[ '색종이', 'colored paper', '5726', '色―' ],
[ '일기장', 'diary', '5727', '日記帳' ],
[ '털실', 'woolen yarn', '5728', '' ],
[ '세뱃돈', "New Year's cash gifts", '5729', '' ],
[ '주저하다', 'to hesitate', '5730', '躊躇' ],
[ '배식하다', 'to distribute [hand out] food', '5731', '配食' ],
[ '쳐들다', 'to lift up, raise', '5732', '' ],
[ '각서', 'memorandum, note of promise', '5733', '覺書' ],
[ '포위하다', 'to surround, encircle', '5734', '包圍' ],
[ '은신처', 'hideout, hiding place', '5735', '隱身處' ],
[ '숙취', 'hangover', '5736', '宿醉' ],
[ '금연석', 'non-smoking section', '5737', '禁煙席' ],
[ '흡연석', 'smoking section', '5738', '吸煙席' ],
[ '위선', 'hypocrisy', '5739', '僞善' ],
[ '아담하다', 'to be neat, tidy; to be petite', '5740', '雅淡·雅澹' ],
[ '소생하다', 'to revive, recover', '5741', '蘇生' ],
[ '강연회', 'lecture', '5742', '講演會' ],
[ '파인애플', 'pineapple', '5743', '' ],
[ '찻집', 'teahouse', '5744', '茶-' ],
[ '이적하다', 'to transfer, move', '5745', '移籍' ],
[ '유치장', 'jail, lockup, holding cell', '5746', '留置場' ],
[ '상투적', 'conventional, commonplace', '5747', '常套的' ],
[ '오답', 'wrong answer, incorrect answer', '5748', '誤答' ],
[ '속초', 'Sokcho', '5749', '束草' ],
[ '윙크하다', 'to wink', '5750', '' ],
[ '핸드볼', 'handball', '5751', '' ],
[ '악필', 'poor handwriting', '5752', '惡筆' ],
[ '주사위', 'dice', '5753', '' ],
[ '물병', 'water bottle, pitcher', '5754', '-甁' ],
[ '앞치마', 'apron', '5755', '' ],
[ '바비큐', 'barbecue', '5756', '' ],
[ '솜사탕', 'cotton candy', '5757', '-沙糖' ],
[ '패스하다', 'to pass', '5758', '' ],
[ '청순하다', 'to be innocent, pure', '5759', '淸純' ],
[ '골인하다', 'to score a goal; to reach the finish line', '5760', '' ],
[ '난동', 'disturbance; abnormally warm winter', '5761', '亂動, 暖冬' ],
[ '쿠폰', 'coupon', '5762', '' ],
[ '불국사', 'Bul Guk temple', '5763', '佛國寺' ],
[ '요란하다', 'to be noisy, be loud', '5764', '搖亂ㆍ擾亂' ],
[ '엔지니어', 'engineer', '5765', '' ],
[ '플랫폼', 'platform', '5766', '' ],
[ '샐러드', 'salad', '5767', '' ],
[ '헬멧', 'helmet', '5768', '' ],
[ '양치기', 'shepherd', '5769', '羊-' ],
[ '차례차례', 'one by one', '5770', '次例次例' ],
[ '정승', 'minister', '5771', '政丞' ],
[ '걸레질', 'mopping, wiping', '5772', '' ],
[ '시냇가', 'bank of a stream', '5773', '' ],
[ '아리랑', '"traditional Korean song ""Arirang"""', '5774', '' ],
[ '죄짓다', 'to commit a sin, do sinful things', '5775', '罪-' ],
[ '곱슬곱슬하다', 'to be curly', '5776', '' ],
[ '여행용', 'for travel', '5777', '旅行用' ],
[ '진주', 'pearl', '5778', '眞珠·珍珠' ],
[ '화살표', 'arrow', '5779', '--標' ],
[ '밀어내다', 'to push out, force out', '5780', '' ],
[ '날달걀', 'raw egg', '5781', '' ],
[ '큐브', "Rubik's cube", '5782', '' ],
[ '장구', 'Korean double-headed drum', '5783', '' ],
[ '미신', 'superstition', '5784', '迷信' ],
[ '메뚜기', 'grasshopper', '5785', '' ],
[ '인질', 'hostage', '5786', '人質' ],
[ '후텁지근하다', 'to be sultry, humid', '5787', '' ],
[ '을지로', 'Euljiro', '5788', '' ],
[ '전화비', 'telephone bill', '5789', '電話費' ],
[ '베이지색', 'beige', '5790', '' ],
[ '원목', 'hardwood', '5791', '原木' ],
[ '배달원', 'delivery man', '5792', '配達員' ],
[ '이상야릇하다', 'to be strange, funny, peculiar', '5793', '' ],
[ '재래시장', 'traditional market', '5794', '在來市場' ],
[ '수산시장', 'fish market', '5795', '水産市場' ],
[ '단무지', 'pickled radish', '5796', '' ],
[ '회초리', 'cane', '5797', '' ],
[ '당황스럽다', 'to be embarrassed, awkward, flustered', '5798', '唐慌' ],
[ '훈장', 'medal, badge; (traditional) teacher', '5799', '勳章, 訓長' ],
[ '도심지', 'downtown', '5800', '都心地' ],
[ '단둘', 'two persons (alone, in private)', '5801', '' ],
[ '저주', 'curse (magical)', '5802', '詛呪' ],
[ '숟갈', 'spoonful (of sugar or other ingredients)', '5803', '' ],
[ '주동자', 'leader', '5804', '主動者' ],
[ '충성', 'loyalty, devotion', '5805', '忠誠' ],
[ '토박이', 'natives', '5806', '土-' ],
[ '오냐오냐하다', 'to overindulge, allow [kids] everything', '5807', '' ],
[ '접선', 'secret meeting', '5808', '接線' ]
]
export default WORDS;