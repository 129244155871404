import './App.css';
import {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import CharacterResult from './characterResult';
import WORDS from './words.js';
import WordItem from './wordItem.js'
import HANJA from './hanja.js'
import firebase from "firebase";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer
} from "@react-firebase/auth";;


const firebaseConfig = {
  apiKey: "AIzaSyBWJakJVZKgk0A-3GRPMJjIBkOKuvuHiZU",
  authDomain: "korean-vocab-302216.firebaseapp.com",
  projectId: "korean-vocab-302216",
  storageBucket: "korean-vocab-302216.appspot.com",
  messagingSenderId: "916802061250",
  appId: "1:916802061250:web:55fe4890b978c87ae90c5b",
  measurementId: "G-CQ4WSJY2YV"
};


function App() {
  useEffect(() => {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'sksnpmyg',
    });
  }, []);
  return (
      <Router>
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <div className="App">
        <header className="App-header">
        Korean Vocab

        </header>
        <nav>
          <ul className="flex flex-row justify-center gap-3">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/explore">Explore</Link>
            </li>
          </ul>
        </nav>


        <div className='App-body text-center'>

            <FirebaseAuthConsumer>
              {({ isSignedIn }) => {
                if (isSignedIn === true) {
    return(
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/explore">
            <Explore />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
  );

                } else {
                  return <IfUnAuthed />
                }
              }}
          </FirebaseAuthConsumer>

        </div>
      </div>
    </FirebaseAuthProvider>
</Router>
 );
}

const Explore = () => {
  const updateWord = (w) => (console.log(w));
  const letters = {}
  WORDS.forEach((word) => {
    const firstChar = word[0][0];
    if(letters[firstChar]) {
      letters[firstChar].push(word)
    } else {
      letters[firstChar] = [word]
    }
  });
  const letterArray = Object.keys(letters).map((letter) => {
    const words = letters[letter]
    return {char: letter, words, count: words.length}
  } ).sort((a,b) => b.count - a.count);
  return (<ul>
   {
     letterArray.map((result) => {
         return(
          <li key={result.char}> {result.count}) <CharacterResult collapsed={true} startsOnly={true} callback={() => {}} character={result.char} data={{}} setWord={updateWord} updateCell={updateWord}/></li>
           )
       })

     //lessWords.map((word) =>  {return <WordItem word={word} setWord={updateWord} /> })
     }
  </ul>)

}

const IfUnAuthed = () => {
  return (
    <div>
      <h2>You're not signed in</h2>

      <button
        onClick={() => {
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          firebase.auth().signInWithPopup(googleAuthProvider);
        }}
      >
        Sign in with Google
      </button>
    </div>
  );
};

const Main = props => {
  const [word, setWord] = useState('');
  const [match, setMatch] = useState(undefined);
  const [db, setDb] = useState(null);
  const [dbVersion, setDbVersion] = useState(0);
  const [records, setRecords] = useState([]);
  const [results, setResults] = useState([]);
  const updateCell = (id, type, description) => {
    const doc = db.doc(id);
    doc.set({ type, description }, { merge: true });
  }

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    const firebaseDB = firebase.firestore();
    const store = firebaseDB.collection('syllables');
    setDb(store);
    store.get().then((querySnapshot) => {
      const newRecords = querySnapshot.docs.map((doc) => {
        return Object.assign({id: doc.id},doc.data());
      });
      setRecords(newRecords);

  });

  }, [dbVersion])

  const paste = () => {
    navigator.clipboard.readText().then( clipText => updateWord(clipText));
  }

  const updateWord = (value) => {
    setWord(value);
    if(!value) {
      return setResults([]);
    }
    const resultObject = value.split('').map((c, i) => { return {character: c, key: `${c}-${i}`}})
    resultObject.forEach((char) => {
      char.item = records.find((i) => i.syllable === char.character)
    })
    setMatch(WORDS.find((w) => w[0] === value))

    setResults(resultObject);
  }

  const hanjaList = (match && match[3]) ? match[3].split('').map((c) => {
    return HANJA.find(h => h[0] === c);
  }).filter((h) => h !== undefined) : [];
  return (
  <div>
    <div className="App-intro">Look up:</div>
    <div className="text-center text-lg">
      <input className="text-black text-lg m-1" value={word} onChange={(e) => updateWord(e.target.value)} type='text' />
      <button onClick={paste} className="App-button">paste</button>
      {word && match && (
        <>
          <h4 className='definition'>{match[1]} ({match[2]}) {match[3]}</h4>
              <ul className="resultbox">
                {hanjaList.map((l) => {
                  return (
                    <li key={l.join('-')}><h5 className="font-bold">{l.join(', ')}</h5>
                    <ul>
                      {WORDS.filter((w) => w[3].includes(l[0])).map(w => (<WordItem word={w} setWord={updateWord} />))}
                    </ul>
                    </li>
                    )
                  })}
              </ul>
        </>
      )}
      {word &&
        <h4><a className="App-link" href={`https://en.dict.naver.com/#/search?range=word&query=${word}`}>naver</a></h4>
      }
    </div>

    <ul>
      {results.map(result => (
        <li key={result.key}> <CharacterResult callback={() => {setDbVersion(dbVersion + 1)}} character={result.character} data={result.item || {}} setWord={updateWord} updateCell={updateCell}/></li>
      ))}
    </ul>

  </div>
  )

}

export default App;
