const HANJA = [
["甑","시루 증","증","boiler for steaming rice","pot (17)"],
["罍","술독 뢰","뢰","large earthenware wine jar (21)"],
["旬","열흘 순","순","두루할 순","고를 순","가득할 순","요사스런 별 순","ten-day period; period of time (6)"],
["宓","성 복","복","quiet","silent; in good health (8)"],
["旭","빛날 욱","욱","햇살 치밀 욱","교만할 욱","rising sun; brilliance; radiant (6)"],
["宕","골집 탕","탕","방탕할 탕","석수 탕","stone quarry; cave dwelling (8)"],
["覧","볼 람","람","look at","inspect; perceive (16)"],
["甓","벽돌 벽","벽","glazed tiles","bricks (18)"],
["兛","킬로그램 천","천","kilogram (10)"],
["罐","두레박 관","관","jar","jug","pitcher","pot (24)"],
["宗","마루 종","종","lineage","ancestry; ancestor","clan (8)"],
["燎","뜰에 세운 횃불 료","료","풀 태울 료","to burn","set afire; to illuminate; a signal lamp (16)"],
["甕","항아리 옹","옹","물장군 옹","earthen jar; jar for ashes (18)"],
["旰","해질 간","간","sunset","dusk; evening (7)"],
["悁","분할 연","연","근심할 연","irritable","nervous","impatient (10)"],
["兜","투구 두","두","pouch (11)"],
["親","사랑할 친","친","친할 친","몸소 친","일가 친","사돈 친","relatives","parents; intimate (16)"],
["療","병 나을 료","료","병 고칠 료","be healed","cured","recover (17)"],
["官","벼슬 관","관","official","public servant (8)"],
["旱","가물 한","한","drought; dry; dry land (7)"],
["宙","집 주","주","time as concept; infinite time (8)"],
["蓼","병 나을 료","료","smartweed","polygonum (17)"],
["悃","정성 곤","곤","sincere","genuine","honest","loyal (10)"],
["悄","근심할 초","초","silent","quiet","still; anxious (10)"],
["定","정할 정","정","편안할 정","고요할 정","decide","settle","fix (8)"],
["遼","멀 료","료","강이름 료","나라이름 료","distant","far (16)"],
["覬","바랄 기","기","covet","long for","desire (17)"],
["甘","달 감","감","달게 여길 감","sweetness; sweet","tasty (5)"],
["罔","그물 망","망","net; deceive; libel; negative (8)"],
["兟","나아갈 신","신","총총 들어설 신","to advance (12)"],
["罕","드물 한","한","rare","scarce; surname (8)"],
["龍","용 룡","룡","임금 룡","dragon; symbolic of emperor (16)"],
["悅","즐거울 열","열","복종할 열","성씨 열","pleased (10)"],
["宛","서쪽 나라이름 원","원","seem","as if","crooked (8)"],
["暈","무리 운","운","어지러울 운","halo in sky; fog; dizzy","faint (13)"],
["甚","심할 심","심","무엇 심","great extent; considerably (9)"],
["旴","해 돋을 우","우","새벽 우","dawn (7)"],
["櫂","상앗대 도","도","oar","scull","paddle; row (18)"],
["宜","옳을 의","의","마땅할 의","유순할 의","suitable","right","fitting","proper (8)"],
["甛","달 첨","첨","sweet","sweetness (11)"],
["阮","원나라 원","원","성씨 원","ancient musical instrument; surname (7)"],
["覯","만나볼 구","구","meet or see unexpectedly (17)"],
["櫃","상자 궤","궤","cupboard","wardrobe","counter (18)"],
["兡","헥트르램 백","백","mercury (chemical element) (13)"],
["甜","달 첨","첨","sweet","sweetness (11)"],
["劉","이길 류","류","죽일 류","베풀 류","성씨 류","surname; kill","destroy (15)"],
["兢","삼갈 긍","긍","fearful","cautious","wary (14)"],
["杻","싸리 뉴","뉴","ligustrum sinenese","tree (8)"],
["罘","그물 부","부","screen used in ancient times (9)"],
["悉","모두 실","실","know","learn about","comprehend (11)"],
["柳","버들 류","류","별 이름 류","willow tree; pleasure (9)"],
["覲","뵈올 근","근","have imperial audience (18)"],
["甞","맛볼 상","상","taste; experience","experiment (13)"],
["流","흐를 류","류","번져나갈 류","구할 류","내칠 류","달아날 류","무리 류","귀양보낼 류","flow","circulate","drift; class (10)"],
["観","볼 관","관","see","observe","view; appearance (18)"],
["生","날 생","생","life","living","lifetime; birth (5)"],
["悋","아낄 린","린","stingy","sparing of; closefisted (10)"],
["入","들 입","입","enter","come in(to)","join (2)"],
["躁","떠들 조","조","tense","excited","irritable (20)"],
["悌","공경할 제","제","brotherly","respectful (10)"],
["溜","처마물 류","류","낙수물 류","증류수 류","slide","glide","slip; slippery (13)"],
["葆","풀 더부룩할 보","보","reserve","preserve; conceal (15)"],
["旺","고울 왕","왕","해무리 왕","성할 왕","prosper; prosperous; increase (8)"],
["兦","없을 무","무","destruction (3)"],
["客","손님 객","객","부칠 객","나그네 객","지날 객","guest","traveller; customer (9)"],
["悍","사나울 한","한","courageous","brave; violent (10)"],
["琉","유리돌 류","류","나라이름 류","sparkling stone; glazed","opaque (11)"],
["旻","하늘 민","민","heaven (8)"],
["內","안 내","내","inside","interior; domestic (4)"],
["宣","베풀 선","선","declare","announce","proclaim (9)"],
["全","온전할 전","전","갖출 전","maintain","keep whole or intact (6)"],
["罝","짐승 그물 저","저","net for catching rabbits (10)"],
["飛","날 비","비","fly; go quickly; dart; high (9)"],
["留","머무를 류","류","그칠 류","오랠 류","횡사할 류","stop","halt; stay","detain","keep (10)"],
["旼","온화할 민","민","gentle and affable (8)"],
["室","집 실","실","아내 실","room","home","house","chamber (9)"],
["覷","엿볼 처","처","peep at; watch","spy on (19)"],
["飜","뒤칠 번","번","flip over","upset","capsize (21)"],
["硫","석류황 류","류","유황 류","sulfur (12)"],
["産","낳을 산","산","give birth (11)"],
["旽","늘 돋을 돈","돈","morning sun","sunrise (8)"],
["兩","두 량","량","two","both","pair","couple; ounce (8)"],
["宥","너그러울 유","유","죄 사할 유","도울 유","forgive","pardon","indulge (9)"],
["宦","벼슬 환","환","officialdom","government official (9)"],
["躅","자취 촉","촉","강동강동 뛸 촉","철쭉꽃 촉","walk carefully; hesitate","falter (20)"],
["紐","단추 뉴","뉴","맺을 뉴","knot; button; handle","knob; tie (10)"],
["葉","잎 엽","엽","세대 엽","성씨 엽","leaf","petal; page of book; period (15)"],
["罟","그물 고","고","net; snare; pressure come or go (10)"],
["兪","그럴 유","유","대답할 유","공손할 유","surname; consent (9)"],
["八","여덟 팔","팔","eight; all around","all sides (2)"],
["類","같을 류","류","나눌 류","착할 류","무리 류","class","group","kind","category (19)"],
["葊","풀이름 암","암","cottage (15)"],
["甥","생질 생","생","sister's child (12)"],
["罠","그물 민","민","animal trap (10)"],
["躇","머뭇거릴 착","착","hesitate","falter","be undecided (20)"],
["食","밥 식","식","먹을 식","씹을 식","제 식","헛말할 식","eat; meal; food; KangXi radical number 184 (9)"],
["六","여섯 륙","륙","나라이름 륙","number six (4)"],
["覺","꿈 깰 교","교","to wake up from sleep; conscious (20)"],
["甦","깨어날 소","소","숨 쉴 소","be reborn; resuscitate","revive (12)"],
["罡","강별 강","강","the name of a certain stars; the god who is supposed to live in them (10)"],
["悒","답답할 읍","읍","근심할 읍","sorrowful","depressed","unhappy (10)"],
["公","공변될 공","공","fair","equitable; public; duke (4)"],
["戮","죽일 륙","륙","육시할 륙","욕할 륙","kill","massacre; oppress (15)"],
["六","여섯 륙","륙","number six (4)"],
["悔","뉘우칠 회","회","repent","show remorse","regret (10)"],
["兮","어조사 혜","혜","exclamatory particle (4)"],
["陸","뭍 륙","륙","길 륙","어긋날 륙","뛸 륙","두터울 륙","land","continental; army (11)"],
["躉","거룻배 돈","돈","sell or buy wholesale; store (20)"],
["用","쓸 용","용","재물 용","통할 용","use","employ","apply","operate; use (5)"],
["罣","걸 괘","괘","hinder","disturb","obstruct (11)"],
["躊","머뭇거릴 주","주","hesitate","falter; smug","self-satisfied (21)"],
["飢","주릴 기","기","hunger","starving; hungry; a famine (11)"],
["倫","인륜 륜","륜","무리 륜","조리 륜","의리 륜","가릴 륜","normal human relationships (10)"],
["覽","볼 람","람","look at","inspect; perceive (21)"],
["躋","오를 제","제","ascend","go up","rise (21)"],
["飣","굄새 정","정","음식 괼 정","display food for show only; sacrifice (11)"],
["崙","곤륜산 륜","륜","나라이름 륜","Kunlun mountains in Jiangsu (11)"],
["悖","거스를 패","패","어지러울 패","be contradictory to","go counter (10)"],
["淪","빠질 륜","륜","물놀이칠 륜","거느릴 륜","be lost; sink","be submerged (11)"],
["甫","클 보","보","begin; man","father; great (7)"],
["罦","그물 부","부","net (12)"],
["共","함께 공","공","together with","all","total; to share (6)"],
["宬","사고 성","성","도서실 성","창고 성","archives; surname (10)"],
["葑","무 풍","풍","the rape-turnip (15)"],
["覿","볼 적","적","see; interview; be admitted to audience (22)"],
["輪","바퀴 륜","륜","땅 길이 륜","우렁찰 륜","서릴 륜","둘레 륜","wheel; revolve","turn; recur (15)"],
["躍","뛸 적","적","skip","jump","frolic (21)"],
["甬","물 솟아 오를 용","용","골목길 용","쇠북 꼭지 용","곡식 셈하는 단위 휘 용","path; river in Ningbo; Ningbo (7)"],
["櫓","방패 로","로","oar","scull; row (19)"],
["飦","범벅 전","전","된죽 전","well-boiled congee or gruel; thick","rich (12)"],
["罨","그물 엄","엄","medical compress; fish net (13)"],
["宮","굴궐 궁","궁","palace","temple","dwelling","enclose (10)"],
["噀","물 뿜을 손","손","spirt out of the mouth (15)"],
["律","법 률","률","풍률 률","지을 률","저울질할 률","statute","principle","regulation (9)"],
["悚","두려울 송","송","송구할 송","afraid","scared","frightened (10)"],
["噁","성낼 오","오","nastiness; nauseate: sickening (15)"],
["飧","저녁밥 손","손","물에 만 밥 손","evening meal","supper; cooked food (12)"],
["噂","수군거릴 준","준","meet (15)"],
["罪","허물 죄","죄","crime","sin","vice; evil; hardship (13)"],
["悛","고칠 전","전","차례 전","repent","reform (10)"],
["宰","주관할 재","재","다스릴 재","재상 재","으뜸 재","삶을 재","to slaughter; to rule (10)"],
["慄","두려울 률","률","쭈그릴 률","슬플 률","떨 률","shiver","shudder","tremble; tremble (13)"],
["躐","밟을 렵","렵","to stride over; to step across (22)"],
["兵","군사 병","병","soldier","troops (7)"],
["躑","머뭇거릴 척","척","waver","hesitate","be irresolute (22)"],
["栗","밤 률","률","신주 재목 률","단단할 률","무서울 률","공손할 률","chestnut tree","chestnuts; surname (10)"],
["甯","편안할 녕","녕","peaceful (12)"],
["罫","줄 괘","괘","hinder (13)"],
["其","그 기","기","his","her","its","their; that (8)"],
["噃","산돼지 번","번","소리 번","(Cant.) emphatic final particle (15)"],
["田","밭 전","전","사냥할 전","논 전","field","arable land","cultivated (5)"],
["率","헤아릴 률","률","과녁 률","홀 한껏 다릴 률","to lead; ratio; rate; limit (11)"],
["具","갖출 구","구","tool","implement; draw up","write (8)"],
["飪","익힐 임","임","cooked food; cook until well done (13)"],
["害","해할 해","해","시기할 해","죽일 해","injure","harm; destroy","kill (10)"],
["典","책 전","전","법 전","맡을 전","도덕 전","law","canon; documentation; classic","scripture (8)"],
["著","분명할 착","착","manifest; (Cant.) to wear (14)"],
["躒","움직일 력","력","walk","move (22)"],
["由","말미암을 유","유","쓸 유","지날 유","cause","reason; from (5)"],
["噅","거짓말 위","위","곱사등이 위","to speak falsely or wrongly; ugly (12)"],
["飫","먹기 싫을 어","어","배부를 어","surfeited","satiated; confer (13)"],
["隆","성할 륭","륭","높을 륭","가운데 우뚝할 륭","괘 이름 륭","prosperous","plentiful","abundant (12)"],
["稀","드물 희","희","rare","unusual","scarce; sparse (12)"],
["置","베풀 치","치","버릴 치","역말 치","안치할 치","place","lay out; set aside (13)"],
["躓","넘어질 지","지","stumble","totter; fail","be frustrated (22)"],
["甲","갑옷 갑","갑","첫째 천간 갑","비롯할 갑","떡잎 날 갑","법령 갑","대궐 갑","armor","shell; fingernails; 1st heavenly stem (5)"],
["櫚","종려나무 려","려","palm (19)"],
["兹","무성할 자","자","now","here; this; time","year (9)"],
["宴","잔치할 연","연","편안할 연","to entertain","feast; a feast","banquet (10)"],
["利","날카로울 리","리","이로울 리","탐할 리","편리할 리","이자 리","gains","advantage","profit","merit (7)"],
["飭","갖출 칙","칙","닦을 칙","부지런할 칙","order; command; give command (13)"],
["櫛","빗 즐","즐","comb out; weed out","eliminate (19)"],
["躔","햇길 전","전","별자리 전","follow in","imitate; rut","path (22)"],
["申","펼 신","신","기지개켤 신","거듭 신","아홉째 지지 신","원숭이 신","to state to a superior","report; extend; 9th terrestrial branch (5)"],
["悟","깨우칠 오","오","to apprehend","realize","become aware (10)"],
["宵","밤 소","소","작을 소","벌레이름 소","night","evening","dark (10)"],
["吏","아전 리","리","관리 리","government official","magistrate (6)"],
["葚","뽕나무 열매 심","심","mulberry fruit (15)"],
["罰","죄 벌","벌","penalty","fine; punish","penalize (14)"],
["悠","멀 유","유","생각할 유","한가할 유","근심할 유","long","far","remote","distant; liesurely (11)"],
["櫜","활집 고","고","quiver (case for arrows) (19)"],
["家","집 가","가","house","home","residence; family (10)"],
["飮","마실 음","음","drink; swallow; kind of drink (13)"],
["履","가죽신 리","리","신을 리","밟을 리","footwear","shoes; walk on","tread (15)"],
["葛","칡 갈","갈","edible bean; surname (15)"],
["稂","강아지 풀 랑","랑","grass; weeds (12)"],
["櫝","함 독","독","cabinet","wardrobe; closet (19)"],
["兼","겸할 겸","겸","아우를 겸","둘 얻을 겸","모을 겸","unite","combine; connect; and (10)"],
["噉","씹을 담","담","bite","chew; (Cant.) like this","in this way (15)"],
["飯","밥 반","반","cooked rice; food; meal (13)"],
["易","쉬울 이","이","다스릴 이","게으를 이","편할 이","change; easy (8)"],
["署","관청 서","서","public office (13)"],
["櫞","연나무 연","연","citrus (19)"],
["宸","집 신","신","처마 신","대궐 신","imperial; imperial palace (10)"],
["李","오얏 리","리","선비 천거리할 리","역말 리","보따리 리","plum; judge; surname (7)"],
["患","근심 환","환","suffer","worry about; suffering (11)"],
["男","사내 남","남","male","man; son; baron; surname (7)"],
["櫟","상수리나무 력","력","chestnut-leaved oak; oak (19)"],
["容","얼굴 용","용","감쌀 용","용서할 용","looks","appearance; figure","form (10)"],
["梨","배 리","리","벌레이름 리","pear; opera; cut","slash (11)"],
["悤","바쁠 총","총","덤빌 총","agitated","restless","hurried (11)"],
["噌","시끄러울 증","증","scold","shout at (15)"],
["躙","짓밟을 린","린","trample down","oppress","overrun (23)"],
["甸","경기 전","전","suburbs of capital; govern; crops (7)"],
["稅","풀 탈","탈","끌를 탈","taxes","revenue","duty; tax (12)"],
["泥","진흙 니","니","물이 더러워질 니","흙손 니","술취할 니","이슬 맺힐 니","mud","mire; earth","clay; plaster (8)"],
["噍","먹을 초","초","chew; eat; munch (15)"],
["躚","춤출 선","선","wander about","walk around; revolve (23)"],
["罵","욕할 매","매","accuse","blame","curse","scold (15)"],
["理","다스릴 리","리","바를 리","누늬낼 리","성품 리","이치 리","고칠 리","나무결 리","reason","logic; manage (11)"],
["町","밭 두덕 정","정","정보 정","시가 정","raised path between fields (7)"],
["鷁","익새 익","익","돛대 끝 바람개비 익","fishhawk bow or prow (21)"],
["噎","목 멜 열","열","choke; hiccup (15)"],
["飴","엿 이","이","sweet-meats; sweet-cakes; syrup (14)"],
["痢","이질 리","리","곱똥 리","설사 리","dysentery (12)"],
["噏","숨 들이쉴 흡","흡","inhale","absorb","imbibe; compress; (Cant.) to talk","say","tell (15)"],
["葡","포도 포","포","들머리 포","나라이름 포","grapes (15)"],
["鷂","장끼 요","요","새매 요","sparrow hawk; Circus species (various) (21)"],
["稈","짚 간","간","stalk of grain; straw (12)"],
["悧","영리할 리","리","smooth; active; clever","sharp (10)"],
["罹","만날 리","리","근심할 리","걸릴 리","sorrow","grief; incur","meet with (16)"],
["罷","느른할 피","피","고달플 피","잔병 피","cease","finish","stop; give up (15)"],
["噐","그릇 기","기","receptacle","vessel; instrument (15)"],
["裏","옷 안 리","리","속 리","inside","interior","within (13)"],
["稊","강아지풀 제","제","darnels","tares (12)"],
["鷄","닭 계","계","chicken (21)"],
["董","바로잡을 동","동","direct","supervise; surname (15)"],
["躞","족자 마구리 섭","섭","저벅저벅 걸을 섭","to walk (24)"],
["宿","잘 숙","숙","지킬 숙","머물 숙","stop","rest","lodge","stay overnight; constellation (11)"],
["程","법 정","정","한정 정","과정 정","품수 정","헤아릴 정","길 정","journey","trip; schedule","agenda (12)"],
["罹","근심 리","리","sorrow","grief; incur","meet with (16)"],
["里","마을 리","리","근심할 리","잇수 리","unit of distance; village; lane (7)"],
["噓","불 허","허","뿜을 허","exhale; blow out; deep sigh; hiss; praise","flatter; lie (14)"],
["鎊","깎을 방","방","pound sterling (18)"],
["稌","찰벼 도","도","glutinous rice (12)"],
["離","떠날 리","리","베풀 리","지날 리","아름다울 리","둘 리","외손 리","leave","depart; go away; separate (19)"],
["稍","벼 줄기 끝 초","초","little","slightly; rather (12)"],
["鷇","새 새끼 구","구","chicks","fledglings (21)"],
["葦","갈대 위","위","작은 배 위","reed (15)"],
["躡","밟을 섭","섭","신 신을 섭","오를 섭","tread","step on; follow","track (25)"],
["匿","숨을 닉","닉","숨길 닉","덮어둘 닉","몰래 붙을 닉","쌀 닉","술 담는 그릇 닉","hide; go into hiding (11)"],
["鎌","낫 겸","겸","sickle (18)"],
["溺","빠질 닉","닉","약할 닉","헤어 나오지 못할 닉","drown; submerge in water; indulge (13)"],
["罽","물고기 그물 계","계","a kind of woolen fabric (17)"],
["吝","아낄 인","인","stingy","miserly","parsimonious (7)"],
["罾","어망 증","증","a large square net","lowered and raised from the bank of the river (17)"],
["櫪","말구유 력","력","type of oak; stable (20)"],
["飼","먹일 사","사","raise animals; feed; nourish (14)"],
["燐","도깨비불 인","인","phosphorus (16)"],
["飽","배부를 포","포","물릴 포","먹기 싫을 포","흡족할 포","eat heartily; eat one's fill (14)"],
["葩","꽃봉오리 파","파","flowers (15)"],
["悰","즐길 종","종","enjoy","amuse","please; joy (11)"],
["鎏","급속 류","류","pure gold (18)"],
["璘","옥빛 인","인","luster of jade (16)"],
["櫬","관 친","친","무궁화나무 친","땔나무 친","coffin; tung tree (20)"],
["葪","베일 계","계","삽주 계(15)"],
["悱","표현 못할 비","비","to be desirous of speaking (11)"],
["飾","꾸밀 식","식","문채날 식","분 바를 식","decorate","ornament","adorn; to deceive (14)"],
["藺","골풀 인","인","rush used in making mats; surname (22)"],
["葫","마늘 호","호","조롱박 호","bottle-gourd (15)"],
["悲","슬플 비","비","sorrow","grief; sorry","sad (12)"],
["葬","장사 지낼 장","장","bury","inter (15)"],
["稔","곡식익을 임","임","ripe grain; harvest; to know","be familiar with (13)"],
["鱗","비늘 인","인","fish scales (23)"],
["悴","근심할 췌","췌","파리할 췌","피폐할 췌","suffer","become emaciated","haggard (11)"],
["葭","갈대 가","가","bulrush","reed; flute","whistle (15)"],
["躩","바삐 갈 곽","곽","take quick","short steps (27)"],
["斁","섞을 두","두","dislike; be weary of; explain (17)"],
["麟","기린 인","인","female of Chinese unicorn (23)"],
["悵","슬퍼할 창","창","disappointed","dissatisfied (11)"],
["鎔","불릴 용","용","녹일 용","거푸집 용","fuse","melt","smelt; mold (18)"],
["躪","짓밟을 린","린","trample down","oppress","overrun (27)"],
["斂","거둘 렴","렴","draw back","fold back; collect (17)"],
["林","수풀 림","림","더북더북 날 림","forest","grove; surname (8)"],
["斃","죽을 폐","폐","엎드러질 폐","kill; die violent death (18)"],
["稗","돌피 패","패","조그마할 패","darnels","weeds","tares small (13)"],
["葯","꽃밥 약","약","orris root; leaf of the iris; medicine (15)"],
["身","몸 신","신","아이 밸 신","몸소 신","body; trunk","hull; rad. no. 158 (7)"],
["悶","번민할 민","민","gloomy","depressed","melancholy (12)"],
["淋","지적지적할 림","림","축일 림","번지르르할 림","drip","soak","drench; perfectly (11)"],
["鎖","자물쇠 쇄","쇄","쇠사슬 쇄","가둘 쇄","항쇄 쇄","lock","padlock; shackles","chains (18)"],
["躬","몸 궁","궁","body; personally","in person (10)"],
["噞","고기 우물거릴 엄","엄","the movement of a fish's mouth at the surface of the water (16)"],
["臨","임할 림","림","군림할 림","여럿이 울 림","굽힐 림","draw near","approach; descend (17)"],
["斅","가르칠 효","효","teach","instruct; be aroused; awake; intelligent (20)"],
["葱","풀 더북할 총","총","scallions","leeks","green onions (15)"],
["稙","올벼 직","직","grain ready for grinding (13)"],
["鎗","금석소리 쟁","쟁","창 쟁","총 쟁","rifle","small arms","hand gun (18)"],
["悸","두근거를 계","계","fearful","apprehensive","perturbed (11)"],
["激","물결 부딪혀 흐를 격","격","찌를 격","맑은 소리 격","말 과격히 할 격","arouse","excite","incite; quickly (16)"],
["立","설 입","입","stand; let stand; establish","set (5)"],
["濁","흐릴 탁","탁","더러울 탁","물이름 탁","muddy","turbid","dirty","filthy (16)"],
["稚","어린 벼 치","치","어릴 치","늦을 치","young","immature; childhood (13)"],
["鷓","자고 자","자","partridge (22)"],
["噠","오랑캐 나라 이름 달","달","sound made to get a horse to move forward (16)"],
["葳","초목 성할 위","위","위유풀 위","luxuriant","flourishing; used for various plants (15)"],
["文","무늬 문","문","얼룩 문","literature","culture","writing (4)"],
["濂","내 이름 렴","렴","a waterfall; a river in Hunan (16)"],
["笠","우리 입","입","bamboo hat; bamboo covering (11)"],
["悻","발끈 성낼 행","행","anger","vexation; angry","indignant (11)"],
["鎚","옥 다듬을 퇴","퇴","hammer","mallet; club (18)"],
["襁","포대기 강","강","swaddling clothes (17)"],
["稜","모 릉","릉","corner","edge","angle; square block (13)"],
["濃","짙을 농","농","thick","strong","concentrated (16)"],
["噢","슬퍼할 욱","욱","moan; interjection for pain","sad (16)"],
["粒","알 입","입","grain; small particle (11)"],
["躱","피할 타","타","hide (13)"],
["噣","쪼을 탁","탁","a beak; to peck; to order","enjoin upon (16)"],
["狀","모양 형상 장","장","form; appearance; shape; official (7)"],
["鎛","종 박","박","large bell; hoe","spade (18)"],
["鷖","갈매기 예","예","봉황 예","seagull (22)"],
["葵","해바라기 규","규","sunflower; measure (15)"],
["炙","고기구이 적","적","김 쏘일 적","냄새 퍼질 적","roast","broil; toast; cauterize (8)"],
["鷗","갈매기 구","구","seagull","tern; Larus species (various) (22)"],
["稞","보리 과","과","grain ready for grinding; grain (13)"],
["悼","슬퍼할 도","도","grieve","mourn","lament; grieved (11)"],
["噤","입 다물 금","금","close; be silent","be unable speak (16)"],
["葷","매운 채소 훈","훈","meat diet; strong smelling (15)"],
["稟","여쭐 품","품","줄 품","받을 품","성품 품","report to","petition (13)"],
["悽","슬퍼할 처","처","sorrowful","grieved","melancholy (11)"],
["識","표할 지","지","recognize","understand","know (19)"],
["襄","오를 양","양","장사지낼 양","aid","help","assist; undress (17)"],
["濆","뿜을 분","분","river bank; river in Henan province; gush (16)"],
["噥","달게 먹을 농","농","whisper (16)"],
["什","세간 집","집","file of ten soldiers (4)"],
["鷙","맹금 지","지","hawk","vulture (22)"],
["稠","빽빽할 주","주","dense","crowded","packed; soupy (13)"],
["悾","정성 공","공","naive","guileless","simple-minded (11)"],
["斌","빛날 빈","빈","refined","having both appearance (11)"],
["濇","껄끄러울 색","색","astringent; harsh","rough; uneven (16)"],
["噦","새소리 홰","홰","belch; vomit (16)"],
["茶","차 차","차","tea (12)"],
["櫻","앵두나무 앵","앵","cherry","cherry blossom (21)"],
["刺","찌를 척","척","stab; prick","irritate; prod (8)"],
["葺","기울 즙","즙","thatch; fix","repair; pile up (15)"],
["器","그릇 기","기","receptacle","vessel; instrument (16)"],
["鎡","호미 자","자","hoe; mattock (18)"],
["濊","흐릴 예","예","물 넘칠 예","vast","expansive","deep; dirty (16)"],
["噩","놀랄 악","악","bad","ill-omened","unlucky (16)"],
["噪","떠들썩할 조","조","be noisy; chirp loudly (16)"],
["鎣","줄 형","형","꾸밀 형","polish (18)"],
["斐","오락가락할 비","비","graceful","elegant","beautiful (12)"],
["噫","탄식할 희","희","belch; alas (16)"],
["鎤","종소리 황","황","sound of a bell; small bell (18)"],
["斑","얼룩 반","반","mottled","striped","freckle (12)"],
["噬","씹을 서","서","bite; gnaw; snap at (16)"],
["噭","부르짖을 교","교","shout (16)"],
["儀","꼴 의","의","모양 의","법도 의","좋을 의","ceremony","rites gifts; admire (15)"],
["噯","트림할 애","애","interjection; exclamation (16)"],
["鎧","투구 개","개","갑옷 개","armor","chain mail (18)"],
["儂","나 농","농","I; you; family name (15)"],
["噱","껄껄 웃을 갹","갹","입 벌일 갹","laugh heartily","laugh aloud (16)"],
["噲","목구멍 쾌","쾌","훨할 쾌","swallow","gulp down; greedy (16)"],
["斗","말 두","두","Chinese peck; liquid measure (4)"],
["鷦","뱁새 초","초","wren (23)"],
["億","억 억","억","편안할 억","이바지할 억","헤아릴 억","인민 억","hundred million; many (15)"],
["孀","과부 상","상","widow (20)"],
["噳","뭇 사슴 우물거릴 우","우","many (16)"],
["料","되질할 료","료","consider","conjecture; materials","ingredients (10)"],
["濕","젖을 습","습","소 귀 벌룩거릴 습","근심 습","wet","moist","humid","damp; an illness (17)"],
["鎬","호경 호","호","stove; bright (18)"],
["種","씨 종","종","seed; race; offspring; to plant (14)"],
["儆","경계할 경","경","warn; warning (15)"],
["噴","뿜을 분","분","spurt","blow out","puff out (15)"],
["儇","영리할 현","현","빠를 현","clever","nimble (15)"],
["鎭","누를 진","진","town","market place; suppress (18)"],
["孃","계집아이 양","양","troubled","oppressed; fat; mother (20)"],
["鷩","금계 별","별","pheasant (23)"],
["斛","휘 곡","곡","dry measure equal to some five or ten dou (U+6597 舒) (11)"],
["儈","거간군 괴","괴","go-between","broker","proxy (15)"],
["襖","도포 오","오","outer garments; coat","jacket (19)"],
["斜","고을이름 야","야","slanting","sloping","inclined (11)"],
["濘","진흙 녕","녕","mud; miry","muddy","stagnant (17)"],
["噶","벼슬이름 갈","갈","땅이름 갈","used in transliterations (16)"],
["稱","저울질할 칭","칭","일컬을 칭","헤아릴 칭","같을 칭","call; name","brand; address; say (14)"],
["儉","검소할 검","검","흉년들 검","적을 검","가난할 검","temperate","frugal","economical (15)"],
["孅","가늘 섬","섬","slender","thin; sharp-pointed (20)"],
["鷫","숙상새 숙","숙","turquoise kingfisher (23)"],
["斝","옥잔 가","가","small jade wine cup (12)"],
["鎰","중량 일","일","measure of weight for gold (18)"],
["斟","술 따를 짐","짐","to pour wine or tea into a cup; to gauge (13)"],
["儋","기운차고 날쌜해집 담","담","a small jar; to bear a burden; a load of two (15)"],
["儌","갈 교","교","lucky","fortunate (15)"],
["濛","가랑비 올 몽","몽","drizzling","misty","raining (17)"],
["噺","이야기 신","신","story","talk (16)"],
["斡","돌릴 알","알","주선할 알","revolve","rotate","turn (14)"],
["襜","행주치마 첨","첨","flutter; shaking or vibrating (19)"],
["稷","기장 직","직","god of cereals; minister of agriculture (15)"],
["鷰","제비 연","연","씨름 연(23)"],
["濟","나루 제","제","to help","aid","relieve; to ferry","cross (17)"],
["儐","인도할 빈","빈","entertain guests (16)"],
["瓊","옥 경","경","jade; rare","precious; elegant (19)"],
["斤","근 근","근","a catty (approximately 500 g); an axe; keen","shrewd; KangXi radical number 69 (4)"],
["濠","해자 호","호","강 이름 호","moat","trench","ditch (17)"],
["鷲","독수리 취","취","condor","vulture (23)"],
["斥","물리칠 척","척","to scold","upbraid","accuse","reproach (5)"],
["儑","잔피할 압","압","용렬할 압(16)"],
["孌","아름다울 련","련","lovely","beautiful; docile","obedient (22)"],
["襞","주름 벽","벽","fold","pleat","crease (19)"],
["鷳","소리개 한","한","the silver pheasant","Lophura nycthemera (23)"],
["儒","선비 유","유","나장이 유","유도 유","광대 유","Confucian scholar (16)"],
["襟","옷깃 금","금","lapel","collar (19)"],
["濡","적실 유","유","막힐 유","곱고 윤택할 유","immerse","moisten; wet","damp (17)"],
["儓","하인 대","대","servant (16)"],
["襠","잠방이 당","당","crotch or seat of pants; pants (19)"],
["稻","벼 도","도","rice growing in field","rice plant (15)"],
["斧","도끼 부","부","axe","hatchet; chop","hew (8)"],
["儔","무리 주","주","companion","mate","colleague (16)"],
["稼","심을 가","가","sow grain; sheaves of grain (15)"],
["儕","동배 제","제","a company","companion; together (16)"],
["子","아들 자","자","offspring","child; fruit","seed of; 1st terrestrial branch (3)"],
["稽","머무를 계","계","examine","investigate; delay (15)"],
["濤","물결 도","도","large waves (17)"],
["孑","외로울 혈","혈","나머지 혈","창갈구리 혈","remaining","left-over; lonely (3)"],
["瓏","옥소리 룡","룡","a gem cut like dragon (20)"],
["儗","서로 못믿을 의","의","참람할 의","compare with","draw analogy with (16)"],
["襤","누더기 람","람","ragged","tattered","threadbare (20)"],
["稿","볏집 고","고","draft","manuscript","rough copy (15)"],
["斫","벨 작","작","cut","chop","lop off (9)"],
["斬","벨 참","참","cut","chop","sever; behead (11)"],
["鷹","매 응","응","eagle","falcon; Accipiter species (various) (24)"],
["孔","구멍 공","공","opening","hole","orifice; great (4)"],
["鷺","해오라기 로","로","heron","egret; Ardea species (various) (23)"],
["襦","저고리 유","유","베쐐기 갓장이 유","short coat","jacket; fine silk fab (20)"],
["断","어질 단","단","sever","cut off; interrupt (11)"],
["濩","퍼질 호","호","pour down","cascade down; look (17)"],
["孕","아이밸 잉","잉","be pregnant","pregnancy (5)"],
["孖","쌍둥이 자","자","twins (6)"],
["瓔","옥돌 영","영","구슬 목걸이 영","a necklace made of precious stones (21)"],
["斯","이 사","사","this","thus","such; to lop off; emphatic particle (12)"],
["鷽","메까치 학","학","oriental bullfinch","weaver bird; Pyrrhula species (various) (24)"],
["恁","생각할 임","임","that","like this","thus","so","such (10)"],
["儜","고달플 녕","녕","weak; wearied; in distress (16)"],
["字","글자 자","자","letter","character","word (6)"],
["呂","풍류 려","려","등골뼈 려","성씨 려","surname; a musical note (7)"],
["濫","넘쳐흐를 함","함","flood","overflow; excessive (17)"],
["濬","칠 준","준","dredge","dig; profound","deep (17)"],
["存","있을 존","존","exist","live","be; survive; remain (6)"],
["恂","갑자기 준","준","careful","sincere","honest; trust (9)"],
["女","계집 녀","녀","시집보낼 녀","woman","girl; feminine; rad. 38 (3)"],
["襪","버선 말","말","socks","stockings (21)"],
["新","새 신","신","처음 신","고울 신","new","recent","fresh","modern (13)"],
["恃","믿을 시","시","의지할 시","어머니 시","rely on","presume on","trust to (9)"],
["廬","초가 려","려","움막 려","hut","cottage; name of a mountain (19)"],
["斲","깎을 착","착","cut","chop","hack (14)"],
["檀","향나무 단","단","sandalwood","hardwood; surname (17)"],
["孚","미쁠 부","부","brood over eggs; have confidence (7)"],
["旅","나그네 려","려","베풀 려","trip","journey; travel; traveler (10)"],
["瓘","옥 이름 관","관","jade (22)"],
["濮","강 이름 복","복","county in Henan province (17)"],
["襭","옷자락 꽂을 힐","힐","tuck up hem of garment and wrap (21)"],
["孛","혜성 패","패","comet (7)"],
["濯","씻을 탁","탁","빨래할 탁","적실 탁","클 탁","빛날 탁","산 민둥민둥할 탁","살찔 탁","놀 탁","wash out","rinse; cleanse (17)"],
["償","갚을 상","상","repay","recompense; restitution (17)"],
["濾","씻을 려","려","맑을 려","strain out","filter (18)"],
["瓚","제기 찬","찬","ceremonial libation cup (23)"],
["孜","힘쓸 자","자","be as diligent as possible (7)"],
["礪","돌 단단할 려","려","whetstone; sharpen (20)"],
["孝","효도 효","효","filial piety","obedience; mourning (7)"],
["瓛","옥홀 환","환","scaptre (24)"],
["襯","속옷 친","친","접근할 친","베풀 친","underwear; inner garments (22)"],
["恇","겁낼 광","광","fear (9)"],
["儡","영락할 뢰","뢰","puppet","dummy (17)"],
["閭","마을 려","려","village of twenty-five families (15)"],
["濱","물가 빈","빈","beach","sea coast; river bank (17)"],
["驪","가라말 려","려","나라이름 려","산이름 려","a pure black horse; a pair of horses (29)"],
["瓜","오이 과","과","melon","gourd","cucumber; rad. 97 (5)"],
["萃","모을 췌","췌","괘 이름 췌","dense","thick","close-set; to collect together (14)"],
["檄","격서 격","격","격문 격","과격할 격","call arms; urgency (17)"],
["孟","맏 맹","맹","first in series; great","eminent (8)"],
["麗","고울 려","려","빛날 려","베풀 려","beautiful","magnificent","elegant (19)"],
["斷","끊을 단","단","sever","cut off; interrupt (18)"],
["恊","겁낼 협","협","위협할 협","be united; cooperate (9)"],
["黎","무리 려","려","동틀 려","surname; numerous","many; black (15)"],
["襲","옷 덧입을 습","습","벼슬 대물릴 습","반복할 습","합할 습","염습할 습","엄습할 습","raid","attack; inherit (22)"],
["萄","포도나무 도","도","grapes (14)"],
["蹀","저벅저벅 걸을 접","접","to skip","to dance; to put the foot down (16)"],
["儥","팔 육","육","길 육(17)"],
["力","힘 력","력","부지런할 력","power","capability","influence (2)"],
["方","모 방","방","a square","rectangle; a region; local (4)"],
["瓠","표주박 호","호","단지 호","bottle gourd; calabash; pot (11)"],
["儦","떼지어 걷는 모양 표","표","많을 표","milling (17)"],
["蹁","돌쳐갈 편","편","잘름거릴 편","to walk with a limp (16)"],
["曆","셀 력","력","세월 력","calendar","era (16)"],
["襴","난삼 란","란","a one piece garment (23)"],
["萆","비해 비","비","the castor-oil plant also used for other plants (14)"],
["恍","황홀할 황","황","seemingly; absent-minded (9)"],
["萇","나무 이름 장","장","averrhora carambola; surname (14)"],
["季","끝 계","계","quarter of year; season; surname (8)"],
["歷","지낼 력","력","겪을 력","take place","past","history (16)"],
["蹂","밟을 유","유","쌀 씻을 유","trample under foot","tread on (16)"],
["瓢","표주박 표","표","ladle made from dried gourd (16)"],
["檉","능수버들 정","정","type of willow; tamarisk (17)"],
["孤","외로울 고","고","orphan","fatherless; solitary (8)"],
["轢","수레바퀴에 치일 력","력","서로 부닥칠 력","run over something with vehicle (22)"],
["於","오홉다 외칠 오","오","in","at","on; interjection alas! (8)"],
["瓣","외씨 판","판","실과씨 판","꽃잎 판","petal; segment; valves (19)"],
["蹄","굽 제","제","hoof; leg of pork; little witch (16)"],
["儩","다할 사","사","complete (17)"],
["孥","자식 노","노","one's children (8)"],
["年","해 년","년","나이 년","나아갈 년","year; new-years; person's age (6)"],
["施","비뚤어질 이","이","옮길 이","잘난체할 이","벗을 이","미칠 이","grant","bestow; give; act; name (9)"],
["恐","두려울 공","공","fear; fearful","apprehensive (10)"],
["優","넉넉할 우","우","부드러울 우","나을 우","결단성 없을 우","superior","excellent; actor (17)"],
["憐","불쌍할 련","련","사랑할 련","pity","sympathize (15)"],
["濺","흩뿌릴 천","천","sprinkle","spray; spill","splash (18)"],
["戀","생각할 련","련","사모할 련","love; long for","yearn for; love (22)"],
["萊","명아주 래","래","goosefoot","weed; fallow field (14)"],
["斿","깃발 유","유","깃술 유","면류관술 유","to swim; to move or rove freely (9)"],
["萋","풀 성하게 우거진 모양 처","처","luxuriant foliage; crowded (14)"],
["恒","늘 항","항","옛 항","constant","regular","persistent (9)"],
["檍","참죽나무 억","억","박달나무 억","싸리나무 억","ilex (17)"],
["撚","잡을 년","년","닦을 년","밟을 년","손 끝으로 비빌 년","twirl in fingers","tease","toy with (15)"],
["蹇","절름거릴 건","건","험할 건","교만할 건","lame","crippled; unlucky (17)"],
["瓦","질그릇 와","와","기와 와","tile; earthenware pottery; girl (5)"],
["儭","속옷 츤","츤","to assist; to give alms (18)"],
["檎","능금나무 금","금","small red apple (17)"],
["漣","물 놀이칠 련","련","눈물 줄줄 흘릴 련","flowing water; ripples; weeping (14)"],
["萌","싹 맹","맹","bud","sprout (14)"],
["蹈","밟을 도","도","stamp feet; dance (17)"],
["孩","어린아이 해","해","방글방글 웃을 해","baby","child; children (9)"],
["萍","마름 평","평","머구리밥 평","개구리밥 평","duckweed; wandering","traveling (14)"],
["蹉","넘어질 차","차","error","mistake","slip; failure (17)"],
["煉","쇠 불릴 련","련","반죽할 련","smelt","refine; distill","condense (13)"],
["蹊","지름길 혜","혜","건널 혜","footpath","trail; track (17)"],
["檐","처마 첨","첨","eaves of house; brim","rim (17)"],
["瓩","킬로그램 천","천","kilowatt; kilogram (8)"],
["恕","용서할 서","서","forgive","excuse","show mercy (10)"],
["璉","종묘 제기 련","련","vessel used to hold grain offerings (15)"],
["萎","마를 위","위","풀 이름 위","쇠약해질 위","wither","wilt (14)"],
["濾","겨를 려","려","strain out","filter (18)"],
["孫","손자 손","손","움 돋을 손","순할 손","피할 손","grandchild","descendent; surname (10)"],
["蹌","추창할 창","창","walk rapidly (17)"],
["儱","건목칠 롱","롱","rude; barbarous (18)"],
["練","이길 련","련","익힐 련","겪을 련","practice","drill","exercise","train (15)"],
["儲","쌓을 저","저","버금 저","용납할 저","to save money","store","reserve; an heir (18)"],
["西","서녘 서","서","west(ern); westward","occident (6)"],
["儳","어긋날 참","참","obstinate; stupid; uneven; mix (19)"],
["恙","근심 양","양","illness","sickness; indisposition (10)"],
["檔","책상 당","당","shelf; frame","crosspiece (17)"],
["聯","잇닿을 련","련","관계할 련","connect","join; associate","ally (17)"],
["恚","성낼 에","에","원망할 에","anger","rage (10)"],
["儴","고생할 상","상","훔칠 상","인연할 상(19)"],
["輦","당길 련","련","궁중의 길 련","a hand-cart; to transport by carriage (15)"],
["風","바람 풍","풍","흘레할 풍","울릴 풍","풍속 풍","경치 풍","위엄 풍","병풍 풍","모양 풍","wind; air; manners","atmosphere (9)"],
["儵","잿빛 숙","숙","남해 임금 숙","독화당할 숙","갑자기 숙(19)"],
["孰","누구 숙","숙","어느 숙","살필 숙","익을 숙","who? which? what? which one? (11)"],
["嘂","크게 부르짖을 교","교","cry loudly","yell","scream (14)"],
["蓮","연밥 련","련","연꽃 련","lotus","water lily; paradise (17)"],
["瓮","항아리 옹","옹","earthen jar; jar for ashes (9)"],
["儶","끝 휴","휴","valiant","brave; eminent (20)"],
["檗","황벽나무 벽","벽","tree (17)"],
["孱","잔약할 잔","잔","weak","unfit","frail","feeble (12)"],
["連","이을 련","련","끌릴 련","join","connect; continuous; even (11)"],
["儷","짝 려","려","spouse","couple","pair (21)"],
["鍊","불린 쇠 련","련","단련할 련","smelt metals","forge; refine (17)"],
["恝","걱정 없을 괄","괄","carefree; indifferent manner; without sorrow (10)"],
["儸","간능할 라","라","bandit","daredevil (21)"],
["嘅","탄식할 개","개","성낼 개","sound of sighing; (Cant.) possessive (14)"],
["列","벌릴 렬","렬","무리에 들어갈 렬","항렬 렬","베풀 렬","a line; to arrange in order","classify (6)"],
["嘆","한숨쉴 탄","탄","sigh","admire (14)"],
["儹","모을 찬","찬","to accumulate","to hoard","to store up (21)"],
["劣","용렬할 렬","렬","못날 렬","어릴 렬","서툴 렬","bad","inferior; slightly (6)"],
["恟","두려워할 흉","흉","scared","nervous (9)"],
["颭","펄렁거릴 점","점","sway in wind (14)"],
["秀","빼어날 수","수","이삭 수","벼 패일 수","아름다울 수","ear of grain; flowering","luxuriant; refined","elegant","graceful (7)"],
["儺","역귀 쫓을 나","나","rich (21)"],
["孵","알 깔 부","부","sit on eggs","hatch (14)"],
["咽","목 멜 열","열","막힐 열","throat; pharynx (9)"],
["蹕","길 치울 필","필","clear way","make room for; emp (18)"],
["嘈","지껄일 조","조","noisy (14)"],
["私","사사 사","사","private","personal; secret (7)"],
["檜","노송나무 회","회","Chinese cypress","Chinese juniper (17)"],
["儻","빼어날 당","당","if (22)"],
["孶","부지런할 자","자","variant of 孳 U+5B73","to breed in large numbers (14)"],
["烈","불 활활 붙을 렬","렬","빛날 렬","업적 렬","아름다울 렬","사나울 렬","fiery","violent","vehement","ardent (10)"],
["儼","공경할 엄","엄","grave","respectful","majestic (22)"],
["嘉","아름다울 가","가","excellent; joyful; auspicious (14)"],
["颯","바람소리 삽","삽","the sound of the wind; bleak; melancholy (14)"],
["裂","찢어질 렬","렬","비단 자투리 렬","split","crack","break open; rend (12)"],
["恢","넓을 회","회","restore; big","great","immense","vast (9)"],
["學","배울 학","학","learning","knowledge; school (16)"],
["儽","게으를 래","래","lazy; tired out","worn fatigued (23)"],
["說","기꺼울 열","열","speak","say","talk; scold","upbraid (14)"],
["颱","몹시 부는 바람 태","태","taiphoon (14)"],
["恣","방자할 자","자","indulge oneself","unrestrained (10)"],
["檟","오동나무 가","가","small evergreen shrub (17)"],
["廉","맑을 렴","렴","조촐할 렴","청렴할 렴","살필 렴","upright","honorable","honest (13)"],
["瓷","오지그릇 자","자","crockery","porcelain","chinaware (11)"],
["恤","구휼할 휼","휼","show pity; relieve","help (9)"],
["嘌","휙 불 표","표","몰아갈 표(14)"],
["蹙","찡그릴 축","축","쭈그러질 축","urgent","suddenly; grieve","lament (18)"],
["檠","도지개 경","경","stand for lamp; frame for bow (17)"],
["孺","젖먹이 유","유","사모할 유","딸릴 유","child; blood relation; affection (17)"],
["念","생각할 념","념","읽을 념","스물 념","대단히 짧은시각 념","think of","recall","study (7)"],
["恥","부끄럼 치","치","욕될 치","shame","humiliation; ashamed (10)"],
["儿","어진사람 인","인","son","child; radical 10 (2)"],
["嘍","시끄러울 루","루","used in onomatopoetic expressions (14)"],
["颳","모진 바람 괄","괄","to blow (15)"],
["捻","손가락으로 찍을 념","념","to twist","nip with the fingers (11)"],
["檢","교정할 검","검","금제할 검","법 검","봉할 검","책메뚜기 검","수결둘 검","check; examine (17)"],
["孼","첩자식 얼","얼","요물 얼","misfortune; sin","evil (19)"],
["嘎","새소리 알","알","sound of laughter; bad","malevolent (14)"],
["殮","염할 염","염","dress corpse for burial (17)"],
["蹜","발 끌어 디딜 축","축","walk carefully (18)"],
["檣","돛대 장","장","mast","yard-arm","boom (17)"],
["嘏","복 하","하","felicity","prosperity; large and strong (14)"],
["簾","발 렴","렴","a blind","screen","curtain (19)"],
["缶","장군 부","부","earthen crock or jar; rad. 121 (6)"],
["恨","한탄할 한","한","hatred","dislike; resent","hate (9)"],
["秉","잡을 병","병","grasp","hold; bundle; authority (8)"],
["嘐","닭 울 교","교","final exclamatory particle (14)"],
["颶","사방풍 구","구","cyclone","typhoon","gale (17)"],
["獵","사냥할 렵","렵","진동할 렵","어긋날 렵","바람소리 렵","hunt; field sports (18)"],
["嘑","부르짖을 호","호","거칠게 말할 호","to menace; to howl at; to bawl; (Cant.) final particle (14)"],
["缸","항아리 항","항","earthen jug","crock","cistern (9)"],
["恩","은혜 은","은","신세 은","사랑할 은","kindness","mercy","charity (10)"],
["孿","쌍둥이 산","산","twins (22)"],
["令","하여금 령","령","시킬 령","가령 령","개 목소리 령","벼슬이름 령","command","order; 'commandant'","magistrate; allow","cause (5)"],
["嘒","가냘플 혜","혜","sharp (14)"],
["秋","가을 추","추","세월 추","때 추","말이 뛰놀 추","성씨 추","autumn","fall; year (9)"],
["蹟","행적 적","적","자취 적","trace","tracks; footprints (18)"],
["恪","삼갈 각","각","respectful","reverent (9)"],
["囹","감옥 령","령","prison","enclosure (8)"],
["鍉","숟가락 시","시","열쇠 시","spoon","key (17)"],
["恫","슬플 통","통","아플 통","앓을 통","in pain","sorrowful (9)"],
["蹠","밟을 척","척","step on","tread on; sole (18)"],
["寧","편안할 녕","녕","차라리 녕","어찌 녕","repose","serenity","peace; peaceful (14)"],
["鍊","불릴 련","련","smelt metals","forge; refine (17)"],
["缺","이지러질 결","결","번개 번쩍거릴 결","깨어질 결","이 빠질 결","be short of","lack; gap","deficit (10)"],
["种","어릴 충","충","seed; race","breed; to plant (9)"],
["恬","편안할 념","념","quiet","calm","tranquil","peaceful (9)"],
["嘓","귀찮을 괵","괵","gurgling sound","chattering (14)"],
["嶺","고개 령","령","봉우리 령","mountain ridge","mountain peak (17)"],
["鍋","노구솥 과","과","cooking-pot","saucepan (17)"],
["恭","공손할 공","공","respectful","polite","reverent (10)"],
["嘔","기쁠 후","후","to vomit; annoy","enrage (14)"],
["颺","날릴 양","양","높일 양","soar","fly","float; scatter (18)"],
["怜","영리할 령","령","pity","sympathize (8)"],
["嘕","기꺼워할 언","언","즐거워할 언","빙그레할 언(14)"],
["玲","옥소리 쟁그렁거릴 령","령","아롱아롱할 령","정교할 령","tinkling of jade (9)"],
["鍍","도금할 도","도","plate","coat","gild (17)"],
["鶉","메추라기 순","순","옷 해질 순","quail; Turnix species (various) (19)"],
["蹣","비틀거릴 만","만","to jump over; to limp (18)"],
["嘖","외칠 책","책","interjection of approval or admiration (14)"],
["蹤","자취 종","종","footprints","traces","tracks (18)"],
["息","숨쉴 식","식","그칠 식","자식 식","기를 식","rest","put stop to","end","cease (10)"],
["颼","바람소리 수","수","sound of wind; blow chilly (19)"],
["瑩","귀막이 옥 영","영","밝을 영","lustre of gems; bright","lustrous (14)"],
["缾","두레박 병","병","jar","bottle (12)"],
["恰","마치 흡","흡","just","exactly","precisely; proper (9)"],
["缿","항통 항","항","문서함 항","벙어리 항(12)"],
["萩","맑은 대쑥 추","추","가래나무 추","scandent hop; tree (15)"],
["科","과정 과","과","section","department","science (9)"],
["嘗","맛볼 상","상","taste; experience","experiment (14)"],
["羚","영양 령","령","큰 뿔 양 령","species of antelope (11)"],
["秒","시간 단위 초","초","beard of grain or corn; a second (9)"],
["聆","들을 령","령","깨달을 령","listen","hear (11)"],
["颿","돛 범","범","sail (19)"],
["鈴","방울 령","령","bell (13)"],
["檮","그루터기 도","도","block of wood; blockhead; stupid (18)"],
["零","부서질 령","령","비뚝비뚝 떨어질 령","셈 나머지 령","zero; fragment","fraction (13)"],
["萬","일만 만","만","ten thousand; innumerable (15)"],
["秕","쭉정이 비","비","empty grain or rice husk","chaff (9)"],
["檯","등대 대","대","table (18)"],
["嘛","나마 마","마","final exclamatory particle (14)"],
["靈","신령 령","령","혼백 령","좋을 령","spirit","soul; spiritual world (24)"],
["蹩","절름발이 별","별","to limp (19)"],
["領","옷깃 령","령","고개 령","거느릴 령","받을 령","neck; collar; lead","guide (14)"],
["鍔","칼날끝 악","악","high","lofty; edge of knife (17)"],
["例","본보기 례","례","견줄 례","대부분 례","precedent","example; regulation (8)"],
["秘","숨길 비","비","secret","mysterious","abstruse (10)"],
["嘞","어조사 륵","륵","(Cant.) final particle used for polite refusal (14)"],
["禮","예도 례","례","인사 례","social custom; manners; courtesy; rites (18)"],
["萱","원추리 훤","훤","day-lily","hemerocallisflava (15)"],
["蹭","어정거릴 층","층","to shuffle","procrastinate","dilly-dally (19)"],
["檳","빈랑나무 빈","빈","betelnut","areca nut (18)"],
["故","옛 고","고","ancient","old; reason","because (9)"],
["嘟","칭찬할 도","도","sound of horn tooting (15)"],
["澁","떫을 삽","삽","astringent; harsh; uneven","rough (15)"],
["醴","단술 례","례","고을이름 례","sweet wine; sweet spring (20)"],
["隸","종 례","례","노비 례","검열할 례","be subservient to; servant (16)"],
["蹯","짐승 발바닥 번","번","an animal's paws (19)"],
["效","본받을 효","효","result","effect; effectiveness (10)"],
["蹰","머뭇거릴 주","주","hesitate","waver","falter (19)"],
["惡","미워할 오","오","부끄러울 오","evil","wicked","bad","foul (11)"],
["鶖","두루미 추","추","large waterfowl with naked head; Garrulus glandarius (20)"],
["澄","맑을 징","징","purify water by allowing sediment to settle; clear","pure (15)"],
["敉","어루만질 미","미","pacify","soothe","stabilize (10)"],
["了","마칠 료","료","깨달을 료","to finish; particle of completed action (2)"],
["鍛","쇠불릴 단","단","forge metal; temper","refine (17)"],
["萵","상추 와","와","부루 와","lettuce (15)"],
["僚","벗 료","료","어여쁠 료","officials; bureaucracy (14)"],
["褄","깃의 가 처","처","a skirt (14)"],
["蹲","걸터앉을 준","준","squat","crouch; idle about (19)"],
["租","구실 조","조","rent","lease; rental; tax (10)"],
["澆","걸찰 요","요","물 끌어올 요","spray","water","sprinkle (15)"],
["寮","동관 료","료","관리 료","중의 집 료","shanty","hut","shack (15)"],
["尿","오줌 뇨","뇨","urine; urinate (7)"],
["萸","수유 유","유","dogwood (15)"],
["檻","우리 함","함","threshold","door-sill (18)"],
["蹴","찰 축","축","밟을 축","kick; tread on; leap; solemn (19)"],
["澈","물 맑을 철","철","thoroughly","completely (15)"],
["敍","차례 서","서","express","state","relate","narrate (11)"],
["料","말질할 료","료","헤아릴 료","대금 료","consider","conjecture; material (10)"],
["鶚","물수리 악","악","osprey","fishhawk; Pandion haliaetus (20)"],
["複","겹칠 부","부","repeat","double","overlap (15)"],
["檼","집 마룻대 은","은","대공 은","ridge pole; shape wood by use of heat; tool for shaping bent wood (18)"],
["敎","가르침 교","교","teach (11)"],
["樂","좋아할 요","요","happy","glad; enjoyable; music (15)"],
["鍠","종고 소리 굉","굉","weapon (17)"],
["蹶","급히걸을 궤","궤","stumble","fall down; trample (19)"],
["秣","꼴 말","말","fodder","horse feed; feed horse (10)"],
["敏","민첩할 민","민","fast","quick","clever","smart (11)"],
["萼","꽃받침 악","악","the stem and calyx of a flower; a younger brother (15)"],
["褊","좁을 편","편","급할 편","옷이 몸에 낄 편","cramped","narrow","crowded; mean (15)"],
["秤","저울 칭","칭","백 근 칭","balance","scale","steelyard (10)"],
["檿","산뽕나무 염","염","mulberry (18)"],
["澌","물 잦을 시","시","다할 시","to exhaust; to drain dry (15)"],
["落","떨어질 락","락","fall","drop; net income","surplus (15)"],
["嘬","물 최","최","산적 한 입에 넣을 최","to lap; to suck (15)"],
["澍","단비 주","주","timely rain","life-giving rain (15)"],
["救","구원할 구","구","save","rescue","relieve; help","aid (11)"],
["澎","물소리 팽","팽","물결 부딛는 형세 팽","splatter (15)"],
["秦","벼 이름 진","진","feudal state of Qin; the Qin dynasty (from which the name 'China' comes) (10)"],
["褌","잠방이 곤","곤","trousers","pants","shorts","drawers (15)"],
["鍤","가래 삽","삽","spade","shovel; marking pin (17)"],
["蹺","발돋움할 교","교","raise one's foot (19)"],
["嘮","이야기할 초","초","chat","jaw","gossip","talk (15)"],
["秧","모 앙","앙","rice seedlings","young rice plants (10)"],
["鶡","할단새 갈","갈","관이름 갈","crossbill","species of nightingale (20)"],
["敔","금할 어","어","풍류그칠 어","gavel (11)"],
["澐","큰 물결 운","운","billows (15)"],
["嘯","휘파람 소","소","세게 뿜을 소","읊을 소","roar","howl","scream; whistle (15)"],
["蹼","물갈퀴 복","복","webbed feet of waterfowl (19)"],
["敕","경계할 칙","칙","다스릴 칙","삼갈 칙","바를 칙","칙서 칙","기릴 칙","신칙할 칙","an imperial order or decree (11)"],
["秩","차례 질","질","order; orderly; salary; decade (10)"],
["嘰","쪽잘거릴 기","기","sigh in disapproval; take small (15)"],
["澒","수은 홍","홍","vast (15)"],
["秪","벼 처음 익을 지","지","only","merely (10)"],
["褐","털베 갈","갈","굵은 베 갈","천한사람 갈","갈옷 갈","coarse woolen cloth; dull","dark (15)"],
["敖","희롱할 오","오","거만할 오","ramble","play about; leisurely (11)"],
["僂","구부릴 루","루","humpback; surname (13)"],
["敗","헐어질 패","패","무너질 패","깨어질 패","썪을 패","be defeated","decline","fail (11)"],
["秫","차조 출","출","glutinous variety of millet (10)"],
["嘲","조롱할 조","조","ridicule","deride","scorn","jeer at (15)"],
["僄","몸이 가벼울 표","표","진중치 못할 표","light; airy (13)"],
["褒","포장할 포","포","옷 뒤길 포","도포 포","고을이름 포","praise","commend","honor","cite (15)"],
["秬","검은 기장 거","거","black millet (10)"],
["嬀","성 규","규","name of a river in Shanxi; crafty (15)"],
["鍪","투구 무","무","가마 무","an iron pan; a metal cap (17)"],
["鍫","가래 초","초","shovel (17)"],
["褓","포대기 보","보","swaddling cloth; infancy (15)"],
["僅","겨우 근","근","only","merely","solely","just (13)"],
["嘴","부리 취","취","mouth","lips (15)"],
["璀","옥빛 찬란할 최","최","구슬 주렁주렁 달릴 최","lustre of gems; glitter; shine (15)"],
["鍬","가래 초","초","shovel (17)"],
["嘵","두려워할 효","효","garrulous; disturbed; restless (15)"],
["褕","꿩의 깃으로 꾸민 옷 유","유","쾌자 유","shirt (15)"],
["澗","산골물 간","간","brook","mountain stream (15)"],
["僇","욕보일 륙","륙","humiliate; treat with contempt (13)"],
["鶩","집오리 목","목","duck (20)"],
["璂","피변 꾸미개 기","기","jade ornament (15)"],
["嘶","말 울 시","시","목 쉴 시","울부짖을 시","neighing of a horse; gravel voiced","husky throated (15)"],
["僈","얕볼 만","만","negligent","remiss (13)"],
["鍮","놋쇠 유","유","brass (17)"],
["嘷","짖을 호","호","외칠 호","the roaring of wild beasts; to howl","to wail","to bawl (15)"],
["敝","옷 해질 폐","폐","버릴 폐","무너질 폐","실패할 폐","활줌통 폐","break","destroy; broken","tattered (12)"],
["僉","다 첨","첨","all","together","unanimous (13)"],
["璃","유리 리","리","glass (15)"],
["鍰","무게 단위 환","환","measure; money","coins (17)"],
["鶬","왜가리 청","청","oriole (21)"],
["敞","높을 창","창","roomy","spacious","open","broad (12)"],
["褘","휘장 위","위","아름다울 위","ceremonial gowns of a queen (15)"],
["嘸","분명하지 않을 무","무","모호한 모양 무","unclear; an expletive (15)"],
["僊","춤출 선","선","Taoist super-being","transcendent","immortal (13)"],
["僋","늘어날 탐","탐","어리석을 탐(13)"],
["嘹","울 료","료","used describe clarity of voice; resonant (15)"],
["褚","주머니 저","저","쌓을 저","옷에 솜 둘 저","bag","valise; stuff","pad; surname (15)"],
["璆","옥경쇠 구","구","beautiful jade; tinkle (15)"],
["嘺","입 비뚤러질 교","교","(Cant.) coincidental (15)"],
["嘻","웃을 희","희","mirthful","happy; interjection (15)"],
["璇","아름다운옥 선","선","beautiful jade; star (15)"],
["敡","업신여길 이","이","쉽게 말할 이","rebuke (12)"],
["働","궁닐 동","동","labor; work (13)"],
["嬈","예쁜체 할 요","요","graceful","charming","fascinating (15)"],
["嬉","즐길 희","희","enjoy; play","amuse oneself (15)"],
["敢","감히 감","감","구태여 감","날랠 감","용맹할 감","to dare","venture; bold","brave (12)"],
["僎","향음 주인 돕는 사람 준","준","collect (14)"],
["鶯","꾀꼬리 앵","앵","oriole","green finch; Sylvia species (various) (21)"],
["嘽","헐떡거릴 탄","탄","기꺼워할 탄","많을 탄","pant; many (15)"],
["璉","호련 련","련","a vessel used hold grain offerings (15)"],
["散","흩을 산","산","scatter","disperse","break up (12)"],
["像","형상 상","상","a picture","image","figure; to resemble (14)"],
["鍵","열쇠 건","건","수레 굴대 건","door bolt","lock bolt; key (17)"],
["秸","볏짚 갈","갈","짚 고갱이 갈","stalks of millet","corn (11)"],
["璊","붉은 옥 문","문","reddish (15)"],
["嬋","고울 선","선","beautiful","lovely","pretty","graceful (15)"],
["嘿","고요할 묵","묵","말 아니할 묵","be silent","be quiet (15)"],
["僑","높을 교","교","sojourn","lodge (14)"],
["嬌","아리따울 교","교","seductive and loveable; tender (15)"],
["澡","씻을 조","조","wash","bathe (16)"],
["璋","끝의 반을 깎아 뾰족하게 한 홀 장","장","jade plaything; jade ornament (15)"],
["敦","쪼을 퇴","퇴","모을 퇴","다스릴 퇴","끊을 퇴","곱송거릴 퇴","성낼 퇴","esteem; honest","candid","sincere (12)"],
["鶴","두루미 학","학","crane; Grus species (various) (21)"],
["顆","낟알 과","과","grain","kernel (17)"],
["移","옮길 이","이","모낼 이","변할 이","change place","shift; move about (11)"],
["澣","빨 한","한","cleanse","wash (16)"],
["澤","못 택","택","진펄 택","진액 택","윤택할 택","비와 이슬 택","marsh","swamp; grace","brilliance; damp","moist; fertile (16)"],
["僕","종 복","복","slave","servant","I (14)"],
["僖","기쁠 희","희","joy","gladness","delight; surname (14)"],
["鍼","바늘 침","침","침 침","needle","pin","tack; prick; inject (17)"],
["鶸","댓닭 약","약","투계 약","light yellowish-green (21)"],
["鍾","술병 종","종","cup","glass","goblet; surname (17)"],
["敬","공경할 경","경","respect","honor; respectfully (13)"],
["澧","강 이름 례","례","river in northern Hunan province (16)"],
["鶹","올빼미 류","류","the owl (21)"],
["顋","뺨 시","시","볼 시","lower part of face; jaw; gills of a fish (18)"],
["題","이마 제","제","forehead; title","headline; theme (18)"],
["敭","오를 양","양","scatter","spread; praise; raise (12)"],
["澨","물가 서","서","shore; river in Hubei (16)"],
["褧","홑옷 경","경","unlined clothes; light overcoat (16)"],
["僚","동료 료","료","companion","colleague; officials; bureaucracy; a pretty face (14)"],
["鶻","송골매 골","골","a kind of pigeon; falcon (21)"],
["額","이마 액","액","수량 액","forehead; tablet","plaque; fixed (18)"],
["嬖","사랑할 폐","폐","윗사람으로부터 사랑받을 폐","벼슬이름 폐","favorite; a minion (16)"],
["顎","턱 악","악","jaw (18)"],
["鹿","사슴 녹","녹","deer; surname; KangXi radical 198 (11)"],
["顏","얼굴 안","안","색채 안","face","facial appearance (18)"],
["褪","옷 벗을 퇴","퇴","꽃빛 옅어질 퇴","strip","undress; fall off; fade (16)"],
["論","말할 논","논","debate; discuss; discourse (15)"],
["数","빽빽할 촉","촉","number; several","count; fate (13)"],
["僝","욕할 잔","잔","to revile; to abuse (14)"],
["褫","옷 빼앗을 치","치","벗을 치","넋 잃을 치","strip","tear off","undress (16)"],
["嬙","궁녀 장","장","lady (16)"],
["壟","밭두둑 농","농","grave","mound; ridge in field (19)"],
["鶿","가마우지 자","자","cormorant (21)"],
["澮","봇도랑 회","회","irrigation ditch","trench; river (16)"],
["弄","희롱할 농","농","do","play or fiddle with; alley (7)"],
["璘","옥빛 린","린","luster of jade (16)"],
["敲","두드릴 교","교","strike","beat","pound","hammer; rap (14)"],
["僞","거짓 위","위","속일 위","FALSE","counterfeit","bogus (14)"],
["顒","우러를 옹","옹","solemn","large","grand","majestic (18)"],
["嬛","산뜻할 현","현","apt","clever; sycophant","flatterer (16)"],
["顓","어리석을 전","전","오로지 전","good","honest; simple; respectful (18)"],
["籠","대그릇 농","농","cage; cage-like basket (22)"],
["整","가지런할 정","정","orderly","neat","tidy; whole (15)"],
["聾","귀머거리 농","농","deaf (22)"],
["菁","부추꽃 청","청","flower of leek family; turnip (14)"],
["澱","찌끼 전","전","물 고일 전","sediment","dregs","precipitate (16)"],
["敵","대적할 적","적","원수 적","무리 적","막을 적","enemy","foe","rival; resist (15)"],
["牢","가축우리 뇌","뇌","prison; stable","pen; secure (7)"],
["璜","서옥 황","황","a semicircular jade ornament used as a pendant (16)"],
["褰","바지 건","건","걷을 건","발 걷을 건","underclothing; to pick up one's skirts (16)"],
["磊","돌이 많이 쌓인 모양 뇌","뇌","pile of rocks or stones; great (15)"],
["僣","교활할 참","참","assume","usurp (14)"],
["敷","펼 부","부","spread","diffuse; apply","paint (15)"],
["橄","감람나무 감","감","olive (16)"],
["賂","뇌물 뇌","뇌","bribe; give present (13)"],
["僤","도타울 탄","탄","빠를 탄","sincere (14)"],
["璞","옥덩어리 박","박","unpolished gem","uncarved gem (16)"],
["數","헤아릴 수","수","수죄할 수","몇 수","이치 수","number; several; count; fate (15)"],
["雷","우뢰 뇌","뇌","thunder (13)"],
["菅","골풀 관","관","coarse grass","themedia forskali (14)"],
["璟","옥 빛 경","경","luster of gem (16)"],
["僥","거짓 요","요","난장이 요","be lucky; by chance","by luck (14)"],
["願","하고자할 원","원","바랄 원","생각할 원","desire","want; wish; ambition (19)"],
["壘","진 누","누","rampart","military wall (18)"],
["橇","진흙 썰매 취","취","a sledge for transportation (16)"],
["僦","임금 추","추","고용할 추","to heir; to rent (14)"],
["澶","물이름 전","전","still water","placid","tranquil (16)"],
["顙","이마 상","상","the forehead; to kowtow (19)"],
["屢","(빛이 들어오는)창 누","누","frequently","often","again and again (14)"],
["璡","옥돌 진","진","jade look alike stone (16)"],
["顚","이마 전","전","엎드러질 전","top","peak","summit; upset (19)"],
["怍","부끄러울 작","작","to be ashamed (8)"],
["橈","노 요","요","bent or twisted piece of wood; a paddle (16)"],
["僧","중 승","승","Buddhist priest","monk; san of Sanskrit sangha (14)"],
["樓","다락 누","누","building of two or more stories (15)"],
["褶","덧옷 첩","첩","겹옷 첩","pleat","crease","wrinkles (17)"],
["僨","넘어질 분","분","ruin","cause fail; overthrown (14)"],
["淚","눈물 누","누","tears; weep","cry (11)"],
["僩","노할 한","한","courageous; martial; dignified (14)"],
["璣","구슬 기","기","pearl that is not quite round (16)"],
["怏","원망할 앙","앙","discontented","dispirited","sad (8)"],
["漏","샐 누","누","leak","drip; funnel; hour glass (14)"],
["褸","남루할 루","루","lapel","collar; tattered","threadbare (17)"],
["菉","조개풀 록","록","the green bamboo; greenish bamboo (14)"],
["敾","글잘쓸 선","선","to rule (16)"],
["澹","맑을 담","담","calm","quiet","tranquil (16)"],
["橋","다리 교","교","bridge; beam","crosspiece (16)"],
["僪","해무리 결","결","미칠 결(14)"],
["累","포갤 누","누","tired; implicate","involve; bother (11)"],
["踆","그칠 준","준","squat (14)"],
["菊","국화 국","국","chrysanthemum (14)"],
["璥","경옥 경","경","a kind of jade (17)"],
["類","무리 류","류","class","group","kind","category (19)"],
["縷","자세할 누","누","thread; detailed","precise (17)"],
["僬","밝게 살필 초","초","clever; alert in mind pigmies (14)"],
["褺","겹옷 첩","첩","dirty","ragged; undress","to treat with irreverence (17)"],
["怒","성낼 노","노","anger","rage","passion; angry (9)"],
["陋","줍을 누","누","narrow; crude","coarse; ugly (9)"],
["僭","참람할 참","참","assume","usurp (14)"],
["菌","버섯 균","균","mushroom; germ","microbe (14)"],
["璧","동근 옥 벽","벽","piece of jade with hole in it (17)"],
["勒","굴레 늑","늑","strangle","tighten (11)"],
["褻","더러울 설","설","dirty","ragged; slight","insult","treat with disrespect (17)"],
["璨","빛날 찬","찬","gems; luster of gems; lustrous (17)"],
["怔","황겁할 정","정","가슴 두근두근할 정","a disease resembling neurosis (8)"],
["踉","뛸 량","량","hop","jump; hurriedly","urgently (14)"],
["僮","아이 동","동","page","boy servant (14)"],
["肋","갈비대 늑","늑","ribs; chest (6)"],
["怕","두려울 파","파","to fear","be afraid of; apprehensive (8)"],
["橐","전대 탁","탁","자루 탁","도가니 탁","공잇소리 탁","짐승이름 탁","a sack","a bag opening at both ends (16)"],
["嬪","아내 빈","빈","court lady; palace maid (17)"],
["凜","찰 늠","늠","shiver with cold or fear","fearful (15)"],
["怖","두려울 포","포","놀래킬 포","terror","fear; frighten; terrified (8)"],
["璪","면루관 드림 옥 조","조","pearl pendants on coronet (17)"],
["僰","오랑캐 북","북","ancient aboriginal tribe (14)"],
["凌","얼음 능","능","pure; virtuous; insult; maltreat (10)"],
["怗","고요할 첩","첩","observant; peaceful; submissive (8)"],
["橒","나무 무늬 운","운","wood streaks (16)"],
["顥","클 호","호","빛나는 모양 호","luminous; white","hoary (21)"],
["菑","따비밭 치","치","to weed grass; land which has been under cultivation for one year (14)"],
["璫","귀고리 당","당","pendant ornaments","earrings (17)"],
["嬭","젖 내","내","milk; suckle; breasts (17)"],
["稜","모질 능","능","corner","edge","angle; square block (13)"],
["怙","믿을 호","호","rely on","presume on; persist in (8)"],
["顦","야윌 초","초","be worn-out","emaciated","haggard (21)"],
["綾","무늬 놓은 비단 능","능","thin silk","damask silk (14)"],
["菓","과일 과","과","fruits","nuts","berries (14)"],
["踏","떨어지는 소리 답","답","step on","trample","tread on","walk (15)"],
["顧","돌아볼 고","고","look back; look at; look after (21)"],
["菱","마름 능","능","water-chestnut","water caltrop (14)"],
["菔","무 복","복","turnip (14)"],
["僵","엎드러질 강","강","쓰러질 강","stiff and motionless","stock still (15)"],
["嬰","어릴 영","영","더할 영","머리치장할 영","물요괴 영","baby","infant; bother (17)"],
["陵","능 능","능","hill","mound; mausoleum (11)"],
["嗃","성낸 소리 효","효","to flute (13)"],
["踐","밟을 천","천","trample","tread upon","walk on (15)"],
["怛","깜짝 놀랄 달","달","grieved","saddened; worried (8)"],
["讀","구절 두","두","read","study; pronounce (22)"],
["環","고리 환","환","jade ring or bracelet; ring (17)"],
["菖","창포 창","창","iris","sweet flag","calamus (14)"],
["怜","영리할 령","령","pity","sympathize (8)"],
["橘","귤나무 귤","귤","orange","tangerine (16)"],
["僷","얼굴 예쁠 엽","엽","펴지 않을 엽(14)"],
["嬲","샘낼 뇨","뇨","frolic","play with; flirt with; (Cant.) angry (17)"],
["嗄","목 쉴 애","애","hoarse of voice (13)"],
["拏","붙잡을 라","라","take; bring; grasp","hold; arrest (8)"],
["嗅","맡을 후","후","smell","scent","sniff; olfactive (13)"],
["顫","사지 떨릴 전","전","머리 비뚤이 전","shiver","tremble; trembling (22)"],
["思","생각할 사","사","think","consider","ponder; final particle (9)"],
["橙","귤 증","증","orange (16)"],
["樂","즐거울 락","락","happy","glad; enjoyable; music (15)"],
["嗆","새 먹을 창","창","choke by smoke; irritates nose (13)"],
["菘","배추 숭","숭","celery","cabbage (14)"],
["璲","서옥 수","수","패옥 수","pendant girdle ornament (17)"],
["橚","길고 꼿꼿할 숙","숙","tall tree (16)"],
["價","값 가","가","price","value (15)"],
["嬴","성씨 영","영","풀어낼 영","가득할 영","아름다울 영","to win; to have a surplus; surname (16)"],
["諾","대답할 락","락","promise; assent","approve (16)"],
["禀","여쭐 품","품","사뢸 품","받을 품","성품 품","report to","petition (13)"],
["踔","우뚝 설 탁","탁","앙감질 탁","to get ahead; to stride; to excel (15)"],
["嗇","아낄 색","색","miserly","thrifty","stingy; stopped up","constipated (13)"],
["丹","붉을 란","란","cinnabar (native HgS); vermilion (artificial HgS used as pigment) (4)"],
["怠","게으를 태","태","느릴 태","거만할 태","idle","remiss","negligent; neglect (9)"],
["禁","금할 금","금","restrict","prohibit","forbid (13)"],
["僻","피할 피","피","out-of-the-way","remote; unorthodox (15)"],
["嗈","기러기 짝지어 울 옹","옹","oh (13)"],
["寧","편안할 령","령","repose","serenity","peace; peaceful (14)"],
["顯","나타날 현","현","밝을 현","통달할 현","높을 현","manifest","display; evident","clear (23)"],
["璵","보배옥 여","여","beautiful jade (18)"],
["怡","기쁠 이","이","즐거울 이","harmony; pleasure","joy; be glad (8)"],
["嗉","멀떠구니 소","소","bird's crop; wine pot (13)"],
["怒","성냉 로","로","anger","rage","passion; angry (8)"],
["菜","나물 채","채","vegetables; dish","order; food (14)"],
["顰","찡그릴 빈","빈","frown","knit brows; with knitted (24)"],
["率","헤아릴 률","률","to lead; ratio; rate; limit (11)"],
["機","틀 기","기","machine; moment","chance (16)"],
["顱","머리뼈 로","로","skull (25)"],
["異","다를 리","리","different","unusual","strange (12)"],
["僾","어렴풋할 애","애","like (15)"],
["錄","사실할 려","려","copy","write down","record (16)"],
["菟","고을 이름 도","도","dodder; creeper (14)"],
["急","급할 급","급","quick","quickly; urgent","pressing (9)"],
["橡","상수리나무 상","상","chestnut oak; rubber tree; rubber (16)"],
["僿","잘게 부슬 사","사","small","minute; lacking sincerity (15)"],
["顳","귀밑 뼈 섭","섭","the temporal bone (27)"],
["磻","강이름 번","번","a tributary of the Wei river in Shanxi (17)"],
["怦","맘 급할 평","평","칼칼할 평","eager","ardent","impulsive; anxious (8)"],
["菠","시금치 파","파","spinach and similar greens (14)"],
["橢","둥글길죽할 타","타","수레통 가운데그릇 타","길죽한 그릇 타","oval-shaped","elliptical","tubular (16)"],
["嗎","꾸짖을 마","마","final interrogative particle (13)"],
["顴","광대뼈 관","관","cheek bones (27)"],
["便","똥오줌 변","변","convenience","ease; expedient (9)"],
["鵂","수리부엉이 휴","휴","horned owl","scops chinensis; bird of ill omen (17)"],
["菡","연봉오리 함","함","buds","lotus buds (14)"],
["錆","자세할 청","청","the color of a mineral (16)"],
["性","성품 성","성","마음 성","바탕 성","색욕 성","nature","character","sex (8)"],
["復","다시 부","부","return; repeat; repeatedly (12)"],
["嗐","하품할 해","해","alas! (13)"],
["踝","복사뼈 과","과","ankle (15)"],
["怨","원망할 원","원","원수 원","분낼 원","hatred","enmity","resentment (9)"],
["嬾","게으를 란","란","lazy","languid","listless (19)"],
["不","아닐 불","불","no","not; un-; negative prefix (4)"],
["嗑","잔말할 합","합","웃는 소리 합","입 다물 합","괘 이름 합","eat seeds; reproach; loquacious (13)"],
["鵄","솔개 치","치","variant of 鴟 U+9D1F","kite","horned owl; wine cups (17)"],
["踞","걸터앉을 거","거","crouch","squat; sit","occupy (15)"],
["璽","도장 새","새","imperial signet","royal signet (19)"],
["禊","계제 계","계","semi-annual ceremony of purification (14)"],
["怩","부끄러워할 니","니","shy","timid","bashful; look ashamed (8)"],
["嬿","아름다울 연","연","lovely (19)"],
["泌","샘물 흐르는 모양 비","비","to seep out","excrete (8)"],
["踟","머뭇거릴 지","지","hesitate","undecided; embarrassed (15)"],
["禋","제사지낼 인","인","offer sacrifice; sacrifice (14)"],
["怪","기이할 괴","괴","strange","unusual","peculiar (8)"],
["數","자주 삭","삭","number; several; count; fate (15)"],
["璿","아름다운옥 선","선","fine jade (18)"],
["橦","나무이름 동","동","tree (16)"],
["索","동아줄 삭","삭","large rope","cable; rules","laws (10)"],
["禍","재화 화","화","misfortune","calamity","disaster (14)"],
["嗓","목구멍 상","상","voice; throat (13)"],
["參","석 삼","삼","take part in","intervene (11)"],
["嗔","성낼 진","진","be angry at","scold","rebuke (13)"],
["禎","상서로울 정","정","lucky","auspicious","good omen (14)"],
["塞","막힐 색","색","stop up","block. seal","cork (13)"],
["踣","넘어질 부","부","to stumble and fall prone stiff in death (15)"],
["福","복 복","복","happiness","good fortune","blessing (14)"],
["省","덜 생","생","province; save","economize (9)"],
["怯","무서워할 겁","겁","겁낼 겁","으를 겁","lacking in courage","afraid (8)"],
["葉","고을이름 섭","섭","leaf","petal; page of book; period (15)"],
["橫","가로 횡","횡","across","horizontal","lateral (16)"],
["錏","투구의 목가림 아","아","soft steel; ammonium (16)"],
["菩","보살 보","보","herb","aromatic plant (14)"],
["說","달랠 세","세","쉴 세","speak","say","talk; scold","upbraid (14)"],
["錐","송곳 추","추","gimlet","awl","drill","auger; bore (16)"],
["殺","내릴 쇄","쇄","감할 쇄","빠를 쇄","kill","slaughter","murder; hurt (11)"],
["菫","오랑캐 꽃 근","근","celery","aconite (14)"],
["嗙","웃을 방","방","boast (13)"],
["辰","날 신","신","early morning (7)"],
["怳","멍할 황","황","wild","mad; flurried (8)"],
["禔","복 제","제","happiness; peace; good fortune (14)"],
["攀","더위잡을 반","반","climb; pull; hang on to (19)"],
["嗚","탄식할 오","오","sound of crying","sobbing (13)"],
["沈","성 심","심","sink","submerge; addicted to; name (7)"],
["踩","뛸 채","채","step on (15)"],
["嗛","부족히 여길 겸","겸","겸손 겸","입에 밥 물고 있을 겸","pouch; hold; content (13)"],
["拾","十의 갖은자","자","pick up","collect","tidy up; ten (9)"],
["踪","자취 종","종","footprints","traces","tracks (15)"],
["嗜","즐길 기","기","be fond of","have weakness for (13)"],
["若","땅이름 야","야","지혜(범어의 음역) 야","if","supposing","assuming; similar (11)"],
["華","꽃 화","화","flowery; illustrious; Chinese (14)"],
["錕","붉은 쇠 곤","곤","ancient treasured sword (16)"],
["嗝","딸꾹질할 격","격","꿩 울 격","cackling of fowls to gag","to vomit (13)"],
["掠","노략질할 약","약","rob","ransack","plunder; pass by (11)"],
["攄","펼칠 터","터","흩을 터","날칠 터","비길 터","spread","disperse; vent","set forth (18)"],
["鵑","접동새 견","견","두견새 견","뻐꾹새 견","cuckoo (18)"],
["菰","향초 고","고","wild rice; zizania latifolia (14)"],
["嗞","탄식할 자","자","to consult about","to plan; to report in writing to an equal; a despatch (13)"],
["嗟","탄식할 차","차","sigh","alas (13)"],
["略","간략할 약","약","노략질할 약","잔꾀 약","approximately","roughly; outline (11)"],
["錘","저울눈 추","추","마치 추","저울 추","balance weight on scale; hammer (16)"],
["鵓","집비둘기 발","발","species of pigeon (18)"],
["菲","엷을 비","비","fragrant","luxuriant; the Philippines (14)"],
["橴","땅이름 자","자","place name (15)"],
["潁","물 이름 영","영","성씨 영","river in Anhui (15)"],
["亮","밝을 량","량","임금의 상중 량","bright","brilliant","radiant","light (9)"],
["攇","비길 헌","헌","물건 맬 헌(19)"],
["錙","저울눈 치","치","조금 치","8 oz; an ancient unit of weight (16)"],
["裀","요 인","인","mat","coverlet; underwear (12)"],
["橵","산자 산","산","wood placed under roof tiles (16)"],
["嗡","소 울음소리 옹","옹","벌레소리 옹","sound of flying bees","airplanes (13)"],
["兩","두 량","량","two","both","pair","couple; ounce (8)"],
["錚","쇳소리 쟁그렁거릴 쟁","쟁","징 쟁","clanging sound; small gong (16)"],
["裁","마름질할 재","재","헤아릴 재","결단할 재","cut out; decrease (12)"],
["菴","암자 암","암","small Buddhist monastery (14)"],
["凉","서늘할 량","량","cool","cold; disheartened (10)"],
["踰","넘을 유","유","exceed","transgress; cross over (16)"],
["裂","찢을 렬","렬","split","crack","break open; rend (12)"],
["嗣","이을 사","사","to connect","inherit; descendants","heirs (13)"],
["梁","들보 량","량","다리 량","bridge; beam; rafters; surname (11)"],
["踱","맨발로 걸을 탁","탁","아금막기할 탁","stroll","pace","walk slowly (16)"],
["嗤","비웃을 치","치","냉소 치","laugh at","ridicule","sneer; snort (13)"],
["菶","풀 무성할 봉","봉","species of water plant; flower (14)"],
["糧","양식 량","량","food","grain","provisions (18)"],
["良","어질 량","량","좋을 량","남편 량","good","virtuous","respectable (7)"],
["錞","사발종 순","순","instrument (16)"],
["怾","산 이름 기","기","mountain name (8)"],
["諒","헤아릴 량","량","excuse","forgive; guess","presume (15)"],
["菹","김치 저","저","푸서리 저","salted or pickled vegetables (14)"],
["錟","긴 창 담","담","long spear (16)"],
["踴","뛸 용","용","leap","jump (16)"],
["量","헤아릴 량","량","용량 량","measure","quantity","capacity (12)"],
["踵","발꿈치 종","종","heel; follow; visit","call on (16)"],
["錠","촛대 정","정","spindle","slab","cake","tablet (16)"],
["勵","힘쓸 려","려","가다듬을 려","strive; encourage (17)"],
["鵜","접동새 제","제","pelican (18)"],
["踶","찰 제","제","paw (16)"],
["錡","세발 가마 의","의","쇠뇌틀 의","휘뚝거릴 의","a kind of pen; a kind of chisel (16)"],
["嗩","뿔나팔 쇄","쇄","flute-like musical instrument (13)"],
["錢","돈 전","전","가래 전","money","currency","coins (16)"],
["鵝","거위 아","아","goose (18)"],
["攏","누를 롱","롱","collect","bring together (19)"],
["裊","끄을 뇨","뇨","curling upwards; wavering gently (13)"],
["橿","참죽나무 강","강","호미자루 강","사닥다리 강","억셀 강","a kind of oak (17)"],
["錣","물미 철","철","armor neckplates (16)"],
["踹","발꿈치 단","단","trample","tread on","kick; to crush (16)"],
["嗭","땅 이름 짓","짓","place name (13)"],
["錤","호미 기","기","hoe (16)"],
["鵠","고니 혹","혹","target (18)"],
["禦","막을 어","어","그칠 어","defend","resist","hold out against (16)"],
["禧","복 희","희","happiness; congratulations (17)"],
["鵡","앵무새 무","무","species of parrot (18)"],
["裍","걷어 올릴 곤","곤","a border or band on the edge of a dress (13)"],
["潏","물 흐를 율","율","fareshare reclaimed from river (15)"],
["傀","클 괴","괴","great","gigantic; puppet (12)"],
["錦","비단 금","금","brocade","tapestry; embroidered (16)"],
["禨","조짐 기","기","pray for good fortune (17)"],
["攔","막을 란","란","obstruct","impede","bar","hinder (20)"],
["踽","타달거릴 우","우","to walk alone; self-reliant (16)"],
["裏","속 리","리","inside","interior","within (13)"],
["潑","뿌릴 발","발","pour","splash","water","sprinkle; violent","malignant (15)"],
["錨","닻 묘","묘","anchor (17)"],
["禪","봉선 선","선","meditation","contemplation (dhyana); to level ground for altar; abdicate (17)"],
["傃","향할 소","소","떳떳할 소(12)"],
["禫","담제 담","담","sacrifice offered at the end of the three years' mourning the death of a parent (17)"],
["錪","솥 전","전","쇠 전(16)"],
["裒","모을 부","부","collect","gather","assemble; praise (13)"],
["攘","물리칠 양","양","빼앗을 양","seize","take by force; repel (20)"],
["潔","맑을 결","결","정결할 결","조촐할 결","clean","purify","pure (15)"],
["攙","찌를 참","참","to give a helping hand; to support","hold up (20)"],
["傅","스승 부","부","tutor","teacher; assist; surname (12)"],
["嫁","시집갈 가","가","to marry","give a daughter in marriage (13)"],
["錫","주석 석","석","tin","stannum; bestow","confer (16)"],
["嫂","형수 수","수","sister-in-law","elder brother's wife (13)"],
["裔","옷 뒷자락 예","예","변방 예","후손 예","progeny","descendants","posterity (13)"],
["禮","예도 례","례","social custom; manners; courtesy; rites (18)"],
["瑀","옥돌 우","우","stone resembling jade; agate (13)"],
["鵩","새 이름 복","복","owl-like bird; buzzard; Otus scops (19)"],
["裕","넉넉할 유","유","너그러울 유","abundant","rich","plentiful (13)"],
["瑁","서옥 모","모","fine piece of jade (13)"],
["嗶","울 필","필","잔말할 필","used in transliterations (14)"],
["嫄","여자이름 원","원","an ancient emperor's mother's name","Qiang Yuan","consort to Gu (13)"],
["錮","땜질할 고","고","run metal into cracks; confine (16)"],
["禰","아비사당 니","니","one's deceased father (19)"],
["錯","섞일 착","착","error","blunder","mistake","wrong (16)"],
["禱","빌 도","도","pray; entreat","beg","plead; prayer (19)"],
["攝","가질 녑","녑","고요할 녑","take in","absorb; act as deputy; administer","assist (21)"],
["潘","넘칠 번","번","surname; water in which rice has been rinsed; a river that flows into the Han (15)"],
["嗷","여럿이 걱정할 오","오","loud clamor; sound of wailing (14)"],
["裘","갓옷 구","구","fur garments; surname (13)"],
["瑄","도리옥 선","선","a ornamental piece of jade several inches across with a hole in the center (13)"],
["潙","물이름 규","규","river in Shanxi (15)"],
["嗸","슬픈 소리 오","오","same as 嗷 U+55F7","noisy (14)"],
["鵬","대붕새 붕","붕","fabulous bird of enormous size (19)"],
["裙","치마 군","군","skirt","apron","petticoat (13)"],
["禳","푸닥거리할 양","양","pray or sacrifice","exorcise (22)"],
["嗹","길게 말할 련","련","chatter (14)"],
["纁","분홍빛 훈","훈","shade of red; light red (20)"],
["嗺","입 옴칠 최","최","급히 마실 최(14)"],
["潛","잠길 잠","잠","hide; hidden","secret","latent (15)"],
["傌","욕할 마","마","to curse","to revile","to abuse; to scold (12)"],
["禴","종묘의 여름제사 약","약","sacrifice (22)"],
["頀","구할 호","호","지킬 호","music (23)"],
["纂","모을 찬","찬","edit","compile; topknot","chignon (20)"],
["嗻","말 많을 차","차","to screen. loquacious (14)"],
["傍","마지못할 팽","팽","by side of","beside","near","close (12)"],
["嫈","예쁠 앵","앵","lady (13)"],
["潝","빨리 흐르는 소리 흡","흡","agree (15)"],
["頁","머리 혈","혈","마리 혈","페이지(page) 혈","page","sheet","leaf; rad. no. 181 (9)"],
["鵯","갈가마귀 필","필","Pycnonotus species (various) (19)"],
["攢","모일 찬","찬","save","hoard (22)"],
["嫉","미워할 질","질","jealousy; be jealous of (13)"],
["傎","엎드러질 전","전","topple (12)"],
["鵰","수리 조","조","eagle","vulture; Aquila species (various) (19)"],
["頂","이마 정","정","꼭대기 정","관 꼭대기 치장하는 장식 정","top","peak; carry on head; very (11)"],
["補","기울 보","보","mend","patch","fix","repair","restore (13)"],
["攣","걸릴 련","련","tangled; entwined; crooked (22)"],
["潞","강 이름 로","로","river in northern china (15)"],
["嗽","기침할 수","수","입가실 수","cough","gargle","clear throat (14)"],
["攤","열 탄","탄","펼칠 탄","spread out","open; apportion (22)"],
["嗾","부추길 주","주","to set a dog on; incite","instigate (14)"],
["裝","차려 입을 장","장","dress","clothes; decorate; fill (13)"],
["嫋","가냘플 약","약","slender and delicate (13)"],
["頃","반걸음 규","규","a moment; a measure of area equal to 100 mu or 6.7 hectares; to lean (11)"],
["潟","개펄 석","석","land impregnated with salt from the tide (15)"],
["嫌","싫어할 혐","혐","의심할 혐","유쾌하지 않을 혐","hate","detest; suspect; criticize (13)"],
["嗿","여럿이 먹는 소리 탐","탐","gobble (14)"],
["傑","호걸 걸","걸","준걸 걸","뛰어날 걸","거만할 걸","떡잎 걸","hero; outstanding","remarkable (12)"],
["鵲","까치 작","작","magpie; Pica species (various) (19)"],
["禹","하우씨 우","우","성씨 우","느릴 우","legendary hsia dynasty founder (9)"],
["傒","묶을 혜","혜","personal name; servant (12)"],
["項","목덜미 항","항","클 항","항목 항","neck","nape of neck; sum; funds (12)"],
["裟","가사 사","사","a cassock or robe of a monk (13)"],
["禺","땅 이름 옹","옹","district; mountain in Zhejiang (9)"],
["瑋","위옥 위","위","노리개 위","type of jade; rare","valuable (13)"],
["纇","실 마디 뢰","뢰","knot; blemish","flaw","wicked (21)"],
["纈","홀치기염색 힐","힐","patterned silk; tie knot (21)"],
["潢","웅덩이 황","황","expanse of water","lake","pond (15)"],
["順","좇을 순","순","부드러울 순","차례 순","성씨 순","obey","submit to","go along with (12)"],
["离","산신 리","리","rare beast; strange; elegant (11)"],
["傔","겸종 겸","겸","servant (12)"],
["須","수염 수","수","기다릴 수","must","have to","necessary; moment; whiskers (12)"],
["禽","날짐승 금","금","birds","fowl; surname;; capture (13)"],
["纊","솜 광","광","cotton; silk (21)"],
["潤","젖을 륜","륜","soft","moist; sleek; freshen (15)"],
["禾","벼 화","화","grain still on stalk; rice plant (5)"],
["傖","천할 창","창","vulgar person","country man (12)"],
["鵷","원추새 원","원","co-worker (19)"],
["頉","기를 이","이","cheeks; jaw; chin; rear; nourish (12)"],
["攪","어지러울 교","교","disturb","agitate","stir up (23)"],
["攫","붙잡을 확","확","snatch away","seize; catch with (23)"],
["頊","머리 굽실거릴 욱","욱","별이름 욱","grieved; anxious (13)"],
["續","이을 속","속","continue","carry on; succeed (21)"],
["潦","큰 비 료","료","to flood; a puddle; without care (15)"],
["傘","우산 산","산","umbrella","parasol","parachute (12)"],
["禿","대머리 독","독","bald","bare","stripped (7)"],
["纍","갇힐 류","류","bind","wind about; link","join (21)"],
["攬","잡을 람","람","grasp","take hold of; monopolize (24)"],
["備","깆출 비","비","prepare","ready","perfect (12)"],
["頌","얼굴 용","용","모양 용","laud","acclaim; hymn; ode (13)"],
["傚","본받을 효","효","imitate","mimic (12)"],
["鵻","아롱비둘기 추","추","pigeon; turtle-dove; a hawk (19)"],
["纏","둘릴 전","전","묶을 전","얽을 전","wrap","wind around; tie","bind (21)"],
["頍","고깔비녀 규","규","to raise one's head (13)"],
["嫖","가벼울 표","표","날랠 표","색에 빠질 표","patronize prostitutes","frequent (14)"],
["傛","혁혁할 용","용","몸 굽힐 용(12)"],
["頎","헌걸찰 기","기","tall and slim (13)"],
["裨","도울 비","비","aid","benefit","help; supplement (14)"],
["頏","목 항","항","오르락 내리락할 항","fly down or downward (13)"],
["瑕","티 하","하","flaw in gem; fault","defect (13)"],
["支","가를 지","지","disperse","pay; support; branch (4)"],
["傜","부릴 요","요","별이름 요","a minority tribe (12)"],
["嫗","할미 구","구","old woman","hag (14)"],
["豈","승전악 개","개","how? what? (10)"],
["鵾","댓닭 곤","곤","a bird resembling the crane (19)"],
["預","미리 예","예","참여할 예","prepare","arrange; in advance (13)"],
["更","다시 갱","갱","more","still further","much more (7)"],
["傝","답답할 탑","탑","불안할 탑","어리석을 탑","용렬할 탑(12)"],
["頑","완고할 완","완","완악할 완","obstinate","stubborn; recalcitrant (13)"],
["車","수레 거","거","그물 거","잇몸 거","cart","vehicle; carry in cart (7)"],
["瑗","도리옥 원","원","구멍 큰 둥근 옥 원","large ring of fine jade (13)"],
["潭","깊을 담","담","deep pool","lake; deep","profound (15)"],
["潮","조수 조","조","tide; moist","wet","damp; flow (15)"],
["樀","추녀 적","적","도토마리 적","방망이 적(15)"],
["傞","취하여 춤추는 모양 사","사","unsteady (12)"],
["嫚","업신여길 만","만","scorn","despise; be rude","affront (14)"],
["頒","큰 머리 분","분","confer","bestow; publish","promulgate (13)"],
["賈","장사 고","고","surname; merchant; buy","trade (13)"],
["纓","갓끈 영","영","말 가슴걸이 영","얽힐 영","a chin strap","tassel; to annoy","bother (23)"],
["攲","기울기 기","기","up; uneven (12)"],
["纔","겨우 재","재","비롯할 재","talent","ability; just","only (23)"],
["嫛","유순할 예","예","갓난아이 예","compliant","yielding; easy-going a newborn child (14)"],
["頓","조아릴 둔","둔","pause","stop; bow","kowtow; arrange (13)"],
["滑","어지러울 골","골","slip","slide; slippery","polished (13)"],
["瑙","마노 노","노","agate; cornelian (13)"],
["潯","물가 심","심","steep bank by stream; jiujiang (15)"],
["瑚","산호 호","호","coral; person of virtue (13)"],
["串","곶 곶","곶","string; relatives; conspire (7)"],
["潰","흩어질 궤","궤","flooding river; militarily defeat (15)"],
["裯","홑이불 주","주","coverlet; bedspread (14)"],
["樂","좋아할 요","요","happy","glad; enjoyable; music (15)"],
["句","글귀 귀","귀","sentence (5)"],
["瑛","옥빛 영","영","수정 영","luster of gem; crystal (13)"],
["纖","가늘 섬","섬","fine","delicate; minute; graceful (23)"],
["傢","세간 가","가","stubborn","obstinate","intransigent (12)"],
["頖","풀릴 반","반","Zhou dynasty school; disperse; fall apart (14)"],
["龜","거북 귀","귀","turtle or tortoise; cuckold (16)"],
["瑜","아름다운 옥 유","유","flawless gem or jewel (13)"],
["收","모을 수","수","거둘 수","잡을 수","수레바퀴 수","gather together","collect; harvest (6)"],
["裱","목수건 표","표","to mount maps or scrolls to paste (14)"],
["纘","이을 찬","찬","continue","carry on","succeed (25)"],
["龜","(피부가)틀 균","균","turtle or tortoise; cuckold (16)"],
["頗","비뚤어질 파","파","자못 파","lean one side; very","rather (14)"],
["樅","전나무 종","종","fir tree (15)"],
["嫠","과부 리","리","a widow (14)"],
["契","부족 이름 글","글","deed","contract","bond; engrave (9)"],
["瑞","상서 서","서","felicitous omen; auspicious (13)"],
["攸","곳 유","유","휙 달아날 유","아득할 유","대롱거릴 유","distant","far; adverbial prefix (7)"],
["嫡","정실 적","적","맏아들 적","legal wife","child of legal wife (14)"],
["領","옷깃 령","령","neck; collar; lead","guide (14)"],
["金","성 금","금","gold; metals in general; money (8)"],
["裳","치마 상","상","clothes; skirt; beautiful (14)"],
["莅","다다를 리","리","attend","be present; arrive at (13)"],
["瑟","비파 슬","슬","거문고 슬","깨끗한체할 슬","바람소리 슬","large stringed musical instrument; dignified","massive; sound of wind (13)"],
["改","고칠 개","개","거듭할 개","바꿀 개","change","alter; improve","remodel (7)"],
["喇","나팔 라","라","라마교 라","horn","bugle; lama; final particle (12)"],
["裴","옷 치렁치렁할 배","배","surname; look of a flowing gown (14)"],
["纛","클 깃발 도","도","a banner","a streamer (25)"],
["嫣","생긋생긋 웃을 언","언","charming","fascinating; gay (14)"],
["奈","어찌 나","나","but","how; bear","stand","endure (8)"],
["跂","육발 기","기","creeping","crawling (11)"],
["纜","닻줄 람","람","hawser","heavy-duty rope","cable (27)"],
["攻","칠 공","공","attack","assault; criticize (7)"],
["懶","게으를 나","나","lazy","languid","listless (19)"],
["樊","울 번","번","a railing; a fence an enclosed place (15)"],
["癩","약물 중독 나","나","leprosy","scabies","mange; shoddy (21)"],
["瑣","옥가루 쇄","쇄","가늘 쇄","좀스러울 쇄","대궐문 아로새길 쇄","fragments; trifling","petty; troublesome (14)"],
["潸","눈물흐를 산","산","weep; tears flowing (15)"],
["樋","대 홈 통","통","어름나무 통","tree name (15)"],
["羅","새 그물 나","나","net for catching birds; gauze (19)"],
["莉","말리 리","리","white jasmine (13)"],
["瑤","아름다운 옥 요","요","못 이름 요","precious jade (14)"],
["放","놓을 방","방","put","release","free","liberate (8)"],
["跆","밟을 태","태","trample (12)"],
["政","정사 정","정","바르게 할 정","조세 정","government","political affairs (8)"],
["莊","엄할 장","장","village","hamlet; villa; surname (13)"],
["頞","콧마루 알","알","(nose) bridge (15)"],
["蘿","소나무겨우살이 나","나","type of creeping plant; turnip (25)"],
["裹","쌀 과","과","wrap","bind; encircle","confine (14)"],
["潺","물이 줄줄 흐르는 모양 잔","잔","sound of flowing water (15)"],
["催","재촉할 최","최","핍박할 최","일어날 최","press","urge (13)"],
["螺","소라 나","나","spiral shell; conch; spiral (17)"],
["傭","고를 충","충","가지런할 충","hire","employ","charter; servant (13)"],
["裸","벌거벗을 라","라","털 없는 벌레 라","bare","nude; undress","strip (14)"],
["潼","물 이름 동","동","high","lofty; damp (15)"],
["瑨","아름다운 돌 진","진","jade look alike stone (14)"],
["傮","마칠 조","조","finish","to go around (13)"],
["嫩","언약할 눈","눈","soft","tender","delicate; young (14)"],
["頡","곧은목 힐","힐","fly upward","soar; contest; to rob (15)"],
["邏","돌 나","나","patrol; inspect; watch (23)"],
["傯","바쁠 총","총","곤궁할 총","urgent (13)"],
["樂","즐길 낙","낙","happy","glad; enjoyable; music (15)"],
["莎","향부자 사","사","kind of sedge grass","used anciently for raincoats (13)"],
["瑩","밝을 형","형","lustre of gems; bright","lustrous (15)"],
["潾","맑을 린","린","clear water (15)"],
["製","만들 제","제","make","manufacture; produce (14)"],
["頣","눈들고 볼 신","신","to view others with raised eyes (15)"],
["跋","밟을 발","발","go by foot; epilogue","colophon (12)"],
["跌","넘어질 질","질","stumble","slip","fall down; stamp (12)"],
["樒","침향 밀","밀","a tree whose branches are placed on Buddhist graves (15)"],
["頤","턱 이","이","턱 끄덕거릴 이","기를 이","늙은이 이","괘 이름 이","cheeks; jaw; chin; rear; to nourish (16)"],
["洛","강 이름 낙","낙","river in Shanxi province; city (9)"],
["裾","옷 뒷자락 거","거","lapel","border of garment; skirt (14)"],
["瑪","마노 마","마","agate; cornelian (14)"],
["樓","다락 루","루","building of two or more stories (15)"],
["傲","업신여길 오","오","즐길 오","proud","haughty","overbearing (13)"],
["烙","지질 낙","낙","brand","burn; branding iron (10)"],
["嫮","아름다울 호","호","시기할 호(14)"],
["頦","턱 해","해","chin (15)"],
["跎","미끄러질 타","타","slip","stumble","falter; vacillate (12)"],
["傳","전할 전","전","펼 전","이을 전","주막 전","책 전","summon; propagate","transmit (13)"],
["樔","풀막 소","소","너스레 소(15)"],
["喀","기침할 객","객","토할 객","vomit; used in transliterations (12)"],
["珞","구슬 목걸이 낙","낙","kind of necklace (10)"],
["莒","모시풀 거","거","나라이름 거","고을이름 거","hemp-like plant; taro; herb (13)"],
["傴","구부릴 구","구","humpback; stoop (13)"],
["喁","서로 부를 우","우","the mouth of a fish at the surface of the water","gasping for breath (12)"],
["落","떨어질 낙","낙","fall","drop; net income","surplus (15)"],
["莓","나무딸기 매","매","moss; edible berries (13)"],
["瑭","옥이름 당","당","a kind of jade (14)"],
["債","빚 채","채","debt","loan","liabilities (13)"],
["酪","타락 낙","낙","cream","cheese; koumiss (13)"],
["跏","책상다리할 가","가","sit cross-legged; squat (12)"],
["樗","가죽나무 저","저","Ailanthus glandulosa","a kind of tree useless as timber (15)"],
["喃","재잘거릴 남","남","keep talking","chattering; mumble (12)"],
["駱","낙타 낙","낙","white horse with black mane; camel (16)"],
["瑯","고을 이름 랑","랑","kind of white cornelian (14)"],
["傷","상처 상","상","wound","injury; fall ill from (13)"],
["善","착할 선","선","good","virtuous","charitable","kind (12)"],
["亂","어지러울 난","난","confusion","state of chaos; create chaos","revolt (13)"],
["莖","줄기 경","경","stem","stalk (13)"],
["瑰","슬이름 괴","괴","extraordinary","fabulous; rose (14)"],
["標","표시할 표","표","높은 가지 표","적을 표","나무 끝 표","a mark","symbol","label","sign; standard (15)"],
["瑱","귀막이옥 전","전","옥이름 전","a gem used as ear plug; a jade earring (14)"],
["頫","머리 숙일 부","부","bow; nod; condescend (15)"],
["卵","알 난","난","egg; ovum; roe; spawn (7)"],
["瑲","옥 소리 창","창","tinkling sound tinkling of pendant gems (14)"],
["欄","난간 난","난","railing","balustrade; animal pan (21)"],
["莘","약 이름 신","신","많은 모양 신","나라이름 신","long; numerous; a marsh plant whose root is used for medicine (13)"],
["纮","넓을 굉","굉","string; vast","expansive (7)"],
["瑳","깨끗할 차","차","luster of gem; lustrous","bright (14)"],
["傺","묵을 제","제","to hinder; to detain (13)"],
["祀","제사 사","사","to sacrifice","worship (8)"],
["樛","나무가지 휘어질 규","규","bending branches","bent limbs (15)"],
["喇","말굽히할 랄","랄","horn","bugle; lama; final particle (12)"],
["頭","머리 두","두","head; top; chief","first; boss (16)"],
["爛","문드러질 난","난","rotten","spoiled","decayed (21)"],
["頮","세수할 회","회","wash (16)"],
["跕","미끄러질 접","접","shuffle (12)"],
["祁","성할 기","기","pray; numerous","ample","abundant (8)"],
["傻","약을 사","사","foolish","silly","stupid; an imbecile (13)"],
["喈","새소리 개","개","빠를 개","music; melody (12)"],
["心","마음 심","심","heart; mind","intelligence; soul (4)"],
["蘭","난초 난","난","orchid; elegant","graceful (23)"],
["莚","만연할 연","연","풀 이름 연","bamboo mat; feast","banquet (13)"],
["喉","목구멍 후","후","throat","gullet","larynx; guttural (12)"],
["跖","발바닥 척","척","sole (of the foot) (12)"],
["莛","풀 줄거리 정","정","들보 정","stalks of grasses","etc. (13)"],
["鸞","난새 난","난","fabulous bird (30)"],
["頰","뺨 협","협","천천히 말할 협","cheeks","jaw (16)"],
["喊","소리 함","함","shout","call out","yell; howl; cry (12)"],
["必","반드시 필","필","오로지 필","살필 필","기약 필","surely","most certainly; must (4)"],
["樞","밑둥 추","추","지두리 추","고동 추","북두 첫째 별 추","긴요할 추","달 추","가운데 추","door hinge; pivot; center of power (15)"],
["嵐","남기 남","남","mountain mist","mountain haze (12)"],
["跗","발등 부","부","the instep (12)"],
["莝","여물 좌","좌","to chop straw fine for animals (13)"],
["樟","녹나무 장","장","camphor tree (15)"],
["傽","놀랄 장","장","terror-stricken (13)"],
["喋","재잘거릴 첩","첩","nag; chatter","babble","twitter (12)"],
["濫","퍼질 남","남","flood","overflow; excessive (17)"],
["嫺","우아할 한","한","refined","elegant","gracious (15)"],
["祅","재앙 요","요","calamities","disasters; ormazda (9)"],
["傾","기울 경","경","upset","pour out","overflow (13)"],
["藍","쪽 남","남","blue; indigo plant; surname (20)"],
["莞","빙그레 웃는 모양 완","완","smiling; a kind of aquatic herb (13)"],
["莟","꽃봉오리 함","함","a bud (13)"],
["嫻","우아할 한","한","elegant","refined; skillful (15)"],
["祆","하늘 천","천","Ormazda","god of the Zoroastrians; extended to god of the Manicheans (9)"],
["模","법 모","모","model","standard","pattern; copy (15)"],
["襤","누더기 남","남","ragged","tattered","threadbare (20)"],
["跚","비틀거릴 산","산","stagger","limp (12)"],
["跛","기울어 지게 설 피","피","lame (12)"],
["祇","마침 지","지","only","merely","but (9)"],
["忉","근심할 도","도","grieved; distressed in mind (5)"],
["拉","꺽을 납","납","pull","drag; seize","hold; lengthen (8)"],
["莠","가라지 유","유","추할 유","weeds","tares; undesirable","evil (13)"],
["祈","빌 기","기","pray; entreat","beseech (9)"],
["嫽","외조모 료","료","play with; (Cant.) to provoke (15)"],
["喏","공손히 대답할 야","야","respectful reply of assent to superiors (12)"],
["臘","납향 납","납","year end sacrifice; dried meat (19)"],
["莢","콩 꼬투리 협","협","명협 협","pods of leguminous plants; pods (13)"],
["祉","복 지","지","happiness","blessings","good luck (9)"],
["樣","모양 양","양","모범 양","shape","form","pattern","style (15)"],
["蠟","밀 납","납","wax; candle; waxy","glazed (21)"],
["鴃","왜가리 격","격","때까치 격","shrike; butcherbird (15)"],
["距","며느리 발톱 거","거","지낼 거","뛸 거","겨룰 거","미눌 거","distance; bird's spur (12)"],
["祊","사당문 제사 팽","팽","river in Shandong; space inside (9)"],
["瑽","패옥소리 종","종","tinkling of jade pendants (15)"],
["忌","꺼릴 기","기","jealous","envious; fear (7)"],
["廊","복도 낭","낭","corridor","porch","veranda (13)"],
["鋈","은 올릴 옥","옥","silver plating (15)"],
["喑","소리지를 음","음","안간힘 쓸 음","목 쉴 음","to be dumb; the sobbing of infants (12)"],
["忍","참을 인","인","endure","bear","suffer; forbear (7)"],
["頷","끄덕일 암","암","chin","jowl; give nod (16)"],
["朗","밝을 낭","낭","clear","bright; distinct (10)"],
["跟","발두꿈치 근","근","heel; to follow","accompany; with (13)"],
["瑾","붉은옥 근","근","brilliance of gems; fine jade (15)"],
["鴆","짐새 짐","짐","a bird resembling the secretary falcon (15)"],
["喒","나 잠","잠","a dual pronoun","I","you and me","we two (12)"],
["頸","목 경","경","neck","throat (16)"],
["浪","물결 낭","낭","wave; wasteful","reckless (10)"],
["頹","기울어질 퇴","퇴","사나운 바람 퇴","쇠할 퇴","무너질 퇴","대머리 퇴","ruined","decayed; disintegrate (16)"],
["跡","자취 적","적","search","track","trace (13)"],
["喓","벌레소리 요","요","chirping","buzzing; (Cant.) to call out (12)"],
["狼","이리 낭","낭","wolf (10)"],
["鴇","능에 보","보","bustard; procuress; Otis species (various) (15)"],
["鋌","화살촉 정","정","달음박질할 정","쇳덩이 정","ingots","bars of metal; hurry (15)"],
["喔","닭 우는 소리 악","악","아첨해 웃는 소리 악","descriptive of crying or of crowing (12)"],
["郎","사나이 낭","낭","gentleman","young man; husband (10)"],
["鴈","기러기 안","안","wild goose (15)"],
["頻","자주 빈","빈","frequently","again and again (16)"],
["來","올 내","내","come","coming; return","returning (8)"],
["鴉","갈가마귀 아","아","검을 아","crow","raven; Corvus species (various) (15)"],
["跣","맨발 선","선","bare footed (13)"],
["横","비낄 횡","횡","across (15)"],
["忒","변할 특","특","의심할 특","어기어질 특","excessive; too; very-usually of objectionable things; to err; to mistake; changeable (7)"],
["祐","도울 우","우","다행할 우","divine intervention","protection (10)"],
["冷","차가울 냉","냉","cold","cool; lonely (7)"],
["莨","수크령 랑","랑","herb","Scopolia japonica (13)"],
["跤","발화목 교","교","stumble","fall down; wrestle (13)"],
["鋏","칼 손잡이 협","협","집개 협","tongs","pincers; dagger; sword (15)"],
["樫","떡갈나무 견","견","evergreen oak (15)"],
["喗","입 클 운","운","이빨 더러울 운(12)"],
["勞","수고할 노","노","labor","toil","do manual work (12)"],
["莩","굶어죽을 표","표","membrane lining inside of reed (13)"],
["喘","헐떡거릴 천","천","pant","gasp","breathe heavily (12)"],
["擄","사로잡을 노","노","capture","seize (16)"],
["莪","지칭개 아","아","artemisia (13)"],
["喙","부리 훼","훼","beak","bill","snout; pant (12)"],
["祓","푸닥거리할 불","불","exorcise","remove evil; clean (10)"],
["櫓","큰 방패 노","노","oar","scull; row (19)"],
["莫","저물 모","모","do not","is not","can not; negative (13)"],
["喚","부를 환","환","call","summon; invite; be called (12)"],
["忖","헤아릴 촌","촌","짐작할 촌","guess","suppose","conjecture (6)"],
["祔","합사할 부","부","worship one's ancestors; entomb (10)"],
["爐","화로 노","노","fireplace","stove","oven","furnace (20)"],
["鋒","칼끝 봉","봉","point of spear","sharp point (15)"],
["跨","타넘을 과","과","straddle","bestride","ride; carry (13)"],
["喛","성낼 환","환","근심할 환(12)"],
["志","뜻 지","지","purpose","will","determination (7)"],
["祕","귀신 비","비","mysterious","secret","abstruse (10)"],
["擁","안을 옹","옹","품을 옹","가질 옹","embrace","hug","squeeze; crowd (16)"],
["盧","밥그릇 노","노","cottage","hut; surname; black (16)"],
["喜","기뻐할 희","희","like","love","enjoy; joyful thing (12)"],
["祖","조상 조","조","ancestor","forefather; grandfather (10)"],
["忘","잊을 망","망","forget; neglect; miss","omit (6)"],
["老","늙을 노","노","old","aged; experienced (6)"],
["跪","끓어앉을 궤","궤","kneel (13)"],
["祗","공경할 지","지","respect","look up to","revere (10)"],
["喝","목 쉴 애","애","drink; shout","call out (12)"],
["忙","바쁠 망","망","busy","pressed for time; hustling (7)"],
["跫","발자국 소리 공","공","sound of footsteps (13)"],
["喞","찍찍거릴 직","직","chirping of insects; pump (12)"],
["擄","사로잡을 로","로","capture","seize (16)"],
["蘆","갈대 노","노","rushes","reeds (22)"],
["跬","한발자국 규","규","to step; a stride equal to half a pace (13)"],
["鋗","노구솥 현","현","퉁노구 현","a small basin; rings on a cart of carriage (15)"],
["擅","멋대로 천","천","monopolize; claim; arbitrarily; to dare (16)"],
["喟","한숨쉴 위","위","heave sigh","sigh (12)"],
["虜","포로 노","노","capture","imprison","seize; prison (10)"],
["鴒","할미새 령","령","species of lark; wagtail; Motacilla species (various) (16)"],
["莱","명아주 래","래","goosefoot","weed; fallow field (13)"],
["祚","복 조","조","throne; bless; blessing","happiness (10)"],
["漁","물고기 잡을 어","어","to fish; seize; pursue; surname (14)"],
["路","길 노","노","road","path","street; journey (13)"],
["漂","뜰 표","표","움직일 표","으스스할 표","높이 훨훨 날 표","float","drift; tossed about (14)"],
["擇","가릴 택","택","추릴 택","차별할 택","select","choose","pick out (16)"],
["樵","땔나무 초","초","woodcutter; firewood; gather wood (16)"],
["祛","물리칠 거","거","빌 거","굳셀 거","expel","disperse","exorcise (10)"],
["露","이슬 노","노","dew; bare","open","exposed (20)"],
["祜","복 호","호","blessing","happiness","prosperity (10)"],
["鴕","타조 타","타","ostrich; Struthio camelus (16)"],
["忝","더럽힐 첨","첨","disgraced; ashamed; self-deprecating (7)"],
["袁","옷 치렁거릴 원","원","성씨 원","robe; surname (10)"],
["魯","미련할 노","노","foolish","stupid","rash; vulgar (15)"],
["路","길 로","로","road","path","street; journey (13)"],
["喣","불 후","후","to breathe upon (12)"],
["擉","찌를 착","착","to pierce; to break through (16)"],
["祝","빌 축","축","끊을 축","비로소 축","pray for happiness or blessings (10)"],
["袂","소매 몌","몌","sleeves (10)"],
["鷺","해오라기 노","노","heron","egret (23)"],
["喤","어린아이 울음 황","황","ah; harmony (12)"],
["跱","머뭇거릴 치","치","주저할 치","갖출 치","stop (13)"],
["樸","총생할 복","복","simple","honest; plain; rough (16)"],
["忟","강인할 민","민","(Cant.) impatient","restless (7)"],
["碌","푸른 빛 녹","녹","rough","uneven","rocky; mediocre (13)"],
["漆","옻나무 칠","칠","물이름 칠","varnish","lacquer","paint (14)"],
["忠","충성 충","충","곧을 충","loyalty","devotion","fidelity (7)"],
["神","천신 신","신","영검할 신","신명 신","정신 신","spirit","god","supernatural being (10)"],
["樹","나무 수","수","막을 수","세울 수","심을 수","tree; plant; set up","establish (16)"],
["祿","행복 녹","녹","blessing","happiness","prosperity (13)"],
["樺","자작나무 화","화","type of birch (16)"],
["忡","근심할 충","충","a sad","uneasy countenance (7)"],
["祟","빌미 수","수","evil spirit; evil influence (10)"],
["喦","땅 이름 엽","엽","다툴 엽","talkative (12)"],
["綠","초록빛 녹","녹","green; chlorine (14)"],
["跳","뛸 조","조","jump","leap","vault","bounce; dance (13)"],
["喧","의젓할 훤","훤","lively","noisy; clamor","talk loudly (12)"],
["鋟","새길 침","침","carve (15)"],
["祠","사당 사","사","ancestral temple; offer sacrifice (10)"],
["菉","조개풀 녹","녹","the green bamboo; greenish bamboo (14)"],
["践","밟을 천","천","trample","tread upon","walk on (12)"],
["操","잡을 조","조","conduct","run","control","manage (16)"],
["漉","거를 록","록","filter","strain; wet","dripping (14)"],
["喨","소리 맑을 량","량","wail","cry","yell; neigh (12)"],
["錄","기록할 녹","녹","copy","write down","record (16)"],
["鴛","숫원앙새 원","원","male mandarin duck (Aix galericulata) (16)"],
["樽","술그릇 준","준","goblet; jar","jug; lush (16)"],
["袈","가사 가","가","Buddhist cassock (11)"],
["祢","아비사당 니","니","one's deceased father (10)"],
["擎","들 경","경","lift up","hold up","support (17)"],
["喩","깨우쳐 줄 유","유","알릴 유","비유할 유","metaphor (12)"],
["忤","거스릴 오","오","미워할 오","insubordinate","stubborn; wrong (7)"],
["莻","늦을 늦","늦","late (13)"],
["喪","죽을 상","상","mourning; mourn; funeral (12)"],
["擐","입을 환","환","to put on (16)"],
["喫","마실 끽","끽","eat; drink; suffer","endure","bear (12)"],
["莽","우거질 망","망","thicket","underbrush; poisonous (12)"],
["鴟","솔개 치","치","수알치 치","부엉이 치","말똥구리 치","토란 치","마음대로 날릴 치","올빼미 치","kite","horned owl; wine cups (16)"],
["袋","자루 대","대","pocket","bag","sack","pouch (11)"],
["祥","상서로울 상","상","good luck","good omen; happiness (11)"],
["喬","높을 교","교","tall","lofty; proud","stately (12)"],
["擒","사로잡을 금","금","catch","capture","seize","arrest (16)"],
["喭","조문할 언","언","condole with; coarse (12)"],
["忨","탐할 완","완","사랑할 완(7)"],
["鋤","호미 서","서","hoe; eradicate (15)"],
["莿","풀가시 자","자","thorn (14)"],
["袍","도포 포","포","관디 포","앞섶 포","long gown","robe","cloak (11)"],
["祧","조묘 조","조","an ancestral hall (11)"],
["漏","샐 루","루","leak","drip; funnel; hour glass (14)"],
["單","고을이름 선","선","single","individual","only; lone (12)"],
["票","쪽지 표","표","문서 표","불날릴 표","훌쩍 날 표","날랠 표","slip of paper or bamboo; ticket (11)"],
["擔","질 담","담","carry","bear","undertake (16)"],
["跼","구부릴 국","국","bent","stooped","crouched; contract (14)"],
["快","기분이 좋을 쾌","쾌","시원할 쾌","빠를 쾌","rapid","quick","speedy","fast; soon (7)"],
["漑","물 댈 개","개","씻을 개","물 천천히 흐를 개","water (14)"],
["喰","먹을 식","식","to eat","drink (12)"],
["鴣","자고 고","고","species of Taiwan pigeon (16)"],
["跽","꿇어앉을 기","기","to kneel for a long time","to go down on hands and knees (14)"],
["擗","가슴칠 벽","벽","to beat the breast (16)"],
["漓","스며들 리","리","river in Guangxi province (13)"],
["忭","기뻐할 변","변","delighted; pleased (7)"],
["偃","자빠질 언","언","cease","lay off","lay down (11)"],
["鋪","전방 포","포","베풀 포","문고리 포","펼 포","아플 포","spread out","arrange; shop","store; place to sleep","bed (15)"],
["袒","솔기 터질 탄","탄","strip; lay bare; bared; naked (11)"],
["擘","엄지손가락 벽","벽","thumb; break","tear open","rip (16)"],
["演","펼칠 연","연","긴 물줄기 연","통할 연","윤택할 연","넓힐 연","perform","put on; exercise (14)"],
["喲","감탄하는 어조사 약","약","ah","final particle (12)"],
["忮","해칠 기","기","stubborn; perverse; aggressive (7)"],
["偄","연약할 연","연","공손할 연","weak (11)"],
["鴦","암원앙새 앙","앙","female mandarin duck (Aix galericulata) (16)"],
["漕","배로 실어 나를 조","조","transport by water; canal transportation (14)"],
["祭","제사 채","채","sacrifice to","worship (11)"],
["喳","예하고 대답하는 소리 사","사","whispering (12)"],
["偆","가멸할 춘","춘","두터울 춘(11)"],
["據","의지할 거","거","기댈 거","웅거할 거","짚을 거","to occupy","take possession of; a base (16)"],
["鴨","오리 압","압","duck; Anas species (various) (16)"],
["忱","믿을 침","침","정성 침","truth","sincerity; sincere (7)"],
["琁","옥 선","선","beautiful jade; star (11)"],
["假","거짓 하","하","falsehood","deception; vacation (11)"],
["袖","소매 수","수","sleeve; put something in sleeve (11)"],
["偈","쉴 게","게","게타 게","글귀 게","brave; martial; hasty; scudding (11)"],
["袗","홑옷 진","진","unlined garments; hem of garment (11)"],
["球","옥경쇠 구","구","ball","sphere","globe; round (11)"],
["偉","클 위","위","넉넉할 위","기특할 위","great","robust; extraordinary (11)"],
["喸","땅이름 폿","폿","place name (12)"],
["漙","이슬방울 맺을 단","단","dewy (14)"],
["琅","옥 이름 랑","랑","a variety of white carnelian; pure (11)"],
["漚","물거품 구","구","soak","steep; sodden","soaked; rotten; foam","froth (14)"],
["念","생각 념","념","think of","recall","study (8)"],
["擠","물리칠 제","제","crowd","squeeze","push against (17)"],
["理","다스릴 리","리","reason","logic; manage (11)"],
["繁","많을 번","번","complicated","complex","difficult (17)"],
["偌","성씨 야","야","너무 야","thus","so","like","such (11)"],
["鋲","넓은 못 병","병","징 병","rivet (15)"],
["琇","귀막이 수","수","옥돌 수","coarse variety of jasper or jade (11)"],
["擡","들 대","대","carry","raise","lift (17)"],
["擢","뽑을 탁","탁","뺄 탁","솟을 탁","이길 탁","pull up","draw up; select (17)"],
["繃","묶을 붕","붕","bind","draw firmly","strap (17)"],
["忸","익을 뉴","뉴","blush","be bashful","be ashamed (7)"],
["偎","사랑할 외","외","가물거릴 외","cling to","cuddle","embrace","fondle (11)"],
["韁","고삐 강","강","reins","halter; bridle (22)"],
["偏","치우칠 편","편","무리 편","오십명 편","간사한 모양 편","inclined one side; slanting (11)"],
["繄","검푸른 비단 예","예","탄식하는 소리 예","be; particle; sigh","alas (17)"],
["擣","찧을 도","도","hull; thresh; beat","attack (17)"],
["鴰","재두루미 괄","괄","the crow (17)"],
["琉","유리 류","류","sparkling stone; glazed","opaque (10)"],
["繅","고치 실 뽑을 소","소","draw","reel silk from cocoons; elegant compositions (17)"],
["偐","거짓 안","안","FALSE","counterfeit","spurious (11)"],
["韃","칠 달","달","tatars (22)"],
["袞","곤룡포 곤","곤","ceremonial dress worn by the emperor (11)"],
["繆","어그러질 류","류","wind around","bind; prepare (17)"],
["漠","사막 막","막","desert; aloof","indifferent","cool (14)"],
["喿","새 때지어 울 소","소","chirping of birds (13)"],
["忻","기뻐할 흔","흔","delightful","joyful","pleasant (7)"],
["擦","뿌릴 찰","찰","wipe","scrub","rub","scour; brush (17)"],
["祺","복 기","기","good luck","good fortune (13)"],
["繇","따를 요","요","성할 요","reason","cause (17)"],
["鋸","톱 거","거","다리 꺾는 형벌 거","a saw; to saw; amputate (16)"],
["漢","한수 한","한","the Chinese people","Chinese language (14)"],
["鋹","날카로울 청","청","sharp; a keen edge; sharp point (16)"],
["韆","그네 천","천","swing (24)"],
["擧","들 거","거","받들 거","움직일 거","일걸을 거","raise","lift up; recommend (18)"],
["忼","강개할 강","강","뜻이 크고 기개있을 강","탄식할 강(7)"],
["偓","거리낄 악","악","to fuss (11)"],
["忽","소흘히 할 홀","홀","suddenly","abruptly; neglect (8)"],
["祼","강신제 관","관","libation (13)"],
["漣","물놀이 련","련","flowing water; ripples; weeping (14)"],
["鋺","저울 바탕 원","원","a metal bowl (16)"],
["偕","같을 혜","혜","화할 혜","together; be in order (11)"],
["繊","가늘 섬","섬","fine","delicate; minute; graceful (17)"],
["袢","속옷 번","번","robe (11)"],
["韉","언치 천","천","saddle blanket (26)"],
["偖","찢을 차","차","rip up","tear down; raise; haul (11)"],
["忿","성낼 분","분","get angry; fury","exasperation (8)"],
["鋼","쇠 강","강","steel; hard","strong","tough (16)"],
["媒","중매 매","매","go-between","matchmaker; medium (12)"],
["袤","길이 무","무","longitude","lengthwise; length (11)"],
["祿","복 록","록","blessing","happiness","prosperity (13)"],
["擬","의논할 의","의","헤아릴 의","흡사할 의","draft; intend","plan","propose (17)"],
["韋","다룬 가죽 위","위","훌부들할 위","군복 위","성씨 위","tanned leather; surname; KangXi radical number 178 (9)"],
["擭","덫 화","화","함정 화","trap catch animals","snare; seize (17)"],
["鴻","큰 기러기 홍","홍","species of wild swan; vast (17)"],
["做","지을 주","주","work","make; act (11)"],
["媕","머뭇거릴 암","암","undecided (12)"],
["韍","폐슬 불","불","a leather knee-pad worn during sacrifices (14)"],
["繐","가늘고 성긴 베 세","세","fine cloth (18)"],
["漪","물놀이칠 의","의","ripples on water; swirling (14)"],
["擯","물리칠 빈","빈","exclude","expel","reject; usher (17)"],
["漫","질펀할 만","만","overflow of water; spreading (14)"],
["漬","담글 지","지","soak","steep; dye; stains; sodden (14)"],
["停","머무를 정","정","늦어질 정","stop","suspend","delay; suitable (11)"],
["韐","가죽바지 갑","갑","knee-pad made of red-dyed leather (15)"],
["袪","소매 거","거","소매 아귀 거","sleeves; cuff (11)"],
["被","이불 피","피","덥힐 피","미칠 피","나타날 피","창피할 피","passive indicator 'by'; bedding (11)"],
["繒","비단 증","증","silk fabrics; surname (18)"],
["擱","놓을 각","각","place","put","lay down; delay (17)"],
["漭","넓을 망","망","vast; expansive (14)"],
["偝","버릴 배","배","not facing; desert (11)"],
["擲","던질 척","척","throw (down)","hurl","cast","fling (18)"],
["槀","마를 고","고","draft","manuscript (14)"],
["偞","가벼울 엽","엽","낮을 엽","얼굴 예쁠 엽(11)"],
["媚","아첨할 미","미","풍치가 아름다울 미","charming","attractive; flatter (12)"],
["偟","노닐 황","황","agitated; alarmed (11)"],
["韓","나라 이름 한","한","fence; surname; Korea (17)"],
["織","짤 직","직","weave","knit; organize","unite (18)"],
["媛","아리따운 여자 원","원","여자 벼슬이름 원","beauty","beautiful woman (12)"],
["荀","풀 이름 순","순","성씨 순","surname; ancient state; plant (12)"],
["繕","기울 선","선","repair","mend; rewrite","transcribe (18)"],
["擴","넓힐 확","확","expand","enlarge","stretch (18)"],
["琛","보배 침","침","treasure","valuables (12)"],
["繖","일산 산","산","umbrella","parasol","parachute (18)"],
["漱","양치질할 수","수","빨래할 수","gargle","rinse; wash","scour (14)"],
["偢","얼이 빠져 있을 초","초","to stare at (11)"],
["漲","불을 창","창","rise in price (14)"],
["槃","쟁반 반","반","tray; turn","rotate; search (14)"],
["荃","향풀 전","전","전풀 전","aromatic herb; fine cloth (12)"],
["漳","강이름 장","장","name of a river in Henan (14)"],
["媟","깔볼 설","설","lust after","act indecently; lewd (12)"],
["袱","보 복","복","a piece of cloth used wrap bundles (12)"],
["繙","되풀이 풀이할 번","번","to interpret; to flap","flutter in the wind (18)"],
["袳","옷 펼칠 치","치","spread one's clothes (12)"],
["繚","감길 료","료","wind round","rap around","bind (18)"],
["健","탐할 견","견","strong","robust","healthy; strength (11)"],
["擺","열 파","파","헤칠 파","제거할 파","손뼉칠 파","put","place; display; swing","sway (18)"],
["趁","좇을 진","진","take advantage of","avail oneself (12)"],
["媢","강새암 할 모","모","be jealous of","be envious (12)"],
["韙","옳을 위","위","right; proper; perpriety (18)"],
["袴","바지 고","고","pants; trousers; panties (12)"],
["荆","광대싸리 형","형","가시 형","thorns; brambles; my wife; cane (12)"],
["荇","마름풀 행","행","조아기 행","a water plant","Nymphoides peltalum (12)"],
["琢","옥 쪼을 탁","탁","옥 다듬을 탁","가릴 탁","polish jade; cut jade (12)"],
["偨","가지런하지 않을 치","치","오르락 내리락할 치","uneven (11)"],
["媤","시집 시","시","husband's family (12)"],
["漸","점점 참","참","gradually (14)"],
["繞","얽힐 요","요","둘릴 요","entwine; wind around; surround (18)"],
["槊","창 삭","삭","spear","lance (14)"],
["偩","본뜰 부","부","follow (11)"],
["韜","활집 도","도","sheath","scabbard","bow case (19)"],
["偪","핍박할 핍","핍","가까울 핍","compel","pressure","force; bother (11)"],
["超","뛰어넘을 초","초","jump over","leap over; surpass (12)"],
["草","풀 초","초","grass","straw","thatch","herbs (12)"],
["琤","옥소리 쟁","쟁","jade of jade being; tinkle (12)"],
["擾","길들일 요","요","번거로울 요","disturb","annoy","agitate (18)"],
["構","집세울 구","구","frame","building","structure (14)"],
["韝","깍지 구","구","leather arm band (19)"],
["琥","호박 호","호","jewel in shape of tiger; amber (12)"],
["荊","모형나무 형","형","곤장 형","thorns; brambles; my wife; cane (12)"],
["偫","기다릴 치","치","갖출 치","wait for; lay in (11)"],
["槌","몽둥이 퇴","퇴","너스레 퇴","누에시렁 퇴","던질 퇴","hammer","mallet; strike","beat (14)"],
["韞","감출 온","온","붉은 빛 온","an orange color; hide","conceal (19)"],
["槍","창 창","창","spear","lance; gun","rifle (14)"],
["琦","옥 이름 기","기","gem","precious stone","jade (12)"],
["繡","수놓을 수","수","성씨 수","embroider; embroidery; ornament (18)"],
["媧","여자이름 왜","왜","mythological snail goddess (12)"],
["韠","슬갑 필","필","knee-pad (20)"],
["漼","깊을 최","최","고을 최","눈물 줄줄 흘릴 최","눈서리 쌓일 최","having the appearance of depth (14)"],
["槎","뗏목 차","차","raft; time","occasion; to hew (14)"],
["繢","톱끝 궤","궤","draw","sketch","paint (18)"],
["偭","향할 면","면","transgress (11)"],
["琨","옥돌 곤","곤","beautiful jade","precious stones (12)"],
["韡","꽃 환히 필 위","위","gorgeous (21)"],
["繤","지을 찬","찬","edit (18)"],
["越","부들자리 활","활","exceed","go beyond; the more ... (12)"],
["漾","물결 출렁거릴 양","양","overflow; swirl","ripple; to be tosssed by waves (14)"],
["槐","홰나무 괴","괴","locust tree (14)"],
["偯","울음 끝소리 의","의","sob (11)"],
["媪","할미 오","오","땅귀신 오","old woman; lower-class woman (12)"],
["漿","미음 장","장","any thick fluid; starch; broth (15)"],
["荏","들깨 임","임","beans; soft","pliable; herb (12)"],
["荐","거듭할 천","천","repeat","reoccur; recommend (12)"],
["琪","옥 기","기","type of jade (12)"],
["繦","돈꿰미 강","강","string of copper coins; swaddling (19)"],
["荑","띠싹 제","제","sprouts; tares (12)"],
["琫","칼집 장식 봉","봉","ornament (12)"],
["槓","지렛대 공","공","lever","pole","crowbar; sharpen; (Cant.) a wardrobe","trunk (14)"],
["偲","간절히 책망할 시","시","살피고 힘쓸 시","굳셀 시","talented; urgent (11)"],
["荒","거칠 황","황","wasteland","desert; uncultivated (12)"],
["琬","보석 완","완","아름다운 옥 완","the virtue of a gentleman; jade (12)"],
["啀","물어뜯을 애","애","gnaw; (Cant.) to shout","yell","scold (11)"],
["側","곁 측","측","기울어질 측","저물 측","배반할 측","가까울 측","미천할 측","아플 측","side; incline","slant","lean (11)"],
["啁","새소리 주","주","chirp","twitter","twittering (11)"],
["繩","노끈 승","승","법 승","다스릴 승","이을 승","rope","string","cord; measure","restrain (19)"],
["繪","그림 회","회","draw","sketch","paint (19)"],
["琮","옥홀 종","종","octagonal piece of jade with hole in middle (12)"],
["偵","엿볼 정","정","정탐할 정","물을 정","spy","reconnoiter; detective (11)"],
["趐","새가 떼로 날 혈","혈","나아갈 혈(13)"],
["琯","옥피리 관","관","a jade tube used as an instrument (12)"],
["繫","맬 계","계","맺을 계","attach","connect","unite","fasten (19)"],
["偶","짝지울 우","우","무리 우","뜻밖에 우","accidentally","coincidently; an idol (11)"],
["啃","입 다시는 소리 삽","삽","gnaw","chew","bite (11)"],
["啄","쪼을 탁","탁","문 두드릴 탁","to peck; (Cant.) to slander (11)"],
["趑","머뭇거릴 자","자","can't move; to falter (13)"],
["琰","아름다운 옥 염","염","비취옥 염","jewel","gem; glitter of gems (12)"],
["偸","훔칠 투","투","엷을 투","구차할 투","인정머리없을 투","to steal (11)"],
["槙","나무 끝 전","전","자빠진 나무 전","a twig; an ornamental evergreen (14)"],
["繭","고치 견","견","발 부르틀 견","목 쉴 견","비단 견","cocoon; callus","blister (19)"],
["媳","며느리 식","식","daughter-in-law (13)"],
["往","갈 왕","왕","옛 왕","이따금 왕","go","depart; past","formerly (8)"],
["征","칠 정","정","갈 정","invade","attack","conquer (8)"],
["琲","구슬 꿰미 배","배","necklace (12)"],
["商","헤아릴 상","상","commerce","business","trade (11)"],
["繯","얽힐 현","현","둘릴 현","맬 현","noose; hang death; tie","bind (19)"],
["徂","갈 조","조","go","advance; to; die (8)"],
["啇","나무 뿌리 적","적","실과 꼭지 적","밑둥 적","to stalk; the stem; the foot; the base (11)"],
["琳","아름다운 옥 림","림","beautiful jade","gem (12)"],
["媵","보낼 잉","잉","a maid who accompanies bride to her new home; to escort; a concubine (13)"],
["韭","부추 구","구","scallion","leek; radical 179 (9)"],
["礁","암초 초","초","reef; jetty; submerged rocks (17)"],
["繰","야청 통견 조","조","to reel silk from cocoons (19)"],
["銀","은 은","은","돈 은","silver; cash","money","wealth (14)"],
["琴","거문고 금","금","Chinese lute or guitar (12)"],
["琵","비파 비","비","guitar-like instrument (12)"],
["啉","술순배 람","람","stupid; slow (11)"],
["径","통할 경","경","narrow path; diameter; direct (8)"],
["琶","비파 파","파","guitar-like instrument (12)"],
["啊","사랑할 아","아","어조사 아","exclamatory particle (11)"],
["待","기다릴 대","대","treat","entertain","receive; wait (9)"],
["韱","산부추 섬","섬","wild onions or leeks (17)"],
["銃","도끼 구멍 총","총","총 총","ancient weapon","blunderbuss (14)"],
["趙","조나라 조","조","surname; ancient state (14)"],
["韲","양념할 제","제","break or smash into pieces","pulverize (19)"],
["媺","착하고 아름다울 미","미","beautiful (13)"],
["徇","조리 돌릴 순","순","따라 죽을 순","빠를 순","부릴 순","comply with","follow; display (9)"],
["很","패려궂을 흔","흔","very","quite","much (9)"],
["啍","입김 톤","톤","느럭느럭할 톤","creak; (Cant.) to babble","gibberish (11)"],
["媻","비틀거릴 반","반","to move (13)"],
["音","소리 음","음","편지 음","음악 음","sound","tone","pitch","pronunciation (9)"],
["銅","구리 동","동","copper","brass","bronze cuprum (14)"],
["琺","법랑 법","법","enamel","cloissoné (12)"],
["啎","거스를 오","오","obstinate","disobedient","intractable (11)"],
["徉","배회할 양","양","wonder","rove","stray; hesitating (9)"],
["徊","노닐 회","회","linger","walk to and fro","hesitain (9)"],
["媽","어미 마","마","mother","mama (13)"],
["問","물을 문","문","알릴 문","ask (about)","inquire after (11)"],
["媾","겹사돈 구","구","marry; be on friendly terms with (13)"],
["律","법 률","률","statute","principle","regulation (9)"],
["後","뒤 후","후","behind","rear","after; descendents (9)"],
["啐","부를 쵀","쵀","놀랄 쵀","맛 볼 쵀","to taste","to sip; to spit; the sound of sipping; to surprise (11)"],
["媿","창피줄 괴","괴","ashamed","conscience-stricken (13)"],
["韶","아름다울 소","소","이을 소","순의 풍류 소","봄 소","music of the emperor Shun; beautiful (14)"],
["槥","널 혜","혜","작은 관 혜","coffin (15)"],
["繹","삭일 역","역","베풀 역","실 끝 찾을 역","to unravel or unreel silk; to interpret","explain (19)"],
["槧","편지 첨","첨","wooden tablet; edition (15)"],
["啓","열 계","계","open; begin","commence; explain (11)"],
["礎","주춧돌 초","초","foundation stone","plinth (18)"],
["繼","이을 계","계","continue","maintain","carry on (20)"],
["槨","덧널 곽","곽","outer-coffin (15)"],
["徐","천천할 서","서","slowly","quietly","calmly; composed","dignified (10)"],
["韺","풍류이름 영","영","music (18)"],
["趣","추창할 취","취","뜻 취","what attracts one's attention (15)"],
["繽","어지러울 빈","빈","flourishing","thriving","abundant (20)"],
["啕","수도할 도","도","wail (11)"],
["徑","지름길 경","경","narrow path; diameter; direct (10)"],
["韻","울림 운","운","소리 맞을 운","운 운","rhyme; vowel (19)"],
["繾","곡진할 견","견","마지 않을 견","attached to","inseparable; entangled (20)"],
["槪","평두목 개","개","절개 개","대강 개","거리낄 개","칠한 술통 개","generally (15)"],
["啖","씹을 담","담","eat","feed; chew","bite; entice (11)"],
["徒","무리 도","도","disciple","follower; go on foot (10)"],
["啗","씹을 담","담","eat","chew","bite; entice","lure (11)"],
["響","소리 울릴 향","향","소리 마주칠 향","악기 향","make sound","make noise; sound (20)"],
["銑","끌 선","선","mill (14)"],
["槭","단풍나무 축","축","maple (15)"],
["啙","약할 자","자","poor (12)"],
["趨","달아날 추","추","추창할 추","hasten","hurry; be attracted to (17)"],
["銓","사람 가릴 전","전","저울질할 전","weigh","measure; select officials (14)"],
["得","얻을 득","득","obtain","get","gain","acquire (11)"],
["啜","마실 철","철","sip","suck up; sob","weep (11)"],
["徘","노닐 배","배","walk back and forth","hesitate (11)"],
["撃","칠 격","격","두드릴 격","눈에 마주칠 격","죽일 격","strike","hit","beat; attack","fight (15)"],
["銖","저울눈 수","수","unit of weight","one twenty-fourth of a Chinese ounce (liang) (14)"],
["槲","떡갈나무 곡","곡","type of oak (15)"],
["啞","벙어리 아","아","놀랄 아","dumb","mute; become hoarse (11)"],
["徙","옮길 사","사","move one's abode","shift","migrate (11)"],
["礙","막을 애","애","obstruct","hinder","block","deter (19)"],
["撅","옷걷을 궤","궤","protrude; snap","break; dig (15)"],
["啟","인도할 계","계","open; begin (11)"],
["滁","물이름 저","저","district in Anhui province (13)"],
["銘","새길 명","명","inscribe","engrave; unforgettably (14)"],
["血","피 혈","혈","피붙이 혈","씩씩할 혈","blood; radical number 143 (6)"],
["荳","콩 두","두","beans","peas; bean-shaped (13)"],
["滂","비 퍼부을 방","방","torrential; voluminous (13)"],
["啡","코 고는 소리 비","비","morphine; coffee (11)"],
["徜","노닐 상","상","walking and fro; lingering (11)"],
["銚","쟁개비 조","조","large hoe; surname; a spear brevium (14)"],
["撈","잡을 로","로","scoop out of water; dredge","fish (15)"],
["滃","구름 피어 오를 옹","옹","안개 자욱할 옹","swelling","rising","dispersing (13)"],
["啣","재갈 함","함","hold in mouth; harbor","cherish (11)"],
["滄","찰 창","창","blue","dark green; cold (13)"],
["從","좇을 종","종","from","by","since","whence","through (11)"],
["銛","가래 섬","섬","sharp","keen-edged; a kind of hoe (14)"],
["銜","재갈 함","함","bit; hold in mouth","bite; gag (14)"],
["滅","멸망할 멸","멸","extinguish; wipe out","exterminate (13)"],
["荷","연 하","하","lotus","water lily","holland (13)"],
["趲","놀라 흩어질 찬","찬","go in hurry","hasten; urge (26)"],
["衄","코피 뉵","뉵","epistaxis","nosebleed; to be defeated (10)"],
["槹","두레박 고","고","spar; well-sweep (15)"],
["徠","올 래","래","induce","encourage to come (11)"],
["足","지날 주","주","foot; attain","satisfy","enough (7)"],
["御","거느릴 어","어","drive","ride; chariot; manage (11)"],
["衆","무리 중","중","multitude","crowd; masses","public (12)"],
["槻","물푸레나무 규","규","zelkova tree (15)"],
["滉","물 깊고 넓을 황","황","deep (13)"],
["趵","차는 소리 작","작","noise of tramping feet (10)"],
["槽","구유 조","조","trough; manger; vat","tank; groove; a distillery (15)"],
["荻","갈대 적","적","reed","miscanthus sacchariflorus (13)"],
["滋","불을 자","자","grow","multiply","increase; thrive (12)"],
["撐","버틸 탱","탱","취할 탱","헤칠 탱","prop up","support; brace; to push off (with a pole) (15)"],
["滌","씻을 척","척","wash","cleanse","purify; sweep (14)"],
["衊","모독할 멸","멸","smear","slander; defile (21)"],
["槿","무궁화 근","근","hibiscus (15)"],
["徨","노닐 황","황","doubtful","irresolute","vacillating (12)"],
["滎","실개천 형","형","물결 일 형","county in Henan; rising and dashing of waves (13)"],
["行","다닐 행","행","길귀신 행","오행 행","그릇 얄긋거릴 행","행서 행","행실 행","go; walk; move","travel; circulate (6)"],
["趺","책상다리할 부","부","sit cross-legged; back of the foo (11)"],
["礦","쇳돌 황","황","mine; mineral","ore (20)"],
["撒","뿌릴 살","살","release","cast away","let go; disperse; relax (15)"],
["衍","성할 연","연","넓을 연","물이 넘칠 연","상자 연","아름다울 연","흐를 연","overflow","spill over","spread out (9)"],
["礧","바위너설 뢰","뢰","roll (20)"],
["撓","긁을 뇨","뇨","흔들 뇨","궁할 뇨","scratch; disturb","bother; submit (15)"],
["復","다시 부","부","return; repeat; repeatedly (12)"],
["倀","미칠 창","창","bewildered; rash","wildly (10)"],
["趼","발 부르틀 견","견","callous skin on hands or feet. blisters (13)"],
["衎","즐길 간","간","바를 간","to give pleasure; pleased","happy (9)"],
["循","좇을 순","순","의지할 순","돌 순","차례 순","돌아다닐 순","obey","comply with","follow (12)"],
["滑","미끄러울 활","활","slip","slide; slippery","polished (13)"],
["趾","발 지","지","toe; tracks","footprints (11)"],
["倂","아우를 병","병","combine (10)"],
["礪","거친 숫돌 려","려","whetstone; sharpen (20)"],
["徬","시중들 방","방","to wander about","walk along side of; to be next to (13)"],
["鳥","새 조","조","bird; KangXi radical 196 (11)"],
["滓","앙금 재","재","찌끼 재","sediment","lees","dregs (13)"],
["倃","훼방할 구","구","damage","destroy (10)"],
["趿","발가락으로잡을 삽","삽","to tread on; slipshod (11)"],
["礫","조약돌 력","력","gravel","pebbles","potsherds (20)"],
["徭","부릴 요","요","conscript labor","compulsory labor (13)"],
["倄","찌를 효","효","끙끙 앓을 효","한탄할 효(10)"],
["衒","자랑할 현","현","팔릴 현","brag; show off","promote oneself (11)"],
["鳦","제비 을","을","swallow (12)"],
["礬","명반 반","반","alum (20)"],
["滔","물 넘칠 도","도","overflow; rushing water","a torrent (13)"],
["微","작을 미","미","자질구레할 미","small","prefix micro-","trifling (13)"],
["徯","기다릴 혜","혜","사잇길 혜","wait for","await","expect","hope (13)"],
["撙","누를 준","준","economize; abide by","comply with (15)"],
["倅","버금 쉬","쉬","아직 벼슬자리에 오르지 않은 아들 쉬","원님 쉬","deputy","vice- (10)"],
["婁","별 이름 루","루","surname; a constellation; to wear (11)"],
["鳧","오리 부","부","wild duck","teal; swim (13)"],
["術","재주 술","술","심술부릴 술","술법 술","art","skill","special feat; method","technique (11)"],
["倆","재주 량","량","clever","skilled; two","pair (10)"],
["珀","호박 박","박","amber (9)"],
["撚","비틀 년","년","twirl in fingers","tease","toy with (15)"],
["滕","물 솟을 등","등","an ancient state in Shandong province; water bursting forth (13)"],
["鳩","비둘기 구","구","pigeon","dove; collect","assemble (13)"],
["珂","옥 이름 가","가","inferior kind of jade (9)"],
["撝","찣을 휘","휘","wave","brandish; modest","humble (15)"],
["倉","곳집 창","창","granary; berth; sea (10)"],
["街","거리 가","가","street","road","thoroughfare (12)"],
["礱","갈 롱","롱","grind","sharpen; mill (21)"],
["撞","두드릴 당","당","knock against","bump into","collide (15)"],
["婆","할머니 파","파","너풀너풀 춤출 파","old woman; grandmother (11)"],
["徵","치성 치","치","summon","recruit; musical note (15)"],
["個","낱 개","개","치우칠 개","numerary adjunct","piece; single (10)"],
["撟","들 교","교","correct; to bend or twist; to feign; to raise (the hand) (15)"],
["倌","수레 모는 사람 관","관","assistant in wine shop","groom (10)"],
["衙","마을 아","아","public office; official residence (13)"],
["倍","어길 패","패","떨어질 패","암송할 패","times","fold","multiple times (10)"],
["銳","창 태","태","sharp","keen","acute","pointed (15)"],
["鞀","소고 도","도","drum (14)"],
["啻","뿐 시","시","쯤 시","only","merely; just like; stop at (12)"],
["德","큰 덕","덕","ethics","morality","virtue (15)"],
["鞁","말 가슴걸이 피","피","안갑 피(14)"],
["啼","울 제","제","weep","whimper; howl","twitter (12)"],
["婉","순할 완","완","예쁠 완","젊을 완","amiable","congenial; restrained (11)"],
["倏","잠간 숙","숙","홀연 숙","개의 빨리 달리는 모양 숙","hastily","suddenly","abruptly (10)"],
["珈","머리꾸미개 가","가","an ornament attached to a woman's hairpin (9)"],
["啽","잠꼬대 암","암","(Cant.) to speak (12)"],
["衝","충돌할 충","충","찌를 충","거리 충","돌파할 충","rush against","charge ahead (15)"],
["啾","두런거릴 추","추","찍찍거릴 추","wailing of child; chirp (12)"],
["撤","거둘 철","철","omit; remove; withdraw (15)"],
["徹","통할 철","철","penetrate","pervade; penetrating (15)"],
["銶","끌 구","구","chisel (15)"],
["珉","아름다운돌 민","민","stone resembling jade (9)"],
["鞄","가방 포","포","가죽 다로는 장인 포","to work hides; leather bag (14)"],
["們","들 문","문","무리 문","adjunct pronoun indicate plural (10)"],
["銷","녹을 소","소","사라질 소","melt","fuse; market","sell; to pass time","finish","cancel (15)"],
["衞","막을 위","위","지킬 위","핏기운 위","나라이름 위","guard","protect","defend (16)"],
["珊","산호 산","산","coral (9)"],
["撥","다스릴 발","발","move; dispel; distribute; plectrum","play stringed instrument (15)"],
["倒","넘어질 도","도","fall over; lie down; take turns (10)"],
["鳳","봉새 봉","봉","male phoenix; symbol of joy (14)"],
["鞅","가슴걸이 앙","앙","소고삐 앙","leather strap over horse's neck (14)"],
["示","보일 시","시","바칠 시","가르칠 시","show","manifest; demonstrate (5)"],
["縇","선두를 선","선","decoration (15)"],
["珌","칼 장식 옥 필","필","ornament (9)"],
["銹","동록 수","수","rust","corrode (15)"],
["鳴","울 명","명","cry of bird or animal; make sound (14)"],
["縈","얽힐 영","영","맬 영","entangle","entwine","coil (16)"],
["徼","돌아다닐 요","요","순라 요","변방 요","구할 요","frontier","border; inspect","patrol (16)"],
["徽","아름다울 휘","휘","a badge","insignia (17)"],
["衡","가로 횡","횡","measure","weigh","judge","consider (16)"],
["珍","보배 진","진","precious","valuable","rare (9)"],
["倔","딱딱할 굴","굴","stubborn","obstinate","intransigent; firm (10)"],
["倕","무거울 수","수","이름 수(10)"],
["婐","정숙할 와","와","단정할 와","maid (11)"],
["鳶","솔개 연","연","kite; Milvus species (various) (14)"],
["衢","네거리 구","구","highway; thoroughfare","intersection (24)"],
["縊","목 맬 의","의","hang","strangle (16)"],
["撩","다스릴 료","료","lift up","raise; leave","depart (15)"],
["倖","요행 행","행","괴일 행","아당할 행","친할 행","lucky","fortunate; dote on","spoil (10)"],
["縋","줄에 달릴 추","추","climb down rope; hang by rope (16)"],
["衣","옷 의","의","입을 의","clothes","clothing; cover","skin (6)"],
["珏","쌍옥 곡","곡","two pieces of jade joined together (9)"],
["社","토지의 신 사","사","god of the soil and altars to him; group of families; company","society (8)"],
["撫","어루만질 무","무","누를 무","pat","console","comfort; pacify (15)"],
["鞋","신 혜","혜","shoes","footwear in general (15)"],
["倘","혹시 당","당","if","supposing","in event of (10)"],
["候","물을 후","후","wait; expect; visit; greet (10)"],
["播","심을 파","파","펼칠 파","버릴 파","까불 파","달아날 파","sow","spread; broadcast; case away","reject (15)"],
["倚","기댈 의","의","의지할 의","믿을 의","rely on","depend on; lean heavily (10)"],
["鞍","안장 안","안","saddle; any saddle-shaped object (15)"],
["表","겉 표","표","윗옷 표","밝을 표","show","express","manifest","display (9)"],
["縐","주름 추","추","구길 추","crepe","crape","wrinkles","creases (16)"],
["撮","머리 끄덩이 잡을 촬","촬","당길 촬","비칠 촬","little bit","small amount","pinch (15)"],
["鞏","묶을 공","공","bind; firm","secure","strong (15)"],
["滬","강 이름 호","호","Shanghai; river near Shanghai (14)"],
["倜","대범할 척","척","raise high; unrestrained (10)"],
["撰","지을 천","천","가질 천","모을 천","고를 천","compose","write","compile (15)"],
["珖","옥피리 광","광","carat (10)"],
["縑","합사비단 겸","겸","fine silk (16)"],
["倝","해 돋을 간","간","sunrise; dawn (10)"],
["衫","적삼 삼","삼","shirt; robe; gown; jacket (9)"],
["婚","혼인할 혼","혼","get married; marriage","wedding (11)"],
["倞","굳셀 경","경","far (10)"],
["撲","닦을 복","복","pound","beat","strike; attack (15)"],
["滯","막힐 체","체","block up","obstruct; stagnant (14)"],
["借","빌 차","차","borrow; lend; make pretext of (10)"],
["珙","큰 옥 공","공","precious stone; xian in Sichuan (10)"],
["鞔","신울 만","만","sides or uppers of shoes to stretch a skin on a frame for a drum; (Cant.) to cover (16)"],
["茀","풀 우거질 불","불","overgrown with grass","weedy (11)"],
["縕","모시 온","온","묵은 솜 온","tangled hemp","raveled silk; vague","confused (16)"],
["倡","여광대 창","창","guide","leader; lead","introduce (10)"],
["茁","풀 처음 나는 모양 줄","줄","to sprout","flourish; sprouts appearing above-ground; vigorous (11)"],
["縗","상복 최","최","상옷 최","sackcloth worn on breast during (16)"],
["倢","빠를 첩","첩","beautiful","handsome; woman (10)"],
["滲","스밀 참","참","soak through","infiltrate (14)"],
["衰","상복 최","최","decline","falter","decrease; weaken (10)"],
["茂","우거질 무","무","무성할 무","thick","lush","dense; talented (11)"],
["倣","본뜰 방","방","imitate (10)"],
["范","풀 이름 범","범","surname; bee-like insect (11)"],
["値","값 치","치","가치있을 치","만날 치","당할 치","price","cost","worth (10)"],
["滴","물방울 적","적","물 댈 적","drip; drop of water (14)"],
["衲","기울 납","납","mend","sew","patch; line; quilt (10)"],
["珞","구슬 목걸이 락","락","kind of necklace (10)"],
["鞘","칼집 초","초","scabbard","sheath (16)"],
["倥","어리석을 공","공","boorish","ignorant; urgent","pressing (10)"],
["茄","가지 가","가","eggplant (11)"],
["珠","구슬 주","주","precious stone","gem","jewel","pearl (10)"],
["倦","게으를 권","권","be tired of","weary (10)"],
["婢","여자종 비","비","servant girl; your servant (11)"],
["茅","띠 모","모","reeds","rushes","grass; surname (11)"],
["縛","묶을 박","박","to tie (16)"],
["撻","매질할 달","달","flog","whip; chastise; (Cant.) classifier for places; a tart (from the English 'tart') (16)"],
["縝","삼실 진","진","detailed","fine; closely woven (16)"],
["倨","거만할 거","거","걸터 앉을 거","굽을 거","arrogant","haughty","rude (10)"],
["珢","옥돌 은","은","precious stone (10)"],
["撼","흔들 감","감","움직일 감","깨뜨릴 감","move","shake; (Cant.) to fight (16)"],
["滷","소금밭 로","로","thick gravy","sauce","broth; brine (14)"],
["縞","명주 호","호","흴 호","white raw silk (16)"],
["滸","물가 호","호","riverbank","shore (14)"],
["衷","가운데 충","충","정성 충","착할 충","속곳 충","마음 충","heart","from bottom of one's heart (10)"],
["倩","예쁠 천","천","beautiful","lovely; son-in-law (10)"],
["茈","지치 자","자","a plant yielding a red dye (11)"],
["珣","옥그릇 순","순","옥 이름 순","name of a kind of jade (10)"],
["倪","어릴 예","예","도울 예","끝 예","feeble","tiny","young and weak (10)"],
["茉","말리 말","말","white jasmine (11)"],
["撾","칠 과","과","to beat; to strike (16)"],
["榌","사닥다리 비","비","wooden ladder (13)"],
["倫","인륜 륜","륜","normal human relationships (10)"],
["婦","며느리 부","부","married women; woman; wife (11)"],
["珥","귀막이 옥 이","이","귀고리 이","해무리 이","ear ornament; stick","insert (10)"],
["縠","주름 비단 곡","곡","crepe (16)"],
["撿","잡을 검","검","구속할 검","관찰할 검","비교할 검","to pick up (16)"],
["倬","환할 탁","탁","클 탁","noticeable","large; clear","distinct (10)"],
["縡","일 재","재","실을 재","matter","affair (16)"],
["倭","순한 모양 위","위","빙글 돌아올 위","dwarf; dwarfish","short (10)"],
["鞠","기를 국","국","bow","bend; rear","raise","nourish (17)"],
["縢","봉할 등","등","bind","tie up","restrain; cord (16)"],
["縣","매달릴 현","현","끊어질 현","county","district","subdivision (16)"],
["倮","알몸 라","라","bare","naked","uncovered (10)"],
["婪","탐할 람","람","covet; covetous","avaricious (11)"],
["滾","흐를 곤","곤","turn","roll","rotate; boil (14)"],
["珩","노리개 형","형","갓끈 형","the top gem of the pendants (10)"],
["鞣","다룬 가죽 유","유","마른 가죽 유","tan","soften (18)"],
["衽","옷깃 임","임","lapel; hidden lapel of coat (10)"],
["滿","찰 만","만","fill; full","satisfied (14)"],
["婬","간통할 음","음","새서방질할 음","음탕할 음","obscene","licentious","lewd (11)"],
["衾","이불 금","금","coverlet","quilt (10)"],
["衿","옷깃 금","금","collar or lapel of garment (10)"],
["鞦","그네 추","추","말고들개 추","leather stap; swing (18)"],
["榔","나무 이름 랑","랑","betel-nut tree (13)"],
["榕","용나무 용","용","banyan tree (14)"],
["倴","사람이름 분","분","둔한 사람 분(10)"],
["唁","위문할 언","언","express condolence (10)"],
["班","나눌 반","반","class","group","grade; squad; job (10)"],
["珮","노리개 패","패","찰 패","jade ornament (10)"],
["鞨","말갈나라 갈","갈","말갈보석 갈","tribe (18)"],
["縫","꿰맬 봉","봉","sew","mend (17)"],
["唄","염불소리 패","패","노래부를 패","final particle of assertion pathaka (10)"],
["榘","곡척 구","구","carpenter's square","ruler","rule (13)"],
["唅","머금을 함","함","a sound; to put in the mouth; (Cant.) to grunt","grumble (10)"],
["彀","활 잔뜩 당길 구","구","enough","adequate fully","quite (13)"],
["茗","차 싹 명","명","tea; tea plant (12)"],
["縮","줄어들 축","축","물러갈 축","거둘 축","모자랄 축","contract","draw in","reduce (17)"],
["唆","부추길 사","사","make mischief","incite","instigate (10)"],
["鞬","칼집 건","건","동개 건","a quiver on a horse; a store (18)"],
["鞭","채찍 편","편","볼기채 편","태장 편","whip; whip; string of firecrackers (18)"],
["榛","개암나무 진","진","hazelnut; thicket","underbrush (14)"],
["唇","놀랄 진","진","lips (10)"],
["縯","길 연","연","늘일 연","long (17)"],
["鞮","가죽신 제","제","leather shoes (18)"],
["磁","자석 자","자","magnetic; magnetism; porcelain (14)"],
["榜","게시판 병","병","방 써붙일 병","매 병","볼기칠 병","placard; list of successful exam candidates (14)"],
["唈","숨 느껴쉴 읍","읍","to sob (10)"],
["珵","노리개 정","정","패옥 정(11)"],
["婷","아리따울 정","정","쌀쌀할 정","pretty; attractive; graceful (12)"],
["倻","땅이름 야","야","phonetic used in Korean place names (10)"],
["唉","대답하는 소리 애","애","alas","exclamation of surprise or pain (10)"],
["鉀","갑옷 갑","갑","potassium kalium (13)"],
["茛","독초이름 간","간","미나리아재비 간(12)"],
["縱","바쁠 총","총","indulge in","give free reign to (17)"],
["唊","망녕되이 말할 겹","겹","(Cant.) to hold fast","press (10)"],
["彆","활 뒤틀릴 별","별","awkward (15)"],
["珷","옥돌 무","무","an inferior gem (12)"],
["縲","포승 류","류","a chain or rope used bind criminals (17)"],
["磅","돌 떨어지는 소리 방","방","pound; weigh (15)"],
["鞲","팔찌 구","구","leather arm guard (19)"],
["縳","올 견","견","낳이 실 견","wrap (17)"],
["彈","쏠 탄","탄","악기부릴 탄","탄알 탄","탄핵할 탄","pellet","bullet","shot","shell (15)"],
["鉄","쇠 철","철","iron; strong","solid","firm (13)"],
["縴","궂은 솜 견","견","tow line (17)"],
["唎","가는 소리 리","리","sound","noise; final particle (10)"],
["鞴","풀무 비","비","saddle up horse; drive horse (19)"],
["鉅","갈고리 거","거","클 거","강한 쇠 거","steel","iron; great (13)"],
["縵","무늬 없는 비단 만","만","plain silk; simple","plain (17)"],
["彊","굳셀 강","강","센 활 강","사나울 강","송장 뻣뻣할 강","stubborn","uncompromising (16)"],
["唏","슬퍼할 희","희","weep or sob; grieve (10)"],
["縷","실 루","루","thread; detailed","precise (17)"],
["珽","옥 이름 정","정","jade tablet (11)"],
["磊","돌무더기 뢰","뢰","pile of rocks or stones; great (15)"],
["榤","닭의 홰 걸","걸","perch for fowls roost on (14)"],
["婿","사위 서","서","son-in-law; husband (12)"],
["唐","당나라 당","당","Tang dynasty; Chinese (10)"],
["彌","두루 미","미","널리 미","extensive","full; fill; complete (17)"],
["茣","차조기 오","오","mat","matting (13)"],
["榥","책상 황","황","screen (14)"],
["鉉","솥귀 현","현","device for carrying a tripod (13)"],
["現","나타날 현","현","지금 현","옥빛 현","appear","manifest","become visible (11)"],
["縹","옥빛 표","표","훌쩍 날 표","light blue silk; dim; misty (17)"],
["磋","윤낼 차","차","polish","buff; scrutinize (15)"],
["鞸","슬갑 필","필","leather arm guard (20)"],
["彎","굽을 만","만","bend","curve (22)"],
["鉋","대패 포","포","carpenter's plane (13)"],
["榧","비자나무 비","비","type of yew (14)"],
["鞹","무두질한 가죽 곽","곽","skin (20)"],
["縻","고삐 미","미","얽어맬 미","halter for ox; tie up","harness (17)"],
["榨","술주자 자","자","to press or extract juices; a press to extract juices; a vegetable (14)"],
["唔","글 읽는 소리 오","오","hold in mouth; bite (10)"],
["總","꿰맬 총","총","거느릴 총","합할 총","끄덩이 잡을 총","collect; overall","altogether (17)"],
["赤","붉을 적","적","금치 적","red; communist","red'; bare (7)"],
["績","길쌈 적","적","공 적","이룰 적","이을 적","spin; achievements (17)"],
["磐","넓을 반","반","large rock","boulder; firm (15)"],
["鉎","녹 생","생","(Cant.) to rust (13)"],
["茨","가시나무 자","자","caltrop","tribulus terrestris (12)"],
["磑","맷돌 애","애","stone mill; grind; break apart (15)"],
["鉏","호미 서","서","hoe; eradicate","eliminate (13)"],
["彔","나무 깍을 록","록","to carve wood (8)"],
["赦","용서할 사","사","forgive","remit","pardon (11)"],
["榭","정자 사","사","kiosk","pavilion (14)"],
["鉑","금박 박","박","platinum; thin sheet of metal (13)"],
["茫","아득할 망","망","vast","boundless","widespread (12)"],
["赧","얼굴 붉힐 난","난","blush","turn red (12)"],
["唚","입맞출 침","침","vomiting of animals; to use bad language (10)"],
["磔","찢을 책","책","downward stroke slanting right (15)"],
["榮","영화로울 영","영","오동나무 영","추녀 영","꽃다울 영","glory","honor; flourish","prosper (14)"],
["彖","결단할 단","단","a hog; a hedgehog; a porcupine (9)"],
["磕","돌 부딪는 소리 개","개","hit; collide","knock into; sound (15)"],
["彗","비 혜","혜","꼬리별 혜","broomstick; comet (11)"],
["赫","불 이글이글할 혁","혁","빛날 혁","성할 혁","bright","radiant","glowing (14)"],
["榱","서까래 최","최","rafter (14)"],
["彘","돼지 체","체","swine (12)"],
["茯","복령 복","복","china root; medicinal fungus (12)"],
["彙","무리 휘","휘","collect","compile","assemble; hedgehog (13)"],
["唟","땅이름 것","것","place name (10)"],
["鉗","목사슬 겸","겸","세력으로써 남을 누를 겸","입 다물 겸","pincers","pliers","tongs (13)"],
["茱","수유 수","수","dogwood (12)"],
["磚","벽돌 전","전","기와 전","tile","brick (16)"],
["榴","석류나무 류","류","pomegranate (14)"],
["茲","무성할 자","자","now","here; this; time","year (12)"],
["赭","붉은흙 자","자","reddish brown; hematite; ochre (16)"],
["茴","회향풀 회","회","fennel","aniseed (12)"],
["彝","떳떳할 이","이","법 이","종묘 제기 이","술통 이","yi; tripod","wine vessel; rule (18)"],
["走","달릴 주","주","walk","go on foot; run; leave (7)"],
["榷","외나무 다리 교","교","footbridge; toll","levy; monopoly (14)"],
["鉛","납 연","연","분 연","lead plumbum (13)"],
["茵","자리 인","인","a cushion","mattress; wormwood; Skimmia japon (12)"],
["蠃","나나니벌 라","라","the solitary wasp (19)"],
["茶","차 차","차","tea (12)"],
["蠅","파리 승","승","flies (19)"],
["鉞","도끼 월","월","broad-axe","a battle axe","halberd (13)"],
["茸","풀 뾰죽뾰죽 날 용","용","못생길 용","soft","downy; buds","sprouts (12)"],
["赳","헌걸찰 규","규","grand","valiant (9)"],
["形","모양 형","형","몸 형","form","shape","appearance (7)"],
["榻","평상 탑","탑","모직 탑","자리 탑","cot","couch","bed (14)"],
["磠","약돌 뇨","뇨","sal ammoniac; sand; pebble (16)"],
["茹","띠 뿌리 여","여","받을 여","헤아릴 여","roots; vegetables; eat; bear (12)"],
["赴","나아갈 부","부","go to; attend","be present (9)"],
["榼","술그릇 합","합","물통 합","뚜껑 합","wine glass (14)"],
["彤","붉은 칠 동","동","red","vermilion; name of ancient (7)"],
["赶","달릴 간","간","pursue","follow; expel","drive away (10)"],
["榾","등걸 골","골","pieces of wood (14)"],
["唪","껄껄 웃을 봉","봉","to recite","to intone or chant (11)"],
["彥","착한 선비 언","언","클 언","elegant","handsome; learned (9)"],
["起","일어날 기","기","rise","stand up; go up; begin (10)"],
["唫","입 다물 금","금","to hum","to intone","etc. to close","to shut (11)"],
["鉢","바리때 발","발","earthenware basin; alms bowl (Sanskrit paatra) (13)"],
["唬","범이 울 호","호","외칠 호","to intimidate; to scare (11)"],
["彧","빛날 욱","욱","문채날 욱","refined","cultured","polished (10)"],
["摒","제거할 병","병","expel","cast off; arrange (14)"],
["鉤","갈고랑쇠 구","구","hook","barb; sickle; stroke with (13)"],
["彩","무늬 채","채","hue","color; variegated colors (11)"],
["磧","돌무더기 적","적","모래벌 적","sand and gravel; rocks exposed at low tide; to crush (16)"],
["溏","진수렁 당","당","pool; not hardened","semi-soft (13)"],
["售","팔 수","수","갚을 수","sell (11)"],
["鉥","돗바늘 술","술","인도할 술","needle (13)"],
["蠍","전갈 갈","갈","scorpion (19)"],
["彪","칡범 표","표","문채 표","작은 범 표","tiger; tiger stripes; tiger-like (11)"],
["鉦","징 정","정","kind of gong used in ancient times by troops on the march (13)"],
["磨","갈 마","마","grind; polish; rub; wear out; a millstone (16)"],
["源","근원 원","원","샘 원","계속할 원","spring; source","head; surname (13)"],
["彫","새길 조","조","carve","decorate; emaciated (11)"],
["唯","오직 유","유","허락할 유","only; yes (11)"],
["磪","험하고 높을 최","최","a high mountain; precipitous (16)"],
["彬","빛날 빈","빈","cultivated","well-bred (11)"],
["唱","노래 창","창","sing","chant","call; ditty","song (11)"],
["彭","성씨 팽","팽","땅이름 팽","방패 팽","총소리 팽","북소리 팽","name of ancient country; surname (12)"],
["蠑","도마뱀 영","영","lizard (20)"],
["摘","딸 적","적","돋구어낼 적","움직일 적","pluck","pick; select; specify (14)"],
["磬","경쇠 경","경","musical instrument; musical stone (16)"],
["唲","어리광 부릴 애","애","응석 부릴 애","forced laughter (11)"],
["唳","울 려","려","cry of bird; cry (11)"],
["威","위엄 위","위","두려울 위","pomp","power; powerful; dominate (9)"],
["彰","밝을 창","창","clear","manifest","obvious (14)"],
["唴","어린아이 울 강","강","울어 기진할 강(11)"],
["準","수준기 준","준","rule","guideline","standard (13)"],
["磯","물가 기","기","jetty; submerged rock; eddy (17)"],
["影","그림자 영","영","형상 영","shadow; image","reflection; photograph (15)"],
["唶","부르짖을 책","책","sigh","groan; loud laughter (11)"],
["娃","어여쁜 계집 왜","왜","얼굴 아름다울 왜","baby; doll; pretty girl (9)"],
["溘","문득 합","합","이를 합","의지할 합","abruptly","suddenly","unexpectedly (13)"],
["唷","소리지를 육","육","final particle (11)"],
["玄","검을 현","현","검붉을 현","하늘 현","아득할 현","고요할 현","deep","profound","abstruse (5)"],
["唸","신음할 점","점","recite","read","chant (11)"],
["唹","고요히 웃을 어","어","to smile at (11)"],
["摠","거느릴 총","총","모을 총","무리 총","장수 총","general (14)"],
["磴","돌 비탈길 등","등","steps on ledge","cliff","or hill (17)"],
["玆","이 자","자","now","here; this; time","year (10)"],
["磵","산골 시내 간","간","석간수 간","brook","mountain stream (17)"],
["溜","방울져 떨어질 류","류","slide","glide","slip; slippery (13)"],
["彷","거닐 방","방","like","resembling; resemble (7)"],
["率","거느릴 솔","솔","좇을 솔","쓸 솔","대강 솔","공경할 솔","뱀 이름 솔","to lead; ratio; rate; limit (11)"],
["溝","개천 구","구","ditch","drain","narrow waterway (13)"],
["唼","쪼아먹을 삽","삽","speaking evil. gobbling sound made by ducks (11)"],
["娉","장가들다 빙","빙","beautiful","attractive","charming","graceful (10)"],
["玈","검을 로","로","black (11)"],
["緄","띠 곤","곤","cord; woven belt; hem; hemming (14)"],
["靂","벼락 력","력","thunderclap","crashing thunder (24)"],
["磷","돌 을 틈물이 흐르는 모양 린","린","phosphorus; water rushing between (17)"],
["唾","침 타","타","버릴 타","spit","spit on; saliva (11)"],
["玉","구슬 옥","옥","사랑할 옥","이룰 옥","jade","precious stone","gem (5)"],
["溟","어두울 명","명","drizzling rain; dark","obscure (13)"],
["役","부릴 역","역","국경 지킬 역","service; a servant","laborer; to serve (7)"],
["鉷","돌쇠뇌 홍","홍","trigger (14)"],
["唿","근심할 홀","홀","sad; (Cant.) a bit","part (11)"],
["靄","아지랭이 애","애","cloudy sky","haze; calm","peaceful (24)"],
["緇","검을 치","치","black silk (14)"],
["王","임금 왕","왕","할아버지 왕","king","ruler; royal; surname (4)"],
["蠟","밀 랍","랍","wax; candle; waxy","glazed (21)"],
["磺","쇳돌 광","광","sulphur; brimstone (17)"],
["彼","저 피","피","that","there","those (8)"],
["摧","꺾을 최","최","망할 최","저지할 최","destroy","break","injure (14)"],
["靆","구름 낄 체","체","cloudy sky; not clear; dark (24)"],
["溢","넘칠 일","일","overflow","brim over; full (13)"],
["磻","강이름 번","번","a tributary of the Wei river in Shanxi (17)"],
["蠡","좀먹을 려","려","wood-boring insect; bore into wood (21)"],
["蠢","꿈틀거릴 준","준","wriggle; stupid; silly; fat (21)"],
["玎","옥소리 정","정","jingling","tinkling (6)"],
["緊","굳게 얽힐 긴","긴","tense","tight","taut; firm","secure (14)"],
["摩","갈 마","마","rub","scour","grind; friction (14)"],
["靈","신령 령","령","spirit","soul; spiritual world (24)"],
["緋","붉은 빛 비","비","scarlet","dark red","crimson; purpl (14)"],
["溥","펼 부","부","big","great","vast","wide; widespread (13)"],
["彿","비슷할 불","불","resembling","like","as if (8)"],
["娑","춤출 사","사","dance","frolic; lounge; saunter (10)"],
["鉼","판금 병","병","plate; (Cant.) a penny (16)"],
["靉","구름낄 애","애","cloudy sky; dark","obscure (25)"],
["蠣","굴 려","려","oyster (21)"],
["磽","메마른 땅 교","교","barren land; sandy soil (17)"],
["緍","낚시줄 민","민","variant of 緡 U+7DE1","a fishing-line; cord; string of coins; a paper or straw string (14)"],
["溧","강 이름 률","률","river in Anhui and Jiangsu provinces (13)"],
["娓","장황할 미","미","힘쓸 미","comply; complying","agreeable (10)"],
["鉾","칼 끝 모","모","spear (14)"],
["摭","주울 척","척","pick up","gather (14)"],
["玔","옥고리 천","천","green jade (7)"],
["溪","시내 계","계","mountain stream","creek (13)"],
["摯","잡을 지","지","sincere","warm","cordial; surname (15)"],
["玕","옥돌 간","간","inferior variety of gem (7)"],
["溫","따뜻할 온","온","부드러울 온","lukewarm","warm; tepid","mild (13)"],
["玖","옥 다음가는 돌 구","구","black-colored jade (7)"],
["娘","아가씨 낭","낭","mother; young girl; woman; wife (10)"],
["娙","여관 이름 형","형","예쁠 형(10)"],
["靑","푸를 청","청","blue (8)"],
["玗","옥돌 우","우","우기나무 우","1/2 pr stone (7)"],
["青","푸를 청","청","blue","green","black; young (8)"],
["玘","패옥 기","기","court-dress jewels (7)"],
["摳","치마걷어들 구","구","raise","lift up; tight-fisted (14)"],
["溯","거슬러 올라갈 소","소","go upstream","go against current; formerly (13)"],
["娛","즐거울 오","오","pleasure","enjoyment","amusement (10)"],
["係","걸릴 계","계","bind","tie up; involve","relation (9)"],
["促","핍박할 촉","촉","촉박할 촉","좁을 촉","빠를 촉","짧을 촉","urge","press","hurry; close (9)"],
["娜","아리따울 나","나","elegant","graceful","delicate (10)"],
["溱","많을 진","진","river in Henan (13)"],
["緖","실마리 서","서","end of thread; thread","clue (15)"],
["楂","떼 사","사","a raft","to hew","to fell trees (13)"],
["俄","갑자기 아","아","sudden(ly)","soon; Russian (9)"],
["緗","담황색 상","상","light-yellow color (15)"],
["溲","오줌 수","수","반족할 수","적실 수","urinate; soak","drench (13)"],
["俅","공손할 구","구","ornamental cap (9)"],
["緘","봉할 함","함","seal","close; bind; letter (15)"],
["靖","다스릴 정","정","편안할 정","생각할 정","pacify; appease; calm","peaceful (13)"],
["娟","어여쁠 연","연","춤추는 모양 연","beautiful","graceful (10)"],
["蠱","독 고","고","poison; venom; harm; bewitch (23)"],
["玞","옥돌 부","부","an inferior agate (8)"],
["摸","찾을 모","모","gently touch with hand; caress (14)"],
["娠","아이밸 신","신","마부 신","pregnant (10)"],
["蠲","밝을 견","견","조촐할 견","반딧불 견","the millipede sometimes confounded with the grow-worm; bright","clear (23)"],
["玟","옥돌 민","민","streaks in jade; gem (8)"],
["線","실 선","선","thread","line","wire; clue (15)"],
["摹","베낄 모","모","trace","copy","duplicate; pattern (15)"],
["摺","접을 접","접","패할 접","fold","bend; twisted","curved (14)"],
["苅","벨 예","예","자를 예","cut off","reap","mow; sickle (10)"],
["玠","큰 서옥 개","개","large jade tablet used by officials at court to indicate their ranks (8)"],
["溵","물소리 은","은","물이름 은(13)"],
["俊","준걸 준","준","talented","capable; handsome (9)"],
["靚","단장할 정","정","밝을 정","make up face; ornament; quiet; (Cant.) pretty (15)"],
["緜","햇솜 면","면","cotton wad; wool; soft","downy (15)"],
["摻","섬섬할 섬","섬","a delicate hand; mix","blend","adulterate (14)"],
["溶","물 질펀히 흐를 용","용","녹일 용","to melt","dissolve; overflowing with (13)"],
["溷","어지러울 혼","혼","privy","latrine; turbid","dirty (13)"],
["緝","낳을 집","집","to sew in close stitches (15)"],
["娣","여동생 제","제","younger sister","sister-in-law (10)"],
["靛","푸른 대 전","전","indigo; any blue dye (16)"],
["蠶","누에 잠","잠","silkworms (24)"],
["俋","날쌜 읍","읍","밭 가는 모양 읍(9)"],
["摽","칠 표","표","두를 표","찔러 밀칠 표","가슴 만질 표","throw out; push out; strike (14)"],
["贄","폐백 지","지","gift superior; gift given (18)"],
["靜","고요할 정","정","조용할 정","쉴 정","편안할 정","quiet","still","motionless; gentle (16)"],
["緞","신발 하","하","satin (15)"],
["楊","갯버들 양","양","willow","poplar","aspen; surname (13)"],
["贅","붙일 췌","췌","데릴사위 췌","혹 췌","군더더기 췌","unnecessary","superfluous (18)"],
["靝","하늘 천","천","(in taoism) the heaven (18)"],
["娥","예쁠 아","아","be beautiful; good; surname (10)"],
["締","맺을 체","체","tie","join","connect; connection (15)"],
["俎","도마 조","조","chopping board or block; painted (9)"],
["溺","빠질 닉","닉","drown; submerge in water; indulge (13)"],
["非","아닐 비","비","not","negative","non-; oppose (8)"],
["娧","기뻐할 태","태","더딜 태(10)"],
["玦","옥패 결","결","활 깍지 결","broken piece of jade (8)"],
["緡","낚시줄 민","민","a fishing-line; cord; string of coins; a paper or straw string (15)"],
["俏","거문고 뒤치는 소리 소","소","like","similar; resemble; pretty (9)"],
["蠹","좀 두","두","moth; insects which eat into clot (24)"],
["贈","보낼 증","증","give present; bestow","confer (19)"],
["俐","똑똑할 리","리","smooth; active; clever","sharp (9)"],
["靠","기댈 고","고","lean on","trust","depend on; near (15)"],
["玧","귀막이 구슬 윤","윤","reddish (8)"],
["緣","인연 연","연","서두를 연","hem","margin; reason","cause; karma","fate (15)"],
["溽","유월 더위 욕","욕","무더울 욕","moist","humid","muggy (13)"],
["娩","해산할 만","만","give birth child; complaisant (10)"],
["俑","시체와 함께 매장하는 허수아비 인형 용","용","wooden figure buried with dead (9)"],
["靡","쓰러질 미","미","쏠릴 미","divide","disperse","scatter (19)"],
["蠻","오랑캐 만","만","barbarians; barbarous","savage (25)"],
["贊","도울 찬","찬","help","support","assist","aid (19)"],
["面","낯 면","면","face; surface; plane; side","dimension (9)"],
["贋","가짜 안","안","false; counterfeit; bogus; sham (19)"],
["玩","희롱할 완","완","보배 완","play with","joke","enjoy (8)"],
["俔","연탐할 현","현","풍세보는 깃 현","like (9)"],
["緦","보름새베 시","시","시마복 시","석달복 시","coarse cotton cloth used for mourning (15)"],
["楓","단풍나무 풍","풍","신나무 풍","maple tree (13)"],
["苑","나랏동산 원","원","큰 바람 원","pasture","park","garden; mansion (11)"],
["玫","매괴 매","매","rose (8)"],
["靦","무안할 전","전","물끄러미 볼 전","timid","shy","bashful (16)"],
["苒","풀 우거질 염","염","덧없을 염","lush; successively","in order (11)"],
["贍","넉넉할 섬","섬","support","aid; to be sufficient; rich","elegant (20)"],
["編","책편 편","편","기록할 편","첩지 편","벌릴 편","knit","weave; arrange; compile (15)"],
["楔","문설주 설","설","wedge; gatepost; foreword (13)"],
["哀","슬플 애","애","가여울 애","sad","mournful","pitiful; pity (9)"],
["品","뭇 품","품","품수 품","가지 품","벼슬 차례 품","article","product","commodity (9)"],
["苓","도꼬마리 령","령","fungus","tuber; licorice (11)"],
["緩","느즈러질 완","완","더딜 완","slow","leisurely; to postpone","delay (15)"],
["俗","익을 속","속","버릇 속","풍속 속","세상 속","social customs; vulgar","unrefined (9)"],
["苔","이끼 태","태","moss","lichen (11)"],
["贏","이문 남길 영","영","멜 영","자랄 영","win; surplus","gain","profit (20)"],
["哂","빙그레 웃을 신","신","비웃을 신","smile","laugh at","sneer at; (Cant.) a final particle indicating doing something to the full extent possible (9)"],
["俘","사로잡을 부","부","prisoner of war; take as prisoner (9)"],
["靨","웃는 볼 옴쏙 들어갈 엽","엽","얼굴의 검은 사마귀 엽","dimples (23)"],
["俙","비슷할 희","희","어슴프레할 희","희미할 희","to pretend","appear as if (9)"],
["苕","능소화 초","초","rush plant (11)"],
["贐","노자 신","신","노수 신","farewell present (21)"],
["楗","문 빗장 건","건","문지방 건","bar of door","bolt of lock (13)"],
["革","가죽 혁","혁","고칠 혁","갑주 혁","leather","animal hides; rad. 177 (9)"],
["哄","떠들썩할 홍","홍","coax; beguile","cheat","deceive (9)"],
["緬","가는 실 면","면","distant","remote; think of (15)"],
["俚","속될 리","리","rustic","vulgar","unpolished; mean (9)"],
["苗","모 묘","묘","sprouts; Miao nationality (11)"],
["楙","무성할 무","무","아름다울 무","name of plant; lush (13)"],
["哆","입 딱 벌일 치","치","tremble","shiver","shudder","quiver (9)"],
["楚","회초리 초","초","name of feudal state; clear (13)"],
["贓","장물 장","장","booty","loot","stolen goods; bribe (21)"],
["玲","옥이름 령","령","tinkling of jade (9)"],
["弁","고깔 변","변","conical cap worn during Zhou dynasty (5)"],
["俛","힘쓸 면","면","make effort","endeavor; to lower the head (9)"],
["娵","젊을 추","추","물고기 추","예쁜 여자 추","star (11)"],
["贔","힘쓸 비","비","strong (21)"],
["玳","대모(바다거북) 대","대","tortoise shell (9)"],
["緯","씨줄 위","위","별 오른편으로 돌 위","woof; parallels of latitude (15)"],
["哇","음란한 소리 와","와","게울 와","vomit; cry of an infant (9)"],
["俜","비틀거리다 빙","빙","to trust to; send a message (9)"],
["哈","한 모금 합","합","sound of laughter (9)"],
["娶","장가들 취","취","중개인 취","marry","take wife (11)"],
["保","지킬 보","보","protect","safeguard","defend","care (9)"],
["哉","비로소 재","재","답다 재","그런가 재","final exclamatory particle (9)"],
["苛","매울 가","가","small","petty; harsh","rigorous (11)"],
["贖","속바칠 속","속","무역할 속","buy","redeem; ransome; atone for (22)"],
["楝","멀구슬나무 련","련","melia japonica (13)"],
["弄","희롱할 롱","롱","do","play or fiddle with; alley (7)"],
["苜","거여목 목","목","clover (11)"],
["楞","모 릉","릉","used for Ceylon in Buddhist texts (13)"],
["俟","기다릴 사","사","wait for","wait until","as soon as (9)"],
["玷","옥의 티 점","점","흠각 점","flaw in gem; flaw in character (9)"],
["緲","아득할 묘","묘","indistinct","dim; minute; distant (15)"],
["俠","의기 협","협","사이에 낄 협","chivalrous person; knight-errant (9)"],
["苞","그령풀 포","포","밑둥 포","초목이 다복히 날 포","꾸러피 포","a variety of rush; firm","enduring; to burst forth (11)"],
["弇","뚜껑 감","감","사람이름 감","cover over","hide; narrow-necked (9)"],
["信","믿을 신","신","밝힐 신","맏길 신","이틀 밤을 잘 신","사신 신","trust","believe; letter (9)"],
["弈","바둑둘 혁","혁","Chinese chess (9)"],
["苟","진실로 구","구","careless","frivolous; illicit; grammatical particle: if","but","if only; surname; grass name; distinguish DKW 30853 (ji4) (11)"],
["練","익힐 련","련","practice","drill","exercise","train (15)"],
["楠","녹나무 남","남","name of tree","machilus nanmu (13)"],
["靳","아낄 근","근","strap on a horse's breast (13)"],
["娼","몸 파는 여자 창","창","prostitute","harlot (11)"],
["碇","닻돌 정","정","배 멈출 정","anchor (13)"],
["贛","줄 공","공","Jiangxi province; places therein (24)"],
["楡","느름나무 유","유","서쪽 유","elm tree (13)"],
["俣","갈래질 오","오","big (9)"],
["靴","신 화","화","boots (13)"],
["弊","해질 폐","폐","폐단 폐","곤할 폐","곰곰 궁리할 폐","결단할 폐","엎드릴 폐","evil","wrong","bad; criminal (15)"],
["緶","꿰맬 편","편","hem; plait","braid","queue (15)"],
["玻","파려옥 파","파","유리옥 파","glass (9)"],
["俤","용모 제","제","(J) same as 面影 U+9762 U+5F71","visage","face; trace","shadow; memory (9)"],
["苡","율무 이","이","질경이 이","barley (11)"],
["楢","부드러운 나무 유","유","홰 유","tinder (13)"],
["哎","애통하는 소리 애","애","interjection of surprise (9)"],
["鈇","도끼 부","부","an ax; a hatchet (12)"],
["楣","문미 미","미","처마 미","crossbeam above or under gate (13)"],
["弋","주살 익","익","취할 익","홰 익","검을 익","벼슬이름 익","오랑캐 익","catch","arrest; shoot with bow (3)"],
["俥","인력거 거","거","rickshaw","cart","vehicle; name of piece in Chinese chess (9)"],
["靶","고삐 파","파","target; splashboard on chariot (13)"],
["緹","붉은 비단 제","제","reddish color","red","brown (15)"],
["鱅","용어 용","용","a kind of tench (22)"],
["苣","상추 거","거","검은 깨 거","kind of lettuce (11)"],
["楥","떡갈나무 원","원","실감개 원","신골 원","a last for making shoes; to turn on a lathe (13)"],
["靷","가슴걸이 인","인","the leather belt that connects a horse with a cart (13)"],
["碌","돌 모양 록","록","rough","uneven","rocky; mediocre (13)"],
["緻","톡톡할 치","치","빽빽할 치","헌 옷 기울 치","delicate","fine; dense (15)"],
["鱇","천징어 강","강","anglerfish (22)"],
["若","같을 약","약","자네 약","만약 약","if","supposing","assuming; similar (11)"],
["式","법 식","식","쓸 식","제도 식","수레 앞 가로막대 식","style","system","formula","rule (6)"],
["楨","담틀 마구리대 정","정","단단한 나무 정","hardwood; supports","posts (13)"],
["苦","쓸 고","고","bitter; hardship","suffering (11)"],
["碎","부술 쇄","쇄","잘 쇄","break","smash; broken","busted (13)"],
["靺","버선 말","말","stocking; the Tungusic tribe (14)"],
["苧","모시 저","저","china grass","ramie (11)"],
["鈍","무딜 둔","둔","blunt","obtuse; dull; flat; dull-witted (12)"],
["鱈","대구 설","설","codfish (22)"],
["碏","삼갈 작","작","coloured (13)"],
["弑","윗사람 죽일 시","시","to kill one's superior (12)"],
["楪","널평상 접","접","small dish; window (13)"],
["靼","다룬가죽 달","달","tartars (14)"],
["楫","노 즙","즙","oar","paddle (13)"],
["碑","돌기둥 비","비","stone tablet; gravestone (13)"],
["弓","활 궁","궁","bow; curved","arched; KangXi radical number 57 (3)"],
["弔","위문할 조","조","condole","mourn","pity; hang (4)"],
["鈐","굴대 비녀장 검","검","자물쇠 검","큰 보습 검","lock","latch; stamp","seal (12)"],
["楬","패를 써서 표시할 갈","갈","tablet; a signpost (13)"],
["修","닦을 수","수","옳게할 수","정리할 수","꾸밀 수","study; repair; cultivate (10)"],
["鈑","불린 금 판","판","plate (12)"],
["苫","이엉 점","점","거적자리 점","글씨 흘려 쓸 점","rush or straw matting (11)"],
["碓","방아 대","대","pestle (13)"],
["業","일 업","업","위태할 업","씩씩할 업","벌써 업","공경할 업","profession","business","trade (13)"],
["引","끌 인","인","to pull","draw out","attract; to stretch (4)"],
["俯","구푸릴 부","부","bow down","face down","look down (10)"],
["楮","닥나무 저","저","종이돈 저","감매나무 저","mulberry; paper (13)"],
["鈒","창 삽","삽","spear; (Cant.) to cut","slice; grass-shears (12)"],
["楯","난간 순","순","방패 순","뺄 순","상여 순","shield; horizontal bar (13)"],
["俱","함께 구","구","all","together; accompany (10)"],
["俲","본받을 효","효","닮을 효","배울 효","same as 傚 U+509A","imitate","mimic (10)"],
["鈔","노략질할 초","초","paper money","bank notes; copy (12)"],
["鱏","칼철갑상어 심","심","a ray","skate (23)"],
["弗","아닐 불","불","not","negative (5)"],
["弘","넓을 홍","홍","enlarge","expand; liberal","great (5)"],
["苯","풀 떨기로 날 분","분","benzene; luxuriant (11)"],
["碗","그릇 완","완","bowl","small dish (13)"],
["俳","광대 배","배","actor; vaudeville show; insincere (10)"],
["俴","엷을 천","천","thin (10)"],
["俵","나누어 줄 표","표","흩어질 표","가마니 표","divide","distribute (10)"],
["鱒","송어 준","준","barbel (23)"],
["英","꽃부리 영","영","영웅 영","구름 뭉게뭉게 일어날 영","아름다울 영","petal","flower","leaf; brave","a hero; England","English (11)"],
["鱓","두렁허리 선","선","eel (23)"],
["搆","얽어맬 구","구","pull","drag; reach; implicate (13)"],
["弛","늦출 이","이","활 부릴 이","방탕할 이","해이할 이","loosen","relax","unstring a bow (6)"],
["俶","시작할 척","척","start","begin; beginning; arrange (10)"],
["蟀","귀뚜라미 솔","솔","cricket (17)"],
["極","가운데 극","극","extreme","utmost","furthest","final (13)"],
["員","관원 원","원","둥글 원","member; personnel","staff member (10)"],
["苴","암삼 저","저","꾸러미 저","대지팡이 저","신바닥 저","sackcloth; female hemp plant (11)"],
["湃","물결 이는 모양 배","배","sound of waves; turbulent (12)"],
["哢","지저귈 롱","롱","syllable (10)"],
["俸","녹 봉","봉","wages","salary","official emolument (10)"],
["楷","해나무 해","해","본뜰 해","해자 해","법 해","model style of Chinese writing (13)"],
["鱖","쏘가리 궤","궤","mandarin fish (23)"],
["搉","칠 각","각","knock; pick out; ridicule (13)"],
["湄","물 가 미","미","더운 물 미","water's edge","shore","bank (12)"],
["俹","기댈 아","아","의지할 아","거만할 아(10)"],
["楸","가래나무 추","추","노나무 추","산유자 추","바둑판 추","mallotus japonicus","deciduous tre (13)"],
["弟","아우 제","제","young brother; junior; i","me (7)"],
["鱗","비늘 린","린","fish scales (23)"],
["湅","누일 련","련","boil silk (12)"],
["哤","난잡할 방","방","jargon (10)"],
["俺","자기 업","업","클 업","personal pronoun","I (10)"],
["蟄","벌레 움츠릴 칩","칩","우물거릴 칩","to hibernate (17)"],
["鱘","심어 심","심","sturgeon (23)"],
["楹","기둥 영","영","하관들 영","column","pillar; numerary adjunct (13)"],
["哥","노래 가","가","elder brother (10)"],
["鈞","서른 근 균","균","unit of measure equivalent to thirty catties (12)"],
["碟","가죽다룰 설","설","small dish","plate (14)"],
["哦","놀람의 어조사 아","아","oh? really? is that so? (10)"],
["俻","하여금 비","비","좇을 비","흘겨볼 비","to prepare; to provide; to put in order; to complete perfection; completeness (10)"],
["苹","맑은 대쑥 평","평","사과 평","artemisia; duckweed; apple (11)"],
["弢","활집 도","도","bow case; scabbard; sheathe (8)"],
["苺","딸기 매","매","berries (11)"],
["蟇","개구리 마","마","frog","toad (17)"],
["損","덜 손","손","잃을 손","피곤할 손","삼갈 손","상할 손","괘 이름 손","diminish; impair; injure (13)"],
["哨","망 볼 초","초","whistle","blow whistle; chirp (10)"],
["湊","모일 주","주","piece together","assemble (12)"],
["苻","귀목풀 부","부","kind of herb; type of grass (11)"],
["哩","어조사 리","리","mile (10)"],
["俾","더할 비","비","so that","in order that; cause (10)"],
["碣","돌 세울 게","게","stone tablet (14)"],
["搏","칠 박","박","seize; spring upon; strike (13)"],
["哪","연귀 쫓는 소리 나","나","which? where? how? (10)"],
["搐","당길 휵","휵","cramp","spasm","convulsion; twitch (13)"],
["弦","시위 현","현","반달 현","맥박 잦을 현","땅이름 현","string; hypotenuse","crescent (8)"],
["哫","아첨할 족","족","to cajole (10)"],
["苽","줄 고","고","bulrush","Zizania latifolia (11)"],
["鱟","참게 후","후","king crab (24)"],
["弧","활 호","호","wooden bow; arc","crescent (8)"],
["蟋","귀뚜라미 실","실","cricket (17)"],
["湍","여울 단","단","rapid water current","rapids (12)"],
["鱠","회 회","회","minced fish; hash (24)"],
["搒","배저을 방","방","pole; beat (13)"],
["湎","빠질 면","면","flushed with drink","drunk (12)"],
["哭","울 곡","곡","weep","cry","wail (10)"],
["搓","비빌 차","차","to rub or roll between the hands (13)"],
["碧","푸를 벽","벽","jade; green","blue (14)"],
["弩","쇠뇌(여러 개의 화살이나 돌을 잇따라 쏘게 된 큰 활) 노","노","cross-bow","bow; downward stroke (8)"],
["哮","으르렁거릴 효","효","cough; pant; roar (10)"],
["碨","돌 자갈 땅 외","외","바위 옹두라지 외(14)"],
["搔","긁을 소","소","휘저을 소","분분할 소","to scratch (13)"],
["鱣","전어 전","전","sturgeon (24)"],
["碩","클 석","석","great","eminent; large","big (14)"],
["湑","거를 서","서","to strain spirits; river in Guangxi; abundant; bright (12)"],
["搖","흔들 요","요","회오리 바람 요","별이름 요","wag","swing","wave; shake; scull (13)"],
["哰","말 알아듣지 못할 로","로","incoherent chatter (10)"],
["搗","찧을 도","도","hull; thresh; beat","attack (13)"],
["弭","활고자 미","미","그칠 미","stop","desist","end","quell (9)"],
["哲","밝을 철","철","wise","sagacious; wise man","sage (10)"],
["蟒","이무기 망","망","python","boa constrictor (19)"],
["湓","용솟음할 분","분","an affluent of the River Yangtze near Kiukiang (12)"],
["碭","무늬진 돌 탕","탕","흰 기운 탕","넘칠 탕","산이름 탕","brilliantly colored stone with veined patterns in it (14)"],
["湔","빨 전","전","씻을 전","물 번질 전","wash","cleanse; purge (12)"],
["鱧","가물치 례","례","snakehead (24)"],
["湖","호수 호","호","a lake; Hubei","Hunan; bluish-green (12)"],
["弱","약할 약","약","어릴 약","weak; fragile","delicate (10)"],
["搜","찾을 수","수","더듬을 수","화살 빨리 가는 소리 수","어지러울 수","search","seek; investigate (13)"],
["哶","양울 마","마","bleat (10)"],
["獃","어리석을 애","애","dull; dull-minded","simple","stupid (13)"],
["湘","강이름 상","상","Hunan province (12)"],
["獄","감옥 옥","옥","prison","jail; case; lawsuit (13)"],
["弴","그림 그린 활 조","조","red lacquer bow (11)"],
["張","베풀 장","장","stretch","extend","expand; sheet (11)"],
["姆","여스승 모","모","child's governess; matron (8)"],
["獅","사자 사","사","lion (13)"],
["哹","부는 소리 부","부","물새 우는 소리 부(10)"],
["哺","씹어 먹을 포","포","먹일 포","chew food; feed (10)"],
["鱭","갈치 제","제","anchovy (25)"],
["湛","빠질 침","침","잠길 침","deep; profound; clear; tranquil","placid (12)"],
["需","음식 수","수","머뭇거릴 수","기다릴 수","찾을 수","괘 이름 수","need","require","must (14)"],
["湜","물 맑을 식","식","엄숙할 식","transparent","clear (12)"],
["哼","겁낼 형","형","hum; sing softly; groan","moan (10)"],
["蟛","방게 팽","팽","a land-crab (18)"],
["綃","생명주실 초","초","raw silk fabric (13)"],
["搢","꽂을 진","진","to stick into; to shake (13)"],
["鈴","방울 령","령","bell (13)"],
["湞","물이름 정","정","river in Guangdong province (12)"],
["哽","목멜 경","경","choke (with grief) (10)"],
["湟","해자 황","황","river in qinghai province (12)"],
["始","비로소 시","시","처음 시","시작할 시","별이름 시","begin","start; then","only then (8)"],
["搤","움켜쥘 액","액","seize (13)"],
["搥","북 칠 추","추","beat","pound","strike","throw; shampoo (13)"],
["鈷","다리미 고","고","cobalt; household iron cobaltum (13)"],
["霄","하늘 소","소","진눈깨비 소","sky; clouds","mist; night (15)"],
["綆","두레박줄 경","경","well rope (13)"],
["哿","좋을 가","가","excellent; to commend to be able to (10)"],
["强","강할 강","강","나머지 강","바구미 강","strong","powerful","energetic (12)"],
["確","굳을 확","확","sure","certain; real","true (15)"],
["姍","헐뜯을 산","산","slander; ridicule; proceed slowly (8)"],
["鈸","방울 발","발","cymbals (13)"],
["霅","비올 삽","삽","thunder (15)"],
["弼","도울 필","필","도지개 필","거듭 필","aid","assist","help; correct (12)"],
["霆","벼락 정","정","번개 정","a sudden peal of thunder (15)"],
["蟠","서릴 반","반","coil; coiling","curling; occupy (18)"],
["碻","굳을 확","확","solid (15)"],
["搨","비문 박을 탑","탑","베낄 탑","rub","make rubbings of inscription (13)"],
["震","벼락 진","진","shake","quake","tremor; excite (15)"],
["鈺","보배 옥","옥","rare treasure (13)"],
["湣","낚시줄 민","민","mixed","confused; pity (12)"],
["霈","비 쏟아질 패","패","torrential rains","flow of water (15)"],
["姐","맏누이 저","저","아가씨 저","교만할 저","elder sister","young lady (8)"],
["碼","마노 마","마","number","numerals; yard; symbol (15)"],
["姑","시어미 고","고","father's sister; husband's mother (8)"],
["霉","매우 매","매","mildew","mold; moldy","mildewed (15)"],
["蟣","서캐 기","기","louse eggs","nits (18)"],
["獐","노루 장","장","roebuck","hornless river deer (14)"],
["姒","동서 사","사","wife of elder brother (8)"],
["鱸","농어 로","로","sea perch","sea bass (27)"],
["碾","맷돌 년","년","roller","crush; roll (15)"],
["搪","당동할 당","당","ward off","evade; parry; block (13)"],
["姓","일가 성","성","아이 낳을 성","백성 성","one's family name; clan","people (8)"],
["委","맡길 위","위","버릴 위","맘에 든든할 위","벼이삭 고개 숙일 위","appoint","send","commission (8)"],
["鱺","뱀장어 리","리","eel (30)"],
["獒","사나운 개 오","오","mastiff","large fierce dog (14)"],
["搬","옮길 반","반","transfer","move","remove","shift (13)"],
["搭","모뜰 탑","탑","박을 탑","join together","attach to; add to (13)"],
["鈿","비녀 전","전","보배로 꾸민 그릇 전","hairpin; gold inlaid work","filigree (13)"],
["鱻","생선 선","선","fresh","new","delicious; rare","few (33)"],
["霍","빠를 곽","곽","quickly","suddenly; surname (16)"],
["霎","가랑비 삽","삽","light rain","drizzle; an instant; passing (16)"],
["綏","깃발 늘어질 유","유","soothe","appease","pacify; carriage harness (13)"],
["湫","늪 추","추","폭포 추","찌푸릴 추","서늘할 추","a small pond; a damp and narrow place (12)"],
["霏","눈 펄펄 내릴 비","비","falling of snow and rain (16)"],
["搯","두드릴 도","도","take out","pull out (13)"],
["搰","팔 골","골","dig; turbid; exert; (Cant.) to shake (13)"],
["侀","이룰 형","형","모일 형","form (8)"],
["霑","젖을 점","점","비 지정거릴 점","be moistened","soaked; moisten (16)"],
["獗","도둑 일어날 궐","궐","unruly","wild","violent","lawless (15)"],
["獘","곤할 폐","폐","폐단 폐","죽을 폐","엎드러질 폐","collapse (16)"],
["姚","어여쁠 요","요","날랠 요","멀 요","handsome","elegant; surname (9)"],
["侁","떼지어 갈 신","신","crowd (8)"],
["蟬","매미 선","선","cicada; continuous (18)"],
["經","날 경","경","classic works; pass through (13)"],
["湮","잠길 인","인","bury","sink","block up; stain (12)"],
["侂","부칠 탁","탁","부탁할 탁","to commission","to entrust to","to depute; to request","to ask (8)"],
["椀","주발 완","완","사발 완","bowl; cup; dish (12)"],
["湯","물 끓일 탕","탕","물이름 탕","씻을 탕","hot water; soup","gravy","broth (12)"],
["椁","관 곽","곽","outer-coffin; vault (12)"],
["姜","성씨 강","강","강할 강","surname; ginger (9)"],
["侃","강직할 간","간","upright and strong; amiable (8)"],
["霓","암무지개 예","예","rainbow; variegated","colored (16)"],
["侄","어리석을 질","질","nephew (8)"],
["姝","예쁠 주","주","a beautiful girl (9)"],
["蟯","촌백충 요","요","worms (18)"],
["侅","이상할 해","해","to give; prepared for included in; embraced in (8)"],
["搶","닿을 창","창","plunder","rob","take by force (13)"],
["姞","성 길","길","concubine (9)"],
["湲","물소리 환","환","물 졸졸 흐를 환","flow (12)"],
["椄","나무 접붙일 접","접","형벌틀 접","문설주 접","to graft (12)"],
["來","올 래","래","come","coming; return","returning (8)"],
["霖","장마 림","림","long spell of rain","copious rain (16)"],
["蟲","벌레 충","충","김 오를 충","worms; insects (18)"],
["椅","가래나무 의","의","교의 의","chair","seat (12)"],
["侇","무리 이","이","주검 이","a class","a category a corpse (8)"],
["賀","축하할 하","하","congratulate; send present (12)"],
["侈","사치할 치","치","넓을 치","많을 치","풍부할 치","luxurious","extravagant (8)"],
["携","끌 휴","휴","가질 휴","떠날 휴","나눌 휴","연속할 휴","lead by hand","take with; carry (13)"],
["霙","눈꽃 영","영","구름 피어오를 영","sleet (17)"],
["賁","클 분","분","forge ahead; energetic; surname (12)"],
["獠","밤사냥 료","료","to hunt at night by torches (15)"],
["綜","모을 종","종","arrange threads for weaving (14)"],
["侊","성한 모양 광","광","big (8)"],
["賂","뇌물 줄 뢰","뢰","bribe; give present (13)"],
["姣","예쁠 교","교","beautiful","handsome","pretty (9)"],
["例","법식 례","례","precedent","example; regulation (8)"],
["賃","품팔이 임","임","rent","hire; hired person (13)"],
["賄","뇌물 회","회","bribe; bribes; riches","wealth (13)"],
["搽","칠할 차","차","smear; rub","wipe; anoint (13)"],
["姤","만날 구","구","mate; copulate; good (9)"],
["霜","서리 상","상","frost; crystallized; candied (17)"],
["芈","양이 울 미","미","성씨 미","bleat of sheep; surname (9)"],
["搾","짤 착","착","to crush with the hand","press","squeeze","extract (13)"],
["椋","들보 량","량","fruit (12)"],
["姥","할미 모","모","maternal grandmother; midwife (9)"],
["侍","모실 시","시","가까울 시","좇을 시","serve","attend upon; attendant","servant; samurai (8)"],
["蟷","사마귀 당","당","mantis (19)"],
["霞","놀 하","하","rosy clouds (17)"],
["芊","풀 무성할 천","천","exuberant and vigorous foliage (9)"],
["姦","간사할 간","간","adultery","debauchery; debauch (9)"],
["侎","어루만질 미","미","to soothe","to pacify; to settle","to establish (8)"],
["綠","초록빛 록","록","green; chlorine (14)"],
["蟹","게 해","해","crab","brachyura (19)"],
["侏","난쟁이 주","주","small","little","tiny","dwarf (8)"],
["植","방망이 치","치","심을 치","세울 치","달굿대 치","두목 치","plant","trees","plants; grow (12)"],
["芋","클 후","후","taro (9)"],
["資","재물 자","자","property; wealth; capital (13)"],
["侐","고요할 혁","혁","quiet (8)"],
["椎","쇠몽둥이 추","추","칠 추","짓찧을 추","참나무 추","hammer","mallet; vertebra (12)"],
["姨","이모 이","이","처이모 이","mother/wife's sister; concubine (9)"],
["賈","장사 고","고","surname; merchant; buy","trade (13)"],
["綢","얽힐 주","주","silk cloth","satin damask (14)"],
["侑","도울 유","유","권할 유","귀인을 모시고 밥먹을 유","help","assist","repay kindness (8)"],
["蟻","왕개미 의","의","검을 의","술구더기 의","ants (19)"],
["芍","연밥 적","적","연꽃의 열매 적","peony; water chestnuts (9)"],
["綣","정다울 권","권","affectionate","solicitous (14)"],
["姪","조카 질","질","niece (9)"],
["賊","도적 적","적","해칠 적","thief","traitor (13)"],
["芎","궁궁이 궁","궁","a kind of herb (9)"],
["獨","홀로 독","독","alone","single","solitary","only (16)"],
["椑","술통 비","비","oval (12)"],
["霣","떨어질 운","운","천둥에 비 몰아올 운","fall (18)"],
["姬","성 희","희","beauty; imperial concubine (10)"],
["獪","교활할 회","회","sly","cunning","crafty (16)"],
["椒","산초나무 초","초","pepper","spices (12)"],
["侔","가지런 할 모","모","equal (8)"],
["蟾","두꺼비 섬","섬","toad (19)"],
["綦","연두빛 비단 기","기","dark grey. variegated. superlative (14)"],
["獫","험윤 오랑캐 험","험","dog (16)"],
["獬","신통한 양 해","해","해갓 해","a fabulous monster (16)"],
["姮","항아 항","항","달에 사는 미녀 항","달 부르는 다른 이름 항","lady (9)"],
["咀","씹을 저","저","깨달을 저","먹을 저","suck; chew","masticate (8)"],
["侖","둥글 륜","륜","logical reasons","logical order (8)"],
["芒","까끄라기 망","망","Miscanthus sinensis (9)"],
["侗","무지할 동","동","big; ignorant; rude","rustic (8)"],
["霧","안개 무","무","어두울 무","fog","mist","vapor","fine spray (19)"],
["芔","풀 훼","훼","a general term for plants (9)"],
["侘","실의할 차","차","disappointed","forlorn (8)"],
["咂","빨 잡","잡","to suck","to smack the lips (8)"],
["獯","오랑캐 이름 훈","훈","a tribe of Scythians which invaded China during the Hsia dynasty (17)"],
["賑","구휼할 진","진","relieve","aid distressed; rich (14)"],
["咄","꾸짖을 돌","돌","noise of rage","cry out in anger (8)"],
["霪","장마 음","음","long and heavy rain (19)"],
["獰","모질 녕","녕","영악할 녕","삽살개 털 녕","ferocious appearance; hideous (17)"],
["綬","인끈 수","수","silk ribbon attached as a seal (14)"],
["侚","좇을 순","순","빠를 순","조리 돌릴 순","fast (8)"],
["維","벼리 유","유","이을 유","맺을 유","오직 유","maintain","preserve","safeguard (14)"],
["獲","얻을 획","획","obtain","get","receive; seize (17)"],
["咆","고함지를 포","포","짐승소리 포","불끈 성낼 포","roar (8)"],
["廁","뒷간 치","치","평상 가장자리 치","섞일 치","버금 치","toilet","lavatory; mingle with (12)"],
["供","이바지할 공","공","supply","provide for; offer in worship (8)"],
["賓","손 빈","빈","guest","visitor; surname; submit (14)"],
["綮","발 고운 비단 계","계","embroidered banner (14)"],
["咇","향기날 필","필","fragrant; (Cant.) to eject (8)"],
["侜","속일 주","주","lies (8)"],
["廂","행랑 상","상","side-room","wing; theatre box (12)"],
["芙","부용 부","부","hibiscus (10)"],
["賔","손 빈","빈","guest","visitor; surname; submit (14)"],
["咈","어길 불","불","oppose (8)"],
["依","의지할 의","의","기댈 의","비슷할 의","따를 의","비유할 의","병풍 의","rely on","be set in; consent","obey a wish (8)"],
["芚","채소 이름 둔","둔","green sprout; foolish (10)"],
["咉","목메어 슬피 울 앙","앙","an echo","a sound to vomit","as an infant (8)"],
["釀","술 빚을 양","양","brew","ferment (24)"],
["獵","사냥 렵","렵","hunt; field sports (18)"],
["綰","얽을 관","관","to string together","to bind up (14)"],
["釁","피바를 흔","흔","smear with blood in sacrifice; rift","quarrel (25)"],
["咊","순할 화","화","harmony; peace; conciliation; to be on good terms with; kindly; mild (8)"],
["硃","주사 주","주","a red mole; cinnabar (11)"],
["姸","고을 연","연","사랑스러울 연","beautiful (9)"],
["霰","싸락눈 선","선","별 선","hail","sleet (20)"],
["綱","벼리 강","강","근본 강","법 강","대강 강","heavy rope","hawser; main points (14)"],
["芝","지초 지","지","sesame; a purplish or brown mushroom thought to have miraculous powers; 'a divine and relicitous plant' (Karlgren) (10)"],
["咋","잠간 사","사","question-forming particle","why? how? what?; to bite; loud (8)"],
["獷","사나울 광","광","fierce","rude","uncivilized (18)"],
["網","그물 망","망","net","web; network (14)"],
["和","화할 화","화","harmony","peace; peaceful","calm (8)"],
["賙","진휼할 주","주","give for charity (15)"],
["硅","유리만드는 흙 규","규","silicon (11)"],
["姺","나라이름 신","신","name of an ancient state (9)"],
["露","이슬 로","로","dew; bare","open","exposed (20)"],
["釃","술 거를 시","시","strain (26)"],
["獸","짐승 수","수","beast","animal; bestial (19)"],
["廈","큰 집 하","하","big building","mansion (13)"],
["綴","꿰멜 체","체","patch together","link","connect (14)"],
["姻","혼인 인","인","relatives by marriage (9)"],
["芟","벨 삼","삼","mow","cut; weed out; scythe (10)"],
["賚","줄 뢰","뢰","give","present","confer; surname (15)"],
["咍","비웃을 해","해","laugh (8)"],
["綵","비단 채","채","varicolored silk; variegated (14)"],
["賛","도울 찬","찬","help","support","assist","aid (15)"],
["廉","청렴할 렴","렴","upright","honorable","honest (13)"],
["釅","술맛 텁텁할 염","염","thick","strong (beverage) (27)"],
["獺","수달 찰","찰","otter (19)"],
["咎","허물 구","구","fault","defect; error","mistake (8)"],
["廊","복도 랑","랑","corridor","porch","veranda (12)"],
["釆","분별할 변","변","distinguish; KangXi radical 165 (7)"],
["芡","마름 검","검","가시연밥 검","plant allied to the waterlily (10)"],
["賜","줄 사","사","give","bestow favors; appoint (15)"],
["獻","바칠 헌","헌","음식 헌","어진이 헌","offer","present; show","display (19)"],
["采","캘 채","채","collect","gather; pick","pluck (8)"],
["咏","노래할 영","영","읊을 영","sing song or poem","hum","chant (8)"],
["廋","숨길 수","수","conceal","hide; search for","seek (13)"],
["獼","원숭이 미","미","macacus monkey (20)"],
["姿","맵시 자","자","one's manner","carriage","bearing (9)"],
["咐","분부할 부","부","instruct","order (8)"],
["賞","상줄 상","상","reward","grant","bestow; appreciate (15)"],
["綸","낚싯줄 륜","륜","green silk thread or tassel (14)"],
["釉","물건 빛날 유","유","glaze (12)"],
["芣","질경이 부","부","medicinal plantago (10)"],
["霸","으뜸 패","패","패왕 패","rule by might rather than right (21)"],
["賠","물어줄 배","배","indemnify","suffer loss (15)"],
["綺","비단 기","기","fine thin silk; elegant","beautifu (14)"],
["綻","옷 터질 탄","탄","나타날 탄","파할 탄","ripped seam","rend","crack (14)"],
["椧","홈 통 명","명","gutter (12)"],
["霹","벼락 벽","벽","thunder","crashing thunder (21)"],
["釋","풀 석","석","interprete","elucidate; release (20)"],
["芥","겨자 개","개","갓 개","지푸라기 개","티끌 개","mustard plant; mustard; tiny (10)"],
["賡","이을 갱","갱","일 갱","continue (15)"],
["賢","어질 현","현","좋을 현","구멍 현","virtuous","worthy","good; able (15)"],
["廐","마구간 구","구","stable; barnyard (14)"],
["里","마을 리","리","unit of distance; village; lane (7)"],
["重","무거울 중","중","heavy","weighty; double (9)"],
["鰈","가자미 접","접","넙치 접","flatfish; flounder; sole (20)"],
["硏","연마할 연","연","grind (11)"],
["咕","투덜거릴 고","고","mumble","mutter","murmur; rumble (8)"],
["廑","작은집 근","근","a hut; careful (14)"],
["芧","상수리 서","서","chestnut oak (10)"],
["賣","팔 매","매","sell; betray; show off (15)"],
["綽","너그러울 작","작","graceful","delicate; spacious (14)"],
["鰉","용상어 황","황","sturgeon (20)"],
["賤","천할 천","천","mean","low; cheap","worthless (15)"],
["咖","커피차 가","가","커피의 음차로 쓰는자 가","coffee; a phonetic (8)"],
["野","들판 야","야","open country","field; wilderness (11)"],
["芨","말오줌나무 급","급","name of a plant","the roots are used for various purposes (10)"],
["綾","비단 릉","릉","thin silk","damask silk (14)"],
["霽","갤 제","제","to clear up after rain; to cease be angry (22)"],
["廓","클 확","확","broad","wide","open","empty; to expand (14)"],
["量","헤아릴 량","량","measure","quantity","capacity (12)"],
["鰊","고기 이름 련","련","a herring (20)"],
["芩","풀 이름 금","금","salt marsh plant (10)"],
["綿","이어질 면","면","cotton wad; wool; soft","downy (14)"],
["侮","업신여길 모","모","insult","ridicule","disgrace (9)"],
["霾","흙비 올 매","매","misty","foggy; dust storm (22)"],
["釐","제사 지내는 고기 희","희","manage","control; 1/1000 of a foot (18)"],
["芪","단너삼 기","기","celery (10)"],
["賦","구실 부","부","tax; give; endow; army; diffuse (15)"],
["侯","과녁 후","후","marquis","lord; target in archery (9)"],
["鰌","미꾸라지 추","추","빙자할 추","해추 추","loach; squid; lizard; eel (20)"],
["廕","덮을 음","음","shade; protect","shade","cover (14)"],
["金","사람의 성 김","김","gold; metals in general; money (8)"],
["芫","고기 잡는 풀 원","원","여뀌 원","daphne genkwa","poisonous plant (10)"],
["鰍","미꾸라지 추","추","loach (20)"],
["揀","가릴 련","련","분별할 련","choose; select; pick up; gather (12)"],
["芬","향기로울 분","분","fragrance","aroma; perfume (10)"],
["芭","파초 파","파","plantain or banana palm; fragrant (10)"],
["廖","공허할 료","료","surname; name of an ancient state (14)"],
["質","바탕 질","질","matter","material","substance (15)"],
["椰","야자나무 야","야","palm tree","coconut palm (13)"],
["芮","풀 뾰족뾰족할 예","예","물가 예","방패끈 예","나라이름 예","성씨 예","tiny","small; water's edge (10)"],
["揃","가를 전","전","자를 전","가지런히 할 전","shear; (Cant.) to skin an animal (12)"],
["鰐","악어 악","악","alligator (20)"],
["芯","등심초 심","심","pith from rush (juncus effusus) (10)"],
["揄","당길 유","유","칭찬할 유","희롱할 유","lift","raise; praise; hang; flap (12)"],
["芰","세발 마름 기","기","water caltrop (10)"],
["花","꽃 화","화","flower; blossoms (10)"],
["侵","범할 침","침","침노할 침","습격할 침","흉작 침","invade","encroach upon","raid (9)"],
["廚","부엌 주","주","kitchen; closet; cupboard (15)"],
["賬","치부책 장","장","accounts; bill","debt; credit (15)"],
["鰒","전복 복","복","abalone (20)"],
["咠","참소할 집","집","to whisper. to blame to slander (9)"],
["釘","창 정","정","못 정","nail","spike; pursue closely (10)"],
["廛","전방 전","전","터전 전","a store","shop; ground alloted to a retainer (15)"],
["椴","자작나무 단","단","poplar","aspen (13)"],
["揆","헤아릴 규","규","prime minister; to guess","estimate (12)"],
["侶","짝 려","려","companion; associate with (9)"],
["鰓","뿔심 새","새","fish gills (20)"],
["賭","노름 도","도","bet","gamble","wager; compete (16)"],
["咡","입 어귀 이","이","side of mouth (9)"],
["侷","죄어칠 국","국","narrow","cramped","confined (9)"],
["芳","꽃다울 방","방","fragrant; virtuous; beautiful (10)"],
["鰕","새우 하","하","shrimp","prawn (20)"],
["咢","놀랄 악","악","sound; drumming (9)"],
["廝","부릴 시","시","천할 시","마부 시","servant (15)"],
["廞","진열할 흠","흠","to prepare horses and chariots for battle (15)"],
["侹","평평할 정","정","멀쑥할 정(9)"],
["硜","아릿아릿할 갱","갱","알랑알랑할 갱","돌소리 갱","the sound of stones knocking together (12)"],
["咤","꾸짖을 타","타","슬플 타","뿜을 타","쩍쩍 씹는소리 타","scold","bellow","shout at","roar (9)"],
["硝","초석 초","초","saltpeter","niter; > to tan (12)"],
["清","맑을 청","청","clear","pure","clean; peaceful (11)"],
["揉","풀 유","유","부드럽게 할 유","희일 유","rub","massage; crush by hand (12)"],
["廟","사당 묘","묘","temple","shrine; imperial court (15)"],
["釜","가마 부","부","cauldron","pot","kettle (10)"],
["螃","방게 방","방","crab (16)"],
["咥","허허 웃을 희","희","sound of cat; bite; laugh (9)"],
["針","바늘 침","침","침 침","바느질할 침","찌를 침","needle; pin; tack; acupuncture (10)"],
["椹","모탕 침","침","도끼바탕 침","토막 침","a chopping board (13)"],
["廠","헛간 창","창","factory","plant","workshop","mill (15)"],
["芷","구리때 지","지","angelica","type of iris (10)"],
["侻","간사할 탈","탈","가벼울 탈","간략할 탈(9)"],
["椺","들보 보","보","beam of a house (13)"],
["咦","크게 부를 재","재","웃을 재","expression of surprise (9)"],
["廡","집 무","무","처마 무","지붕 무","corridor","hallway; luxuriant (15)"],
["芸","향풀 운","운","궁궁이 운","촘촘할 운","성씨 운","rue","herb used to keep insects away; to cut grass or weeds; art","talent","ability","craft","technique","performance","acting","trick","stunt (10)"],
["廢","폐할 폐","폐","내칠 폐","집 쏠릴 폐","떨어질 폐","abrogate","terminate","discard (15)"],
["咧","새소리 렬","렬","stretch mouth","grimace","grin (9)"],
["芹","미나리 근","근","celery (10)"],
["賴","힘 입을 뢰","뢰","rely","depend on; accuse falsely (16)"],
["咨","물을 자","자","inquire","consult","discuss; plan (9)"],
["廣","넓을 광","광","broad","wide","extensive (15)"],
["芻","꼴 추","추","짐승먹이 추","mow","cut grass; hay","fodder (10)"],
["椽","서까래 연","연","beams","rafters","supports (13)"],
["咩","양 울 미","미","the bleating of sheep; (Cant.) an interrogative particle (9)"],
["廤","곳 곶","곶","to place (15)"],
["描","그릴 묘","묘","copy","trace","sketch; depict (12)"],
["便","편할 편","편","소식 편","익힐 편","오줌 편","convenience","ease; expedient (9)"],
["芼","풀 우거질 모","모","to choose; to select; greens (10)"],
["咫","길이 지","지","foot measure of Zhou dynasty (9)"],
["提","끌 제","제","hold in hand; lift in hand (12)"],
["芽","싹 아","아","bud","sprout","shoot (10)"],
["椿","참죽나무 춘","춘","대춘나무 춘","어르신네 춘","father; a plant with white flowers (13)"],
["釣","낚시 조","조","fish; fishhook; tempt","lure (11)"],
["廧","담 장","장","wall (16)"],
["咬","새소리 교","교","bite","gnaw (9)"],
["芾","작은 모양 비","비","flower; small; little; lush (10)"],
["廨","관청 해","해","공해 해","government office","public office (16)"],
["釤","낫 삼","삼","samarium (11)"],
["芿","새풀싹 잉","잉","grass which has been cut and regrown; wild grass (11)"],
["螌","가뢰 반","반","a striped","poisonous fly (16)"],
["咭","웃는 모양 길","길","(Cant.) to guard (from Engl. 'guard'); a card (from Engl. 'card'); young and pretty (from Engl. 'kid') (9)"],
["廩","곳집 름","름","granary; stockpile","store (16)"],
["賻","부의 부","부","gift of money help pay funeral (17)"],
["融","부드러울 융","융","밝을 융","융통할 융","불길 위로 오를 융","귀신이름 융","melt","fuse; blend","harmonize (16)"],
["硨","조개 이름 차","차","giant clam","tridacna gigas (12)"],
["咮","부리 주","주","a beak; to peck (9)"],
["釦","그릇에 금테 두를 구","구","button; buckle","clasp; engrave (11)"],
["購","살(구입할) 구","구","buy","purchase; hire (17)"],
["釧","팔찌 천","천","bracelet","armlet (11)"],
["咯","토할 각","각","final particle (9)"],
["鰣","준치 시","시","reeves' shad; hilsa herring (21)"],
["賽","굿할 새","새","compete","contend; contest","race (17)"],
["揖","모을 즙","즙","salute","bow; defer to","yield (12)"],
["廬","오두막집 려","려","hut","cottage; name of a mountain (19)"],
["鰤","새우 사","사","a yellowtail (fish) (21)"],
["賾","깊숙할 색","색","abstruse","deep","profound (18)"],
["鰥","환어 환","환","huge fish; widower; bachelor (21)"],
["咱","나 찰","찰","us (9)"],
["硫","유황 류","류","sulfur (12)"],
["咲","웃을 소","소","smile","laugh","giggle; snicker (9)"],
["釪","요령 우","우","바리때 우","an alms bowl; a small bell (11)"],
["硬","굳을 경","경","hard","firm","strong; obstinate (12)"],
["咳","기침 해","해","침 뱉을 해","방글방글 웃을 해","cough (9)"],
["确","확실할 학","학","sure","certain; real","true (12)"],
["揚","드높일 양","양","칭찬할 양","scatter","spread; praise (12)"],
["妁","중매 작","작","act as go-between (6)"],
["換","바꿀 환","환","change","exchange; substitute (12)"],
["鰨","큰 배 탑","탑","sole (21)"],
["廱","벽옹 옹","옹","harmonious; pool; hall (21)"],
["如","같을 여","여","만약 여","어떠할 여","이를 여","if","supposing; as if; like","as (6)"],
["釭","굴대 공","공","tire of wheel; band (11)"],
["硯","벼루 연","연","돌 연","inkstone (12)"],
["渗","스밀 참","참","soak through","infiltrate (11)"],
["揜","찾아가질 엄","엄","곤박할 엄","cover up; take by force","shut (12)"],
["咶","핥을 활","활","이야기할 활(9)"],
["妃","왕비 비","비","wife","spouse; imperial concubine (6)"],
["鰩","날치 요","요","the nautilus; the ray (21)"],
["廳","관청 청","청","hall","central room (25)"],
["咷","아이 울음 끈질길 도","도","howl","cry loudly (9)"],
["妄","허망할 망","망","absurd","foolish","reckless; false (6)"],
["渙","흩어질 환","환","scatter; scattered","dispersed (12)"],
["咸","모두 함","함","together; all","completely; united (9)"],
["渚","물가 저","저","물 갈라질 저","small sand bank","islet (11)"],
["螘","목 매어 죽는 벌레 의","의","ant (16)"],
["咺","섧게 울 훤","훤","majestic (9)"],
["揠","뽑을 알","알","to pull up","to eradicate (12)"],
["減","덜 감","감","무지러질 감","가벼울 감","물이름 감","decrease","subtract","diminish (12)"],
["鰭","지느러미 기","기","fin (21)"],
["咻","떠들 휴","휴","shout (9)"],
["終","끝낼 종","종","end; finally","in the end (11)"],
["握","잡을 악","악","손아귀 악","grasp","hold fast","take by hand (12)"],
["延","미칠 연","연","닿을 연","delay","postpone","defer (7)"],
["鰮","멸치 온","온","sardine (21)"],
["雀","참새 작","작","sparrow (11)"],
["絃","줄풍류 현","현","string on musical instrument (11)"],
["渝","빛 변할 투","투","더러워질 투","고을이름 투","change; chongqing (12)"],
["廷","조정 정","정","바를 정","곧을 정","court (7)"],
["咼","입 비뚤어질 괘","괘","chat","jaw","gossip","talk; mouth (9)"],
["鰯","멸치 약","약","정어리 약","a sardine (21)"],
["揣","헤아릴 타","타","put things under clothes (12)"],
["釵","비녀 채","채","ornamental hairpin (11)"],
["組","끈 조","조","class; section","department (11)"],
["咽","목구멍 인","인","throat; pharynx (9)"],
["妊","아이밸 임","임","conceive","be pregnant (7)"],
["渟","물 고일 정","정","도랑 정","물가 정","(of water) not flowing; clear (12)"],
["絅","끌어 쥘 경","경","unlined garment","dust coat (11)"],
["鰱","연어 련","련","silver carp","hypophthalmiathys (22)"],
["猊","사자 예","예","lion; wild beast; wild horse (11)"],
["絆","줄 반","반","loop","catch; fetter","shackle (11)"],
["渠","개천 거","거","도랑 거","휑덩그렁할 거","클 거","껄껄 웃을 거","무엇 거","ditch","canal","channel","gutter (12)"],
["咿","선웃음칠 이","이","책 읽는 소리 이","descriptive of creaking; laugh (9)"],
["建","세울 건","건","둘 건","심을 건","칼집 건","별이름 건","build","establish","erect","found (9)"],
["鰲","큰 자라 오","오","huge sea turtle (22)"],
["雄","수컷 웅","웅","영웅 웅","웅장할 웅","male of species; hero; manly (12)"],
["螞","말거머리 마","마","ant; leech (16)"],
["廻","돌 회","회","circle around; return; curving (9)"],
["渡","건널 도","도","cross","ferry over; ferry (12)"],
["鰳","준치 륵","륵","Chinese herring; shad (22)"],
["雅","아담할 아","아","elegant","graceful","refined (12)"],
["螟","마디충 명","명","kind of caterpillar","larva (16)"],
["集","모일 집","집","assemble","collect together (12)"],
["廽","돌 회","회","return","turn around; a time (10)"],
["渣","찌끼 사","사","refuse","dregs","lees","sediment (12)"],
["鰵","대구 민","민","codfish (22)"],
["雇","새 이름 호","호","employ","to hire (12)"],
["螢","개똥벌레 형","형","glow-worm","luminous insect (16)"],
["渤","바다이름 발","발","swelling; name of a sea gulf (12)"],
["硼","평돌 평","평","borax","boron (13)"],
["妑","새앙머리 파","파","갈래머리 파(7)"],
["雉","꿩 치","치","폐백 치","성 윗담 치","목맬 치","pheasant; crenellated wall (13)"],
["揩","문지를 개","개","문질러 훔칠 개","rub and wipe","dust","clean (12)"],
["渥","두터울 악","악","moisten","soak; great","deep; dye; to enrich (12)"],
["廿","스물 입","입","twenty","twentieth (4)"],
["鰷","피라미 조","조","minnow (22)"],
["螣","등사 등","등","mythological wingless dragon of (16)"],
["妒","투기할 투","투","미워할 투","강샘할 투","부러워할 투","jealous","envious (7)"],
["経","날 경","경","classic works; pass through (11)"],
["渦","소용돌이 와","와","시끄러울 와","swirl","whirlpool","eddy (12)"],
["揫","묶을 추","추","거둘 추","모을 추","grasp with hand","pinch (13)"],
["絍","짤 임","임","길쌈할 임","to lay the warp; to weave (12)"],
["妓","기생 기","기","prostitute (7)"],
["鰹","큰 가물치 견","견","물치 견","강고도리 견","skipjack","bonito (22)"],
["渨","더러울 외","외","a cove","a bay; a bend or nook in the hills; the curve of a bow (12)"],
["雌","암컷 자","자","female; feminine; gentle","soft (13)"],
["猓","긴 꼬리 원숭이 과","과","monkey (11)"],
["揭","높이 들 게","게","옷 걷고 물 건널 게","등에 질 게","raise","lift up; surname (12)"],
["鰻","뱀장어 만","만","eel (22)"],
["雍","조화로울 옹","옹","학교 옹","harmony","union; harmonious (13)"],
["揮","휘두를 휘","휘","direct; wipe away; squander (12)"],
["雎","저구 저","저","증경이 저","원앙 저","osprey","fishhawk; hold back (13)"],
["妖","요괴로울 요","요","생긋생긋 웃는 모양 요","strange","weird","supernatural (7)"],
["結","맺을 결","결","마칠 결","나중 결","몫 결","knot","tie; join","connect (12)"],
["渫","물결 출렁출렁할 접","접","beating of ocean; surging of wate (12)"],
["妗","외숙모 금","금","wife of mother's brother (7)"],
["鰾","부레 표","표","swimming bladder of fish (22)"],
["測","측량할 측","측","깊을 측","맑을 측","날카로울 측","measure","estimate","conjecture (12)"],
["猖","미쳐 날뛸 창","창","mad","wild","reckless","unruly (11)"],
["猗","불 깐 개 의","의","탄식할 의","길 의","exclamation of admiration (11)"],
["渭","물 이름 위","위","속 끓일 위","name of a river in Shanxi (12)"],
["妙","묘할 묘","묘","mysterious","subtle; exquisite (7)"],
["佀","같을 사","사","resemble (7)"],
["猘","미친개 제","제","fierce dog; furious","frenzied (11)"],
["揲","셀 설","설","to sort out the stalks used in divination; to fold (12)"],
["港","물 갈라질 항","항","항구 항","뱃길 항","port","harbor; small stream; bay (12)"],
["猙","밉살스럴 쟁","쟁","짐승이름 쟁","fierce-looking","ferocious (11)"],
["雒","수리부엉이 락","락","black horse with white mane (14)"],
["螭","교륭 리","리","a dragon whose horns have not grown; cruel (17)"],
["絕","끊을 절","절","으뜸 절","멸할 절","그칠 절","cut","sever","break off","terminate (12)"],
["佃","밭 맬 전","전","머슴꾼 전","사냥할 전","tenant farmer; be a tenant farmer (7)"],
["艀","작은 배 부","부","a sampan","lighter","barge (13)"],
["援","당길 원","원","사다리 원","구원할 원","aid","assist; lead; cite (12)"],
["妝","꾸밀 장","장","to adorn oneself","dress up","use make-up (7)"],
["猛","사나울 맹","맹","violent","savage","cruel; bold (11)"],
["絖","솜 광","광","fine silks; floss (12)"],
["揵","멜 건","건","막을 건","들 건","carry (12)"],
["雕","독수리 조","조","engrave","inlay","carve; exhaust; used for U+9D70 鵰 an eagle","vulture (16)"],
["猜","의심낼 시","시","시기할 시","두려워할 시","사나울 시","guess","conjecture","suppose; feel (11)"],
["揶","희롱할 야","야","make fun of","ridicule (12)"],
["猝","갑자기 졸","졸","abruptly","suddenly; abrupt (11)"],
["揷","꽂을 삽","삽","insert (12)"],
["渲","바림 선","선","add repeated washes of color (12)"],
["棄","버릴 기","기","reject","abandon","discard (12)"],
["但","다만 단","단","only; but","however","yet","still (7)"],
["雖","비록 수","수","벌레이름 수","although","even if (17)"],
["佇","오래 설 저","저","기다릴 저","잠깐 머물 저","wait; look towards; turn one's back on (7)"],
["佈","펼칠 포","포","spread","publicize","announce (7)"],
["渴","물 잦을 걸","걸","thirsty","parched; yearn","pine (12)"],
["螳","버마 재비 당","당","mantis (17)"],
["絛","끈 조","조","silk braid","sash (13)"],
["艅","나룻배 여","여","a despatch boat (13)"],
["佉","나라 이름 구","구","surname; name of a divine being; transliteration of Sanskrit 'kh' (7)"],
["雙","짝 쌍","쌍","set of two","pair","couple; both (18)"],
["絜","헤아릴 혈","혈","묶을 혈","a marking line; ascertain","assess; measure (12)"],
["貂","담비 초","초","marten","sable","mink (12)"],
["雚","황새 관","관","a heron; small cup (18)"],
["猢","원숭이 호","호","a kind of monkey found in W.China (12)"],
["雛","새 새끼 추","추","병아리 추","어린아이 추","chick","fledging; infant","toddler (18)"],
["艇","거룻배 정","정","작은 배 정","small boat","dugout","punt (13)"],
["棉","목화 면","면","cotton; cotton padded (12)"],
["妣","죽은 어미 비","비","one's deceased mother (7)"],
["佋","소개할 소","소","사신 소","댓수의 차례 소","continue","carry on; hand down; to join (7)"],
["雜","섞일 잡","잡","mixed","blended; mix","mingle (18)"],
["絞","목맬 교","교","twist","wring; intertwine; winch (12)"],
["游","헤엄칠 유","유","노닐 유","깃발 유","to swim; float","drift; wander","roam (12)"],
["貅","비휴 휴","휴","brave","fierce","courageous (13)"],
["妥","편안할 타","타","일 없을 타","타협할 타","떨어질 타","satisfactory","appropriate (7)"],
["棋","바둑 기","기","chess; any game similar to chess (12)"],
["位","벼슬 위","위","임금의 신분 위","자리 위","방위 위","throne; position","post; rank","status; seat (7)"],
["雝","조화로울 옹","옹","학교 옹","marsh; pool; harmonious (18)"],
["低","낮을 저","저","값 쌀 저","소리 약할 저","숙일 저","머뭇거릴 저","low; to lower","hang","bend","bow (7)"],
["渺","아득할 묘","묘","endlessly long","boundless","vast (12)"],
["雞","닭 계","계","chickens; domestic fowl (18)"],
["住","살 주","주","reside","live at","dwell","lodge; stop (7)"],
["猥","더러울 외","외","굽을 외","섞일 외","개 새끼 셋 낳을 외","vulgar","low","cheap; wanton; obscene (12)"],
["絡","헌솜 락","락","enmesh","wrap around; web","net (12)"],
["棍","묶을 혼","혼","stick","cudgel; scoundrel (12)"],
["妧","좋을 완","완","고울 완(7)"],
["絢","채색무늬 현","현","variegated","adorned; brilliant (12)"],
["佐","도울 좌","좌","assist","aid","second; subordinate (7)"],
["渼","물놀이 미","미","내 이름 미","ripples (12)"],
["妨","방해할 방","방","interfere with","impede","obstruct (7)"],
["絣","무늬없는 비단 병","병","이을 병","먹줄 튀길 병","to baste for sewing; to connect (12)"],
["佑","도울 우","우","help","protect","bless (7)"],
["螻","땅강아지 루","루","a mole cricket","Gryllotalpa africana (17)"],
["渾","흐릴 혼","혼","muddy","turbid; blend","merge","mix (12)"],
["棐","도지개 비","비","species of yew (12)"],
["離","떼 놓을 리","리","leave","depart; go away; separate (19)"],
["螽","누리 종","종","katydid (17)"],
["貊","북방 종족 맥","맥","leopard; ancient tribe in northeastern China (13)"],
["猩","성성이 성","성","species of orangutan (12)"],
["体","몸 체","체","body; group","class","body","unit; inferior (7)"],
["難","어려울 난","난","difficult","arduous","hard; unable (19)"],
["妬","투기할 투","투","자식 없는 계집 투","jealous","envious (8)"],
["佔","엿볼 점","점","숙일 점","occupy by force","usurp; possess (7)"],
["給","넉넉할 급","급","give; by","for (12)"],
["棒","몽둥이 봉","봉","stick","club","truncheon; hit (12)"],
["何","어찌 하","하","what","why","where","which","how (7)"],
["艑","거룻배 편","편","small boat (15)"],
["貌","얼굴 모","모","countenance","appearance (14)"],
["猫","고양이 묘","묘","cat (12)"],
["呀","입 벌릴 하","하","particle used to express surprise or mild emphasis; (Cant.) slurred form of the number ten (7)"],
["佖","점잔피울 필","필","가득할 필(7)"],
["貍","삵 리","리","a fox-like animal (14)"],
["絨","삶은 실 융","융","가는 베 융","silk","cotton","or woolen fabric (12)"],
["佗","다를 타","타","저 타","마음에 든든할 타","더할 타","입을 타","짊어질 타","other","he; surname; a load (7)"],
["妯","동서 축","축","wives of one's brothers; sister-i (8)"],
["棕","종려나무 종","종","hemp palm; palm tree (12)"],
["棖","문설주 정","정","막대 정","door stop","door jam; touch; a prop","post (12)"],
["絪","기운 인","인","matting; coverlet; cloudy (12)"],
["呂","음률 려","려","surname; a musical note (7)"],
["佘","산 이름 사","사","surname (7)"],
["雨","비 우","우","rain; rainy; KangXi radical 173 (8)"],
["棗","대추나무 조","조","date tree; dates","jujubes; surname (12)"],
["呃","재채기할 애","애","belch; hiccup (7)"],
["雩","기우제 우","우","offer sacrifice for rain (11)"],
["棘","가시나무 극","극","jujube tree; thorns","brambles (12)"],
["妲","계집의 이름 달","달","concubine of last ruler of the Shang dynasty (8)"],
["余","자기 여","여","남을 여","I","my","me; surname; surplus (7)"],
["雪","눈 설","설","snow; wipe away shame","avenge (11)"],
["猱","못할 노","노","a monkey with yellow hair (12)"],
["呅","웃을 문","문","(Cant.) a dollar (7)"],
["佚","흐릴 질","질","indulge in pleasures; flee (7)"],
["庁","관청 청","청","hall","central room (5)"],
["艘","배 소","소","counter for ships","vessels (16)"],
["棚","시렁 붕","붕","tent","awning; booth; shed (12)"],
["佛","흥할 필","필","도울 필","Buddha; of Buddhism; merciful percon; Buddhist image; the dead (Jap.) (7)"],
["艙","선창 창","창","hold of ship; cabin (16)"],
["貔","비휴 비","비","fox","leopard","panther (17)"],
["絮","성씨 여","여","waste cotton","raw silk or cotton (12)"],
["作","원망할 주","주","make; work; compose","write; act","perform (7)"],
["猴","원숭이 후","후","monkey","ape; monkey-like (12)"],
["艚","거룻배 조","조","ship (17)"],
["呈","보일 정","정","드러낼 정","submit","show; appear; petition (7)"],
["佝","꼽추 구","구","rickets (7)"],
["絰","질 질","질","white hempen cloth worn by mourners (12)"],
["庄","장중할 장","장","village","hamlet; villa","manor (6)"],
["砂","모래 사","사","sand; pebbles","gravel; gritty (9)"],
["佞","재주 녕","녕","flattery; glib (7)"],
["雯","구름 무늬 문","문","cloud patterns","coloring of cloud (12)"],
["統","거느릴 통","통","벼리 통","실마디 통","근본 통","이을 통","govern","command","control; unite (12)"],
["猶","어미 원숭이 유","유","같을 유","오히려 유","like","similar to","just like","as (12)"],
["告","청할 곡","곡","tell","announce","inform; accuse (7)"],
["雰","안개 분","분","atmosphere; mist","fog (12)"],
["貘","짐승 이름 맥","맥","the panther; the tapir (18)"],
["猷","꾀 유","유","그릴 유","옳을 유","탄식할 유","plan","scheme; plan","plot; way (12)"],
["絲","실 사","사","silk; fine thread; wire; strings (12)"],
["妹","누이 매","매","younger sister (8)"],
["你","너 니","니","you","second person pronoun (7)"],
["醃","절일 엄","엄","김치 엄","marinate","pickle; salt (15)"],
["絳","깊게 붉을 강","강","강초 강","deep red; river in Shanxi provinc (12)"],
["棟","용마루 동","동","the main beams supporting a house (12)"],
["庇","덮을 비","비","cover","shield","shelter","protect (7)"],
["妻","아내 처","처","wife (8)"],
["鯀","물고기 이름 곤","곤","giant fish; father of the emperor Yu (18)"],
["艟","배 동","동","ancient warship (18)"],
["棠","아가위 당","당","crab apple tree; wild plums (12)"],
["呌","부르짖을 규","규","to call; to summon (7)"],
["雲","구름 운","운","팔대손 운","하늘 운","clouds; Yunnan province (12)"],
["鯁","생선뼈 경","경","fish bones; honest","upright (18)"],
["呎","길이 단위 척","척","foot (7)"],
["貝","조개 패","패","재물 패","비단 패","sea shell; money","currency (7)"],
["棣","산앵두나무 체","체","kerria japonica plant","cherry (12)"],
["妾","첩 첩","첩","concubine (8)"],
["醇","전국술 순","순","두터울 순","삼갈 순","순수할 순","rich","good as wine; pure","unmixed (15)"],
["呏","용량의 단위 갤런(gallon) 승","승","gallon; quart (7)"],
["庋","시렁 기","기","a cupboard or pantry to store (7)"],
["貞","곧을 정","정","굳을 정","virtuous","chaste","pure; loyal (9)"],
["零","조용히 오는 비 령","령","zero; fragment","fraction (13)"],
["猾","교활할 활","활","crafty","cunning","shrewd; deceitful (13)"],
["呑","삼킬 탄","탄","휩쌀 탄","감출 탄","멸할 탄","swallow; absorb (7)"],
["醉","술취할 취","취","궤란할 취","intoxicated","drunk; addicted to (15)"],
["絹","비단 견","견","깁 견","kind of thick stiff silk (13)"],
["雷","우뢰 뢰","뢰","thunder (13)"],
["絺","가는 칡베 치","치","가는 갈포 치","fine linen; fine hemp fiber (13)"],
["砌","섬돌 체","체","stone steps","brick walk (9)"],
["艤","배 닿을 의","의","to moor a boat to the bank (19)"],
["負","질 부","부","load","burden; carry","bear (9)"],
["猿","원숭이 원","원","잔나비 원","ape (13)"],
["鯆","매가리 포","포","물아지 포","the skate or ray (18)"],
["佩","패옥 패","패","찰 패","마음에 먹을 패","물이 흘러서 합할 패","belt ornament","pendant; wear at waist","tie to the belt; respect (8)"],
["財","재물 재","재","뇌물 재","보배 재","wealth","valuables","riches (10)"],
["醋","초산 초","초","vinegar; jealousy","envy (15)"],
["序","차례 서","서","series","serial order","sequence (7)"],
["雹","누리 박","박","hail (13)"],
["佪","어정거릴 회","회","hesitate (8)"],
["鯇","잉어 환","환","carp (18)"],
["艦","싸움배 함","함","warship (20)"],
["貢","바칠 공","공","offer tribute; tribute","gifts (10)"],
["棨","창 계","계","tally or wooden pass (12)"],
["醍","맑은 술 제","제","essential oil of butter (16)"],
["電","번개 전","전","전기 전","electricity","electric; lightning (13)"],
["鯉","잉어 리","리","carp (18)"],
["艨","싸움배 몽","몽","long and narrow war-boat (20)"],
["鯊","문절망둑 사","사","shark (18)"],
["絿","급박할 구","구","urgent (13)"],
["砑","갈 아","아","to grind","to calender","to polish; to roll with a stone roller (9)"],
["醐","제호 호","호","purest cream (16)"],
["鯋","상어 사","사","모래무지 사","shark (18)"],
["砒","비상 비","비","arsenic (9)"],
["棬","휘어만든 나무그릇 권","권","bowl (12)"],
["艫","뱃머리 로","로","bow or prow of boat (22)"],
["貧","가난할 빈","빈","poor","impoverished","needy (11)"],
["佯","거짓 양","양","어정거릴 양","pretend","feign; false","deceitful (8)"],
["掀","치켜들 흔","흔","lift","raise; stir (11)"],
["貨","재화 화","화","goods","commodities","products (11)"],
["底","이룰 지","지","정할 지","이를 지","bottom","underneath","underside (8)"],
["醒","술 깰 성","성","꿈 깰 성","깨달을 성","wake up; sober up; startle (16)"],
["森","나무빽빽할 삼","삼","forest; luxuriant vegetation (12)"],
["庖","푸줏간 포","포","부엌 포","kitchen; cooking","cuisine (8)"],
["販","장사 판","판","팔 판","peddler","hawker","street merchant (11)"],
["店","가게 점","점","주막 점","shop","store; inn","hotel (8)"],
["艮","괘이름 간","간","seventh of eight diagrams (6)"],
["貪","탐할 탐","탐","욕심낼 탐","greedy","covet; covetous (11)"],
["良","좋을 량","량","good","virtuous","respectable (7)"],
["掃","쓸 소","소","상투 소","sweep","clear away; exterminate (11)"],
["佳","착할 개","개","좋을 개","좋아할 개","good","auspicious; beautiful; delightful (8)"],
["貫","꿸 관","관","a string of 1000 coins; to go through (11)"],
["棲","살 서","서","perch; roost; stay (12)"],
["掄","가릴 륜","륜","swing","brandish","flourish (11)"],
["佴","버금 이","이","a second","an assistant (8)"],
["呟","소리 현","현","to mutter","grumble","murmur (8)"],
["責","꾸짖을 책","책","one's responsibility","duty (11)"],
["淀","얕은 물 정","정","배 댈 정","shallow water","swamp; swampy (11)"],
["艱","어려울 간","간","difficult","hard; distressing (17)"],
["庚","일곱째 천간 경","경","7th heavenly stem (8)"],
["色","빛 색","색","color","tint","hue","shade; form","body; beauty","desire for beauty (6)"],
["佶","건장할 길","길","strong","robust; exact","correct (8)"],
["佷","돌아올 항","항","고을이름 항","to act contrary to (8)"],
["鯔","숭어 치","치","mullet (19)"],
["掇","주울 철","철","collect","gather up","pick up (11)"],
["蝀","무지개 동","동","rainbow (14)"],
["府","곳집 부","부","prefecture; prefect; government (8)"],
["貯","쌓을 저","저","감출 저","둘 저","store","stockpile","hoard (12)"],
["艴","발끈할 불","불","the countenance changing (11)"],
["呢","소근거릴 니","니","interrogative or emphatic final; (Cant.) this (8)"],
["佸","힘 쓸 괄","괄","meet (8)"],
["淄","물이름 치","치","검을 치","river in Shandong province (11)"],
["鯖","청어 청","청","mackerel (19)"],
["貰","빌릴 세","세","세 바칠 세","borrow; pardon; loan (12)"],
["授","줄 수","수","부칠 수","give to","transmit; confer (11)"],
["醜","추할 추","추","같을 추","무리 추","자라똥구멍 추","부끄러울 추","ugly looking","homely; disgraceful (17)"],
["艶","고울 염","염","얼굴 탐스러울 염","beautiful","sexy","voluptuous (19)"],
["掉","흔들 도","도","turn","move; shake","wag; drop down (11)"],
["淅","쌀일 석","석","water used wash rice; to wash ric (11)"],
["佹","포갤 궤","궤","depend upon; support; go against; (variant deceive) (8)"],
["淆","뒤섞일 효","효","confused","in disarray","mixed up (11)"],
["佺","신선이름 전","전","fairy (8)"],
["貲","재물 자","자","property; wealth; to count (12)"],
["棹","노 저을 도","도","oar; boat (12)"],
["掊","그러모을 부","부","to extract; injure (11)"],
["庠","학교 상","상","village school; teach (9)"],
["艸","풀 초","초","grass; KangXi radical 140 (6)"],
["佻","방정맞을 조","조","frivolous; unsteady; delay (8)"],
["貳","二의 갖은자","자","버금 이","의심낼 이","마음 변할 이","이별할 이","number two (12)"],
["棺","널 관","관","coffin (12)"],
["淇","강 이름 기","기","river in Henan province (11)"],
["呦","사슴 우는 소리 유","유","새짐승 우는 소리 유","the bleating of the deer (8)"],
["呧","꾸짖을 제","제","비방할 제","to rebuke; to scold; slander (8)"],
["掌","손바닥 장","장","palm of hand","sole of foot","paw (12)"],
["醞","술 빚을 온","온","liquor","spirits","wine; ferment (17)"],
["貴","높을 귀","귀","expensive","costly","valuable (12)"],
["佼","예쁠 교","교","beautiful","handsome","good-looking (8)"],
["佽","도울 차","차","aid; help (8)"],
["周","두루 주","주","Zhou dynasty; circumference (8)"],
["鯛","도미 조","조","pagrosomus major","porgy (19)"],
["棼","마룻대 분","분","beams in the roof of a house confused","disordered (12)"],
["貶","떨어질 폄","폄","덜릴 폄","꺾을 폄","귀양보낼 폄","decrease","lower; censure","criticize (12)"],
["砢","돌 쌓일 라","라","pile of stones (10)"],
["掎","끌 기","기","drag aside","pull; drag one foot (11)"],
["佾","춤 일","일","a row or file of dancers (8)"],
["呪","빌 주","주","curse","damn","incantation (8)"],
["鯝","참마자 고","고","fish guts (19)"],
["買","살 매","매","buy","purchase; bribe","persuade (12)"],
["掏","가릴(선택)할 도","도","take out; pull out; clean out (11)"],
["淋","물뿌릴 림","림","drip","soak","drench; perfectly (11)"],
["醢","젓 해","해","젓 담글 해","젓 담그는 형벌 해","minced pickled meat; mince (17)"],
["呫","맛 볼 첩","첩","귀에 대고 소근거릴 첩","앙징할 첩","whisper; lick","taste; petty (8)"],
["貸","빌릴 특","특","lend; borrow; pardon (12)"],
["度","꾀할 탁","탁","헤아릴 탁","벼슬이름 탁","degree","system; manner; to consider (9)"],
["砥","숫돌 지","지","a whetstone; to polish (10)"],
["座","자리 좌","좌","seat; stand","base (10)"],
["艾","쑥 애","애","artemisia","mugwort; translit. (8)"],
["貺","줄 황","황","give","grant","bestow; surname (12)"],
["砦","울타리 채","채","stockade","fort","military outpost; brothel (10)"],
["蝌","올챙이 과","과","tadpole (15)"],
["排","밀칠 배","배","row","rank","line (11)"],
["呬","숨 쉴 히","히","to rest (8)"],
["砧","방칫돌 침","침","다듬잇돌 침","anvil; flat stone; exe (10)"],
["鯡","곤이 비","비","herring (19)"],
["費","쓸 비","비","expenses","expenditures","fee (12)"],
["呭","말 수다스럴 예","예","final particle expressing consent; talkative (8)"],
["貼","붙을 첩","첩","paste to","stick on; attached to (12)"],
["鯢","암고래 예","예","Cryptobranchus japonicus; a salamander (19)"],
["蝎","전갈 갈","갈","빈대 갈","scorpion (15)"],
["呮","도사리고 앉을 기","기","to sit; (Cant.) only","merely (8)"],
["貽","끼칠 이","이","줄 이","give to","hand down","bequeath (12)"],
["庫","곳집 고","고","armory","treasury","storehouse (10)"],
["鯤","준마 이름 곤","곤","spawn; roe; fy (19)"],
["掖","겨드랑이에 낄 액","액","support with the arms; stick in","tuck in; fold up (11)"],
["淑","맑을 숙","숙","착할 숙","사모할 숙","good","pure","virtuous","charming (11)"],
["淒","쓸쓸할 처","처","bitter cold","miserable","dreary (11)"],
["庭","뜰 정","정","곧을 정","courtyard; spacious hall or yard (10)"],
["貿","바꿀 무","무","물건 살 무","trade","barter; mixed; rashly (12)"],
["呱","울 고","고","wail","crying of child; swear at (8)"],
["醪","막걸리 료","료","unclear wine","wine with dregs (18)"],
["砬","돌 소리 립","립","big stone (10)"],
["掘","뚫을 궐","궐","dig","excavate; excavate cave (11)"],
["砭","돌침 폄","폄","stone probe; pierce; counsel (10)"],
["醫","의원 의","의","병 고칠 의","cure","heal; doctor","medical (18)"],
["味","맛 미","미","뜻 미","taste","smell","odor; delicacy (8)"],
["鯧","병어 창","창","the silvery pomfret","Stromateoides argenteus (19)"],
["醬","장 장","장","any jam-like or paste-like food (18)"],
["狀","모양 형상 장","장","form; appearance; shape; official (8)"],
["呴","숨을 내쉴 구","구","breathe on; yawn; roar (8)"],
["鯨","고래 경","경","whale (19)"],
["掛","걸 괘","괘","hang","suspend; suspense (11)"],
["淖","진흙 뇨","뇨","slush; mud (11)"],
["呵","꾸짖을 가","가","scold; laughing sound; yawn (8)"],
["砰","여울 물소리 팽","팽","sound of crashing stones","bang! (10)"],
["醮","초례 초","초","religious service; Daoist or Buddhist ceremony (19)"],
["蝕","벌레먹을 식","식","일식 식","월식 식","nibble away; erode; eclipse (15)"],
["狂","미질 광","광","insane","mad; violent; wild (7)"],
["掜","부칠 예","예","비길 예","잡을 예","당길 예(11)"],
["呶","들랠 노","노","talkative; clamour; hubbub (8)"],
["醯","초 혜","혜","위대로울 혜","vinegar; pickle; acid (19)"],
["呷","빨아 마실 합","합","떠들썩할 합","벌릴 합","집오리 우는 소리 합","suck","swallow","drink (8)"],
["奄","문득 엄","엄","가릴 엄","그칠 엄","오랠 엄","ere long; remain","tarry; feeble (8)"],
["鯪","천산갑 릉","릉","carp (19)"],
["淘","일 도","도","wash in sieve; weed out (11)"],
["庳","집 낮을 비","비","a low-built house (11)"],
["蝗","누리 황","황","kind of locust (15)"],
["砲","큰 대포 포","포","돌쇠뇌 포","돌 튀기는 화살 포","gun","cannon (10)"],
["鯫","송사리 추","추","따라지 추","small fish; small; minnow (19)"],
["淙","물 소리 종","종","gurgling sound of water (11)"],
["呸","다투는 소리 비","비","expression of reprimand (8)"],
["掟","둘러칠 정","정","law","commandments","regulations; (Cant.) to throw (11)"],
["狄","북방 오랑캐 적","적","꿩 그린 옷 적","멀 적","tribe from northern china; surnam (7)"],
["醱","술 괼 발","발","to brew for the second time (19)"],
["紀","벼리 기","기","record","annal","historical account (9)"],
["淚","눈물 루","루","tears; weep","cry (11)"],
["呹","소리 질","질","(of animals) to eat grass (8)"],
["呺","큰체할 효","효","voice of anger vast","spacious (8)"],
["蝙","박쥐 편","편","bat (15)"],
["破","깨뜨릴 파","파","다할 파","갈라질 파","break","ruin","destroy; rout (10)"],
["奇","기이할 기","기","strange","unusual","uncanny","occult (8)"],
["醲","텁텁한 술 농","농","strong wine; dense","thick (20)"],
["掠","볼기칠 량","량","rob","ransack","plunder; pass by (11)"],
["庵","초막 암","암","Buddhist monastery or nunnery (11)"],
["採","캘 채","채","gather","collect; pick","select (11)"],
["紂","껑거리끈 주","주","name of an emperor; saddle part (9)"],
["奈","어찌 내","내","but","how; bear","stand","endure (8)"],
["呻","읊조릴 신","신","끙끙거릴 신","groan","moan; recite with intonation (8)"],
["庶","여러 서","서","numerous","various; multitude (11)"],
["呼","부를 호","호","숨 내쉴 호","breathe sigh","exhale; call","shout (8)"],
["探","더듬을 탐","탐","찾을 탐","취할 탐","염탐할 탐","find","locate; search","grope for (11)"],
["奉","받들 봉","봉","offer; receive; serve; respect (8)"],
["醴","단술 례","례","sweet wine; sweet spring (20)"],
["紃","신에 실선 두를 순","순","실 띠 순","법 순","좇을 순","a cord (9)"],
["淝","강 이름 비","비","name of an affluent of the Poyang Lake (11)"],
["康","편안할 강","강","즐거울 강","다섯거리 강","peaceful","quiet; happy","healthy (11)"],
["掣","끌어당길 체","체","drag","pull; hinder by pulling back (12)"],
["醵","술추렴 거","거","추렴 걷을 거","contribute for drinks; pool money (20)"],
["約","약속할 요","요","treaty","agreement","covenant (9)"],
["淞","강이름 송","송","name of a river in Jiangsu (11)"],
["命","목숨 명","명","life; destiny","fate","luck; an order","instruction (8)"],
["庸","쓸 용","용","떳떳할 용","수고로울 용","어리석을 용","물도랑 용","usual","common","ordinary","mediocre (11)"],
["狉","삵의 새끼 비","비","fox-cub (8)"],
["紅","붉을 홍","홍","red","vermillion; blush","flush (9)"],
["接","사귈 접","접","잇닿을 접","receive; continue; catch; connect (11)"],
["紆","얽힐 우","우","굽을 우","bend","turn","twist; distort (9)"],
["呿","입 딱 벌일 거","거","to yawn (8)"],
["紇","질 낮은 명주실 흘","흘","inferior silk; tassel","fringe (9)"],
["隅","모퉁이 우","우","기슭 우","corner","nook","remote place (12)"],
["蝟","고슴도치 위","위","hedgehog (15)"],
["淡","물모양 담","담","weak","watery; insipid","tasteless (11)"],
["紈","흰 비단 환","환","white silk","fine silk; gauze (9)"],
["奎","별 규","규","stride of man; one of the twenty-eight lunar mansions (9)"],
["隆","클 륭","륭","prosperous","plentiful","abundant (12)"],
["蝠","박쥐 복","복","kind of bat (15)"],
["控","당길 공","공","accuse","charge; control (11)"],
["淢","빨리 흐를 역","역","ditch (11)"],
["奏","아뢸 주","주","memorialize emperor; report (9)"],
["奐","빛날 환","환","be numerous","brilliant (9)"],
["推","밀 퇴","퇴","push","expel; push forward (11)"],
["隈","모퉁이 외","외","cove","bay","inlet (12)"],
["狎","친근할 압","압","업신여길 압","be familiar with; disrespect (8)"],
["紊","어지러울 문","문","confused","disorder (10)"],
["淤","앙금 어","어","진흙 어","mud","sediment; clog up","silt up (11)"],
["庾","노적 유","유","곡식 셈하는 단위 휘 유","granary; storehouse (12)"],
["鯷","메기 제","제","anchovy (20)"],
["契","부족 이름 글","글","deed","contract","bond; engrave (9)"],
["隉","위태할 얼","얼","in disorder; a dangerous condition of the State (12)"],
["紋","무늬 문","문","주름 문","line","streak","stripe; wrinkle (10)"],
["掩","거둘 엄","엄","가릴 엄","to cover (with the hand); shut","conceal; ambush (11)"],
["淥","밭을 록","록","strain; (Cant.) to scald (11)"],
["狐","여우 호","호","species of fox (8)"],
["措","둘 책","책","place; collect","arrange; employ (11)"],
["隊","군대 대","대","team","group; army unit (12)"],
["奓","오만할 차","차","extravagant (9)"],
["隋","수나라 수","수","Sui dynasty; surname (12)"],
["蝥","해충 모","모","a fly which is used similarly to cantharides (15)"],
["蝦","새우 하","하","shrimp","prawn (15)"],
["淨","맑을 정","정","조촐할 정","pure","clean","unspoiled (11)"],
["奔","달릴 분","분","run fast","flee; rush about; run (9)"],
["狒","비비 비","비","baboon (8)"],
["納","바칠 납","납","admit","take","receive","accept (10)"],
["掬","두 손으로 움킬 국","국","to grasp or hold with both hands (11)"],
["隍","해자 황","황","dry ditch","dry moat (12)"],
["奕","아름다울 혁","혁","클 혁","차례 혁","바둑 혁","빛날 혁","근심할 혁","in sequence","orderly; abundant (9)"],
["階","섬돌 계","계","stairs","steps; rank","degree (12)"],
["蝨","이 슬","슬","louse; bed bug (15)"],
["淪","물놀이 륜","륜","be lost; sink","be submerged (11)"],
["套","전례 투","투","case","cover","wrapper","envelope (10)"],
["鯽","붕어 즉","즉","carassius auratus","crucian carp (20)"],
["紐","잡색비단 뉴","뉴","knot; button; handle","knob; tie (10)"],
["淫","방탕할 음","음","간음할 음","음란할 음","넘칠 음","적실 음","오랠 음","obscene","licentious","lewd (11)"],
["奘","클 장","장","large","powerful","stout","thick (10)"],
["淬","칼 담글 쉬","쉬","물들일 쉬","범할 쉬","temper; dye; soak; change","alter (11)"],
["鯿","병어 편","편","bream (20)"],
["伀","두려워할 종","종","excited (6)"],
["狗","개 구","구","dog","canis familiaris (8)"],
["淮","강 이름 회","회","river in Anhui province (11)"],
["奚","어찌 해","해","종 해","큰 배 해","where? what? how? why?; servant (10)"],
["企","꾀할 기","기","plan a project; stand on tiptoe (6)"],
["紓","느슨할 서","서","loosen","relax","relieve; extricate (10)"],
["狙","원숭이 처","처","an ape","monkey; to spy","watch for; to lie (8)"],
["純","선으로 두를 준","준","pure","clean","simple (10)"],
["梁","푸조나무 량","량","bridge; beam; rafters; surname (11)"],
["伃","궁녀 벼슬이름 여","여","아름다울 여","handsome (6)"],
["蝮","살무사 복","복","venomous snake","viper (15)"],
["紕","가선 비","비","spoiled silk; hem of dress; mista (10)"],
["隔","막을 격","격","멀 격","separate","partition (13)"],
["梃","외줄기 정","정","막대 정","꼿꼿할 정","a club; a stalk; straight (11)"],
["隕","폭원 원","원","fall","slip; let fall; die (13)"],
["舁","마주들 여","여","carry on one's shoulder (10)"],
["紗","깁 사","사","gauze","thin silk; yarn","thread (10)"],
["深","깊을 심","심","deep; depth; far; very","extreme (11)"],
["舂","방아 찧을 용","용","쇠북소리 용","해가 질 용","grind in mortar (11)"],
["紘","갓끈 굉","굉","string; vast","expansive (10)"],
["紙","종이 지","지","paper (10)"],
["梅","매화나무 매","매","plums; prunes; surname (11)"],
["隗","높을 외","외","성씨 외","high; lofty; surname (13)"],
["舃","신 석","석","a shoe; the sole of a shoe; magpie (12)"],
["淳","맑을 순","순","순박할 순","honest","simple","unsophisticated; cyanogen; ethane dinitrile (11)"],
["奠","정할 전","전","pay respect; settle (12)"],
["梆","목어 방","방","watchman's rattle (11)"],
["伈","두려워할 침","침","nervous","fearful (6)"],
["級","등급 급","급","level","rank","class; grade (10)"],
["豁","뚫린 골 활","활","open up","clear; exempt (17)"],
["伉","강직할 항","항","우뚝할 항","배필 항","겨룰 항","compare","match; pair; spouse (6)"],
["隙","틈 극","극","crack","split","fissure; grudge (13)"],
["舅","외삼촌 구","구","mother's brother","uncle (13)"],
["紛","어지러워질 분","분","in disorder","scattered","tangled (10)"],
["淵","못 연","연","깊을 연","북소리 둥둥할 연","모래톱 연","gulf","abyss","deep (11)"],
["奢","사치할 사","사","extravagant","wasteful; exaggerate (12)"],
["伊","저 이","이","이 이","오직 이","답답할 이","성씨 이","third person pronoun; he","she","this","that (6)"],
["狡","교활할 교","교","cunning","deceitful","treacherous (9)"],
["紜","얼크러질 운","운","confused","in disorder; numerous (10)"],
["淶","강 이름 래","래","river in Hebei province; creek (11)"],
["狢","오소리 학","학","animal name (9)"],
["際","사이 제","제","border","boundary","juncture (14)"],
["伋","속일 급","급","deceptive (6)"],
["與","너울너울할 여","여","더불어 여","같을 여","미칠 여","and; with; to; for; give","grant (14)"],
["混","섞을 혼","혼","to mix","blend","mingle; to bumble along (11)"],
["興","일 흥","흥","thrive","prosper","flourish (15)"],
["淸","맑을 청","청","clear (11)"],
["蝶","들나비 접","접","butterfly (15)"],
["障","막을 장","장","separate; shield; barricade (14)"],
["伍","五의 갖은자","자","다섯 사람 오","무리 오","five","company of five; troops (6)"],
["掾","아전 연","연","기인할 연","a general designation of officials (12)"],
["淹","담글 엄","엄","오래머물 엄","drown; cover with liquid","steep (11)"],
["淺","얕을 천","천","shallow","not deep; superficial (11)"],
["伎","재주 기","기","talent","skill","ability (6)"],
["蝸","달팽이 와","와","a snail","Eulota callizoma (15)"],
["舊","옛 구","구","old","ancient; former","past (18)"],
["豆","콩 두","두","beans","peas; bean-shaped (7)"],
["素","흴 소","소","생초 소","빌 소","바탕 소","원래 소","white (silk); plain; vegetarian; formerly; normally (10)"],
["添","더할 첨","첨","append","add to; increase (11)"],
["奧","따스할 욱","욱","모퉁이 욱","mysterious","obscure","profound (13)"],
["伏","알 안을 부","부","crouch","crawl","lie hidden","conceal (6)"],
["豇","광저기 강","강","a small kidney bean (10)"],
["紡","자을 방","방","spin","reel","weave; reeled pongee (10)"],
["伐","칠 벌","벌","cut down","subjugate","attack (6)"],
["舌","혀 설","설","tongue; clapper of bell; KangXi radical 135 (6)"],
["豈","어찌 기","기","how? what? (10)"],
["索","찾을 색","색","large rope","cable; rules","laws; to demand","to exact; to search","inquire; isolated (10)"],
["淼","물 아득할 묘","묘","a wide expanse of water (12)"],
["休","쉴 휴","휴","rest","stop; retire; do not! (6)"],
["梏","쇠고랑 곡","곡","handcuffs","manacles","fetters (11)"],
["奩","화장 상자 렴","렴","lady's vanity case; trousseau (14)"],
["舍","집 사","사","house","dwelling; dwell","reside (8)"],
["奪","빼앗을 탈","탈","잃어버릴 탈","좁은 길 탈","깎을 탈","take by force","rob","snatch (14)"],
["豉","메주 시","시","콩자반 시","fermented beans (11)"],
["奫","물 깊을 윤","윤","물이 충충할 윤(14)"],
["狩","겨울사냥 수","수","순행할 수","winter hunting; imperial tour (9)"],
["隤","무너질 퇴","퇴","미끄러질 퇴","부드러울 퇴","땅이름 퇴","to fall in ruins; to collapse (15)"],
["舐","핥을 지","지","lick with tongue (10)"],
["奬","도울 장","장","prize","reward; give award to (14)"],
["梓","가래나무 재","재","목수 재","우러러 볼 재","고향 재","catalpa ovata (11)"],
["奭","성대할 학","학","red; anger; ire; surname (15)"],
["豌","동부 완","완","완두 완","peas (15)"],
["梔","치자 치","치","gardenia (11)"],
["奮","떨칠 분","분","strive","exert effort; arouse (16)"],
["舒","펼 서","서","open up","unfold","stretch out; comfortable","easy (12)"],
["隧","무덤길 수","수","구멍 수","길 수","tunnel","underground passageway","path to a tomb (16)"],
["豎","세울 수","수","내시 수","더벅머리 수","vertical; perpendicular; upright (15)"],
["隨","따를 수","수","괘 이름 수","나라이름 수","맡길 수","follow","listen to","submit; to accompany; subsequently","then (16)"],
["吃","말더듬을 흘","흘","eat; drink; suffer","endure","bear (6)"],
["豐","풍년 풍","풍","두터울 풍","왕콩 풍","괘 이름 풍","abundant","lush","bountiful","plenty (18)"],
["梗","대개 경","경","stem of flower","branch of plant (11)"],
["隩","감출 오","오","물가 언덕 오","profound","subtle; warm (16)"],
["紫","자주빛 자","자","purple","violet; amethyst; surname (11)"],
["伙","세간 화","화","companion","colleague; utensils (6)"],
["險","험할 험","험","narrow pass","strategic point","dangerous (16)"],
["紬","명주 주","주","kind of thin silk (11)"],
["各","각각 각","각","each","individually","every","all (6)"],
["女","계집 녀","녀","woman","girl; feminine; rad. 38 (3)"],
["奴","종 노","노","slave","servant (5)"],
["吆","애통하는 소리 요","요","bawl","yell","shout","cry out (6)"],
["舘","객사 관","관","a mansion; a building (16)"],
["紮","감을 찰","찰","tie","fasten","bind (11)"],
["狴","짐승이름 폐","폐","옥 폐","들개 폐","a kind of tapir; a fierce beast depicted on the door of prisons (10)"],
["奶","젖 내","내","milk; woman's breasts; nurse (5)"],
["合","합할 합","합","같을 합","짝 합","대답할 합","combine","unite","join; gather (6)"],
["幃","향낭 위","위","홑휘장 위","curtain that forms wall (12)"],
["豕","돼지 시","시","돝 시","a pig","boar; KangXi radical 152 (7)"],
["累","묶을 루","루","tired; implicate","involve; bother (11)"],
["豖","발 얽은 돼지걸음 축","축","a shackled pig (8)"],
["舛","어그러질 천","천","oppose","deviate","be contrary to (6)"],
["吉","길할 길","길","lucky","propitious","good (6)"],
["幄","장막 악","악","tent; mosquito net (12)"],
["細","가늘 세","세","작을 세","좀놈 세","fine","tiny; slender","thin (11)"],
["幅","폭 폭","폭","겉치레할 폭","piece","strip","breadth of","hem (12)"],
["條","가지 조","조","clause","condition; string","stripe (11)"],
["奸","범할 간","간","간악할 간","crafty","villainous","false (6)"],
["隰","진펄 습","습","low","damp land","marsh","swamp (17)"],
["舜","순임금 순","순","무궁화 순","legendary ruler (12)"],
["紱","인끈 불","불","ribbon or cord used attach ornaments (11)"],
["狷","고집스러울 견","견","조수할 견","편협할 견","rash","impetuous","impulsive (10)"],
["紲","고삐 설","설","bridle; halter (11)"],
["梟","올배미 효","효","owl thus","something evil (11)"],
["吋","인치(inch) 촌","촌","inch (6)"],
["隱","숨을 은","은","아낄 은","속걱정할 은","불쌍히 여길 은","점칠 은","의지할 은","쌓을 은","hide","conceal; hidden","secret (17)"],
["舞","춤출 무","무","dance","posture","prance; brandish (14)"],
["紳","큰 띠 신","신","벼슬아치 신","girdle; tie","bind; gentry (11)"],
["狹","좁을 협","협","narrow","limited; narrow-minded; to pinch (10)"],
["鮀","모래무지 타","타","snakefish (16)"],
["舟","배 주","주","boat","ship; KangXi radical 137 (6)"],
["梠","평고대 려","려","a small beam supporting the rafters at the eaves (11)"],
["同","같을 동","동","same","similar; together with (6)"],
["豚","돼지 돈","돈","small pig","suckling pig; suffle (11)"],
["隳","무너뜨릴 휴","휴","to destroy; to overthrow (18)"],
["紵","모시 저","저","ramie; sack cloth (11)"],
["梡","네 발 도마 완","완","토막나무 완","tray for carrying sacrificial meat (11)"],
["名","이름 명","명","name","rank","title","position (6)"],
["狺","뭇 개 짖는 소리 은","은","the snarling of dogs (10)"],
["矇","소경 몽","몽","stupid","ignorant; blind (19)"],
["后","임금 후","후","queen","empress","sovereign (6)"],
["好","좋을 호","호","옳을 호","good","excellent","fine; well (6)"],
["梢","나무 끝 초","초","pointed tip of something long like a branch; rudder (11)"],
["隴","고개 이름 롱","롱","mountain located between Shanxi (19)"],
["狻","사자 산","산","a fabulous beast (10)"],
["鮃","가자미 평","평","a flatfish","flounder (16)"],
["吏","벼슬아치 리","리","government official","magistrate (6)"],
["舡","오나라 배 강","강","boat","ship (9)"],
["狼","이리 랑","랑","wolf (10)"],
["矉","찡그릴 빈","빈","an angry glare; to open the eyes with anger (19)"],
["幌","휘장 황","황","curtain","cloth screen (13)"],
["狽","이리 패","패","a legendary animal with short forelegs which rode a wolf (10)"],
["吐","토할 토","토","나올 토","펼 토","vomit","spew out","cough up (6)"],
["隶","밑 이","이","subservient; servant; rad. 171 (8)"],
["酈","땅 이름 력","력","place in today's Henan province (22)"],
["向","향할 향","향","나아갈 향","북쪽 창 향","기울어질 향","toward","direction","trend (6)"],
["酉","별 유","유","열째 지지 유","나아갈 유","10th terrestrial branch; a wine vessel (7)"],
["紹","이을 소","소","얽을 소","소개할 소","continue","carry on; hand down; to join (11)"],
["吒","조롱할 탁","탁","shout","roar","bellow; scold (6)"],
["酊","비틀거릴 정","정","drunk","intoxicated (9)"],
["幎","덮을 멱","멱","cover-cloth","cover with cloth (13)"],
["隸","붙을 례","례","be subservient to; servant (17)"],
["紺","아청 감","감","보랏빛 감","dark blue or reddish color (11)"],
["矍","두리번거릴 확","확","look about in fright or alarm (20)"],
["隹","새 추","추","bird; KangXi radical 172 (8)"],
["酋","괴수 추","추","두목 추","숙 익을 추","끝날 추","chief of tribe","chieftain (9)"],
["梧","머귀나무 오","오","버틸 오","Sterculia platanifolia (11)"],
["象","코끼리 상","상","elephant; ivory; figure","image (12)"],
["豢","기를 환","환","domestic animals; feed","raise (13)"],
["梨","태나무 리","리","pear; opera; cut","slash (11)"],
["酌","따를 작","작","serve wine; feast; deliberate (10)"],
["隻","새 한 마리 척","척","single","one of pair","lone (10)"],
["配","아내 배","배","match","pair; equal; blend (10)"],
["豣","노루 연","연","pig of 3 years (13)"],
["隼","새매 준","준","aquiline (nose); a falcon (10)"],
["酎","세 번 빚은 술 주","주","double-fermented wine","vintage wine (10)"],
["航","쌍배 항","항","배질할 항","sail; navigate; ship","boat (10)"],
["梭","북 사","사","weaver's shuttle; go to and fro (11)"],
["幔","막 만","만","curtain","screen","tent (14)"],
["伯","우두머리 패","패","으뜸 패","older brother; father's elder brother; senior male 'sire'; feudal rank 'count' (7)"],
["舫","배 방","방","fancy boat","yacht (10)"],
["豨","멧돼지 희","희","pig","hog (14)"],
["酒","술 주","주","wine","spirits","liquor","alcoholic beverage (10)"],
["吚","탄식할 의","의","신음할 의","used to represent sound (7)"],
["幕","막 막","막","curtain","screen","tent (14)"],
["估","값 고","고","merchant; estimate","guess","presume (7)"],
["般","옮길 반","반","sort","manner","kind","category (10)"],
["梯","사다리 제","제","ladder","steps","stairs; lean (11)"],
["鮎","메기 점","점","sheatfish","parasilurus asotus (16)"],
["君","임금 군","군","sovereign","monarch","ruler","chief","prince (7)"],
["械","형틀 계","계","weapons; implements","instruments (11)"],
["捂","부닥칠 오","오","거스릴 오","resist (10)"],
["豪","호걸 호","호","귀인 호","brave","heroic","chivalrous (14)"],
["鮐","복 태","태","늙은이 등에 복 무늬 날 태","blowfish","globefish","tetraodon (16)"],
["矗","곧을 촉","촉","우뚝 솟을 촉","straight","upright","erect","lofty (24)"],
["幘","건 책","책","turban; conical cap (14)"],
["梱","문지방 곤","곤","doorsill; doorjamb (11)"],
["吝","아낄 린","린","stingy","miserly","parsimonious (7)"],
["鮑","절인 생선 포","포","성씨 포","abalone; dried fish; surname (16)"],
["酖","술 즐길 탐","탐","wine with bird poison added (11)"],
["捄","흙파올릴 구","구","long and curved (10)"],
["伴","짝 반","반","companion","comrade","partner; accompany (7)"],
["豫","미리 예","예","beforehand (16)"],
["酗","주정할 후","후","drunk","to become violent under the influence of alcohol (11)"],
["豬","돼지 저","저","물이 괼 저","못이름 저","pig","hog (16)"],
["吟","읊을 음","음","끙끙거릴 음","탄식할 음","턱 끄덕거릴 음","노래할 음","말 더듬을 음","울 음","sing","hum; recite; type of poetry (7)"],
["鮒","붕어 부","부","carp","carassicus auratus (16)"],
["梳","얼레빗 소","소","comb; brush (11)"],
["吠","짖을 폐","폐","땅이름 폐","bark (7)"],
["捆","두드릴 곤","곤","tie up; bind","truss up; bundle (10)"],
["伶","영리할 령","령","악공 령","광대 령","lonely","solitary; actor (7)"],
["鮓","젓 자","자","minced and salted fish; to preserve (16)"],
["吡","새소리 필","필","blame (7)"],
["舳","고물 축","축","배 꼬리 축","stern of ship (11)"],
["蜀","촉규화벌레 촉","촉","큰 닭 촉","name of an ancient state (13)"],
["矚","볼 촉","촉","watch carefully","stare at","focus on (26)"],
["涂","길 도","도","surname; name of certain rivers (10)"],
["梵","범어 범","범","Buddhist","Sanskrit (11)"],
["吢","개가 토할 심","심","vomiting of a dog (7)"],
["矛","창 모","모","spear","lance; KangXi radical 110 (5)"],
["梶","나무 끝 미","미","oar","shaft (11)"],
["舵","키 타","타","rudder","helm (11)"],
["幞","건 복","복","turban (15)"],
["伸","펼 신","신","다스릴 신","기지개 신","extend","stretch out","open up; trust (7)"],
["蜂","벌 봉","봉","bee","wasp","hornet (13)"],
["矜","불쌍히 여길 긍","긍","pity","feel sorry for","show sympat (9)"],
["幟","깃대 치","치","flag","pennant; sign; fasten (15)"],
["捉","잡을 착","착","grasp","clutch; catch","seize (10)"],
["涅","죽을 녈","녈","blacken; black mud","slime; tatto (10)"],
["舶","배 박","박","large","ocean-going vessel (11)"],
["蜃","큰 조개 신","신","이무기 신","술잔 신","marine monster which can change its shape; water spouts; clams (13)"],
["舷","뱃전 현","현","the sides of a boat","bulwarks; a gunwale (11)"],
["伺","엿볼 사","사","serve","wait upon","attend; examine (7)"],
["矞","송곳질할 율","율","상서구름 율","궤휼할 율","귀신이름 율","bore with awl; bright","charming (12)"],
["伻","부릴 팽","팽","좇을 팽","messenger","emissary; send emissary (7)"],
["涇","통할 경","경","name of a river (10)"],
["否","막힐 비","비","더러울 비","악할 비","not","no","negative; final particle (7)"],
["幡","기 번","번","pennant","banner","streamer","flag (15)"],
["舸","큰 배 가","가","large boat","barge (11)"],
["豳","나라 이름 빈","빈","a Zhou-dynasty state (17)"],
["蜆","가막조개 현","현","호수이름 현","a variety of bivalves (13)"],
["捌","깨뜨릴 팔","팔","나눌 팔","칠 팔","break open","split open; an accounting version of the numeral eight (10)"],
["吧","아이 다툴 파","파","emphatic final particle (7)"],
["消","다할 소","소","꺼질 소","풀릴 소","해어질 소","vanish","die out; melt away (10)"],
["幢","기 당","당","carriage curtain; sun screen (15)"],
["似","같을 사","사","resemble","similar to; as if","seem (7)"],
["鮚","대합 길","길","clam; oyster (17)"],
["船","배 선","선","ship","boat","vessel (11)"],
["捍","막을 한","한","ward off","guard against","defend (10)"],
["幣","폐백 폐","폐","예물 폐","재물 폐","돈 폐","currency","coins","legal tender (15)"],
["涉","물 건널 섭","섭","지나칠 섭","돌아다닐 섭","ford stream","wade across (10)"],
["吨","말 분명치 못할 둔","둔","metric ton; (Cant.) to babble","gibberish (7)"],
["伽","절 가","가","transcription of sanskrit 'gha' in buddhist texts ('samgha'","etc.); (nursing; attending; entertainer) (Jap.); tample; in Chinese this character is not used alone (7)"],
["捎","덜 소","소","흔들거릴 소","추릴 소","버릴 소","to select; to take; to carry (10)"],
["吩","뿜을 분","분","order","command","instruct (7)"],
["伾","힘셀 비","비","mighty (7)"],
["蜈","지네 오","오","centipede (13)"],
["矢","살 시","시","소리살 시","곧을 시","베풀 시","똥 시","arrow","dart; vow","swear (5)"],
["捏","이길 날","날","pick with fingers; knead; mold (10)"],
["吪","움직일 와","와","거짓말 와","사투리 와","move (7)"],
["鮝","건어 상","상","dried fish (17)"],
["蜉","하루살이 부","부","mayfly; kind of large insect (13)"],
["矣","말 그칠 의","의","particle of completed action (7)"],
["豸","발 없는 벌레 치","치","풀 치","해치양 치","radical 153; legless insects; a legendary beast (7)"],
["捐","버릴 연","연","병들어 죽을 연","contribute; give up","renounce (10)"],
["涌","물 넘칠 용","용","날칠 용","surge up","bubble up","gush forth (10)"],
["酢","초 초","초","toast one's host with wine; to express juice by pressing (12)"],
["鮞","곤이 알 이","이","roe (17)"],
["蜊","참조개 리","리","clam (13)"],
["含","머금을 함","함","hold in mouth; cherish; contain (7)"],
["豹","표범 표","표","아롱범 표","leopard","panther; surname (10)"],
["知","알 지","지","know","perceive","comprehend (8)"],
["鮟","아귀 안","안","anglerfish (17)"],
["蜋","사마귀 랑","랑","mantis","dung beetle; insect (13)"],
["幨","휘장 첨","첨","curtain (16)"],
["涎","침 연","연","물 졸졸 흐를 연","saliva (10)"],
["听","벙긋거릴 은","은","들을 은","입이 큰 모양 은","hear; understand; obey","comply (7)"],
["酤","계명주 고","고","to deal in spirits (12)"],
["豺","승냥이 시","시","늑대 시","wolf; cruel","wicked","mean (10)"],
["吭","목구멍 항","항","새 목구멍 항","throat (7)"],
["酥","타락죽 소","소","butter; flaky","crispy","light","fluffy (12)"],
["矧","하물며 신","신","잇속 드러날 신","much more","still more; the gums (9)"],
["吮","빨 전","전","suck with mouth","sip","lick (7)"],
["幪","덮을 몽","몽","cover","shelter","screen; protect (17)"],
["酦","술기운 발","발","fermentation","brewing (13)"],
["捕","사로잡을 포","포","arrest","catch","seize (10)"],
["幫","도울 방","방","to help","assist; to defend; shoe upper (17)"],
["矩","곡척 구","구","carpenter's square","ruler","rule (10)"],
["幬","휘장 주","주","cover up; curtain (17)"],
["捗","거둘 척","척","make progress (10)"],
["吱","가는 소리 지","지","chirping","squeaking","hissing (7)"],
["酩","술 취할 명","명","drunk; intoxicated; tipsy (13)"],
["蜑","오랑캐 이름 단","단","egg (13)"],
["涓","물방울 떨어질 연","연","조촐할 연","brook","stream; select; pure (10)"],
["吲","비웃을 신","신","smile at; sneer at (7)"],
["酪","진한 유즙 락","락","cream","cheese; koumiss (13)"],
["涔","괸물 잠","잠","river in Shaanxi; murky torrent (10)"],
["短","짧을 단","단","short; brief; deficient","lacking (12)"],
["幰","수레 안장 헌","헌","휘장 헌","curtain at front of carriage (19)"],
["涕","눈물 체","체","tear; snivel","nasal mucus (10)"],
["犀","무소 서","서","rhinoceros; sharp","well-tempered (12)"],
["酬","술 권할 수","수","갚을 수","toast; reward","recompense (13)"],
["鮧","복 제","제","the sheat fish (17)"],
["矮","난장이 왜","왜","short","dwarf; low (13)"],
["鮨","젓갈 지","지","seasoned rice mixed with fish or vegetables (17)"],
["犁","얼룩소 리","리","plow (11)"],
["涖","다다를 리","리","river in Hebei province; creek (10)"],
["吵","지저궐 초","초","argue","dispute; disturb","annoy (7)"],
["幱","내리다지 란","란","official dress (20)"],
["矯","바로잡을 교","교","correct","rectify","straighten out (17)"],
["矰","주살 증","증","arrow with attached silk cord (17)"],
["吶","말 느리게 할 열","열","함성 지를 열","raise voice","yell out loud","shout; stammer (7)"],
["干","생강 강","강","oppose","offend; invade; dried (3)"],
["吷","마실 혈","혈","새소리 혈","작은소리 혈(7)"],
["平","평탄할 평","평","바를 평","고를 평","다스릴 평","쉬울 평","풍년 평","화친할 평","재관관 평","flat","level","even; peaceful (5)"],
["鮪","상어 유","유","kind of sturgeon","tuna (17)"],
["吸","숨 들이쉴 흡","흡","inhale","suck in; absorb; attract (7)"],
["鮫","상어 교","교","shark (17)"],
["吹","불 취","취","숨쉴 취","악기 불 취","부를 취","충동할 취","blow; puff; brag","boast (7)"],
["蜘","거미 지","지","spider (14)"],
["年","해 년","년","year; new-years; person's age (6)"],
["夆","끌 봉","봉","resist (7)"],
["石","돌 석","석","stone","rock","mineral; rad. 112 (5)"],
["鮭","어채 해","해","복 해","salmon; spheroides vermicularis (17)"],
["酲","술병 정","정","술 깰 정","hangover; uncomfortable (14)"],
["陀","비탈 타","타","땅이름 타","steep bank","rough terrain (8)"],
["吻","입술 문","문","입가 문","kiss; the lips; coinciding (7)"],
["鮮","고울 선","선","fresh","new","delicious; rare","few (17)"],
["蜚","바퀴 비","비","cockroach (14)"],
["吼","울 후","후","roar","shout; bark","howl (7)"],
["犇","달아날 분","분","run fast","flee; rush about; run (12)"],
["酴","두번 빚은 술 도","도","leaven","yeast; wine (14)"],
["酵","술밑 효","효","yeast","leaven (14)"],
["幸","다행할 행","행","바랄 행","luck(ily)","favor","fortunately (8)"],
["陂","기울어질 피","피","치우칠 피","dam","embankment; reservoir (8)"],
["蜜","꿀 밀","밀","honey; sweet; nectar (14)"],
["糅","섞일 유","유","얽힐 유","잡곡밥 유","blend","mix; mixed (15)"],
["吾","자기 오","오","아들 오","i","my","our; resist","impede (7)"],
["幹","줄기 간","간","우물난간 간","trunk of tree or of human body (13)"],
["酶","술밑 매","매","enzymes (14)"],
["酷","독할 혹","혹","strong","stimulating; very (14)"],
["幺","작을 요","요","어릴 요","one; tiny","small (3)"],
["附","붙을 부","부","adhere to","append; rely on near to (8)"],
["幻","변할 환","환","illusion","fantasy","mirage (4)"],
["酸","초 산","산","tart","sour; acid; stiff; spoiled (14)"],
["矺","나무이름 탁","탁","(Cant.) to press steadily (8)"],
["幼","어릴 유","유","사랑할 유","infant","young child; immature (5)"],
["酹","부을 뢰","뢰","to pour out a libation; to sprinkle (14)"],
["夏","여름 하","하","summer; great","grand","big (10)"],
["犍","불친 소 건","건","짐승이름 건","촉나라 고을이름 건","a bullock; a fabulous monster (13)"],
["捧","받들 봉","봉","hold up in two hands (11)"],
["幽","숨을 유","유","그윽할 유","적을 유","어두울 유","귀신 유","저승 유","quiet","secluded","tranquil; dark (9)"],
["蜡","납향 사","사","wax; candle; waxy","glazed; maggot; as a non-simplified form sometimes used as an equivalent to U+410D (䄍)","meaning imperial harvest (14)"],
["矻","돌 골","골","toil","slave away; busy (8)"],
["糊","풀 호","호","끈끈할 호","paste","stick on with paste (15)"],
["夐","멀 형","형","바라보는 모양 형","long","faraway; pre-eminent (14)"],
["捨","버릴 사","사","discard","give up willingly; give alms (11)"],
["幾","기미 기","기","how many? how much?; a few","some (12)"],
["矼","돌다리 강","강","징검다리 강","성실할 강","stone bridge; stepping stones (8)"],
["捩","비틀 렬","렬","twist with hands; snap","tear (11)"],
["广","바윗집 엄","엄","wide","extensive","broad; rad. 53 (3)"],
["矽","석비레 석","석","silicon (8)"],
["捫","어루만질 문","문","쓰다듬을 문","stoke","pat","feel by hand","grope (11)"],
["鮹","문어 소","소","낙지 소","an octopus","devilfish (18)"],
["陋","줍을 루","루","narrow; crude","coarse; ugly (9)"],
["蜥","도마뱀 석","석","lizard (14)"],
["犒","호궤할 호","호","entertain victorious soldiers (14)"],
["夔","조심할 기","기","one-legged monster; walrus (22)"],
["陌","두렁 맥","맥","foot path between rice fields (9)"],
["夕","저녁 석","석","evening","night","dusk; slanted (3)"],
["降","항복할 항","항","descend","fall","drop; lower","down (9)"],
["蜨","나비 접","접","butterfly (14)"],
["据","가질 거","거","길거할 거","의지할 거","손병 거","to occupy","take possession of; a base","position (11)"],
["涪","물거품 부","부","river in Sichuan province (11)"],
["外","바깥 외","외","다를 외","잃을 외","아버지 외","out","outside","external; foreign (5)"],
["蜩","매미 조","조","cicada","broad locust (14)"],
["陏","오이 라","라","풀열매 라(9)"],
["犖","얼룩소 락","락","brindled ox; brindled in color (14)"],
["限","한정할 한","한","boundary","limit","line (9)"],
["捱","막을 애","애","put off","procrastinate; endure (11)"],
["夙","이를 숙","숙","이미 숙","빠를 숙","일찍 일어날 숙","early in morning","dawn; previous (6)"],
["什","세간 집","집","file of ten soldiers; mixed","miscellaneous (4)"],
["捲","거둘 권","권","curl","roll up; curly","rolled up (11)"],
["多","많을 다","다","much","many; more than","over (6)"],
["仁","어질 인","인","humaneness","benevolence","kindness (4)"],
["涯","물가 언덕 의","의","물이름 의","shore","bank","water's edge (11)"],
["桀","찢을 걸","걸","홰 걸","빼어날 걸","멜 걸","사나을 걸","왕이름 걸","chicken roost; ancient emperor (10)"],
["仂","나머지 륵","륵","surplus or excess; remainder (4)"],
["桁","시렁 형","형","마개 형","cross-beams of roof (10)"],
["糕","떡 고","고","cakes","pastry (16)"],
["夜","밤 야","야","night","dark; in night; by night (8)"],
["陔","층 뜰 해","해","언덕 해","변방 해","a step","a terrace","a grade a ledge (9)"],
["仄","기울 측","측","성조 측","물이 괄괄 흐를 측","희미할 측","옆 측","돈 이름 측","slanting","oblique; oblique tones (4)"],
["臀","볼기 둔","둔","buttocks (17)"],
["桂","계수나무 계","계","cassia or cinnamon (10)"],
["捶","종아리 칠 추","추","짓찧을 추","strike with stick","lash","beat (11)"],
["糗","마른밥가루 구","구","parched wheat or rice; broken grain (16)"],
["桃","복숭아 나무 도","도","peach; marriage; surname (10)"],
["捷","이길 첩","첩","win","victory","triumph (11)"],
["臂","팔 비","비","arm (17)"],
["液","즙 액","액","sap","juice","liquid","fluid (11)"],
["桄","광랑나무 광","광","a coir-palm (10)"],
["仆","엎드릴 부","부","fall forward; lie prostrate","prone; servant (4)"],
["糙","매조미쌀 조","조","coarse","harsh","rough","unpolished rice (17)"],
["仇","짝 구","구","enemy","hate","hatred","enmity (4)"],
["陘","지레목 형","형","비탈 형","defile","mountain pass","gorge (10)"],
["涴","물 굽이쳐 흐를 완","완","진흙 묻을 완","daub (11)"],
["框","문테 광","광","frame; framework; door frame (10)"],
["涵","젖을 함","함","soak","wet; tolerate","be lenient (11)"],
["捺","누를 날","날","to press down heavily with the fingers (11)"],
["蜴","도마뱀 척","척","lizard (14)"],
["臆","가슴 억","억","가득할 억","chest","breast","bosom; thought (17)"],
["糜","죽 미","미","싸라기 미","rice gruel","congee; mashed (17)"],
["捻","비틀 녑","녑","to twist or nip with the fingers (11)"],
["案","책상 안","안","생각할 안","table","bench; legal case (10)"],
["夢","꿈 몽","몽","dream; visionary; wishful (14)"],
["今","이제 금","금","now","today","modern era (4)"],
["陛","대궐 섬돌 폐","폐","steps leading throne; throne (10)"],
["犢","송아지 독","독","calf; victim of sacrifice (19)"],
["糝","나물죽 삼","삼","scattered (grains of rice); mixed (17)"],
["涷","마룻대 동","동","rainstorm (11)"],
["介","낄 개","개","도울 개","맬 개","클 개","중매할 개","forerunner","herald","harbinger; to lie between; sea shell; to wear armor (4)"],
["蜵","우렁찰 연","연","벌레 이름 연(14)"],
["陜","좁을 협","협","narrow; mountain pass (10)"],
["涸","물 마를 후","후","dried up; exhausted","tired; dry (11)"],
["糞","똥 분","분","manure","dung","night soil (17)"],
["夤","조심할 인","인","distant place; remote; deep (14)"],
["糟","지게미 조","조","sediment","dregs; pickle (17)"],
["夥","많을 해","해","동무 해","companion; partner; assistant (14)"],
["仍","인할 잉","잉","yet","still","as ever; again; keep -ing","continuing (4)"],
["陝","고을이름 섬","섬","mountain pass; Shaanxi province (10)"],
["蜷","움추러질 권","권","creep like worm","curl one's body (14)"],
["臊","누릴 조","조","rank; rancid; frowzy","fetid; bashful (17)"],
["糠","겨 강","강","번쇄할 강","chaff","bran","husks; poor (17)"],
["捿","깃들일 서","서","perch; roost; stay (11)"],
["陞","오를 승","승","올릴 승","promote","rise","ascend (10)"],
["陟","오를 척","척","climb","scale","ascend; proceed (10)"],
["大","클 태","태","굵을 태","big","great","vast","large","high (3)"],
["桎","차꼬 질","질","fetters","shackles","handcuffs (10)"],
["涼","서늘할 량","량","cool","cold; disheartened (11)"],
["蜺","말매미 예","예","암무지개 예","reflection of rainbow (14)"],
["蜻","귀뚜라미 청","청","dragonfly (14)"],
["天","하늘 천","천","sky","heaven; god","celestial (4)"],
["臍","배꼽 제","제","abdominal area of crab; navel (18)"],
["犧","희생 희","희","sacrifice","give up; sacrificial (20)"],
["陡","절벽 두","두","steep","sloping; abruptly","sudden (10)"],
["太","클 태","태","굵을 태","심할 태","처음 태","콩 태","very","too","much; big; extreme (4)"],
["桐","오동나무 동","동","name applied various trees (10)"],
["院","원집 원","원","공해 원","절 원","학교 원","courtyard","yard","court; school (10)"],
["涿","물방울 떨어질 탁","탁","칠 탁","물이름 탁","땅이름 탁","drip","dribble","trickle (11)"],
["陣","줄 진","진","column","row or file of troops (10)"],
["臏","종지뼈 빈","빈","the kneecap (18)"],
["桑","뽕나무 상","상","mulberry tree; surname (10)"],
["夫","지아비 부","부","man","male adult","husband; those (4)"],
["夬","결단할 쾌","쾌","나누어 정할 쾌","괘 이름 쾌","parted; fork; certain (4)"],
["除","섬돌 제","제","eliminate","remove","except (10)"],
["仔","자세할 자","자","small thing","child; young animal (5)"],
["桓","푯말 환","환","variety of tree; surname (10)"],
["陥","빠질 함","함","submerge","sink","plunge; trap (10)"],
["臑","팔마디 노","노","soft (18)"],
["糧","양식 량","량","food","grain","provisions (18)"],
["夭","어여쁠 요","요","무성할 요","재앙 요","일찍 죽을 요","뱃속에 든 아이 요","끊어 죽일 요","young","fresh-looking; die young (4)"],
["仕","벼슬할 사","사","official; serve government (5)"],
["蜿","굼틀거릴 원","원","creep","crawl (14)"],
["他","다를 타","타","저 타","간사할 타","other","another; he","she","it (5)"],
["犬","큰 개 견","견","dog; radical number 94 (4)"],
["桔","도라지 길","길","Chinese bellflower; well-swept; the inner fibers of corn-stalks (10)"],
["央","가운데 앙","앙","center","conclude","run out; beg (5)"],
["夯","멜 항","항","다질 항","heavy load","burden; lift up (5)"],
["仗","병장기 장","장","rely upon; protector; fight; war","weaponry (5)"],
["参","간여할 참","참","take part in","intervene; ginseng (8)"],
["犯","범할 범","범","commit crime","violate; criminal (5)"],
["失","잃을 실","실","그르칠 실","lose; make mistake","neglect (5)"],
["參","간여할 참","참","take part in","intervene; ginseng (11)"],
["付","줄 부","부","give","deliver","pay","hand over; entrust (5)"],
["叄","간여할 참","참","take part in","intervene; ginseng (11)"],
["仙","신선 선","선","Taoist super-being","transcendent","immortal (5)"],
["陪","쌓아올릴 배","배","accompany","be with","keep company (11)"],
["仚","날듯할 헌","헌","to fly (5)"],
["叅","의논할 참","참","to counel","to consult together; to take part in; to intervene (12)"],
["陬","벼루 추","추","정월 추","부끄러울 추","마을 추","corner","cranny","nook","niche (11)"],
["仛","부칠 탁","탁","다를 탁","young girl; strange","different (5)"],
["臘","납향 랍","랍","year end sacrifice; dried meat (19)"],
["臙","목구멍 연","연","연지 연","rouge","cosmetics (20)"],
["市","저자 시","시","흥정할 시","집 많은 동리 시","market","fair; city","town; trade (5)"],
["布","베 포","포","피륙 포","벌릴 포","돈 포","베풀 포","cotton cloth","textiles","linen (5)"],
["臚","살갗 려","려","arrange in order; display (20)"],
["犴","들개 안","안","a wild dog","a not very large lock-up (6)"],
["糯","찰벼 나","나","glutinous rice; glutinous","sticky (20)"],
["又","또 우","우","용서할 우","and","also","again","in addition (2)"],
["犵","오랑캐이름 힐","힐","resolute (6)"],
["叉","깍지낄 차","차","crotch; fork","prong (3)"],
["糰","경단 단","단","rice ball (20)"],
["桜","벚나무 앵","앵","cherry","cherry blossom (10)"],
["夷","동쪽 오랑캐 이","이","평평할 이","기꺼울 이","상할 이","베풀 이","괘 이름 이","ancient barbarian tribes (6)"],
["仞","길 인","인","ancient unit of measure (8 feet); 'fathom' (5)"],
["仟","일천 천","천","one thousand; leader of one thousand men (5)"],
["夸","자랑할 과","과","extravagant","luxurious; handsome (6)"],
["鄂","나라이름 악","악","Hubei province; startled (12)"],
["臝","벌거벗을 라","라","be bare; nude (21)"],
["糲","현미 려","려","unpolished rice; brown rice (21)"],
["及","미칠 급","급","extend; reach; come up to; and (4)"],
["帆","돛 범","범","sail; boat (6)"],
["陰","음기 음","음","부인에게 예 가르칠 음","몰래 음","세월 음","female' principle; dark; secret (11)"],
["仡","날랠 흘","흘","strong; valiant (5)"],
["友","벗 우","우","합할 우","friend","companion; fraternity (4)"],
["糴","쌀 사들일 적","적","빠를 적","purchase grains; store grain (22)"],
["夻","대구 구","구","fish name (6)"],
["陲","변방 수","수","위태할 수","frontier","border (11)"],
["陳","늘어놓을 진","진","exhibit","display; plead; surname (11)"],
["臟","오장 장","장","internal organs","viscera (22)"],
["魁","으뜸 괴","괴","chief; leader; best; monstrous (14)"],
["反","되돌릴 번","번","reverse","opposite","contrary","anti (4)"],
["帉","걸레 분","분","행주 분(7)"],
["代","대신할 대","대","replace","replacement (of person or generation >); era","generation (5)"],
["魂","넋 혼","혼","soul","spirit (14)"],
["糶","쌀 내어 팔 조","조","sell grains (25)"],
["臠","저민고기 련","련","small lump of meat; sliced meat (25)"],
["令","명령 령","령","시킬 령","command","order; 'commandant'","magistrate; allow","cause (5)"],
["陴","성가퀴 비","비","a parapet on a city wall (11)"],
["夾","곁 협","협","낄 협","잡을 협","성씨 협","가까울 협","be wedged or inserted between (7)"],
["魃","가물 귀신 발","발","drought demon (15)"],
["以","써 이","이","쓸 이","까닭 이","함께 이","거느릴 이","by means of; thereby","therefore; consider as; in order to (5)"],
["陵","큰 언덕 릉","릉","hill","mound; mausoleum (11)"],
["希","바랄 희","희","rare; hope","expect","strive for (7)"],
["糸","가는 실 사","사","silk; KangXi radical 120 (6)"],
["陶","화락할 요","요","순임금의 신하 요","pottery","ceramics (11)"],
["陷","빠질 함","함","submerge","sink","plunge; trap (11)"],
["瞋","부릅뜰 진","진","glare with anger (15)"],
["魄","넋 백","백","vigor; body; dark part of moon (15)"],
["臣","신하 신","신","두려울 신","minister","statesman","official (6)"],
["魅","도깨비 매","매","kind of forest demon","elf (15)"],
["糺","거둘 규","규","to collaborate; to band together (7)"],
["叒","동방귀신나무 약","약","obedient; united (6)"],
["陸","뭍 륙","륙","land","continental; army; an accounting form of U+516D 六 (six) (11)"],
["臥","누울 와","와","쉴 와","lie down; crouch (8)"],
["系","이을 계","계","system; line","link","connection (7)"],
["瞍","소경 수","수","장님 수","판수 수","blind; no pupil in the eye (15)"],
["瞎","애꾸눈 할","할","blind","reckless; rash (15)"],
["叔","아재비 숙","숙","주울 숙","어릴 숙","끝 숙","father's younger brother (8)"],
["叕","이을 철","철","잇댈 철(8)"],
["臧","착할 장","장","good","right","generous; command (14)"],
["魈","산도깨비 소","소","mischevious","one footed spirit that dwells in the mountains (17)"],
["帑","나라 곳집 탕","탕","a treasury; public funds (8)"],
["取","거둘 취","취","찾을 취","받을 취","빼앗을 취","장가들 취","take","receive","obtain; select (8)"],
["陼","모래섬 저","저","둔덕 저","언덕 저","islet (12)"],
["臨","임할 림","림","draw near","approach; descend (17)"],
["糾","삼합노 규","규","investigate","inspect (8)"],
["瞑","눈 감을 명","명","close eyes (15)"],
["受","이을 수","수","얻을 수","담을 수","받을 수","receive","accept","get; bear","stand (8)"],
["仭","길 인","인","잴 인","찰 인","깊을 인","ancient unit of measure (8 feet); 'fathom' (5)"],
["陽","볕 양","양","드러낼 양","시월 양","거짓 양","male' principle; light; sun (12)"],
["魋","북상투 추","추","bear (18)"],
["自","스스로 자","자","self","private","personal; from (6)"],
["鄒","추나라 추","추","name of an ancient state; surname (13)"],
["魍","도깨비 망","망","demons","mountain spirits (18)"],
["臬","법 얼","얼","문지방 얼","과녁 얼","law","rule; door post (10)"],
["叚","빌 가","가","false (9)"],
["帕","머리 동이수건 파","파","turban","kerchief","veil; wrap (8)"],
["仰","우러를 앙","앙","raise the head to look; look up to","rely on","admire (6)"],
["臭","냄새 취","취","향기 취","썪을 취","고약한 이름 취","smell","stink","emit foul odor (10)"],
["帖","표제 체","체","invitation card; notice (8)"],
["持","가질 지","지","sustain","support; hold","grasp (9)"],
["桯","서안 정","정","걸상 정","table (11)"],
["魎","도깨비 량","량","a kind of monster (18)"],
["叛","배반할 반","반","rebel; rebellion; rebellious (9)"],
["仲","버금 중","중","middle brother; go between","mediator; surname (6)"],
["鄔","성 이름 오","오","various place names; surname; transliteration of Sanskrit 'u' (13)"],
["魏","위나라 위","위","대궐 위","우뚝할 위","kingdom of Wei; surname (18)"],
["瞖","눈에 백태 낄 예","예","a cataract or film over the eye (16)"],
["挂","그림족자 괘","괘","hang","suspend; suspense (9)"],
["鄕","시골 향","향","고향 향","country; rural; village (13)"],
["帘","주막기 렴","렴","flag-sign of a tavern (8)"],
["仳","떠날 비","비","separate","part company (6)"],
["帙","책갑 질","질","book cover; satchel or bag (8)"],
["魑","도깨비 리","리","a mountain demon resembling a tiger (21)"],
["叞","안위할 위","위","다리미 위(10)"],
["帚","비 추","추","털 추","broom","broomstick (8)"],
["叟","어른 수","수","쌀 씻는 소리 수","old man; elder (10)"],
["仵","짝 오","오","같을 오","similar (6)"],
["桴","마룻대 부","부","raft; drum stick; ridge pole (11)"],
["流","흐를 류","류","flow","circulate","drift; class (9)"],
["帛","비단 백","백","silks","fabrics; wealth","property (8)"],
["件","조건 건","건","가지 건","구별할 건","나눌 건","numerary adjunct for article; matter (6)"],
["至","이를 지","지","reach","arrive; extremely","very (6)"],
["蛀","나무좀 주","주","insects that eat books","clothes (11)"],
["鄙","다라울 비","비","mean; low (14)"],
["魔","마귀 마","마","demon","evil spirits; magic power (21)"],
["叡","밝을 예","예","임금 예","어질 예","astute","profound","shrewd (16)"],
["致","이를 치","치","극진할 치","버릴 치","불러올 치","풍치 치","드릴 치","보낼 치","연구할 치","send","deliver","present; cause (9)"],
["叢","떨기 총","총","모을 총","번잡할 총","bush","shrub; thicket; collection (18)"],
["指","손가락 지","지","finger","toe; point","indicate (9)"],
["帝","임금 제","제","supreme ruler","emperor; god (9)"],
["桶","엿되들이 통 통","통","pail","bucket","tub; cask","keg (11)"],
["价","착할 개","개","클 개","사신 개","price","value (6)"],
["桷","서까래 각","각","rafter; malus toringo (11)"],
["挈","손에들 설","설","assist","help","lead by hand (10)"],
["口","입 구","구","mouth; open end; entrance","gate (3)"],
["浅","얕을 천","천","shallow","not deep; superficial (9)"],
["按","막을 알","알","put hand on","press down with hand (9)"],
["古","옛 고","고","old","classic","ancient (5)"],
["魘","잠꼬대할 염","염","nightmare","bad dreams (24)"],
["瞞","속일 만","만","deceive","lie; eyes half-closed (16)"],
["句","글귀 귀","귀","sentence (5)"],
["另","나눌 령","령","다를 령","헤어질 령","another","separate","other (5)"],
["任","맡길 임","임","trust to","rely on","appoint; to bear","duty","office; allow (6)"],
["蛆","구더기 저","저","지네 저","maggots (11)"],
["鄞","땅이름 은","은","county in Zhejiang province (14)"],
["魚","물고기 어","어","fish; surname; KangXi radical 195 (11)"],
["瞠","똑바로 볼 쟁","쟁","look at","stare at","gaze at (16)"],
["挌","칠 격","격","들 격","fight (9)"],
["臺","능 대","대","tower","lookout; stage","platform (14)"],
["蛇","든든할 이","이","snake (11)"],
["叨","탐할 도","도","talkative; quarrelous (5)"],
["份","빛날 빈","빈","portion","part; duty (6)"],
["臻","이를 진","진","reach","arrive; utmost","superior (16)"],
["魜","인어 인","인","mermaid; manatee (13)"],
["瞢","어두울 몽","몽","eyesight obscured; to feel ashamed (16)"],
["叩","두드릴 고","고","knock","ask; kowtow","bow (5)"],
["臼","절구 구","구","mortar; bone joint socket (6)"],
["谷","성씨 욕","욕","나라이름 욕","valley","gorge","ravine (7)"],
["蛉","장자리 령","령","dragonfly","libellulidae (11)"],
["帥","주장할 수","수","장수 수","commander","commander-in-chief (9)"],
["仿","헤멜 방","방","imitate","copy; as if (6)"],
["只","다만 지","지","only","just","simply (5)"],
["鄢","땅이름 언","언","name of a district in Honan (14)"],
["挐","이끌 녀","녀","drag (10)"],
["带","띠 대","대","belt","girdle","band","strap","zone (9)"],
["桿","막대기 한","한","pole; stick; club; pole as unit (11)"],
["鄣","나라이름 장","장","name of an ancient city in N. Jiangsu","near Shandong (14)"],
["臾","잠간 유","유","착할 유","나라이름 유","활이 뒤로 젖혀질 유","moment","instant","short while (8)"],
["蛋","오랑캐 이름 단","단","eggs; term of abuse (11)"],
["瞥","언뜻 볼 별","별","take fleeting glance at (17)"],
["挑","돋울 조","조","a load carried on the shoulders; to carry (9)"],
["叫","부를 규","규","cry","shout; hail","greet","call (5)"],
["召","대추 조","조","imperial decree; summon (5)"],
["帨","차는 수건 세","세","손수건 세","handkerchief","kerchief","shawl (10)"],
["叭","나팔 팔","팔","입 벌릴 팔","trumpet (5)"],
["叮","단단히 부탁할 정","정","정성스러울 정","exhort or enjoin repeatedly (5)"],
["鄧","나라 이름 등","등","surname (15)"],
["可","오랑캐 임금 이름 극","극","may","can","-able; possibly (5)"],
["師","스승 사","사","teacher","master","specialist; multitude","troops (10)"],
["瞪","바로 볼 징","징","stare at (17)"],
["台","별 태","태","늙을 태","platform; unit; term of address (5)"],
["叱","꾸짖을 질","질","scold","shout at","bawl out (5)"],
["谿","시내 계","계","valley","gorge; mountain stream (17)"],
["席","자리 석","석","seat; mat; take seat; banquet (10)"],
["瞬","눈 깜작일 순","순","잠깐 순","wink","blink; in a wink","a flash (17)"],
["史","역사 사","사","history","chronicle","annals (5)"],
["瞭","밝을 료","료","bright","clear; clear-sighted (17)"],
["右","오른쪽 우","우","높일 우","강할 우","도울 우","right; west; right-wing (5)"],
["壁","벽 벽","벽","partition wall; walls of a house (16)"],
["牀","평상 상","상","bed","couch; bench; chassis (8)"],
["叴","소리 높일 구","구","a three-edged spear (5)"],
["蛔","거위 회","회","tapeworm (12)"],
["叵","못할 파","파","드디어 파","술잔 파","cannot","be unable do","improbable; thereupon (5)"],
["鄭","나라이름 정","정","성씨 정","state in today's Henan; surname (15)"],
["叶","화합할 협","협","to harmonize","to rhyme; to unite; (borrowed for) leaf (5)"],
["瞰","굽어볼 감","감","내려다 볼 감","watch","spy; overlook; look down (17)"],
["牂","암양 장","장","female sheep","ewe (10)"],
["浙","쌀 씻을 절","절","물이름 절","Zhejiang province; river (10)"],
["帳","휘장 장막 장","장","tent; screen","mosquito net (11)"],
["壅","막을 옹","옹","북돋울 옹","to obstruct (16)"],
["鄰","이웃 린","린","neighbor; neighborhood (15)"],
["司","맡을 사","사","take charge of","control","manage; officer (5)"],
["鄱","땅이름 파","파","county and lake in Jiangxi (15)"],
["浚","깊을 준","준","dredge (10)"],
["瞳","눈동자 동","동","pupil of eye (17)"],
["粁","킬로메트르 천","천","km (9)"],
["壇","제터 단","단","altar; arena","examination hall (16)"],
["鄲","조나라 서울 단","단","county in Hebei province (15)"],
["蛙","개구리 왜","왜","음란한 소리 왜","frog (12)"],
["叺","가마니 입","입","a straw bag; a tobacco pouch (5)"],
["牆","담 장","장","wall (17)"],
["浜","물가 빈","빈","creek","stream; beach","sea coast (10)"],
["帶","띠 대","대","belt","girdle","band","strap","zone (11)"],
["片","조각 편","편","쪼갤 편","성씨 편","slice","splinter","strip; rad. 91 (4)"],
["蛛","거미 주","주","spider (12)"],
["鄴","땅이름 업","업","성씨 업","place in today's honan province (16)"],
["魯","노둔할 로","로","foolish","stupid","rash; vulgar (15)"],
["粃","쭉정이 비","비","empty husks of grain (10)"],
["帷","휘장 유","유","tent; curtain","screen (11)"],
["版","조각 판","판","호적 판","국경 판","한 길 판","인쇄할 판","printing blocks; edition (8)"],
["常","항상 상","상","common","normal","frequent","regular (11)"],
["鄶","나라이름 회","회","state in today's Henan province (16)"],
["蛞","괄태충 활","활","snail; slug","mole cricket (12)"],
["牋","글 전","전","표 전","문체이름 전","memorandum","official note (12)"],
["蛟","교룡 교","교","scaly dragon with four legs (12)"],
["瞹","흐릿할 애","애","clouded","obscure (18)"],
["浡","일어날 발","발","burst forth; rise; vigorous (10)"],
["壎","진나팔 훈","훈","instrument (17)"],
["魴","방어 방","방","bream (15)"],
["牌","방 붙일 패","패","호패 패","방패 패","signboard","placard (12)"],
["瞻","볼 첨","첨","look; look out for; respect (18)"],
["鄺","성 광","광","surname (18)"],
["粉","가루 분","분","powder","face powder; plaster (10)"],
["浣","옷 빨 완","완","씻을 완","to wash","to rinse (10)"],
["帽","모자 모","모","hat","cap; cap-like tops (12)"],
["瞼","눈시울 검","검","eyelid (18)"],
["挨","밀칠 애","애","near","close by","next to","towards","against; to wait; to lean on (10)"],
["浤","용솟음할 굉","굉","beating of ocean; surging of wate (10)"],
["帿","과녁 후","후","marquis","lord; target in archery (12)"],
["壑","골짜기 학","학","bed of torrent","narrow ravine (17)"],
["蛣","장구벌레 길","길","(dung) beetle (12)"],
["瞽","소경 고","고","blind; blind musician; stupid (18)"],
["浥","젖을 읍","읍","흠치르르할 읍","moist","wet","dampen (10)"],
["蛤","조개 합","합","clam (12)"],
["浦","물가 포","포","갯가 포","bank of river","shore; surname (10)"],
["挪","옮길 나","나","move","shift on one side (10)"],
["挫","꺽을 좌","좌","push down; chop down; grind (10)"],
["壓","누를 압","압","press; oppress; crush; pressure (17)"],
["瞿","놀라서 볼 구","구","surname (18)"],
["浧","잠길 영","영","앙금 영(10)"],
["牒","글씨판 첩","첩","documents","records; dispatch (13)"],
["壔","작은 성 도","도","a mound","a tumulus (17)"],
["粍","밀리메트로 모","모","mm (10)"],
["浩","클 호","호","great","numerous","vast","abundant (10)"],
["壕","해자 호","호","trench","ditch","channel","moat (17)"],
["牓","패 방","방","tablet; public notice; register (14)"],
["牔","박공 박","박","gable (14)"],
["浪","물결 랑","랑","wave; wasteful","reckless (10)"],
["振","떨칠 진","진","raise","excite","arouse action (10)"],
["蛩","메뚜기 공","공","cricket","locust; anxious (12)"],
["牖","엇살창 유","유","들창 유","밝을 유","인도할 유","window; lead enlightenment (15)"],
["浬","해리 리","리","nautical mile (10)"],
["壙","광 광","광","tomb","grave: prairie; open wilderness (18)"],
["粒","알 립","립","grain; small particle (11)"],
["壚","흑토 로","로","black clods of earth; shop","hut (19)"],
["牘","편지 독","독","writing tablet; documents","books (19)"],
["浮","뜰 부","부","to float","drift","waft; to exceed; superfluous (10)"],
["蛭","거머리 질","질","leech (12)"],
["亂","어지러울 란","란","confusion","state of chaos; create chaos","revolt (13)"],
["牙","어금니 아","아","대장 기 아","tooth","molars","teeth; serrated (4)"],
["壜","술병 담","담","earthen jar or jug (19)"],
["粕","지게미 박","박","lees","dregs","sediments left after distilling liquor (11)"],
["壝","토담 유","유","단 둘러쌓은 담 유","a mound","an embankment the earthen altar to the god of the soil (19)"],
["亄","탐할 의","의","인색할 의","아낄 의","greedy","stingy (13)"],
["膀","쌍배 방","방","upper arm; shoulder; wing (14)"],
["牛","소 우","우","별 이름 우","cow","ox","bull; KangXi radical93 (4)"],
["栃","상수리나무 회","회","type of oak; stable (9)"],
["粗","거칠 조","조","rough","thick","course; rude (11)"],
["亅","갈고리 궐","궐","hook; KangXi radical 6 (1)"],
["粘","붙일 점","점","끈끈할 점","viscous","mucous; glutinous (11)"],
["壞","혹 회","회","bad","spoil(ed)","ruin","destroy (19)"],
["了","마칠 료","료","to finish; particle of completed action (2)"],
["膂","등골 뼈 려","려","backbone","spinal column (14)"],
["牝","암컷 빈","빈","female of species; deep gorge (6)"],
["栅","울짱 책","책","fence; palisade; grid (9)"],
["壟","언덕 롱","롱","grave","mound; ridge in field (19)"],
["亇","망치 마","마","kwukyel hammer (3)"],
["膃","해구 올","올","살찔 올","fat (14)"],
["予","자기 여","여","I","me; to give (4)"],
["膄","파리할 수","수","to become emaciated (14)"],
["牟","소 우는 소리 모","모","make; seek","get; barley; low (6)"],
["挹","잡아당길 읍","읍","잔질할 읍","to bale out; to decant liquids (10)"],
["浴","깨끗이할 욕","욕","못 이름 욕","bathe","wash; bath (10)"],
["挺","빼어날 정","정","너그러울 정","향초이름 정","to stand upright","straighten; rigid (10)"],
["膆","살찔 소","소","멀떠구니 소","the corp of a bird or fowl; fat (14)"],
["牡","수컷 모","모","male of animals; bolt of door (7)"],
["挻","당길 연","연","달아날 연","손바닥으로 칠 연(10)"],
["海","바다 해","해","많을 해","세계 해","sea","ocean; maritime (10)"],
["事","일 사","사","affair","matter","business; to serve; accident","incident (8)"],
["浸","적실 침","침","불릴 침","번질 침","빠질 침","soak","immerse","dip","percolate (10)"],
["壤","흙 양","양","풍년들 양","soil","loam","earth; rich (20)"],
["二","두 이","이","풍신 이","같을 이","의심할 이","two; twice (2)"],
["阜","언덕 부","부","mound; abundant","ample","numerous (8)"],
["膈","명치 격","격","diaphragm (14)"],
["牢","우리 뢰","뢰","prison; stable","pen; secure (7)"],
["挽","당길 만","만","pull; pull back","draw back (10)"],
["浹","사무칠 협","협","둘릴 협","saturate","drench; damp","wet (10)"],
["挾","낄 협","협","도울 협","품을 협","clasp under arm; hold to bosom (10)"],
["亍","자축거릴 촉","촉","겨우 디딜 촉","to take small steps; Korean place name (3)"],
["阝","언덕 부","부","place; KangXi radical 170 (3)"],
["粟","좁쌀 속","속","겉곡식 속","unhusked millet; grain (12)"],
["于","갈 우","우","여기 우","만족할 우","탄식의 어조사 우","in","on","at; go to; surname (3)"],
["蛸","거미알 소","소","연가시 알집 소","long legged spider; octopus; chrysalis of mantis (13)"],
["膊","포 박","박","shoulders","upper arms (14)"],
["栍","찌 생","생","a lot; a label; a slip of bamboo engraved with signs (9)"],
["蛹","번데기 용","용","chrysalis","larva (13)"],
["膋","발기름 료","료","fat (14)"],
["粢","피 자","자","grain offered in ritual sacrifice; millet (12)"],
["壩","방죽 파","파","둑 파","embankment; dam (24)"],
["阡","두렁 천","천","footpaths between fields; paths (6)"],
["云","이를 운","운","움직일 운","돌아갈 운","say","speak; clouds (4)"],
["蛻","매미 허물 세","세","molt","exuviate","shed (13)"],
["牧","칠 목","목","tend cattle","shepherd (8)"],
["互","서로 호","호","함께 호","mutually","reciprocally (4)"],
["粤","말 내킬 월","월","곰곰히 생각할 월","나라이름 월","Guangdong and Guangxi provinces; initial particle (12)"],
["浿","물이름 패","패","물가 패(10)"],
["士","선비 사","사","scholar","gentleman; soldier (3)"],
["膏","살찔 고","고","grease","fat; paste","ointment (14)"],
["物","만물 물","물","무리 물","thing","substance","creature (8)"],
["粥","죽 죽","죽","rice gruel","congee (12)"],
["阤","떨어질 치","치","헐릴 치","언덕 치","사태날 치","hill (6)"],
["栒","경쇠걸이 순","순","순나무 순","cross bar (10)"],
["壬","아홉째천간 임","임","9th heavenly stem (4)"],
["五","다섯 오","오","five; surname (4)"],
["蛾","누에나방 아","아","moth (13)"],
["粦","도깨비 불 린","린","phosphorus (12)"],
["井","우물 정","정","단정할 정","괘 이름 정","천정 정","저자 정","well","mine shaft","pit (4)"],
["栓","말뚝 전","전","wooden peg","post or stick (10)"],
["粧","단장할 장","장","toilet; make-up; dress up; adorn (12)"],
["壮","씩씩할 장","장","big","large; robust; name of tribe (6)"],
["粨","힉터메트르 백","백","hm (12)"],
["壯","씩씩할 장","장","big","large; robust; name of tribe (7)"],
["栖","깃들일 서","서","perch; roost; stay (10)"],
["厂","사람 이름 한","한","factory","workshop; radical 27 (2)"],
["阨","막힐 액","액","in distress; adverse; strategic; (Cant.) to swindle (7)"],
["栗","밤나무 률","률","chestnut tree","chestnuts; surname (10)"],
["厃","우러러 볼 점","점","to look upward (4)"],
["亘","굳셀 환","환","extend across","through; from (6)"],
["牯","암소 고","고","cow; bull; ox (9)"],
["阪","산비탈 판","판","언덕 판","hillside farmland; slope (7)"],
["厄","재앙 액","액","adversity","difficulty","distress (4)"],
["亙","뻗칠 긍","긍","extend across","through; from (6)"],
["膘","허구리살 표","표","소 볼기 표","부레 표","fat; rump (15)"],
["些","적을 사","사","little","few; rather","somewhat (7)"],
["牲","희생 생","생","sacrificial animal; animal (9)"],
["着","붙을 착","착","make move","take action (11)"],
["亝","재계할 제","제","even","regular","uniform all alike; to arrange (8)"],
["牴","찌를 저","저","대강 저","씨름 저","당할 저","gore","butt; resist (9)"],
["阮","원나라 원","원","성씨 원","ancient musical instrument: surname (7)"],
["膚","살갗 부","부","skin; superficial","shallow (15)"],
["亞","버금 아","아","Asia; second; used as a prefix to names (8)"],
["阯","터 지","지","foundation; site; address (7)"],
["亟","자주 기","기","urgently","immediately","extremely (8)"],
["郁","문채날 욱","욱","자욱할 욱","sweet smelling","rich in aroma; (Cant.) to move","hit (9)"],
["膜","무릎 꿇을 모","모","membrane; to kneel and worship (15)"],
["粱","기장 량","량","better varieties of millet (13)"],
["栝","노송나무 괄","괄","a builder's frame for measuring (10)"],
["粲","정미 찬","찬","polish; bright","radiant; smiling (13)"],
["壹","한 일","일","number one (12)"],
["亠","뜻 없는 토 두","두","head; KangXi radical 8 (2)"],
["膝","무릎 슬","슬","종지뼈 슬","치슬말 슬","knee (15)"],
["栞","도표 간","간","publication","periodical; publish (10)"],
["壺","병 호","호","단지 호","jar","pot","jug","vase; surname (12)"],
["郃","땅이름 합","합","county in Shanxi province (9)"],
["阱","함정 정","정","pitfall","trap","snare (7)"],
["亡","없을 무","무","death","destroyed; lose","perish (3)"],
["牸","암컷 자","자","the female of certain animals; to bring forth young of animals (10)"],
["粳","메벼 갱","갱","non-glutinous rice (13)"],
["睆","가득 차 있는 모양 환","환","bright (12)"],
["亢","목 항","항","높을 항","별이름 항","기둥 항","가릴 항","high","proud; violent","excessive; skilled; name (4)"],
["特","우뚝할 특","특","숫소 특","뛰어날 특","다만 특","세 살 먹은 짐승 특","special","unique","distinguished (10)"],
["膞","썰 전","전","질그릇 만드는 틀 전(15)"],
["壻","사위 서","서","son-in-law; husband (12)"],
["防","둑 방","방","defend; prevent; embankment (7)"],
["巉","가파를 참","참","steep","rugged","jagged","precipitous (20)"],
["郅","고을 이름 질","질","to go up to. flourishing a superlative (9)"],
["睇","흘끗 볼 제","제","look at","glance at; stare at (12)"],
["校","학궁 효","효","school; military field officer (10)"],
["厎","숫돌 지","지","settle (7)"],
["壽","목숨 수","수","장수할 수","나이 수","축복할 수","old age","long life; lifespan (14)"],
["交","사귈 교","교","mix; intersect; exchange","communicate; deliver (6)"],
["膠","아교 교","교","glue","gum","resin","rubber (15)"],
["亥","열 둘째 지지 해","해","서북쪽과 북쪽의 사이 방향 해","음력 시월 해","돼지 해","12th terrestrial branch (6)"],
["巋","높고 험준할 규","규","grand","stately; secure","lasting (20)"],
["亦","또 역","역","모두 역","also","too; likewise (6)"],
["牽","당길 견","견","이끌 견","빠를 견","거리낄 견","볏줄 견","drag","pull","lead by hand (11)"],
["膣","새살 날 질","질","vagina (15)"],
["巍","높을 외","외","높고 튼 모양 외","high","lofty","majestic","eminent (21)"],
["粹","정할 수","수","순수할 수","pure; unadulterated; select (14)"],
["亨","형통할 형","형","제사 형","smoothly","progressing","no trouble (7)"],
["郊","성 밖 교","교","suburbs; waste land","open spaces (9)"],
["睍","물끄러미 볼 현","현","고울 현","to look at fearfully; overly cautious (12)"],
["厓","언덕 애","애","물가 애","precipice","shore","bank; surname (8)"],
["鬆","터럭 더부룩할 송","송","lax","loose; loosen","relax (18)"],
["厔","막을 질","질","stop","hinder; place near water (8)"],
["享","드릴 향","향","제사지낼 향","잔치 향","흠향할 향","enjoy (8)"],
["粽","주악 종","종","dumpling made of glutinous rice (14)"],
["阻","험할 조","조","impede","hinder","obstruct; oppose (8)"],
["鬈","수염좋을 권","권","fine growth of hair curly hair (18)"],
["膨","배 불룩할 팽","팽","to swell; swollen","bloated","inflated (16)"],
["株","그루 주","주","numerary adjunct for trees; root (10)"],
["阼","동편 층계 조","조","the steps leading to the eastern door (8)"],
["精","정교할 정","정","세밀할 정","정할 정","성실할 정","신령 정","essence; semen; spirit (14)"],
["厖","클 방","방","bulky; thick and large; confused (9)"],
["京","서울 경","경","capital city (8)"],
["郎","남편 랑","랑","gentleman","young man; husband (9)"],
["亭","정자 정","정","여관 정","고를 정","pavilion; erect (9)"],
["阽","낭떠러지 점","점","낭비탈 점(8)"],
["巒","뫼 만","만","mountain range; pointed mountain (22)"],
["膩","살찔 니","니","greasy","oily","dirty; smooth (16)"],
["鬋","수염 깎을 전","전","귀밑 털 드릴 전","forelock","bangs (19)"],
["巓","산 꼭대기 전","전","summit of mountain (22)"],
["厘","전방 전","전","터전 전","thousandth part of tael (9)"],
["亮","밝을 량","량","bright","brilliant","radiant","light (9)"],
["睒","번개빛 섬","섬","to glance at","to peep; glittering; to shine (13)"],
["阿","누구 옥","옥","prefix for people's names; used in transliteration (8)"],
["厚","두터울 후","후","thick; substantial; greatly (9)"],
["鬍","수염 호","호","beard","mustache","whiskers (19)"],
["亰","서울 경","경","capital city (9)"],
["栮","버섯 이","이","느타리 이(10)"],
["巖","높을 엄","엄","cliff","precipice; cave","grotto (23)"],
["拂","도울 필","필","shake off","brush away; dust (8)"],
["亳","땅이름 박","박","name of district in Anhui; capital of Yin (10)"],
["鬐","갈기 기","기","horse's mane; fins (20)"],
["栱","두공 공","공","large peg","stake; post","pillar (10)"],
["拄","떠받칠 주","주","lean on; post; prod; ridicule (8)"],
["膰","제사 고기 번","번","to cook meat for a sacrifice or offering (16)"],
["栲","북나무 고","고","mangrove (10)"],
["巚","봉우리 헌","헌","시루봉 헌(23)"],
["鬒","숱 많을 진","진","black","glossy hair (20)"],
["原","근본 원","원","거듭 원","언덕 원","기인할 원","들판 원","source","origin","beginning (10)"],
["厠","뒷간 측","측","평상 가장자리 측","섞일 측","mingle with; toilet","lavatory (11)"],
["栴","향나무 전","전","sandalwood (10)"],
["担","원숭이 같은 짐승 단","단","carry","bear","undertake (8)"],
["拆","터질 탁","탁","찢어질 탁","열릴 탁","to break up","split apart","rip open; to destroy (8)"],
["亶","믿을 단","단","sincere; real","true; truth (13)"],
["膳","반찬 선","선","meals","provisions","board (16)"],
["睚","눈흘길 애","애","corner of eye; stare (13)"],
["川","내 천","천","stream","river; flow; boil (3)"],
["睛","눈 검은자위 정","정","눈동자 정","eyeball; pupil of eye (13)"],
["拇","엄지손가락 무","무","thumb; big toe (8)"],
["膴","포 무","무","크게 저민 고기 무","fine (16)"],
["洄","거슬러 올라 갈 회","회","a back-water; an eddy a whirlpool (9)"],
["膵","첩육경 췌","췌","지라 췌","pancreas (16)"],
["州","고을 주","주","administrative division","state (6)"],
["拈","집을 념","념","pick up with fingers; draw lots (8)"],
["鬖","헝클어질 삼","삼","wild hair (21)"],
["核","씨 홀","홀","seed","kernel","core","nut; atom (10)"],
["拉","꺽을 랍","랍","pull","drag; seize","hold; lengthen (8)"],
["厤","다스릴 력","력","to calculate; the calendar (12)"],
["亹","힘쓸 미","미","문채가 있는 모양 미","progress; busy","untiring; exert (21)"],
["拊","어루만질 부","부","slap; pat; tap; handle of a vessel (8)"],
["厥","그 궐","궐","personal pronoun he","she","it (12)"],
["人","사람 인","인","man; people; mankind; someone else (2)"],
["郜","나라이름 고","고","name of fief in Shantong bestowed on the eldest son of Wen Wang (10)"],
["鬘","머리장식 만","만","beautiful hair (21)"],
["根","뿌리 근","근","root","base(d on); foundation (10)"],
["郝","고을 이름 학","학","surname; place in modern Shanxi (10)"],
["厦","큰 집 하","하","big building","mansion (12)"],
["拋","던질 포","포","버릴 포","돌소뇌 포","throw (away)","abandon","reject (8)"],
["巡","돌 순","순","순행할 순","두루 순","patrol","go on circuit","cruise (7)"],
["鬙","터럭 더부룩할 승","승","matted hair (22)"],
["睟","똑바로 볼 수","수","윤택할 수","clear-eyed (13)"],
["拌","버릴 반","반","mix (8)"],
["巢","새집 소","소","집 지을 소","새 보금자리 소","적진 망보는 높을 수레 소","도둑굴 소","큰 피리 소","채소이름 소","nest","living quarter in tree (11)"],
["郞","사나이 랑","랑","gentleman (10)"],
["鬚","턱수염 수","수","아랫수염 수","beard","whiskers; whisker-like (22)"],
["拍","칠 박","박","clap","tap","beat; beat or rhythm (8)"],
["郟","그을 이름 겹","겹","county in Henan province; surname (10)"],
["膺","가슴 응","응","친할 응","당할 응","응할 응","받을 응","breast","chest; undertake","bear (17)"],
["睡","졸음 수","수","잠 수","sleep","doze (13)"],
["栻","점판 식","식","tree (10)"],
["洊","이를 천","천","flowing water (9)"],
["睢","물이름 수","수","gaze at","stare at; uninhibited (13)"],
["格","막힐 락","락","pattern","standard","form; style (10)"],
["栽","심을 재","재","to cultivate","plant; to care for plants (10)"],
["拏","붙잡을 나","나","take; bring; grasp","hold; arrest (9)"],
["洋","큰 바다 양","양","넘칠 양","ocean","sea; foreign; western (9)"],
["厩","마구 구","구","stable; barnyard (11)"],
["工","장인 공","공","labor","work; worker","laborer (3)"],
["郡","고을 군","군","administrative division (10)"],
["督","감독할 독","독","supervise","oversee","direct (13)"],
["左","왼 좌","좌","left; east; unorthodox","improper (5)"],
["拐","속일 괴","괴","kidnap","abduct; turn (8)"],
["洌","맑을 렬","렬","clear (9)"],
["厪","적을 근","근","a hut; careful (13)"],
["郢","땅이름 영","영","state in today's Hubei province (10)"],
["膽","쓸개 담","담","gall bladder; bravery","courage (17)"],
["蚊","모기 문","문","mosquito; gnat (10)"],
["鬟","쪽찐 머리 환","환","dress hair in coiled knot; maid (23)"],
["拑","자갈 먹일 겸","겸","to clamp","tie down or pin down (8)"],
["巧","공교할 교","교","skillful","ingenious","clever (5)"],
["蚋","모기 예","예","gnat","mosquito (10)"],
["睥","흘겨볼 비","비","look askance at","glare at (13)"],
["膾","회 회","회","minced meat or fish (17)"],
["拒","진이름 구","구","ward off with hand","defend (8)"],
["巨","클 거","거","많을 거","억 거","large","great","enormous; chief (5)"],
["郤","틈 극","극","crack","opening; surname (10)"],
["鬠","결발할 괄","괄","to make a top knot; (Cant.) 鬅鬠","to be slovenly dressed (23)"],
["蚌","방합 방","방","oysters","mussels; mother-of-pearl (10)"],
["睦","화목할 목","목","friendly","amiable","peaceful (13)"],
["拓","물리칠 탁","탁","헤칠 탁","밀칠 탁","expand; open up; support or push (8)"],
["厭","제사할 엽","엽","모을 엽","덜 엽","누를 엽","축하할 엽","괴로울 엽","숨길 엽","dislike","detest","reject; satiate (14)"],
["膿","고름 농","농","pus (17)"],
["蚍","왕개미 비","비","mussels; various shellfish (10)"],
["拔","밋밋할 패","패","휘어 꺾을 패","uproot","pull out (8)"],
["厮","부릴 시","시","마부 시","나눌 시","servant; to make a disturbance (14)"],
["巪","사람 이름자 걱","걱","person's name (6)"],
["鬢","살쩍 빈","빈","hair on temples (24)"],
["睨","흘겨볼 예","예","해 기울어질 예","look askance at","glare at; squint (13)"],
["拕","끌 타","타","당길 타","to drag after","to drag out","from which comes:to involve","to delay","to implicate (8)"],
["巫","무당 무","무","산 이름 무","wizard","sorcerer","witch","shaman (7)"],
["洑","보 보","보","whirlpool","undercurrent (9)"],
["部","거느릴 부","부","part","division","section (11)"],
["鬣","갈기 렵","렵","horse's mane; fin; human whiskers (25)"],
["睫","속눈썹 첩","첩","eyelashes (13)"],
["拗","고집스러울 요","요","to pull","drag","break off","to pluck (a flower); bent","warped; perverse","obstinate (8)"],
["洒","씻을 세","세","설치할 세","sprinkle; scatter; pour; to wipe away; to shiver (9)"],
["巭","공부할 부","부","study (9)"],
["郪","고을 이름 처","처","name of a stream in Sichuan province (12)"],
["睬","주목할 채","채","notice; pay attention to (13)"],
["差","어긋날 치","치","오르락 내리락할 치","구분지을 치","to differ; different","wrong; nearly","almost; an officer (10)"],
["拘","잡을 구","구","restrain","seize","detain (8)"],
["厲","갈 려","려","whetstone; grind","sharpen; whet (15)"],
["墀","계단 위의 공지 지","지","porch; courtyard; steps leading (14)"],
["拙","서툴 졸","졸","stupid","clumsy","crude; convention (8)"],
["爀","불빛 혁","혁","빛날 혁(18)"],
["墁","흙손 만","만","to plaster; to pave (14)"],
["鬧","시끄러울 료","료","quarrel; dispute hotly (15)"],
["蚓","지렁이 인","인","earthworm (10)"],
["鬨","싸울 홍","홍","boisterous; clamor","noise (16)"],
["招","부를 초","초","beckon","summon; recruit","levy (8)"],
["己","자기 기","기","self","oneself; personal","private; 6th heavenly stem (3)"],
["郭","성곽 곽","곽","outer part (of a city); surname (11)"],
["鬩","송사할 혁","혁","싸울 혁","feud","fight","quarrel (18)"],
["蚕","지렁이 천","천","silkworms (10)"],
["拜","절 배","배","do obeisance","bow","kowtow (9)"],
["洗","씻을 세","세","세수 그릇 세","wash","rinse; clean","purify (9)"],
["厶","사사 사","사","private","secret; KangXi radical 28 (2)"],
["境","지경 경","경","boundary","frontier; area","region (14)"],
["鬪","싸움 투","투","struggle","fight","compete","contend (20)"],
["厷","둥글 굉","굉","the fore-arm; most Chinese-English dictionaries give the upper-arm; round (4)"],
["已","이미 이","이","그칠 이","버릴 이","너무 이","already; finished; stop (3)"],
["郯","나라이름 담","담","name of a small ancient principality which was situated in what is now part of Shantung and Kiangsu (11)"],
["洙","물가 수","수","물읾 수","name of a river in Shandong (9)"],
["巳","여섯째 지지 사","사","the hours from 9 to 11; 6th terrestrial branch (3)"],
["墅","농막 서","서","villa","country house (14)"],
["巴","꼬리 파","파","뱀 파","땅이름 파","greatly desire","anxiously hope (4)"],
["厹","세모창 구","구","spear; tribe (4)"],
["巵","잔 치","치","연지 치","measuring cup; wine container (7)"],
["洛","강이름 락","락","river in Shanxi province; city (9)"],
["爆","불터질 폭","폭","crackle","pop","burst","explode (19)"],
["去","갈 거","거","버릴 거","오래될 거","떨어질 거","쫓을 거","감출 거","go away","leave","depart (5)"],
["鬮","제비뽑을 구","구","lots (to be drawn); draw lots (27)"],
["巷","거리 항","항","마을 항","복도 항","내시 항","alley","lane (9)"],
["郴","땅이름 침","침","county in Hunan province; surname (11)"],
["鬯","울창주 청","청","sacrificial wine; unhindered (10)"],
["爇","불사를 설","설","burn (19)"],
["籃","바구니 람","람","basket (20)"],
["墉","작은 성 용","용","담벽 용","wall; fortified wall; small wall (14)"],
["墊","빠질 점","점","축축할 점","advance money","pay for another (14)"],
["洞","구렁 통","통","깊을 통","빌 통","밝을 통","cave","grotto; ravine; hole (9)"],
["郵","역말 우","우","지날 우","우편 우","postal","mail; post office (11)"],
["洟","콧물 이","이","눈물 이","못이름 이","snivel","mucus from nose (9)"],
["鬱","향기로운 풀 울","울","luxuriant; dense","thick; moody (29)"],
["闃","고요할 격","격","alone; quiet","still (17)"],
["鬲","오지병 격","격","땅이름 격","type of caldron with three hollow legs; name of a state; KangXi radical number 193 (10)"],
["墍","맥질할 기","기","paint","decorate (14)"],
["睹","볼 도","도","look at","gaze at; observe (14)"],
["巼","땅이름 팟","팟","place name (10)"],
["爍","빛날 삭","삭","shine","glitter","sparkle (19)"],
["巽","사양할 손","손","낮은체 할 손","부드러울 손","괘 이름 손","동과 남의 사이방향 손","5th of the 8 trigrams; South-east; mild","modest","obedient (12)"],
["闇","어두울 암","암","어리석을 암","close","shut; dark","dismal (17)"],
["巾","수건 건","건","덮을 건","kerchief; towel; turban; KangXi radical number 50 (3)"],
["墐","진흙 근","근","to build with soil","plaster over with mud (14)"],
["闈","대궐안의 문 위","위","gate","door; living quarters (17)"],
["津","나루 진","진","ferry; saliva; ford (9)"],
["蚣","지네 공","공","centipede (10)"],
["睽","어그러질 규","규","staring (14)"],
["闊","트일 활","활","broad","ample","wide; be apart (17)"],
["蚤","벼룩 조","조","flea; louse (10)"],
["爐","화로 로","로","fireplace","stove","oven","furnace (20)"],
["睾","못 고","고","testicle (14)"],
["籌","투호 살 주","주","chip","tally","token; raise money (20)"],
["洧","물이름 유","유","name of a river in honan (9)"],
["墓","무덤 묘","묘","grave","tomb (14)"],
["都","도읍 도","도","metropolis","capital; all","the whole; elegant","refined (12)"],
["闋","쉴 결","결","다할 결","풍류 마칠 결","문 닫을 결","close","shut; watch tower (17)"],
["睿","슬기로울 예","예","밝을 예","통할 예","shrewd","astute","clever","keen (14)"],
["籍","문서 적","적","왁자할 적","호적 적","압수할 적","임금이 가는 밭 적","record","register","list; census (20)"],
["括","묶을 괄","괄","include","embrace","enclose (9)"],
["闌","가막을 란","란","door screen; railing fence (17)"],
["拭","닦을 식","식","다듬을 식","wipe away stains with cloth (9)"],
["洩","퍼질 예","예","날개 훨훨칠 예","leak","drip; vent or release (9)"],
["闍","성문 층대 도","도","tower over city gate (17)"],
["洪","큰물 홍","홍","vast","immense; flood","deluge (9)"],
["拮","일할 길","길","laboring hard","occupied; pursue (9)"],
["鬼","귀신 귀","귀","ghost; spirit of dead; devil (10)"],
["蚨","파랑강충이 부","부","a kind of water-beetle cash","then dollars and money generally (10)"],
["洫","넘칠 혁","혁","빌 혁","봇도랑 혁","to ditch; a moat (9)"],
["蚩","치벌레 치","치","어리석을 치","업신여길 치","worm; ignorant","rustic; laugh (10)"],
["拯","건질 증","증","help","save","aid; lift","raise (9)"],
["増","더할 증","증","increase (14)"],
["籐","대 기구 등","등","climbing plants","vines","cane (21)"],
["蚪","올챙이 두","두","tadpole (10)"],
["爗","불 이글글할 엽","엽","번쩍번쩍할 엽(20)"],
["拱","두 손 맞잡을 공","공","fold hands on breast; bow","salute (9)"],
["乀","파임 불","불","stretch (1)"],
["籔","조리 수","수","곡식 세는 단위 휘 수","또아리 수","bamboo basket (21)"],
["拳","주먹 권","권","fist; various forms of boxing (10)"],
["洮","달아날 도","도","cleanse; river in Gansu province (9)"],
["乂","풀 벨 예","예","다스릴 예","govern","control","manage; nurture (2)"],
["柁","키 타","타","large tie-beams (9)"],
["墜","떨어질 추","추","잃을 추","fall down","drop","sink","go to ruin (15)"],
["爚","스러질 약","약","bright; fiery (21)"],
["乃","이에 내","내","then; really","indeed; as it turned out","after all; namely (2)"],
["闓","열 개","개","풀 개","활깍지 개","open; peaceful (18)"],
["闔","문짝 합","합","닫을 합","close; whole","entire; all; leaf (18)"],
["拵","꽂을 존","존","to make","prepare","manufacture; workmanship; an arrangement (9)"],
["爛","문드러질 란","란","rotten","spoiled","decayed (21)"],
["蚯","지렁이 구","구","earthworm (11)"],
["拶","핍박할 찰","찰","press","squeeze hard; force (9)"],
["腁","살갗 틀 변","변","callus (12)"],
["墝","메마른 밭 요","요","자갈 땅 요","stony (15)"],
["久","오랠 구","구","long time (ago); time passage","grow late (3)"],
["闕","대궐 궐","궐","watch tower; palace (18)"],
["蚰","노래기 유","유","땅지네 유","millipede (11)"],
["蚱","벼메뚜기 책","책","grasshopper; (edible) locust; cicada (11)"],
["增","불을 증","증","increase","add to","augment (15)"],
["洲","섬 주","주","continent; island; islet (9)"],
["拷","가질 고","고","torture and interrogate; hit (9)"],
["柄","자루 병","병","handle","lever","knob; authority (9)"],
["墟","옛 터 허","허","장 허","큰 두덕 허","high mound; hilly countryside; wasteland (15)"],
["乇","풀잎 적","적","to depend on","to entrust with (3)"],
["柅","얼레자루 니","니","flourish (9)"],
["讀","귀절 두","두","read","study; pronounce (22)"],
["爟","봉화 관","관","fire (22)"],
["籛","성씨 전","전","surname (22)"],
["洵","멀 현","현","TRUE","real","truly","really (9)"],
["洶","물살세찰 흉","흉","the rush of water; turbulent; noisy","restless (9)"],
["籜","대 껍질 탁","탁","bamboo sheath","bamboo shoots (22)"],
["腆","두터울 전","전","지극할 전","많이 차려놓을 전","prosperous; good; protruding (12)"],
["闚","갸웃이 볼 규","규","flash; flashing; steal look (19)"],
["拼","좇을 평","평","join together","link","incorporate (9)"],
["讃","기릴 찬","찬","praise","commend","eulogize (22)"],
["之","갈 지","지","s (marks preceding phrase as modifier of following phrase); it","him her","them; go to (4)"],
["柊","방망이 종","종","holly (9)"],
["拽","당길 예","예","drag","tow; throw; twist (9)"],
["洸","물 용솟음할 광","광","sparkle","glitter (9)"],
["關","화살 먹일 완","완","문지방 완","빗장 완","frontier pass; close; relation (19)"],
["籟","세 구멍 퉁소 뢰","뢰","bamboo flute; pipe; various sound (22)"],
["拾","十의 갖은자","자","pick up","collect","tidy up; accounting form of the numeral ten (9)"],
["洹","흐를 원","원","물 이름 원","river in Henan province (9)"],
["乍","잠깐 사","사","first time","for the first time (5)"],
["乎","인가 호","호","interrogative or exclamatory final particle (5)"],
["闞","범 소리 함","함","glance","peep; roar","growl (20)"],
["腊","포 석","석","year end sacrifice; dried meat (12)"],
["籠","대그릇 롱","롱","cage; cage-like basket (22)"],
["拿","붙잡을 나","나","take","hold","grasp; bring; with (10)"],
["洺","강 이름 명","명","river in Hebei province (9)"],
["乏","없을 핍","핍","옹색할 핍","다할 핍","lack; poor (5)"],
["爥","비칠 촉","촉","simmer","cook over slow fire (25)"],
["腋","겨드랑이 액","액","armpit (12)"],
["活","살 활","활","live","exist","survive; lively (9)"],
["洼","물 이름 와","와","hollow; pit; depression; swamp (9)"],
["墨","먹 묵","묵","형벌 이름 묵","ink; writing (15)"],
["闠","저자문 궤","궤","gate of a market (20)"],
["洽","윤태하게 할 흡","흡","to blend with","be in harmony; to penetrate; to cover; a river in Shenxi (9)"],
["闡","열 천","천","explain","clarify","elucidate (20)"],
["柏","나무 이름 백","백","cypress","cedar (9)"],
["墩","돈대 돈","돈","heap","mound","block of stone (15)"],
["乑","나란히 설 임","임","기어 오를 임","stand side by side (6)"],
["派","물 갈래 파","파","보낼 파","나눌 파","파벌 파","school of thought","sect","branch (9)"],
["籤","제비 첨","첨","tally; lot; marker (23)"],
["爨","불땔 찬","찬","oven","cooking stove; cook (29)"],
["腎","콩팥 신","신","불알 신","자지 신","kidneys; testes","gizzard (12)"],
["某","아무 모","모","certain thing or person (9)"],
["闢","열 벽","벽","open; settle","develop","open up (21)"],
["變","변할 변","변","change","transform","alter; rebel (23)"],
["籥","피리 약","약","잠글 약","key; woodwind instrument (23)"],
["爪","손톱 조","조","claw","nail","talon; animal feet (4)"],
["腐","썩을 부","부","rot","decay","spoil; rotten (14)"],
["腑","장부 부","부","bowels","entrails","internal organs (12)"],
["讌","잔치 연","연","모여 말할 연","a feast","banquet; to entertain","feast (23)"],
["籧","대자리 거","거","천상바라기 거","양잠기 거","crude bamboo mat (23)"],
["染","꼭두서니 염","염","물들일 염","dye; be contagious; infect (9)"],
["闥","대문 달","달","door; gate (21)"],
["爬","긁을 파","파","기어다닐 파","crawl","creep; climb; scramble (8)"],
["墮","떨어질 타","타","상투 타","잃을 타","fall","sink","let fall; degenerate (15)"],
["腒","건치 거","거","꿩 포 거","game (12)"],
["柔","부드러울 유","유","순할 유","복종할 유","soft; gentle","pliant (9)"],
["區","경계 우","우","저울 네 눈 우","숨길 우","area","district","region","ward; surname (11)"],
["爭","다툴 쟁","쟁","다스릴 쟁","분별할 쟁","to dispute","fight","contend","strive (8)"],
["腓","장딴지 비","비","calf; avoid; be ill; wither (12)"],
["讎","짝 수","수","원수 수","대거리할 수","비교할 수","갚을 수","enemy","rival","opponent (23)"],
["籩","제기 이름 변","변","bamboo container for food (25)"],
["十","열 십","십","전부 십","ten","tenth; complete; perfect (2)"],
["乖","어그러질 괴","괴","rebel; crafty","shrewd (8)"],
["腔","창자 강","강","속 빌 강","뼈대 강","말 허구리 강","chest cavity; hollow in body (12)"],
["墰","목긴 술병 담","담","an earthenware jar; a jug (15)"],
["卂","빠를 신","신","급히 날 신","to fly rapidly (3)"],
["千","일천 천","천","thousand; many","numerous; very; (Cant.) a cheater","swindler (3)"],
["腕","팔뚝 완","완","wrist (12)"],
["乘","오를 승","승","곱할 승","셈하는 단위 승","같은 물건 네 개 승","ride","ascend; avail oneself of; numerary adjunct for vehicles (10)"],
["爰","당길 원","원","이끌 원","가로되 원","lead on to; therefore","then (9)"],
["柘","산뽕나무 자","자","a thorny tree; sugarcane (9)"],
["卄","스물 입","입","twenty","twentieth (3)"],
["乙","새 을","을","천간 이름 을","굽힐 을","생선의 창자 을","모모 을","second; 2nd heavenly stem (1)"],
["讒","참소할 참","참","slander","defame","misrepresent (24)"],
["籬","울타리 리","리","bamboo or wooden fence; hedge (25)"],
["墳","무덤 분","분","grave","mound; bulge; bulging (15)"],
["卅","서른 삽","삽","thirty","thirtieth (4)"],
["柙","우리 합","합","향나무 합","cage","pen for wild animals (9)"],
["讓","사양할 양","양","allow","permit","yield","concede (24)"],
["爲","할 위","위","다스릴 위","하여금 위","어미원숭이 위","이룰 위","do","handle","govern","act; be (12)"],
["嶁","봉우리 루","루","Goulou mountain peak in Hunan (14)"],
["嶂","높고 가파른 산 장","장","cliff; mountain barrier (14)"],
["柚","북 축","축","pomelo","grapefruit (9)"],
["升","되 승","승","오를 승","피륙의 짜인 날을 세는 단위 새 승","괘 이름 승","arise","go up; hoist; advance (4)"],
["乜","사팔 뜨기 먀","먀","to squint; what? huh? (Cant.) (2)"],
["省","살필 성","성","province; save","economize (9)"],
["九","모을 규","규","nine (2)"],
["讖","참서 참","참","prophecy","hint","omen (24)"],
["嶄","높을 참","참","high","steep","precipitous; new (14)"],
["邀","맞을 요","요","구할 요","부를 요","invite","welcome; meet","intercept (17)"],
["爵","벼슬 작","작","feudal title or rank (18)"],
["午","낮 오","오","남쪽 오","말 오","noon; 7th terrestrial branch (4)"],
["乞","줄 기","기","beg; request (3)"],
["卉","풀 훼","훼","general term for plants; myriads (5)"],
["柝","쪼갤 탁","탁","조두 탁","목탁 탁","watchman's rattle (9)"],
["邁","갈 매","매","take a big stride; pass by (17)"],
["父","아비 부","부","father; KangXi radical 88 (4)"],
["也","단정의 어조사 야","야","also; classical final particle of strong affirmation or identity (3)"],
["邂","우연히 만날 해","해","unexpected meeting; encounter by (17)"],
["籲","부르짖을 유","유","appeal; request; implore (32)"],
["眄","애꾸눈 면","면","to look askance; looking dull (9)"],
["柞","나무 이름 책","책","oak; spinous evergreen tree; to clear away trees (9)"],
["半","반 반","반","half (5)"],
["讙","시끄러울 훤","훤","cheer; noise","noisy (25)"],
["爸","아비 파","파","father","papa (8)"],
["邃","깊숙할 수","수","profound","detailed; deep (17)"],
["米","쌀 미","미","hulled or husked uncooked rice (6)"],
["墺","방 구들 옥","옥","물가 언덕 옥","four walls (16)"],
["嶇","산험준할 구","구","steep","sheer; rugged","rough (14)"],
["讚","기릴 찬","찬","praise","commend","eulogize (26)"],
["還","돌아올 환","환","still","yet","also","besides (17)"],
["髀","볼기짝 폐","폐","buttocks; thigh","thigh bone (18)"],
["爹","아비 다","다","father","daddy (10)"],
["卌","사십 십","십","40 (5)"],
["邅","머뭇거릴 전","전","hesitant","faltering (17)"],
["髁","넓적다리뼈 화","화","thigh bone","hipbone; kneecap (18)"],
["爺","아비 야","야","노인 야","father","grandfather (13)"],
["眇","애꾸눈 묘","묘","blind in one eye; minute","minuscule (9)"],
["卍","만자 만","만","swastika fourth of auspicious (6)"],
["爻","효 효","효","diagrams for divination (4)"],
["乤","고을 이름 할","할","first character of Korean place name 'Halpho' (4)"],
["眈","노려볼 탐","탐","즐길 탐","gloat","stare at; to delay","to loiter","to hinder (9)"],
["腠","살결 주","주","between the skin and the flesh (13)"],
["柢","뿌리 저","저","root","base; bottom of object (9)"],
["讜","곧은 말 당","당","counsel","advice; speak out (27)"],
["邇","가까울 이","이","be near","be close; recently (18)"],
["髂","허리뼈 가","가","the pelvis (19)"],
["眉","눈썹 미","미","노인 미","eyebrows; upper margin of book (9)"],
["嶋","섬 도","도","island (14)"],
["邈","멀 막","막","far","distant","remote; slight (18)"],
["讞","죄 의논할 언","언","to decide","judge; a verdict","decision (27)"],
["爽","시원할 상","상","happy","cheerful; refreshing (11)"],
["眊","눈 흐릴 모","모","dim-sighted; dull","bewildered (9)"],
["墾","따비질할 간","간","cultivate","reclaim","to farm land (16)"],
["爾","너 이","이","가까울 이","오직 이","you; that","those; final particle (14)"],
["看","볼 간","간","look","see; examine","scrutinize (9)"],
["卑","낮을 비","비","humble","low","inferior; despise (8)"],
["県","고을 현","현","지방 현","county","district","subdivision (9)"],
["卒","군사 졸","졸","soldier; servant; at last","finally (8)"],
["邊","가 변","변","edge","margin","side","border (19)"],
["卓","높을 탁","탁","뛰어날 탁","책상 탁","성씨 탁","profound","brilliant","lofty (8)"],
["髆","어깨쭉지 박","박","shoulder blade (20)"],
["腥","비린내 성","성","더러울 성","날고기 성","raw meat; rank","strong-smelling (13)"],
["类","나눌 류","류","class","group","kind","category (9)"],
["乩","점칠 계","계","to divine (6)"],
["協","조화할 협","협","맞을 협","도울 협","be united; cooperate (8)"],
["邌","천천히 걸을 려","려","to walk slowly; to parade (19)"],
["腦","머리 뇌","뇌","brain (13)"],
["柩","관 구","구","coffin which contains corpse (9)"],
["乫","땅이름 갈","갈","used in Korean place names (6)"],
["籾","벼 인","인","unhulled rice (9)"],
["乬","갈 걸","걸","to hang (6)"],
["嶒","산 높고 험할 증","증","towering","lofty","steep (15)"],
["邏","순행할 라","라","patrol; inspect; watch (23)"],
["南","남녘 남","남","South; Southern part; Southward (9)"],
["乭","이름 돌","돌","rock; used in Korean names (6)"],
["邐","이어질 리","리","meandering","winding (23)"],
["柬","가릴 간","간","letter","invitation; choose (9)"],
["腫","부스럼 종","종","swell; swollen; swelling (13)"],
["邑","고을 읍","읍","답답할 읍","area","district","city","state (7)"],
["嶔","높고 험할 금","금","lofty","towering (15)"],
["技","재주 기","기","skill","ability","talent","ingenuity (7)"],
["博","넓을 박","박","gamble","play games; wide","broad (12)"],
["腭","잇몸 악","악","palate","roof of mouth (13)"],
["柯","가지 가","가","axe-handle; stalk","bough; surname (9)"],
["乱","어지러울 란","란","confusion","state of chaos","revolt (7)"],
["髏","해굴 루","루","skull; skeleton (21)"],
["柰","능금나무 내","내","crab-apple tree; endure","bear (9)"],
["卜","점 복","복","fortune telling; prophesy (2)"],
["柱","기둥 주","주","pillar","post; support; lean on (9)"],
["邕","막힐 옹","옹","사람이름 옹","former or literary name for Nanning (in Guangxi) (10)"],
["抃","손뼉칠 변","변","to clap hands; to cheer (7)"],
["乳","젖 유","유","종유석 유","낳을 유","젖먹일 유","기를 유","breast","nipples; milk","suckle (8)"],
["髑","해골 촉","촉","skull (23)"],
["腰","허리 요","요","waist; kidney (13)"],
["卞","조급할 변","변","to be impatient","in a hurry; excitable (4)"],
["嶙","가파를 린","린","precipitous (15)"],
["邗","고을 이름 한","한","an ancient place in the state of Wu (6)"],
["抄","베낄 초","초","copy","confiscate","seize (7)"],
["髒","몸 뚱뚱할 장","장","dirty; firm; fat (23)"],
["腱","힘줄 건","건","tendons (13)"],
["柳","버들 류","류","willow tree; pleasure (9)"],
["卟","점칠 게","게","생각할 게","a chemical compound; divination; to consider (5)"],
["況","하물며 황","황","condition","situation; furthermore (8)"],
["占","점칠 점","점","날씨 볼 점","점령할 점","divine; observe; versify (5)"],
["髓","뼛속 기름 수","수","마음속 수","bone marrow; essences","substances (23)"],
["柴","섶 채","채","firewood","faggots","fuel (9)"],
["泂","멀 형","형","깊고 넓은 모양 형","차가운 형","clear and deep (of water); wide (8)"],
["乶","땅이름 폴","폴","Korean place name Pholha (8)"],
["體","몸 체","체","body; group","class","body","unit (23)"],
["柵","울짱 책","책","fence; palisade; grid (9)"],
["卡","관 잡","잡","card","punch card; calorie (5)"],
["眚","눈에백태낄 생","생","disease of the eyes; crime","fault (10)"],
["抆","닦을 문","문","문지를 문","wipe","rub off (7)"],
["邙","산 이름 망","망","mountain in Henan province (6)"],
["髕","종지뼈 빈","빈","kneecap (24)"],
["腴","아랫배 살찔 유","유","fat; fertile","rich; plump","soft (13)"],
["眛","어두울 매","매","dim","dark","obscure; blind (10)"],
["柶","수저 사","사","ladle (9)"],
["嶝","고개 등","등","path leading up a mountain (15)"],
["柷","축풍류 축","축","instrument (9)"],
["髖","허리뼈 관","관","hipbone; hip (25)"],
["泄","내칠 예","예","날개 훨훨칠 예","흩을 예","leak; vent; flow; reveal (8)"],
["卣","중술통 유","유","wine pot (7)"],
["邛","언덕 공","공","in distress; a mound (6)"],
["髗","해골 로","로","the skull; the forehead (26)"],
["腶","약포 단","단","meat (13)"],
["抉","긁을 결","결","당길 결","choose","select; gouge","pluck out (7)"],
["腷","뜩 막힐 픽","픽","답답할 픽","닭 홰치는 소리 픽(13)"],
["把","잡을 파","파","헤칠 파","hold","take; guard; regard as (7)"],
["眞","참 진","진","real","actual","TRUE","genuine (10)"],
["高","높을 고","고","high","tall; lofty","elevated (10)"],
["泆","끊을 일","일","licentious","libertine","dissipated (8)"],
["嶠","뾰족하게 높을 교","교","high pointed mountain (15)"],
["乺","솔 솔","솔","땅이름 솔","painting tool place name (9)"],
["腸","창자 장","장","intestines; emotions; sausage (13)"],
["柹","감 시","시","persimmon (9)"],
["卦","걸 괘","괘","fortune telling; tell fortunes (8)"],
["乼","줄 줄","줄","rope (9)"],
["腹","배 복","복","stomach","belly","abdomen; inside (13)"],
["眠","잠잘 면","면","close eyes","sleep; hibernate (10)"],
["嶢","높을 요","요","high or tall (15)"],
["泉","샘 천","천","spring","fountain; wealth","money (9)"],
["腺","샘 선","선","gland (13)"],
["査","사실할 사","사","investigate","examine","seek into (9)"],
["邠","나라 이름 빈","빈","county in Shaanxi province (7)"],
["泊","머무를 박","박","anchor vessel; lie at anchor (8)"],
["乾","하늘 건","건","괘 이름 건","서북쪽 건","굳셀 건","임금 건","dry; first hexagram; warming principle of the sun","penetrating and fertilizing","heavenly generative principle (male) (11)"],
["卩","몸기 절","절","병부 절","seal; KangXi radical 26 (2)"],
["邢","나라 이름 형","형","땅 이름 형","surname; state in today's Hebei (7)"],
["柾","나무결 바를 정","정","straight grain (9)"],
["泌","도랑물 필","필","개천물 필","to seep out","excrete (8)"],
["髟","머리털 희뜩희뜩할 표","표","깃발 날릴 표","머리 늘일 표","hair; KangXi radical 190 (10)"],
["那","어찌 나","나","that","that one","those (7)"],
["眥","흘길 자","자","eye sockets; corner of the eyes (10)"],
["抑","누를 억","억","억울할 억","press down","repress; curb","hinder (7)"],
["嶧","연달아 있는 산 역","역","a range of peaks in Shandong and Jiangsu; the name of a mountain (16)"],
["抒","당길 저","저","북 저","express; eliminate; relieve (7)"],
["卬","자기 앙","앙","lofty; high; raise; high-priced (4)"],
["腿","넓적다리 퇴","퇴","legs","thighs (14)"],
["抓","긁을 조","조","scratch; clutch","seize","grab (7)"],
["髡","머리 깍을 곤","곤","to shear tree; ancient punishment (13)"],
["卭","병될 공","공","high. to raise (5)"],
["虎","범 호","호","tiger; brave","fierce; surname (6)"],
["卮","술잔 치","치","연지 치","measuring cup; wine container (5)"],
["眨","눈 깜작일 잡","잡","wink (10)"],
["邦","나라 방","방","nation","country","state (7)"],
["抔","움킬 부","부","take or hold up in both hands (7)"],
["眩","요술 환","환","to confuse; dizzy","dazed","disoriented (10)"],
["投","던질 투","투","버릴 투","줄 투","의탁할 투","throw","cast","fling","pitch; jump (7)"],
["泐","돌 갈라질 륵","륵","to write; to indite; veins in minerals (8)"],
["卯","토끼 묘","묘","4th terrestrial branch; period from 5-7 a.m. (5)"],
["虐","사나울 학","학","cruel","harsh","oppressive (7)"],
["髣","비슷할 방","방","similar to","like (14)"],
["抖","퍼주거릴 두","두","tremble","shake","rouse; give shake (7)"],
["泑","물빛이 검을 유","유","물이름 유","the vitreous glaze on china","porcelain","etc. (8)"],
["印","도장 인","인","print","seal","stamp","chop","mark (6)"],
["抗","막을 항","항","들 항","겨룰 항","resist","oppose","defy","reject (7)"],
["危","위태할 위","위","무너질 위","상할 위","높을 위","별 이름 위","dangerous","precarious; high (6)"],
["泓","깊을 홍","홍","clear","deep pool of water (8)"],
["嶮","산 가파를 험","험","high","steep","precipitous (16)"],
["折","천천히 할 제","제","break off","snap; bend (7)"],
["邪","의문의 어조사 야","야","희롱할 야","wrong","evil","depraved","vicious","perverse","heterodox (7)"],
["髥","구레나룻 염","염","beard; mustache (14)"],
["卲","높을 소","소","성씨 소","eminent","lofty; beautiful; surname (7)"],
["塀","담 병","병","wall","fence (12)"],
["髦","다팔머리 모","모","flowing hair of young child (14)"],
["虓","울부짖을 효","효","roar (8)"],
["嶰","골짝 사이 해","해","골 이름 해","gorge (16)"],
["法","법 법","법","law","rule","regulation","statute; France","French (8)"],
["却","물리칠 각","각","still","but; decline; retreat (7)"],
["虔","정성 건","건","빼앗을 건","죽일 건","act with reverence; reverent (8)"],
["卵","알 란","란","egg; ovum; roe; spawn (7)"],
["處","살 처","처","place","locale; department (9)"],
["燁","불 이글글할 엽","엽","번쩍번쩍할 엽","bright","glorious","splendid","flame (16)"],
["泗","물 이름 사","사","mucous; nasal flow; sniffle; river in Shandong (8)"],
["邯","고을 이름 함","함","city in Hebei province; various (8)"],
["卷","굽을 권","권","scroll; curl; make a comeback (8)"],
["泙","물소리 평","평","물결 셀 평","roar (8)"],
["邰","태나라 태","태","surname; state in modern Shanxi (8)"],
["髫","다박머리 초","초","children's hair style; a youngster (15)"],
["燃","불사를 연","연","연등절 연","burn; light fire","ignite (16)"],
["卸","풀 사","사","lay down; retire from office (8)"],
["卹","가엾이 여길 휼","휼","sympathy","pity; comfort (8)"],
["簀","살평상 책","책","bed mat; mat (17)"],
["邱","언덕 구","구","surname; hill; mound; grave (8)"],
["泚","맑을 자","자","clear; bright and brilliant; swea (9)"],
["髭","코밑수염 자","자","mustache (15)"],
["眴","눈짓할 순","순","dazzled (11)"],
["泛","엎을 봉","봉","to drift","float; careless","reckless (8)"],
["卺","합환주잔 근","근","(nuptial) winecups (8)"],
["泜","강 이름 지","지","a river in Hebei province (8)"],
["邳","클 비","비","a department in the State of Lu; in what is now Shantung or N. China (8)"],
["髮","터럭 발","발","hair (15)"],
["門","문 문","문","gate","door","entrance","opening (8)"],
["虛","빌 허","허","헛될 허","다할 허","약할 허","거짓 허","하늘 허","FALSE","worthless; empty","hollow (10)"],
["邴","고을 이름 병","병","name of a city in the ancient state of Song","in what is now Shandong; pleased (8)"],
["泝","거슬러 올라갈 소","소","흘러갈 소","go upstream; trace source; formerly (8)"],
["卼","위태할 올","올","unsteady; dangerous (9)"],
["嶷","산이름 의","의","a range of mountains in Hunan province (17)"],
["卽","곧 즉","즉","promptly (9)"],
["塊","흙덩이 괴","괴","piece","lump; dollar (13)"],
["邵","땅이름 소","소","성씨 소","surname; various place names (8)"],
["虜","포로 로","로","to capture","imprison","seize; a prison (10)"],
["燈","등잔 등","등","lantern","lamp (16)"],
["嶸","산 높을 영","영","high","steep; lofty","towering (17)"],
["邶","패나라 패","패","place in today's Henan province (8)"],
["塋","무덤 영","영","grave","tomb","cemetery (13)"],
["閃","번쩍할 섬","섬","flash; avoid","dodge","evade (10)"],
["眷","돌아볼 권","권","take interest in","care for (11)"],
["燉","불 성할 돈","돈","heat with fire; stew (16)"],
["髲","머리쓰개 피","피","첩지 피","a wig (15)"],
["塌","낮은 땅 탑","탑","초벌갈 탑","떨어질 탑","fall in ruins","collapse (13)"],
["虞","염려할 우","우","즐거울 우","갖출 우","우제 지낼 우","concerned about","anxious","worried (11)"],
["眸","눈동자 모","모","pupil of eye; eye (11)"],
["泠","깨우칠 령","령","nice and cool","mild and comfortable (8)"],
["卿","벼슬 경","경","noble","high officer (11)"],
["嶺","재 령","령","mountain ridge","mountain peak (17)"],
["號","울부짖을 호","호","mark","sign; symbol; number (11)"],
["泡","물거품 포","포","물이 솟아날 포","물 흐를 포","성할 포","bubbles","suds; blister; soak (8)"],
["燋","홰 초","초","scorch","burn","scald; torch (16)"],
["簇","모일 족","족","swarm","crowd together","cluster (17)"],
["邸","사처 저","저","주막 저","홀바탕 저","병풍 저","official residence","residence of (8)"],
["波","물결 파","파","물 젖을 파","눈 광채 파","움직일 파","달빛 파","waves","breakers; undulations (8)"],
["眺","바라볼 조","조","look at","gaze at","scan","survey (11)"],
["髴","비슷할 불","불","disheveled hair; similar to (15)"],
["嶼","섬 서","서","island (17)"],
["塏","시원한 땅 개","개","high and dry place (13)"],
["泣","눈물 줄줄 흘릴 읍","읍","부글부글 끓는 소리 읍","cry","sob","weep (8)"],
["嶽","높은 산 악","악","mountain peak","summit (17)"],
["閈","마을 문 한","한","village (11)"],
["抨","탄핵할 평","평","impeach","censure; attack (8)"],
["簉","버금 추","추","가지런히 날 추","deputy","subordinate; concubine (17)"],
["眼","눈 안","안","eye; eyelet","hole","opening (11)"],
["燎","화톳불 료","료","to burn","set afire; to illuminate; a signal lamp (16)"],
["塑","허수아비 소","소","model in clay","sculpt; plastics (13)"],
["閉","닫을 폐","폐","마칠 폐","가릴 폐","shut","close; obstruct","block up (11)"],
["泥","막힐 니","니","mud","mire; earth","clay; plaster (8)"],
["塒","홰 시","시","roost (13)"],
["閊","비색할 산","산","to be obstructed","blocked; to break down (11)"],
["燐","도깨비불 린","린","phosphorus (16)"],
["簋","대제기 궤","궤","a square basket of bamboo for holding grain used at sacrifices","feast (17)"],
["披","헤칠 피","피","흩어질 피","찢어질 피","wear; split; crack (8)"],
["邽","고을이름 규","규","name of a county in Han dynasty (9)"],
["開","열 개","개","통할 개","발할 개","베풀 개","풀 개","open; initiate","begin","start (12)"],
["抬","칠 태","태","lift","carry (8)"],
["塔","탑 탑","탑","tower","spire","tall building (13)"],
["邾","나라이름 주","주","a feudal state which existed B.C.700-469; now in Shandong (9)"],
["注","물댈 주","주","concentrate","focus","direct (8)"],
["髺","머리 묶을 괄","괄","the hair dishevelled","as in mourning (16)"],
["燒","불 붙을 소","소","들불 소","불 놓을 소","burn; bake; heat; roast (16)"],
["虧","이지러질 휴","휴","lose","fail; damage; deficient (15)"],
["髻","상투 계","계","hair rolled up in a bun","topknot (16)"],
["燔","구울 번","번","to roast; to burn (16)"],
["簏","대 상자 록","록","a box","a basket (17)"],
["泫","물 깊을 현","현","이슬 맺힐 현","눈물 줄줄 흘릴 현","weep; cry; shine","glisten (8)"],
["髽","북상투 좌","좌","to dress the hair (17)"],
["塗","바를 도","도","smear","daub","apply","spread; paint (13)"],
["閎","마을문 굉","굉","gate","barrier; wide","vast","expand (12)"],
["燕","제비 연","연","편안할 연","잔치 연","나라이름 연","swallow (bird); comfort","enjoy (16)"],
["塘","연못 당","당","pond; tank; dike","embankment (13)"],
["閏","윤달 륜","륜","intercalary; extra","surplus (12)"],
["燖","삶을 심","심","reheat food (16)"],
["抱","안을 포","포","품을 포","낄 포","embrace","hold in arms","enfold (8)"],
["虫","벌레 훼","훼","insects","worms; KangXi radical 142 (6)"],
["簒","빼앗을 찬","찬","usurp (17)"],
["塙","단단할 고","고","truly (13)"],
["一","한 일","일","one; a","an; alone (1)"],
["閑","한가할 한","한","fence","barrier; defend; idle time (12)"],
["丁","장정 정","정","네째 천간 정","male adult; robust","vigorous; 4th heavenly stem (2)"],
["燙","씻을 탕","탕","불에 델 탕","scald","heat; wash; iron clothes (16)"],
["丂","교묘할 교","교","obstruction of breath (qi) as it seeks release; variant of other characters (2)"],
["閒","한가할 한","한","peaceful","tranquil","calm (12)"],
["泮","학교 반","반","Zhou dynasty school; disperse; fall apart (8)"],
["七","일곱 칠","칠","글체이름 칠","seven (2)"],
["間","틈 간","간","사이 간","interval","space; place","between (12)"],
["泯","망할 민","민","destroy","eliminate; perish (8)"],
["泰","클 태","태","통할 태","너그러울 태","편안할 태","괘 이름 태","great","exalted","superior; big (9)"],
["抵","칠 지","지","resist","oppose; deny; off-set (8)"],
["塜","먼지 일 봉","봉","dusty","dirt (13)"],
["閔","위문할 민","민","mourn","grieve; urge on","incite (12)"],
["虯","뿔없는 용 규","규","young dragon (8)"],
["泱","끝없을 앙","앙","great","expansive; agitated (8)"],
["脂","기름 지","지","fat","grease","lard; grease (10)"],
["塞","막힐 색","색","stop up","block","seal","cork; pass","frontier; fortress (13)"],
["万","성씨 묵","묵","ten thousand; innumerable (3)"],
["泳","헤엄칠 영","영","dive; swim (8)"],
["丈","어른 장","장","unit of length equal 3.3 meters; gentleman","man","husband (3)"],
["閘","문 빗장 갑","갑","물 문 갑","sluice; flood gate","canal lock (13)"],
["營","별이름 형","형","encampment","barracks; manage (17)"],
["抹","바랄 말","말","smear","apply","wipe off","erase (8)"],
["譁","시끄러울 화","화","noise","uproar; clamor","hubbub (19)"],
["脅","갈빗대 협","협","위협할 협","ribs; armpits; flank; threaten (10)"],
["塡","누를 진","진","fill in","fill up; make good (13)"],
["三","석 삼","삼","three (3)"],
["枇","비파나무 비","비","loquat (8)"],
["脆","연할 취","취","약할 취","crisp; fragile","frail; brittle (10)"],
["塢","산 언덕 오","오","마을 오","entrenchment","bank","low wall (13)"],
["上","위 상","상","top; superior","highest; go up","send up (3)"],
["燠","더울 욱","욱","warm; warmth (17)"],
["下","아래 하","하","under","underneath","below; down; inferior; bring down (3)"],
["押","수결 둘 압","압","누를 압","mortgage","pledge; deposit; to pawn; to arrest","detain","guard (8)"],
["枉","굽을 왕","왕","원통할 왕","useless","in vain; bent","crooked (8)"],
["抽","뺄 추","추","뽑을 추","당길 추","거둘 추","draw out","pull out; sprout (8)"],
["丌","책상 기","기","table (3)"],
["脈","맥 맥","맥","blood vessels","veins","arteries (10)"],
["簞","소쿠리 단","단","small bamboo basket for holding (18)"],
["簟","삿자리 점","점","대 이름 점","bamboo mat (18)"],
["不","아닐 불","불","no","not; un-; negative prefix (4)"],
["枋","다목 방","방","sandalwood; tree used as timber (8)"],
["脊","등성마루 척","척","spine","backbone; ridge (10)"],
["簠","제기 이름 보","보","an ancient vessel used for holding boiled grain (18)"],
["枌","나무 이름 분","분","variety of elm with small seeds (8)"],
["燥","마를 조","조","dry","parched","arid; quick-tempered (17)"],
["丏","가릴 면","면","parapet; invisible (4)"],
["閟","문 닫을 비","비","hide","keep secret; hideaway (13)"],
["虹","무지개 홍","홍","rainbow (9)"],
["簡","대쪽 간","간","simple","terse","succinct; letter (18)"],
["虺","살무사 훼","훼","large poisonous snake (9)"],
["燦","빛날 찬","찬","vivid","illuminating; bright (17)"],
["丐","빌 개","개","beggar; beg; give (4)"],
["丑","소 축","축","축시 축","북동쪽 축","clown","comedian; 2nd terrestrial branch (4)"],
["燧","봉화 수","수","불 수","나무 문질러 불낼 수","flintstone; beacon","signal fire; torch (17)"],
["枏","녹나무 남","남","a variety of evergreen tree (8)"],
["證","증거 증","증","proof","evidence; testify","verify (19)"],
["析","가를 석","석","split wood; break apart; divide (8)"],
["閣","문설주 각","각","chamber","pavilion; cabinet (14)"],
["閤","도장 합","합","침방 합","개구리소리 합","small side door; chamber (14)"],
["簧","혀 황","황","reed of woodwind instrument (18)"],
["且","또 차","차","moreover","also (post-subject); about to","will soon (pre-verb) (5)"],
["閥","공훈 벌","벌","powerful and influential group (14)"],
["譌","거짓 와","와","와전될 와","요사스런 말 와","FALSE","erronious (19)"],
["枓","두공 두","두","capital (8)"],
["燬","불 훼","훼","burn down; blaze","fire (17)"],
["閦","무리 축","축","아축부처 축","crowd; transliteration of Sanskrit 'kso'","e.g. 'aksobhya' (14)"],
["丕","클 비","비","great","grand","glorious","distinguished (5)"],
["譎","속일 휼","휼","cunning","crafty","sly","wily (19)"],
["閧","싸우는 소리 홍","홍","boisterous; clamor","noise (14)"],
["枕","베개 침","침","수레뒤 가로댄 나무 침","소말뚝 침","pillow (8)"],
["匁","몸메 문","문","Japanese unit of weight (1/1000 of a kan) (4)"],
["世","세상 세","세","평생 세","백년 세","generation; world; era (5)"],
["匂","향내 내","내","fragrance","smell (4)"],
["閨","색시 규","규","small entrance; women's quarters (14)"],
["譏","나무랄 기","기","ridicule","jeer","mock; inspect (19)"],
["燮","불에 익힐 섭","섭","불꽃 섭","조화로울 섭","harmonize","blend; adjust (17)"],
["簪","비녀 잠","잠","hairpin","clasp; wear in hair (18)"],
["匃","청구할 개","개","줄 개","빌 개","beggar; beg; give (5)"],
["丘","언덕 구","구","hill; elder; empty; a name (5)"],
["閩","종족이름 민","민","fujian province; a river; a tribe (14)"],
["林","수풀 림","림","forest","grove; surname (8)"],
["丙","남녘 병","병","third; 3rd heavenly stem (5)"],
["閪","잃을 서","서","to lose lost item; (Cant.) vagina (vulg.) (14)"],
["脖","배꼽 발","발","neck (11)"],
["簫","퉁소 소","소","활고지 소","musical instrument like pan-pipes","bamboo flute (18)"],
["包","꾸릴 포","포","용납할 포","더부룩히 날 포","보따리 포","wrap","pack","bundle; package (5)"],
["閫","문지방 곤","곤","threshold; women's quarters (15)"],
["枘","자루 예","예","the handle of tools (8)"],
["匆","바쁠 총","총","hastily","in haste","hurriedly (5)"],
["嵁","산 험할 감","감","rugged (12)"],
["閬","솟을 대문 랑","랑","high door; high gate; high","lofty (15)"],
["脘","중완 완","완","internal cavity of stomach (11)"],
["塵","띠끌 진","진","dust","dirt","ashes","cinders (14)"],
["嵂","가파를 률","률","lofty (12)"],
["譔","가르칠 선","선","eulogize; compose","write (19)"],
["枚","줄기 매","매","stalk of shrub","trunk of tree (8)"],
["閭","이문 려","려","village of twenty-five families (15)"],
["脚","다리 각","각","leg","foot; base","leg","foundation (11)"],
["譖","참소할 참","참","to slander (19)"],
["丞","이을 승","승","도울 승","벼슬의 이름 승","향상할 승","assist","aid","rescue (6)"],
["脛","정강이 경","경","shinbone; calf of leg (11)"],
["盂","바리 우","우","사냥하는 진 우","basin; cup (8)"],
["果","실과 과","과","fruit; result (8)"],
["匉","크게 소리지를 평","평","noise of waters (7)"],
["枝","가지 지","지","branches","limbs; branch off (8)"],
["丟","갈 주","주","discard","reject","lose (6)"],
["遁","달아날 둔","둔","hide","conceal oneself; escape (13)"],
["塹","구덩이 참","참","moat","trench","pit","cavity (14)"],
["匊","움켜 뜰 국","국","handful (8)"],
["遂","사무칠 수","수","나아갈 수","이룰 수","마침내 수","comply with","follow along; thereupon (13)"],
["識","표할 지","지","recognize","understand","know (19)"],
["譙","꾸짖을 초","초","tower; surname (19)"],
["匋","기오 굽는 가마 요","요","pottery (8)"],
["閱","겪을 열","열","군대 점호할 열","용납할 열","examine","inspect","review","read (15)"],
["燹","들불 희","희","fire; wild fires (18)"],
["丢","잃어버릴 주","주","discard (6)"],
["譚","클 담","담","surname; to talk (19)"],
["盆","동이 분","분","basin","tub","pot","bowl (9)"],
["匍","엉금엉금 길 포","포","엎드러질 포","crawl; lie prostrate (9)"],
["遅","더딜 지","지","late","tardy; slow; delay (13)"],
["燻","연기 낄 훈","훈","smoke","fog","vapor; smoke","cure (18)"],
["塼","벽돌 전","전","brick (14)"],
["匎","사치스럽지 않을 압","압","검약할 압(10)"],
["两","쌍 량","량","two","both","pair","couple; ounce (7)"],
["譜","계보 보","보","register","list","table; musical score (19)"],
["盈","가득할 영","영","넘칠 영","물 흐를 영","fill; full","overflowing; surplus (9)"],
["匏","박 포","포","바가지 포","퉁소 포","gourd; musical instrument (11)"],
["簷","처마 첨","첨","eaves of house; brim (19)"],
["嵋","산이름 미","미","Omei mountain in Sichuan (12)"],
["遇","만날 우","우","대접할 우","뜻밖에 우","meet","come across","encounter (13)"],
["燼","깜부기불 신","신","불똥 신","재난의 뒤 신","cinders","ashes","embers; remnants (18)"],
["塽","높고밝은땅 상","상","plateau (14)"],
["簸","까부를 파","파","키 파","winnower (19)"],
["閶","천문 청","청","gate of heaven; main gate of palace (16)"],
["匐","길 복","복","fall prostrate; crawl (11)"],
["嵌","깊은 골 감","감","곁굴 감","깊은 산 감","inlay","set in; fall into; rugged (12)"],
["益","더할 익","익","profit","benefit; advantage (10)"],
["塾","사랑방 숙","숙","기숙사 숙","village school; private tutorage (14)"],
["譟","시끄러울 조","조","clamor","noise","din; slander (20)"],
["遉","순라군 정","정","염탐할 정","spy","reconnoiter; detective (13)"],
["脣","입술 순","순","lips (11)"],
["燾","비칠 도","도","shine","illuminate; cover","envelope (18)"],
["丨","뚫을 곤","곤","number one; line; KangXi radical 2 (1)"],
["遊","놀 유","유","벗 사귈 유","wander","roam","travel (13)"],
["脤","사직제 지낸 고기 신","신","raw meat for sacrifice (11)"],
["燿","비칠 요","요","빛날 요","shine","dazzle; brilliant","radiant (18)"],
["盌","은 바리 완","완","bowl","basin","cup (10)"],
["盍","덮을 합","합","합할 합","어찌 아니할 합","what? why not? (10)"],
["丩","넝쿨 구","구","to join or connect the vine (2)"],
["閹","고자 엄","엄","내시 엄","castrate; eunuch (16)"],
["運","움직일 운","운","운수 운","luck","fortune; ship","transport (13)"],
["个","낱 개","개","numerary adjunct","piece; single (3)"],
["盎","동이 앙","앙","cup; pot; bowl; abundant (10)"],
["遍","두루 편","편","everywhere","all over","throughout (13)"],
["簽","농 첨","첨","sign","endorse; slip of paper (19)"],
["匕","비수 비","비","spoon","ladle; knife","dirk (2)"],
["嵐","남기 람","람","mountain mist","mountain haze (12)"],
["丫","가장귀 아","아","forked; bifurcation (3)"],
["閻","마을 염","염","항간 염","저승 염","village gate; surname (16)"],
["化","될 화","화","change","convert","reform; -ize (4)"],
["過","지날 과","과","pass","pass through","go across (13)"],
["簾","발 렴","렴","a blind","screen","curtain (19)"],
["閽","문지기 혼","혼","gatekeeper; gate","door (16)"],
["中","가운데 중","중","central; center","middle; in the midst of; hit (target); attain (4)"],
["北","북녘 북","북","north; northern; northward (5)"],
["遏","막을 알","알","stop","suppress","curb","check; a bar (13)"],
["脩","포 수","수","길 수","마를 수","dried meat (used as teachers payment in ancient times) (11)"],
["簿","장부 부","부","register","account book","notebook (19)"],
["盒","소반 뚜껑 합","합","합 합","small box or case; casket (11)"],
["遐","멀 하","하","afar","distant; old","advanced in (13)"],
["匘","살찐 모양 뇌","뇌","the brain (11)"],
["嵓","바위 암","암","가파를 암(12)"],
["丮","잡을 극","극","catch (4)"],
["閾","문지방 역","역","threshold; separated","confined (16)"],
["遑","허둥거릴 황","황","leisure","leisurely; hurry about (13)"],
["脫","천천할 태","태","더딜 태","take off","peel off","strip (11)"],
["匙","숟가락 시","시","열쇠 시","spoon; surname (11)"],
["嵔","산이름 외","외","산 높을 외(12)"],
["警","경계할 경","경","guard","watch; alert","alarm (20)"],
["遒","다가설 주","주","strong","unyielding","forceful (13)"],
["所","바 소","소","곳 소","쯤 소","가질 소","얼마 소","place","location; numerary adjunct (8)"],
["匚","상자 방","방","box; KangXi radical 22 (2)"],
["丰","예쁠 봉","봉","abundant","lush","bountiful","plenty (4)"],
["盔","바리 회","회","helmet; bowl; basin (11)"],
["扁","특별할 편","편","거룻배 편","flat; tablet","signboard (9)"],
["匛","관 구","구","널 구(5)"],
["丱","쌍상투 관","관","child's hairstyle bound in two tufts; ore (5)"],
["道","길 도","도","path","road","street; method","way (13)"],
["枯","마를 고","고","dried out","withered","decayed (9)"],
["枰","장기판 평","평","바둑판 평","회양목 평","smooth board; chessboard; chess (9)"],
["匜","손대야 이","이","술잔 이","basin; container for wine (5)"],
["串","꿸 천","천","string; relatives; conspire (7)"],
["達","통달할 달","달","arrive at","reach; intelligent (13)"],
["脯","포 포","포","dried meat; preserved fruits (11)"],
["丳","꼬챙이 찬","찬","spit","grill; a skewer (8)"],
["匝","돌 잡","잡","full circle; encircle (5)"],
["違","어길 위","위","미적거릴 위","되돌아올 위","disobey","violate","defy; be apart from (13)"],
["扃","빗장 경","경","a door bar placed outside a door (9)"],
["脰","목 두","두","the neck (11)"],
["譫","헛소리 섬","섬","talkative; incoherent talk (20)"],
["枲","수삼 시","시","도꼬마리 시","male nettle-hemp (9)"],
["匟","평상 강","강","divan for two persons (6)"],
["嵚","산 솟을 금","금","lofty","towering (12)"],
["譬","비유할 비","비","metaphor","simile","example (20)"],
["枳","탱자나무 지","지","trifoliate orange; hedge thorn; (Cant.) a plug (9)"],
["丵","풀이 우거질 착","착","thick (grass) (10)"],
["匠","장인 장","장","craftsman","artisan; workman (6)"],
["沁","스며들 심","심","soak into","seep in","percolate (7)"],
["蘀","떨어질 탁","탁","마를 탁","falling leaves and barks (22)"],
["丶","심지 주","주","dot; KangXi radical 3 (1)"],
["沂","물 이름 기","기","river in Southeast Shandong (7)"],
["匡","바룰 광","광","correct","restore","revise (6)"],
["遘","만날 구","구","to meet; to come across (14)"],
["脳","머리골 뇌","뇌","brain (11)"],
["丷","구결 하","하","kwukyel (2)"],
["扇","사립문 선","선","fan; door panel (10)"],
["沃","기름질 옥","옥","부드러울 옥","손 씻을 옥","아리따울 옥","water","irrigate; fertile","rich (7)"],
["遙","멀 요","요","노닐 요","far away","distant","remote (14)"],
["譯","통변할 역","역","번역할 역","translate; decode; encode (20)"],
["盛","담을 성","성","이룰 성","정제할 성","많을 성","abundant","flourishing; contain; fill (12)"],
["架","시렁 가","가","rack","stand","prop; prop up (9)"],
["丸","알 환","환","small round object; pellet","pill (3)"],
["扈","뒤따를 호","호","escort","retinue; insolent (11)"],
["沄","물 콸콸 흐를 운","운","끓을 운","billows (7)"],
["匣","궤 갑","갑","상자 갑","small box","case","coffer (7)"],
["議","말할 의","의","의논할 의","꾀할 의","consult","talk over","discuss (20)"],
["盜","도둑 도","도","rob","steal; thief","bandit (12)"],
["枷","목칼 가","가","도리깨채 가","cangue scaffold (9)"],
["扉","문짝 비","비","door panel (12)"],
["沅","물 이름 원","원","name of a river in western Hunan that flows into Dongting lake (7)"],
["丹","붉을 단","단","cinnabar (native HgS); vermilion (artificial HgS used as pigment) (4)"],
["遛","머무를 류","류","take walk","stroll (14)"],
["沆","큰 물 항","항","진펀할 항","이슬 항","a ferry; fog; flowing (7)"],
["扊","문 빗장 염","염","upright bar for fastening door (12)"],
["匥","관갑 변","변","옷상자 변","대그릇 변(7)"],
["遜","순할 손","손","겸손할 손","도망할 손","사양할 손","humble","modest; yield (14)"],
["蘄","풀 이름 기","기","variety of artemisia seek (22)"],
["盞","작은 술잔 잔","잔","small cup or container; classifier for lamps","etc. (13)"],
["枸","구기자 구","구","kind of aspen found in Sichuan (9)"],
["主","주인 주","주","master","chief owner; host; lord (5)"],
["手","손 수","수","잡을 수","칠 수","hand (4)"],
["沇","물 졸졸 흐를 연","연","flowing and engulfing; brimming (7)"],
["遝","가다가설 답","답","mixed","abundant","assorted (14)"],
["盟","맹세할 맹","맹","swear; oath","covenant","alliance (13)"],
["遞","갈마들 체","체","hand over","deliver; substitute (14)"],
["脹","배부를 창","창","swell","inflate","expand (12)"],
["丼","우물 정","정","샘 정","bowl of food; well (5)"],
["沈","장마물 침","침","진펄 침","잠길 침","채색할 침","고요할 침","sink","submerge; addicted to; name (7)"],
["譴","꾸짖을 견","견","성낼 견","귀양갈 견","reprimand","scold","abuse (21)"],
["蘆","꼭두서니 려","려","rushes","reeds (22)"],
["匧","옷상자 협","협","꿰맬 협","a trunk; a portfolio (9)"],
["盡","다할 진","진","exhaust","use up; deplete (14)"],
["遟","더딜 지","지","late","tardy; slow; delay (14)"],
["才","재주 재","재","능할 재","현인 재","바탕 재","talent","ability; just","only (3)"],
["脺","얼굴 윤택할 수","수","머리덜미 수(12)"],
["蘇","차조기 소","소","나무할 소","까무러칠 소","깨어날 소","성씨 소","revive","resurrect; a species of thyme; transliteration of 'Soviet' (22)"],
["扎","뺄 찰","찰","pull up; pierce; struggle free (4)"],
["遠","멀 원","원","심오할 원","distant","remote","far; profound (14)"],
["護","보호할 호","호","감쌀 호","protect","guard","defend","shelter (21)"],
["丿","삐침 별","별","line; KangXi radical 4 (1)"],
["遡","거스를 소","소","맞이할 소","하소연할 소","go upstream; trace source; formerly (14)"],
["監","거느릴 감","감","살필 감","볼 감","벼슬 감","감옥 감","supervise","control","direct (14)"],
["匪","대상자 비","비","bandits","robbers","gangsters (10)"],
["蘊","마름 온","온","쌓일 온","collect","gather","store; profound; (Budd.) skandha (22)"],
["盤","소반 반","반","tray","plate","dish; examine (15)"],
["扑","칠 복","복","pound","beat","strike; attack (5)"],
["沌","기름 덩어리 돈","돈","chaotic","confused; turbid","murky (7)"],
["遣","보낼 견","견","쫓을 견","견전제 견","send","dispatch; send off","exile (14)"],
["蘋","네가래 빈","빈","apple (22)"],
["盥","대야 관","관","wash (16)"],
["扒","뺄 배","배","scratch; dig up; crawl; crouch (5)"],
["脾","지라 비","비","spleen","pancreas; disposition (12)"],
["打","때릴 타","타","strike","hit","beat; fight; attack (5)"],
["匭","갑 궤","궤","small box; chest","casket (11)"],
["嵩","높을 숭","숭","산이름 숭","high","lofty; one of the 5 peaks","situated in Hunan (13)"],
["盧","밥그릇 로","로","cottage","hut; surname; black (16)"],
["匯","물돌 회","회","a concourse; to flow together","gather together; (same as U+6ED9 滙) to remit money (13)"],
["沐","머리감을 목","목","bathe","cleanse","wash","shampoo (7)"],
["譽","칭찬할 예","예","이름날 예","즐길 예","fame","reputation; praise (21)"],
["盪","씻을 탕","탕","움직일 탕","진동할 탕","그릇 부실 탕","뭍 배질할 탕","to toss about; to swing; to rock (17)"],
["嵬","산 뾰족할 외","외","산 울뚝질뚝할 외","high; rugged","rocky","precipitous (13)"],
["遨","노닐 오","오","ramble","roam; travel for pleasure (15)"],
["適","갈 척","척","match","comfortable; just (15)"],
["沒","가라 앉을 몰","몰","not","have not","none; to drown","sink (7)"],
["匱","갑 궤","궤","to lack (14)"],
["托","밀칠 탁","탁","떡국 탁","차반 탁","to hold up with palm; to support","rely on (6)"],
["沓","거듭 답","답","connected","joined; repeated (8)"],
["盬","염지 고","고","salt pit; leisure; pot; drink (18)"],
["嵯","산 울뚝질뚝할 치","치","high","towering; irregular","rugged (13)"],
["沔","물 흐를 면","면","flood; overflowing (7)"],
["匳","경대 렴","렴","ladies toilet case with mirror (15)"],
["堀","굴뚝 굴","굴","cave","hole (11)"],
["沕","아득할 물","물","숨길 물","deep; (Cant.) to dive (7)"],
["遬","공손할 속","속","줄어들 속(15)"],
["目","눈 목","목","eye; look","see; division","topic (5)"],
["沖","깊을 충","충","온화할 충","어릴 충","pour","infuse","steep; wash away (7)"],
["遭","만날 조","조","come across","meet with","encounter (15)"],
["熀","불빛 이글이글할 녑","녑","the blaze of fire; dazzling (14)"],
["扛","마주 들 강","강","carry on shoulders; lift (6)"],
["匵","손궤 독","독","case; casket (17)"],
["堂","집 당","당","hall; government office (11)"],
["骨","뼈 골","골","bone; skeleton; frame","framework (10)"],
["遮","막을 차","차","cover","shield","protect (15)"],
["堃","따 곤","곤","compliance; obedience; female (11)"],
["沘","강 이름 비","비","name of a river (7)"],
["遯","달아날 둔","둔","deceive","hide","conceal; flee (15)"],
["蘖","그루터기 얼","얼","stump","sprout (23)"],
["匸","감출 혜","혜","덮을 혜","box; KangXi radical 23 (2)"],
["扞","막을 한","한","ward off","withstand","resist (6)"],
["沙","모래 사","사","sand","gravel","pebbles; granulated (7)"],
["堅","굳을 견","견","굳셀 견","반드시 견","hard","strong","firm; resolute (11)"],
["蘗","황경나무 벽","벽","stump","sprout (23)"],
["盲","소경 맹","맹","blind; unperceptive","shortsighted (8)"],
["堆","흙무더기 퇴 놓을 퇴","퇴","언덕 퇴","heap","pile","crowd; pile up (11)"],
["沚","물가 지","지","islet in stream; small sandbar (7)"],
["節","마디 절","절","절개 절","knot","node","joint; section (13)"],
["熄","불 꺼질 식","식","없어질 식","마칠 식","put out","extinguish","quash (14)"],
["匹","짝 필","필","둘 필","한마리 필","벗 필","무리 필","bolt of cloth; counter for horses (4)"],
["蘘","양하 양","양","a kind of wild ginger (23)"],
["篁","대숲 황","황","bamboo grove; bamboo (15)"],
["沛","둥둥 뜰 패","패","비 쏟아질 패","넉넉할 패","abundant","full","copious; sudden (7)"],
["遲","늦을 지","지","late","tardy; slow; delay (16)"],
["熅","서린 연기 온","온","따뜻할 온","숯불 온","smooth out","iron out (14)"],
["堇","노란진흙 근","근","yellow loam; clay; season; few (11)"],
["直","곧을 직","직","straight","erect","vertical (8)"],
["堈","언덕 강","강","밭 두둑 강","earthen jug","crock","cistern (11)"],
["蘚","이끼 선","선","moss","lichen (23)"],
["熇","불김 효","효","bake (14)"],
["骯","꼿꼿할 항","항","dirty (14)"],
["堉","기름진 땅 육","육","ground","fertile land (11)"],
["骰","주사위 투","투","die","dice (14)"],
["遵","좇을 준","준","obey","comply with","follow; honor (16)"],
["範","법 범","범","pattern","model","rule","law (15)"],
["匽","숨길 언","언","길 곁 언","한데 뒷간 언","to hide","to secrete","to repress; to bend (9)"],
["堊","흰 흙 악","악","white earth; to daub with white mud (11)"],
["扣","두드릴 구","구","knock","strike","rap","tap; button (6)"],
["遶","둘릴 요","요","entwine; wind around; surround (16)"],
["遷","옮길 천","천","move","shift","change; transfer (16)"],
["篆","문체이름 전","전","seal script; seal","official stamp (15)"],
["熊","곰 웅","웅","빛날 웅","a bear; brilliant; bright; surname (14)"],
["匿","숨쉴 닉","닉","hide; go into hiding (11)"],
["相","서로 상","상","mutual","reciprocal","each other (9)"],
["篇","책 편","편","편차할 편","chapter","section","essay","article (15)"],
["盹","눈 감을 순","순","졸 순","to doze; to nap; to nod (9)"],
["選","가릴 선","선","choose","select; elect; election (16)"],
["盻","흘겨볼 혜","혜","돌아볼 혜","glare; stare (9)"],
["築","다질 축","축","쌓을 축","build","erect; building (16)"],
["骶","궁둥이 제","제","coccyx (15)"],
["扨","그러하나 인","인","pick up with fork or pincers (6)"],
["遺","잃어버릴 유","유","남을 유","끼칠 유","lose; articles lost; omit (16)"],
["蘢","개여뀌 롱","롱","tall grass; water-weeds (22)"],
["盼","곁눈질 할 반","반","look","gaze; expect","hope for (9)"],
["熏","연기 낄 훈","훈","smoke","fog","vapor; smoke","cure (14)"],
["骷","해골 고","고","skeleton (15)"],
["篋","상자 협","협","행담 협","ratton box","suitcase","or case (15)"],
["骸","뼈 해","해","skeleton","body; leg bone (16)"],
["遼","멀 료","료","distant","far (16)"],
["盾","방패 순","순","벼슬이름 순","shield; dutch guilder; Indonesia (9)"],
["篌","공후 후","후","ancient music instrument; harp (15)"],
["遽","급할 거","거","두려울 거","역말 수레 거","suddenly","unexpectedly; at once (17)"],
["熒","등불 형","형","shine","shimmer; shining","dazzling (14)"],
["避","숨을 피","피","어길 피","싫어할 피","avoid; turn aside; escape; hide (17)"],
["堕","무너뜨릴 휴","휴","게으를 휴","fall","sink","let fall; degenerate (11)"],
["蘧","패랭이꽃 거","거","석죽화 거","마음에 든든할 거","a plant which resembles wheat but has no edible grain (23)"],
["扮","꾸밀 분","분","dress up; dress up as (7)"],
["骼","마른 뼈 격","격","드러난 뼈 격","짐승의 뼈 격","bone; skeleton; corpse (16)"],
["扯","찢어버릴 차","차","rip up","tear down; raise; haul (7)"],
["沫","거품 말","말","froth","foam","bubbles","suds (8)"],
["扱","거둘 삽","삽","들 삽","당길 삽","깊을 삽","collect","draw in","gather; receive (7)"],
["沬","지명 매","매","dusk; Mei river; Zhou dynasty place-name; (Cant.) to go underwater","to dive (8)"],
["堙","막을 인","인","bury; dam","block up (12)"],
["龍","잿빛 방","방","dragon; symbolic of emperor (16)"],
["熙","빛날 희","희","bright","splendid","glorious (14)"],
["沮","그칠 저","저","막을 저","무너질 저","공갈할 저","물이 번질 저","나라이름 저","stop","prevent; defeated","dejected (8)"],
["篔","왕대나무 운","운","tall bamboo (16)"],
["扳","끌 반","반","pull; drag; right itself (7)"],
["蘭","난초 란","란","orchid; elegant","graceful (23)"],
["熛","불똥 튈 표","표","성낼 표","붉을 표","빛날 표","blaze (15)"],
["沰","떨어뜨릴 탁","탁","돌 던질 탁","떨어질 탁","to let drop (8)"],
["龐","어지러울 방","방","disorderly","messy; huge","big (19)"],
["沱","물이 갈래질 타","타","큰 비 타","눈물이 흐를 타","rivers","streams","waterways; flow (8)"],
["扶","도울 부","부","support","help; protect; hold on (7)"],
["堝","도가니 과","과","crucible (12)"],
["堞","성가퀴 첩","첩","plate (12)"],
["河","물 하","하","river; stream; yellow river (8)"],
["胃","밥통 위","위","별 이름 위","성씨 위","stomach; gizzard of fowl (9)"],
["篙","상앗대 고","고","pole used punt boat (16)"],
["堠","봉화대 후","후","battlements","battlemented walls (12)"],
["胄","후사 주","주","helmet","headpiece; descendant (9)"],
["謀","꾀할 모","모","plan","scheme; strategem (16)"],
["批","칠 비","비","comment","criticize; wholesale (7)"],
["杆","지레 간","간","나무이름 간","pole; shaft of spear (7)"],
["龔","공손할 공","공","give","present; reverential (22)"],
["謁","아뢸 알","알","뵈올 알","visit","pay respects to (16)"],
["熟","익힐 숙","숙","삶을 숙","풍년들 숙","well-cooked; ripe; familiar with (15)"],
["篛","죽순 약","약","cuticle of bamboo plant (16)"],
["堡","작은 성 보","보","fort","fortress; town","village (12)"],
["胆","침비깨 단","단","gall bladder; bravery","courage (9)"],
["謂","고할 위","위","이를 위","say","tell; call","name; be called (16)"],
["熠","환할 습","습","선명할 습","반딧불 반짝거릴 습","bright and sparkling (15)"],
["龕","취할 감","감","이길 감","감실 감","niche","shrine (22)"],
["篝","불덮개 구","구","bamboo basket; bamboo frame (16)"],
["扼","움켜쥘 액","액","grasp","clutch; choke","strangle (7)"],
["杉","삼나무 삼","삼","various species of pine and fir (7)"],
["堤","둑 제","제","dike (12)"],
["沸","끓을 비","비","boil","bubble up","gush (8)"],
["找","채울 조","조","search","seek","look for; find (7)"],
["謄","베낄 등","등","copy","transcribe (17)"],
["油","기름 유","유","구름 피어오를 유","공손할 유","oil","fat","grease","lard; paints (8)"],
["胊","포(얇게 저미어서 말린 고기) 구","구","yoke (9)"],
["熤","빛날 익","익","사람이름 익(15)"],
["篠","가는 대 소","소","dwarf bamboo; diminutive in person's name (16)"],
["承","받들 승","승","이을 승","도울 승","차례 승","inherit","receive; succeed (8)"],
["堦","섬돌 계","계","stairs; a flight of steps; a degree","a class","a rank or step (12)"],
["治","다스릴 치","치","다듬을 치","익을 치","치료할 치","govern","regulate","administer (8)"],
["篡","빼앗을 찬","찬","usurp (16)"],
["堧","빈 땅 연","연","adjoin (12)"],
["背","버릴 패","패","배반할 패","back; back side; behind; betray (9)"],
["謇","말 더듬거릴 건","건","바른말할 건","stutter; speak out boldly (17)"],
["沼","못 소","소","늪 소","lake","fishpond","swamps (8)"],
["李","오얏 리","리","plum; judge; surname (7)"],
["杏","살구 행","행","은행 행","apricot; almond (7)"],
["沽","팔 고","고","buy and sell; inferior in quality (8)"],
["龜","(피부가)틀 균","균","turtle or tortoise; cuckold (16)"],
["胎","아이 밸 태","태","시작 태","unborn child","embryo","fetus (9)"],
["沾","더할 첨","첨","moisten","wet","soak; touch (8)"],
["材","재목 재","재","늘미 재","성품 재","재주 재","material","stuff; timber; talent (7)"],
["熨","다리미 위","위","고약 붙일 위","iron","press (15)"],
["篤","도타울 독","독","deep","TRUE","sincere","genuine (16)"],
["堪","견딜 감","감","이길 감","맡을 감","하늘 감","산 모양이 기괴할 감","adequately capable of","worthy of (12)"],
["蘼","장미 미","미","천궁 미(25)"],
["村","마을 촌","촌","밭집 촌","시골 촌","village","hamlet; uncouth","vulgar (7)"],
["篥","대 이름 률","률","bulgle (16)"],
["沿","물 따라 내려갈 연","연","좇을 연","follow course","go along (8)"],
["篦","빗치개 비","비","fine-toothed comb; comb hair (16)"],
["杓","북두자루 표","표","handle of cup","ladle","spoon; name of a constellation (7)"],
["龠","피리 약","약","flute; pipe","ancient measure (17)"],
["蘿","무 라","라","type of creeping plant; turnip (25)"],
["龡","불 취","취","to blow","exhale (21)"],
["熬","볶을 오","오","애 끓는 소리 오","cook down","to boil; endure (15)"],
["龢","풍류 조화될 화","화","in harmony; calm","peaceful (22)"],
["謎","수수께끼 미","미","riddle","conundrum; puzzle (17)"],
["堯","높을 요","요","요임금 요","a legendary ancient emperor-sage (12)"],
["勁","굳셀 경","경","strong","unyielding","tough","powerful (9)"],
["杖","지팡이 장","장","cane","walking stick (7)"],
["篩","체 사","사","sieve","filter","screen; sift (16)"],
["堰","방죽 언","언","dam; embankment","dike","bank (12)"],
["龤","노래의 가락이 조화될 해","해","to harmonize; to accord with; to agree (26)"],
["篪","저 이름 지","지","a bamboo flute with seven holes (16)"],
["謐","고요할 밀","밀","calm","quiet","still; cautious (17)"],
["熯","불 이글이글할 선","선","태울 선","공경할 선","by fire (15)"],
["報","나아갈 부","부","report","tell","announce (12)"],
["勃","갑작스러울 발","발","suddenly","sudden","quick (9)"],
["謑","창피줄 혜","혜","shame","disgrace (17)"],
["胖","희생 반","반","fat","obese; fatty (9)"],
["龥","부르짖을 유","유","beg","appeal for help (26)"],
["勅","신칙할 칙","칙","칙령 칙","imperial degree; daoist magic (9)"],
["熱","뜨거울 열","열","쏠릴 열","hot; heat; fever; restless; zeal (15)"],
["場","마당 장","장","open space","field","market (12)"],
["熲","빛날 경","경","bright (15)"],
["謔","희롱할 학","학","jeer (17)"],
["胙","복 조","조","meat offered in sacrifice to one's ancestors (9)"],
["皀","고소할 급","급","kernel","seed; enjoy","feast [?] (7)"],
["堵","담 도","도","wall; stop","prevent; stop up (12)"],
["皁","하인 조","조","black; police runners","from the black clothes formerly worn by them (7)"],
["胚","아이 밸 배","배","embryo; unfinished things (9)"],
["勇","날랠 용","용","용맹할 용","brave","courageous","fierce (9)"],
["退","물러갈 퇴","퇴","겸양할 퇴","물리칠 퇴","step back","retreat","withdraw (10)"],
["杜","막을 두","두","stop","prevent; restrict; surname (7)"],
["送","보낼 송","송","가질 송","활쏘기 능통할 송","줄 송","see off","send off; dispatch","give (10)"],
["胛","어깻죽지 갑","갑","the shoulder","shoulder blade (9)"],
["謗","헐뜯을 방","방","slander","libel (17)"],
["勉","힘쓸 면","면","endeavor","make effort; urge (9)"],
["的","과녁 적","적","꼭 그러할 적","밝을 적","possessive","adjectival suffix (8)"],
["适","빠를 괄","괄","go","reach; appropriate","suitable (10)"],
["杞","나무 이름 기","기","willow; medlar tree; a small feudal state (Qi) (7)"],
["崆","산이름 공","공","Kongtong mountain (11)"],
["篳","대 사립문 필","필","나무 실은 수레 필","wicker","bamboo (17)"],
["逃","달아날 도","도","escape","flee; abscond","dodge (10)"],
["謙","사양할 겸","겸","겸손할 겸","괘 이름 겸","humble","modest (17)"],
["束","묶을 속","속","단나무 속","약속할 속","비단 다섯끝 속","bind","control","restrain; bale (7)"],
["崇","높을 숭","숭","공경할 숭","마칠 숭","산이름 숭","esteem","honor","revere","venerate (11)"],
["熹","성할 희","희","dim light","glimmer; warm","bright (16)"],
["胞","태보 포","포","한 배 포","동포 포","womb","placenta","fetal membrane (9)"],
["謚","웃을 익","익","to confer posthumous titles (17)"],
["皆","모두 개","개","한 가지 개","같을 개","all","every","everybody (9)"],
["杠","외나무 다리 강","강","깃대 강","상 앞 가로막대 강","올릴 강","lever","pole","crowbar; sharpen (7)"],
["逅","만날 후","후","meet unexpectedly (10)"],
["驀","말탈 맥","맥","suddenly","quickly","abruptly (21)"],
["講","강론할 강","강","강구할 강","익힐 강","강화할 강","explain; discuss; talk (17)"],
["皇","임금 황","황","royal","imperial; ruler","superior (9)"],
["勍","셀 경","경","strong","powerful","mighty; violent (10)"],
["逆","거스를 역","역","disobey","rebel; rebel","traitor (10)"],
["驁","준마 오","오","깔볼 오","wild horse","mustang; wild (21)"],
["胠","열 거","거","갈비 거","open; throw away (9)"],
["謜","천천히 말할 원","원","기꺼울 원(17)"],
["胡","턱밑살 호","호","recklessly","foolishly; wildly (9)"],
["驂","곁마 참","참","two outside ones in three horse (21)"],
["謝","사례할 사","사","thank; decline (17)"],
["篷","뜸 봉","봉","awning","covering; sail; boat (17)"],
["逈","멀 형","형","빛날 형","distant","far; separated; different (10)"],
["驃","날쌜 표","표","누런 말 표","charger","steed; swift","valiant (21)"],
["熾","불 활활 붙을 치","치","burning-hot","intense; to burn","blaze; splendid","illustrious (16)"],
["驄","총이말 총","총","horse with a bluish white color (21)"],
["長","길 장","장","long; length; excel in; leader (8)"],
["皋","못 고","고","the high land along a river (10)"],
["勑","바를 칙","칙","다스릴 칙","reward; sincere (10)"],
["崍","산 이름 래","래","mountain in Sichuan province (11)"],
["驅","몰 구","구","spur a horse on; expel","drive away (21)"],
["胤","맏아들 윤","윤","익힐 윤","heir","successor; progeny","posterity (9)"],
["謠","노래 요","요","소문 요","sing; folksong","ballad; rumor (17)"],
["勒","굴레 륵","륵","strangle","tighten (11)"],
["崎","험할 기","기","rough","uneven","jagged","rugged (11)"],
["逋","달아날 포","포","포흠할 포","flee","run away","leave debt unsettled (11)"],
["胥","서로 서","서","all","together","mutually (9)"],
["逌","빙그레할 유","유","please; smiling; complacent (11)"],
["皎","달빛 교","교","white; bright","brilliant; clear (11)"],
["勔","권면할 민","민","힘쓸 민","endeavor","make effort; urge (11)"],
["逍","노닐 소","소","거닐 소","ramble","stroll","jaunt","loiter (11)"],
["動","움직일 동","동","move","happen; movement","action (11)"],
["崐","곤륜산 곤","곤","Kunlun mountains in Jiangsu (11)"],
["杪","끝 초","초","tip of twig","top of tree; twig (8)"],
["篾","대 껍질 멸","멸","bamboo splints or slats (17)"],
["皐","부를 호","호","the high land along a river (11)"],
["勖","힘쓸 욱","욱","enjoin","advise","preach to (11)"],
["崑","산이름 곤","곤","Kunlun mountains in Jiang Su province. (11)"],
["驊","준마 화","화","an excellent horse (22)"],
["透","통할 투","투","사무칠 투","지나칠 투","penetrate","pass through (11)"],
["逐","쫓을 축","축","물리칠 축","chase","expel; one by one (11)"],
["杬","몸 주무를 원","원","나무이름 원(8)"],
["勘","마감할 감","감","정할 감","investigate; compare; collate (11)"],
["杭","건널 항","항","거루 항","고을이름 항","cross stream; navigate (8)"],
["崔","산 우뚝할 최","최","높고 가파를 최","성씨 최","high","lofty","towering; surname (11)"],
["逑","짝 구","구","collect","unite; match","pair (11)"],
["驌","좋은말 숙","숙","숙상말 숙","name of a famous horse (22)"],
["謦","기침 경","경","t speak softly (18)"],
["務","일 무","무","힘쓸 무","권장할 무","affairs","business; must","should (11)"],
["驍","날랠 효","효","excellent horse; brave","valiant (22)"],
["皓","흴 호","호","깨끗할 호","bright","luminous; clear; hoary (12)"],
["杮","대패밥 폐","폐","wood shavings (8)"],
["戀","사모할 련","련","love; long for","yearn for; love (23)"],
["謨","꾀 모","모","scheme","plan; plan; be without (18)"],
["杯","잔 배","배","cup","glass (8)"],
["崖","언덕 의","의","cliff","precipice; precipitous (11)"],
["途","길 도","도","way","road","path","journey; course (11)"],
["驏","안장 얹지 않은 말 잔","잔","horse without saddle (22)"],
["皖","샛별 환","환","Anhui province (12)"],
["逕","소로 경","경","pass by","approach; direct (11)"],
["胯","사타구니 과","과","pelvis; groin; thighs (10)"],
["東","동녘 동","동","east","eastern","eastward (8)"],
["勝","이길 승","승","경치가 좋을 승","가질 승","victory; excel","be better than (12)"],
["逖","멀 적","적","far","distant; keep at distance (11)"],
["謫","귀양갈 적","적","꾸짖을 적","charge","blame; disgrace; demote; punish (18)"],
["杲","밝을 고","고","bright sun; brilliant; high (8)"],
["勞","일할 로","로","labor","toil","do manual work (12)"],
["崙","산 이름 륜","륜","Kunlun mountains in Jiangsu (11)"],
["驒","돈짝무늬총이말 탄","탄","dappled (22)"],
["汀","물가 정","정","수렁 정","sandbar","beach","bank","shore (5)"],
["胱","오줌통 광","광","bladder (10)"],
["募","모을 모","모","levy","raise; summon; recruit (13)"],
["汁","진액 집","집","진눈깨비 집","국 집","juice","liquor","fluid","sap","gravy","sauce (5)"],
["謭","얕을 전","전","shallow; stupid (18)"],
["逗","머무를 두","두","tempt","allure","arouse","stir (11)"],
["勠","협력할 륙","륙","join forces","unite (13)"],
["崛","산 높을 굴","굴","towering","eminent; rise abruptly (11)"],
["勡","겁탈할 표","표","겁박할 표(13)"],
["杵","공이 저","저","방망이 저","혜성 저","pestle; baton used beat clothes (8)"],
["皚","흴 애","애","brilliant white (15)"],
["求","찾을 구","구","seek; demand","request; beseech (6)"],
["這","맞을 저","저","이것 저","여기 저","this","the","here (11)"],
["驕","부리 짧은 사냥개 효","효","spirited horse; haughty (22)"],
["胴","큰 창자 동","동","the large intestine; the body (10)"],
["藁","마른 나무 고","고","straw","hay; dry","withered (20)"],
["戇","어리석을 당","당","stupid","simple","simple-minded (28)"],
["勢","권세 세","세","위엄 세","불알 세","기회 세","power","force; tendency (13)"],
["皜","흴 호","호","흰 모양 호","white","bright; clear","pure (15)"],
["通","뚫릴 통","통","사무칠 통","형통할 통","사귈 통","다닐 통","간음할 통","pass through","common","communicate (11)"],
["勣","공적 적","적","사업 적","achievements","conduct deserving (13)"],
["戈","창 과","과","halberd","spear","lance; rad. 62 (4)"],
["驗","시험할 험","험","중험할 험","보람 험","test","examine","inspect; verify (23)"],
["杷","비파나무 파","파","써레 파","칼자루 파","악기이름 파","loquat (8)"],
["勤","부지런할 근","근","industrious","diligent","attentive (13)"],
["崟","멧부리 음","음","산 높을 음","cliffs (11)"],
["皞","밝을 호","호","흴 호","bright","brilliant (15)"],
["驘","옹 솥 라","라","mule (23)"],
["戊","다섯째 천간 무","무","무성할 무","5th heavenly stem (5)"],
["胸","가슴 흉","흉","breast","bosom","chest; thorax (10)"],
["逝","갈 서","서","pass away; die (11)"],
["謳","읊조릴 구","구","to sing; songs (18)"],
["勦","수고롭게 할 초","초","destroy","exterminate","annihilate (13)"],
["崢","가파를 쟁","쟁","산 높을 쟁","high","lofty","noble; steep","perilous (11)"],
["逞","굳셀 령","령","indulge oneself; brag","show off (11)"],
["驚","놀랄 경","경","frighten","surprise","startle (23)"],
["戌","개 술","술","열한째 지지 술","때려 부술 술","11th terrestrial branch (6)"],
["速","빠를 속","속","부를 속","더러울 속","사슴 발자취 속","quick","prompt","speedy (11)"],
["驛","잇닿을 역","역","쌀 뾰족뾰족할 역","역말집 역","relay station (23)"],
["杻","수갑 추","추","조막손이 추","ligustrum sinenese","tree (8)"],
["戍","수자리 수","수","막을 수","집 수","defend borders","guard frontiers (6)"],
["崤","산 이름 효","효","mountain in Henan (11)"],
["造","지을 조","조","construct","build","make","begin; prepare (11)"],
["杼","북 저","저","도토리 저","shuttle of loom; narrow; long-headed; a scrub oak; thin (8)"],
["戎","병장기 융","융","싸움수레 융","클 융","너 융","도울 융","서쪽 오랑캐 융","arms","armaments; military affair (6)"],
["勨","느즈러질 양","양","힘쓸 양","움직일 양(14)"],
["逡","뒷걸음칠 준","준","retreat","withdraw","fall back (11)"],
["謷","중얼거릴 오","오","거만할 오","slander; to revile; huge; big (18)"],
["藉","어수선할 적","적","확자할 적","임금이 가는 밭 적","mat","pad; rely on; pretext (20)"],
["勩","수고로울 이","이","work hard; belabored; toil (14)"],
["皤","흴 파","파","배 불룩할 파","귀 밑에 희끗희끗할 파","white","grey; corpulent (17)"],
["逢","만날 봉","봉","come upon","happen meet; flatter (11)"],
["松","소나무 송","송","향풀 송","땅이름 송","pine tree; fir tree (8)"],
["成","이룰 성","성","거듭 성","마칠 성","화목할 성","completed","finished","fixed (7)"],
["汋","삶을 작","작","to pour (6)"],
["崦","해 지는 산 이름 엄","엄","a mountain in Kansu","where there is a cave into which the sun is said to sink at night (11)"],
["板","널조각 판","판","널기와 판","풍류이름 판","plank","board; iron or tin plate (8)"],
["驟","몰아갈 취","취","달릴 취","별안간 취","procedure; gallop; sudden(ly) (24)"],
["連","잇닿을 련","련","join","connect; continuous; even (11)"],
["能","능할 능","능","to be able; can","permitted to; ability (10)"],
["謹","삼갈 근","근","prudent","cautious; attentive (18)"],
["我","자기 아","아","our","us","i","me","my","we (7)"],
["崧","산 불끈 솟을 숭","숭","산 웅장할 숭","산이름 숭","high mountain; lofty","eminent (11)"],
["胾","고깃점 자","자","minced meat; to cut meat into pieces (12)"],
["戒","경계할 계","계","warn","caution","admonish (7)"],
["藍","쪽 람","람","blue; indigo plant; surname (20)"],
["汎","뜰 봉","봉","float","drift; afloat; careless (6)"],
["崩","무너질 붕","붕","rupture","split apart","collapse (11)"],
["藎","갈골 신","신","나아갈 신","남을 신","a kind of weed; faithfulness (20)"],
["戔","쌓일 잔","잔","small","narrow","tiny","little (8)"],
["勮","부지런히 힘쓸 거","거","두려울 거(15)"],
["戕","죽일 장","장","kill","slay; wound","injure","hurt (8)"],
["驢","나귀 려","려","donkey","ass (26)"],
["汐","조수 석","석","night tides","evening ebb tide (6)"],
["勯","힘다할 단","단","exhausted (15)"],
["或","혹 혹","혹","or","either","else; perhaps","maybe (8)"],
["勰","생각할 협","협","조화할 협","peaceful","harmonious (15)"],
["藏","감출 장","장","hide","conceal; hoard","store up (20)"],
["謾","속일 만","만","deceive","insult (18)"],
["驤","날뛰는 말 양","양","gallop about with head uplifted (27)"],
["藐","작을 묘","묘","disregard","slight","disdain (20)"],
["勱","힘쓸 매","매","put forth effort","strive forward (15)"],
["驥","천리마 기","기","thoroughbred horse; refined and (27)"],
["勳","공 훈","훈","meritorious deed; merits; rank (16)"],
["皮","가죽 피","피","껍질 피","성씨 피","skin","hide","fur","feather; outer (5)"],
["戚","겨레 척","척","relative; be related to; sad (11)"],
["汕","오구 산","산","basket for catching fish; bamboo; Swatow (Shantou) (6)"],
["勵","힘쓸 려","려","strive; encourage (17)"],
["驩","기뻐할 환","환","happy","pleased","glad; joy; to enjoy (28)"],
["汗","땀 한","한","perspiration","sweat (6)"],
["逮","미칠 태","태","쫓을 태","seize","catch; reach","arrive (12)"],
["勶","버릴 철","철","일으킬 철","사무칠 철(17)"],
["埃","티끌 애","애","속세 애","fine dust","dirt (10)"],
["藕","연뿌리 우","우","lotus root (21)"],
["驪","가라말 려","려","a pure black horse; a pair of horses (29)"],
["勷","급할 양","양","urgent; in haste","hurriedly; help (19)"],
["戞","창 알","알","lance; tap or strike lightly (12)"],
["勸","권할 권","권","recommend","advise","urge (19)"],
["勹","꾸릴 포","포","쌀 포","wrap; KangXi radical 20 (2)"],
["週","돌 주","주","week; turn","cycle; anniversary (12)"],
["埆","메마를 각","각","stony (10)"],
["戟","갈래진 창 극","극","halberd with crescent blade (12)"],
["汚","더러울 오","오","웅덩이 오","filthy","dirty","impure","polluted (6)"],
["崴","평평치 아니할 외","외","high","lofty; precipitous (12)"],
["進","나아갈 진","진","advance","make progress","enter (12)"],
["埇","골목길 용","용","길 돋울 용","name of bridge (10)"],
["汛","물뿌릴 신","신","high water","flood tides (6)"],
["勺","구기 작","작","spoon","ladle; unit of volume (3)"],
["煆","불사를 하","하","raging fire (13)"],
["皴","주름 준","준","chapped","cracked (12)"],
["埈","가파를 준","준","high","steep","towering; stern (10)"],
["戡","찌를 감","감","이길 감","죽일 감","subjugate","subdue","quell; kill (13)"],
["汜","지류 사","사","a stream which leaves the main branch then later returns (6)"],
["勻","가지런할 윤","윤","적을 윤","equal","impartially","uniform","even (4)"],
["煇","빛날 휘","휘","weld","solder (13)"],
["逴","멀 탁","탁","절름거릴 탁","뛰어날 탁","argue","quarrel","squabble; far (12)"],
["汝","물 이름 여","여","물에 젖을 여","you (6)"],
["汞","수은 홍","홍","element mercury (7)"],
["戢","그칠 집","집","to put away; to cease; store up (13)"],
["逵","구거리 규","규","thoroughfare","crossroads (12)"],
["藜","나라 이름 려","려","chenopodium album","pigweed (21)"],
["勽","덮을 문","문","to incubate; to brood; to hatch (4)"],
["埋","묻을 매","매","bury","secrete","conceal (10)"],
["逶","비틀거릴 위","위","winding","curving; swagger (12)"],
["藝","재주 예","예","글 예","가늠할 예","극진할 예","심을 예","art; talent","ability; craft (21)"],
["煉","불릴 련","련","smelt","refine; distill","condense (13)"],
["江","가람 강","강","물 강","large river; yangzi; surname (6)"],
["煊","따뜻할 훤","훤","warm (13)"],
["池","물이름 타","타","pool","pond; moat; cistern (6)"],
["皸","얼어터질 군","군","crack","chap (14)"],
["勿","말 물","물","아닐 물","깃발 물","must not","do not; without","never (4)"],
["煌","빛날 황","황","bright","shining","luminous (13)"],
["皺","쭈그러질 추","추","wrinkles","creases","folds (15)"],
["城","보루 성","성","재 성","서울 성","castle; city","town; municipality (9)"],
["逸","달아날 일","일","flee","escape","break loose (12)"],
["箇","낱 개","개","개수 개","numerary adjunct","piece; single (14)"],
["戧","다칠 창","창","support (14)"],
["崽","자식 재","재","저 재","a child","a servant; a diminutive (12)"],
["埏","땅 가장자리 연","연","a boundary","a limit (10)"],
["煎","달일 전","전","불에 말릴 전","fry in fat or oil; boil in water (13)"],
["齄","코가 붉게 부을 차","차","red sores on nose (23)"],
["逼","가까울 핍","핍","궁핍할 핍","핍박학 핍","compel","pressure","force; bother (13)"],
["截","끊을 절","절","말 잘할 절","cut off","stop","obstruct","intersect (14)"],
["箋","기록할 전","전","글 전","note","memo; stationery; comments (14)"],
["埒","바자울 랄","랄","enclosure","dike","embankment (10)"],
["藤","등나무 등","등","rattan","cane; creeper plant (21)"],
["藥","약 약","약","drugs","pharmaceuticals","medicine (21)"],
["皿","그릇 명","명","shallow container; rad. no. 108 (5)"],
["逾","갈 유","유","지날 유","go over","pass over","exceed (13)"],
["煒","벌그레할 위","위","환할 위","brilliant red; glowing (13)"],
["箍","테 고","고","hoop; bind","surround (14)"],
["汨","물이름 멱","멱","Mi(luo) river in Hunan province where Qu Yuan drowned himself; to sink; used (erroneously) for U+6C69 汩 (7)"],
["箎","긴 대 호","호","ancient woodwind instrument (14)"],
["汩","물 흐를 율","율","빨리 걸을 율","환할 율","북 울릴 율","걸을 율","run swiftly (7)"],
["箏","쟁 쟁","쟁","풍경 쟁","stringed musical instrument; kite (14)"],
["戮","죽일 륙","륙","kill","massacre; oppress (15)"],
["汪","물 출렁출렁할 왕","왕","연못 왕","vast","extensive","deep; surname (7)"],
["齊","가지런할 제","제","even","uniform","of equal length (14)"],
["藩","덮을 번","번","fence; boundary; outlying border (21)"],
["戰","싸움 전","전","경쟁할 전","war","fighting","battle (16)"],
["齋","재계할 재","재","집 재","vegetarian diet; study; to fast","abstain (17)"],
["藪","큰 늪 수","수","덤불 수","똬리 수","marsh","swamp; wild country; clump of trees or bushes (21)"],
["煖","따뜻할 난","난","warm","genial (13)"],
["箒","비 추","추","broom (14)"],
["戲","탄식할 희","희","theatrical play","show (17)"],
["汭","물 이름 예","예","물 굽이 예","confluence of two streams; bend (7)"],
["最","가장 최","최","우뚝할 최","넉넉할 최","most","extremely","exceedingly (12)"],
["戳","창으로 찌를 착","착","prick","stab; stamp","seal","chop (18)"],
["齎","탄식할 재","재","쌀 재","가질 재","take in both hands and offer to (21)"],
["煙","김 인","인","기운 인","안개 인","숯 그을음 인","smoke","soot; opium; tobacco","cigarettes (13)"],
["箔","발 박","박","reed screen; frame for growing silkworms (14)"],
["齏","회 제","제","break or smash into pieces","pulverize; hash (23)"],
["箕","키 기","기","sieve; dust pan","garbage bag (14)"],
["戴","받들 대","대","wear on top; support (18)"],
["汰","미끄러울 태","태","넘칠 태","씻길 태","사치할 태","excessive; scour","wash out (7)"],
["戵","창 구","구","a halberd (22)"],
["埜","들판 야","야","open country","field; wilderness (11)"],
["戶","지게 호","호","door; family","household (4)"],
["煜","비칠 읍","읍","불꽃 읍","성할 읍","불빛 비칠 읍","bright","shining","brilliant (13)"],
["算","셀 산","산","count","calculate","figure; plan (14)"],
["會","모일 회","회","to assemble","meet together; a meeting; an organization (13)"],
["齒","이 치","치","나이 치","같을 치","teeth; gears","cogs; age; KangXi radical 211 (15)"],
["汲","길을 급","급","draw water from well; imbibe (7)"],
["箙","전동 복","복","quiver (14)"],
["朅","갈 걸","걸","brave (14)"],
["域","경계 역","역","범위 역","district","region","boundary; land (11)"],
["箚","차자 차","차","brief note; official communique (14)"],
["肄","익힐 이","이","수고할 이","휘추리 이","곁가지 이","learn","practice","study; toil (13)"],
["煞","죽일 살","살","malignant deity; baleful","noxious; strike dead (13)"],
["戹","좁을 액","액","In difficulty","distressed (5)"],
["埠","선창 부","부","port city (11)"],
["齔","이 갈 친","친","어릴 친","lose baby teeth and get adult teeth (17)"],
["肅","공손할 숙","숙","공경할 숙","경계할 숙","pay respects; reverently (13)"],
["齕","깨물 흘","흘","gnaw","bite","nibble (18)"],
["諂","아첨할 첨","첨","flatter","truckle","toady (15)"],
["肆","방자할 사","사","indulge; excess; numeral four; particle meaning now","therefore; shop (13)"],
["箜","공후 공","공","ancient string music instrument (14)"],
["汶","내 이름 문","문","수치 문","a river in Shandong province (7)"],
["月","달 월","월","moon; month; KangXi radical 74 (4)"],
["肇","비롯할 조","조","begin","commence","originate (14)"],
["箝","재갈 겸","겸","족집개 겸","목 잠글 겸","tweezers","pliers","tongs","pincers (14)"],
["有","있을 유","유","얻을 유","과연 유","또 유","have","own","possess; exist (6)"],
["齗","잇몸 은","은","말다툼할 은","미워할 은","gums (of the teeth); to dispute (19)"],
["煢","외로울 경","경","alone; no friends or relatives (13)"],
["埤","더할 비","비","add","increase","attach; low fence (11)"],
["藷","마 저","저","감자 저","yam","tuber","potato (22)"],
["肉","고기 육","육","몸 육","flesh; meat; KangXi radical 130 (6)"],
["諄","거듭 일러줄 순","순","지극할 순","patient","earnest; earnestly (15)"],
["戾","어그러질 렬","렬","휘어질 렬","perverse","recalcitrant","rebellious (8)"],
["朋","벗 붕","붕","friend","pal","acquaintance (8)"],
["齙","이 드러날 포","포","projecting teeth (20)"],
["箠","채찍 추","추","볼기채 추","horse whip; flog (14)"],
["煤","그을음 매","매","coal","coke","charcoal","carbon (13)"],
["房","방 방","방","house","building; room (8)"],
["決","결단할 결","결","물이름 결","물골 터 놓을 결","끊을 결","이별할 결","decide","determine","judge (7)"],
["煥","불꽃 환","환","shining","brilliant","lustrous (13)"],
["藹","화기로울 애","애","lush; affable","friendly (22)"],
["肋","갈비 륵","륵","ribs; chest (6)"],
["管","피리 관","관","pipe","tube","duct; woodwind music (14)"],
["服","옷 복","복","clothes; wear","dress (8)"],
["煦","따뜻하게 할 후","후","kind","gentle","gracious","genial (13)"],
["藺","골풀 린","린","rush used in making mats; surname (22)"],
["肌","살 기","기","muscle tissue; meat on bones (6)"],
["談","말씀 담","담","talk; conversation; surname (15)"],
["藻","조류 조","조","splendid","magnificent; algae (22)"],
["照","비출 조","조","shine","illumine","reflect (13)"],
["齜","이를 갈 차","차","to show the teeth; crooked teeth (20)"],
["諉","핑계할 위","위","버릴 위","pass buck","lay blame on others (15)"],
["煨","불에 묻어 구울 외","외","그슬릴 외","사람이름 외","to stew","simmer (13)"],
["汾","클 분","분","river in Shanxi province (7)"],
["煩","괴로워할 번","번","bother","vex","trouble; troublesome (13)"],
["請","청할 청","청","ask","request; invite; please (15)"],
["齟","이 어긋날 저","저","irregular teeth; discord (20)"],
["埭","보뚝 태","태","a dam","a jock; inclined plane on a canal","where boats can be hauled up or down (11)"],
["齠","이를 갈 초","초","lose baby teeth and get adult teeth (20)"],
["藿","콩잎 곽","곽","lophanthus rugosus","betony (22)"],
["諍","간할 쟁","쟁","to expostulate; to remonstrate (15)"],
["齡","나이 령","령","age; years (20)"],
["煬","구울 양","양","roast; scorch; melt; flame (13)"],
["朔","초하루 삭","삭","first day of lunar month; the north (10)"],
["肓","명치 끝 황","황","region between heart and diaphragm (7)"],
["朕","나 짐","짐","pronoun 'I' (10)"],
["諏","꾀할 추","추","물을 추","consult","confer; select","choose (15)"],
["齣","단락 척","척","act; stanza; time","occasion (20)"],
["埰","영지 채","채","fief (11)"],
["煮","삶을 자","자","cook (12)"],
["劃","쪼갤 획","획","divide","mark off","lay boundary (14)"],
["箪","밥그릇 단","단","small bamboo basket for holding (14)"],
["諑","헐뜯을 착","착","slander; gossip; rumors (15)"],
["劄","차자 차","차","brief note; official communique (14)"],
["肖","닮을 초","초","look like","resemble","be like (7)"],
["朗","밝을 랑","랑","clear","bright; distinct (11)"],
["齦","물을 간","간","gums (21)"],
["諒","믿을 량","량","excuse","forgive; guess","presume (15)"],
["埳","구덩이 감","감","a pit","a hole","a snare","a danger; a crisis; a mortar (11)"],
["肘","팔꿈치 주","주","the elbow; help a person shoulder a load (7)"],
["箭","화살 전","전","약이름 전","arrow; type of bamboo (15)"],
["齧","물 설","설","bite","gnaw; wear down","erode (21)"],
["埴","찰진흙 치","치","흙 이길 치","soil with large clay content (11)"],
["劇","심할 극","극","theatrical plays","opera","drama (15)"],
["望","바랄 망","망","to look at","look forward; to hope","expect (11)"],
["齪","악착할 착","착","narrow","small; dirty (22)"],
["執","잡을 집","집","hold in hand; keep; carry out (11)"],
["劈","쪼갤 벽","벽","cut apart","split","chop (15)"],
["肚","밥통 두","두","belly; abdomen; bowels (7)"],
["論","차례 륜","륜","debate; discuss; discourse (15)"],
["療","병고칠 료","료","be healed","cured","recover (17)"],
["肛","똥구멍 항","항","배 뚱뚱할 항","anus (7)"],
["朝","아침 조","조","dynasty; morning (12)"],
["劉","죽일 류","류","surname; kill","destroy (15)"],
["諗","고할 심","심","생각할 심","꾀할 심","consult carefully with","counsel (15)"],
["箱","상자 상","상","case","box","chest","trunk (15)"],
["癃","느른할 륭","륭","weakness","infirmity retention of urine (17)"],
["劊","끊을 회","회","amputate","cut off (15)"],
["齬","이 어긋날 어","어","uneven teeth; to disagree (22)"],
["朞","돌 기","기","full year","anniversary (12)"],
["培","무덤 부","부","bank up with dirt; cultivate (11)"],
["肝","간 간","간","liver (7)"],
["期","기약할 기","기","period of time; date; time limit (12)"],
["箴","바늘 잠","잠","needle","probe; admon (15)"],
["劌","상할 귀","귀","to cut","injure","stab","stick on (15)"],
["癆","종독 로","로","consumption; tuberculosis (17)"],
["基","터 기","기","foundation","base (11)"],
["劍","칼 검","검","칼로 찔러 죽일 검","칼 쓰는법 검","sword","dagger","saber (15)"],
["諛","아첨할 유","유","flatter","truckle (16)"],
["癈","고질병 폐","폐","abrogate","terminate","discard (17)"],
["諜","염탐할 첩","첩","an intelligence report; to spy; spying (16)"],
["騁","달리다 빙","빙","gallop horse; hasten","hurry (17)"],
["峋","후미질 순","순","ranges of hills stretching on beyond another irregular peaks (9)"],
["騂","붉은 소 성","성","누른말 성","활 물쩡물쩡할 성","red","brown","bay; neat","harmonious (17)"],
["股","넓적다리 고","고","thighs","haunches","rump; share (8)"],
["諝","슬기 서","서","knowledge; discrimination; treachery (16)"],
["癉","황달 단","단","to hate bitterly; drought; dry (17)"],
["朣","달 뜰 동","동","the rising moon (16)"],
["埽","쓸 소","소","그릴 소","언덕 소","broom; to sweep","clear away (11)"],
["劐","끊을 확","확","destroy (16)"],
["諞","공교한 말 편","편","brag","boast; quibble (16)"],
["肢","사지 지","지","human limbs; animal feet (8)"],
["箸","젓가락 저","저","chopsticks (15)"],
["齲","충치 우","우","tooth decay (24)"],
["騃","어리석을 애","애","말달릴 애","stupid; foolish (17)"],
["煽","부칠 선","선","stir up","incite","agitate","provoke (14)"],
["劑","약 지을 제","제","medicinal preparation (16)"],
["騄","말이름 록","록","name of a legendary swift horse (18)"],
["諟","이 시","시","살필 시","다스릴 시","to examine; to consider; honest (16)"],
["諠","잊을 훤","훤","noisy","uproarious; forget (16)"],
["騅","청부루말 추","추","piebald horse (18)"],
["癌","종양 암","암","cancer; marmoset (17)"],
["朦","풍부할 몽","몽","condition or appearance of moon (18)"],
["劓","코 베일 의","의","베일 의","cut off nose (16)"],
["肥","살찔 비","비","fat","plump","obese; fertile (8)"],
["諡","행장 시","시","시호 시","posthumous name","posthumous title (16)"],
["朧","흐릿할 롱","롱","condition or appearance of moon (20)"],
["锺","술병 종","종","cup","glass","goblet; surname (14)"],
["肦","머리 클 분","분","large head; many; high; to divide","to distribute (8)"],
["諢","농담 원","원","jokes","obscene jests; joke (16)"],
["癎","경풍 간","간","epilepsy","convulsions (17)"],
["木","나무 목","목","tree; wood","lumber; wooden (4)"],
["齶","잇몸 악","악","palate","roof of mouth (24)"],
["騈","나란히 할 병","병","team of horses; associate (18)"],
["劖","새길 참","참","make into mince; cut (19)"],
["齷","악착할 악","악","narrow","small; dirty (24)"],
["箾","상소 소","소","musical instrument like pan-pipes","bamboo flute (15)"],
["未","아닐 미","미","not yet; 8th terrestrial branch (5)"],
["峒","산이름 동","동","mountain in Gansu province (9)"],
["锽","쇠북소리 굉","굉","weapon (14)"],
["肩","어깨 견","견","이길 견","맡길 견","멜 견","세 살 된 짐승 견","shoulders; to shoulder; bear (8)"],
["諤","직언할 악","악","honest speech","straightforward (16)"],
["末","끝 말","말","final","last","end; insignificant (5)"],
["劘","깍을 마","마","make into mince (21)"],
["騋","큰 말 래","래","tall (7 chi) horse (18)"],
["肪","기름 방","방","animal fat (8)"],
["本","밑 본","본","root","origin","source; basis (5)"],
["諦","살필 체","체","careful","attentive (16)"],
["札","패 찰","찰","letter","note; correspondence (5)"],
["劙","가를 리","리","a divide","to partition (23)"],
["肫","광대뼈 순","순","정성스러울 순","the gizzard of a fowl; honest","sincere (8)"],
["癒","병 나을 유","유","병들 유","get well","recover (18)"],
["諧","조화할 해","해","글 해","기롱지거리할 해","고를 해","harmonize","agree; joke","jest (16)"],
["朮","삽주뿌리 출","출","skill","art; method; trick","device (5)"],
["劚","깎을 촉","촉","cut (23)"],
["騍","암말 과","과","mother horse (18)"],
["力","힘 력","력","power","capability","influence (2)"],
["騎","말탈 기","기","ride horseback; mount; cavalry (18)"],
["肭","물개 눌","눌","fat; seal (moon radical DKW: 14342 is different) (8)"],
["騏","털총이 기","기","piebald horse; excellent horse (18)"],
["癕","등창 옹","옹","loss of the sense of smell (18)"],
["朱","붉을 주","주","cinnabar","vermilion; surname (6)"],
["肯","옳이 여길 긍","긍","willing; consent to","permit (8)"],
["癖","적취 벽","벽","craving","weakness for; indigestion (18)"],
["峙","산이 우뚝 솟을 치","치","갖출 치","쌓을 치","stand erect","stand up; pile up (9)"],
["懃","은근할 근","근","courteous","cordial; zealous (16)"],
["騑","곁마 비","비","horse; run (18)"],
["諫","간할 간","간","remonstrate","admonish (16)"],
["功","공 공","공","achievement","merit","good result (5)"],
["肱","팔뚝 굉","굉","forearm (8)"],
["癘","창질 려","려","a sore","ulcer; pestilence (18)"],
["加","더할 가","가","add to","increase","augment (5)"],
["育","기를 육","육","날 육","자랄 육","produce","give birth to; educate (8)"],
["諭","고할 유","유","비유할 유","proclaim","instruct; edict (16)"],
["朴","나무껍질 박","박","simple","unadorned; sincere; surname; a tree (6)"],
["懆","근심할 조","조","anxious","apprehensive (16)"],
["諮","물을 자","자","consult","confer; communicate in (16)"],
["薀","마름 온","온","쌓일 온","the hippuris or mare's tail plant (19)"],
["肴","안주 효","효","cooked or prepared meat (8)"],
["懇","정성 간","간","sincere","earnest","cordial (16)"],
["懈","게으를 해","해","idle","relaxed","remiss (16)"],
["朶","나무가지 휘늘어질 타","타","떨기 타","움킬 타","cluster of flowers; earlobe (6)"],
["癜","어루러기 전","전","erythema (18)"],
["劣","못할 렬","렬","bad","inferior; slightly (6)"],
["騖","달릴 무","무","질주할 무","gallop; rush about; pursue","run (19)"],
["肵","적대 기","기","table (8)"],
["諰","눈 휘둥글할 시","시","곧은말 시(16)"],
["諱","꺼릴 휘","휘","conceal; shun; regard as taboo (16)"],
["氅","새털 창","창","overcoat; down feathers (16)"],
["應","응당 응","응","꼭 응","사랑할 응","대답할 응","should","ought to","must (16)"],
["劤","힘많을 근","근","strong (6)"],
["懊","원망할 오","오","번뇌할 오","vexed","worried","nervous; regret (16)"],
["峠","고개 상","상","mountain pass; crisis","climax (9)"],
["薄","숲 박","박","thin","slight","weak; poor","stingy (19)"],
["騙","속일 편","편","말에 뛰어 오를 편","swindle","cheat out of","defraud (19)"],
["懋","힘쓸 무","무","노력할 무","splendid","grand","majestic (17)"],
["諳","욀 암","암","versed in","fully acquainted with (16)"],
["迀","구할 간","간","권할 간(7)"],
["諴","화할 함","함","in harmony; in agreement; incere (16)"],
["氈","단자리 전","전","전방석 전","felt; rug","carpet (17)"],
["懌","기꺼울 역","역","enjoy","be glad","be pleased (16)"],
["薆","숨겨질 애","애","to cover; to hide; to conceal (19)"],
["机","느티나무 궤","궤","desk; machine; moment (6)"],
["癡","어리석을 치","치","미련할 치","미치광이 치","주근깨 치","silly","foolish","idiotic (19)"],
["薇","고삐 미","미","Osmunda regalis","a species of fern (19)"],
["迂","굽을 우","우","피할 우","멀 우","doctrinaire","abstruse","unrealistic (7)"],
["肺","허파 폐","폐","부아 폐","친할 폐","마음속 폐","lungs (8)"],
["諶","참 심","심","sincere","faithful; surname (16)"],
["癢","가려울 양","양","itch (20)"],
["迄","이를 흘","흘","extend","reach; until; till (7)"],
["朽","섞을 후","후","decayed","rotten; rot","decay (6)"],
["諷","비유로 간할 풍","풍","외울 풍","recite","incant; satirize (16)"],
["助","도울 조","조","help","aid","assist (7)"],
["薉","더러울 예","예","dirty","unclean; immoral","obscene (19)"],
["諸","모든 제","제","several","various (16)"],
["癤","멍울 절","절","부스럼 절","pimple","sore","boil (20)"],
["努","힘쓸 노","노","to exert","strive","make an effort; to pout (7)"],
["薊","삽주 계","계","circium","thistles; surname (19)"],
["迅","빠를 신","신","억셀 신","quick","hasty","rapid","sudden (7)"],
["癥","적취 징","징","obstruction of bowels (20)"],
["劫","위협할 겁","겁","겁탈할 겁","부지런할 겁","대궐 층계 겁","겁 겁","화재 겁","take by force","coerce; disaster (7)"],
["薌","곡기 향","향","향기 향","기장 향","fragrant smell of grain; aromatic (19)"],
["劬","수고로울 구","구","be diligent","toil","endeavor (7)"],
["峨","산 높을 아","아","lofty (10)"],
["諺","상말 언","언","proverb","maxim (16)"],
["劭","힘쓸 초","초","encourage; to excel; excellent (7)"],
["癧","연주창 력","력","scrofulous lumps or swellings (21)"],
["諼","속일 훤","훤","forget; lie","cheat","deceive (16)"],
["氏","나라이름 지","지","clan","family; mister (4)"],
["癨","곽란 곽","곽","quickly","suddenly; surname (21)"],
["氐","낮을 저","저","오랑캐 이름 저","이를 저","근본 저","별이름 저","name of an ancient tribe (5)"],
["懕","편안할 염","염","satiated; contented (18)"],
["癩","약물 중독 라","라","leprosy","scabies","mange; shoddy (21)"],
["民","백성 민","민","people","subjects","citizens (5)"],
["諾","대답할 락","락","promise; assent","approve (16)"],
["薏","연밥 의","의","율무 의","seed of job's tears; lotus seed (19)"],
["迋","갈 왕","왕","두려울 왕","travel","go to; deceive; scare (8)"],
["峭","가파를 초","초","steep","precipitous","rugged (10)"],
["氓","백성 맹","맹","people; subjects; vassals (8)"],
["薑","생강 강","강","ginger (19)"],
["迍","머뭇거릴 둔","둔","falter","hesitate (8)"],
["癬","옴 선","선","ringworms (22)"],
["迎","맞을 영","영","receive","welcome","greet (8)"],
["癭","목 혹 영","영","swelling","goiter (22)"],
["峯","봉우리 봉","봉","peak","summit; hump of camel (10)"],
["癮","두드러기 은","은","rash; addiction","craving","habit (22)"],
["劵","게으를 권","권","고달플 권","수고로울 권","certificate","ticket; title deeds (8)"],
["垂","드리울 수","수","변방 수","거의 수","남길 수","let down; suspend","hand; down (8)"],
["薔","물여뀌 장","장","rose (19)"],
["癯","여윌 구","구","thin","emaciated; worn","tired (23)"],
["峱","산이름 노","노","name of a mountain (10)"],
["近","가까울 근","근","near","close; approach; intimate (8)"],
["癱","중풍증 탄","탄","사지 틀릴 탄","paralysis","palsy","numbness (24)"],
["騫","말 배 앓을 건","건","이지러질 건","휙 뛰어 나아갈 건","raise","hold high; fly","soar (20)"],
["迓","마중할 아","아","to go to meet","to receive","as a guest (8)"],
["焄","연기에 그을릴 훈","훈","rising flames or fumes; aroma (11)"],
["峴","고개 현","현","산이름 현","steep hill; mountain in Hubei (10)"],
["癲","미칠 전","전","crazy","mad; madness","mania","insanity (24)"],
["懟","원망할 대","대","hate","abhor; hatred","resentment (18)"],
["返","돌이킬 반","반","return","revert to","restore (8)"],
["薙","풀 깎을 치","치","weed; shave (19)"],
["騭","수말 즐","즐","stallion; promote (20)"],
["氛","기운 분","분","gas","vapor","air (8)"],
["迕","만날 오","오","어길 오","obstinate","perverse (8)"],
["劻","급할 광","광","flurried","be in flurry","zealous (8)"],
["島","섬 도","도","island (10)"],
["劼","삼갈 할","할","be discreet","prudent","cautious (8)"],
["薛","맑은대쑥 설","설","kind of marsh grass; feudal state (19)"],
["騰","오를 등","등","fly; gallop; run; prance; rise (20)"],
["薜","승검초 폐","폐","돌삼 폐","evergreen shrubs","ligusticum (19)"],
["型","거푸집 형","형","본보기 형","pattern","model","type; law; mold (9)"],
["劾","캐물을 핵","핵","핵실할 핵","examine into","impeach","charge (8)"],
["焉","어찌 언","언","의심쩍을 언","thereupon","then; how? why? where? (11)"],
["筅","솔 선","선","bamboo brush; halberd (12)"],
["懣","번거로울 문","문","be sick at heart","sorrowful","sad (18)"],
["癸","열째 천간 계","계","10th heavenly stem (9)"],
["筆","붓 필","필","오랑캐 이름 필","writing brush; write; stroke (12)"],
["峻","높을 준","준","high","steep","towering; stern (10)"],
["焌","굽을 준","준","to light (11)"],
["懦","만만할 유","유","나약할 유","weak","timid","cowardly (17)"],
["発","일어날 발","발","issue","dispatch","send out","emit (9)"],
["峽","물 낀 두메 협","협","산이름 협","gorge","strait","ravine; isthmus (10)"],
["筈","하눌타리 괄","괄","arrow end (12)"],
["氣","기운 기","기","air","gas","steam","vapor; spirit (10)"],
["騵","배 희고 갈기 검은 말 원","원","bay (20)"],
["登","오를 등","등","rise","mount","board","climb (12)"],
["騶","거덜 추","추","마부 추","짐승이름 추","mounted escort; groom (20)"],
["等","가지런할 등","등","rank","grade; wait; equal; 'etc.' (12)"],
["氤","기운성할 인","인","hanging fog","misty (10)"],
["發","쏠 발","발","issue","dispatch","send out","emit (12)"],
["鼄","거미 주","주","the spider (19)"],
["騷","근심스러울 소","소","흔들릴 소","급할 소","처량할 소","harass","bother","annoy","disturb","agitate; sad","grieved (20)"],
["白","흰 백","백","white; pure","unblemished; bright (5)"],
["薤","염교 해","해","부추 해","allium bakeri; shallots","scallion (19)"],
["筋","힘줄 근","근","muscles; tendons (12)"],
["騸","불깔 선","선","geld","castrate (20)"],
["百","일백 백","백","one hundred; numerous","many (6)"],
["垓","땅 가장자리 해","해","햇수 해","계단 해","border","boundary","frontier (9)"],
["薥","촉규화 촉","촉","자귀밥 촉(19)"],
["筌","통발 전","전","bamboo fish trap (12)"],
["薦","천거할 천","천","offer","present; recommend (19)"],
["筍","죽순 순","순","대싹 순","bamboo shoot; joint; tendon (12)"],
["鼇","매우 큰 자라 오","오","huge sea turtle (23)"],
["迢","멀 초","초","far; distant (9)"],
["鼈","자라 별","별","a fresh-water turtle","Trionyx sinensis (25)"],
["薨","죽을 훙","훙","death of prince; swarming (19)"],
["鼉","자라 타","타","large reptile","water lizard (25)"],
["筏","떼 벌","벌","raft (12)"],
["焕","빛날 환","환","shining (11)"],
["筐","광주리 광","광","bamboo basket or chest (12)"],
["薩","보살 살","살","transliteration of 'sat' in 'bodhisattva"," etc. (20)"],
["迤","어정거릴 타","타","wind; walk out of straight path (9)"],
["迥","막을 형","형","차단할 형","distant","far; separated; different (9)"],
["筑","비파 축","축","ancient lute; build (12)"],
["騾","노새 라","라","mule (21)"],
["薪","섶 신","신","땔나무 신","월급 신","성씨 신","fuel","firewood; salary (19)"],
["薫","향내 훈","훈","a medicinal herb; to cauterize (19)"],
["筒","사통대 통","통","통 통","thick piece of bamboo; pipe (12)"],
["迦","부처이름 가","가","character for transliteration (9)"],
["懲","혼날 징","징","punish","reprimand; warn (19)"],
["筓","비녀 계","계","a hairpin on which the hair is bound at the back of the head. 15 of age (12)"],
["迨","미칠 태","태","until","when; seize","arrest (9)"],
["鼎","솥 정","정","바야흐로 정","새로울 정","large","three-legged bronze caldron (13)"],
["焙","불에 쬘 배","배","dry over slow fire; bake; roast (12)"],
["答","젖을 답","답","answer","reply; return; assent to (12)"],
["曀","음산할 에","에","가리울 에","stormy; cloudy","misty; dim; (Cant.) sultry (16)"],
["焚","불사를 분","분","burn (12)"],
["曁","및 기","기","and; attain (16)"],
["策","채찍 책","책","scheme","plan; to whip; urge (12)"],
["迪","나아갈 적","적","순할 적","열어줄 적","enlighten","advance; progress (9)"],
["鼐","가마솥 내","내","incense tripod (15)"],
["焜","빛날 혼","혼","fire","flames; bright","shining (12)"],
["懶","게으를 란","란","lazy","languid","listless (19)"],
["薯","참마 서","서","yam","tuber","potato (20)"],
["迫","닥칠 박","박","coerce","force","compel; urgent (9)"],
["薰","향불 훈","훈","a medicinal herb; to cauterize (20)"],
["懷","품을 회","회","bosom","breast; carry in bosom (19)"],
["曄","빛날 엽","엽","번개 번쩍거릴 엽","bright; radiant; thriving (16)"],
["懸","매달 현","현","멀 현","hang","suspend","hoist; be hung (20)"],
["迭","갈마들 질","질","repeatedly","frequently (9)"],
["聃","노자이름 담","담","ears without rims; a personal name (11)"],
["氳","기운 어릴 온","온","기운 성할 온","life giving influences of nature; spirit of harmony; prosperity (14)"],
["焞","귀갑 지지는 불 돈","돈","dim (12)"],
["水","물 수","수","고를 수","water","liquid","lot5on","juice (4)"],
["曆","책력 력","력","calendar","era (16)"],
["垠","언덕 은","은","하늘 가장자리 은","경계 은","boundary","bank of stream or river (9)"],
["鼓","북 고","고","drum; beat","top","strike (13)"],
["懺","뉘우칠 참","참","regret","repent; confess sins (20)"],
["曇","날 흐릴 담","담","become cloudy","overcast (16)"],
["聆","들을 령","령","listen","hear (11)"],
["焠","물들일 쉬","쉬","칼날 물에 담글 쉬","지질 쉬","태울 쉬","temper; burn (12)"],
["垢","때 구","구","더러울 구","dirt","filth","stains; dirty (9)"],
["鼕","북소리 동","동","rattle of drums (18)"],
["述","이을 술","술","좇을 술","조회할 술","밝힐 술","narrate","state","express (9)"],
["曉","새벽 효","효","dawn","daybreak; clear","explicit (16)"],
["誂","꾈 조","조","tempt (13)"],
["無","없을 무","무","negative","no","not; lack","have no (12)"],
["懼","두려워할 구","구","fear","be afraid of","dread (21)"],
["氷","얼음 빙","빙","ice","frost","icicles; cold (5)"],
["垣","낮은 담 원","원","보호하는 사람 원","별 이름 원","low wall (9)"],
["懽","기뻐할 환","환","happy","pleased","glad","joyous (21)"],
["垤","개밋둑 질","질","ant-hill","small mound; hill (9)"],
["永","길 영","영","오랠 영","long","perpetual","eternal","forever (5)"],
["鼗","땡땡이 도","도","small revolving drum with knobs (19)"],
["誄","뇌사 뢰","뢰","eulogize","praise the dead (13)"],
["懾","무서울 섭","섭","두려울 섭","afraid","scared","fearful (21)"],
["誅","벨 주","주","execute","kill","put to death; punish (13)"],
["聊","귀 울 료","료","somewhat","slightly","at least (11)"],
["筠","대껍질 균","균","bamboo skin; bamboo (13)"],
["懿","아름다울 의","의","클 의","virtuous","admirable","esteemed (22)"],
["鼙","마상북 비","비","drum carried on horseback (21)"],
["誆","속일 광","광","cheat","swindle","lie (13)"],
["焦","그슬릴 초","초","burned","scorched; anxious","vexed (12)"],
["薺","냉이 제","제","water-chestnuts; caltrop (20)"],
["誇","자랑할 과","과","exaggerate; brag","boast; flaunt (13)"],
["氽","뜰 탄","탄","헤엄칠 탄","to float; to deep fry; (Cant.) to turn inside-out (6)"],
["氾","넘칠 범","범","overflow","flood","inundate (5)"],
["迷","미혹할 미","미","전념할 미","bewitch","charm","infatuate (10)"],
["筥","쌀 담는 광주리 거","거","볏단 거","round-shaped bamboo basket for (13)"],
["氿","물가 궤","궤","spring (5)"],
["迸","달아날 병","병","gush out; burst forth; split (12)"],
["迹","발자국 적","적","자취 적","뒤따를 적","traces","impressions","footprints (10)"],
["曓","급할 포","포","창졸 포(17)"],
["誌","기록할 지","지","write down; record; magazine (14)"],
["筦","피리 관","관","a key; to be in charge; a pipe (13)"],
["鼠","쥐 서","서","rat","mouse; KangXi radical 208 (13)"],
["迺","이에 내","내","then","thereupon","only then (10)"],
["聒","떠들석할 괄","괄","clamor","din","hubbub (12)"],
["認","알 인","인","recognize","know","understand (14)"],
["筧","대 홈통 견","견","bamboo water pipe (13)"],
["焭","근싱할 경","경","orphan; alone","desolate (12)"],
["鼢","두더지 분","분","a variety of mole (17)"],
["焮","불사를 흔","흔","heat","radiate heat; broil; cauterize (12)"],
["筩","대나무통 통","통","낚시 통","bamboo pipe; fish hook (13)"],
["追","옥 다듬을 퇴","퇴","쇠북 꼭지 퇴","pursue","chase after; expel (10)"],
["聕","들릴 호","호","긴 귀 호(13)"],
["剃","머리 깎을 체","체","shave (9)"],
["曖","희미할 애","애","obscure","dim","ambiguous","vague (17)"],
["聖","성인 성","성","착할 성","통할 성","지극할 성","holy","sacred; sage (13)"],
["誑","속일 광","광","deceive","lie","delude","cheat (14)"],
["誒","탄식할 희","희","an exclamation of confirmation (14)"],
["焱","불꽃 혁","혁","flames (12)"],
["筬","바디 성","성","reed of a loom (13)"],
["剄","목 벨 경","경","cut throat (9)"],
["聘","찾아가다 빙","빙","engage","employ; betroth (13)"],
["誓","맹세할 서","서","swear","pledge; oath (14)"],
["曙","새벽 서","서","bright","light of rising sun (18)"],
["筮","점대 서","서","divination with stalks of plants; divining rod (13)"],
["瘀","어혈질 어","어","a hematoma","contusion; extravasted blood (13)"],
["曚","어두울 몽","몽","twilight just before sun rises (17)"],
["曛","삭양 빛 훈","훈","twilight; sunset (18)"],
["誕","기를 탄","탄","클 탄","속일 탄","넓을 탄","탄생할 탄","방탕할 탄","to bear children","give birth; birth (14)"],
["則","법칙 칙","칙","본받을 칙","모범 칙","조목 칙","rule","law","regulation; grades (9)"],
["聚","모을 취","취","고을 취","걷을 취","쌓을 취","assemble","meet together","collect (14)"],
["曜","해 비칠 요","요","요일 요","glorious","as sun; daylight","sunlight (18)"],
["曝","햇볕에 말릴 폭","폭","sun","air in sun","expose or dry in the sun (19)"],
["剉","꺽을 좌","좌","cut","file","trim; file (9)"],
["然","불사를 연","연","그러할 연","허락할 연","그러나 연","yes","certainly; pledge","promise (12)"],
["筱","가는 대 소","소","dwarf bamboo; diminutive in person's name (13)"],
["鼫","석서 석","석","marmot; squirrel (18)"],
["誘","꾀일 유","유","가르칠 유","당길 유","persuade","entice","induce; guide (14)"],
["削","깎을 삭","삭","scrape off","pare","trim (9)"],
["鼬","청서 유","유","족제비 유","weasel","mustela itatis (18)"],
["筳","대쪽 정","정","feast (13)"],
["剋","깍일 극","극","subdue","overcome; cut down (9)"],
["誚","꾸짖을 초","초","criticize","scold","blame","ridicule (14)"],
["聞","들을 문","문","hear; smell; make known; news (14)"],
["曠","밝을 광","광","extensive","wide","broad; empty (19)"],
["剌","어그러질 랄","랄","slash","cut in two; contradict (9)"],
["瘇","수종 종","종","dropsy of the leg (14)"],
["前","앞 전","전","옛 전","인도할 전","in front","forward; preceding (9)"],
["筵","대자리 연","연","bamboo mat; feast","banquet (13)"],
["鼯","날다람쥐 오","오","flying squirrel (20)"],
["剎","절 찰","찰","temple (9)"],
["岊","산 모퉁이 우뚝 내밀 절","절","foothill (7)"],
["駁","얼룩말 박","박","varicolored","variegated; mixed; contradict","argue; suddenly (14)"],
["瘈","미칠 계","계","prolonged fever; chronic malaria (14)"],
["剏","비롯할 창","창","establish","create; knife cut (9)"],
["曣","더울 연","연","청명할 연(20)"],
["垽","해감 은","은","찌끼 은","(Cant.) sediment","precipitate (10)"],
["語","말씀 어","어","language","words; saying","expression (14)"],
["岌","높을 급","급","perilous","hazardous; high","steep (7)"],
["瘋","두풍 풍","풍","미치광이 풍","crazy","insane","mentally ill (14)"],
["曦","햇빛 희","희","sunlight","sunshine","early dawn (20)"],
["誠","미쁠 성","성","공경할 성","살필 성","진실 성","sincere","honest; true","real (14)"],
["誡","경계할 계","계","warn","admonish; warning (14)"],
["瘍","머리헐 양","양","종기 양","ulcers","sores; infection (14)"],
["鼴","두더쥐 언","언","소 같은 큰 쥐 언","a kind of insectivorous rodent (22)"],
["剔","바를 척","척","pick out; scrape off; scrape meat (10)"],
["曨","어스레할 롱","롱","vague","dim; twilight (20)"],
["曩","접때 낭","낭","in ancient times","of old","former (21)"],
["岐","갈림길 기","기","high; majestic; fork in road (7)"],
["鼷","새앙쥐 혜","혜","a mouse (23)"],
["駉","목장 경","경","big (15)"],
["誣","무고할 무","무","깔볼 무","make false accusation; defame (14)"],
["瘐","죄인 주려 죽을 유","유","to die in prison from cold and hunger; to treat with cruelty (14)"],
["剖","쪼갤 부","부","split in two","slice; dissect (10)"],
["岑","봉우리 잠","잠","steep","precipitous; peak (7)"],
["誤","그릇할 오","오","err","make mistake; interfere (14)"],
["剗","깍을 잔","잔","to level off; to trim; to pare down (10)"],
["誥","고할 고","고","inform","notify","admonish","order (14)"],
["剙","비롯할 창","창","establish","create; knife cut (10)"],
["岔","갈림길 차","차","diverge","branch off; fork in road (7)"],
["誦","풍유할 송","송","읽을 송","말할 송","원망할 송","recite","chant","repeat (14)"],
["誧","꾀할 포","포","클 포","도울 포","간할 포","huge; to admonish (14)"],
["剚","찌를 사","사","stab (10)"],
["鼻","코 비","비","nose; first; KangXi radical 209 (14)"],
["誨","가르칠 회","회","teach","instruct; encourage","urge (14)"],
["剛","굳을 강","강","굳셀 강","꼬장할 강","바야흐로 강","hard","tough","rigid","strong (10)"],
["瘕","이질 하","하","asthma; disese of the bowels (14)"],
["憁","실심할 총","총","뜻 같지 않을 총(15)"],
["駏","튀기 거","거","거공벌레 거","offspring of a stallion and a she-mule (15)"],
["聮","연할 련","련","connect","join; associate","ally (18)"],
["曰","가로되 왈","왈","say; KangXi radical 73 (4)"],
["剜","깎을 완","완","cut","cut out","pick out","scoop out (10)"],
["鼽","감기들어 코 막힐 구","구","clogged nose (16)"],
["鼾","코골 한","한","snore loudly (17)"],
["駐","머무를 주","주","to be stationed at","reside at; to stop (15)"],
["聯","잇달 련","련","connect","join; associate","ally (17)"],
["說","기꺼울 열","열","speak","say","talk; scold","upbraid (14)"],
["憂","근심 우","우","상제될 우","병 우","sad","grieved; grief","melancholy (15)"],
["剝","벗길 박","박","peel","peel off","to shell","strip (10)"],
["聰","귀 밝을 총","총","민첩할 총","intelligent","clever","bright (17)"],
["駑","둔할 노","노","tired","old horse; old","weak (15)"],
["曲","굽을 곡","곡","crooked","bent; wrong","false (6)"],
["憃","천치 용","용","foolish","stupid","dull","silly (15)"],
["剞","새김칼 기","기","carving or engraving knife; grave (10)"],
["毀","헐 훼","훼","destroy","ruin","injure; slander (13)"],
["剟","깎을 철","철","to prick; to cut blocks","to engrave (10)"],
["駒","망아지 구","구","colt; fleet","swift; sun; surname (15)"],
["聱","못 들은채 할 오","오","말 더듬거릴 오","bent and twisted; too complicated (17)"],
["曳","끌 예","예","당길 예","천천히 할 예","trail","tow","drag","pull (6)"],
["聲","소리 성","성","풍류 성","명예 성","sound","voice","noise; tone; music (17)"],
["瘙","옴 소","소","종기 소","itch (15)"],
["更","고칠 경","경","more","still further","much more (7)"],
["聳","솟을 용","용","귀먹을 용","공경할 용","urge on; rise up; stir","excite; to raise up; lofty (17)"],
["剡","땅이름 섬","섬","sharp","sharp-pointed; sharpen (10)"],
["聴","들을 청","청","hear","listen; understand; obey (18)"],
["駕","멍에 가","가","to drive","sail","fly; a cart","carriage (15)"],
["蕁","말 담","담","nettle (18)"],
["岝","산 높을 작","작","name of a mountain in Shandong (8)"],
["聵","배냇귀머거리 외","외","deaf (18)"],
["誰","누구 수","수","무엇 수","who? whom? whose? anyone? (15)"],
["毄","맞부딛는 소리 격","격","충돌할 격","창 격(14)"],
["聶","소곤거릴 섭","섭","낄 섭","성씨 섭","whisper; surname (18)"],
["蕃","우거질 번","번","foreign things (18)"],
["曷","어찌 갈","갈","그칠 갈","벌레이름 갈","why? what? where? (9)"],
["毅","굳셀 의","의","발끈 성낼 의","resolute","decisive","firm","persist (15)"],
["職","벼슬 직","직","duty","profession; office","post (18)"],
["課","매길 과","과","lesson; course; classwork (15)"],
["書","쓸 서","서","book","letter","document; writings (10)"],
["憊","고달플 비","비","tired","weary","fatigued (16)"],
["毆","때릴 구","구","beat","fight with fists","hit; to strike","brawl (15)"],
["曹","무리 조","조","ministry officials; surname (11)"],
["駙","곁마 부","부","extra horse; imperial son-in-law (15)"],
["瘟","염병 온","온","온역 온","epidemic","plague","pestilence (15)"],
["憋","악할 별","별","to suppress inner feelings; hasty (16)"],
["岡","멧동 강","강","산등성이 강","ridge or crest of hill (8)"],
["蕆","경계할 천","천","finish","complete; solve; complete (18)"],
["瘠","파리할 척","척","thin","emaciated; barren (15)"],
["瘡","부스럼 창","창","tumor","boil","sore","wound (15)"],
["駛","달릴 사","사","sail","drive","pilot; fast","quick (15)"],
["憍","교만할 교","교","be haughty","proud","arrogant (15)"],
["岣","산이름 구","구","a hill in Hunan (8)"],
["駜","살찐 말 필","필","strong horse (15)"],
["憎","미워할 증","증","hate","detest","abhor; hatred (15)"],
["誶","몰아 세울 줄","줄","꾸짖을 줄","speak ill of","vilify; berate; interrogate (15)"],
["瘢","흉터 반","반","scar","mole (15)"],
["曼","끌 만","만","long","extended","vast; beautiful (11)"],
["剨","자끈할 괵","괵","sound of something being smashed (11)"],
["駝","약대 타","타","곱사등이 타","a camel; humpbacked; to carry on the back (15)"],
["蕉","파초 초","초","banana; plantain (18)"],
["剩","남을 잉","잉","leftovers","residue","remains (12)"],
["聼","들을 청","청","hear","listen; understand; obey (20)"],
["曾","일찍 증","증","already; sign of past (12)"],
["剪","가위 전","전","scissors; cut","divide","separate (11)"],
["蕊","꽃술 예","예","꽃 예","unopened flowers","flower buds (18)"],
["瘤","혹 류","류","tumor","lump","goiter (15)"],
["憐","불쌍히 여길 련","련","pity","sympathize (15)"],
["毋","말 무","무","do not; not; surname; rad. 80 (4)"],
["剫","쪼갤 탁","탁","건목칠 탁(11)"],
["岧","산이 높을 초","초","peak; cliff; precipice (8)"],
["替","바꿀 체","체","change","replace","substitute for (12)"],
["聽","들을 청","청","hear","listen; understand; obey (22)"],
["誹","헐뜯을 비","비","slander","vilify","condemn (15)"],
["憑","기대다 빙","빙","lean on","depend on","rely on (16)"],
["岨","돌산에 흙 덮일 저","저","uneven (8)"],
["駟","사마 사","사","team of four horses (15)"],
["聾","귀머거리 롱","롱","deaf (22)"],
["瘦","파리할 수","수","가늘 수","thin","emaciated","lean; meager (15)"],
["憒","심란할 궤","궤","confused","troubled","muddle-headed (15)"],
["母","어미 모","모","mother; female elders; female (5)"],
["瘧","학질 학","학","intermittent fever; malaria (15)"],
["聿","마침내 율","율","지을 율","오직 율","스스로 율","붓 율","writing brush","pencil; thereupon (6)"],
["憔","수척할 초","초","be worn-out","emaciated","haggard (15)"],
["誼","옳을 의","의","다스릴 의","friendship; appropriate","suitable (15)"],
["每","매양 매","매","every","each (7)"],
["剮","바를 과","과","cut","cut flesh from bones (11)"],
["蕎","메밀 교","교","buckwheat (18)"],
["副","버금 부","부","assist; supplement; assistant (11)"],
["岫","바위구멍 수","수","mountain peak; cave","cavern (8)"],
["誾","화평할 은","은","speak gently; respectful (15)"],
["憖","물을 은","은","공근할 은","힘쓸 은","서러워할 은","cautious; willing; but","moreover (16)"],
["岬","산 허구리 갑","갑","줄이 느런할 갑","cape; promontory","headland (8)"],
["調","아침 주","주","transfer","move","change; tune (15)"],
["毒","독 독","독","poison","venom; poisonous (8)"],
["割","벨 할","할","cut","divide","partition; cede (12)"],
["毓","기를 육","육","어릴 육","많을 육","give birth to; bring up","educate (13)"],
["岭","산 이름 령","령","mountain ridge","mountain peak (8)"],
["憙","성할 희","희","like","love","enjoy; joyful thing (16)"],
["剳","낫 차","차","brief note; official communique (12)"],
["址","터 지","지","site","location","land for house (7)"],
["比","견줄 비","비","to compare","liken; comparison; than (4)"],
["憚","수고로울 탄","탄","꺼릴 탄","dread","shrink from","shirk","fear (15)"],
["蕓","운풀 운","운","평지 운","rue","an herb used keep insects away (18)"],
["剴","낫 개","개","가까울 개","간절할 개","sharpen; carefully","thorough (12)"],
["坂","언덕 판","판","산비탈 판","순나라 서울이름 판","hillside","inconvenient places (7)"],
["創","비롯할 창","창","establish","create; knife cut (12)"],
["瘯","피부병 이름 족","족","itch (16)"],
["毖","삼갈 비","비","guard against","take care; caution (9)"],
["蕕","냄새나는 풀 유","유","유풀 유","caryopteris divaricata (18)"],
["瘰","연주창 라","라","scrofula","swellings (16)"],
["毗","도울 비","비","help","assist; connect","adjoin (9)"],
["岱","태산 대","대","Daishan","one of the Five Sacred Mountains in China (8)"],
["駪","말 우물거릴 신","신","빨리 걸을 신","crowd (16)"],
["剷","깎을 산","산","to level off","cut off","pare down","raze (13)"],
["剸","오로지 전","전","저밀 전","cut (13)"],
["岳","큰 산 악","악","mountain peak; surname (8)"],
["毚","토끼 참","참","cunning; greedy; crafty (17)"],
["蕘","나무할 요","요","거칠 요","fuel","firewood; stubble (18)"],
["岵","산 호","호","hill (8)"],
["蕙","혜초 혜","혜","species of fragrant orchid (18)"],
["駭","놀랄 해","해","북 울릴 해","terrify","frighten","scare; shock (16)"],
["瘳","병 나을 추","추","덜릴 추","to be healed; to reform (16)"],
["毛","털 모","모","hair","fur","feathers; coarse (4)"],
["剺","벗길 리","리","mark (13)"],
["均","고를 균","균","equal","even","fair; all","also (7)"],
["瘴","장기 장","장","malaria pestilential vapors (16)"],
["駮","짐승이름 박","박","a kind of fierce animal; join; argue; transfer; piebald horse","variegated (16)"],
["岷","산이름 민","민","min mountain","min river (8)"],
["剽","표독할 표","표","긁을 표","겁박할 표","끝 표","찌를 표","빠를 표","rob","plunder; slice off; fast (13)"],
["烈","세찰 렬","렬","fiery","violent","vehement","ardent (10)"],
["岸","물가 언덕 안","안","bank","shore; beach","coast (8)"],
["坊","동네 방","방","neighborhood","urban subdivision (7)"],
["駱","낙타 락","락","a white horse with black mane; a camel (16)"],
["剿","죽일 초","초","destroy","exterminate","annihilate (13)"],
["蕞","작은 모양 체","체","little","small","tiny; petty (18)"],
["烊","구울 양","양","녹일 양","to smelt; to melt (10)"],
["憤","결낼 분","분","resent","hate; indignant (15)"],
["岺","재 령","령","mountain ridge","mountain peak (8)"],
["坌","먼지 분","분","dust","earth; a bank of earth; to dig; to bring together (7)"],
["烋","경사로울 휴","휴","boast (10)"],
["笆","가시 대 파","파","울타리 파","bamboo fence (10)"],
["坍","물이 언덕 찰 담","담","collapse; landslide (7)"],
["辛","매울 신","신","고생 신","여덟째 천간 신","bitter; toilsome","laborious; 8th heavenly stem (7)"],
["坎","구덩이 감","감","험할 감","작은 잔 감","괘 이름 감","불우하여 뜻을 이루지 못할 감","pit","hole; snare","trap; crisis (7)"],
["蕡","들깨 분","분","hemp seeds; plant with abundant (18)"],
["辜","허물 고","고","crime","criminal offense (12)"],
["瘻","부스럼 루","루","fistula","sore","ulcer","goiter (16)"],
["笈","책상자 급","급","bamboo box used carry books (10)"],
["憧","동경할 동","동","irresolute","indecisive; yearn for (15)"],
["坏","언덕 배","배","rotten","spoilt","bad","broken down (7)"],
["黃","누를 황","황","yellow; surname (12)"],
["坐","앉을 좌","좌","sit; seat; ride","travel by (7)"],
["岾","고개 점","점","mountain pass (Korean) (8)"],
["憨","어리석을 감","감","미련할 감","foolish","silly","coquettish (16)"],
["黄","누를 황","황","yellow; surname (12)"],
["笊","조리 조","조","ladle","bamboo skimmer (10)"],
["蕣","무궁화 순","순","hibiscus (18)"],
["烏","까마귀 오","오","검을 오","어찌 오","탄식할 오","crow","rook","raven; black","dark (10)"],
["憩","쉴 게","게","rest","take rest (16)"],
["坑","빠질 갱","갱","묻을 갱","구덩이 갱","pit","hole; bury","trap; harry (7)"],
["駸","말 몰아 달릴 침","침","galloping; speeding (17)"],
["蕤","더북할 유","유","drooping leaves; fringe soft","delicate (18)"],
["辟","피할 피","피","law","rule; open up","develop (13)"],
["笋","초목 성할 순","순","bamboo shoots (10)"],
["憫","불쌍히 여길 민","민","pity","sympathize with","grieve for (15)"],
["憬","깨달을 경","경","rouse","awaken; become conscious (15)"],
["駻","사나운 말 한","한","to rage","run wild (17)"],
["烓","화덕 계","계","stove (10)"],
["笏","홀 홀","홀","tablet held by someone having authority (10)"],
["蕨","고사리 궐","궐","pteris aquilina","common bracken (18)"],
["辣","매울 랄","랄","peppery","pungent","hot; cruel (14)"],
["憮","어루만질 무","무","애무할 무","regretful","disappointed (15)"],
["駽","돗총이 현","현","철총이 현","grey (17)"],
["蕩","넓고 클 탕","탕","없어질 탕","방탕할 탕","소탕할 탕","pond","pool; wash away","cleanse (18)"],
["憯","슬퍼할 참","참","sorrowful","sad; already","nevertheless (15)"],
["毫","가는 털 호","호","fine hair; measure of length (11)"],
["蕪","거칠어질 무","무","거친풀 무","luxurious growth of weeds (18)"],
["笑","웃음 소","소","smile","laugh","giggle; snicker (10)"],
["黌","글방 횡","횡","school (25)"],
["辦","힘들일 판","판","갖출 판","manage","do","handle; deal with (16)"],
["毬","제기 구","구","ball","anything round","sphere (11)"],
["烘","횃불 홍","홍","bake","roast; dry by fire (10)"],
["憲","법 헌","헌","표준될 헌","고시할 헌","기뻐할 헌","constitution","statute","law (15)"],
["駿","준마 준","준","excellent horse","noble steed (17)"],
["黍","기장 서","서","glutinous millet; KangXi radical number 202 (12)"],
["黎","검을 려","려","surname; numerous","many; black (15)"],
["辨","분별할 변","변","distinguish","discriminate (16)"],
["烙","지질 락","락","brand","burn; branding iron (10)"],
["黏","붙일 점","점","풀 점","stick to; glutinous","sticky; glue (17)"],
["蕭","쑥 소","소","쓸쓸할 소","말 우는 소리 소","바람소리 소","common artemisia; soughing of wind > mournful; dejected (18)"],
["毯","담자리 담","담","rug","carpet","blanket (12)"],
["黐","끈끈이 리","리","to stick; sticky (22)"],
["耀","빛날 요","요","shine","sparkle","dazzle; glory (20)"],
["黑","검을 흑","흑","black; dark; evil","sinister (12)"],
["老","늙은이 로","로","old","aged; experienced (6)"],
["憶","생각 억","억","remember","reflect upon; memory (16)"],
["烜","마를 훤","훤","light of the sun; to dry in the sun (10)"],
["暄","따뜻할 훤","훤","warm","comfortable; warm","genial (13)"],
["毳","솜털 취","취","연하고 부드러울 취","fine hair or fur on animals (12)"],
["烝","김 오를 증","증","rise","steam; many","numerous (10)"],
["辭","말 사","사","words","speech","expression","phrase (19)"],
["考","상고할 고","고","examine","test; investigate (6)"],
["笙","생황 생","생","small gourd-shaped musical instrument (11)"],
["憸","간사할 섬","섬","to flatter; crafty","having a specious tongue (16)"],
["耄","늙은이 모","모","elderly person; very old","senile (10)"],
["坡","언덕 파","파","제방 파","slope","bank","hillside (8)"],
["笛","피리 적","적","날라리 적","bamboo flute; whistle (11)"],
["黔","귀신 이름 금","금","black; Guizhou (16)"],
["辯","말잘할 변","변","dispute","argue","debate","discuss (21)"],
["者","놈 자","자","that which; he who; those who (10)"],
["憺","고요할 담","담","peace (16)"],
["暇","겨를 가","가","leisure","relaxation","spare time (13)"],
["辰","다섯째지지 진","진","early morning; 5th terrestrial branch (7)"],
["詁","주석 고","고","exegesis","explanation; explain (12)"],
["暈","무리 훈","훈","halo in sky; fog; dizzy","faint (13)"],
["暉","빛 휘","휘","sunshine; light","bright","radiant (13)"],
["辱","욕될 욕","욕","굽힐 욕","humiliate","insult","abuse (10)"],
["耇","명길 구","구","wrinkled face of the elderly (11)"],
["笞","볼기칠 태","태","bamboo rod used for beatings (11)"],
["農","농사 농","농","agriculture","farming; farmer (13)"],
["坤","땅 곤","곤","earth; feminine","female (8)"],
["默","묵묵할 묵","묵","고요할 묵","silent; quiet","still; dark (16)"],
["耉","늙을 구","구","old age (11)"],
["憾","섭섭할 감","감","한할 감","한혐할 감","to regret","remorse; dissatisfied (16)"],
["暋","굳셀 민","민","strong","robust","tough (13)"],
["坦","너그러울 탄","탄","평평할 탄","넓을 탄","flat","smooth; self-possessed (8)"],
["笠","우리 립","립","bamboo hat; bamboo covering (11)"],
["暌","해질 규","규","in opposition; distant from; separated (13)"],
["詆","꾸짖을 저","저","알소할 저","비방할 저","slander","condemn","reproach (12)"],
["黛","눈썹 그릴 대","대","새파랄 대","blacken eyebrows; black (17)"],
["而","순접의 어조사 이","이","너 이","말이을 이","이에 이","and; and then; and yet; but (6)"],
["暎","비칠 영","영","sun beginning decline; reflect (13)"],
["黜","내칠 출","출","물리칠 출","dismiss; demote","downgrade (17)"],
["坩","도가니 감","감","earthenware","earthenware vessel (8)"],
["坪","벌판 평","평","평평할 평","level ground; Japanese measure (8)"],
["黝","검푸를 유","유","검은칠할 유","black (17)"],
["暐","햇빛 위","위","빛 환할 위","the bright shining of the sun (13)"],
["點","더러울 점","점","뭉갤 점","검은 점 점","dot","speck","spot; point","degree (17)"],
["坫","병풍 점","점","막을 점","담 점","a stand on which to replace goblets after drinking (8)"],
["毿","털긴 삼","삼","long feathers; scraggy (15)"],
["暑","더울 서","서","hot (12)"],
["黟","검을 이","이","고을이름 이","black and shining; ebony (18)"],
["蕾","꽃봉오리 뢰","뢰","buds","unopened flowers (19)"],
["耐","견딜 내","내","endure","bear; resist; patient (9)"],
["笥","상자 사","사","a hamper","wicker basket (11)"],
["黠","약을 힐","힐","sly","cunning","shrewd; artful (18)"],
["耑","시초 단","단","specialized; concentrated (9)"],
["符","부신 부","부","i.d. tag","tally","symbol","charm (11)"],
["笧","책 상자 책","책","to entwine around; check (a current) with a weir (11)"],
["刀","칼 도","도","knife; old coin; measure (2)"],
["辻","네거리 십","십","crossroads; street (6)"],
["耒","쟁기 뢰","뢰","handle of plow; plow; rad. 127 (6)"],
["坮","들 대","대","platform; unit; term of address (8)"],
["刁","바라 조","조","tricky","sly","crafty","cunning (2)"],
["込","담을 입","입","crowd into","go into (6)"],
["詎","어찌 거","거","모를 거","interjection used to express surprise (12)"],
["笨","거칠 분","분","foolish","stupid","dull; awkward (11)"],
["耔","복돋을 자","자","to hoe up the earth around plants (9)"],
["坰","들 경","경","wilds (8)"],
["耕","밭갈 경","경","plow","cultivate (10)"],
["詐","속일 사","사","cheat","defraud","swindle; trick; to feign (12)"],
["暖","부드러울 훤","훤","warm","genial (13)"],
["坱","티끌 앙","앙","dust (8)"],
["辿","천천히 걸을 천","천","follow","pursue (7)"],
["刃","칼날 인","인","edged tool","cutlery","knife edge (3)"],
["黥","묵형할 경","경","tattooing face (20)"],
["詑","자랑할 이","이","cheat (12)"],
["暗","어두울 암","암","몰래 암","어리석을 암","dark; obscure; in secret","covert (13)"],
["第","집 제","제","sequence","number; grade","degree (11)"],
["局","부분 국","국","bureau","office; circumstance (7)"],
["耗","줄 모","모","consume","use up; waste","squander (10)"],
["詒","속일 태","태","bequeath","pass on to future generations (12)"],
["烱","빛날 경","경","bright","brilliant","clear; hot (11)"],
["暘","해 돋을 양","양","rising sun; sunshine (13)"],
["刅","해칠 창","창","to create","to make to invent; to begin (4)"],
["屁","방귀 비","비","break wind; fart; buttocks (7)"],
["黧","검을 리","리","a dark","sallow colour (20)"],
["笭","도꼬마리 령","령","bamboo screen (11)"],
["詔","고할 조","조","decree","proclaim; imperial decree (12)"],
["分","세는 단위 푼","푼","divide; small unit of time etc. (4)"],
["黨","무리 당","당","political party","gang","faction (20)"],
["耘","길 맬 운","운","weed (10)"],
["痀","곱사등이 구","구","to crouch; a hunchback (10)"],
["評","평론할 평","평","헤아릴 평","고칠 평","기롱할 평","appraise","criticize","evaluate (12)"],
["耙","쇠시랑 파","파","rake (10)"],
["切","온통 체","체","cut","mince","slice","carve (4)"],
["笯","새장 노","노","a bird-cage (11)"],
["詖","말 잘할 피","피","간사할 피","알소할 피","argue; biased; one-sided (12)"],
["刈","풀 벨 예","예","죽일 예","cut off","reap","mow; sickle (4)"],
["痂","헌데 딱지 가","가","scab (10)"],
["坷","험할 가","가","clod of earth","lump of soil (8)"],
["詗","염탐할 형","형","구할 형","to spy; to give information; shrewd (12)"],
["痃","힘줄 당기는 병 현","현","적병 현","indigestion; buboes","lymphatic inflammation (10)"],
["居","어조사 기","기","live","dwell","reside","sit (8)"],
["暝","어두울 명","명","dark","obscure (14)"],
["刊","책 펴낼 간","간","publication","periodical; publish (5)"],
["屆","이를 계","계","numerary adjunct for time","term (8)"],
["詘","굽을 굴","굴","bend","stoop","crouch; to yield (12)"],
["刋","끊을 천","천","publication","periodical; publish (5)"],
["屇","구멍 전","전","cave; hole (8)"],
["笳","갈잎피리 가","가","a reed leaf whistle (11)"],
["病","병 병","병","illness","sickness","disease (10)"],
["烹","삶을 팽","팽","요리 팽","boil","cook; quick fry","stir fry (11)"],
["屈","굽을 굴","굴","bend","flex; bent","crooked; crouch (8)"],
["黮","검을 담","담","black","dark; unclear; private (21)"],
["耞","도리깨 가","가","flail (11)"],
["暠","흴 호","호","daybreak; bright and brilliant (14)"],
["坻","모래섬 지","지","an islet","a rock in a river; an embankment; to stop (8)"],
["症","증세 증","증","disease","illness","ailment (10)"],
["黯","어두울 암","암","dark","black; sullen","dreary (21)"],
["笵","법 범","범","a bamboo form; a model (11)"],
["坼","찢을 탁","탁","터질 탁","난산할 탁","싹 트일 탁","split","tear","open (8)"],
["暢","펼 창","창","smoothly","freely","unrestrained (14)"],
["刎","목 벨 문","문","자를 문","끊을 문","behead","cut throat (6)"],
["屋","집 옥","옥","지붕 옥","거북 덮개 옥","house; room; building","shelter (9)"],
["耡","구실이름 서","서","hoe (13)"],
["痊","병 나을 전","전","be healed","be cured; recover (11)"],
["詞","말씀 사","사","words; phrase","expression (12)"],
["刑","형벌 형","형","죽일 형","punishment","penalty; law (6)"],
["黲","검푸르죽죽할 참","참","grey black (23)"],
["烽","봉화 봉","봉","signal fire; tower where signal (11)"],
["笹","가는 대 세","세","small bamboo (11)"],
["划","삿대 화","화","to row or paddle boat; to scratch (6)"],
["屍","주검 시","시","송장 시","corpse","carcass (9)"],
["詠","읊을 영","영","sing","hum","chant (12)"],
["刓","깎을 완","완","trim (6)"],
["屎","똥 시","시","excrement","dung (9)"],
["黴","곰팡이 미","미","검을 미","썪을 미","mold","mildew; bacteria","fungi (23)"],
["痍","상할 이","이","다칠 이","흠집 이","wound","bruise","sore (11)"],
["詡","자랑할 후","후","boast","brag; popular","well known; to flatter (13)"],
["痎","학질 해","해","하루거리 해(11)"],
["耦","쟁기 우","우","겨리 우","짝 맞을 우","plow side by side; team of two (15)"],
["屐","나막신 극","극","wooden shoes","clogs (10)"],
["黶","검은 사마귀 염","염","mole","scar","blemish (26)"],
["詢","꾀할 순","순","물을 순","믿을 순","inquire into","ask about; consult (13)"],
["刖","발꿈치 자를 월","월","cutting off feet as form of punishment (6)"],
["屑","가루 설","설","bits","scraps","crumbs","fragments (10)"],
["黷","더러울 독","독","to dishonor","defile","corrupt; soiled (27)"],
["耨","김맬 누","누","hoe","rake; weed (16)"],
["詣","이를 예","예","나아갈 예","학문 통달할 예","reach; achievement","accomplishment (13)"],
["暫","잠시 잠","잠","temporary (15)"],
["列","줄 렬","렬","a line; to arrange in order","classify (6)"],
["試","시험할 시","시","더듬을 시","비교할 시","test","try","experiment (13)"],
["痒","가려울 양","양","옴 양","itch (11)"],
["痓","풍병 치","치","악할 치(11)"],
["展","펼칠 전","전","열릴 전","살필 전","open","unfold; stretch","extend (10)"],
["黻","수 불","불","special pattern of embroidery (17)"],
["耬","씨 뿌리는 기구 루","루","drill for sowing grain (17)"],
["暮","저물 모","모","evening","dusk","sunset; ending (15)"],
["痔","치질 치","치","hemorrhoids","piles (11)"],
["黼","수 보","보","embroidered official or sacrificial robe (19)"],
["耭","갈 기","기","plow","cultivate (18)"],
["痕","흉터 흔","흔","scar; mark; trace (11)"],
["刜","칠 불","불","chop (7)"],
["黽","힘쓸 민","민","to strive; to endeavor (13)"],
["詩","귀글 시","시","풍류가락 시","받들 시","poetry; poem","verse","ode (13)"],
["初","처음 초","초","beginning","initial","primary (7)"],
["慂","권할 용","용","거들어 말할 용","urge","incite","egg-on (14)"],
["暱","칠근할 닐","닐","intimate","close; approach (15)"],
["詫","자랑할 타","타","풍칠 타","신통할 타","to brag; exaggerated; to wonder at (13)"],
["黿","큰 자라 원","원","large turtle","sea turtle (17)"],
["耰","고무래 우","우","곰방메 우","toothless rake for drawing earth over newly sown grain (21)"],
["慄","두려워할 률","률","shiver","shudder","tremble; tremble (13)"],
["殀","단명할 요","요","die young","die prematurely (8)"],
["詬","꾸짖을 구","구","abuse","scold","berate","insult (13)"],
["痘","마마 두","두","smallpox (12)"],
["屛","병풍 병","병","folding screen (11)"],
["詭","속일 궤","궤","deceive","cheat","defraud; sly (13)"],
["痙","심줄 땅길 경","경","convulsions","fits (12)"],
["暴","햇빛 쪼일 폭","폭","나타날 폭","드러날 폭","violent","brutal","tyrannical (15)"],
["殂","죽을 조","조","to die (9)"],
["詮","평론할 전","전","갖출 전","explain","expound","comment on (13)"],
["慆","기뻐할 도","도","excessive","dissolute; delighted (13)"],
["耳","귀 이","이","조자리 이","말 그칠 이","여덟대 손자 이","ear; merely","only; handle (6)"],
["蔀","빈지문 부","부","screen (17)"],
["暵","말릴 한","한","dry by exposing sun (15)"],
["痛","아플 통","통","상할 통","심할 통","몹시 통","pain","ache; sorry","sad; bitter (12)"],
["慇","은근할 은","은","공손할 은","간절할 은","careful","anxious","attentive (14)"],
["殃","재앙 앙","앙","misfortune","disaster","calamity (9)"],
["詰","물을 힐","힐","question","interrogate (13)"],
["殄","끊어질 진","진","to end; to exterminate (9)"],
["慈","사랑할 자","자","kind","charitable","benevolent (14)"],
["首","머리 수","수","먼저 수","비롯할 수","임금 수","향할 수","자백할 수","head; first; leader","chief; a poem (9)"],
["話","말할 화","화","speech","talk","language; dialect (13)"],
["馗","아홉거리 규","규","cheekbone; path","road; intersection (11)"],
["耶","의문의 어조야 야","야","아비 야","used in transliteration (9)"],
["該","갖출 해","해","모두 해","겸할 해","마땅할 해","should","ought to","need to (13)"],
["判","판단할 판","판","쪼갤 판","맡을 판","judge; discriminate; conclude (7)"],
["殆","위태할 태","태","가까이할 태","비롯할 태","dangerous","perilous; endanger (9)"],
["慊","족할 협","협","to resent; contended; satisfied (13)"],
["屠","흉노왕의 이름 저","저","butcher","slaughter","massacre (12)"],
["馘","벨 괵","괵","cut off left ear; tally dead enemy (17)"],
["痞","뱃속 결릴 비","비","dyspepsia","spleen infection (12)"],
["暸","밝을 료","료","bright","clear (16)"],
["香","향내 향","향","약이름 향","fragrant","sweet smelling","incense (9)"],
["態","모양 태","태","뜻 태","manner","bearing","attitude (14)"],
["別","나눌 별","별","separate","other; do not (7)"],
["詳","거짓 양","양","detailed","complete","thorough (13)"],
["轀","온량차 온","온","hearse (17)"],
["暹","해돋을 섬","섬","rise; advance","go forward (16)"],
["慌","어렴풋할 황","황","nervous","panicky","frantic (13)"],
["刦","겁탈할 겁","겁","구속할 겁","take by force","coerce; disaster (7)"],
["屢","창 루","루","frequently","often","again and again (14)"],
["蔆","마름 릉","릉","water-chestnut","water caltrop (17)"],
["慍","성낼 온","온","병 온","angry","indignant","resentful (13)"],
["詵","많을 선","선","inquire","question; numerous (13)"],
["轂","바퀴 곡","곡","hub of wheel (17)"],
["暻","밝을 경","경","bright (16)"],
["刨","깎을 포","포","carpenter's plane; plane","level (7)"],
["層","겹 층","층","거듭 층","층층대 층","storey","layer","floor","stratum (15)"],
["殉","죽은 사람을 따라 죽을 순","순","구할 순","좇을 순","die for cause","be martyr for (10)"],
["痢","설사 리","리","dysentery (12)"],
["轄","다스릴 할","할","linchpin of wheel; control (17)"],
["痣","사마귀 지","지","spots","moles; birthmark (12)"],
["殊","죽을 수","수","베일 수","끊어질 수","지나갈 수","different","special","unusual (10)"],
["利","날카로울 리","리","gains","advantage","profit","merit (7)"],
["履","신 리","리","footwear","shoes; walk on","tread (15)"],
["馝","향내날 필","필","fragrance (14)"],
["痤","뾰루지 좌","좌","a swelling of the lymph nodes (12)"],
["残","해칠 잔","잔","injure","spoil; oppress; broken (10)"],
["刪","깎을 산","산","to cut; delete","erase; to geld (7)"],
["轅","진문 원","원","멍에 원","별 이름 원","axle; magistrate's office; surname (17)"],
["暾","아침해 돈","돈","morning sun","sunrise (16)"],
["耽","즐길 탐","탐","귀 축 늘어질 탐","웅크리고 볼 탐","즐거울 탐","깊고 멀 탐","indulge in; be negligent (10)"],
["詹","이를 첨","첨","surname; talk too much","verbose (13)"],
["轆","도르래 록","록","windlass","pulley","capstan; wheel (18)"],
["殍","굶어 죽을 표","표","to starve to death (11)"],
["屨","삼으로 짠 신 구","구","straw sandals; tread on (17)"],
["耿","빛날 경","경","bright","shining; have guts (10)"],
["痧","쥐통 사","사","cholera; colic (12)"],
["詼","조롱할 회","회","tease","joke with; ridicule","mock (13)"],
["轉","구를 전","전","변할 전","굴릴 전","shift","move","turn (18)"],
["刮","깍을 괄","괄","shave","pare off","scrape (8)"],
["慕","그리워할 모","모","long for","desire; admire (15)"],
["殑","까무라칠 긍","긍","swoon (11)"],
["到","이를 도","도","go to","arrive","been to (8)"],
["刱","비롯할 창","창","establish","create; knife cut (8)"],
["屬","이을 촉","촉","닿을 촉","부탁할 촉","모을 촉","조심할 촉","class","category","type; to belong to (21)"],
["詿","그르칠 괘","괘","error","mistake; deceive","mislead (13)"],
["轍","바퀴 자국 철","철","wagon ruts","wheel tracks (19)"],
["刲","찌를 규","규","cut","slice off (8)"],
["馥","향기 복","복","fragrance","scent","aroma (18)"],
["蔑","업신여길 멸","멸","disdain","disregard; slight (17)"],
["慙","부끄러울 참","참","ashamed","humiliated; shameful (15)"],
["刳","가를 고","고","cut out","dig","rip up","scoop out (8)"],
["轎","가마 교","교","sedan-chair","palanquin (19)"],
["刴","찍을 타","타","꺾을 타","chop by pounding","mince","hash (8)"],
["慚","부끄러울 참","참","ashamed","humiliated; shameful (14)"],
["屯","어려울 준","준","village","hamlet; camp; station (4)"],
["蔓","덩굴 만","만","creeping plants","tendrils","vines (17)"],
["圂","뒷간 혼","혼","pig-sty; privy (10)"],
["馨","향기 형","형","fragrant","aromatic; distant fragrance (20)"],
["殖","날 식","식","심을 식","성할 식","breed","spawn; increase; prosper (12)"],
["刵","귀 베일 이","이","punishment by cutting off ears (8)"],
["蔔","무우 복","복","radish (17)"],
["圃","채마밭 포","포","밭 갈 포","곳 포","산이름 포","garden","cultivated field (10)"],
["蔕","작은 가시 체","체","peduncle or stem of plants (17)"],
["制","억제할 제","제","system; establish; overpower (8)"],
["炁","기운 기","기","breath","air","steam","gas. weather. used in Taoist charms (8)"],
["山","뫼 산","산","mountain","hill","peak (3)"],
["慝","간악할 특","특","간사할 특","더러울 특","do evil in secret; evil","vice (15)"],
["殘","해칠 잔","잔","injure","spoil; oppress; broken (12)"],
["刷","긁을 쇄","쇄","고칠 쇄","빗을 쇄","문지를 쇄","brush; clean with brush","scrub (8)"],
["圄","가둘 어","어","prison","jail (10)"],
["轒","병거 분","분","chariot (19)"],
["痰","가래 담","담","phlegm","mucus","spittle (13)"],
["圅","담을 함","함","correspondence; a case; a box (10)"],
["券","엄쪽(어음을 쪼갠 한 쪽) 권","권","certificate","ticket; title deeds (8)"],
["蔗","사탕수수 자","자","sugar cane (17)"],
["刹","절 찰","찰","temple","shrine","monastary (8)"],
["馬","말 마","마","horse; surname; KangXi radical 187 (10)"],
["蔘","인삼 삼","삼","ginseng; huge (17)"],
["轔","수레 소리 린","린","rumbling of vehicles; threshold (19)"],
["痲","저릴 마","마","pock-marked; leprosy; measles (13)"],
["慟","서러울 통","통","sadness","grief; mourn; be moved (14)"],
["炅","빛날 경","경","brilliance (8)"],
["刺","찌를 척","척","stab; prick","irritate; prod (8)"],
["圇","완전할 륜","륜","all","complete","entire (11)"],
["馭","말 부릴 어","어","drive","ride; manage","control (12)"],
["痳","임질 림","림","pock-marked; leprosy; measles (13)"],
["馮","벼슬이름 풍","풍","성씨 풍","물귀신이름 풍","고을이름 풍","surname; gallop; by dint of (12)"],
["炆","따뜻할 문","문","(Cant.) to simmer","cook over a slow fire (8)"],
["刻","새길 각","각","carve","engrave; quarter hour (8)"],
["圈","둥글 권","권","to circle; a circle; corral (11)"],
["蔚","제비쑥 위","위","초목 우거진 모양 위","잔무늬 위","luxuriant","thick; ornamental (17)"],
["圉","마부 어","어","변방 어","stable","corral","enclosure; frontier","border (11)"],
["圊","뒷간 청","청","rest room (11)"],
["馰","별배기 적","적","소태성 적(13)"],
["慢","게으를 만","만","slow(ly)","leisurely","sluggish (14)"],
["殞","죽을 운","운","떨어질 운","die","perish; vanish; fall (14)"],
["屹","산 우뚝솟을 흘","흘","to rise high; to stand erect (6)"],
["轘","환형 환","환","tear asunder between chariots (20)"],
["馱","탈 타","타","짐 실을 타","carry on back (12)"],
["竄","숨을 찬","찬","run away; revise","edit; expel (18)"],
["慣","버릇 관","관","habit","custom; habitually","usually (14)"],
["國","나라 국","국","nation","country","nation-state (11)"],
["炊","불 지필 취","취","밥 지을 취","cook; meal (8)"],
["圌","둥근 대그릇 천","천","fence (12)"],
["鑄","쇠 부어 만들 주","주","melt","cast; coin","mint (22)"],
["竅","구멍 규","규","hole","opening","aperture (18)"],
["慤","삼갈 각","각","sincerity","honesty; modest (15)"],
["屺","민둥산 기","기","a hill with trees or grass-- some give the opposite meaning (6)"],
["蔞","쑥 루","루","Artemisia stelleriana (17)"],
["馳","달릴 치","치","거동길 치","전할 치","go quickly or swiftly; hurry (13)"],
["蔟","태주 주","주","frame on which silkworms spin (17)"],
["圍","둘레 위","위","에워 쌀 위","한아람 위","지킬 위","surround","encircle","corral (12)"],
["麀","암사슴 우","우","female deer; doe (13)"],
["痹","저릴 비","비","paralysis","numbness (13)"],
["殢","나른할 체","체","be in great difficulty","greatly (15)"],
["竇","구멍 두","두","surname; hole","burrow; corrupt (20)"],
["馴","가르칠 훈","훈","tame","docile","obedient (13)"],
["痺","암메추라기 비","비","paralysis","numbness (13)"],
["慧","슬기로울 혜","혜","총명할 혜","bright","intelligent; intelligence (15)"],
["竈","부엌 조","조","furnace; kitchen range (21)"],
["麂","큰고라니 궤","궤","species of deer (13)"],
["蔡","거북 채","채","surname; species of tortoise (17)"],
["炎","불꽃 염","염","임금의 호 염","flame","blaze; hot (8)"],
["慨","분할 개","개","강개할 개","슬플 개","sigh","regret; generous (12)"],
["殤","일찍죽을 상","상","die young; national mourning (15)"],
["痼","고질 고","고","chronic disease (13)"],
["轞","함거 함","함","a vehicle for transporting prisoners (21)"],
["竊","좀도둑 절","절","얕을 절","secretly","stealthily; steal; thief (22)"],
["蔣","줄 장","장","surname; hydropyrum latifalium (17)"],
["立","설 립","립","stand; let stand; establish","set (5)"],
["園","동산 원","원","능 원","울타리 원","garden; park","orchard (13)"],
["轟","울릴 굉","굉","rumble","explosion","blast (21)"],
["鑊","가마 확","확","cauldron","large iron pot; a wok (22)"],
["蔥","파 총","총","파 뿌리 총","푸를 총","scallions","onions","leeks (17)"],
["慫","권할 종","종","to alarm; to instigate","arouse","incite (15)"],
["圓","둥글 원","원","원만할 원","화폐의 단위 원","circle; round","circular; complete (13)"],
["馹","역말 일","일","post horse","relay horse (14)"],
["痿","습병 위","위","잘름거릴 위","음위증 위","paralysis; impotence (13)"],
["炒","볶을 초","초","fry","saute","roast","boil","cook (8)"],
["蔦","담쟁이 조","조","the convovulvus; parasitic plants such as mistletoe; Ribes ambiguum (17)"],
["麇","노루 균","균","general name for the hornless deer; to collect to band together (16)"],
["轡","고삐 비","비","bridle of horse","reins (22)"],
["麈","큰사슴 주","주","species of deer (16)"],
["鑌","강철 빈","빈","high quality iron (22)"],
["轢","삐걱거릴 력","력","run over something with vehicle (22)"],
["慮","생각할 려","려","be concerned","worry about (15)"],
["圖","그림 도","도","diagram; chart","map","picture (14)"],
["炕","마를 항","항","구울 항","구들 항","the brick-bed in northern China (8)"],
["轤","고패 로","로","pulley","windlass","capstan (23)"],
["殫","다할 탄","탄","utmost","entirely","quite; use up (16)"],
["蔪","풀 벨 점","점","보리 팰 점(17)"],
["慰","위로할 위","위","comfort","console","calm (15)"],
["圗","그림 도","도","diagram; chart","map","picture (14)"],
["麋","큰사슴 미","미","부서질 미","elk; surname (17)"],
["慱","근심할 단","단","sad (14)"],
["團","둥글 단","단","sphere","ball","circle; mass","lump (14)"],
["麌","숫사슴 우","우","stag; herd (18)"],
["炘","화끈거릴 흔","흔","brilliant","shining","bright (8)"],
["殭","죽어 썩지 않을 강","강","마른 누에 강","a stiff corpse (17)"],
["鑑","거울 감","감","밝을 감","비칠 감","경계할 감","mirror","looking glass; reflect (22)"],
["炙","고기구이 적","적","김 쏘일 적","냄새 퍼질 적","roast","broil; toast; cauterize (8)"],
["慳","아낄 간","간","miserly","parsimonious","stingy (14)"],
["殮","염할 렴","렴","dress corpse for burial (17)"],
["蔬","나물 소","소","vegetables","greens (17)"],
["慴","두려울 접","접","겁낼 접","fear; terrified","fearful; fear (14)"],
["殯","염할 빈","빈","encoffin; embalm; funeral (18)"],
["圛","맴돌 역","역","used in place names; stratus (16)"],
["蔭","덮을 음","음","가릴 음","그늘 음","shade","shelter; protect (17)"],
["慵","게으를 용","용","indolent","easy-going","lazy (14)"],
["時","때 시","시","끼니 시","이 시","엿볼 시","가끔 시","time","season; era","age","period (10)"],
["圜","두를 환","환","circle","surround; encircle (16)"],
["晃","밝을 황","황","bright","dazzling; to sway","shake (10)"],
["鑕","모루 질","질","tungsten","wolfram (23)"],
["蔯","더워지기 진","진","a variety of artemisia (17)"],
["慶","경사 경","경","congratulate","celebrate (14)"],
["圝","둥글 란","란","entire (22)"],
["慷","강개할 강","강","ardent; generous","magnanimous (14)"],
["殲","다죽일 섬","섬","annihilate","wipe out","kill off (21)"],
["麑","아기사슴 예","예","사자 예","fawn","young deer (19)"],
["站","우두커니 설 참","참","stand up; a stand","station (10)"],
["土","흙 토","토","뿌리 토","나라 토","뭍 토","soil","earth; items made of earth (3)"],
["麒","기린 기","기","legendary auspicious animal (19)"],
["麓","신기슭 록","록","foot of hill; foothill (19)"],
["言","심사 화평할 은","은","words","speech; speak","say (7)"],
["段","층계 단","단","section","piece","division (9)"],
["慼","근심할 척","척","sorrowful","mournful; sorrow (15)"],
["訂","바로잡을 정","정","의논할 정","draw up agreement; arrange (9)"],
["炡","데칠 정","정","불 번쩍거릴 정(9)"],
["竝","아우를 병","병","to combine","annex; also","what's more (10)"],
["殷","많을 은","은","무리 은","클 은","가운데 은","은나라 은","융성할 은","천둥소리 은","many","great; abundant","flourishing (10)"],
["鑛","쇳돌 광","광","mine; mineral","ore (23)"],
["慽","근심할 척","척","sorrowful","mournful; sorrow (14)"],
["訃","부고 부","부","obituary; give notice of death (9)"],
["麗","고울 리","리","beautiful","magnificent","elegant (19)"],
["竟","다할 경","경","finally","after all","at last (11)"],
["慾","욕심낼 욕","욕","하고자할 욕","lust","desire","passion; appetite (15)"],
["圥","버섯 록","록","mushroom (5)"],
["章","문채 장","장","composition; chapter","section (11)"],
["晌","정오 상","상","noon","midday; moment; unit land measurement equal to seven mou (U+755D 畝)","or the area that can be sown in one day (10)"],
["鑞","땜납 랍","랍","solder; tin (23)"],
["在","있을 재","재","살 재","살필 재","곳 재","be at","in","on; consist in","rest (6)"],
["訇","큰 소리 굉","굉","the sound of a crash (9)"],
["竢","기다릴 사","사","wait for","wait until","as soon as (12)"],
["殼","껍질 각","각","casing","shell","husk","hull","skin (12)"],
["鑠","녹일 삭","삭","melt","smelt; shine (23)"],
["殽","섞일 효","효","mixed up","confused; cooked (12)"],
["竣","마칠 준","준","terminate","end","finish; quit (12)"],
["計","꾀 계","계","plan","plot; strategem; scheme (9)"],
["晏","늦을 안","안","편안할 안","peaceful","quiet; clear; late in the day (10)"],
["鑢","줄 려","려","file","rasp; file; polish; (Cant.) to cut (23)"],
["麝","사향노루 사","사","musk deer (21)"],
["鑣","말 재갈 표","표","성할 표","bit","bridle; ride (23)"],
["蔽","가리울 폐","폐","다할 폐","정할 폐","cover","hide","conceal; shelter (18)"],
["殿","대궐 전","전","hall; palace; temple (13)"],
["麞","노루 장","장","roebuck","Moschus chinloo (22)"],
["訊","물을 신","신","다스릴 신","죄 조사할 신","알릴 신","꾸짖을 신","inquire; ask; examine; reproach (10)"],
["童","아이 동","동","child","boy","servant boy; virgin (12)"],
["圬","흙손 오","오","plaster over with layer of mud (6)"],
["麟","기린 린","린","female of Chinese unicorn (23)"],
["訌","무너질 홍","홍","confusion; internal strife (10)"],
["炫","밝을 현","현","불빛 현","shine","glitter; show off","flaunt (9)"],
["竦","공경할 송","송","두려울 송","높일 송","솟을 송","revere","respect","be in awe of (12)"],
["圭","서옥 규","규","jade pointed at top (6)"],
["蔿","풀 위","위","성씨 위","고을이름 위","surname; place name (18)"],
["炬","횃불 거","거","불 땔 거","torch (9)"],
["圮","무너질 비","비","destroyed","ruined; to subvert to injure (6)"],
["討","다스릴 토","토","칠 토","찾을 토","벨 토","꾸짖을 토","to discuss; ask for","beg; demand; dun; marry (10)"],
["炭","숯 탄","탄","불똥 탄","볶일 탄","charcoal; coal; carbon (9)"],
["凁","싸늘할 수","수","얻을 수(9)"],
["炮","그슬릴 포","포","싸서 구울 포","형벌 포","large gun","cannon; artillery (9)"],
["地","땅 지","지","earth; soil","ground; region (6)"],
["炯","빛날 형","형","밝을 형","bright","brilliant","clear; hot (9)"],
["麤","성길 추","추","클 추","거칠 추","rough","coarse","big","rough (33)"],
["訐","들추어 낼 알","알","expose other's secrets","pry (10)"],
["竪","세울 수","수","서있을 수","더벅머리 수","perpendicular","vertical; erect (13)"],
["炰","그슬 포","포","까불거릴 포","roast","broil; bake (9)"],
["訑","자랑할 이","이","deceive","cheat; arrogant (10)"],
["麥","보리 맥","맥","wheat","barley","oats; KangXi radical number 199 (11)"],
["凄","쓸쓸할 처","처","bitter cold","miserable","dreary (10)"],
["鑪","화로 로","로","fireplace","stove","oven","furnace (24)"],
["訓","가르칠 훈","훈","teach","instruct; exegesis (10)"],
["竭","다할 걸","걸","마를 걸","put forth great effort; exhaust (14)"],
["凅","얼어붙을 고","고","dried up; exhausted","tired; dry (10)"],
["封","봉할 봉","봉","letter","envelope; feudal (9)"],
["准","승인할 준","준","approve","allow","permit; in accord (10)"],
["炳","밝을 병","병","bright","luminous; glorious (9)"],
["晛","햇발 현","현","sunlight (11)"],
["訕","헐뜯을 산","산","abuse","slander; vilify; ridicule (10)"],
["端","끝 단","단","end","extreme; head; beginning (14)"],
["凇","산유화 송","송","dewdrop; icicle (10)"],
["麩","밀기울 부","부","bran (15)"],
["訖","이를 흘","흘","finish; conclude","stop; exhaust (10)"],
["凈","찰 정","정","clean","pure; cleanse (10)"],
["射","맞힐 석","석","shoot","eject","issue forth","emit (10)"],
["麪","밀가루 면","면","flour","noodles","dough (15)"],
["託","맡길 탁","탁","부탁할 탁","핑계할 탁","entrust","rely on; commission (10)"],
["晝","낮 주","주","daytime","daylight (11)"],
["晞","마를 희","희","dry","expose sun; dawn (11)"],
["凊","서늘할 청","청","surname (10)"],
["炷","심지 주","주","candle wick","lamp wick; stick of incense (9)"],
["記","기록할 기","기","record; keep in mind","remember (10)"],
["鑰","자물쇠 약","약","key; lock (25)"],
["鑱","보습 참","참","spade","trowel (25)"],
["凋","시들 조","조","be withered","fallen; exhausted (10)"],
["將","장차 장","장","will","going to","future; general (11)"],
["炸","터질 작","작","to fry in oil; to scald; to explode (9)"],
["晟","밝을 성","성","햇살 퍼질 성","clear","bright; splendor","brightness (11)"],
["專","오로지 전","전","monopolize","take sole possession (11)"],
["疆","땅 경계 강","강","갈피 강","굳셀 강","변방 강","boundary","border","frontier (19)"],
["翁","늙은이 옹","옹","새 목털 아래 옹","old man; father","father-in-law (10)"],
["凌","능가할 릉","릉","pure; virtuous; insult; maltreat (10)"],
["鑲","거푸집 속 양","양","insert","inlay","set","mount; fill (25)"],
["晡","신시 포","포","해질 포","late afternoon (11)"],
["疇","밭두둑 주","주","farmland","arable land; category (19)"],
["訛","거짓말 와","와","어긋날 와","swindle","cheat; erroneous","wrong (11)"],
["圻","언덕 은","은","border","boundary (7)"],
["凍","얼 동","동","freeze; cold","congeal; jelly (10)"],
["尉","벼슬이름 위","위","눌릴 위","officer","military rank (11)"],
["晢","별이 반짝반짝 할 제","제","light of stars; shine (11)"],
["競","겨룰 경","경","contend","vie","compete (20)"],
["麰","보리 모","모","barley (17)"],
["饂","밀 국수 온","온","Japanese noodles (19)"],
["訝","맞아들일 아","아","의심할 아","express surprise","be surprised (11)"],
["鑵","두레박 관","관","jar; can (26)"],
["疊","거듭 첩","첩","포갤 첩","repeat","duplicate; repetitious (22)"],
["翅","날개 시","시","깃 시","뿐 시","wings; fin (10)"],
["晤","밝을 오","오","만날 오","have interview with; meet (11)"],
["圾","위태할 급","급","garbage","rubbish; shaking; danger (7)"],
["尋","찾을 심","심","seek","search","look for; ancient (12)"],
["晥","환할 환","환","Anhui province (11)"],
["竹","대 죽","죽","bamboo; flute; KangXi radical 118 (6)"],
["尌","세울 주","주","standing (something) up (12)"],
["凑","모일 주","주","piece together","assemble (11)"],
["訟","송사할 송","송","시비할 송","꾸짖을 송","괘 이름 송","accuse; argue","dispute; litigate (11)"],
["疋","짝 필","필","끝 필","roll","bolt of cloth; foot (5)"],
["鑷","쪽집게 섭","섭","tweezers","forceps","pincers (26)"],
["晦","그믐 회","회","dark","unclear","obscure; night (11)"],
["饅","만두 만","만","steamed bread; steamed dumplings (20)"],
["竺","나라이름 축","축","India; bamboo; surname (8)"],
["對","마주볼 대","대","correct","right; facing","opposed (14)"],
["晧","밝을 호","호","빛날 호","daybreak; bright and brilliant (11)"],
["凓","찬 기운 률","률","shiver; severe cold (12)"],
["導","이끌 도","도","direct","guide","lead","conduct (15)"],
["麴","누룩 국","국","yeast","leaven; surname (19)"],
["凔","찰 창","창","cold (12)"],
["疎","성길 소","소","드물 소","neglect; careless","lax (12)"],
["晨","샛별 신","신","아침을 아뢸 신","early morning","daybreak (11)"],
["小","작을 소","소","좁을 소","적을 소","어릴 소","적게 여길 소","small","tiny","insignificant (3)"],
["訢","기뻐할 흔","흔","pleased","delighted; happy (11)"],
["饈","드릴 수","수","반찬 수","food","meal; eat; offer (20)"],
["竽","큰 생황 우","우","ancient woodwind instrument (9)"],
["翊","도울 익","익","flying; assist","help; respect (11)"],
["晩","저물 만","만","night (11)"],
["饉","주릴 근","근","time of famine or crop failure (20)"],
["訣","이별할 결","결","열결할 결","사례할 결","비결 결","take leave of","bid farewell (11)"],
["疏","뚫릴 소","소","나눌 소","멀 소","추할 소","드물 소","neglect; careless","lax (12)"],
["少","적을 소","소","조금 소","멸시할 소","잠깐 소","젊을 소","few","less","inadequate (4)"],
["鑼","징 라","라","gong (27)"],
["晫","환할 탁","탁","밝을 탁(12)"],
["鑽","끌 찬","찬","drill","bore; pierce; diamond (27)"],
["竿","장대 간","간","화살대 간","횃대 간","bamboo pole; penis (9)"],
["翌","다음날 익","익","bright; daybreak","dawn; the next day (11)"],
["饋","먹일 궤","궤","offer food superior; send gift (21)"],
["訥","말 더듬거릴 눌","눌","slow of speech; mumble; stammer (11)"],
["疑","의심할 의","의","두려워할 의","그럴듯할 의","doubt","question","suspect (14)"],
["晬","돐 쉬","쉬","첫 생일 쉬","first birthday of a child (12)"],
["鑾","방울 란","란","bells hung on horse; bells hung (27)"],
["麹","누룩 국","국","yeast","leaven; surname (15)"],
["饌","반찬 찬","찬","feed","support","provide for; food; fine meats","delicacies (21)"],
["鑿","뚫을 착","착","chisel; bore","pierce (27)"],
["翎","깃 령","령","feather; plume; wing (11)"],
["普","널리 보","보","universal","general","widespread (12)"],
["凚","쌀쌀하게 추울 금","금","cold; chilly (15)"],
["麻","삼 마","마","hemp","jute","flax; sesame (11)"],
["愀","정색할 초","초","to change one's countenance","be anxious; to blush (12)"],
["尖","뾰족할 첨","첨","sharp","pointed","acute","keen (6)"],
["疔","헌데 정","정","carbuncle","boil","ulcer (7)"],
["景","그림자 영","영","옷 영","scenery","view; conditions (12)"],
["凛","찰 름","름","to shiver with cold or fear (15)"],
["麼","작을 마","마","interrogative final particle; insignificant","small","tiny (14)"],
["晰","밝을 석","석","clear","evident; clearly (12)"],
["愁","근심 수","수","염려할 수","탄식할 수","anxiety; to worry about","be anxious (13)"],
["凜","찰 름","름","shiver with cold or fear","fearful (15)"],
["麽","잘 마","마","interrogative final particle; insignificant","small","tiny (14)"],
["麾","대장기 휘","휘","a pennant","flag","banner; to signal to (15)"],
["饐","밥 쉴 의","의","spoiled","rotten","sour (21)"],
["訪","찾을 방","방","visit; ask","inquire (11)"],
["習","익힐 습","습","거듭 습","practice; flapping wings (11)"],
["凝","엉길 응","응","이룰 응","열중할 응","coagulate; congeal; freeze (16)"],
["凞","빛날 희","희","bright","splendid","glorious (16)"],
["饑","주릴 기","기","starve","be hungry; famine (21)"],
["愃","쾌할 선","선","relax (12)"],
["尙","오히려 상","상","still","yet; even; fairly","rather (8)"],
["麿","갈 마","마","I","personal name marker (18)"],
["饒","용서할 요","요","배부를 요","bountiful","abundant","plentiful (21)"],
["翔","빙빙돌아날 상","상","soar","glide","hover; detailed (12)"],
["翕","합할 흡","흡","agree (12)"],
["疙","쥐부스럼 흘","흘","pimple","sore","boil","wart","pustule (8)"],
["設","베풀 설","설","build; establish; display; particle of hypothesis","supposing (11)"],
["晳","밝을 석","석","fair; white; clear; discriminate (12)"],
["几","안석 궤","궤","small table (2)"],
["晴","갤 청","청","clear weather","fine weather (12)"],
["饔","아침밥 옹","옹","breakfast; eat prepared food (22)"],
["疚","오랜 병 구","구","chronic disease","chronic illness; sorrow (8)"],
["愆","죄 건","건","허물 건","어기어질 건","a fault","mistake","error","transgression (13)"],
["凡","무릇 범","범","all","any","every; ordinary","common (3)"],
["蓁","우거질 진","진","abundant","luxuriant vegetation (16)"],
["饕","탐할 도","도","gluttonous","greedy","covetous (22)"],
["歃","마실 삽","삽","smear one's mouth with blood of a victim when taking an oath (13)"],
["晶","수정 정","정","맑을 정","빛날 정","crystal; clear","bright; radiant (12)"],
["蓂","명협 명","명","lucky place (16)"],
["愈","나을 유","유","어질 유","더욱 유","병 나을 유","more and more","even more (13)"],
["許","이영차 호","호","allow","permit; promise; betroth (11)"],
["饗","잔치할 향","향","흠향할 향","host banquet; banquet (22)"],
["疝","산증 산","산","hernia","rupture (8)"],
["晷","시각 귀","귀","shadows of sun; time; sundial (12)"],
["愉","기뻐할 유","유","즐거울 유","pleasant","delightful; please (12)"],
["歆","받을 흠","흠","like","admire; willingly","gladly; to quicken (13)"],
["蓄","쌓을 축","축","모을 축","감출 축","store","save","hoard","gather (16)"],
["尠","적을 선","선","very few; seldom","rarely (13)"],
["歇","쉴 헐","헐","나른할 헐","다할 헐","으슥할 헐","스러질 헐","흩어질 헐","rest","stop","lodge (13)"],
["処","위치할 처","처","place","locale; department (5)"],
["智","슬기 지","지","wisdom","knowledge","intelligence (12)"],
["訴","하소연할 소","소","송사할 소","accuse; sue; inform; narrate (12)"],
["蓆","자리 석","석","straw mat","mat (16)"],
["翛","날개치는 소리 소","소","모지라질 소","look of rumpled feathers; bedraggled; hasty; rapid flight (13)"],
["愍","근심할 민","민","pity","sympathize with (13)"],
["愎","사나울 퍅","퍅","고집할 퍅","obstinate","stubborn","headstrong (12)"],
["訶","꾸짖을 가","가","scold loudly","curse","abuse (12)"],
["較","견줄 교","교","compare; comparatively","more (13)"],
["歉","흉년들 겸","겸","적을 겸","나쁠 겸","deficient","lacking","insufficient (14)"],
["尤","가장 우","우","더욱 우","탓할 우","especially","particularly (4)"],
["饜","싫을 염","염","물릴 염","be satiated","eat one's full (23)"],
["蓉","연꽃 용","용","약 이름 용","hibiscus; Chengdu","Sichuan (16)"],
["意","뜻 의","의","생각 의","의리 의","형세 의","thought","idea","opinion; think (13)"],
["凩","찬바람 목","목","wintry wind (6)"],
["疤","흉터 파","파","scar","cicatrix; birthmark (9)"],
["凪","바람이 자고 파도 그칠 지","지","calm","lull (6)"],
["翟","꿩 책","책","surname; a kind of pheasant; plumes (14)"],
["蓊","옹풀 옹","옹","우거질 옹","luxuriant vegetation; lush (16)"],
["輅","수레앞 가로막이 나무 핵","핵","맞을 핵","a chariot","carriage; a carriage pull-bar (13)"],
["翠","비취 취","취","푸를 취","color green; kingfisher (14)"],
["饞","탐할 참","참","gluttonous","greedy; lewd","lecherous (26)"],
["疥","옴 개","개","scabies","itch (9)"],
["歌","노래 가","가","song","lyrics; sing","chant; praise (14)"],
["診","볼 진","진","examine patient","diagnose (12)"],
["輇","달 전","전","잴 전","cart wheel with no spokes (13)"],
["蓋","이엉 덮을 합","합","cover","hide","protect (16)"],
["翡","물총새 비","비","kingfisher; emerald","jade (14)"],
["尨","헝클어진 모양 봉","봉","shaggy haired dog; variegated (7)"],
["歎","탄식할 탄","탄","아름답다 할 탄","화답할 탄","sigh","admire (15)"],
["輈","끌채 주","주","shaft (13)"],
["註","주낼 주","주","explain; annotate; make entry (12)"],
["凭","기대다 빙","빙","lean on","depend on","rely on (8)"],
["証","간할 정","정","prove","confirm","verify; proof (12)"],
["載","실을 재","재","이길 재","비롯할 재","가득할 재","load; carry; transport","convey (13)"],
["蓍","뺑대쑥 시","시","시초 시","milfoil","plant used in divination (16)"],
["翣","운삽 삽","삽","feathers used decorate coffin; large wooden fan (14)"],
["愔","조용할 음","음","화평할 음","comfortable","contented","peaceful (12)"],
["愕","놀랄 악","악","startled","alarmed","astonished (12)"],
["歐","토할 구","구","used in transliterating non-Chinese words such as Europe","ohm; surname (15)"],
["凰","봉황새 황","황","female phoenix (11)"],
["訾","헐뜯을 자","자","bad-mouth; criticize; defects (12)"],
["尫","절름발이 왕","왕","파리할 왕","천상바라기 왕","lame (7)"],
["翦","베어 없앨 전","전","멸할 전","scissors; cut","clip; annhilate (15)"],
["蓐","새싹 욕","욕","가을 맡은 귀신 욕","straw bed mat; rushes (16)"],
["疫","염병 역","역","epidemic","plague","pestilence (9)"],
["凱","싸움 이긴 풍류 개","개","착할 개","마파람 개","좋은 사람 개","triumphant; triumph","victory (12)"],
["蓑","도롱이 사","사","rain coat made of straw","coir","etc. (16)"],
["歔","한숨 쉴 허","허","흑흑 느껴 울 허","코로 숨 내쉴 허","blow through nose","snort (16)"],
["囀","새 지저귈 전","전","후렴 전","sing","chirp","warble","twitter (21)"],
["凳","걸상 등","등","bench; stool (14)"],
["翩","훌쩍 날 편","편","오락가락할 편","fly","flutter (15)"],
["蓓","꽃봉오리 배","배","bud (16)"],
["愚","어리석을 우","우","어두울 우","업신여길 우","stupid","doltish","foolish (13)"],
["囁","소곤거릴 엽","엽","move lips as when speaking; hesitation (21)"],
["囂","시끄럽다 효","효","be noisy; treat with contempt (21)"],
["愛","사랑 애","애","love","be fond of","like (13)"],
["凵","입 벌릴 감","감","위가 벌어진 그릇 감","receptacle; KangXi radical 17 (2)"],
["凶","흉할 흉","흉","culprit; murder; bad","sad (4)"],
["愜","쾌할 협","협","뜻에 맞을 협","be satisfied","be comfortable (12)"],
["就","좇을 취","취","이룰 취","곧 취","가령 취","능할 취","저자 취","just","simply; to come","go to; to approach","near (12)"],
["囃","장단 잡을 잡","잡","to play (on an instrument); to accompany; to banter","jeer (21)"],
["翫","익숙할 완","완","싫을 완","구경할 완","careless","play; a legendary archer (15)"],
["翬","훨훨날 휘","휘","pheasant; variegated; to fly (15)"],
["輒","문득 첩","첩","sides of chariot where weapons (14)"],
["蓖","아주까리 비","비","castor-oil plant","Ricinus commumis (16)"],
["凷","흙덩이 괴","괴","dirt clod; piece (5)"],
["歙","줄일 흡","흡","to suck; a county's name in anhui (16)"],
["疱","부풀 포","포","부르틀 포","acne (10)"],
["囅","껄껄 웃을 철","철","smile (22)"],
["輓","끌 만","만","mourn; pull; draw (14)"],
["凸","볼록할 철","철","protrude","bulge out","convex (5)"],
["翮","깃촉 핵","핵","쭉지 핵","칼 갓 핵","stem of feather; quill (16)"],
["疲","피곤할 피","피","나른할 피","feel tired","be exhausted; weak (10)"],
["灄","흠치르르할 섭","섭","물이름 섭","떼 섭","river in Hubei province (21)"],
["窀","광중 둔","둔","to bury (9)"],
["歚","고을 선","선","used in name of famous Korean painter Ceng Sen (16)"],
["凹","오목할 요","요","concave","hollow","depressed; a pass","valley (5)"],
["出","날 출","출","게울 출","도망할 출","보일 출","낳을 출","물러갈 출","생질 출","go out","send out; stand; produce (5)"],
["輔","덧방나무 보","보","cheek bone; protective; assist (14)"],
["疳","감질병 감","감","궤양 감","창병 감","종기 아플 감","childhood diseases (10)"],
["突","부딪칠 돌","돌","suddenly","abruptly","unexpectedly (9)"],
["感","감동할 감","감","찌를 감","깨달을 감","느낄 감","feel","perceive","emotion (13)"],
["歛","탐할 검","검","구걸할 검","draw back","fold back; collect (17)"],
["翰","날개 한","한","writing brush","pen","pencil (16)"],
["蓚","기쁠 조","조","oxalic (used in compounds) (16)"],
["輕","가벼울 경","경","light; easy","simple; gentle (14)"],
["疴","앓을 아","아","illness","sickness","disease; pain (10)"],
["囈","잠꼬대할 예","예","헛소리할 예","talk in one's sleep","somniloquy (22)"],
["疵","흠 자","자","flaw","fault","defect; disease (10)"],
["囉","소리 읽힐 라","라","exclamatory final particle","nag (22)"],
["函","상자 함","함","correspondence; a case; a box (8)"],
["尸","시동 시","시","주검 시","corpse; to impersonate the dead; to preside; rad. no. 44 (3)"],
["囊","주머니 낭","낭","bag","purse","sack; put in bag (22)"],
["凾","상자 함","함","correspondence; a case; a box (9)"],
["窄","좁을 착","착","narrow","tight; narrow-minded (10)"],
["鐃","작은 징 뇨","뇨","cymbals; hand bell; disturb (20)"],
["翳","어조사 예","예","깃 일산 예","가려 숨길 예","새이름 예","shade","screen; to hide","screen (17)"],
["歟","그런가할 어조사 여","여","a final particle used to express admiration","doubt","surprise","or to mark a question (18)"],
["尹","다스릴 윤","윤","바를 윤","벼슬이름 윤","믿을 윤","govern; oversee; director (4)"],
["鐄","종 횡","횡","bell (20)"],
["歠","마실 철","철","sip","drink","suck (19)"],
["尺","자 척","척","Chinese measure approx. 'foot' (4)"],
["疸","황달 달","달","jaundice; disorders of stomach (10)"],
["窅","움펑눈 요","요","까마득할 요","far","deep; sunken eyes; sad (10)"],
["囍","쌍희 희","희","double happiness (21)"],
["歡","기뻐할 환","환","happy","pleased","glad; joy; to enjoy (22)"],
["窆","하관할 폄","폄","광중 폄","to put a coffin in a grave (10)"],
["疹","홍역 진","진","measles; rash; fever (10)"],
["尻","꽁무니 고","고","end of spine; buttocks","sacrum (5)"],
["止","발 지","지","stop","halt","desist; detain (4)"],
["輛","수레 량","량","numerary adjunct for vehicles (15)"],
["灌","물 댈 관","관","pour; water; irrigate","flood (21)"],
["尼","가까울 닐","닐","정할 닐","그칠 닐","Buddhist nun; transliteration (5)"],
["輜","짐수레 치","치","휘장 친 수레 치","a supply cart","covered wagon","dray (15)"],
["正","바를 정","정","과녁 정","남쪽으로 난 창 정","어른 정","분별할 정","right","proper","correct (5)"],
["窈","고요할 요","요","깊을 요","obscure","secluded; refined (10)"],
["愧","부끄러워할 괴","괴","ashamed","conscience-stricken (13)"],
["輝","빛날 휘","휘","brightness","luster","brilliance (15)"],
["此","이 차","차","this","these; in this case","then (6)"],
["囐","북소리 잘","잘","나라이름 잘(23)"],
["疼","아플 동","동","aches","pains; be fond of; love (10)"],
["尾","꼬리 미","미","등 미","tail","extremity; end","stern (7)"],
["囑","부탁할 촉","촉","order","tell","instruct","leave word (24)"],
["疽","등창 저","저","ulcer","carbuncle","abscess (10)"],
["輞","바퀴테 망","망","exterior rim of wheel","felly (15)"],
["窊","웅덩이 와","와","pit","vault; store in pit; (Cant.) 手窊","the arm (10)"],
["步","걸을 보","보","step","pace; walk","stroll (7)"],
["尿","오줌 뇨","뇨","urine; urinate (7)"],
["輟","그칠 철","철","stop","suspend","halt (15)"],
["疾","병 질","질","illness","disease","sickness; to hate (10)"],
["翹","꼬리 긴 깃털 교","교","turn up","lift","elevate","raise (18)"],
["武","굳셀 무","무","자만할 무","군인 무","military; martial","warlike (8)"],
["囒","거짓말 할 란","란","confused talk (24)"],
["翺","날 고","고","soar","roam (18)"],
["灑","뿌릴 쇄","쇄","깜짝 놀랄 쇄","sprinkle","splash; scatter","throw (22)"],
["翻","날 번","번","flip over","upset","capsize (18)"],
["愬","고할 소","소","참소할 소","알릴 소","accuse; sue; inform; narrate (14)"],
["翼","날개 익","익","wings; fins on fish; shelter (18)"],
["翾","파뜩파뜩 날 현","현","경박할 현","영리할 현","급할 현","fly; to flit (19)"],
["愰","밝을 황","황","clearness of mind; doubtful; uncertain (13)"],
["鐏","창고달 준","준","cap (20)"],
["窒","막을 질","질","stop up","obstruct (11)"],
["鐐","은 료","료","fetters (20)"],
["輦","손수레 련","련","a hand-cart; to transport by carriage (15)"],
["灘","여울 탄","탄","해 이름 탄","bank","a sandbar","shoal; rapids (22)"],
["窓","창 창","창","window (11)"],
["輧","거마 소리 병","병","curtained carriage used by women (15)"],
["蓬","쑥 봉","봉","type of raspberry; fairyland (17)"],
["囚","가둘 수","수","갇힐 수","묶일 수","prisoner","convict; confine (5)"],
["愴","슬퍼할 창","창","sad","broken-hearted","disconsolate (13)"],
["窕","정숙할 조","조","slender; quiet and modest","charming (11)"],
["鐓","창고달 대","대","ferrule; castrate (20)"],
["輩","무리 배","배","generation","lifetime; class (15)"],
["四","넉 사","사","four (5)"],
["鐔","칼날 아래 코등이 심","심","dagger; small sword (20)"],
["蓮","연밥 련","련","lotus","water lily; paradise (17)"],
["輪","바퀴 륜","륜","wheel; revolve","turn; recur (15)"],
["昂","높을 앙","앙","rise","raise; proud","bold; upright (8)"],
["蓯","풀 더북할 총","총","medicinal herb (17)"],
["窗","창 창","창","window (12)"],
["囝","아이 건","건","baby","infant (6)"],
["回","돌 회","회","return","turn around; a time (6)"],
["窘","군색할 군","군","embarrassed; hard-pressed (12)"],
["愷","탐할 개","개","급할 개","enjoy","be contented","joyful (13)"],
["歲","해 세","세","곡식 익을 세","돐 세","풍년 세","year; age; harvest (13)"],
["灝","넓을 호","호","콩 삶은 물 호","vast","large","grand","expansive (24)"],
["蓱","마름 평","평","머구리밥 평","개구리밥 평(17)"],
["囟","숨구멍 신","신","정수리 신","top of the head; skull (6)"],
["灞","물이름 파","파","river in Shanxi province (24)"],
["鐘","쇠북 종","종","clock; bell (20)"],
["昆","덩어리 혼","혼","서쪽 오랑캐 이름 혼","elder brother; descendants (8)"],
["因","인할 인","인","cause","reason; by; because (of) (6)"],
["輯","모을 집","집","gather up","collect; edit","compile (16)"],
["鐙","등자 등","등","lamp; a kind of cooking vessel (20)"],
["觀","볼 관","관","see","observe","view; appearance (25)"],
["昇","해 돋을 승","승","풍년들 승","rise","ascent; peaceful; peace (8)"],
["蓴","순나물 순","순","edible water plant","bracenia (17)"],
["愼","삼갈 신","신","정성스러울 신","고요할 신","act with care","be cautious (13)"],
["歷","지낼 력","력","take place","past","history (16)"],
["昉","마침 방","방","bright dawn","daybreak; appear (8)"],
["昊","하늘 호","호","큰 모양 호","summer time; sky; heaven (8)"],
["歸","돌아올 귀","귀","return; return to","revert to (18)"],
["囤","작을 곳집 돈","돈","grain basket","bin for grain (7)"],
["輳","모일 주","주","hubs of wheel; converge around (16)"],
["灣","물굽이 만","만","a bay","cove","inlet","bend of stream (25)"],
["窟","구멍 굴","굴","hole","cave; cellar; underground (13)"],
["歹","뼈 앙상할 알","알","잔골 알","살 발린 뼈 알","bad","vicious","depraved","wicked (4)"],
["昌","창성할 창","창","light of sun; good","proper (8)"],
["窠","보금자리 과","과","nest; hole","indention; den (13)"],
["愾","한숨 희","희","anger","wrath","hatred","enmity (13)"],
["愿","정성 원","원","착할 원","삼갈 원","sincere","honest","virtuous (14)"],
["死","죽을 사","사","die; dead; death (6)"],
["明","밝을 명","명","bright","light","brilliant; clear (8)"],
["昏","어두울 혼","혼","dusk","nightfall","twilight","dark (8)"],
["蓼","여뀌 료","료","smartweed","polygonum (17)"],
["窣","느릿느릿 걸을 솔","솔","우지직거릴 솔","구멍으로 나올 솔","to rush out of a den; rustling","whispering (13)"],
["囫","온전할 홀","홀","entire","whole (7)"],
["蓽","휘추리 필","필","약이름 필","species of bean; piper longtum (17)"],
["輸","떨어뜨릴 수","수","쏟을 수","보낼 수","짐바리 수","transport","carry","haul (16)"],
["灩","물 그득할 염","염","물결 출렁거릴 염","overflowing","billowing; wavy (31)"],
["歿","죽을 몰","몰","die; death; dead (8)"],
["昑","밝을 금","금","bright (8)"],
["輹","복토 복","복","two pieces of wood underneath a cart (16)"],
["火","불 화","화","fire","flame; burn; anger","rage (4)"],
["蓿","거여목 숙","숙","clover","lucerne (17)"],
["园","둥글게 깎을 완","완","garden; park","orchard (7)"],
["冀","바랄 기","기","hope for; wish; Hebei province (16)"],
["輻","바퀴살통 폭","폭","spokes of wheel (16)"],
["易","쉬울 이","이","다스릴 이","게으를 이","편할 이","change; easy (8)"],
["囮","새 후릴 와","와","inveigle","decoy; cheat (7)"],
["昔","옛 착","착","formerly; ancient; in beginning (8)"],
["昕","아침 흔","흔","dawn; early morning; day (8)"],
["窩","굴 와","와","움집 와","nest; cave","den; hiding place (14)"],
["困","괴로울 곤","곤","to surround","beseige; to be surrounded; difficult (7)"],
["囱","굴뚝 총","총","chimney (7)"],
["輾","돌아 누울 전","전","turn over","roll (17)"],
["冂","먼데 경","경","wide; KangXi radical 13 (2)"],
["窪","도랑 와","와","깊을 와","hollow; pit; depression; swamp (14)"],
["灰","재 회","회","ashes; dust; lime","mortar (6)"],
["輿","수레바탕 여","여","짐질 여","cart","palanquin; public opinion (17)"],
["寀","녹봉 채","채","feudal estate (11)"],
["角","사람이름 녹","녹","horn; angle","corner; point (7)"],
["窬","판장문 유","유","a small door or window; a hole in the wall to cut through a wall (14)"],
["鐫","새길 전","전","engraving tool; carve","engrave (21)"],
["寂","고요할 적","적","쓸쓸할 적","still","silent","quiet","desolate (11)"],
["窮","다할 궁","궁","poor","destitute","impoverished (15)"],
["畀","줄 비","비","to give (8)"],
["寃","원통할 원","원","굽힐 원","죄 없을 원","grievance","injustice","wrong (11)"],
["窯","기와가마 요","요","오지그릇 요","kiln; coal mine pit (15)"],
["寄","부칠 기","기","send","transmit","mail; rely on (11)"],
["觖","서운할 결","결","들쳐낼 결","바랄 결","to long for; dissatisfied to criticize (11)"],
["冉","가는 털 늘어질 염","염","남생이 등 언저리 염","침노할 염","tender; weak; proceed gradually (5)"],
["寅","세째지지 인","인","to respect","reverence; respectfully; 3rd terrestrial branch (11)"],
["囷","둥근곳집 균","균","round-shaped storage bin for grain (8)"],
["冊","책 책","책","book","volume (5)"],
["密","빽빽할 밀","밀","dense","thick","close; intimate (11)"],
["昞","밝을 병","병","bright","luminous; glorious (9)"],
["寇","도둑 구","구","bandits","thieves; enemy; invade (11)"],
["灸","지질 구","구","cauterize with moxa; moxibustion (7)"],
["窳","이지러질 유","유","약할 유","짐승이름 유","dirty","useless","weak","powerless; cracked","a flaw (15)"],
["星","별 성","성","희뜩희뜩할 성","세월 성","성시 성","a star","planet; any point of light (9)"],
["囹","옥 령","령","prison","enclosure (8)"],
["册","책 책","책","book","volume","register","list (5)"],
["觚","술잔 고","고","jug","winecup","goblet; square (12)"],
["映","비칠 영","영","빛날 영","project; reflect light (9)"],
["固","굳을 고","고","to become solid","solidify; strength (8)"],
["昡","햇빛 현","현","long day; extended; relaxed (9)"],
["再","두 번 재","재","거듭 재","again","twice","re- (6)"],
["畇","밭개간할 균","균","land (9)"],
["羂","걸 견","견","걸릴 견","to trap","snare (18)"],
["觜","부리 취","취","beak (13)"],
["窶","가난하여 예절없을 구","구","poor","impoverished (16)"],
["鐵","쇠 철","철","iron; strong","solid","firm (21)"],
["觝","찌를 저","저","받을 저","씨름 저","gore","butt; resist; push (12)"],
["鐶","고리 환","환","metal ring; measure of currency (21)"],
["餃","경단 교","교","stuffed dumplings (15)"],
["灼","사를 작","작","burn; broil; cauterize; bright (7)"],
["羅","새그물 라","라","net for catching birds; gauze (19)"],
["昤","날 빛 영롱할 령","령","sunshine (9)"],
["春","봄 춘","춘","남녀의 정 춘","술 춘","온화할 춘","spring; wanton (9)"],
["冑","투구 주","주","helmet (9)"],
["畋","사냥할 전","전","till land","cultivate; hunt (9)"],
["災","재앙 재","재","천벌 재","횡액 재","calamity","disaster","catastrophe (7)"],
["富","가멸 부","부","abundant","ample; rich","wealthy (12)"],
["羆","큰 곰 비","비","brown bear","ursus arctos (19)"],
["囿","엔담 유","유","고루할 유","pen up; limit","constrain (9)"],
["鐸","요령 탁","탁","목탁 탁","bell; surname (21)"],
["冒","무릅쓸 목","목","risk","brave","dare (9)"],
["窺","엿볼 규","규","peep","watch","spy on","pry (16)"],
["界","지경 계","계","boundary","limit; domain; society; the world (9)"],
["羇","나그네 기","기","inn; lodge; travel (22)"],
["冓","재목 어긋매겨 쌓을 구","구","a secluded place; secret cabinet (10)"],
["羈","굴레 기","기","halter; restrain","hold","control (24)"],
["昧","새벽 매","매","obscure","dark; darken (9)"],
["冔","관 후","후","cap worn during the Yin dynasty (10)"],
["鐺","죄인 자물쇠 당","당","frying pan; warming vessel (21)"],
["鹵","소금 로","로","saline soil; natural salt","rock (11)"],
["昨","어제 작","작","yesterday; in former times","past (9)"],
["冕","면류관 면","면","crown; ceremonial cap (11)"],
["寐","잠잘 매","매","sleep; be asleep (12)"],
["鹶","짜고 쓸 긍","긍","salty and bitter; pitiful (15)"],
["畎","밭 도랑 견","견","산골 도랑 견","a drain between fields","irrigation; to flow (9)"],
["羊","양 양","양","sheep","goat; KangXi radical 123 (6)"],
["餉","먹일 향","향","군량 향","점심 향","rations and pay for soldiers (15)"],
["冖","덮을 멱","멱","cover; KangXi radical 14 (2)"],
["解","쪼갤 해","해","꺠우칠 해","풀 해","괘 이름 해","벗을 해","loosen","unfasten","untie; explain (13)"],
["窾","빌 관","관","hole; hallow; empty (17)"],
["畏","두려울 외","외","겁낼 외","fear","dread","awe","reverence (9)"],
["羋","양 울 미","미","bleat of sheep; surname (7)"],
["寒","찰 한","한","cold","wintry","chilly (12)"],
["養","기를 양","양","다스릴 양","봉양할 양","raise","rear","bring up; support (15)"],
["窿","활꼴 륭","륭","mine shaft; cavity","hole (17)"],
["羌","오랑캐 강","강","말 끝낼 강","Qiang nationality; surname (8)"],
["昫","해돋아 따뜻할 구","구","warm (9)"],
["鹹","짤 함","함","salty","briny; salted; pickled (20)"],
["畑","화전 전","전","밭 전","dry (as opposed to rice) field; used in Japanese names (9)"],
["冘","머뭇거릴 유","유","갈 유","to move on; coubtful (4)"],
["寓","살 우","우","부탁할 우","빙자할 우","residence; lodge; dwell (12)"],
["觥","뿔잔 굉","굉","a cup made of horn obstinate (13)"],
["寔","이 식","식","참 식","뿐 식","real","TRUE","solid","honest (12)"],
["餌","미끼 이","이","이깝 이","먹일 이","흰떡 이","bait; bait","entice; dumplings (15)"],
["美","아름다울 미","미","맛이 좋을 미","beautiful","pretty; pleasing (9)"],
["昭","밝을 조","조","bright","luminous; illustrious (9)"],
["寕","문안할 녕","녕","repose","serenity","peace; peaceful (12)"],
["鹻","소금물 검","검","잿물 검","비누 검","impure carbonate of sodium of natron (21)"],
["畓","논 답","답","rice field (9)"],
["寖","젖을 침","침","점점 침","gradually","step by step","little (13)"],
["畔","지경 반","반","boundary path dividing fields (10)"],
["是","이 시","시","바를 시","곧을 시","indeed","yes","right; to be; demonstrative pronoun","this","that (9)"],
["切","자를 체","체","cut","mince","slice","carve (4)"],
["寗","편안할 녕","녕","a surname; peaceful; rather (13)"],
["鹽","소금 염","염","절일 염","후렴 염","variant of 塩 U+5869","salt (24)"],
["度","꾀할 탁","탁","헤아릴 탁","벼슬이름 탁","degree","system; manner; to consider (9)"],
["寘","둘 치","치","폐할 치","쉴 치","put aside","put down; discard (13)"],
["餐","먹을 찬","찬","eat","dine; meal; food (16)"],
["昱","햇빛 밝을 욱","욱","bright light","sunlight; dazzling (9)"],
["拓","물리칠 탁","탁","헤칠 탁","밀칠 탁","expand; open up; support or push (8)"],
["冞","점점 미","미","두루 다닐 미","far (8)"],
["鹿","사슴 록","록","deer; surname; KangXi radical 198 (11)"],
["觫","곱송거릴 속","속","to start","tremble with fear (14)"],
["惄","마음 졸일 녁","녁","long for; hungry (12)"],
["冟","고룰 석","석","고르지 않을 석","알맞게 된 밥 석(9)"],
["餒","굶길 뇌","뇌","hungry","starving","famished (16)"],
["羔","새끼양 고","고","lamb","kid (10)"],
["宅","집 택","택","자리 택","살 택","정할 택","묘 구덩이 택","residence","dwelling","home; grave (6)"],
["情","뜻 정","정","마음속 정","feeling","sentiment","emotion (11)"],
["冠","갓 관","관","cap","crown","headgear (9)"],
["寛","용서할 관","관","broad (13)"],
["餓","주릴 아","아","hungry; greedy for; hunger (16)"],
["留","머무를 류","류","stop","halt; stay","detain","keep (10)"],
["餔","저녁 곁두리 포","포","먹을 포","eat; dinner time; sunset (16)"],
["洞","구렁 통","통","깊을 통","빌 통","밝을 통","cave","grotto; ravine; hole (9)"],
["惆","실심할 추","추","섭섭할 추","distressed","regretful","sad (11)"],
["寜","차라리 녕","녕","repose","serenity","peace; peaceful (13)"],
["畚","삼태기 분","분","straw basket","hamper (10)"],
["羖","검은 암양 고","고","a black ram (10)"],
["昴","별자리 이름 묘","묘","one of the 28 constellations (9)"],
["暴","사나울 포","포","급할 포","맨손으로 칠 포","불끈 일어날 포","마를 포","상할 포","violent","brutal","tyrannical (15)"],
["欃","살별 참","참","sandalwood; comet (21)"],
["畛","두렁길 진","진","border","boundary; raised path (10)"],
["餕","대궁 준","준","leftovers (16)"],
["惇","두터울 돈","돈","be kind","cordial","sincere (11)"],
["昵","가깝게 할 닐","닐","intimate","close; approach (9)"],
["輻","바퀴살통 폭","폭","spokes of wheel (16)"],
["畜","기를 휵","휵","용납할 휵","livestock","domestic animals (10)"],
["冢","무덤 총","총","burial mound","mausoleum; grand (10)"],
["蒂","작은 가시 체","체","peduncle or stem of plants (15)"],
["昶","밝을 창","창","a long day. bright. extended. clear (9)"],
["欄","난간 란","란","railing","balustrade; animal pan (21)"],
["寞","쓸쓸할 막","막","silent","still","lonely","solitary (14)"],
["行","시장 항","항","항렬 항","굳셀 항","go; walk; move","travel; circulate (6)"],
["觱","바람이 쌀쌀할 필","필","대평수 필","tartar horn; chilly wind (16)"],
["冣","쌓을 취","취","모을 취(10)"],
["察","살필 찰","찰","examine","investigate; notice (14)"],
["羘","수양 장","장","ram (10)"],
["畝","이랑 무","무","Chinese land measure; fields (10)"],
["昷","어질 온","온","to feed a prisoner (9)"],
["降","항복할 항","항","descend","fall","drop; lower","down (9)"],
["冤","원통할 원","원","원한 원","grievance","injustice","wrong (10)"],
["餘","남을 여","여","나라이름 여","surplus","excess","remainder (16)"],
["見","나타날 현","현","드러날 현","있을 현","보일 현","see","observe","behold; perceive (7)"],
["惋","한탄할 완","완","놀라 탄식할 완","regret","be sorry; alarmed (11)"],
["冥","어두울 명","명","dark","gloomy","night; deep (10)"],
["寡","적을 과","과","widowed; alone","friendless (14)"],
["觳","뿔잔 곡","곡","an ancient measure; a goblet; mean; frightened (17)"],
["軀","몸 구","구","body (18)"],
["羚","영양 령","령","species of antelope (11)"],
["餚","반찬 효","효","prepared meat; food (17)"],
["廓","둘레 확","확","broad","wide","open","empty; expand (14)"],
["寢","잠잘 침","침","쉴 침","방 침","못생길 침","sleep","rest; bed chamber (14)"],
["畠","밭 전","전","garden","field","farm","plantation (10)"],
["觴","잔 상","상","wine vessel; propose toast; feast (18)"],
["兀","우뚝할 올","올","움직이지 않을 올","duplicate of Big Five A461 (3)"],
["昻","오를 앙","앙","밝을 앙","rise (9)"],
["畢","마칠 필","필","토끼그물 필","다할 필","편지 필","책 필","고기 꿰 다는 나무 필","end","finish","conclude; completed (11)"],
["觶","잔 치","치","벌술잔 치","wine goblet made from horn (19)"],
["羝","숫양 저","저","ram","he-goat (11)"],
["寤","잠 깨어날 오","오","깨달을 오","few","scarce; empty","deserted (14)"],
["惏","떨릴 람","람","avaricious; greedy cold (11)"],
["權","권세 권","권","power","right","authority (22)"],
["冩","베낄 사","사","write; draw","sketch; compose (14)"],
["寥","쓸쓸할 료","료","few","scarce; empty","deserted (14)"],
["羞","부끄러울 수","수","음식 수","disgrace","shame; ashamed; shy (11)"],
["畤","제터 치","치","place for worshipping the haven (11)"],
["觸","받을 촉","촉","느낄 촉","지날 촉","범할 촉","touch; butt","ram","gore (20)"],
["冪","덮을 멱","멱","cover-cloth","cover with cloth (16)"],
["實","열매 실","실","real","TRUE","solid","honest (14)"],
["惑","미혹할 혹","혹","confuse","mislead","baffle; doubt (12)"],
["軆","몸 체","체","body; group","class","body","unit (20)"],
["餞","전별잔치 전","전","보낼 전","farewell party; see off","send off (17)"],
["冫","얼음 빙","빙","ice; KangXi radical 15 (2)"],
["寧","편안할 녕","녕","repose","serenity","peace; peaceful (14)"],
["略","다스릴 략","략","approximately","roughly; outline (11)"],
["畦","밭두둑 휴","휴","sections in vegetable farm (11)"],
["寨","울짱 채","채","stockade","stronghold","outpost; brothel (14)"],
["冬","겨울 동","동","winter","11th lunar month (5)"],
["晴","갤 청","청","clear weather (12)"],
["惓","삼갈 권","권","careful","sincere","candid (11)"],
["審","살필 심","심","examine","investigate; judge (15)"],
["餠","떡 병","병","rice-cakes","biscuits (17)"],
["惕","두려워할 척","척","be cautious","careful","alert (11)"],
["車","수레 차","차","cart","vehicle; carry in cart (7)"],
["群","무리 군","군","(same as U+7FA3 羣) group","crowd","multitude","mob (13)"],
["惖","존경할 척","척","respect; fear (12)"],
["欑","모일 찬","찬","gather (23)"],
["寫","베낄 사","사","write; draw","sketch; compose (15)"],
["軋","수레 삐걱거릴 알","알","crush by weight; grind (8)"],
["番","갈마들 번","번","to take turns; a turn","a time; to repeat (12)"],
["畫","그을 획","획","delineate","painting","picture","drawing; draw (12)"],
["冱","찰 호","호","얼 호","막을 호","freezing; stopped up","closed off (6)"],
["欒","나무 이름 란","란","name of tree; a part of cornice (23)"],
["寬","너그러울 관","관","broad","wide; spacious","vast (15)"],
["蒐","꼭두서니 수","수","모을 수","숨을 수","찾을 수","collect","gather","assemble; seek; spring hunt; assemble for war (16)"],
["軌","수레바퀴 사이 궤","궤","track","rut","path (9)"],
["寭","밝힐 혜","혜","깨달을 혜(15)"],
["冲","깊을 충","충","온화할 충","어릴 충","soar; pour boiling water over (6)"],
["惘","멍할 망","망","disconcerted","dejected","discouraged (11)"],
["益","더할 익","익","profit (10)"],
["軍","군사 군","군","army","military; soldiers","troops (9)"],
["惙","근심할 철","철","sad","melancholy","grieving","mournful (11)"],
["餦","산자 장","장","candy","pastry (17)"],
["寮","벼슬아쳐 료","료","shanty","hut","shack (15)"],
["嚀","정녕할 녕","녕","enjoin","instruct; charge (17)"],
["羨","광중길 연","연","envy","admire; praise; covet (13)"],
["惚","황홀할 홀","홀","absent-minded","confused (11)"],
["欕","엄나무 엄","엄","tree name (24)"],
["餧","먹일 위","위","steamed bread; to feed (17)"],
["義","옳을 의","의","의리 의","뜻 의","right conduct","righteousness (13)"],
["寰","기내 환","환","great domain","country","world (16)"],
["惛","흐릴 혼","혼","confused","stupid","dull","senile (11)"],
["欖","감람나무 람","람","olive (25)"],
["館","객사 관","관","public building (17)"],
["神","천신 신","신","영검할 신","신명 신","정신 신","spirit (10)"],
["蒔","소회향 시","시","모종낼 시","transplant; plant; dill","Anethum graveolens (16)"],
["嚃","훅 들어마실 탑","탑","gobble (17)"],
["瀁","물결 일렁일 양","양","waves","ripples","rapids; overflow (18)"],
["惜","아낄 석","석","pity","regret","rue","begrudge (11)"],
["冶","단련할 야","야","대장장이 야","smelt","fuse metals; cast","found (7)"],
["祥","복 상","상","good luck (11)"],
["羫","밭 갈 강","강","the skeleton of a sheep (14)"],
["嚄","외칠 획","획","roar (17)"],
["軒","초헌 헌","헌","껄껄 웃을 헌","주적댈 헌","추녀끝 헌","고기 굵게 저밀 헌","carriage; high; wide; balcony; surname of the Yellow Emperor (10)"],
["冷","찰 랭","랭","cold","cool; lonely (7)"],
["餪","풀보기 잔치 난","난","feast (18)"],
["福","복 복","복","happiness (14)"],
["異","다를 이","이","괴이할 이","나눌 이","different","unusual","strange (12)"],
["惝","멍할 창","창","alarmed","agitated (11)"],
["靖","다스릴 정","정","편안할 정","생각할 정","pacify; appease; calm (13)"],
["嚅","말 머뭇거릴 유","유","잔말할 유","talk indistinctly and falteringly (17)"],
["嚆","울릴 효","효","give forth sound","make noise (17)"],
["餬","기식할 호","호","porridge","gruel","congee; paste (18)"],
["精","정교할 정","정","세밀할 정","정할 정","성실할 정","신령 정","essence; semen; spirit (14)"],
["穀","녹봉 구","구","corn","grain","cereal; lucky (15)"],
["瀅","맑을 형","형","개천 형","clear","pure water; lucid; glossy (18)"],
["嚇","노할 혁","혁","꾸짖을 혁","scare","frighten; intimidate (17)"],
["寵","사랑할 총","총","임금께 총애받을 총","은혜 총","첩 총","favorite","concubine; favor (19)"],
["惟","꾀할 유","유","오직 유","생각할 유","but","however","nevertheless; only (11)"],
["羽","깃 우","우","오음의 하나 우","feather (6)"],
["蒙","입을 몽","몽","cover; ignorant; suffer; mongolia (16)"],
["羯","불친 양 갈","갈","땅이름 갈","wether","castrated ram; deer skin (15)"],
["惠","은혜 혜","혜","사랑할 혜","favor","benefit","confer kindness (12)"],
["餮","탐할 철","철","a legendary animal; a greedy pers (18)"],
["瀆","도랑 독","독","ditch","sluice","gutter","drain (18)"],
["寶","보배 보","보","treasure","jewel; precious","rare (20)"],
["瀇","아득할 왕","왕","넓을 왕","고인 물 썪어 냄새날 왕(18)"],
["惡","미워할 오","오","부끄러울 오","evil","wicked","bad","foul (12)"],
["鏁","긴 창 쇄","쇄","variant of 鎖 U+9396","a lock","padlock; shackles","chains (19)"],
["羲","숨 희","희","ancient emperor; breath","vapor (16)"],
["寸","치 촌","촌","마디 촌","헤아릴 촌","조금 촌","inch; small","tiny; rad. no. 41 (3)"],
["欞","격자창 령","령","the lattice of a window a sill","a lintel (28)"],
["冽","찰 렬","렬","cold and raw; pure","clear (8)"],
["嚊","헐떡거릴 비","비","to pant; wife; (Cant.) particle indicating doubt (17)"],
["蒜","달래 산","산","garlic (16)"],
["當","마땅할 당","당","bear","accept","undertake; just (13)"],
["餱","건량 후","후","dry goods","dry provisions (18)"],
["鏃","화살촉 족","족","arrowhead","barb; swift","quick (19)"],
["諸","모든 제","제","several (16)"],
["瀉","토할 사","사","drain off","leak; flow","pour down (18)"],
["嚌","맛볼 제","제","to sip; (Cant.) aspect marker of excessive extent (17)"],
["穅","겨 강","강","악기이름 강","chaff","bran","husks of grain","from which comes: --poor","remiss (16)"],
["欠","하품 흠","흠","owe","lack","be deficient; KangXi radical number 76 (4)"],
["寺","내관 시","시","court","office; temple","monastery (6)"],
["畸","뙈기 밭 기","기","odd","fractional","remainder","odds (13)"],
["次","버금 차","차","order","sequence; next (6)"],
["穆","화목할 목","목","majestic","solemn","reverent; calm (16)"],
["餳","엿 당","당","sugar","syrup; malt sugar; sticky (18)"],
["蒟","구장 구","구","betel pepper; Amorphaphalus konjac (16)"],
["嚎","울부짖을 호","호","cry loudly","yell","scream (17)"],
["羶","노린내날 전","전","rank odor (19)"],
["逸","달아날 일","일","flee (12)"],
["欣","기뻐할 흔","흔","happy","joyous","delighted (8)"],
["嚏","재채기 할 체","체","sneeze (17)"],
["都","도읍 도","도","metropolis (12)"],
["蒡","인동 덩굴 방","방","burdock","herb (16)"],
["鏇","갈이틀 선","선","lathe (19)"],
["瀏","맑을 류","류","clear; bright; whistling (18)"],
["鏈","쇠사슬 련","련","chain","wire","cable; chain","shack (19)"],
["羸","여윌 리","리","weak","lean","emaciated","exhausted (19)"],
["軟","부드러울 연","연","soft","flexible","pliable; weak (11)"],
["羹","국 갱","갱","soup","broth (19)"],
["瀑","폭포수 폭","폭","waterfall","cascade; heavy rain (18)"],
["穌","깨어날 소","소","숨 쉴 소","기뻐할 소","revive","to rise again; collect (16)"],
["餹","엿 당","당","sugar; sugared; candy; crystallized sweetmeats (19)"],
["飯","밥 반","반","cooked rice; food; meal (13)"],
["鏊","번철 오","오","a flat","iron cooking-plate for cakes (19)"],
["畿","경기 기","기","imperial domain; area near capita (15)"],
["嚔","재채기 할 체","체","sneeze (18)"],
["鸇","구진매 전","전","hawk; Butastur indicus (24)"],
["積","포갤 적","적","모을 적","넓이 적","부피 적","accumulate","store up","amass (16)"],
["飼","기를 사","사","raise animals; feed; nourish (14)"],
["穎","이삭 영","영","송곳 끝 영","빼어날 영","rice tassel; sharp point; clever (16)"],
["館","객사 관","관","public building (17)"],
["鏌","칼 이름 막","막","sword (19)"],
["鶴","두루미 학","학","crane (22)"],
["蒨","대가 우거질 천","천","lush vegetation","luxuriant growth (16)"],
["嚕","아까워할 로","로","verbose","talkative; mumbling (18)"],
["羽","깃 우","우","오음의 하나 우","feather","plume; wings; rad. 124 (6)"],
["餼","꾸밀 희","희","sacrificial victim; gift; grain (19)"],
["瀕","물가 빈","빈","approach","be on verge of; near (19)"],
["惰","게으를 타","타","태만할 타","indolent","careless","lazy","idle (12)"],
["嚗","역정낼 박","박","지팡이 던지는 소리 박(18)"],
["欬","기침할 해","해","크게 부를 해","일깨울 해","cough; sound of laughter (10)"],
["惱","괴로워할 뇌","뇌","angered","filled with hate (12)"],
["嚘","말 얼버무릴 우","우","탄식할 우(18)"],
["鏑","화살촉 적","적","dysprosium; the barb of an arrow; the head of a javelin (19)"],
["瀘","강 이름 로","로","river in Jiangxi province (19)"],
["惲","의논할 운","운","혼후할 운","devise","plan","deliberate; consult (12)"],
["嚙","깨물 교","교","bite","gnaw (18)"],
["想","생각할 상","상","think","speculate","plan","consider (13)"],
["旀","하며 며","며","place name (9)"],
["嚚","어리석을 은","은","말다툼할 은","불칙하게 말할 은","argumentative","talkative (18)"],
["軨","사냥 수레 령","령","lattice work on front and sides (12)"],
["惴","두려워할 췌","췌","afraid","apprehensive","nervous (12)"],
["旁","휘몰아갈 팽","팽","side; by side","close by","near (10)"],
["瀚","넓고 큰 한","한","vast","wide","extensive (19)"],
["旂","기 기","기","flag (10)"],
["嚜","거짓 묵","묵","불만스러운 모양 묵","be silent; final particle (18)"],
["惶","두려워할 황","황","fearful","afraid","anxious","nervous (12)"],
["軫","수레 뒤턱 나무 진","진","cross board at rear of carriage (12)"],
["旃","기 전","전","말 그칠 전","silk banner with bent pole (10)"],
["瀛","큰 바다 영","영","신선 사는 산 영","sea","ocean (19)"],
["穗","이삭 수","수","ear of grain; tassel; Guangzhou (17)"],
["蒯","황모 괴","괴","a rush","of which various things are made (16)"],
["惷","어수선할 준","준","wriggle; stupid; silly; fat (13)"],
["欲","욕심낼 욕","욕","하고자할 욕","desire","want","long for; intend (11)"],
["旄","깃대 장식 모","모","a kind of ancient flag; old (10)"],
["鏖","무찌를 오","오","구리동이 오","to fight to the end","engage in a fierce battle (19)"],
["瀝","거를 력","력","trickle","drip; strain; dregs (19)"],
["惸","근심할 경","경","troubled","worried","distressed (12)"],
["旅","군사 려","려","trip","journey; travel; traveler (10)"],
["鏗","금석 소리 갱","갱","사람이름 갱","strike","beat","stroke; jingling (19)"],
["蒲","부들풀 포","포","창포 포","개버들 포","type of rush; vine (16)"],
["旆","기 패","패","깃발 날릴 패","flag ornament; flags","banners (10)"],
["瀞","맑을 정","정","정결할 정","pool in a river (19)"],
["鏘","금옥 소리 장","장","tinkle","clang","jingle (19)"],
["惹","끌릴 야","야","어지러울 야","irritate","vex","offend","incite (13)"],
["瀟","물 맑을 소","소","비바람 몰아칠 소","강이름 소","sound of beating wind and rain; light","ethereal (19)"],
["惻","슬플 측","측","감창할 측","아플 측","불쌍할 측","feel anguish","feel compassion (12)"],
["要","구할 요","요","살필 요","언약할 요","허리 요","기다릴 요","부를 요","반드시 요","necessary","essential; necessity (9)"],
["鸕","가마우지 로","로","cormorant (27)"],
["蒴","말오줌대 삭","삭","seed (capsule) (16)"],
["欷","흐느낄 희","희","sob; sigh (11)"],
["惼","조급할 편","편","편협할 편","narrow-minded (12)"],
["覃","미칠 담","담","reach to","spread to; extensive (12)"],
["欸","한숨쉴 애","애","sighs; an exclamatory sound (11)"],
["鏜","북방축소 당","당","boring tool (19)"],
["瀣","찬이슬 해","해","이슬 기운 해","sea mist; vapor (19)"],
["欹","아름답다할 의","의","거룩하다할 의","fierce dog; interjection of pleas; (Cant.) strange (12)"],
["旋","돌 선","선","revolve","move in orbit; return (11)"],
["嚥","침 삼킬 연","연","목구멍 연","swallow","gulp (19)"],
["鏝","흙손 만","만","trowel (19)"],
["蒸","찔 증","증","steam; evaporate (16)"],
["旌","장목기 정","정","표시할 정","banner or flag adorned with feathers; to signal (11)"],
["穠","후한 제사 농","농","thick","lush","in clusters (18)"],
["欺","속일 기","기","cheat","doublecross","deceive (12)"],
["嚦","소리 력","력","used in onomatopoetic expressions (19)"],
["鏞","큰 쇠북 용","용","a large bell used as a musical instrument (19)"],
["欻","문득 훌","훌","sudden","abrupt","quick (12)"],
["覆","덮을 부","부","가리울 부","고루고루 퍼질 부","cover; tip over; return; reply (18)"],
["穡","거둘 색","색","farm","harvest grain; stingy (18)"],
["鏟","대패 산","산","spade","shovel","trowel","scoop (19)"],
["鸚","앵무새 앵","앵","parrot (28)"],
["蒹","갈대 겸","겸","reed","phragmites communis (16)"],
["蒺","납가새 질","질","furze; gorse (16)"],
["瀦","물 괼 저","저","못 저","pond; a pool (19)"],
["嚨","목구멍 롱","롱","throat (19)"],
["鸛","황새 관","관","crane","Grus japonensis; stork; Ciconia species (various) (29)"],
["欽","공경할 흠","흠","respect","admire; respectful (12)"],
["覈","핵실할 핵","핵","겨무거리 핵","씨 핵","액색할 핵","investigate","consider","examine (19)"],
["族","풍류가락 주","주","a family clan","ethnic group","tribe (11)"],
["瀧","비 올 룡","룡","raining; wet; soaked; a river in Guangdong (19)"],
["穢","더러울 예","예","거칠 예","dirty","unclean; immoral","obscene (18)"],
["鏡","거울 경","경","mirror; lens; glass; glasses (19)"],
["鸜","구관조 구","구","a species of mynah (29)"],
["蒻","구약나물 약","약","rushes (16)"],
["鏢","칼날 표","표","비수 표","dart","spear","harpoon; escort (19)"],
["蒼","푸를 창","창","blue; green (16)"],
["覉","구속할 기","기","말 굴레 기","variant of 羇 U+7F87","inn; to lodge; to travel (23)"],
["瀨","여울 뢰","뢰","swift current; rapids (19)"],
["款","정성 관","관","item","article; clause; fund (12)"],
["嚫","베풀 친","친","(Cant.) aspect marker of injury (19)"],
["軸","바디집 축","축","질책 축","속바퀴 축","얇을 축","axle","axletree; pivot; axis (12)"],
["欿","서운할 감","감","찌푸릴 감","걱정할 감","discontented; sad; gloomy (12)"],
["鸞","난새 란","란","fabulous bird (30)"],
["軹","굴대 머리 지","지","end of axle; divergent (12)"],
["見","나타날 현","현","드러날 현","있을 현","보일 현","see","observe","behold; perceive (7)"],
["嚬","찡그릴 빈","빈","frown (19)"],
["鏤","새길 루","루","carve","inlay","engrave","tattoo (19)"],
["蒿","쑥 호","호","향기날 호","지칠 호","mugwort","artemisia; give off (16)"],
["軺","수레 초","초","small light carriage (12)"],
["覌","볼 관","관","see","observe","view; appearance (9)"],
["旒","깃발 류","류","fringes of pearls on crowns (13)"],
["嚭","클 비","비","mound","lump; stealthily (19)"],
["嚮","향할 향","향","누릴 향","울릴 향","받을 향","guide","direct; incline to","favor (19)"],
["瀬","여울 뢰","뢰","swift current; rapids (19)"],
["軻","수레 가기 힘들 가","가","axle; personal name of mencius (12)"],
["鏦","창 총","총","찌를 총","spear (19)"],
["兀","우뚝할 올","올","움직이지 않을 올","to cut off the feet (3)"],
["軼","앞지를 일","일","rush forth; surpass","excel (12)"],
["規","그림쇠 규","규","rules","regulations","customs","law (11)"],
["穩","편안할 온","온","곡식 걷어 모을 온","stable","firm","solid","steady (19)"],
["旕","땅이름 엇","엇","엇 엇","place name (13)"],
["允","미쁠 윤","윤","마땅할 윤","옳게 여길 윤","좇을 윤","허락할 윤","to grant","to allow","to consent (4)"],
["軽","천할 경","경","light; easy","simple; gentle (12)"],
["嚱","소리낼 희","희","아아 경탄하는 소리 희","a shrill noise; alas! (20)"],
["鏨","끌 참","참","engraving tool","chisel (19)"],
["瀯","물소리 영","영","물 돌아나갈 영","물웅덩이 영(20)"],
["軾","수레앞 가로막이 나무 식","식","수레 안에서 절할 식","horizontal wooden bar in front of a sedan chair (13)"],
["穫","벼 벨 확","확","harvest","cut grain (19)"],
["嚲","휘늘어질 타","타","read aloud","recite","chant; droop (20)"],
["瀰","물 넓을 미","미","overflow (20)"],
["旗","기 기","기","banner","flag","streamer (14)"],
["元","으뜸 원","원","착하고 어질 원","일 년의 첫 날 원","임금 원","백성 원","하늘 원","first; dollar; origin; head (4)"],
["兄","맏이 형","형","elder brother (5)"],
["嚳","고할 고","고","mythical emperor; to inform quickly; an urgent communication (20)"],
["充","가득찰 충","충","막을 충","당할 충","fill","be full","supply (5)"],
["宁","조회 받는 곳 저","저","멈출 저","calm","peaceful","serene; healthy (5)"],
["覓","찾을 멱","멱","seek; search (11)"],
["瀲","넘칠 렴","렴","waves","ripples; overflow (20)"],
["嚴","굳셀 엄","엄","공경할 엄","엄할 엄","strict","rigorous","rigid; stern (20)"],
["兆","조짐 조","조","omen; million; mega; also trillion. China"],
["宂","번잡할 용","용","바쁠 용","떠돌아 다닐 용","scattered","mixed affairs; duties; occupation business (5)"],
["兇","흉악할 흉","흉","atrocious","ferocious","brutal (6)"],
["甁","병 병","병","jug (13)"],
["視","볼 시","시","견줄 시","본받을 시","대접 시","look at","inspect","observe","see (11)"],
["穰","풍년들 양","양","stalks of grain; lush","abundant (22)"],
["嚶","새 소리 앵","앵","seek friends; also used in names; the call of a bird (20)"],
["先","먼저 선","선","first","former","previous (6)"],
["甃","우물 벽돌 추","추","brick wall of a well (14)"],
["覗","엿볼 사","사","peek (12)"],
["嚷","떠들 양","양","shout","brawl","make uproar","cry (20)"],
["光","빛 광","광","light","brilliant","shine; only (6)"],
["宅","집 택","택","자리 택","살 택","정할 택","묘 구덩이 택","residence","dwelling","home; grave (6)"],
["甄","질그릇 구울 진","진","to examine","discern; to grade; a surname (14)"],
["克","이길 극","극","gram; overcome; transliteration (7)"],
["宇","집 우","우","처마 기슭 우","하늘 우","house; building","structure; eaves (6)"],
["穴","굴 혈","혈","움 혈","틈 혈","굿 혈","cave","den","hole; rad. no. 116 (5)"],
["无","없을 무","무","negative","no","not; KangXi radical 7 (4)"],
["兌","기쁠 태","태","지름길 태","모일 태","통할 태","곧을 태","구멍 태","바꿀 태","괘 이름 태","cash; exchange","barter; weight (7)"],
["守","지킬 수","수","보살필 수","원님 수","기다릴 수","defend","protect","guard","conserve (6)"],
["嚻","한가할 효","효","be noisy; treat with contempt (21)"],
["穵","구멍 알","알","deep hollow;","gouge","dig out (6)"],
["免","해산할 문","문","통건 쓸 문","spare; excuse from; evade (8)"],
["究","궁리할 구","구","examine","investigate (7)"],
["罃","술병 앵","앵","long necked jar or bottle (16)"],
["嚼","씹을 작","작","prattle","be glib (21)"],
["安","편안할 안","안","어찌 안","peaceful","tranquil","quiet (6)"],
["飂","높이 부는 바람 료","료","wind in high places (20)"],
["罄","빌 경","경","exhaust","run out","use up; empty (17)"],
["旣","이미 기","기","already; de facto; since; then (11)"],
["罅","틈 하","하","crack","fissure","split (17)"],
["瀼","이슬 많은 모양 양","양","강이름 양","river in Henan province; flowing (20)"],
["宋","송나라 송","송","성씨 송","Song dynasty; surname (7)"],
["飄","회오리바람 표","표","나부낄 표","떨어질 표","whirlwind","cyclone; floating (20)"],
["穹","높을 궁","궁","high and vast; elevated; arched (8)"],
["日","날 일","일","sun; day; daytime (4)"],
["完","완전할 완","완","끝날 완","지킬 완","complete","finish","settle; whole (7)"],
["瀾","물결 란","란","overflowing; waves","ripples (20)"],
["空","빌 공","공","empty","hollow","bare","deserted (8)"],
["甌","사발 구","구","bowl","cup; small tray (16)"],
["旦","밝을 단","단","dawn; morning; day (5)"],
["兒","아이 아","아","son","child","oneself; final part (8)"],
["飇","회오리바람 표","표","whirlwind","stormy gale (21)"],
["兔","토끼 토","토","rabbit","hare (8)"],
["旨","맛있을 지","지","purpose","aim; excellent (6)"],
["覡","박수 격","격","남자무당 격","wizard (14)"],
["宏","클 굉","굉","wide","spacious","great","vast (7)"],
["早","새벽 조","조","early; soon; morning (6)"],
["穽","구덩이 정","정","함정 정","hole; pitfall","trap (9)"],
["甎","벽돌 전","전","기와 전","brick","square tile","slab (16)"],
["兕","외뿔난 소 시","시","a female rhinoceros (8)"],
["鏻","굳셀 린","린","phosphonium (20)"]]

export default HANJA;