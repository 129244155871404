import {useState} from 'react';
import HANJA from './hanja.js';
import WORDS from './words.js';
import WordItem from './wordItem.js'
import ChevronDown from './icons/ChevronDown'

const CharacterResult = (props) => {
  const typeOptions = ['word', 'verb', 'hanja', 'random']

  const {callback, character, collapsed, startsOnly, updateCell, data, setWord} = props;

  const [description, setDescription] = useState(data.description ? data.description : '');
  const [type, setType] = useState(data.type ? data.type : typeOptions[0]);
  const [editing, setEditing] = useState(!collapsed && !data.description);

  const [showHanja, setShowHanja] = useState(false);
  const [showWords, setShowWords] = useState(true);
  const [showDetails, setShowDetails] = useState(!collapsed && !data.description);
  const onSubmit = () => {
    updateCell(data.id, type, description);
    setEditing(false);
    callback();
  }

  const words = WORDS.filter((w) => {
    if(startsOnly) {
    return w[0][0] === character;
    }
    return w[0].includes(character);
  }).sort((a,b)=> a[2] - b[2]);

  const hanjaList = HANJA.filter(h => {
    return h[2] === character;
  }).map((h) => {
    const hanja = h[0];
    return {
      hanja,
      item: h.slice(1, -1),
      count: words.filter((w) => w[3].includes(hanja)).length,
    }
  }).sort((a,b) => b.count - a.count);

  const selectHanjaTab = () => {
    setShowHanja(true);
    setShowWords(false);

  }

  const selectWordTab = () => {
    setShowHanja(false);
    setShowWords(true);
  }

  if(!data)
  return (<>missing</>)
  return (
    <div>
      <ChevronDown onClick={() => setShowDetails(!showDetails)} height='20px' width='20px'/>
      <span>{character}: </span>
      {editing ? (
      <div>
        <input className='text-black m-2 text-lg' placeholder="description" value={description} onChange={(e) => setDescription(e.target.value)} type='text' />
        <select className='text-black text-lg' value={type} onChange={(e) => setType(e.target.value)}>
          {typeOptions.map((option) => <option>{option}</option>)}
        </select>

        <button className='m-1 uebtn' onClick={onSubmit}>update</button>
      </div>
      ) : (
        <>{description} ({type}) <button className='uebtn' onClick={() => setEditing(true)}>edit</button> </>
      )}
      {showDetails && (
        <div>
          {hanjaList.length > 0 ? (
            <button  className='m-1 btn' onClick={selectHanjaTab}>hanja</button>
          ): (<></>)}
          {words.length > 0 ? (
            <button  className='m-1 btn' onClick={selectWordTab}>words</button>
          ): (<></>)}

          <a className="App-link" arget="blank" href={`https://en.dict.naver.com/#/search?range=word&query=${character}`}>naver</a>
          {showHanja && (
            <div>
              <ul className="resultbox">
                {hanjaList.map((l) => {
                  return (<li key={l.item.join('-')}>{l.hanja}: {l.item.join(', ')} {l.count > 0 && (<span>(words: {l.count})</span>)}</li>)
                })}
              </ul>
          </div>
          )}
          {showWords && (
            <>
            <ul className="resultbox">
              {words.map((l) => {
                return(<WordItem word={l} setWord={setWord} />)
              })}
            </ul>
            </>
            )}

        </div>
      )}
    </div>
  )

}
export default CharacterResult;